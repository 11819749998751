<template>
<div class="my-demand">
    <template v-if="tempNum==1">
        <div class="search-box">    
            <el-form :inline="true" :model="searchData" size="mini">
                <el-form-item label="日期">
                    <el-date-picker
                        v-model="searchData.date"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        :unlink-panels="true"
                        >
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="OE号">

                    <el-input v-model="searchData.oecode" class="oe-el-input"></el-input>
                </el-form-item>
                <el-form-item label="状态">
                    <el-select v-model="searchData.state" class="small-select">
                        <el-option label="解决中" value="0"></el-option>
                        <el-option label="已解决" value="1"></el-option>
                        <el-option label="未解决" value="2"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                  
                    <el-button type="primary" @click="onSubmit">搜索</el-button>
                    <el-button type="primary" @click="pubNewDemand">发布新需求</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="reBtn" v-if="rebackFlag">
            

            <el-button @click="reBack" type="primary" size="mini">返回列表</el-button>
        </div>
        <div class="damand-table">
            <div class="table-head">
                <div class="check"></div>
                <div class="oecode">OE号</div>
                <div class="sccode">生产编码</div>
                <div class="sccode">配件名称</div>
                <div class="brand">品牌</div>
                <div class="vehicle">车型</div>
                <div class="num">需求数量</div>
                <div class="linkman">联系人</div>
                <div class="linkman">联系方式</div>
                <!-- <div class="site">地址</div>
                <div class="pubTime">发布时间</div>
                <div class="overTime">解决时间</div> -->
                <div class="pubState">状态</div>
                <div class="handle">操作</div>
            </div>
            <div class="table-body">
                <div class="table-row" v-for="(item,index) in demandList" :key="index">
                    <div class="check">
                        <el-checkbox v-model="item.choose"></el-checkbox>
                    </div>
                    <div class="oecode">{{item.oecode}}</div>
                    <div class="sccode">{{item.sccode}}</div>
                    <div class="sccode">{{item.goods_name}}</div>
                    <div class="brand">{{item.oenaturebrand}}</div>
                    <div class="vehicle">{{item.vehicletype}}</div>
                    <div class="num">{{item.dem_num}}</div>
                    <div class="linkman">{{item.fullname}}</div>
                    <div class="linkman">{{item.telephone}}</div>
                    <!-- <div class="site">{{item.re_address}}</div>
                    <div class="pubTime">{{item.add_time}}</div>
                    <div class="overTime">{{item.over_time}}</div> -->
                    <div class="pubState">{{$hnwTools.reDemandStateText(item.state)}}</div>
                    <div class="handle">
                         <!-- v-if="item.state=='1'||item.state=='2'" -->
                        <div v-show="item.state=='0'" @click="edit(item)">编辑</div>
                        <div  @click="readInfo(item.dem_id)">查看详情</div>
                        <div v-if="item.state=='0'&&isHnw" @click="readInfo(item.dem_id)">回复</div>
                        <div class="shu" ></div>
                        <div  @click="del(item.dem_id)" >删除</div>
                    </div>
                </div>
                <!-- v-if="isHnw||item.state=='1'" -->
            </div>
        </div>
        <div class="betcHandle">
            <el-checkbox v-model="allChoose" @change="allChange">全选</el-checkbox>
            <div class="betchDel"  @click="del('betch')">删除</div>
        </div>

    </template>
    <template v-if="tempNum==2">
        <div class="demandInfo">
            <div class="container-box">
                <div class="title">
                    需求详情
                </div>
                <div class="body">
                    <div class="item">
                        <div class="label">
                            OE号
                        </div>
                        <div class="value">
                            {{demandData.oe_code}}
                        </div>
                    </div>

                    <div class="item">
                        <div class="label">
                            OE号
                        </div>
                        <div class="value">
                            {{demandData.goods_name}}
                        </div>
                    </div>

                    <div class="item">
                        <div class="label">
                            配件品牌
                        </div>
                        <div class="value">
                             {{demandData.oenaturebrand}}
                        </div>
                    </div>
                    <div class="item">
                        <div class="label">
                            需求数量
                        </div>
                        <div class="value">
                            {{demandData.dem_num}}
                        </div>
                    </div>
                    <div class="item">
                        <div class="label">
                            发布时间
                        </div>
                        <div class="value">
                            {{demandData.add_time}}
                        </div>
                    </div>
                    <div class="item">
                        <div class="label">
                            发布地址
                        </div>
                        <div class="value">
                             {{demandData.re_address}}
                        </div>
                    </div>
                    <div class="item">
                        <div class="label">
                            发布人
                        </div>
                        <div class="value">
                             {{demandData.fullname}}
                        </div>
                    </div>
                    <div class="item">
                        <div class="label">
                            联系方式
                        </div>
                        <div class="value">
                             {{demandData.telephone}}
                        </div>
                    </div>
                    <div class="item">
                        <div class="label">
                            配件描述
                        </div>
                        <div class="value">
                            {{demandData.parts_cri}}
                        </div>
                    </div>
                    <div class="item">
                        <div class="label">
                            状态
                        </div>
                        <div class="value">
                            {{$hnwTools.reDemandStateText(demandData.state)}}
                        </div>
                    </div>
                    <div class="item">
                        <div class="label">
                            照片
                        </div>
                        <div class="value img-con">
                            <div class="img-box" v-for="(img,index) in  demandData.img_url" :key="index">
                                <img :src="img" alt="">
                            </div>
                            
                        </div>
                    </div>

                </div>

            </div>
            <div class="container-box">
                <div class="title">
                    官方回复
                </div>
                <div class="body">
                    <div class="item" v-if="demandData.state==0">
                        <div class="label" >
                            有无货
                        </div>
                        <div class="value">
                          
                            {{verifyData.is_goods==1?'有货':'无货'}}
                           
                        </div>
                    </div>
                
                    <div class="item" v-if="demandData.state==0">
                        <div class="label">
                            咨询定价
                        </div>
                        <div class="value">
                            {{verifyData.offer_price}}
                            
                        </div>
                    </div>
                    <div class="item" v-else>
                        <div class="label">
                            咨询定价
                        </div>
                        <div class="value">
                            {{demandData.offer_price}}
                        </div>
                    </div>
                
                    <div class="item" v-if="demandData.state!=0">
                        <div class="label">
                            回复
                        </div>
                        <div class="bz">
                            <div class="row">
                                <div>
                                     {{demandData.rem_bz}}
                                </div>
                                <div>
                                    {{demandData.rem_bzTime}}
                                </div>
                            </div>
                            <div class="row" v-if="demandData.rem_bz1">
                                <div>
                                     追加回复1： {{demandData.rem_bz1}}
                                </div>
                                <div>
                                    {{demandData.rem_bz1Time}}
                                </div>
                            </div>
                            <div class="row" v-if="demandData.rem_bz2">
                                <div>
                                    追加回复2{{demandData.rem_bz2}}
                                </div>
                                <div>
                                    {{demandData.rem_bz2Time}}
                                </div>
                            </div>
                
                        </div>
                    </div>
                    <div class="item" v-else>
                         <div class="label">
                            回复
                        </div>
                        <div class="value">
                            {{verifyData.rem_bz}}
                            <!-- <el-input v-model="verifyData.rem_bz"></el-input> -->
                        </div>
                    </div>
                    <div class="item" v-if="demandData.rem_bz&&!demandData.rem_bz2">
                        <div class="label">
                            追加回复
                        </div>
                        <div class="value">
                            {{verifyData.rem_bz}}
                            <!-- <el-input v-model="verifyData.rem_bz"></el-input> -->
                        </div>
                    </div>

                </div>
            </div>
            <div class="btn-box">
                  <el-button @click="tempNum=1">返回</el-button>
                  <!-- <el-button type="primary"  @click="revert">提交</el-button> -->
            </div>
        </div>
    </template>
    <template v-if="tempNum==3">
        
            <!-- 需求发布 -->
        <!-- <div class="de-title">
          <i class="el-icon-warning-outline"></i>  提示：发布信息请填写完整，以便尽快为您解决需求
        </div> -->
        <div class="form-box">
            <!--  -->
          <el-form :model="pubDemand" status-icon  ref="demandFrom" label-width="100px" :rules="demandRules">
                <el-form-item label="OE号" prop="oecode">
                    <el-input type="text" v-model="pubDemand.oecode"></el-input>
                </el-form-item>
                <el-form-item label="生产编码" prop="sccode">
                    <el-input type="text" v-model="pubDemand.sccode"></el-input>
                </el-form-item>
                <el-form-item label="商品名称" prop="sccode">
                    <el-input type="text" v-model="pubDemand.goods_name"></el-input>
                </el-form-item>
                <el-form-item label="配件品牌" prop="oenaturebrand">
                    <el-input type="text" v-model="pubDemand.oenaturebrand"></el-input>
                </el-form-item>
                
                <el-form-item label="适配车型" prop="vehicletype">
                    <el-input type="text" v-model="pubDemand.vehicletype"></el-input>
                </el-form-item>
                
                <el-form-item label="需求数量" prop="dem_num">
                    <el-input type="text" v-model="pubDemand.dem_num"></el-input>
                </el-form-item>
                
                <el-form-item label="配件描述" prop="parts_cri">
                    <el-input type="text" v-model="pubDemand.parts_cri"></el-input>
                </el-form-item>
            
                <el-form-item label="联系地址" prop="re_address">
                    <el-input type="text" v-model="pubDemand.re_address"></el-input>
                </el-form-item>
                
                <el-form-item label="联系人" prop="fullname">
                    <el-input type="text" v-model="pubDemand.fullname"></el-input>
                </el-form-item>
                
                <el-form-item label="联系方式" prop="telephone">
                    <el-input type="text" v-model="pubDemand.telephone"></el-input>
                </el-form-item>
                <el-form-item label="商品图片" prop="img_name">
                    <el-upload
                        :action="$axios.defaults.baseURL+'index.php?act=store_demand&op=picture'"
                        :data="upImgData"
                        :headers="{'token':$store.state.token}"
                        :file-list="imgList"
                        name="pic"
                        list-type="picture-card"
                        size="mini"
                        :on-success="upImgOver"
                        :on-remove="removeImg"
                    >
                        <i class="el-icon-plus"></i>
                    </el-upload>
                </el-form-item>
                <el-form-item>
                    <el-button @click="resetForm">放弃</el-button>
                    <el-button type="primary" @click="submitForm">发布</el-button>
                </el-form-item>
            </el-form>
        </div>
    </template>
</div>
</template>

<script>
export default {
    data(){
        return{
            searchData:{
                date:[],
                oe_code:"",
                state:"",
                start_time:"",
                end_time:""
            },
            isEdit:false,
            upImgData:{
                store_id:this.$store.state.store_id
            },
            isHnw:this.$store.state.store_id==1780,
            rebackFlag:false,
            radio:"1",
            verifyData:{
                is_goods:"",
                offer_price:"",
                type:"1",
                rem_bz:""
            },
            rem_text:"",
            demandList:[
               {
                   choose:false,
                   state:1
               },
               {
                   choose:false,
                   state:2
               }
            ],
            imgList:[],
            allChoose:false,
            tempNum:1,
            demandData:"",
            dem_id:"",
            // 发布需求的字段
            pubDemand:{
                parts_cri:"",
                dem_num:"",
                re_address:"",
                oecode:"",
                sccode:"",
                goods_name:"",
                oenaturebrand:"",
                vehicletype:"",
                fullname:"",
                telephone:"",
                img_name:"",
                dem_id:""
            },
            demandRules:{
                oecode:[
                      { required: true, message: '请输入OE码', trigger: 'blur' }
                ],
                sccode:[
                      { required: true, message: '请输入生产编码', trigger: 'blur' }
                ],
                oenaturebrand:[
                      { required: true, message: '请输入汽车品牌', trigger: 'blur' }
                ],
                vehicletype:[
                      { required: true, message: '请输入车型', trigger: 'blur' }
                ],
                parts_cri:[
                      { required: true, message: '请输入描述', trigger: 'blur' }
                ],
                dem_num:[
                      { required: true, message: '请输入数量', trigger: 'blur' }
                ],
                re_address:[
                      { required: true, message: '请输入地址', trigger: 'blur' }
                ],
                fullname:[
                      { required: true, message: '请输入姓名', trigger: 'blur' }
                ],
                telephone:[
                      { required: true, message: '请输入联系方式', trigger: 'blur' }
                ],
                img_name:[
                      { required: true, message: '请输入商品图片', trigger: 'blur' }
                ]
            }
        }
    },
    methods:{
        async onSubmit(){
            const {date} = this.searchData
            if(date.length==2){
                console.log(date)
                this.searchData.start_time = new Date(date[0]).getTime()/1000
                this.searchData.end_time = new Date(date[1]).getTime()/1000
            }
            this.getList()
            this.rebackFlag  = true
        },
        pubNewDemand(){
            this.tempNum = 3
            this.isEdit = false

        },
        reBack(){
            this.rebackFlag  = false
            for(let key in this.searchData){
                this.searchData[key] = ""
            }
            this.searchData.date = []
            this.getList()
        },
        upImgOver(res){
            console.log("图片上传成功",res)
          

            this.pubDemand.img_name += res.data.file_name+","
        },
        removeImg(file){
            // file.name
            this.pubDemand.img_name = this.pubDemand.img_name.replace(file.name+',',"")
        },
        async submitForm(){
          
            this.$refs.demandFrom.validate(async (flag,obj)=>{
                console.log("未通过字段是:",obj)
                if(flag){
                    let res = ''
                    if(this.isEdit){
                        res = await this.$api.editDemand({
                            store_id:this.$store.state.store_id,
                            member_id:this.$store.state.member_id,
                            member_name:this.$store.state.member_name,
                            // nickname:this.$store.state.nickname,
                            ...this.pubDemand
                        })
                    }else{
                        this.pubDemand.dem_id = ""
                        res = await this.$api.pubDemand({
                            store_id:this.$store.state.store_id,
                            member_id:this.$store.state.member_id,
                            member_name:this.$store.state.member_name,
                            // nickname:this.$store.state.nickname,
                            ...this.pubDemand
                        })
                    }
                    
                    if(res){
                        this.$suMsg("发布成功")
                        this.resetForm()
                        // \\ // \\ // \\ // \\ // \\ || \\ console.log('')
                        for(let key in this.searchData){
                            this.searchData[key] = ""
                        }
                        this.getList()
                        this.resetForm()
                    }else{
                        
                        this.$erMsg("发布失败")
                    }
                }

            })
        },
        resetForm(){
            for(let key in this.pubDemand){
                this.pubDemand[key] = ""
            }
            this.imgList = []
            this.tempNum = 1
        },
        async getList(){
           
            let res = await this.$api.getDemandList({
                store_id:this.$store.state.store_id,
                member_id:this.$store.state.member_id,
                ...this.searchData
            })
             this.demandList = res.data
            this.demandList.forEach(item=>{
                this.$set(item,"choose",false)
            })
        },
        
        del(dem_id){
            
            let demId = ""
            let tit = "确定要删除这个需求?"
            let ids = []
            if(dem_id=='betch'){
                this.demandList.forEach(item=>{
                    if(item.choose){
                        demId +=item.dem_id + ","
                        ids.push(item.dem_id)
                    }
                })   
                tit= "确定要删除这些需求？"
            }else{
                demId = dem_id + ""
                ids.push(dem_id)
            }
            this.$confirm(tit, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
                })
                .then(async () => {
                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    });
                    await this.$api.delDemand({
                        dem_id:demId
                    })
                    // await this.$http("index.php?act=store_demand&op=demand_del",{
                    //     dem_id:demId
                    // })
                   
                    let temp =  this.demandList.filter(item=>ids.indexOf(item.dem_id)==-1)
                    this.$set(this,"demandList",temp)
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });          
             });
        },
        // 查看详情
        async readInfo(dem_id){
            this.tempNum = 2
            this.dem_id = dem_id
            this.demandData =  await this.$api.getDemandInfo({
                dem_id
            })
            // this.demandData =  await this.$http("index.php?act=store_demand&op=demand_info",{
            //     dem_id
            // })
            this.verifyData.is_goods = this.demandData.is_goods
            this.verifyData.offer_price = this.demandData.offer_price
            
            let stateText = ""
            if(this.demandData.state==0){
                stateText = "解决中"
            }else if(this.demandData.state==1){
                stateText = "已解决"
                
            }else if(this.demandData.state==2){
                stateText = "未解决"
            }
            this.demandData.stateText = stateText
            if(stateText=="已解决"){
                let {rem_bz,rem_bz1,rem_bz2} = this.demandData
                console.log("1正在")
                if(rem_bz){
                    
                     let arr = rem_bz.split(",")
                     let remTime = arr[arr.length-1]
                     arr.pop()
                     this.demandData.rem_bz = arr.join("")
                     this.demandData.rem_bz = this.demandData.rem_bz||"暂无回复"
                     this.demandData.rem_bzTime = remTime
                     this.verifyData.type = 2
                }
                if(rem_bz1){
                     let arr = rem_bz1.split(",")
                     
                     let remTime = arr[arr.length-1]
                     arr.pop()
                     this.demandData.rem_bz1 = arr.join("")
                     this.demandData.rem_bz1 = this.demandData.rem_bz1||"暂无回复"
                     this.verifyData.type = 3
                     this.demandData.rem_bz1Time = remTime
                }
                if(rem_bz2){
                     let arr = rem_bz2.split(",")
                     let remTime = arr[arr.length-1]
                     arr.pop()
                     this.demandData.rem_bz2 = arr.join("")
                      this.demandData.rem_bz2 = this.demandData.rem_bz2||"暂无回复"
                     this.demandData.rem_bz2Time = remTime
                }
            }
            this.$store.commit("addHis",{
              label:"查看/回复",
              path:"/demand/index",
              tempNum:this.tempNum,
              demandData:this.demandData,
              verifyData:this.verifyData
            })
        },
        edit(demand){

            this.tempNum = 3
            this.isEdit = true
            console.log(demand)

            if(demand.img_url){
                demand.img_url.forEach(item=>{
                    this.imgList.push({
                        url:item.img_url,
                        name:item.img_name
                    })
                })
            }
            this.pubDemand  = JSON.parse(JSON.stringify(demand))
        },
        // 回复
        async revert(){
            
            const res = await this.$api.verifyDemadn({
                dem_id:this.dem_id,
                ...this.verifyData
            })
            // const res = await this.$http("index.php?act=store_demand&op=demand_verify",{

            //     dem_id:this.dem_id,
            //     ...this.verifyData
            // -- ./`_`\../`_`\ 
            // })
            console.log("res",res)
            
            this.$suMsg("回复成功")
            for(let k in this.verifyData){
                this.verifyData[k] = ""
            }
            this.getList()
            this.tempNum = 1
        },
        allChange(flag){
            this.demandList.forEach(item=>{
                this.$set(item,"choose",flag)
            })
        }
    },
    created(){
        this.getList()


    },
    watch:{
        "$store.state.pageNavHis":{
            deep:true,
            handler(pageNavHis){
                this.demandData = pageNavHis.demandData
                this.verifyData = pageNavHis.verifyData
                this.tempNum = pageNavHis.tempNum
            }
        }
    }
}
</script>

<style lang="scss">
.my-demand{
    background: #fff;
    padding: $leftPad;
    .search-box{
        .el-input__inner{
            border-radius: 0;
            
        }
    }
    .de-title{
        padding: 10px;
        background: #efefef;
        i{
            color: cornflowerblue;
            font-size: 16px;
        }
    }
    .reBtn{
        padding: 10px;
    }
    .damand-table{
        border: 1px solid #ccc;
        border-left: 0;
        border-right: 0;
        .table-head{
            background: #F7F7F7;
            margin-bottom: 10px;
            height: 38px;
            line-height: 38px;
            text-align: center;
            display: flex;
            .check{
                min-width: 40px;
                max-width: 40px;
            }
            >div{
                flex: 1;
            }
            .handle{
                min-width: 150px;
                max-width: 150px;
            }
        }
        .table-body{
            .table-row{
                display: flex;
                border-top: 1px solid #ccc;
                >div{
                    padding: 8px 0;
                    // padding: 8px 5px;
                    text-align: center;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    flex: 1;
                }
                .check{
                    min-width: 40px;
                    max-width: 40px;
                }
                .handle{
                    display: flex;
                    min-width: 150px;
                     max-width: 150px;
                    justify-content: center;
                    
                    >div{
                        cursor: pointer;
                        color: #3A82D4;
                        margin:  0 5px;
                    }
                    .shu{
                       
                        cursor: default;
                        height: 80%;
                        width: 1px;
                        transform: translateY(1px);
                        background: #CCCCCC;
                    }
                }
            }
        }
    }
    .betcHandle{
        padding: 10px 5px;
        display: flex;
        align-items: center;
        color: #3A82D4;
        label{
            margin-left: 8px;
        }
        .betchDel{
            margin: 0 8px;
            cursor: pointer;
        }
    }
    // 需求详情
    .demandInfo{
        .container-box{
            margin-top: 60px;
            .title{
                font-size: 18px;
                color:rgba(58,130,212,1);
                height: 40px;
                text-align: center;
                line-height:40px;
                background: #FCF9FC;
                border: 1px solid #ccc;
            }
            .body{
                .item{
                    border: 1px solid #ccc;
                    border-top: none;
                    display: grid;
                    grid-template-columns:150px 1fr;
                    >div{
                        padding: 8px 0;
                    }  
                    .label{
                        font-size: 15px;
                        text-align: center;
                        border-right: 1px solid #ccc;
                    }
                    .value{
                        padding-left: 40px;
                        padding-right: 40px;
                    }
                    .img-con{
                        padding: 7px;
                        .img-box{
                            width: 110px;
                            img{
                                width: 100%;
                            }
                        }
                    }
                    .bz{
                         padding-left: 40px;
                         line-height: 20px;
                         >div{
                            
                         }
                         .row{
                             display: flex;
                             div:first-child{
                                  width: 600px;
                                  white-space: nowrap;
                                  overflow: hidden;
                                  text-overflow: ellipsis;
                                  margin-right: 20px;
                             }
                         }
                    }
                }
            }
        }
        .btn-box{
            padding: 20px;
            text-align: center;
        }
    }
   
}
</style>