<template>
<div class="VinFill">
    <div class="vin-fill-head">
        <div class="head-left">
            <img src="../../assets/catalog/logo.png" alt="" class="logo">
            <img src="../../assets/catalog/fillImg/fillHead.png" alt="" class="log-text">
            <div class="reback-btn" @click="reback">
                返回首页
            </div>
        </div>
        <div class="head-right">
            <div class="title">
                <div class="vin-day" v-if="$store.state.vin_over_time">
                     剩余免费查询天数
                    <span class="red">
                        {{$store.state.vin_over_time}}
                    </span>
                </div>
                <div class="query-num" v-else>
                
                    <div class="num-all">
                        总次数 ：{{$store.state.vinTotal}}
                    </div>
                    <div class="num-left">
                        剩余 ：<span>{{$store.state.vinNum}}</span>
                    </div>
                    
                </div>
               
            </div>
                          
        </div>
    </div>

    <div class="tip">
        <div>用户须知:</div>
        <div>
            为方便广大会员使用汽配供应链专家平台，车架号查询的数据实时对接于市场上主流的第三方数据公司，我们对数据的正确性及查询产生的结果不做负责！为了不影响您体验此项功能，推荐使用谷歌浏览器。
        </div>
    </div>
    <div class="Box">
           <div class="fill-body">
               <div class="fill-title">
                   套餐详情
               </div>
               <div class="fill-meal-list">
                   <div class="list-head">
                       <div class="head-item">
                           套餐序号
                       </div>
                       <div class="head-item">
                           查询次数
                       </div>
                       <div class="head-item">
                           价格
                       </div>
                       <div class="head-item">
                           选择
                       </div>
                   </div>
                    <div class="list-body">
                        <div class="meal-row" v-for="(meal,index) in mealList" :key="index">
                            <div>{{meal.meal_name}}</div>
                            <div>{{meal.meal_strip}}条</div>
                            <div>￥{{meal.meal_money}}</div>
                            <div><el-checkbox v-model="meal.choose" @change="mealChange(meal)"></el-checkbox></div>
                        </div>
                    </div>
               </div>
              
           </div>
           <div class="payOption">
               <div>开通账号:{{$store.state.member_name}}</div>
               <div>应付金额{{seleItem.meal_money}}</div>
           </div>
           <div class="ewm-list">
               <div class="Ewm-item">
                   <div class="title">
                        <img src="../../assets/catalog/fillImg/wx.png" alt="">
                        请使用微信扫码支付
                   </div>
                    <img class="wx-img" :src="wxEwm" alt="" v-if="showEwm" >
                  
               </div>
               <div class="Ewm-item">
                   <div class="title">
                        <img src="../../assets/catalog/fillImg/zfb.png" alt="">
                        请使用支付宝扫码支付
                   </div>
                    <img  class="zfb-img" :src="zfbEwm" alt="" v-if="showEwm">
                  
               </div>
           </div>
           <!-- <div class="payOption">
               <div class="optionName">
                   购买清单
               </div>
               <div class="fill-meal-list">
                   <div class="list-head">
                       <div class="head-item">
                           套餐序号
                       </div>
                       <div class="head-item">
                           查询次数
                       </div>
                       <div class="head-item">
                           价格
                       </div>
                       
                   </div>
                    <div class="list-body">
                        <div class="meal-row">
                            <div>{{seleItem.meal_name}}</div>
                            <div>{{seleItem.meal_strip}}条</div>
                            <div>￥{{seleItem.meal_money}}</div>
                            
                        </div>
                    </div>
               </div>
               <div class="footer-total">
                   您共需支付 ￥{{seleItem.meal_money}}元
               </div>
           </div> -->

           <!-- <div class="payOption payOption2">
               <div class="optionName">
                   选择支付方式
               </div>
               <div class="payWay">
                   <div class="way-item" v-for="(way,index) in wayList" :key="index" >
                        <el-checkbox v-model="way.choose" @change="wayChange(way)" :disabled="way.name=='微信'"></el-checkbox>
                        
                        <img :src="way.img" alt="">
                   </div>
               </div>
                <div class="payMoney">
                    <div class="price">
                        应支付金额：
                        <span>{{seleItem.meal_money}}</span>
                        元
                    </div>
                    
                    <img :src="payImg" v-if="payImg" alt="">
                </div>
           </div> -->
           <!-- <el-button type="primary" @click="reback" size="mini" class="backBtn">返回</el-button> -->
           <div>
                <el-dialog
                    :visible.sync="dialogVisible"
                    width="30%"
                    center
                    :modal-append-to-body="false"
                >
                    <div class="suHint">
                        <div class="suIcon">
                            <i class="el-icon-check"></i>
                        </div>
                        <span>
                            恭喜你，支付成功！
                        </span>
                    </div>
                    <span slot="footer" class="dialog-footer">
                        <el-button type="primary" @click="affPay" size="mini">确 定</el-button>
                    </span>
                </el-dialog>
           </div>
      
    </div>
</div>
</template>

<script>
export default {
    data(){
        return{
            tempNum:1,
            clickNum:0,
            dialogVisible:false,
            mealList:[
               
            ],
           
            payImg:"",
            inverId:null,
            wxEwm:"",
            zfbEwm:"",
            showEwm:true,
            wayList:[
                {
                    name:"支付宝",
                    choose:true,
                    img:require("../../assets/catalog/alipay.png")
                },
                {
                    name:"微信",
                    choose:false,
                    img:require("../../assets/catalog/wechat.png")
                },
            ]
        }
    },
    methods:{

        mealChange(meal){
            
            if(meal.choose){
                this.mealList.forEach(me=>{
                    if(me.meal_name!=meal.meal_name){
                        this.$set(me,'choose',false)
                    }
                })
            }
            this.getQr()
        },
        wayChange(way){
            if(way.choose){
                this.wayList.forEach(way1=>{
                    if(way.name!=way1.name){
                        this.$set(way1,'choose',false)
                    }
                })
            }
        },
        async getQr(){
            this.showEwm = false
            if(this.inverId){
                clearInterval(this.inverId)
                this.clickNum = 0
            }

            let res = await this.$api.getAlipayEwm({
                ...this.seleItem,
                store_id:this.$store.state.store_id,
                store_name:this.$store.state.store_name,
                member_id:this.$store.state.member_id,
                member_name:this.$store.state.member_name
            })
            if(res){
                this.zfbEwm = res.url
                this.wxEwm = res.wx
                this.showEwm = true
                // this.zfbEwm = re.zfb
                let ms = 1
                this.inverId = setInterval(async ()=>{
                    let res1 = await this.$api.getMealOk({
                        out_trade_no:res.outTradeNo
                    })
                    
                    ms++
                    if(res1.pay_state==1){
                        this.dialogVisible = true
                        this.$store.dispatch("getVinNum")
                        clearInterval(this.inverId)
                        this.clickNum = 0
                    }else{

                        if(ms>600){

                            clearInterval(this.inverId)
                            this.$erMsg("支付超时，二维码已失效")
                            this.tempNum = 1
                            this.clickNum = 0
                        }
                    }
                },1000)
                
            }else{
                this.$erMsg("二维码拉取失败")
            }

        },
        
        reback(){
            
            if(this.tempNum==1){  
                this.$emit("hideFill")
            }else{
                this.tempNum=1
                clearInterval(this.inverId)
                this.clickNum = 0
            }
        },
        affPay(){
            this.dialogVisible = false
            this.tempNum=1
        }
    },
    computed:{
        seleItem(){
            return this.mealList.find(meal=>meal.choose) ||{}
        }
    },
	beforeDestroy(){
	    clearInterval(this.inverId)
        this.clickNum = 0
	},
    async created(){
        // this.$http("index.php?act=vin_meal&op=vin_meal") 
        this.mealList = await this.$api.getMeaList()
        this.mealList.forEach((item,index)=>{
            if(index==0){
                this.$set(item,"choose",true)
                this.getQr()
            }
        })

    }
}
</script>

<style lang="scss">
.VinFill{
   
    background: #fff;
    flex:1;
    // padding: 0 150px;
    box-sizing: border-box;
    .vin-fill-head{
       
        display: flex;
        height: 1.81818rem;
        overflow: hidden;
        align-items: center;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        .head-left{
            padding-left: .606061rem /* 20/33 */;
            width: 50%;
            display: flex;
            align-items: center;
            // line-height: 1.818182rem /* 60/33 */;
            >img{
                padding-left: .181818rem /* 6/33 */;
                vertical-align: middle;
            }
            .logo{
                width: 1.939394rem /* 64/33 */;
            }
            .logo-text{
                width: 6.969697rem /* 230/33 */;
            }
            .reback-btn{
                padding: 5px;
                cursor: pointer;
                border-radius: 5px;
                margin-left: 10px;
                border: 1px solid #3E84D6;
                color: #3E84D6;
            }
        }
        .head-right{
             display: flex;
                width: 50%;
                align-items: center;
                justify-content: flex-end;
                padding-right: 1.212121rem /* 40/33 */;
            .title{
                font-size: 22px;
               
                color: #3E84D6;
                text-align: center;
                display: flex;
                justify-content: flex-end;
                .query-num{
                    display: flex;
                    align-items: center;
                    flex-shrink: 0;
                    font-size: 12px;
                    
                    >div{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        box-sizing: border-box;
                    }
                    .num-all{
                    
                        padding: 5px 10px;
                        border: 1px solid #ccc;
                        
                    }
                    .num-left{
                    
                        border: 1px solid #ccc;
                        padding: 5px 10px;
                        span{
                            color: red;
                        }
                    }
                    
                }
                .vin-day{
                    font-size: .484848rem /* 16/33 */;
                }
            }
        }
    }
    .tip{
        background: url("../../assets/catalog/fillImg/tip.png");
        height: 4.242424rem /* 140/33 */;
        width: 100%;
        padding: .606061rem /* 20/33 */ 0;
        box-sizing: border-box;
        >div{
            width: 1200px;
            margin: 0 auto;
            font-size: .606061rem /* 20/33 */;
            color: #fff;
            padding: 0 4.242424rem /* 140/33 */;
            padding-top: .30303rem /* 10/33 */;
        }
    }
   
    .Box{
        width: 1200px;
        margin: 0 auto;
        .el-checkbox{
            .el-checkbox__inner{
                border-radius: 50%;
            }
        }
        
        .fill-meal-list{
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.16);
            .list-head{
                display: flex;
                // background: $head-gray;
                background: #3E84D6;
                color: #fff;
                height: 30px;
                line-height: 30px;
                text-align: center;
                .head-item{
                    flex: 1;
                }
            }
            .list-body{
               
                border-top: none;

                .meal-row{
                    display: flex;
                    border-bottom: 1px dashed #ccc;
                    >div{
                        flex: 1;
                        text-align: center;
                        color: red;
                        padding: 10px 0;
                    }
                    >div:first-child{
                        color: #333;
                    }
                    
                }
                .meal-row:last-child{
                    border-bottom: none;
                }
            }
        }
        
        .fill-body{
           .fill-title{
               text-align: center;
               color: #3E84D6;
               font-size: .727273rem /* 24/33 */;
               padding: .606061rem /* 20/33 */ 0;
           }
            
        }
        .footer-btn{
            display: flex;
            justify-content: center;
            padding: 30px 0;
            position: relative;
            >div{
                padding: 11px 30px;
                cursor: pointer;
                color: #fff;
                // background: #3E84D6-text-color;
                background: #3E84D6;
            }
            .backBtn{
                position: absolute;
                top: 0;
                left: 0;
            }
        }
        .payOption{
            display: flex;
            padding: .454545rem /* 15/33 */ 0;
           >div{
               margin-right: 10px;
           }
        }
        .ewm-list{
            display: flex;
            padding: 1.212121rem /* 40/33 */ 0;
            justify-content: center;
            .Ewm-item{
                flex: 1;
                text-align: center;
                .title{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding-bottom: .6rem /* 24/33 */;
                    img{
                        width: .848485rem /* 28/33 */;
                        margin-right: 5px;
                    }
                    
                }
                .wx-img{
                    border: 1px solid #09BB07;
                    border-radius: 3px;
                }
                .zfb-img{
                    border: 1px solid #09AAF0;
                    border-radius: 3px;
                }
            }
        }
        .backBtn{
            margin-left: 20px;
            margin-top: 10px;
        }
        
        .suHint{
            // border: 1px solid #ccc;
            width: 150px;
            height: 150px;
            margin: 0 auto;
            text-align: center;
            padding-top: 30px;
            box-sizing: border-box;
            .suIcon{
                background: #60D44B;
                width: 43px;
                height: 43px;
                border-radius: 50%;
                text-align: center;
                margin: 0 auto;
                margin-bottom: 10px;
                i{
                    line-height: 43px;
                    color: #fff;
                    font-size: 28px;
                }
            }
            span{
                text-align: center;
                // color: #3E84D6-text-color;
                color: #3E84D6;
            }
        } 
    }

}
</style>