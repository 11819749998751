<template>

	<div class="hnw-home">
		
		<div class="hnw-area-center">
			
			<div class="banner-user">
				
				
				<div class="bannder">
					
					<el-carousel height="360px">
						<el-carousel-item v-for="(item,index) in banner" :key="index" >
							<img :src="item.img" alt="" @click="bannerJump(item)">
						</el-carousel-item>
					</el-carousel>
				</div>
				<div class="user">
					<div class="user-info" >
							
						<!-- logo_url|| -->
						<img :src="member_avatar||require('../../assets/index/home/face.svg')" alt="" class="logo-face">
						
						<div class="signature">
							
							<div>
								{{ company_name_jc}}
							</div>
							{{ token?store_id?sc_name:'C-个人':'Hi,您好' }}
						</div>
						<!---->
						<div class="handle" >
							<template v-if="!token">
								<div class="active" @click="$router.push('/login')">登录</div>
								<div class="split"></div>
								<div @click="$router.push('/Register')">注册</div>
	
							</template>
							<div class="re-btn" @click="$router.push('/Register')"  v-else-if="!store_id" >{{$store.state.authText}}</div>
						</div>
					</div>
					<!-- @click="$router.push('/brand')" -->
					<ul class="brand-list">
						<li  @click="readBrandInfo('法雷奥')">
							<img class="fla" src="../../assets/index/home/logo/法雷奥1.jpg" alt="">
						</li>
						<li @click="readBrandInfo('索恩格')">
							<img src="../../assets/hnwbrandlogo/索恩格.jpg" alt="" >
						</li>
						<li  @click="readBrandInfo('德尔福')">
							<img class="def" src="../../assets/index/home/logo/def.jpg" alt="">
						</li>
						<li  @click="readBrandInfo('康迪泰克')">
							<img class="kdtk" src="../../assets/hnwbrandlogo/康迪泰克.jpg" alt="">
						</li> 
						<li  @click="readBrandInfo('海拉')">
							<img class="cef" src="../../assets/hnwbrandlogo/海拉.jpg" alt="">
						</li>
	
					</ul>
				</div>
	
			</div>
			<!-- 大家一起来集单-->
			<div class="index-collect-module" v-if="nowCollectList.length>0">
				<div class="module-tit">
					<div class="tit-left1">
						<div class="chinese1">
							集单
						</div>
					</div>
					<div class="tit-more" @click="$router.push('/purchase/index')">
						更多 <i class="el-icon-arrow-right"></i>
					</div>
				</div>
				
				<div class="collct-content">
					
						<div class="collect-card" v-for="(collect,index) in nowCollectList" :key="index" :class="{'no-border':(index+1)%4==0}">
							<div class="img-box" @click="tododetail(collect)">
								<img :src="collect.goods_image" alt="">
							
							</div>
							<div class="card-info">
								<div class="goods-msg">
									<div class="tit">
										{{collect.setlist_name}}
										<span class="subsidiary-tit">{{collect.remark}}</span>
									</div>
									<div class="goods-coll-msg">
										<img src="../../assets/commom/75.png" alt="">
										<span style="padding-right: 20px;">
										{{collect.buyer_count}}
										</span>
										<img src="../../assets/commom/qian.png" alt="">
										{{collect.totalsum_now}}
										
									</div>

									<div class="rote-box" >
										<span v-if="collect.isNow">
											已售:{{ collect.rote }}%
										</span>
										<!--ele 百分比不允许超过100 -->
										<el-progress :percentage="collect.rote>100?100:collect.rote" :format="()=>''" v-if="collect.isNow"></el-progress>
									</div>

								</div>
								<div class="btn-operation-box">
										<div class="btn-operation" @click="tododetail(collect)">
											{{collect.isNow?'集单进行中':'集单未开始'}}
										</div>
								</div>
							</div>
						</div>
						
					<!-- <el-row :gutter="6" justify="space-between">
						<el-col :span="6" v-for="(o, index) in 4" :key="index" >
							<div class="collect-card">
								<div class="img-box">
									<img src="../../assets/temp/jdgoods.png" alt="">
								
								</div>
								<div class="card-info">
									<div class="goods-msg">
										<div class="tit">
											博士进口雨刮精
										</div>
										<div class="goods-coll-msg">
											<img src="../../assets/commom/75.png" alt="">
											255
											<img src="../../assets/commom/qian.png" alt="">
											10000
											
										</div>
										<div class="rote-box">
											<span>
												已完成:60%
											</span>
												<el-progress :percentage="60" :format="()=>''"></el-progress>
										</div>
									</div>
									<div class="btn-operation-box">
											<div class="btn-operation">
												集单开始
											</div>
									</div>
								</div>
							</div>
						</el-col>
					</el-row> -->
				</div>
			</div>
			<!-- 秒杀 -->
			<div class="seckill" v-if="false">
				<div class="module-tit"  v-if="seckiList.length>0">
					<div class="tit-left">
						<div class="chinese">
							<img src="../../assets/index/index/seckill.png" alt="">
							秒杀
						</div>
						<!-- Collection list -->
					</div>
					<div class="tit-more" >
						<!-- @click="$router.push('/purchase/index')" -->
						<!-- 更多 <i class="el-icon-arrow-right"></i> -->
					</div>
				</div>
				<swiper :options="swiperOption" ref="mySwiper">
					<!-- slides -->
					<swiper-slide v-for="(item,index) in seckiList" :key="index" >
						<!-- <div class="seckill-item" > -->
						<div class="seckill-img" @click="goseckill(item)">
							<img :src="item.groupbuy_image" alt="">
							<div class="count-down" v-if="item.state==20">
								<div class="time-of-day" >
									<div class="time-size">
									{{item.leftDay}}
									</div>
									<div class="time-color">天</div> 
								</div>
								<CountDwon bgrColor="#fff" :splitorText="splist" :bor="false" marginTop="6px" splitorColor="#fff" fontColor="#FF2020" fontSize="14px" :timer="item.leftTime" minWidth="19px" width="19px"></CountDwon>
							</div>
							<div class="count-down1" v-if="item.state==40">
								 {{$hnwTools.formatDateTime(item.start_time,'dhms')}}
								 开始
								
							</div>
							<div class="count-down2" v-if="item.state==32">
								<!-- <img src="../../assets/index/index/countdown1.png" alt=""> -->
								<div class="timekeeping-text">已结束</div>
							</div>
						</div>
						<div class="seckill-info">
							<div class="seckill-name">
								{{item.groupbuy_name}}
							</div>
							<div class="seckill-quantity">
								<!-- 限量{{item.buy_total}}件 -->
								限量{{item.goods_storage}}件
							</div>
							<div class="seckill-schedule">
								<div class="progress-body">
									<div class="progress1" :style="{width:item.buy_quantity/item.buy_total*100+'%'}"></div>
								</div>
									<div class="sold">已售 {{item.buy_quantity}} 件</div>
							</div>
							<div class="seckill-price" @click="goseckill(item)">
								<div class="blue" v-if="!$store.state.member_id" >
									会员可见
								</div>
								<div v-else class="price">
									<div style="font-size: 14px; color: #FF2020; margin-top: 2px;">￥</div>
									<div class="current"> {{item.groupbuy_price}}</div>
									<div class="original">￥{{item.goods_price}}</div>
								</div>
								<div class="seckill-btn" v-if="item.state==20">马上抢</div>
								<div class="seckill-btn" v-if="item.state==40">未开始</div>
							</div>
						</div>
					<!-- </div> -->
					</swiper-slide>
					<!-- <swiper-slide>I'm Slide 2</swiper-slide> -->
					<!-- Optional controls -->
					<div class="swiper-pagination"  slot="pagination"></div>
					<div class="swiper-button-prev" slot="button-prev"></div>
					<div class="swiper-button-next" slot="button-next"></div>
					<!--<div class="swiper-scrollbar"   slot="scrollbar"></div>-->
				</swiper>
				
			</div>
			<!-- 精品热销 -->
			<div class="store-content" v-if="hotGoodsList.length>0">
				<div class="module-tit">
					<div class="tit-left">
						<div class="chinese">
							<img src="../../assets/index/index/hotrecommend.png" alt="">
							精品热销
						</div>
						
					</div>
				</div>
				<div class="goods-list">
					<el-row  :gutter="8">
					<el-col :xs="12" :sm="12" :lg="{span: '4-8'}" v-for="(item,index) in hotGoodsList" :key="index" class="goods-col">

						<div class="goods-card" @click="goRecommend(item)">
							<div class="img-box" >
								
								<img :src="item.homeImg" alt="">
							</div>	
							<div class="card-fot">
								<div class="fot-bot-price" >
									<div class="showPrice blue" v-if="!$store.state.member_id" >
										会员可见
									</div>
									<div v-else class="showPrice">
										<div style="font-size:14px; color:#FF2020; padding-top: 8px;">￥</div>
										<div class="Price">{{item.goods_price}}</div>
										<div class="salePrice">￥{{$hnwTools.times(item.goods_price,1.2)}}</div> 
									</div>
								</div>
								<div class="fot-top">
									<span class="goods-name">{{item.goods_name}}</span>
									<div class="joinCart" @click.stop="joinCart(item)">
										<img src="../../assets/index/shop.png" alt="">
									</div>
								</div>
								<div class="fot-bot">
									<div class="fot-right">
										<div class="store-name" >
											{{item.oenaturebrand}}
										</div>
										<div class="store-oe">
											{{item.oecode}}
										</div>
									</div> 
									<div class="store-inventory">
										<span v-if="$store.state.look_inv==1">还剩{{item.goods_storage}}</span>
										<span v-else>{{item.goods_storage>0?"有货":"无货"}}</span>
										
									</div>
								</div>
							</div>
						</div>
					</el-col>
					
					</el-row>
				</div>
			</div>
	</div>
			<div class="store-content xscx-content">
				<img class="backimg" src="../../assets/index/newHome/限时促销.png" alt="" srcset="">
				<div class="to-list-btn" @click="$router.push('/index/disgoods')">

				</div>
				<div class="goods-list">
					<div  class="goods-card" v-for="(item,index) in disGoodsList" :key="index"  @click="goRecommend(item)">
						
						<div class="img-box" >
							<!-- ||item.goods_image -->
							<HnwOssImg :src="item.homeImg" :width="140"></HnwOssImg>
						</div>
						<div class="dis-good-tips">
							{{ item.roi*10 }}折
						</div>
						<div class="card-fot">
							<div class="fot-top"> 
								<span class="goods-name">{{item.goods_name}}</span>
								
							</div>
							<div class="fot-bot-price" >
								<div class="showPrice blue" v-if="!$store.state.member_id" >
									会员可见
								</div>
								
								<div v-else class="showPrice">
									<div style="font-size:14px; color:#FF2020; padding-top: 8px;">￥</div>
									<div class="Price">{{item.goods_marketprice}}</div>
                                    <div class="salePrice">￥{{item.goods_price}}</div> 
								</div>
							</div>
							
							
						</div>
					</div>
					
				</div>
			</div>
	<div class="hnw-area-center">
			<!-- 商品分类 -->
			<div class="store-content cpfl-content" >
				
				<div class="goods-list">
					<div class="goods-list-content" >
						<img src="../../assets/index/newHome/biansuxiang.png"   @click="findGoods('变速箱系统')"  alt="" srcset="">
						<img src="../../assets/index/newHome/dipanxitong.png" @click="findGoods('底盘悬挂系统')"  alt="" srcset="">
						<img src="../../assets/index/newHome/事故碰撞件.png" @click="findGoods('事故碰撞件')"  alt="" srcset="">

					</div>
				</div>
			</div>
			<div class="store-content jfsc-content">
				<div class="to-list-btn" @click="$router.push('/integral')"></div>
				<div class="goods-list">
					
					<div class="goods-card"  @click="toInteGoodsInfo(item)" v-for="(item,index) in inteGoodsList" :key="index" >
						<div class="img-box" >
							<img :src="item.images[0]" alt="">
						</div>
						<div class="card-fot">
							<!-- 价格和仓位 -->
							<div class="pri_warehouse">
								<div class="price">
									<div class="red">
										<div class="num">
											{{ item.price }}
										</div>
										<div class="jf">
											积分
										</div>
									</div>
									<div class="old-price">
										￥ {{item.old_price||"暂无原价"}}
									</div>
								</div>
								<div class="ware">
									限量 {{item.stock}} 件
								</div>
							</div>
							<div class="goods-name">
								<el-tooltip class="item" effect="dark" :content="item.name" placement="bottom">
									<span>{{item.name}}</span>
								</el-tooltip>
								
							</div>
							
						</div>
					</div>
					
				</div>
			</div>
			
			<div class="brand-content" v-for="(brand,index) in homeBrandList" :key="index">
				<div class="brand-left" @click="comSearchBrand(brand.title)">
					<!-- <img  :src="require(`../../assets/index/newHome/${brand}.png`)"  alt="" srcset=""> -->
					<img  :src="brand.image"  alt="" srcset="">
				</div>
				<div class="goods-list">
					<!-- {{ brand.title }} -->
					<div  class="goods-card" v-for="(item,index) in homeBrandGoodsList[brand.title]" :key="index"  @click="goRecommend(item)">
						<div class="img-box" >
							<!-- ||item.goods_image -->
							<HnwOssImg :src="item.homeImg" :width="140"></HnwOssImg>
						</div>
						
						<div class="card-fot">
							<div class="fot-top"> 
								<span class="goods-name">{{item.goods_name}}</span>
								
							</div>
							<div class="fot-bot-price" >
								<div class="showPrice blue" v-if="!$store.state.member_id" >
									会员可见
								</div>
								
								<div v-else class="showPrice">
									<div style="font-size:14px; color:#FF2020; padding-top: 8px;">￥</div>
									<div class="Price">{{item.goods_price}}</div>
									<div class="salePrice">￥{{$hnwTools.times(item.goods_price,1.2)}}</div> 
								</div>
							</div>
							
							
						</div>
					</div>
					
				</div>
			</div>
			<!-- 推荐品牌 -->
			<div class="store-content" v-if="brandList.length>0">
				<div class="module-tit">
					<div class="tit-left">
						<div class="chinese">
							<img src="../../assets/index/index/brandrecommend.png" alt="">
							推荐品牌
						</div>
						
					</div>
				</div>

				<div class="goods-list">
					<div class="goods-list-content"  @mouseenter="enterGoodsColList" @mouseleave="leaveGoodsColList">
						<div class="brand-list">
							<div class="brand-img"> 
								<div class="img-item" :class="{'active':activeBrandItem==item}" v-for="(item,index) in brandList" :key="index" @click="getBrand(item)">
									<img v-if="item=='采埃孚'" style="height:76px;margin-top: -13px;" :src="require(`../../assets/hnwbrandlogo/${item}.jpg`)" alt=""> 
									<img v-else-if="item=='HNW'" style="height:52px;margin-top: 0px;" :src="require(`../../assets/hnwbrandlogo/${item}.jpg`)" alt=""> 
									<img v-else :src="require(`../../assets/hnwbrandlogo/${item}.jpg`)" alt=""> 
								</div>   
							</div> 
						</div>
						<div class="great-than" v-if="showRight" @click="scrollRight">&gt;</div>
						<div class="less-than" v-if="showLeft" @click="scrollLeftItem">&lt;</div>
						<div class="goods-col-list">
							
							<div class="goods-col-all" v-for="(item,index) in brandGoodsListItem" :key="index">
								<div class="goods-card" @click="goRecommend(item)">
									<div class="img-box" >
										<!-- <img :src="item.homeImg||item.goods_image" alt=""> -->
										<img :src="item.homeImg" alt="">
										
									</div>
									<div class="card-fot">
										<div class="fot-bot-price" >
											<div class="showPrice blue" v-if="!$store.state.member_id" >
												会员可见
											</div>
											<div v-else class="showPrice">
												<div style="font-size:14px; color:#FF2020; padding-top: 8px;">￥</div>
												<div class="Price">{{item.goods_price}}</div>
												<div class="salePrice">￥{{$hnwTools.times(item.goods_price,1.2)}}</div> 
											</div>
										</div>
										<div class="fot-top">
											<span class="goods-name">{{item.goods_name}}</span>
											<div class="joinCart" @click.stop="joinCart(item)">
												<img src="../../assets/index/shop.png" alt="">
											</div>
										</div>
										<div class="fot-bot">
											<div class="fot-right">
												<div class="store-name" >
													{{item.oenaturebrand}}
												</div>
												<div class="store-oe">
													{{item.oecode}}
												</div>
											</div> 
											<div class="store-inventory">
												<span v-if="$store.state.look_inv==1">还剩{{item.goods_storage}}</span>
												<span v-else>{{item.goods_storage>0?"有货":"无货"}}</span>
												
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						
					</div>
					 
				</div>
				
			</div>


			<!-- 商品推荐列表 -->
			<div class="store-content" v-if="storeGoodsList.length>0">
				<div class="module-tit">
					<div class="tit-left">
						<div class="chinese">
							<img class="img-goods" src="../../assets/index/index/recommend.png" alt="">
							商品推荐
						</div>
						
					</div>
				</div>
				<div class="goods-list">
					<el-row  :gutter="8">
						<el-col :xs="12" :sm="12" :lg="{span: '4-8'}" v-for="(item,index) in storeGoodsList" :key="index" class="goods-col">

							<div class="goods-card" @click="goRecommend(item)">
								<div class="img-box" >
									<!-- <img :src="item.homeImg||item.goods_image" alt=""> -->
									<!-- <img :src="item.homeImg" alt=""> -->
									<!-- ||item.goods_image -->
									<HnwOssImg v-if="item.homeImg" :src="item.homeImg"></HnwOssImg>
								</div>
								<div class="card-fot">
									<div class="fot-bot-price" >
										<div class="showPrice blue" v-if="!$store.state.member_id" >
											会员可见
										</div>
										<div v-else class="showPrice">
											<div style="font-size:14px; color:#FF2020; padding-top: 8px;">￥</div>
											<div class="Price">{{item.goods_price}}</div>
											<div class="salePrice">￥{{$hnwTools.times(item.goods_price,1.2)}}</div> 
										</div>
									</div>
									<div class="fot-top">
										<span class="goods-name">{{item.goods_name}}</span>
										<div class="joinCart" @click.stop="joinCart(item)">
											<img src="../../assets/index/shop.png" alt="">
										</div>
									</div>
									<div class="fot-bot">
										<div class="fot-right">
											<div class="store-name" >
												{{item.oenaturebrand}}
											</div>
											<div class="store-oe">
												{{item.oecode}}
											</div>
										</div> 
										<div class="store-inventory">
											<span v-if="$store.state.look_inv==1">还剩{{item.goods_storage}}</span>
											<span v-else>{{item.goods_storage>0?"有货":"无货"}}</span>
											
										</div>
									</div>
								</div>
							</div>
						</el-col>
						
					</el-row>
				</div>
			</div>

			<div class="demand-content" v-if="false">
				<div class="module-tit">
					<div class="tit-left">
						<div class="chinese">
							<img src="../../assets/index/index/search.png" alt="">
							华诺威免费为您找件	  
						</div>
						
					</div>
				</div>

				<div class="demand-tab">
					<div class="tab">
						<div class="active">
							需求发布
						</div>
						<div class="split">/</div>
						<div @click="$router.push('/my/demand')">
							我要发布
						</div>
					</div>
					<!-- <div class="tab-more" @click="$router.push('/my/demand')">
						更多 <i class="el-icon-arrow-right"></i>
					</div> -->
				</div>
				<div class="demand-list">
					<div class="list-head">
						<div v-for="(label,index) in demandTableHead" :key="index" :class="{'dem-num':label=='需求数量'}">
							{{label}}
						</div>
					</div>

					 <!-- height="200px"   :autoplay="false" indicator-position="none" -->
					<el-carousel :height="demandH" arrow="never" :interval="5000"  @change="demaListChange"  :autoplay="false">
						<el-carousel-item v-for="i in demandTotal" :key="i" >
							<div class="list-body">
								<div class="body-row" v-for="(dema,index) in demandList" :key="index">
									<div class="col">
										{{dema.oecode}}
									</div>
									<div class="col">
										{{dema.sccode}}  
									</div>
									<div class="col">
										{{dema.goods_name}}  
									</div>
									<!-- dem-num -->
									<div class="col ">
										{{dema.oenaturebrand}}   
									</div>
									
									<div class="col">
										{{dema.member_name}}
									</div>

									<div class="col">
										{{dema.add_time}}		<!-- 用户 -->
									</div>
									<div class="col">
										{{$hnwTools.reDemandStateText(dema.state)}}		
									</div>
									<div class="col">
										<span class="btn" @click="readDemaInfo(dema)">
											查看详情
										</span>
									</div>
								</div> 
								
							</div>
						</el-carousel-item>
					</el-carousel>
					
				</div>
			</div>
		</div>
		<!-- 860 120 -->
		<el-dialog
			:visible.sync="demaInfoVis"
			class="dema-info-dis"
			width="860px"
			top="9vh"
			>
			
			    <div slot="title" class="dema-info-title">
					<i class="el-icon-edit-outline"></i>
					<span>
						需求发布
					</span>
				</div>
				
				<div class="dama-info-msg">
					<div class="msg-item">
						<div class="msg-label">
							OE号:
						</div>
						<div class="msg-value">
							{{demaInfo.oecode}}
						</div>
					</div>
					<div class="msg-item">
						<div class="msg-label">
							生产编码:
						</div>
						<div class="msg-value">
							{{demaInfo.sccode}}
						</div>
					</div>
					<div class="msg-item">
						<div class="msg-label">
							品牌:
						</div>
						<div class="msg-value">
							{{demaInfo.oenaturebrand}}
						</div>
					</div>
					<div class="msg-item">
						<div class="msg-label">
							车型:
						</div>
						<div class="msg-value">
							{{demaInfo.vehicletype}}
						</div>
					</div>
					<div class="msg-item">
						<div class="msg-label">
							需求数量:
						</div>
						<div class="msg-value">
							{{demaInfo.dem_num}}
						</div>
					</div>
					<div class="msg-item">
						<div class="msg-label">
							发布时间:
						</div>
						<div class="msg-value">
							{{demaInfo.add_time}}
						</div>
					</div>
					<div class="msg-item">
						<div class="msg-label">
							联系人:
						</div>
						<div class="msg-value">
							{{demaInfo.fullname}}
						</div>
					</div>
					<div class="msg-item">
						<div class="msg-label">
							电话:
						</div>
						<div class="msg-value">
							{{demaInfo.hidePhone}}
							
						</div>
					</div>
					<div class="msg-item">
						<div class="msg-label">
							地址:
						</div>
						<div class="msg-value">
							{{demaInfo.hideSite}}
						</div>
					</div>
					<div class="msg-item">
						<div class="msg-label">
							需求详情:
						</div>
						<div class="msg-value">
							{{demaInfo.parts_cri}}
						</div>
					</div>
					<div class="msg-item">
						<div class="msg-label">
							商品图片:
						</div>
						<div class="img-list">
							<img :src="img.img_url" alt="" v-for="(img,index) in demaInfo.img_url" :key="index">
						</div>
					</div>
				</div>
		</el-dialog>
		    <el-dialog
			:visible.sync="explVis"
			class="expl-info-dis"
			width="860px"
			top="9vh"
			>
			<div class="img-box">
				<img src="../../assets/index/exploit.png" alt="">
			</div>
	   </el-dialog>
	   
	</div>
</template>

<script>
    import { mapState } from "vuex" 
	import { swiper, swiperSlide } from 'vue-awesome-swiper'
	import 'swiper/dist/css/swiper.css'
	import HnwOssImg from "../../components/hnwOssImg.vue";
import router from "../../router";
	export default{
		data(){
			return{
				splist:['时','分','秒'],
				searchText:"",
				groupbuyId:'',
				nowCollectList:[],
				seckiList:[],
				prefoList:[],
				nowList:[],
				// 假的时间
				timer:"",
				// 当前页面导航的模式
				navModel:1,
				// 当前导航的标题
				nowNavTitle:"集中采购",
				swiperOption: { 
					notNextTick: true,
					loop:true,
					slidesPerView : 'auto',
					navigation: { //最新版本的需要这样配置，按钮才有效
						prevEl:'.swiper-button-prev',//上一张
						nextEl:'.swiper-button-next'//下一张
					}
				},
				demandList:[],
				demaInfo:{},
				demaInfoVis:false,
				demandTotal:1,
				demandH:"240px",
				
				explVis:false,
				// 中间的banner图部分
				banner:[
				],
				seckilList:[
					0,1,2,3,4
				],
				// 折扣列表
				disList:[],
				disGoodsList:[],
				// 展示商品
				disShowGoodsList:[],
				inteGoodsList:[],
				storeGoodsList:[],
				// 需求发布表格
				demandTableHead:["OE号","生产编码","配件名称","配件品牌","发布人","发布时间","状态","操作"],
				hotGoodsList:[],// 热销商品
				brandGoodsList:[],// 推荐品牌
				brandList:[],
				activeBrandItem:'',
				brandGoodsListItem:[],
				showRight:false,
				showLeft:false,
				hnwList:[],
				lucasList:[],
				ATIList:[],
				boschList:[],
				acfList:[],
				otherList:[],
				homeBrandList:["卢卡斯","华诺威","ATI","崇芳"],
				homeBrandGoodsList:[],
				showDiaVisGoods:"",
				showDiaVis:false
			}
		},

		methods:{
			// 鼠标移入时，根据数据的长度显示箭头
			enterGoodsColList(){
				if(this.brandGoodsListItem.length>4){
					this.showLeft = true;
					this.showRight = true
				}else{
					this.showLeft = false;
					this.showRight = false
				}
				
			},
			// 鼠标移出时，箭头消失
			leaveGoodsColList(){
				this.showLeft = false;
				this.showRight = false
			},
			// 点击左箭头向左移动
			scrollLeftItem(){
				let goodsColList = document.querySelector('.goods-col-list') 
				if(goodsColList.scrollLeft!=0){
					goodsColList.scrollLeft -= 240 
				}  
			},
			// 点击右箭头向右移动
			scrollRight(){
				let goodsColList = document.querySelector('.goods-col-list') 
				if(goodsColList.scrollWidth-goodsColList.scrollLeft>958){
					goodsColList.scrollLeft += 240 
				}  
				
			},
			// 筛选品牌
			getBrand(item){ 
				this.activeBrandItem = item
				this.brandGoodsListItem = this.brandGoodsList.filter(ite => ite.oenaturebrand==item)
				if(this.brandGoodsListItem.length>4){
					this.showLeft = true;
					this.showRight = true
				}else{
					this.showLeft = false;
					this.showRight = false
				}
			},
			readBrandInfo(brand){
				if(!this.token){
					this.$warMsg("请先去登录");
					return this.$router.push("/login")
				}
				if(this.$store.state.brands&&!this.$store.state.brands.includes(brand)){
					return this.$warMsg("无品牌权限");
				}
				if(this.$isCompany()){
					this.$router.push("/index/brand/"+brand)
				}
			},
			// 处理时间大于一天的情况和完成率，以及标记上是否可以购买
			handlerDay(){

				// 计算即将开始时间
				// (acti.totalsum_now*10000) / (acti.totalsum*10000*10000)
				// "overList"


				let arrs = ["prefoList","nowList","seckiList"]
				
				let key = ""
				let dayTime =  60*60*24*1000
				arrs.forEach(arr=>{

					if(arr=='prefoList'){
						key = "start_time"
						
					}else{
						key = "end_time"
					}
					try{

						this[arr].forEach(acti=>{
							let leftTime = 0
							if(arr=='prefoList'){
								
								acti[key] = acti[key]*1000
								
								leftTime =  acti[key] - new Date()
								acti.isNow = false
							}else{
								acti.isNow = true

								if(arr=='seckiList'){
									// 秒杀未开始/开始在同一数组 用状态判断
									if(acti.state==40){
										key = "start_time"
									}
									
								}
								if(key=='start_time'){
									leftTime =  acti[key]*1000 - new Date()
									
								}else{
									leftTime =  acti[key] - new Date()

								}
							}
												
							if(leftTime>dayTime){
								let leftDay = parseInt(leftTime/dayTime)
								this.$set(acti,"leftDay",leftDay)
								// 剩余总时间减去大于一天的天数
								
								this.$set(acti,"leftTime", acti[key] - leftDay*dayTime )
								
							}else{
								this.$set(acti,"leftTime",acti[key])
							}

						
							if(arr=='nowList'||arr=='overList'){
								// let rote = parseInt(acti.totalsum_now*100) / (acti.totalsum*100) *100
								
								let rote = ""
								if(Number(acti.totalsum)>0){
									rote = this.$hnwTools.div(acti.totalsum_now,acti.totalsum) *100
								}else{
									rote =  0 
								}
								this.$set(acti,"rote",Number(rote.toFixed(2)))
							}   
							// 标记
							if(arr=="overList"){
								this.$set(acti,"notbuy",'over')
							}else if(arr=='prefoList'){

								this.$set(acti,"notbuy",'pre')
							}  

						})
					}catch(e){
						// console.log(e);
						// console.log(arr);
					}
					
					
				})
			},
			findGoods(text){
				console.log("text",text);
				
				this.$store.commit('UpDateState',{
					searchOesort:text
				})
				this.$router.push('/find/index')
			},
			comSearchBrand(brand){
				
				localStorage.setItem("epc-brand-text",brand)
				
				this.$router.push("/brand")
				

			},
			toInteGoodsInfo(goods){
				if(this.$isCompany()){
					let rou = '/integral/info'
					if(this.$route.params.id){
						goods.preRou = "积分礼品"
						localStorage.setItem("hnw-web-item-data",JSON.stringify(goods))
						this.$emit("showInteInfo")
					}else{
						goods.preRou = "积分礼品"
						
						localStorage.setItem("hnw-web-item-data",JSON.stringify(goods))
						
						this.$router.push(rou)
					}
				}
			},
			async bannerJump(banner){ 
				if(!this.token){
					this.$warMsg("请先去登录")
					return this.$router.push("/login")
				}
				if(banner.url){
					let acti = this.nowCollectList.find(col=>col.setlist_id==140)
					if(acti){
						this.tododetail(acti)
					}else{
						this.$warMsg("暂无信息")
					}
					// 如果图片能跳转商品
				}else if(banner.goods_id){

					try {
						let jumpMsg = JSON.parse(banner.goods_id)
						if(jumpMsg.type=='goods'){
							const res = await this.$api.getGoodsInfo({
								goods_id:jumpMsg.data
							}) 
							this.goRecommend(res)
						}else if(jumpMsg.type=='brand'){

							this.$store.commit('UpDateState',{
								searchText:jumpMsg.data
							})

							this.$router.push('/find/index')
						}else if(jumpMsg.type=='link'){
							this.$router.push(jumpMsg.data)
						}else if(jumpMsg.type=='bodyImg'){ 
							localStorage.setItem("web-banner-body-img",jumpMsg.data)
							this.$router.push("/bodyImg")
						}else if(jumpMsg.type=='adcerImg'){
							let tempArr =  jumpMsg.data.split("&") 
							if(tempArr[1]&&tempArr[1]!='undefined'){
								localStorage.setItem("web-banner-body-img",jumpMsg.data.split("&")[1])
								this.$router.push("/bodyImg")
							}else if(tempArr[2]){
								this.$store.commit('UpDateState',{
									searchText:tempArr[2]
								}) 
								this.$router.push('/find/index')
							}
						}
					} catch (error) {
						// console.log("解goods_id信息发生错误",banner.goods_id);
					}
					
				}else if(banner.img.search('vin')!=-1){
					if(!this.token){
						this.$warMsg("未登录账号")
						return this.$router.push("/login")
						
					}else if(this.$isCompany()){
						let link = location.href.split("#")[0]
						// window.open(link + '#/catalog/home/vin')
						window.open(link + '#/epc/home/vin')
						// if(this.$store.state.shop==1780){
						// }else{
						// 	this.$warMsg("您的账号不能查看电子目录")
						// }
					}
				}
			},
			tododetail(acti,type){ 
				if(!this.token){
					this.$warMsg("请先去登录")
					return this.$router.push("/login")
				}
				if(this.$isCompany()){
					
					let rou = ''
					if(type){
						return this.explVis = true
						acti.preRou = "首页店铺商品"
						rou = "/index/goodsInfo"
						
					}else{
						acti.preRou = "首页"
						rou = "/index/goodsinfo"
					}
					
					localStorage.setItem("hnw-web-item-data",JSON.stringify(acti))
					
					this.$router.push(rou)
				}
				
			},
			goseckill(acti){ 
				if(!this.token){
					this.$warMsg("请先去登录")
					return this.$router.push("/login")
				}
				if(this.$isCompany()){
					acti.preRou = "首页"
					let rou = '/index/goodsinfo'
					localStorage.setItem("hnw-web-item-data",JSON.stringify(acti))
					
					this.$router.push(rou)
				}
			},
			goRecommend(acti){
				this.showDiaVis=false
				if(!this.token){
					this.$warMsg("请先去登录")
					return this.$router.push("/login")
				}
				if(this.$isCompany()){
					acti.preRou = "首页"
					if(acti.g_id){
						acti.goods_id = acti.g_id
					}
					let rou = '/index/goodsinfo'
					localStorage.setItem("hnw-web-item-data",JSON.stringify(acti))
					
					this.$router.push(rou)
				}
			},
			
			async joinCart(carItem){
				if(this.$isCompany()){
					if(carItem.goods_storage<1){
						return this.$store.commit('UpDateState',{invDia:true})
					}
					let res = ''
					
					let carMessage = {
						goods_id:carItem.goods_id,
						sccode:carItem.sccode,
						oecode:carItem.oecode,
						goods_name:carItem.goods_name,
						goods_price:carItem.goods_price,
						goods_storage:carItem.goods_storage,
						goods_num:1,
						store_id:carItem.store_id,
						store_name:carItem.store_name,
						cart_type:'1',
				
					} 
					res = await this.$api.joinCart([carMessage])
					
					// 跳转到加入成功页
					
				}
			},
			goBus(store_id){
				if(this.$isCompany()){
					this.$router.push(`/store/businesses/${store_id}`)
				}
			},
			
			// 切换模式
			switchModel(modelIndex){
				this.navModel = modelIndex
				switch(modelIndex){
					case 2:
					this.nowNavTitle = "集中采购"
					break;
					case 3:
					this.nowNavTitle = "限时秒杀"
					break;
					case 4:
					this.nowNavTitle = "找件儿"
					break;
					case 5:
					this.nowNavTitle = "商家店铺"
					break;
					case 6:
					this.nowNavTitle = "推广分成"
					break;
				}
			},
			callback(e){ 
			},
			// 获取需求列表
			async demaListChange(curpage){
				let res= await this.$api.getDemandList({
					all:"all",
					curpage:curpage+1,
					page:5
				})
				this.demandList = res.data
			},
			test(){ 
			},
			readDemaInfo(dema){

				if(this.$isCompany()){

					this.demaInfo = dema
					if(dema.telephone){
						this.demaInfo.hidePhone = dema.telephone.substr(0,3)+"****"+ dema.telephone.substr(7,dema.telephone.length)
					}
					this.demaInfo.hideSite= dema.re_address.substr(0,3)+"****"+ dema.re_address.substr(7,dema.re_address.length)
					this.demaInfoVis = true
				}
			},
			// 当点击去联系之后
			async connection(){
				let res = await this.$api.connectionDema(this.demaInfo.dem_id)
				if(res){
					this.$suMsg("获取信息成功")
					this.demaInfo.hidePhone = this.demaInfo.telephone
					this.demaInfo.hideSite = this.demaInfo.re_address
					this.demaInfo.state = 1
				}else{
					this.$erMsg("获取信息失败")
				}
			}
		},
		computed:{
			shopHidden(){
				return !this.$store.state.shopCart.length>0
			},
			// swiper() {
			//   return this.$refs.mySwiper.swiper
			// },
			...mapState(["company_name_jc","store_id","store_name","logo_url","member_avatar","sc_name","token"]),
		},
		mounted() { 
		},
		async created(){ 
			this.$api.getAnalysisLog("home")
			 // 首页防直营
			// 首页防直营
			// if(this.$store.state.for_branch&&this.$store.state.for_branch!=1780){
			// 	this.$store.dispatch("getThan")
			// 	this.$router.push(`/store/businesses/${this.$store.state.for_branch}`)
			// }else if(this.$store.state.shop&&this.$store.state.shop!=1780){ 
			// 	this.$store.dispatch("getThan")
			// 	this.$router.push(`/store/businesses/${this.$store.state.shop}`)
			// }

			// 版本升级
			let verRes = await this.$api.handleVer({
			model: 2,
			});
			let vers = localStorage.getItem("hnw-web-vers");
			if(vers!="undefined"){
			vers = JSON.parse(vers);

			if (vers != verRes) {
				localStorage.setItem("hnw-web-vers", JSON.stringify(verRes));
				location.reload();
			}
			}
			
			
			if(this.$store.state.for_branch&&this.$store.state.for_branch!=1780){
				await this.$store.dispatch("getThan")
				this.$router.push(`/store/businesses/${this.$store.state.for_branch}`)
			}else if(this.$store.state.shop&&this.$store.state.shop!=1780){ 
				await this.$store.dispatch("getThan")
				this.$router.push(`/store/businesses/${this.$store.state.shop}`)
			}
			
			
			
			let orgin = window.location.hostname 
			let storeInfo = this.$hnwTools.storeUrls.find(store=>store.url==orgin)
			if(storeInfo){ 
				if(storeInfo.id){
					return this.$router.push(`/store/businesses/${storeInfo.id}`);
				}else{
					console.log("当前网址未开通店铺!");
				}
			}

			let state = {
				token:""
			}
			// 采购自动登录
			if(this.$route.params.acc&&this.$route.params.acc!='self'){
				
				let acc = this.$hnwTools.mixStr(this.$route.params.acc).split("|") 
				let seller_name =  acc[0]
				let password =  acc[1]
				// 登录
				let res = await this.$api.login({
					seller_name,
					password
				})
				state = JSON.stringify(res)
				
				localStorage.removeItem("hnw-web-state")
				
				localStorage.setItem("hnw-web-state",state)

				this.$store.commit("UpDateState",JSON.parse(state))
				
			}
			this.nowList = await this.$api.getSetList({mark:1})
			
			this.prefoList = await this.$api.getSetList({mark:2})
			

			this.nowCollectList = this.nowList.concat(this.prefoList)
			this.nowCollectList = this.nowCollectList.filter(coll=>coll.is_vr==1)
			this.nowCollectList = this.nowCollectList.slice(0,4)
			
			
			let res= await this.$api.getDemandList({
				all:"all",
				curpage:1,
				page:5
			})
			 
			this.demandList = res.data

			// this.demaInfo = this.demandList[0]
			if(res.total==0){
				this.demandTotal = 1
				this.demandH = "0px"
			}else if(res.total<5){
				this.demandTotal = 1
				
			}else{
				this.demandTotal = parseInt( res.total / 5)
				let leftNum = res.total%5
				if(leftNum>0){
					this.demandTotal = this.demandTotal+1
				}
			}
			// 品牌 
			this.brandGoodsList = await this.$api.getlistRecom({
				goods_commend:6,
				store_id:1780
			})
			let indexRes = []
			let houseInfo = []
			if(this.$store.state.shop==1780){
				// Cang 
				// this.brandGoodsList = this.brandGoodsList.filter(item => item.house_info!=null)
				this.brandGoodsList.forEach((goods) => {
					this.$storageCangInfo(goods)
				}); 
			}
			
			// 排序，将华诺威 卢卡斯 ATI放在首位
			this.brandGoodsList.forEach(item => {
			if(item.oenaturebrand=='华诺威'){
				this.hnwList.push(item)
			}else if(item.oenaturebrand=='卢卡斯'){
				this.lucasList.push(item)
			}else if(item.oenaturebrand=='ATI'){
				this.ATIList.push(item)
			}else if(item.oenaturebrand=='博世'){
				this.boschList.push(item)
			}else if(item.oenaturebrand=='采埃孚'){
				this.acfList.push(item)
			}else{
				this.otherList.push(item)
			}
			}) 
			this.brandGoodsList = this.lucasList.concat(this.ATIList,this.hnwList,this.boschList,this.acfList,this.otherList)
			// console.log("this.$store",this.$store.state)
			// 获取图片
			// getlistRecomImg
			if(this.brandGoodsList.length>0){
				let ids= this.brandGoodsList.map(item=>item.goods_id)
				let goods_id = ids.join(",") 
				let listimgs = await this.$api.getGoodsListHomeImg({
					goods_id,
					goods_type: "推荐品牌",
					shop_id:1780,
					store_id:1780 
				}) 
				listimgs.forEach(item=>{
					this.brandGoodsList.forEach(goods=>{
						if(goods.goods_id==item.goods_id){
						this.$set(goods,"homeImg",item.url)
						}
					})
				})
			}
			// 将品牌放单独放在数组中
			this.brandGoodsList.forEach(item => {
				this.brandList.push(item.oenaturebrand)
			}) 
			this.brandList = Array.from(new Set(this.brandList))

			// console.log("this.brandList",this.brandList)

			this.brandGoodsList.forEach(goods=>{
                   
				goods.goods_price = this.$handlePrice(goods)
              
            })
			this.brandGoodsListItem = this.brandGoodsList

			// 热销
			this.hotGoodsList = await this.$api.getlistRecom({
				goods_commend:5,
				store_id:1780
			}) 
			if(this.$store.state.shop==1780){
				// Cang
				indexRes = []
				houseInfo = []
				// this.hotGoodsList = this.hotGoodsList.filter(item => item.house_info!=null)
				this.hotGoodsList.forEach((goods) => {
					this.$storageCangInfo(goods)
				}); 
			}
			
			if(this.hotGoodsList.length>0){
			let ids= this.hotGoodsList.map(item=>item.goods_id)
			let goods_id = ids.join(",") 
			// 获取图片
			// getlistRecomImg
			let listimgs = await this.$api.getGoodsListHomeImg({
				goods_id,
				goods_type: "商品热销",
				
				shop_id:1780,
				store_id:1780
			}) 
			listimgs.forEach(item=>{
				this.hotGoodsList.forEach(goods=>{
					if(goods.goods_id==item.goods_id){
					this.$set(goods,"homeImg",item.url)
					}
				})
			})
			} 
			
			this.hotGoodsList.forEach(goods=>{
                   
				goods.goods_price = this.$handlePrice(goods)
              
            })
			
			
			// 获取折扣列表
			this.disList = await this.$api.getDisList({
				type:1
			})
			// 品牌列表
			this.homeBrandList = await this.$api.getDisList({
				type:2
			})
			// 
			let listimgs = []
			this.homeBrandList.forEach(async item=>{
				let res = await this.$api.getDisList({
					type:2,
					id:item.id,
					page:1,
					limit:8
				})
				if(res.db&&res.db.length>0){
					listimgs = await this.$api.getGoodsListHomeImg({
						goods_id:res.db.map(item=>item.goods_id).join(","),
						goods_type:"品牌首图",
						shop_id:1780,
						store_id:1780 
					}) 
					listimgs.forEach(item=>{
						res.db.forEach(goods=>{
							if(goods.goods_id==item.goods_id){
								this.$set(goods,"homeImg",item.url)
							}
						})
					})
					this.homeBrandGoodsList[item.title] =  res.db
				}else{
					this.homeBrandGoodsList[item.title] = []
				}
			})

			// this.homeBrandList.forEach(async brand=>{
			// 	let  res = await this.$api.getBrandGoodsListNew({
			// 		sort_key: "oecode",
			// 		sort_value: "DESC",
			// 		page: "1",
			// 		limit: "8",
			// 		shop_id: "1780",
			// 		store_id: this.$store.state.store_id,
			// 		member_id: this.$store.state.member_id,
			// 		stock: "gt",
			// 		search:brand
            // 	})
			// 	try {
				
			// 		this.$set(this.homeBrandGoodsList,brand,res.data)
					  
			// 		this.homeBrandGoodsList[brand].forEach(goods=>{
			// 			goods.goods_price = this.$handlePrice(goods)						
			// 		})
					
			// 	} catch (error) {
					
			// 	}
			// 	console.log("this.homeBrandGoodsList",this.homeBrandGoodsList)
				
			// })

			this.disList = await this.$api.getDisList({
				type:2
			})
			// 获取折扣商品列表
			let disRes = await this.$api.getDisList({
				page:1,
				limit:4,
				id:"all",
				type:1,
				goods_commend:71
			})
			
			console.log("disGoodsList",this.disGoodsList);
			
			if(disRes.db.length>0){
				let goods_id = disRes.db.map(item=>item.goods_id).join(",")
				let listimgs = await this.$api.getGoodsListHomeImg({
					goods_id,
					goods_type: "折扣首图",
					shop_id:1780,
					store_id:1780 
				}) 
				listimgs.forEach(item=>{
					disRes.db.forEach(goods=>{
						if(goods.goods_id==item.goods_id){
							this.$set(goods,"homeImg",item.url)
						}
					})
				})
			}

			this.disGoodsList  = disRes.db
			// 积分推荐

			let InteRes = await this.$api.getIntegrallist({
                limit:5,
                page:1,
				data:2,
            })
            

           
            if(InteRes.data){
                InteRes.data.forEach(goods => {
                    if(goods.images){
                        // console.log(goods.images)
                        goods.images = JSON.parse(goods.images)
                    }
                })
				
                this.inteGoodsList = InteRes.data  ||[]
            } 
			console.log();
			
			// 品牌商品列表
			
			
			
			// 推荐
			this.storeGoodsList = await this.$api.getlistRecom({
				goods_commend:1,
				store_id:1780,
			})
			if(this.$store.state.shop==1780){
				// Cang
				indexRes = []
				houseInfo = []
				// this.storeGoodsList = this.storeGoodsList.filter(item => item.house_info!=null)
				this.storeGoodsList.forEach((goods) => {
					this.$storageCangInfo(goods)
				}); 
			}
			
			if(this.storeGoodsList.length>0){
				let ids = this.storeGoodsList.map(item=>item.goods_id)
				let goods_id = ids.join(",") 
				// 获取图片
				// getlistRecomImg
				let listimgs = await this.$api.getGoodsListHomeImg({
					goods_id,
					goods_type: "WEB推荐",
					// shop_id:this.$store.state.store_id,
					shop_id:1780,
					store_id:1780			
				})
				 
				listimgs.forEach(item=>{
					this.storeGoodsList.forEach(goods=>{
						if(goods.goods_id==item.goods_id){
							this.$set(goods,"homeImg",item.url)
							
						}
					})
				})
			} 
			
			this.storeGoodsList.forEach(goods=>{
                   
				goods.goods_price = this.$handlePrice(goods)
              
            })
			// goodprice1

			// this.banner = await this.$api.getHomeBanner()
			// https://b.huanuowei.cn/api/web/
			let bannerRes = await this.$api.getHomeBanner({
				source: 1
			})
			// 36823
			if(bannerRes){
				bannerRes.forEach(item=>{
					item.img=item.adv_pic
					// item.goods_id = '{"type":"brand","data":"火花塞"}'
				})
				
				this.banner = bannerRes.concat(this.banner)
			}
			this.seckiList = await this.$api.getSeckiList({mark:""})
			
			this.handlerDay() 
		},
		components:{
			swiper,
			swiperSlide,
			HnwOssImg
		}
	}
</script>

<style lang="scss" scoped>
	.hnw-home{
		// 每个模块的标题
		padding-top: 10px;
		background: $backGray;
		.blue{
			color: #3E84D6;
		}
		// padding-bottom: 10px;
		.module-tit{
			display: flex;
			justify-content: space-between;
			align-items: center;
			// padding: 40px 0 0 0;
			.tit-left{
				display: flex;
				align-items: center;
				font-size: 18px;
				.chinese{
					// border-left: 4px solid #000;
					padding: 0 24px 0 0;
					font-weight: 700;
					font-size: 24px;
				}

			}
			.tit-left1{
				// display: flex;
				// align-items: center;
				// font-size: 18px;
				height: 0;
				width: 100px;
				border-bottom: 40px solid #3E84D6;
				border-left: 0px solid #3E84D6;
				border-right: 30px solid transparent;
				.chinese1{
					padding: 10px 24px 0 30px;
					font-weight: 500;
					font-size: 22px;
					color: #fff;
				}
			}
			.tit-more{
				font-size: 14px;
				color: #9A9A9A;
				cursor: pointer;
			}
		}
		.swiper-container{
			width: 100%;
    		// height: 300px;
			.swiper-wrapper{
				width: 100%;
				height: 290px;
				.swiper-slide{
					// width: 272px ;
					width: 290px !important;
					height: 348px;
					// border: 1px solid #000;
					box-sizing: border-box;
					background-color: #fff;
					margin-right: 15px;
					box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
					border-radius:6px ;
					.seckill-img{
						position: relative;
						width: 290px;
						height: 210px;
						display: flex;
						justify-content:center;
						>img{
							width: 268px;
							height: 210px;
						}
						.count-down{
							position: relative;
							// width: 200px;
							padding: 0 5px 0 44px;
							height: 32px;
							display: flex;
							position: absolute;
							top: 0;
							left: 0;
							background-color: #f00;
							border-radius: 0 0 12px 0 ;
							.time-of-day{
								// width: 44px;
								position: absolute;
								top: 5px;
								left: 5px;
								color: #000;
								z-index: 99;
								display: flex;
								.time-size{
									min-width: 20px;
									height: 20px;
									text-align: center;
									line-height: 20px;
									color: #f00;
									background-color: #fff;
								}
								.time-color{
									color: #fff;
									margin: 5px 3px 0;
									font-size: 12px;
									
								}
							}
							.timekeeping{
								position: absolute;
								top: 11px;
								left: 11px;
								color: #333333;
							}
						}
						.count-down1{
							// width: 92px;
							height: 32px;
							line-height: 32px;
							display: flex;
							position: absolute;
							top: 0;
							left: 0;
							padding: 0 10px;
							background-color: #f00;
							border-radius: 0 0 12px 0 ;
							color: #fff;
						}
						.count-down2{
							width: 92px;
							height: 32px;
							line-height: 32px;
							display: flex;
							position: absolute;
							top: 0;
							left: 0;
							background-color: #999999;
							border-radius: 0 0 12px 0 ;
							// .timekeeping{
							// 	position: absolute;
							// 	top: 11px;
							// 	left: 11px;
							// 	color: #333333;
							// }
							.timekeeping-text{
								// position: absolute;
								// top: 11px;
								// left: 20px;
								color: #fff;
								margin-left: 20px;
							}
						}
					}
					.seckill-info{
						padding: 0 10px;
						position: relative;
						.seckill-name{
							margin-top: 10px;
							font-size: 16px;
							font-weight: 500;
							color: #333333;
						}
						.seckill-quantity{
							margin-top: 10px;
							font-size: 14px;
							font-weight: 500;
							color: #999999;
						}
						.seckill-schedule{
							margin-top: 10px;
							display: flex;
							.progress-body{
								display: flex;
								width: 180px;
								height: 8px;
								margin-top: 3px;
								background-color: #FEE8EA;
								border-radius:  4px;
								overflow: hidden;
								.progress1{
									// width: 200px;
									// height: 14px;
									text-align: center;
									line-height: 14px;
									background-color: #DD5044;
									color: #fff;
									border-radius:  4px 0 0 4px ;
								}
								

							}		
								.sold{
									flex: 1;
									text-align: center;
									font-size: 14px;
									color: #999999;
									// margin-left: 10px;
								}
						}
						.seckill-price{
							position: absolute;
							bottom: -40px;
							width: 270px;
							display: flex;
							justify-content:space-between;
							height: 28px;
							line-height: 28px;
							.price{
								display: flex;
								.current{
									font-size: 24px;
									color: #FF2020;
									
								}
								.original{
									font-size: 14px;
									text-decoration: line-through;
									color: #999999;
									margin-top: 2px;
									margin-left: 5px;
								}
							}
							.seckill-btn{
								width: 68px;
								height: 28px;
								background: #FFD101;
								border-radius: 6px;
								color: #333333;
								font-size: 14px;
								text-align: center;
								cursor:pointer; 
							}
						}
					}
				}
			}
		}
		.banner-user{
			display: flex;
			justify-content: space-between;
			margin-bottom: 10px;
			height: 360px;
			.bannder{
				height: 360px;
				width: 100%;
				// width: 972px;
				overflow: hidden;
				
				img{
					width: 100%;
					height: 100%;
					cursor: pointer;

				}
				//  修改指示器样式
				::v-deep .el-carousel__indicators{
					
					background: rgba($color: #fff, $alpha: 0.4);
					padding: 0 2px;
					height: 18px;
					display: flex;
					align-items: center;
					border-radius: 9px;
					bottom: 20px;
					display: none;
					.el-carousel__indicator{
						padding: 0 6px;
						.el-carousel__button{
							width: 12px;
							height: 12px;
							border-radius: 50%;
						}
					}
					.is-active{
						.el-carousel__button{
							background: #3E84D6;
						}
					}
				}
			}
			.user-box{
				margin: 0 auto;
				width: 1200px;
				position: relative;
				height: 100%;
			}
			.user{
				width: 220px;
				background: #fff;
				display: flex;
				flex-direction: column;
				
				
				.brand-list{
					>li{
						overflow: hidden; 
					}
				}
				.user-info{
					// height: 150px;
					text-align: center;
					// padding: 16px 0 24px;
					box-sizing: border-box;
					.logo-face{
						width: 48px;
						height: 48px;
						border-radius: 50%;
					}
					.signature{
						font-size: 14px;
						font-weight: 500;
						padding: 8px;
						>div{
							margin-bottom: 5px;
						}
					}
					.handle{
						display: flex;
						justify-content: center;
						.re-btn{
							color: #666;
							background: #d8d8d8;
							padding: 4px 8px;
						}
						.split{
							width: 26px;
							height: 10px;
							background: #fff;
							padding: 0;
						}
						>div{
							padding: 4px 22px;
							background: $split-color;
							color: #666;
							font-size: 14px;
							cursor: pointer;
						}
						.active{
							background: #3E84D6;
							color: #fff;
						}
					}
				}
				>ul{
					// flex: 1;
					display: flex;
					flex-direction: column;
					cursor: pointer;
					height: 260px;
					li{
						flex: 1;
						height: 40px;
						border-top: 1px solid $split-color;
						display: flex;
						justify-content: center;
						align-items: center;
						img{
							width: 92px;
							
						}
						.def{
							width: 100px;
						}
						.fla{
							width: 75px;
						}
						.cef{
							width: 75px;
						}
						.kdtk{
							width: 112px;
						}
					}
				}	
			}
		}
		
		.buy-steps{
			background: #fff;
			margin: 10px 0;
			padding: 12px 100px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			.arrow{
				font-size: 24px;
			}
			.step-item{
				text-align: center;
				img{
					
				}
				div{
					padding-top: 6px;
				}
			}
		}
		.index-collect-module{
			// background: #fff;
			margin-bottom: 10px;
			// margin-right: 10px;
			.module-tit{
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 40px 0 0 0;
			.tit-left{
				display: flex;
				align-items: center;
				font-size: 18px;
				.chinese{
					// border-left: 4px solid #000;
					padding: 0 24px 0 12px;
					font-weight: 700;
					font-size: 24px;
				}

			}
			.tit-more{
				font-size: 14px;
				color: #9A9A9A;
				cursor: pointer;
			}
		}
			.collect-tit{
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 20px 0;
				.collect-tit-left{
					display: flex;
					align-items: center;
					font-size: 18px;
					.chinese{
						// border-left: 4px solid #000;
						padding: 0 24px 0 12px;
						font-weight: 700;
						font-size: 24px;
					}

				}
				.collect-tit-more{
					font-size: 14px;
					color: #9A9A9A;
				}
			}
			.collct-content{
				// border-top: 1px solid #f7f7f7;
				// ::v-deep .el-row{
				// 	border-bottom: 1px solid $gray;
				// 	.el-col:last-child{
				// 		.collect-card{
				// 			&::after{
				// 				display: none;
				// 			}
				// 		}
				// 	}
				// }
				display: flex;
				border: 2px solid #3E84D6;
				height: 351px;
				padding: 30px 0 0 30px;
				box-sizing: border-box;
				// flex-wrap: wrap;
				.collect-card{
					// margin-bottom: 5px;
					
					background: #fff;
					position: relative;
					// width: 25%;
					width: 272px;
					height: 290px;
					// margin: 0 4px 8px;
					margin-right: 18px;
					// padding: 0 5px 8px;
					// flex: 1;
					box-shadow:0px 0px 10px 0px rgba(0, 0, 0, 0.1);
					overflow: hidden;
					border-radius: 5px;
					.img-box{
						height: 182px;
						display: flex;
						justify-content: center;
						align-items: center;
						cursor: pointer;
						img{
							// width: 212px;
							// height: 166px;
							width: 182px;
							height: 182px;
						}
					}
					.card-info{
						padding: 10px 10px;
						box-sizing: border-box;
						display: flex;
						justify-content: space-between;
						// align-items: center;
						.goods-msg{
							
							.tit{
								font-size: 16px;
								height: 32px;
								overflow: hidden;
								color: #333;
								font-weight: 500;
								.subsidiary-tit{
									padding-left: 6px;
								}
								// white-space: nowrap;
								// text-overflow: ellipsis;
								// overflow: hidden;
							}
							.goods-coll-msg{
								box-sizing: border-box;
								padding: 10px 0;
								color: #999999;
								display: flex;
								align-items: center;
								
								img{
									width: 16px;
									height: 16px;
									padding: 0 4px;
								}
								
							}
							.rote-box{
								display: flex;
								color: #999999;
								.el-progress{
									width: 60px;
									margin-left: 10px;
									::v-deep .el-progress-bar{
										padding-right: 0;
									}
								}
							}
						}

						.btn-operation-box{
							.btn-operation{
								border:1px solid #3E84D6;
								color: #3E84D6;
								cursor: pointer;
								font-size: 14px;
								text-align: center;
								line-height: 22px;
								margin-top: 60px;
								height: 22px;
								width: 80px;
								box-sizing: border-box;
							}
							
						}
					}
					// &::after{
					// 	position: absolute;
					// 	right: 0;
					// 	top: 62px;
					// 	width: 1px;
					// 	height: 150px;
					// 	background: $gray;
					// 	content: '';
					// }
				}
				.no-border{
					&::after{
						width: 0;
					}
				}
			}
		}
		.seckill{
			.module-tit{
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 9px 0 16px  0;
				.tit-left{
					display: flex;
					align-items: center;
					font-size: 18px;
					.chinese{
						// border-left: 4px solid #000;
						// padding: 0 24px 0 12px;
						font-weight: bold;
						font-size: 22px;
						color: #3E84D6;
						img{
							width: 20px; 
							vertical-align: bottom;
						}
					}

				}
				.tit-more{
					font-size: 14px;
					color: #9A9A9A;
					cursor: pointer;
				}
			}
		}
		.store-content{
			.module-tit{
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 9px 0 16px  0;
				.tit-left{
					display: flex;
					align-items: center;
					font-size: 18px;
					.chinese{
						// border-left: 4px solid #000;
						// padding: 0 24px 0 12px;
						font-weight: bold;
						font-size: 22px;
						color: #3E84D6;
						img{
							width: 20px; 
							vertical-align: bottom;
						}
						.img-goods{
							width: 24px; 
							vertical-align: middle;
							margin-right: -5px;
						}
					}

				}
				.tit-more{
					font-size: 14px;
					color: #9A9A9A;
					cursor: pointer;
				}
			}
			.store-tit{
				display: flex;
				justify-content: space-between;
				padding: 10px 0 10px 0;
				.tit-left{
					font-size: 18px;
					color: #333333;
					font-weight: 600;
				}
				.tit-right{
					font-size: 14px;
					color: #333;
				}
			}
			.goods-list{
				background: $backGray;
				.el-col-lg-4-8 {
					width: 20%;
				}
				.goods-list-content{
					display: flex; 
					position: relative;
					.brand-list{
						width: 234px;
						border: 1px solid #ccc;
						margin: 0 4px;
						background: #3E84D6;
						border-radius: 6px;
						margin-bottom: 10px;
						padding: 10px 0;
						box-sizing: border-box;
						.brand-img{ 
							height: 300px; 
							// border-radius: 6px;
							overflow: auto;
							.img-item{
								background: #ccc;
								border-radius: 3px;
								width: 192px;
								height: 50px;
								background: #fff;
								padding: 0 15px; 
								box-sizing: border-box; 
								margin: 10px 20px;
								text-align: center;
								overflow: hidden;
								>img{
									width: auto;
									height: 96px;
									margin-top: -23px;
								}
							}
							.active{
								width: 213px;
							}
						}
					}
					.brand-img::-webkit-scrollbar{
						display: none;
					}
					.goods-col-list::-webkit-scrollbar{
						display: none;
					}
					.less-than{
						position: absolute;
						top: 40%;
						left: 273px;
						width: 60px;
						height: 60px;
						background: rgba(0, 0, 0, 0.2);
						border-radius: 50%;
						font-size: 30px;
						color: #fff;
						text-align: center;
						line-height: 60px;
					}
					.great-than{
						position: absolute;
						top: 40%;
						right: 36px;
						width: 60px;
						height: 60px;
						background: rgba(0, 0, 0, 0.2);
						border-radius: 50%;
						font-size: 30px;
						color: #fff;
						text-align: center;
						line-height: 60px;
					}
					.goods-col-list{
						display: flex;
						overflow-x: auto;
						flex-wrap: nowrap;
						justify-content: space-between; 
						
						.goods-col-all{
							width: 234px;
							margin: 0 4px;
							.goods-card{
								background: #fff;
								width: 232px;
								height: 336px;
								display: flex;
								margin-bottom: 10px;
								flex-direction: column;
								// justify-content: space-between;		
								box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
								border-radius: 6px ;
								.img-box{
									// cursor: pointer;
									height: 210px;
									display: flex;
									justify-content: center;
									align-items: center;
									img{
										
										width: 210px;
										height: 210px;
									}
								}
								.card-fot{
									// padding: 14px 10px;
									padding: 0px 14px 10px;
									box-sizing: border-box;
									.fot-bot-price{
											font-size: 18px;
											padding-right: 10px;
											.dot{
												font-size: 14px;
												position: relative;
												left: -3px;
											}
											.blue{
												font-size: 14px;
												color: #3E84D6;
											}
											.showPrice{
												display: flex;
												margin: 20px 0 10px 0;
												.Price{
													font-size: 24px;
													color: #FF2020;
													
												}
												.salePrice{
													font-size: 14px;
													text-decoration: line-through;
													color: #999999;
													padding-top: 8px;
													padding-left: 7px;
												}
											}
										}
									.fot-top{
										display: flex;
										height: 22px;
										position: relative;
										.store-name{
											font-size: 12px;
											border:1px solid #3E84D6;
											padding: 3px 1px;
											margin-right: 5px;
											
											border-radius: 2px;
										}
										.goods-name{
											font-size: 16px;
											font-weight: 500;
											color: #333333;
											white-space: nowrap;
											text-overflow: ellipsis;
											overflow: hidden;
											width: 179px;
											
										}
										.joinCart{
											width: 25px;
											height: 23px;
											position: absolute;
											top: 0;
											right: 0;
											cursor:pointer;
											img{
												width: 25px;
												height: 23px;
											}
											}
									}
									.fot-bot{
										display: flex;
										justify-content: space-between;
										padding-top: 6px;
										height: 28px;
										line-height: 28px;
										.fot-right{
											display: flex;
											.store-name{
												min-width: 47px;
												height: 28px;
												padding: 0 4px;
												line-height: 28px;
												text-align: center;
												background: #3E84D6;
												font-size: 14px;
												color: #fff;
												margin-right: 4px;
												// cursor: pointer;
											}
											.store-oe{
												max-width: 130px;
												font-size: 14px;
												color: #3E84D6;
												overflow:hidden;
												white-space:nowrap;
												text-overflow:ellipsis;
											}
										}
										.store-inventory{
											font-size: 14px;
											color: #999999;
										}
									}
								}
							}
						}
					}
					
				}
				
				.goods-col{
					
					.goods-card{
						background: #fff;
						
						height: 336px;
						display: flex;
						margin-bottom: 10px;
						flex-direction: column;
						// justify-content: space-between;		
						box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
						border-radius: 6px ;
						.img-box{
						    // cursor: pointer;
							height: 210px;
							display: flex;
							justify-content: center;
							align-items: center;
							img{
								
								width: 210px;
								height: 210px;
							}
						}
						.card-fot{
							// padding: 14px 10px;
							padding: 0px 14px 10px;
							box-sizing: border-box;
							.fot-bot-price{
									font-size: 18px;
									padding-right: 10px;
									.dot{
										font-size: 14px;
										position: relative;
										left: -3px;
									}
									.blue{
										font-size: 14px;
										color: #3E84D6;
									}
									.showPrice{
										display: flex;
										margin: 20px 0 10px 0;
										.Price{
											font-size: 24px;
											color: #FF2020;
											
										}
										.salePrice{
											font-size: 14px;
											text-decoration: line-through;
											color: #999999;
											padding-top: 8px;
											padding-left: 7px;
										}
									}
								}
							.fot-top{
								display: flex;
								height: 22px;
								position: relative;
								.store-name{
									font-size: 12px;
									border:1px solid #3E84D6;
									padding: 3px 1px;
									margin-right: 5px;
									
									border-radius: 2px;
								}
								.goods-name{
									font-size: 16px;
									font-weight: 500;
									color: #333333;
									white-space: nowrap;
									text-overflow: ellipsis;
									overflow: hidden;
									width: 179px;
									
								}
								.joinCart{
									width: 25px;
									height: 23px;
									position: absolute;
									top: 0;
									right: 0;
									cursor:pointer;
									img{
										width: 25px;
										height: 23px;
									}
									}
							}
							.fot-bot{
								display: flex;
								justify-content: space-between;
								padding-top: 6px;
								height: 28px;
								line-height: 28px;
								.fot-right{
									display: flex;
									.store-name{
										min-width: 47px;
										height: 28px;
										padding: 0 4px;
										line-height: 28px;
										text-align: center;
										background: #3E84D6;
										font-size: 14px;
										color: #fff;
										margin-right: 14px;
										// cursor: pointer;
									}
									.store-oe{
										max-width: 130px;
										font-size: 14px;
										color: #3E84D6;
										overflow:hidden;
										white-space:nowrap;
										text-overflow:ellipsis;
									}
								}
								.store-inventory{
									font-size: 14px;
									color: #999999;
								}
							}
						}
					}
				}
				
			}
		}
		.xscx-content{
			// margin:  0;
			height: 270px;
			// background: url(../../assets/index/newHome/限时促销.png);
			width: 1200px;
			margin: 20px auto;
			position: relative;
			.backimg{
				position: absolute;
				left: -18px;
				top: 0;
			}
			.to-list-btn{
				position: absolute;
				bottom: 14px;
				left: 120px;
				width: 80px;
				height: 30px;
				cursor: pointer;
			}
			.goods-list{
				background: transparent;
				width: 940px;
				position: absolute;
				right: 20px;
				top: 25px;
				display: flex;
				// justify-content: space-between;
				.goods-card{
					background: #fff;
					position: relative;
					width: 222px ;
					height: 222px ;
					background: #fff;
					display: flex;
					margin-bottom: 10px;
					flex-direction: column;
					cursor: pointer;
					
					.img-box{
						// cursor: pointer;
						
						display: flex;
						justify-content: center;
						align-items: center;
						
					}
					.dis-good-tips{
						position: absolute;
						right: 0;
						top: 10px;
						background: url(../../assets/index/newHome/折扣.png);
						width: 64px;
						height: 30px;
						text-align: center;
						line-height: 30px;
						font-family: Source Han Sans CN;
						font-weight: 500;
						font-size: 20px;
						color: #FFFFFF;
						
					}
					.card-fot{
						// padding: 14px 10px;
						padding: 0px 14px 10px;
						box-sizing: border-box;
						.fot-bot-price{
								font-size: 18px;
								padding-right: 10px;
								text-align: center;
								
								.showPrice{
									display: flex;
									margin: 20px 0 10px 0;
									justify-content: center;
									.Price{
										font-size: 24px;
										color: #FF2020;
										
									}
									.salePrice{
										font-size: 14px;
										text-decoration: line-through;
										color: #999999;
										padding-top: 8px;
										padding-left: 7px;
									}
								}
							}
						.fot-top{
							display: flex;
							height: 22px;
							position: relative;
						
							.goods-name{
								font-size: 16px;
								font-weight: 500;
								color: #333333;
								white-space: nowrap;
								text-overflow: ellipsis;
								overflow: hidden;
								width: 179px;
								
							}
							
						}
						
					}
				}
				
				.goods-card{
					
					margin-right: 17px;
					.showPrice{
						margin-top: 5px !important;
						width: 100%;
					}
					.card-fot{
						text-align: center !important;
						.goods-name{
							font-family: Source Han Sans CN;
							font-weight: 500;
							font-size: 20px;

						}
					}
					&:nth-child(4){
						margin-right: 0;
					}
				}
				
			}
		}
		.jfsc-content{
			margin: 20px 0;
			height: 360px;
			background: url(../../assets/index/newHome/积分商城.png);
			padding-top: 110px;
			box-sizing: border-box;
			position: relative;
			padding-left: 15px;
			.to-list-btn{
				width: 60px;
				height: 60px;
				position: absolute;
				right: 34px;
				top: 30px;
				cursor: pointer;
				
			}
			.goods-list{
				display: flex;
				background: transparent;
				.goods-card{
					background: #fff;
					cursor: pointer;
					width: 220px;
					height: 220px;
					display: flex;
					margin-bottom: 10px;
					flex-direction: column;
					justify-content: space-between;		
					box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
					border-radius: 6px ;
					margin-right: 16px;
					.img-box{
						// cursor: pointer;
						height: 140px;
						display: flex;
						justify-content: center;
						align-items: center;
						img{
							// width: 229px;
							// height: 130px;
							width: 140px;
							height: 140px;
						}
					}
					.card-fot{
						box-sizing: border-box;
						padding: 10px;
						
						.pri_warehouse{
							display: flex;
							justify-content: space-between;
							align-items: flex-end;
							.price{
								display: flex;
								align-items: flex-end;

								.red{
									display: flex;
									.num{
										font-size: 22px;
									}
									.jf{
										position: relative;
										padding-left: 3px;
										top: 5px;
										white-space: nowrap;
										overflow: hidden;
										text-overflow: ellipsis;
									}
								}
								.old-price{
									font-size: 12px;
									text-decoration: line-through;
									color: #999999;
									padding-top: 2px;
									padding-left: 2px;
									white-space: nowrap;
									overflow: hidden;
									text-overflow: ellipsis;
								}
							}
							.ware{
								color: #999999;
								white-space: nowrap;
								overflow: hidden;
								text-overflow: ellipsis;
							}
						}
						.goods-name{
							font-weight: 600;
							padding: 5px 0;
							line-height: 22px;
							min-height: 22px;
							font-size: 16px;
							white-space: nowrap;
							overflow: hidden;
							text-overflow: ellipsis;
						}
						.handle{
							text-align: right;
						}
					}
					&:last-child{
						margin-right: 0;
					}
				}
			}
		}
		
		.brand-content{
			display: flex;
			margin: 10px 0;
			width: 100%;
			background: #fff;
			
			.brand-left{
				cursor: pointer;
				width: 244px;
				img{
					width: 244px;
				}
			}
			
			.goods-list{
				background: transparent;
				width: 940px;
				background: #fff;
				display: flex;
				flex-wrap: wrap;
				.goods-card{
					background: #fff;
					position: relative;
					width: 222px ;
					height: 222px ;
					background: #fff;
					display: flex;
					margin-bottom: 10px;
					flex-direction: column;
					margin: 0 6px;
					cursor: pointer;
					
					.img-box{
						// cursor: pointer;
						
						display: flex;
						justify-content: center;
						align-items: center;
						
					}
					
					.card-fot{
						// padding: 14px 10px;
						padding: 0px 14px 10px;
						box-sizing: border-box;
						.fot-bot-price{
								font-size: 18px;
								padding-right: 10px;
								text-align: center;
								
								.showPrice{
									display: flex;
									margin: 20px 0 10px 0;
									justify-content: center;
									margin-top: 5px !important;
									width: 100%;
									.Price{
										font-size: 24px;
										color: #FF2020;
										
									}
									.salePrice{
										font-size: 14px;
										text-decoration: line-through;
										color: #999999;
										padding-top: 8px;
										padding-left: 7px;
									}
								}
							}
						.fot-top{
							display: flex;
							height: 22px;
							position: relative;
						
							.goods-name{
								font-size: 16px;
								font-weight: 500;
								color: #333333;
								white-space: nowrap;
								text-overflow: ellipsis;
								overflow: hidden;
								width: 179px;
								
							}
							
						}
						
					}
				}
				.goods-card{
					
					.card-fot{
						text-align: center !important;
						.goods-name{
							font-family: Source Han Sans CN;
							font-weight: 500;
							font-size: 20px;

						}
					}
					&:nth-child(4){
						margin-right: 0;
					}
				}
			}
		}
		.cpfl-content{
			.tit-left{
				.chinese{
					color: #000 !important;
					img{
						margin-right: 5px;
					}
				}
			}
			.goods-list-content{
				justify-content: space-between;
				img{
					cursor: pointer;
				}
			}
		}
		.demand-content{
			background: #fff;
			.module-tit{
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 9px 0 16px  0;
				.tit-left{
					display: flex;
					align-items: center;
					font-size: 18px;
					.chinese{
						// border-left: 4px solid #000;
						// padding: 0 24px 0 12px;
						font-weight: bold;
						font-size: 22px;
						color: #3E84D6;
						img{
							width: 25px;
							height: 24px;
							vertical-align: middle;
						}
					}

				}
				.tit-more{
					font-size: 14px;
					color: #9A9A9A;
					cursor: pointer;
				}
			}
			.demand-tab{
				display: flex;
				align-items: center;
				justify-content: space-between;
				.tab{
					display: flex;
					padding: 10px 8px;
					box-sizing: border-box;
					>div{
						cursor: pointer;
					}
					.split{
						padding: 0 10px;
					}
					.active{
						color: #3E84D6;
					}

				}
				.tab-more{
					cursor: pointer;
					font-size: 14px;
					color: #9A9A9A;
				}
			}
			.demand-list{
				padding: 6px $leftPad 0;
				
				box-sizing: border-box;
				text-align: center;
				color: #555;
				.list-head{
					display: flex;
					background-color: #f7f7f7;
					>div{
						flex: 1;
						padding: 7px 0;
					}
					.dem-num{
						max-width: 60px;
						min-width: 60px;
					}
				}
				::v-deep .el-carousel{
					padding-bottom: 20px;
					.el-carousel__button{
						background: #3E84D6;
					}
				}
				// 轮播指示器
				.el-carousel{
					.el-carousel__arrow{
						width: 0;
						height: 0;
					}
				}
				
				.list-body{
					.body-row{
						display: flex;
						border-bottom: 1px solid #ccc;
						.col{
							flex: 1;
							padding: 9px 0;
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
							// word-break: break-all;
							.btn{
								cursor: pointer;
								color: #3E84D6;
							}
						}
						.dem-num{
							max-width: 60px;
							min-width: 60px;
						}
					}
				}
			}
		}
		.dema-info-dis{
			::v-deep .el-dialog__header{
				padding: 10px 20px;
				background: #3E84D6;
				.dema-info-title{
					font-size: 15px;
					color: #fff;
					i{
						margin-right: 5px;
						font-size: 18px;
					}
				}
				.el-dialog__headerbtn{
					top: 12px;
					i{
						color: #fff;
					}
				}
				
			}
			::v-deep .el-dialog__body{
				
				padding: 10px 20px;
				
				.dama-info-msg{
					padding-bottom: 10px;
					.msg-item{
						display: flex;
						margin: 10px 0;
						.msg-label{
							width: 60px;
							display: flex;
							justify-content: flex-end;
							align-items: center;
							margin-right: 10px;
						}
						.msg-value{
							padding: 5px;
							border:1px solid #ccc;
							background: #fefefe;
							width: 600px;
							box-sizing: border-box;
							min-height: 26px;
							.tip{
								color: #ccc;
								font-size: 12px;
								margin-left: 10px;
							}
						}
						.img-list{
							>img{
								margin-right: 10px;
								width: 80px;
								height: 80px;
								cursor: pointer;
								background: #fff;
							}
							img:hover{
								transform: scale(2);
							}
						}
					}
					.dema-btn-box{
						display: flex;
						justify-content: center;
						.link-btn{
							background: #3E84D6;
							color: #fff;
							height: 30px;
							line-height: 30px;
							width: 160px;
							text-align: center;
							border-radius: 30px;
							cursor: pointer;
						}
					}
				}
			}
		}
	}
	
	
</style>
