<template>
  <div class="prodect-info">
        <div class="product-title">
            <div class="jianhao">件号查询</div>
            <div class="back" @click="backPage">返回
                <img src="../../../assets/ybj/arrow_right.png" alt="">
            </div>
        </div>
        <div class="product-body" v-if="productInfo.length==0">
            <div class="none-product" >
                <img src="../../../assets/ybj/noneproduct.png" alt="">
                <div class="info">未查询到产品信息</div>
            </div>


            
        </div>
        <div class="product-carinfo">
            <carlist :productInfo="productInfo" @fankuiCenter='fankuiCenter' :levelidsInfo="levelidsInfo"></carlist>
        </div>
        
  </div>
</template>

<script>
import carlist from "./carlist.vue"
export default {
    components:{
        carlist,
    },
    props:{
        productInfo:{
            require:true
        },
        levelidsInfo:{
            require:true
        }
    },
    methods:{
        fankuiCenter(option){
            this.$emit("fankuiCenter",option)
        },
        backPage(){ 
            this.$emit("backpagemode",1)
        }
    }
}
</script>

<style lang="scss" scoped>
    .prodect-info{
        width: 100%;
        min-height: 100vh;
        background: #fff;
        .product-title{
            width: 100%;
            height: 40px;
            background: #cbd1e5;
            padding-left: 10px;
            padding-right: 10px;
            overflow: hidden;
            display: flex;
            justify-content: space-between;
            box-sizing: border-box;
            .jianhao{
                color: #4564d3;
                line-height: 20px;
                font-size: 14px;
                font-weight: bold;
                padding-left: 10px;
                border-left: 2px solid #4564d3;
                height: 20px;
                margin-top: 10px;
            }
            .back{
                color: #4564d3;
                line-height: 40px;
                font-size: 14px;
                font-weight: bold;
                >img{
                    vertical-align: middle;
                }
            }
        }
        .product-carinfo{
            background: #fff;
            height: 700px;
        }
        .product-body{
            background: #fff;
            height: 700px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 10px;
            .none-product{
                .info{
                    font-size: 14px;
                    color: #999;
                    font-weight: bold;
                    text-align: center;
                }
            }
        }
    }
</style>