<template>
<div class="store-branchHome w">
    
    <div class="home-modu" v-if="clearGoodsList.length>0">
        <div class="modu-title">
           <img src="../../../assets/store/clear.png" alt="">
        </div>
        <div class="modu-content clear-modu" >
            <div class="goods-list">
                <div class="goods-item" @click="showGoodsInfo(goods,'直营首页清库存')" v-for="(goods,index) of clearGoodsList" :key="index" :class="{'disbld':!goods.goods_id}">
                    <div class="img-box" v-if="goods.goods_id">
                        <img :src="goods.goods_image" alt="">
                    </div>
                    <div class="goods-info" v-if="goods.goods_id">
                        <div class="goods-name">{{goods.goods_name}}</div>
                        <div class="price">
                            
                            <span >
                                <span class="symbol">￥</span>{{goods.taxStoPrice2}}
                                <span class="old-price">
                                    ￥{{goods.goods_price}}
                                </span>
                            </span>
                            <!-- <span v-else>
                                会员可见
                            </span> -->
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="home-modu home-hot" v-if="hotGoodsList.length>0">
        <div class="modu-title">
            <img src="../../../assets/store/hot.png" alt="">
        </div>
        <div class="modu-content hot-modu" >
            
            <div class="hot-content">
               
                <!-- hotGoodsList -->
                <div class="hot-goods-list">
                    <div class="goods-item" @click="showGoodsInfo(goods,'直营首页热销')" v-for="(goods,index) of hotGoodsList" :class="{'first-goods':index==0}" :key="index">
                        <template v-if="index==0">
                            <div class="goods-left">
                                <div class="goods-name">{{goods.goods_name}}</div>
                                <div class="line"></div>
                                <div class="desc">
                                    <span class="tit">
                                        发生器:
                                    </span>
                                    <span class="content">
                                        采用军工企业生产，严格按照
                                        CCAP质量体系强制认证标准，发生器
                                        内部炸药与原车相同品质，严格起到
                                        保护作用.
                                    </span>
                                </div>
                                 <div class="read-btn">
                                    点击查看
                                </div>
                            </div>
                            
                            <div class="goods-right">
                                <div class="img-box">
                                <img :src="goods.goods_image" alt="">
                            </div>
                            </div>

                        </template>
                        <template v-else>
                            <div class="goods-name">{{goods.goods_name}}</div>
                                    
                            <div class="img-box">
                                <img :src="goods.goods_image" alt="">
                            </div>
                            <div class="handle" v-if="goods.goods_id">
                                <div class="read-btn">
                                    点击查看
                                </div>
                            </div>
                        </template>
                       
                    </div>
                </div>
            </div>
            
        </div>
    </div>
    <div class="home-modu w recommend-home">
        <div class="modu-title">
            <img src="../../../assets/store/reco.png" alt="">
        </div>
        <div class="modu-content recommend-modu" v-if="recommendGoodsList.length>0">
            
            
            <div class="goods-list">
                <!-- <div class="goods-item" >
                    <div class="img-box">
                        <img :src="goods.goods_image" alt="">
                    </div>
                    <div class="goods-info" v-if="goods.goods_id">

                        <div class="price-row">
                            <span >
                                <span class="now-price">
                                    <span class="symbol">￥</span><span class="price">{{goods.goods_price}}</span>
                                </span>
                                
                                <span class="old-price">
                                    <span class="symbol">￥</span><span class="price">{{$hnwTools.times(goods.goods_price,1.2)}}</span>
                                </span>
                            </span>
                           
                        </div>
                        <div class="item-bottom">
                            <div class="goods-name">{{goods.goods_name}}</div>
                            <div class="read-btn">
                                点击查看
                            </div>
                        </div>
                    </div>
                </div> -->

                <div class="goods-card" @click="showGoodsInfo(goods,'直营首页推荐')" v-for="(goods,index) of recommendGoodsList"  :key="index" >
                    <div class="img-box" >
                        <img :src="goods.goods_image" alt="">
                        
                    </div>
                    <div class="card-fot">
                        <div class="fot-bot-price" >
                        
                            <div class="showPrice">
                                <div style="font-size:14px; color:#FF2020; padding-top: 8px;">￥</div>
                                <div class="Price">{{goods.goods_price}}</div>
                                <div class="salePrice">￥{{$hnwTools.times(goods.goods_price,1.2)}}</div> 
                            </div>
                        </div>
                        <div class="fot-top">
                            <span class="goods-name">{{goods.goods_name}}</span>
                            <div class="joinCart" @click.stop="joinCart(goods)">
                                <img src="../../../assets/index/shop.png" alt="">
                            </div>
                        </div>
                        <div class="fot-bot">
                                <div class="fot-right">
                                    <div class="store-name" >
                                        {{goods.oenaturebrand}}
                                    </div>
                                    <div class="store-oe">
                                        {{goods.oecode}}
                                    </div>
                                </div> 
                                <div class="store-inventory">
                                    <span v-if="$store.state.look_inv==1">还剩{{goods.goods_storage}}</span>
                                    <span v-else>{{goods.goods_storage>0?"有货":"无货"}}</span>
                                    
                                </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    </div>
    
</div>
</template>
<script>
export default {
    data(){
        return{
            clearGoodsList:[],
            hotGoodsList:[],
            recommendGoodsList:[],
            shopId:""
        }
    },
    methods:{
        async showGoodsInfo(goods,rou){
          if(!goods.goods_id){
            return 
          }
          if(this.$store.state.shop!=this.shopId&&this.$store.state.store_id!=this.shopId&&this.$store.state.for_branch!=this.shopId){
            return this.$warMsg("暂无权限")
          }
          goods.preRou = rou?rou:"直营"
          localStorage.setItem("hnw-web-item-data",JSON.stringify(goods))
          this.$emit("showInfo")
          
      },
      reClass(index){
            if(index==0){
                return "first"
            }
      },
      async joinCart(carItem){
            if(this.$isCompany()){

                let res = ''
                
                let carMessage = {
                    goods_id:carItem.goods_id,
                    sccode:carItem.sccode,
                    oecode:carItem.oecode,
                    goods_name:carItem.goods_name,
                    goods_price:carItem.goods_price,
                    goods_storage:carItem.goods_storage,
                    goods_num:1,
                    store_id:carItem.store_id,
                    store_name:carItem.store_name,
                    cart_type:'1',
            
                }
                res = await this.$api.joinCart([carMessage])
                
                // 跳转到加入成功页
                
            }
      },
    },
    async created(){
        
        this.shopId = this.$route.params.id
        
        // 获取清库存 4
        this.clearGoodsList = await this.$api.getlistRecom({
            goods_commend:3,
            shop:this.shopId,
            is_chain:this.chain
        })
        this.clearGoodsList.forEach(goods=>{
           this.$handleStorePrice(goods)
           this.$hnwTools.formatGoodsKey(goods)
        })
        // 获取热销 5
       
        this.hotGoodsList = await this.$api.getlistRecom({
            goods_commend:4,
            shop:this.shopId,
            is_chain:this.chain
        })
        this.hotGoodsList.forEach(goods=>{
            this.$handleStorePrice(goods)
            this.$hnwTools.formatGoodsKey(goods)
        })
        // 推荐 8
         // 获取热销 5
        this.recommendGoodsList = await this.$api.getlistRecom({
            goods_commend:1,
            shop:this.shopId,
            is_chain:this.chain
        })
        this.recommendGoodsList.forEach(goods=>{
            this.$handleStorePrice(goods)
            this.$hnwTools.formatGoodsKey(goods)
        })
    
        if(this.clearGoodsList.length>4){
            this.clearGoodsList = this.clearGoodsList.slice(0,4)    
        }else if(this.clearGoodsList.length>0&&this.clearGoodsList.length<4){
            for (let index = 0; index < 4-this.clearGoodsList.length; index++) {
                this.clearGoodsList.push({
                    name:""
                })
            }
        }
        
        if(this.hotGoodsList.length>5){
            this.hotGoodsList = this.hotGoodsList.slice(0,5)    
        }else if(this.hotGoodsList.length>0&&this.hotGoodsList.length<5){
            for (let index = 0; index < 5-this.hotGoodsList.length; index++) {
                this.hotGoodsList.push({
                    name:""
                })
            }
        }
        if(this.recommendGoodsList.length>8){
            this.recommendGoodsList = this.recommendGoodsList.slice(0,8)    
        }else if(this.recommendGoodsList.length>0&&this.recommendGoodsList.length<8){
           
        }
        
        
        
        

    },
    props:{
        chain:""
    }
}
</script>

<style lang="scss">
$xyblue:#1B458B;
$xyow:#FF9E20;
.store-branchHome{
    
    .home-modu{
        // background: #fff;
        .modu-title{
            text-align: center;
            padding-top: 10px;
            padding-bottom: 5px;
        }
        .modu-content{
            padding: 10px 0;
            >div{

            }
        }
        .goods-item{
            cursor: pointer;
        }
        // 清库存
        .clear-modu{
            .goods-list{
                display: flex;
                justify-content: space-between;
                .goods-item{
                    background: #fff;
                    width: 286px;
                    // border: 1px solid #739FE9;
                    // border-radius:15px;
                    .img-box{
                        width: 100%;
                        height: 286px;
                        // border-radius:15px;
                        overflow: hidden;
                        img{
                            width: 286px;
                            // border-radius:15px;
                        }
                    }
                    .goods-info{
                        padding: 10px 15px;
                        // border-radius: 0px 25px 15px 15px;
                        background:#e7ecf0;
                        .price{
                            color: $xyow;
                            // font-size: 16px;
                            font-size: 24px;
                            span{
                            }
                            .symbol{
                                font-size: 12px;
                            }
                        }
                        .old-price{
                            text-decoration: line-through;
                            color: #C1C1C1;
                            font-size: 12px;
                        }
                        .goods-name{
                            color: #43659a;
                            font-size: 24px;
                            padding: 5px 0;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }
                    }
                }
                .disbld{
                    background: #f7f7f7;
                    border: none;
                }
            }

        }
        // 热销商品
        .hot-modu{
            
            
            box-sizing: border-box;
           
            .hot-content{
                background: #f7f7f7;
                .hot-goods-list{
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    .goods-item{
                        text-align: center;
                        background: #fff;
                        // flex: 1;
                        width: 380px;
                        padding: 20px 0;
                        margin-top: 15px;
                        .goods-name{
                            font-size: 30px;
                            font-weight: 500;
                            color: #333333;
                            line-height: 31px;
                        }
                        .img-box{
                           text-align: center;
                           padding: 10px 0;
                            img{
                                width: 320px;
                            }
                        }
                        .handle{
                            display: flex;
                            justify-content: center;
                        }
                        .read-btn{
                            background: $xyblue;
                            width: 66px;
                            text-align: center;
                            color: #fff;
                            // padding: 10px 5px;
                            cursor: pointer;
                            // border-radius: 34px;
                            width: 139px;
                            height: 43px;
                            line-height: 43px;
                            // background: #1B458B;
                            border-radius: 22px;
                        }
                    }
                    .first-goods{
                        width: 780px;
                        display: flex;
                        padding: 20px 50px;
                        box-sizing: border-box;
                        .goods-left{
                            padding-right: 15px;
                            .goods-name{
                                text-align: left;
                                height: 29px;
                                font-size: 30px;
                                font-family: Source Han Sans CN;
                                font-weight: 500;
                                color: #333333;
                                line-height: 21px;
                            }
                            .line{
                                width: 169px;
                                height: 4px;
                                background: #1B458B;
                                border-radius: 2px;
                                margin: 20px 0;
                            }
                            .desc{
                                padding: 10px 0;
                                padding-bottom: 20px;
                                text-align: left;
                                color: #223C8F;
                                line-height: 18px;
                                .tit{
                                    font-weight: 700;
                                }
                                .content{
                                    opacity: .8;
                                }
                            }
                        }
                        .goods-right{
                           
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            img{
                                width: 360px;
                            }
                        }
                    }
                }
            }
            
        }
    }
    .recommend-home{
        position: relative;
       
        padding-top: 10px;
        .modu-title{
            color: #fff;
            
           
            position: relative;
            z-index: 5;
        }
        
        .goods-list{
            display: flex;
            flex-wrap: wrap;
            
            justify-content: space-between;
            .goods-card{
                background: #fff;
                width: 286px;
                height: 336px;
                display: flex;
                margin-bottom: 10px;
                flex-direction: column;
                // justify-content: space-between;		
                box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
                border-radius: 6px ;
                .img-box{
                    // cursor: pointer;
                    height: 210px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    img{
                        
                        width: 210px;
                        height: 210px;
                    }
                }
                .card-fot{
                    // padding: 14px 10px;
                    padding: 0px 14px 10px;
                    box-sizing: border-box;
                    .fot-bot-price{
                            font-size: 18px;
                            padding-right: 10px;
                            .dot{
                                font-size: 14px;
                                position: relative;
                                left: -3px;
                            }
                            .blue{
                                font-size: 14px;
                                color: #3E84D6;
                            }
                            .showPrice{
                                display: flex;
                                margin: 20px 0 10px 0;
                                .Price{
                                    font-size: 24px;
                                    color: #FF2020;
                                    
                                }
                                .salePrice{
                                    font-size: 14px;
                                    text-decoration: line-through;
                                    color: #999999;
                                    padding-top: 8px;
                                    padding-left: 7px;
                                }
                            }
                        }
                    .fot-top{
                        display: flex;
                        height: 22px;
                        position: relative;
                        .store-name{
                            font-size: 12px;
                            border:1px solid #3E84D6;
                            padding: 3px 1px;
                            margin-right: 5px;
                            
                            border-radius: 2px;
                        }
                        .goods-name{
                            font-size: 16px;
                            font-weight: 500;
                            color: #333333;
                            
                        }
                        .joinCart{
                            width: 25px;
                            height: 23px;
                            position: absolute;
                            top: 0;
                            right: 0;
                            cursor:pointer;
                            img{
                                width: 25px;
                                height: 23px;
                            }
                            }
                    }
                    .fot-bot{
                        display: flex;
                        justify-content: space-between;
                        padding-top: 6px;
                        height: 28px;
                        line-height: 28px;
                        .fot-right{
                            display: flex;
                            .store-name{
                                min-width: 47px;
                                height: 28px;
                                padding: 0 4px;
                                line-height: 28px;
                                text-align: center;
                                background: #3E84D6;
                                font-size: 14px;
                                color: #fff;
                                margin-right: 14px;
                                // cursor: pointer;
                            }
                            .store-oe{
                                max-width: 130px;
                                font-size: 14px;
                                color: #3E84D6;
                                overflow:hidden;
                                white-space:nowrap;
                                text-overflow:ellipsis;
                            }
                        }
                        .store-inventory{
                            font-size: 14px;
                            color: #999999;
                        }
                    }
                }
            }
        }
    }
    .home-hot{
        .modu-title{
            margin: 0 auto;
            box-sizing: border-box;
            
        }
    }
}
</style>