// index.php?act=setlist&op=getlist
import { post } from "axios"
import store from '../../../store'
const getOrderList = async (data)=>{
    return post("index.php?act=orders&op=ordersList",{
        ...data
    })
}
const getOrderLists = async (data)=>{
    return post("index.php?act=store_orders&op=ordersList",{
        ...data
    })
}
const getOrderInfo = async (data)=>{
    return post("index.php?act=orders&op=orderInfo",{
        ...data
    })
}
const getOrderNums = async () =>{
    return post("index.php?act=orders&op=ordersCount",{
        buyer_id:store.state.store_id,
        member_id:store.state.member_id,
    })
}
const getOrdersReCount = async () =>{
    return post("index.php?act=orders&op=ordersReCount",{
        buyer_id:store.state.store_id,
        member_id:store.state.member_id,
    })
}

// 取消订单
const cancelOrder = async (data) =>{
    return post("index.php?act=orders&op=order_cancel",{
        member_name:store.state.member_name,

        ...data
    })
}
// 删除订单
const delOrder = async (order_sn) =>{
    return post("index.php?act=orders&op=orderDel",{
        order_sn
    })
}
// 确认收货
const affOrder = async (data) =>{
    return post("index.php?act=orders&op=order_over_erp",{
        ...data
    })
}
export {
    getOrderList,
    getOrderInfo,
    getOrderNums,
    cancelOrder,
    delOrder,
    affOrder,
    getOrderLists,
    getOrdersReCount
}