<template>
    <div class="ResetPassword">
        <header class="regisHead">
            <div class="logo">
                <!-- <img src="../../assets/images/index/logo.png" alt=""> -->
                <!-- <img src="../../assets/images/index/logo-pjlm.png" alt=""> -->
                 <img src="../../assets/regist/logo-pjlm - 副本.png" alt="" />
                <!-- <span>华诺威汽配联盟平台</span> -->
            </div>
           
        </header>
        <div class="cotainer">
            <div class="box">
                <div class="step">

                    <div class="step-item" :class="{'active':step>0}">
                        <div class="serial">
                            <div class="num">
                                1
                            </div>
                            <div class="line"></div>
                        </div>
                        <div class="step-text">
                            填写登录账号
                        </div>
                    </div>
                    <div class="step-item " :class="{'active':step>1}">
                        <div class="serial">
                            <div class="num">
                                2
                            </div>
                            <div class="line"></div>
                        </div>
                        <div class="step-text">
                            验证身份
                        </div>
                    </div>
                    <div class="step-item " :class="{'active':step>2}">
                        <div class="serial">
                            <div class="num">
                                3
                            </div>
                            <div class="line"></div>
                        </div>
                        <div class="step-text">
                            重置密码
                        </div>
                    </div>
                    <div class="step-item " :class="{'active':step>3}">
                        <div class="serial">
                            <div class="num">
                                4
                            </div>
                            <div class="line"></div>
                        </div>
                        <div class="step-text">
                            成功找回
                        </div>
                    </div>

                </div>
                <div class="form">
                    <el-form :model="regitsData" :rules="reserRule" ref="reRef1">
                        <template v-if="step==1">
                            <el-form-item prop="phone">
                                <div class="input-box">
                                    <img src="../../assets/regist/user.png" class="label-img">
                                    <input type="text" placeholder="请输入手机号" v-model="regitsData.phone">
                                </div>   
                                
                            </el-form-item>
                            
                            <el-form-item prop="identifyNum">
                                 <div class="input-box capcha-box" ref="canFaDiv">
                                    <img src="../../assets/regist/yzm.png" class="label-img">
                                    <input type="text" placeholder="请输入验证码" v-model="regitsData.identifyNum">
                                    <div class="capcha-box-btn">
                                        <Identify :contentHeight="canvasHeight" :contentWidth="canvasWidth" v-if="canvasHeight!=0" :identifyCode="identifyCode"></Identify>
                                    </div>
                                    <span class="exchange" @click.stop="changeIdent">
                                        换一张
                                    </span>
                                </div> 
                            
                            </el-form-item>
                        </template>

                        <template v-if="step==2">
                            <el-form-item prop="phone">
                                <div class="input-box disabinuBox">
                                    <img src="../../assets/regist/user.png" class="label-img">
                                    <input type="text" placeholder="请输入手机号" v-model="regitsData.phone" disabled>
                                </div>                       
                            </el-form-item>
                            <el-form-item prop="captcha">
                                 <div class="input-box capcha-box">
                                    <img src="../../assets/regist/yzm.png" class="label-img">
                                    <input type="text" placeholder="请输入验证码" v-model="regitsData.captcha">
                                    <div class="capcha-box-btn">
                                        <el-button type="primary" class="dxYzm-btn" @click="getCaptcha">{{vcodeBtnName}}</el-button>
                                    </div>
                                    
                                </div> 
                            
                            </el-form-item>
                         
                        </template>
                        <template v-if="step==3">
                            <el-form-item prop="password">
                                <div class="input-box">
                                    <img src="../../assets/regist/lock.png" class="label-img">
                                    <input :type="isPass?'password':'text'" placeholder="新的登录密码" v-model="regitsData.password" >
                                
                                </div> 
                            </el-form-item>

                            <el-form-item prop="password_confirm">
                                <div class="input-box">
                                    <img src="../../assets/regist/lock.png" class="label-img">
                                    <input :type="isPass?'password':'text'" placeholder="新的登录密码" v-model="regitsData.password_confirm" >
                                
                                </div> 
                            </el-form-item>
                        </template>
                        <template v-if="step==4">
                           
                        </template>
                        <div class="nextBox">
                            <el-button type="primary" class="capBtn" @click="next">{{nexText}}</el-button>
                        </div>
                     </el-form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Identify from "../../components/identify.vue"
export default {
    
    data(){
        // const validatePass = (rule, value, callback)=>{
        //     if(value==""){
        //         callback(new Error("请填写确认密码"))
        //     }else if(value!==this.regitsData.password){
        //         callback(new Error('两次输入的密码不同'))
        //     }else{
        //         callback()
        //     }
        // }
        const validateIdent = (rule,value,callback)=>{
                    
            if(value!=this.identifyCode){
                return callback(new Error("验证码错误"))
            }else{ 
                callback()
            }
        }
        const validatePass = (rule,value,callback)=>{
                    
            if(value!=this.regitsData.password){
                callback(new Error("两次输入的密码不同"))
            }else{
                callback()
            }
        }
        return{
            step:1,
            regitsData:{
                phone:"",
                identifyNum:"",
                captcha:"",
                password:"",
                password_confirm:""
            },
            canvasHeight:0,
            canvasWidth:0,
            // 公司类型
            typeList:[],
            // 支付类型
            payTypeList:[],
            reserRule:{
                phone:[
                        { len:11, message: '请输入正确的手机号', trigger: 'blur' }
                ],
                identifyNum:[
                        { validator: validateIdent, trigger: 'blur' }
                ],
                captcha:[
                        { len:6, message: '请输入正确的验证码', trigger: 'blur' }
                ],
                password:[
                    { required: true, message: '请填写密码', trigger: 'blur' },
                ],
                password_confirm:[
                    { validator: validatePass, trigger: 'blur' }
                ]
            },
            isPass:true,
            vcodeBtnName:"获取验证码",
            nexText:"下一步",
            hasCaptcha:false,
            countDownTimer:"",
            countNum:120,
            identifyCode:"",
            identifyCodes: "1234567890",
        }
    },
    methods:{
     
        toLogin(){
            this.$router.push("/login")
        },
       
        async next(){
            if(this.step==1){
               
               
                this.$refs.reRef1.validate((flag)=>{
                    if(flag){
                       
                        this.step++
                    }else{

                    }
                })

            }else if(this.step==2){
                this.$refs.reRef1.validate(async (flag)=>{
                    if(flag){
                       
                        let res = await this.$api.checkSMS({ 
                            phone:this.regitsData.phone,
                            captcha:this.regitsData.captcha
                        })
                        // 检查验证码
                        if(res){
                            this.step++
                            this.nexText = "确定"
                        }else{
                            return this.$erMsg("验证码不正确!!")
                        }
                    }else{

                    }
                })
                
            }else if(this.step==3){
                this.$refs.reRef1.validate(async (flag)=>{
                    if(flag){
                        const res = await this.$api.forgetPassword(this.regitsData)
                        if(res){
                            this.step++
                            this.nexText = "马上登录"
                        }else{
                            this.$erMsg("修改失败")
                        }
                    }else{

                    }
                })
            }else if(this.step==4){

                this.toLogin()
            }
            
        },
        async getCaptcha(){
        
          if(this.regitsData.phone.length!=11){
             return this.$erMsg("请填写正确的手机号")
          }
          if (this.vcodeBtnName != '获取验证码' && this.vcodeBtnName != '重新发送'){return ;}
			
			
            // 与后端 api 交互，发送验证码 【自己写的具体业务代码】
            const res = await this.$api.sendSms({
                phone:this.regitsData.phone,
                type:3
            })
            if(!res){
                this.$erMsg("发送失败，手机未注册")
            }else{
                this.vcodeBtnName =  "发送中...";
                this.$suMsg("短信已发送，请注意查收")
                this.countNum = 120;
                this.countDownTimer = setInterval(function(){this.countDown();}.bind(this), 1000);
                this.hasCaptcha = true
            }
			
        },
        countDown(){
            if (this.countNum < 1){
                clearInterval(this.countDownTimer);
                this.vcodeBtnName = "重新发送";
                return ;
            }
            this.countNum--;
            this.vcodeBtnName =  this.countNum + '秒重发';
        },
        // 下一步
    
        randomNum(min, max) {
            return Math.floor(Math.random() * (max - min) + min);
        },
        refreshCode() {
            
            this.identifyCode = "";
            this.makeCode(this.identifyCodes, 4);
        },
        makeCode(o, l) {
            for (let i = 0; i < l; i++) {
                this.identifyCode += this.identifyCodes[this.randomNum(0, this.identifyCodes.length)];
            }
           
        },
        changeIdent(){
            this.identifyCode = "";
            this.makeCode(this.identifyCodes, 4);
        }
       
    },
    async created(){
        this.changeIdent()
    },
    mounted(){
        this.$nextTick(()=>{
           this.canvasHeight  =  this.$refs.canFaDiv.offsetHeight
           this.canvasWidth = this.canvasHeight*2.5
        //    console.log()
        })
        // 
    },
    components:{
        Identify
    }
}
</script>

<style lang="scss">
.ResetPassword{
    .regisHead{
       @include hnwHeader;
    }
    .cotainer{
        // padding: 2.121212rem /* 70/33 */;
        padding: 2.121212rem /* 70/33 */ 0;
        background: #F2F2F2;
        display: flex;
        justify-content: center;
        .box{
            // padding: 1.515152rem /* 50/33 */ 9.090909rem /* 300/33 */;
            width: 60%;
            min-width: 1000px;
            padding: 1.515152rem /* 50/33 */ 0;
            background: #fff;
            border-radius: 6px;
            .step{
                display: flex;
                width: 90%;
                transform: translateX(5%);
                .step-item{
                    flex: 1;
                    .serial{
                        position: relative;
                        height: 45px;
                        .num{
                            // background: #3E84D6;
                            // color: #fff;
                            border:1px solid #A8A8A8;
                            color: #999;
                            border-radius: 50%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 45px;
                            height: 45px;
                            font-size: 30px;
                            position: absolute;
                            left: 50%;
                            background: #fff;
                            transform:translateX(-50%);
                            z-index: 2;
                        }
                        .line{
                            border-top: 1px dashed #ccc;
                            position: absolute;
                            top: 50%;
                            transform: translateY(-50%);
                            width: 100%;
                        }
                    }
                    .step-text{
                        color: #999;
                        text-align: center;
                        padding: 20px 0;
                        font-size: .727273rem /* 24/33 */;
                    }
                }
                .active{
                    .serial{
                        .num{
                            background:#3E84D6;
                            border: 1px solid #3E84D6;
                            color: #fff;
                        }
                        .line{
                             border-top: 1px solid #3E84D6;
                             
                        }   
                    }
                    .step-text{
                        color: #3E84D6;
                    }
                }

            }
            .form{
                padding: 1.818182rem /* 60/33 */ 3.636364rem /* 120/33 */;
                box-sizing: border-box;
                display: flex;
                justify-content: center;
                .el-form{
                    width: 540px;
                    .el-form-item__content{
                        position: relative;
                        .input-box{
                            border: 1px solid #ccc;
                            border-radius: .242424rem /* 8/33 */;
                            // height: .464976rem /* 77/165.6 */;
                        
                            height: 2.30303rem /* 76/33 */;
                            display: flex;
                            align-items: center;
                            padding: .757576rem /* 25/33 */ .30303rem /* 10/33 */;
                        
                            box-sizing: border-box;
                            
                            input{
                                border: none;
                            
                                margin-left: .909091rem /* 30/33 */;
                            
                                
                                outline: none;
                                
                                font-size: .484848rem /* 16/33 */;
                                min-width:6.363636rem /* 210/33 */;
                                
                                &::placeholder{
                                    color: #CCCCCC;
                                    
                                }
                            }   
                        }
                        .disabinuBox{
                            background: #eee;
                        }
                    }
                    .capcha-box{
                        width: 55%; 
                    }
                    .capcha-box-btn{
                        position: absolute;
                        height: 100%;
                        right: 0;
                        
                        display: flex;
                        align-items: center;
                        box-sizing: border-box;
                        .dxYzm-btn{
                            height: 100%;
                            width: 6.060606rem /* 200/33 */;
                        }
                    }
                    .exchange{
                        color: #4099FF;
                        font-size: 24px;
                        position: absolute;
                        right: -2.575758rem /* 85/33 */;
                        cursor: pointer;
                    }
                    
                }
            
                .nextBox{
                    text-align: center;
                    .el-button{
                        width: 100%;
                        height: 2.060606rem /* 68/33 */;
                    }
                }
                .exchange{
                    position: absolute;
                    right: 0;
                }
            }
          
        }

    }
}

</style>