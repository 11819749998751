<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  created(){ 
    
    // this.authAmc();
    console.log("2");
  },
  methods:{
    async authAmc(){
      let isok = false
      try {
        let devMac = ""
        if(this.$store.state.member_id){
          devMac = await this.$api.deviceMacCdde({
            member_id: this.$store.state.member_id,
            member_name: this.$store.state.member_name,
            buyer_id: this.$store.state.store_id,
            buyer_name: this.$store.state.store_name
          })
        }
        
        if(devMac.length>0){
            let MyMacCddeList = await this.$api.getMyMacCdde({
              member_id: this.$store.state.member_id
            })
            
            if(MyMacCddeList&&MyMacCddeList.length){
              devMac.forEach(devmac => {
                if(!isok){
                  isok = MyMacCddeList.some(item=>item.mac_md5==devmac.mac_md5)
                }
              });
              // MyMacCddeList.some(item=>item.mac==)
            }
        }else{
          console.log("本地mac地址获取失败");
          // this.$warMsg("本地mac地址获取失败")
          this.$router.push("/login")
        }
      } catch (error) {
          console.log("获取本地mac地址发生错误");
          // this.$warMsg("获取本地mac地址发生错误")
          this.$router.push("/login")
      }
      if(isok){
        console.log("已授权");
      }else{
        console.log("未授权");
        this.$warMsg("设备未授权")
        this.$router.push("/login")
      }
    }
  },
  updated(){ 
  }
}
</script>


<style lang="scss">

#app {
  
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333333;
  font-size: 14px;
  min-width: 1200px;
  height: 100%;
}


@media (min-width:1024px) {
  html{font-size:18px}
}


@media (min-width: 1100px ){
  html{font-size:20px}
}

 
@media (min-width:1280px){
  html{font-size: 22px}
}

@media (min-width:1366px){
  html{font-size: 24px}
}

@media (min-width:1440px){
  html{font-size: 25px}
}

@media (min-width:1680px){
  html{font-size: 28px}
}

@media (min-width:1920px){
  html{font-size: 33px}
}

</style>
