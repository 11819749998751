<template>
    <!-- 地址选择组件 -->
    <div class="AdressSelect">
        <el-select v-model="prov" placeholder="省份" :size="size" @change="provChange">
            <el-option v-for="(option,index) in arr" :value="option.name" :key="index" @click="provChange(option.name)">
            {{ option.name }}
            </el-option>
        </el-select>
        <el-select v-model="city"  placeholder="城市" :size="size" @change="cityChange">
            <el-option v-for="(option,index) in cityArr" :value="option.name" :key="index" @click="cityChange(option.name)">
            {{ option.name }}
            </el-option>
        </el-select>
        <el-select v-model="district" v-if="district" placeholder="区域" :size="size">
            <el-option v-for="(option,index) in districtArr" :value="option.name" :key="index">
            {{ option.name }}
            </el-option>
        </el-select>
    </div>
</template>

<script>
import area from '../tools/adress.js'
export default {
    data(){
        
        
        return{
            arr: area.arrAll,
            prov: '省份',
            city: '城市',
            district: '区域',
            cityArr: [],
            districtArr: []
        }
    },
    methods:{
        provChange(prov){  
            for (var i in this.arr) {
                    var obj = this.arr[i];
                    if (obj.name) {
                        if (obj.name == prov) {
                            this.cityArr = obj.sub;
                            // 选中省后，如果为直辖市，直接将该直辖市下得所有区加到数组中
                            // if(this.cityArr.length==2){
                                this.cityChange(this.cityArr[1].name)
                            //     }
                            break;
                        }
                    }
                }
            this.city = this.cityArr[1].name;
        },

        cityChange(city){ 
            for (var i in this.cityArr) {
                var obj = this.cityArr[i];
                if (obj.name == city) {
                    this.districtArr = obj.sub;
                    break;
                }
            }
            if (this.districtArr && this.districtArr.length > 0 && this.districtArr[1].name) {
            this.district = this.districtArr[1].name;
            } else {
                this.district = '';
            }
        },

        updateCity() {
            for (var i in this.arr) {
                var obj = this.arr[i];
                if (obj.name) {
                    if (obj.name == this.prov) {
                        this.cityArr = obj.sub;
                        
                        break;
                    }
                }
            }
            this.city = this.cityArr[1].name;
        },
        updateDistrict() {
            for (var i in this.cityArr) {
            var obj = this.cityArr[i];
            if (obj.name == this.city) {
            this.districtArr = obj.sub;
            break;
            }
            }
            if (this.districtArr && this.districtArr.length > 0 && this.districtArr[1].name) {
            this.district = this.districtArr[1].name;
            } else {
            this.district = '';
            }
        }
    },
  
    created(){
        // this.updateCity();
        // this.updateDistrict();
       
        if(this.site&&this.site.length>0){
            this.prov = this.site[0]
            this.city = this.site[1]
            this.district = this.site[2]

        }
    },
    watch: {
      
        district(n){
            if(n!="区域"){
                this.$emit("upAdress",`${this.prov}-${this.city}-${this.district}`)
            }
        }
    },
    props:{
        site:{
            type:Array
        },
        size:{
            default:"medium"
        }
    }
}
</script>

<style lang="scss" scoped>
.AdressSelect{
    ::v-deep .el-select{
        input{
            width: 120px;
        }
        .el-input__inner{
            height: 23px;
            line-height: 23px;
            border-radius: 0;
            margin-right: 10px;
        }
        .el-input__suffix{
            right: 10px;
            .el-input__icon{
                line-height: 23px;
            }
        }
    }
}
</style>