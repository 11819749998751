<template>
  <div class="Photo">
    <div class="pic-box">
      
      <img :src="imgUrl" alt="" class="pic-img"  @click="dialogVisible=true">
    
    </div>
    <div class="img-list">
      <div
       v-for="(img,index) in imagesList" 
        :key="index"
        @click="setIndex(index)"
        class="img-url"
      >     
        <img :src="img.img_url" />    
      </div>

    </div>
    <!-- 图片全屏显示 -->
    <el-dialog :visible.sync="dialogVisible">
      <div class="vis-img-box">
        <i class="el-icon-arrow-left"  @click="changeIndex(1)" v-if="imagesList.length>1"></i>
        <i class="el-icon-arrow-right" @click="changeIndex(2)" v-if="imagesList.length>1"></i>
        <img width="100%" :src="imgUrl" alt="">
      </div>
    </el-dialog>
  </div>
</template>
<script>

export default {
  data() {
    return {
     
      // 展示的第几张图片
      showIndex:0,
      dialogVisible:false
    };
  },
  props: {
   
    imagesList: {
      type: Array,
      default: function() {
        return [];
      }
    }
  },
  methods: {
    setIndex(index) {
      this.showIndex = index;
    },
    changeIndex(type){
      if(type==2){
          if(this.showIndex==this.imagesList.length-1){
            this.showIndex = 0
          }else{
            this.showIndex ++
          }
      }else{
          if(this.showIndex==0){
            this.showIndex = this.imagesList.length-1
          }else{
            this.showIndex --
          }
      }
    }
  },
  computed:{

    imgUrl(){
      if(this.imagesList[this.showIndex]){
        return this.imagesList[this.showIndex].img_url
      }else{
        return 'https://jc.hnwmall.com/data/upload/shop/common/default_goods_image_.gif'
      }
    }

  }
};
</script>
<style lang="scss" scoped>
.Photo{
  overflow: hidden;
  width: 420px;
  .pic-box {
    position:relative;
    width: 420px;
    height: 420px;
    overflow: hidden;
    height: 100%;
    // border: 1px solid #eee;
    box-sizing: border-box;
    cursor: pointer;
    // box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.12);
    .pic-img{
      width: 420px;
      height: 420px;
      box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.12);
    }
  }
  
  .img-list{
    display: flex;
    width: 100%;
    margin-top: 10px;
    .img-url {
      width: 78px;
      height: 78px;
      border: 1px solid #eee;
      box-sizing: border-box;
      margin-right: 10px;
      cursor: pointer;
      &:nth-child(5) {
        margin-right: 0;
      }
      img {
        width: 76px;    
        height: 76px;
    
      }
    }
  }
  .vis-img-box{
    position: relative;
    i{
      position: absolute;
      opacity: 0;
      top: 40%;
      width: 40px;
      height: 40px;
      background: rgba(0,0,0,.3);
      cursor: pointer;
      color: #fff;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .el-icon-arrow-right{
      right: 0;
    }
  }
  .vis-img-box:hover{
    i{
      opacity: 1;
    }
  }
}

</style>