<template>
<div class="veh-info">
    <div class="part-info">
        <div class="part-img-box" v-show="showTreeImg">
            <div class="part-name">
                {{partInfo.mid}}
                {{partInfo.subgroup_label}}
                
            </div>
           
         <PartImg :partImgSty="partImgSty" @amplify="amplify" :imgSrc="imgSrc" :hotspots="hotspots" :partList="epcPart"  @canvaScaleChange="canvaScaleChange" @acDotChange="acDotChange" :activeDot="dot" v-if="showImg"></PartImg>
         
        </div>


        <div class="part-list">
            <div class="part-handle-head">
                <div class="btn" @click="showTreeImg=!showTreeImg">
                    <i class="el-icon-back" v-show="showTreeImg"></i>
                    <i class="el-icon-right" v-show="!showTreeImg"></i>
                    {{showTreeImg?'展开':"收起"}}
                        
                </div>
                <div class="hadle-right">
                    <div class="filter"  :class="{'active':listFilter}" @click="listFilter=!listFilter">
                        <img src="../../assets/catalog/filter.png" alt="">
                        过滤
                    </div>
                    <div class="search">
                        <el-autocomplete
                            class="inline-input"
                            size="mini"
                            v-model="partsName"
                            :fetch-suggestions="querySearch"
                            value-key="record_name"
                            placeholder="请输入配件名称或编号"
                            :hide-loading="true"
                        ></el-autocomplete>
                        <div class="btn" @click="partSearch">
                            搜索
                        </div>
                    </div>
                </div>

            </div>
            <div class="goods-list-box">
                <VinGoodsList v-if="showList" :goodsList="epcPart" listH="auto" :filterText="partsName" :brandCode="brandCode" :listFilter="listFilter" :canvaScale="canvaScale" :activePnum="activePnum" @rowClick="acDotChange"></VinGoodsList>
            </div>
        </div>
    </div>
    <el-drawer
        title="配件选择"
        :visible.sync="drawer"
        direction="btt"
        :withHeader="false"
        @closed="draClosed"
        size="90%"
        >
        <div class="body-list">
            <div class="list-tit">
                为您找到相关结果约{{partList.length}}个
            </div>
            
            <div class="body-part-list">
                <!-- @click="readInfo(part.pid)" -->
                <div class="part-item" v-for="(part,index) in partList" :key="index" @click="epcSearch(part)">
                    <div class="img-box">
                        <img :src="part.url" alt="">
                    </div>
                    <div class="item-right">

                        <div class="part-label">
                            <template  v-for="(debris,index2) in part.label">
                                <span :class="{'blue':debris==partsName}" :key="index2" v-if="debris">{{debris}}</span>
                            </template>
                        </div>
                        
                        <div class="part-params">

                            <div class="params-item">
                                <div class="label">
                                    零件号：
                                </div>
                                <div class="value">
                                    {{part.pid}}
                                    <!-- <template  v-for="(debris,index2) in part.pid">
                                        
                                        <span :class="{'blue':debris==partsName}" :key="index2" v-if="debris">{{debris}}</span>
                                    </template> -->
                                </div>
                            </div>

                            <div class="params-item">
                                <div class="label">
                                    主组：
                                </div>
                                <div class="value"> 
                                    {{part.maingroupname}}
                                </div>

                                <div class="label">
                                    分组：
                                </div>
                                <div class="value"> 
                                    {{part.mid}}
                                    {{part.struct_label}}
                                </div>
                            </div>

                            <div class="params-item">
                                <div class="label">
                                    型号：
                                </div>
                                <div class="value"> 
                                    {{part.model}}
                                </div>
                            </div>

                            <div class="params-item">
                                <div class="label">
                                    备注：
                                </div>
                                <div class="value"> 
                                    {{part.remark}}
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    </el-drawer>
</div>
</template>

<script>
import VinGoodsList from "./VinGoodsList"
import PartImg from "./PartImg"
export default {
    data(){
        return{
            goodsList:[],
            // 一开始搜索出来的列表
            partList:[],
            drawer:false,
            showTreeImg:true,
            showImg:false,
            showList:false,
            partsName:"",
            imgSrc:"",
            hotspots:[],
            epcPart:[],
            brandCode:"",
            mcid:"",
            listFilter:true,
            partImgSty:{
                width:"24rem",
                height:"100%"
            },
            partInfo:{},
            canvaScale:"",
            activePnum:"",
            dot:""
        }
    },
    methods:{
        querySearch(str,cb){
            
            cb([])
        },
        partSearch(){

        },
        amplify(isAmp){
            if(isAmp){
                this.partImgSty.width = "24rem"
            }else{
                this.partImgSty.width = "28rem"
            }
        },
        canvaScaleChange(canvaScale){
            this.canvaScale = canvaScale
        },
        
        acDotChange(pnum){
            console.log("????")
            this.activePnum = pnum
            this.hotspots.forEach(dot=>{
                if(dot.hsKey==pnum){
                    dot.active = true
                }else{
                    dot.active = false
                }

            })
            
        },

        async epcSearch(part){
            this.partInfo = part

            let res = await this.$api007.vinPartSearch({
                brandCode:this.brandCode,
                mcid:this.mcid,
                num:part.num,
                mid:part.mid,
                subgroup:part.subgroup
            })
           
            let nearList = []
            
         

            if(res.data.part_detail){

                res.data.part_detail.forEach(parts=>{
                    if(Array.isArray(parts)){
                        parts.forEach(part=>{
                            nearList.push(part)
                        })
                    }else{
                        nearList.push(parts)
                    }
                })
                
                // nearList = nearList.sort((part1,part2)=>Number(part1.itid)-Number(part2.itid))

              
            }else{
                console.error("未查询配件列表")
            }

            this.epcPart = nearList

            if(res.img.imgPoints){
                this.hotspots = res.img.imgPoints.hotspots
                
            }
            if(Array.isArray(this.hotspots)){
                this.hotspots.forEach(dot=>{
                    dot.left = dot.hsX + "px"
                    dot.top = dot.hsY + "px"
                    dot.width = dot.maxX -  dot.hsX + "px"
                    dot.height = dot.maxY -  dot.hsY + "px"
                    
                    // 该零件是否使用该车型

                    let temp =  nearList.filter(item=>item.pnum==dot.hsKey)
                    let isNot = temp.every(part=>part.colorvalue!=1)
                    if(isNot){
                        dot.title = "零件不适用该车型"
                    }
                    
                    this.$set(dot,"active",false)
                    
                    
                })
            }
            if(res.img){
                this.imgSrc = res.img.imagePath
            }
            this.drawer = false
            this.showImg = true
            this.showList = true
        },
        draClosed(){
            
            if(this.partInfo.mid){
                console.log("已选择了配件")
            }else{
                console.log("未选择配件")
                let list = JSON.parse(JSON.stringify(this.$store.state.epcNavList))
                list.pop()
                this.$store.commit("UpDateState",{
                    epcNavList:list
                })
                this.$router.go(-1)
            }
        }
    },
    components:{
        VinGoodsList,
        PartImg
    },
    async created(){
        
        
        // 获取配件信息
        this.brandCode = this.$route.params.brandCode
        
        this.mcid = this.$route.params.mcid.replace(/xiegang/g,"/")
        let res = await this.$api007.vinSearchByName({

            brandCode:this.brandCode,
            mcid:this.mcid,
            search_key:this.$route.params.oecode
        })
        
        if(res.data.length==1){
            await this.epcSearch(res.data[0])
        }else{
            this.partList = res.data
            this.drawer = true
        }        
    },
    
}
</script>

<style lang="scss">
.veh-info{
    flex: 1;
    .part-info{
        display: flex;
        
        height: 100%;
        box-sizing: border-box;
        background: #F4F6F7;
        .part-img-box{
            padding-top: 44px;
            .part-name{
                color: #333333;
                position: absolute;
                height: 44px;
                line-height: 44px;
                background: #F7F7F7;
                width: 100%;
                top: 0;
            }
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
           
            margin-right: 5px;
            margin-left: 5px;
            background: #fff;
            
           
            .hanlde-box{
                position: absolute;
                width: 100%;
                height: 100%;
                overflow: hidden;
                .han-rigt{
                    position: absolute;
                    right: 20px;
                    top: 60px;
                    >div{
                        width: 26px;
                        height: 26px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        box-shadow: 0px 0px 4px 0px rgba(167, 167, 167, 0.35);
                        border-radius: 3px;
                        margin-top: 10px;
                        cursor: pointer;
                        i{

                        }
                        img{
                            width: 16px;
                            height: 20px;
                        }
                    }
                }

            }
        }
        .part-list{
            flex: 1;
            overflow: auto;
            position: relative;
            background: #fff;
            display: flex;
            flex-direction: column;
            .part-handle-head{
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding-right: 20px;
                padding-left: 30px;
                height: 44px;
                background: #F7F7F7;    
                .btn{
                    color: #fff;
                    cursor: pointer;
                    background: #3E84D6;
                    width: 66px;
                    // width: 2rem /* 66/33 */;
                    height: 30px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    i{
                        margin-right: 5px;
                    }
                }
                .hadle-right{
                    display: flex;
                    .search{
                        display: flex;
                        align-items: center;
                        margin-right: 1.212121rem /* 40/33 */;
                        .el-input__inner{
                            border-radius: 0;
                            // width: 6.060606rem /* 200/33 */;
                            width: 8rem;
                        }
                        .btn{
                            width: 2.545455rem /* 84/33 */;
                            height: 28px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            cursor: pointer;
                            color: #fff;
                            background: #198AFF;
                        }

                    }

                    // 切换分组
                    .switch-handle{
                        display: flex;
                        align-items: center;
                        >div{
                            width: 80px;
                            // width: 2.424242rem /* 80/33 */;
                            height: .909091rem /* 30/33 */;
                            background: #fff;
                            cursor: pointer;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            margin-right: .606061rem /* 20/33 */;
                        }
                    }
                    .filter{
                        width: 70px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-right: 10px;
                        background: #fff;
                        cursor: pointer;
                    }
                    .active{
                        color: #3E84D6;
                        border: 1px solid #3E84D6;
                    }

                }
            }
            .goods-list-box{
                position: relative;
                flex: 1;
            }
        }
    }
    .body-list{
        flex: 1;
        .list-tit{
            color: rgba(0,0,0,.45);
            height: 30px;
            color: rgba(0,0,0,.45);
            padding: 5px 10px;
            display: flex;
            align-items: center;
        }
        .body-part-list{
            .part-item{
                display: flex;
                padding: 10px;
                cursor: pointer;
                border-bottom: 1px solid #eee;
                .img-box{
                    width: 150px;
                    height: 150px;
                    img{
                        width: 140px;
                        height: 140px;
                        border: 1px solid rgba(0,0,0,.1);
                    }
                }
                .item-right{
                    flex: 1;
                    padding-left: 10px;
                    .part-label{
                        font-size: 16px;
                        font-weight: 700;
                        margin-top: 10px;
                        margin-bottom: 10px;
                        .blue{
                            color: #1890ff;
                        }
                    }
                    .part-params{
                        .params-item{
                            display: flex;
                            height: 20px;
                            line-height: 20px;
                            .label{
                                color: rgba(0,0,0,.45);
                            }
                            .value{
                                margin-right: 10px;
                                .blue{
                                    color: #1890ff;
                                }
                            }
                        }
                    }
                }
            }
            .part-item:hover {
                box-shadow: 0 2px 12px 0 rgba(0,0,0,.15);
            }
        }
    }
}
</style>