<template>
<div class="sysStaff">
    <!-- 员工管理 -->
   <template v-if="tempNum==1">
       <div class="table-box">
            <BtnBox @click1="tempNum=2" btnText="新增账号" ></BtnBox>
            <div class="staffTable">
                <div class="table-head">
                    <div class="flex2">登录账号名称</div>
                    <div class="flex2">公司职位</div>
                    <div class="flex2">真实姓名</div>
                    <div class="flex2">手机号码</div>
                 
                    <div class="flex2">密码</div>
                    <div class="flex1">部门</div>
                    <div class="flex1">子部门</div>
                    <div class="flex2">操作</div>
                </div>
                <div class="table-body">
                    <div class="table-row" v-for="item in staffDataList" :key="item.account_id"> 
                         <div class="flex2">{{item.account_number}}</div>
                         <div class="flex2">{{item.position}}</div>
                         <div class="flex2">{{item.nickname}}</div>
                         <div class="flex2">{{item.account_phone}}</div>
                     
                        <div class="flex2">{{item.account_passwd}}</div>
                        <div class="flex1">{{item.role_name_far}}</div>
                        <div class="flex1">{{item.role_name}}</div>
                        <div class="handle">
                            <div>
                                 <!--  -->
                                <span class="edit-btn" @click="edit(item)">编辑</span>
                            </div>
                            <div>
                                <span class="del-btn" @click="del(item.account_id)">删除</span>
                            </div>
                            <div>
                                <span class="del-btn" @click="showLimit(item)">权限</span>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>

       </div>
   </template>
   <template v-if="tempNum==2">
       <div class="staffData-box">
            <el-form :model="staffData" :rules="staffRule" ref="staform" label-width="120px" >
                <el-form-item label="登录账号名称" prop="account_number">
                    <el-input v-model="staffData.account_number"></el-input>
                </el-form-item>
                <el-form-item label="公司职位" prop="position">
                    <el-input v-model="staffData.position" placeholder="昵称长度必须在1到10位中间"></el-input>
                </el-form-item>
                <el-form-item label="真实姓名" prop="nickname">
                    <el-input v-model="staffData.nickname" placeholder="昵称长度必须在1到10位中间"></el-input>
                </el-form-item>
                <el-form-item label="手机号" prop="account_phone">
                    <el-input v-model="staffData.account_phone"></el-input>
                </el-form-item>
                <el-form-item label="密码" prop="account_passwd">
                    <el-input v-model="staffData.account_passwd"></el-input>
                </el-form-item>
                <el-form-item label="部门" prop="pid" >
                    <el-select v-model="staffData.pid" placeholder="请选择主账号组">
                        <el-option :label="item.role_name" :value="item.role_id" v-for="(item,index) in roleList1" :key="index"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="子部门" prop="role_id">
                    <el-select v-model="staffData.role_id" placeholder="请选择子账号组">
                        <el-option :label="item.role_name" :value="item.role_id" v-for="(item,index) in roleList2" :key="index"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="是否可以查看库存">
                    <el-radio-group v-model="staffData.look_inv">
                            <el-radio label="1">可以查看</el-radio>
                            <el-radio label="0">不可查看</el-radio>
                            
                    </el-radio-group>
                </el-form-item>
                <el-form-item>
                     <el-button @click="reset">放 弃</el-button>
                    <el-button type="primary" @click="commit">提 交</el-button>
                </el-form-item>
            </el-form>
       </div>
   </template>
   <div class="sysStaff-limit-dia">
        <el-dialog
        :title="'员工权限 - '+staffData.nickname +' '+ staffData.account_phone"
        :visible.sync="limitVis"
        width="600px"
        >
            <div class="limit-modu" v-for="(item,index) in limitList" :key="index">
                <div class="limit-tit">{{item.title}}</div>
                <div class="limit-list">
                    <div class="limit-item" :class="{'active':limit.choose}" v-for="limit in item.limit" :key="limit.id">
                        <el-checkbox v-model="limit.choose"></el-checkbox>
                        <span>
                            {{limit.key_content}}
                        </span>
                    </div>
                </div>
            </div>

            <span slot="footer" class="dialog-footer">
                <!-- <el-button @click="limitVis = false">取 消</el-button> -->
                <el-button type="primary" @click="saveLimit">保存</el-button>
            </span>
        </el-dialog>
   </div>
   
</div>
</template>

<script>
import BtnBox from "./components/BtnBox"
export default {
    data(){
        return{
            tempNum:1,
            staffDataList:[
            ],
            // 是否为修改
            isEdit:false,
            // 一级权限分组
            roleList1:[],
            // 二级权限
            roleList2:[],
            staffData:{
                account_id:"",
                account_number:"",
                account_passwd:"",
                account_phone:"",
                nickname:"",
                role_id:"",
                role_name:"",
                role_name_far:"",
                pid:"",
                position:"",
                look_inv:"0"
            },
            limitVis:false,
            limitList:[],
            staffRule:{
                account_number:[
                     { required: true, message: '请输入登录账号名称', trigger: 'blur' }
                ],
                account_passwd:[
                    { required: true, message: '请输入登录密码', trigger: 'blur' }
                ],
                nickname:[
                     { required: true, message: '请输入昵称', trigger: 'blur' },
                     { min: 1, max: 10, message: '昵称长度超出限制', trigger: 'blur'}
                ],
                account_phone:[
                     { required: true, message: '请输入手机号', trigger: 'blur' },
                     { len:11, message: '请填写正确的手机号', trigger: 'blur'}
                ],
                role_id:[
                     { required: true, message: '请选择子分组', trigger: 'blur' }
                ],
                pid:[
                    { required: true, message: '请选择父分组', trigger: 'blur' }
                ]
            },
            
        }
    },
    methods:{

        del(account_id){
            this.$confirm('是否删除该账号?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                this.$message({
                    type: 'success',
                    message: '删除成功!'
                });
                
                const res = await this.$http("index.php?act=store_role&op=role_account_del",{
                    account_id
                })
                
                this.staffDataList =  this.staffDataList.filter(item=>item.account_id!=account_id)
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });          
            });
        },
        // 编辑权限
        showLimit(account){
           this.staffData = account
           
           this.limitList.forEach(item=>{
               item.limit.forEach(limi=>{
                   limi.choose =  this.staffData[limi.key_name] ==1?true:false
               })
           })

            this.limitVis = true
        },
        async saveLimit(){
            let editObj = {
                account_id:this.staffData.account_id
            }
           this.limitList.forEach(item=>{
               item.limit.forEach(limi=>{
                   editObj[limi.key_name] = limi.choose?1:0
               })
           })
        //    
            const res =  await this.$http("index.php?act=store_role&op=power_over",{
                    ...editObj
                })
            if(res){
                this.$suMsg("修改成功")
                this.getList()
            }else{
                this.$erMsg("修改失败")
            }
            this.limitVis = false
            
        },
        edit(account){
            this.isEdit = true
            this.staffData = account
            this.staffData.look_inv = this.staffData.look_inv?this.staffData.look_inv:"0"
            console.log("account",account)
            this.tempNum  = 2
        },
        async getList(){
            this.$set(this,"staffDataList",[])
            this.staffDataList = await this.$http("index.php?act=store_role&op=role_account_list",{
                store_id:this.$store.state.store_id
            })
        },
        reset(){
            for(let k in this.staffData){
                this.staffData[k] = ""
            }
            this.staffData.look_inv = "0"
            this.tempNum = 1
        },
        commit(){
            let msg = ""
            let url = ""
             if(this.isEdit){
                 msg = "修改完成"
                 url="index.php?act=store_role&op=role_account_edit"
            }else{
                url="index.php?act=store_role&op=role_account_add"
                msg = "添加成功"
            }
            
            this.$refs.staform.validate(async flag=>{
               
                if(flag){
                    // 父
                    let role = this.roleList1.find(item=>item.role_id==this.staffData.pid)
                    // 子
                    let role1 = this.roleList2.find(item=>item.role_id==this.staffData.role_id)
                    this.staffData.role_name_far = role.role_name
                    this.staffData.role_name = role1.role_name
                    
                    let data = {
                        ...this.staffData,
                        store_id:this.$store.state.store_id
                    }
                    
                    if(!this.isEdit){
                        data.store_name = this.$store.state.store_name
                    }
                    const res = await this.$http(url,{
                        ...data
                    })
                    
                    if(!res){
                        return this.$erMsg("注册失败，账号已被注册")
                    }else if(res.code==2020){
                        return this.$erMsg(res.msg)
                    }
                    this.getList()
                    this.$suMsg(msg)
                    this.reset()
                }
            })
           
            
        },
        // 获取权限列表
        async getRoleList(pid){
         
           this.roleList2 = await this.$http("index.php?act=store_role&op=role_list",{
                store_id:this.$store.state.store_id,
                pid
            })
           
        }
    },
    async created(){
        this.roleList1 =  await this.$http("index.php?act=store_role&op=role_list",{
            store_id:this.$store.state.store_id,
            pid:0
        })
        // this.roleList1 = this.roleList1.filter(item=>{
        //     return item.role_name == "采购部"||item.role_name == "采购组"
        // })
        this.getList()
        // 获取特殊权限列表  
        this.limitList = await this.$http("index.php?act=store_role&op=power_list")
        this.limitList.forEach(item=>{
            item.limit.forEach(limi=>{
                this.$set(limi,"choose",false)
            })
        })
    },
    components:{
        BtnBox
    },
    computed:{
        pid(){
            return this.staffData.pid
        }
    },
    watch:{
        "staffData.pid":{
            deep:true,
            handler(n){
                this.staffData.roleMain = n
                this.getRoleList(n)
            }
        },
        tempNum(n){
            if(n==1){
                this.reset()
                this.isEdit = false
                this.getList()
            }
        }
    }
}
</script>

<style lang="scss">
.sysStaff{
    .table-box{
       .BtnBox{
           width: 98%;
       }
        .staffTable{
            border: 1px solid #ccc;
            border-left: none;
            border-right: none;
            .table-head{
               display: flex;
                text-align: center;
                >div{
                    padding: 10px 15px;
                    
                   color: #fff;
                   background: #6DB5FF;
                   background: #409EFF;
                   color: #fff;
                }
                >div:last-child{
                    border-right: none;
                }
                .flex1{
                    flex: 1;
                }
                .flex2{
                    flex: 2;
                }
            }
            .table-body{
                .table-row{
                    display: flex;
                    text-align: center;
                    border-top: 1px solid #ccc;
                    >div{
                        padding: 10px 15px;
                        
                    }

                    >div:last-child{
                        border-right: none;
                        padding: 0 15px;
                    }
                    .flex1{
                        flex: 1;
                    }
                    .flex2{
                        flex: 2;
                    }
                    .handle{
                        display: flex;
                        justify-content: center;
                        flex: 2;
                        span{
                            cursor: pointer;
                        }
                        >div{
                           margin: 0 10px;
                            display: flex;
                            align-items: center;
                            justify-content: center;    
                        }
                        div:first-child{
                            
                        }
                        .del-btn{
                            color: red;
                        }
                        .edit-btn{
                            color: rgb(61, 132, 212);
                        }
                    }
                }
            }
        }
    }
    // 权限模态框
    .sysStaff-limit-dia{
        .el-dialog__header{
            padding: 20px;
        }
        .el-dialog__body{
            padding: 0;
            .limit-modu{
                padding: 20px;
                border-top: 1px solid #DFDFDF;
                .limit-tit{
                    color: #000;
                    font-size: 16px;
                    padding-bottom: 10px;
                }
                .limit-list{
                    .limit-item{
                        padding-left: 20px;
                        padding-bottom: 5px;
                        
                        span{
                            color: #777;
                        }
                    }
                    .active{
                        span{
                            color: #000;
                        
                        }
                    }
                }
            }
            .limit-modu:last-child{
                padding-bottom: 0;
            }
        }
    }
}
</style>