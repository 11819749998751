<template>
<div class="epc-pur-from">
    <el-dialog
        :title="this.actiPart.id?'修改采购单':'加入采购单'"
        :visible.sync="purCartVis"
        width="60%"
        custom-class="pur-form-dia"
    >
        <el-form :model="actiPart" size="mini" label-width="120px">
            <el-form-item label="配件编码" >
                <el-input v-model="actiPart.goods_oecode" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="配件名称" >
                <el-input v-model="actiPart.goods_name" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="适用车型" >
                <el-input v-model="actiPart.models" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="参考采购价(含税)" >
                <el-input v-model="actiPart.purchase_price" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="品牌" >
                <el-input v-model="actiPart.goods_brand" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="供应商" >
                <el-input v-model="actiPart.supplier" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="数量" >
                <el-input-number v-model="actiPart.goods_number" ></el-input-number>
            </el-form-item>
            <el-form-item label="价格" >
                <el-input v-model="actiPart.goods_price" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="车架号" >
                <el-input v-model="actiPart.data" autocomplete="off"></el-input>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="purCartVis=false" size="mini">取 消</el-button>
            <el-button type="primary" @click="commitPurData" size="mini">确 定</el-button>
        </span>
    </el-dialog>
</div>
</template>

<script>
export default {
    data(){
        return{
            purCartVis:false,
            actiPart:{

            }
        }
    },
    methods:{
        
        async commitPurData(){
            
            let res = await this.$api.addVinPur({
                id:this.actiPart.id,
                goods_oecode:this.actiPart.goods_oecode,
                goods_name:this.actiPart.goods_name,
                goods_number:this.actiPart.goods_number,
                goods_price:this.actiPart.goods_price,
                purchase_price:this.actiPart.goods_price,
                models:this.actiPart.models,
                supplier:this.actiPart.supplier,
                goods_brand:this.actiPart.goods_brand,
                data:this.actiPart.data,
                store_id:this.$store.state.store_id,
                store_name:this.$store.state.store_name,
                member_id:this.$store.state.member_id,
                member_name:this.$store.state.member_name
            })
            if(res){
                this.$suMsg("操作成功!")
                this.purCartVis = false
                this.$emit("over")
            }else{
                this.$erMsg("操作失败!")
            }
        }
    },
    created(){

    },
    props:{
        
    }
}
</script>

<style lang="scss">
.epc-pur-from{
    .pur-form-dia{
        padding: 10px;
        .el-dialog__header{
            padding-bottom: 10px;
        }
        .el-form-item{
            margin-bottom: 10px;
        }
        .el-dialog__footer{
            padding-bottom: 10px;
        }
    }
}
</style>