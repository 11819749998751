<template>
<div class="my-message">
<!-- 消息 -->
<div class="msg-menu-list">
    <div class="menu-item" :class="{'active':msgType==1}" @click="msgType=1">
        <div class="img-box">
            <img src="../../assets/my/msg/ld.png" alt="">
        </div>
        <span>活动消息</span>
    </div>
    
    <div class="menu-item"  :class="{'active':msgType==2}" @click="msgType=2">
        <div class="img-box">
            <img src="../../assets/my/msg/car.png" alt="">
        </div>
        <span>交易物流</span>
    </div>
    
    <em>
        <!-- con-lmit-oo[ - ] -->
    </em>

</div>

<div class="msg-body" v-if="msgType==1">
    <div class="body-item"  v-for="(item,index) of message " :key="index">
        
            <div class="item-top">
            <div class="tit">
                <!-- 秒杀活动 -->
                {{item.message_title}}
            </div>
            <div class="time">
                <!-- {{item.message_time}} -->
                {{$hnwTools.formatDateTime(item.message_time,'ymd')}}
            </div>
        </div>
        <div class="item-body">
            <!-- <span>{{item.end_time}}</span> -->
                <span>集单商品:{{item.goods_name}},</span>
            <span>集单价格:{{item.groupbuy_price}},</span>
            <span>集单总额:{{item.totalsum}},</span>
            <span>集单日期:{{$hnwTools.formatDateTime(item.start_time,'ymd')}} - {{$hnwTools.formatDateTime(item.end_time,'ymd')}} </span>
        </div>
        <el-button @click="del(item.message_id)">删除</el-button>
    </div>
</div>

<div class="msg-body" v-if="msgType==2">
    
    <!-- <div class="body-item"  v-for="(item,index) of message " :key="index">
        
            <div class="item-top">
            <div class="tit">
           
                {{item.message_title}}
            </div>
            <div class="time">
              
                {{$hnwTools.formatDateTime(item.message_time,'ymd')}}
            </div>
        </div>
        <div class="item-body">
          
            <span>集单商品:{{item.goods_name}}</span>
            <span>集单价格:{{item.groupbuy_price}}</span>
            <span>集单总额:{{item.totalsum}}</span>
            <span>集单日期:{{$hnwTools.formatDateTime(item.start_time,'ymd')}} - {{$hnwTools.formatDateTime(item.end_time,'ymd')}} </span>
        </div>
        <el-button @click="del(item.message_id)">删除</el-button>


    </div> -->
</div>
</div>
</template>

<script>
export default {
    data(){
        return{
            msgType:1,
            message:[],
            messageList:[],
            visible: false,
        }
    },
    created(){
     this.getAllList()
    },
    methods:{
        async getAllList(){
        
            let res= await this.$api.getMessage({
                member_id:this.$store.state.member_id,
            })
            this.message = res

        },
    
        async del(index){
            this.$affMsg("确定要删除吗?",async ()=>{
				let res= await this.$api.delMessage({
                member_id:this.$store.state.member_id,
                message_id:index
            })
				if(res){
					this.getAllList()
					this.$suMsg("删除成功")
				}else{
					this.$erMsg("删除失败")
				}
			},()=>{
				this.$infoMsg("已取消")
            })
            
        },
    },
     
    props:{
        
    }
}
</script>

<style lang="scss">
.my-message{
    display: flex;
    .msg-menu-list{
        width: 130px;
        .menu-item{
            height: 72px;
            display: flex;
            align-items: center;
            padding-left: 10px;
            cursor: pointer;
            .img-box{
                width: 32px;
                height: 32px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                background: #C3CCD3;
                margin-right: 10px;
                img{
                    width: 18px;
                }
            }
        }
        .active{
            border-left: 2px solid #3E84D6;
            border-right: 1px solid #F5F5F5;
            background: #fff;
        }
    }
    .msg-body{
        background: #fff;
        flex: 1;
        .body-item{
            border: 1px solid #F5F5F5;
            border-left: none;
            height: 95px;
            padding: 10px 20px;
            padding-right: 80px;
            box-sizing: border-box;
            position: relative;
            .item-top{
                display: flex;
                .tit{
                    font-size: 16px;
                    margin-right: 10px;
                }
                .time{
                    color: #999;
                }
            }
            .item-body{
                color: #666;
                margin-top: 10px;
                height: 28px;
                overflow: hidden;
                span{
                    margin-right: 15px;
                }
            }
            .el-button{
                position: absolute;
                top: 26px;
                right: 22px;
            }
        }
    }
}
</style>