<template>
<div class="scd-part-tem-img" :style="partImgSty" >
    <div class="epc-img"  @mousewheel.stop="imgZoom" @mousedown="dragStart" @mousemove="canvasdDrag" @mouseout="dragOut" @mouseup="dragEnd"  ref="cansBox">
      
        <div class="img-box" :style="canvaSty"  >  
            <img :src="imgSrc"  alt="" id="imgElement">

            <div v-for="(dot,index) in hotspots" :key="index" @click="sevenCanvasClick(dot.pots_number)">
                <div  class="img-dot" :class="{'active':dot.active}" :title="dot.title" :num="dot.index" :style="item" v-for="(item,index) in dot.dots" :key="index"></div>
            </div>
        </div>
       
        <span class="scaleNum" >{{canvaScale}}</span>

        <div class="han-row">
            <div class="left">

            </div>
            <div class="right">
                <!-- <div class="btn" @click="lauImg($event)">全屏</div> -->
                <div @click="imgZoom({wheelDelta:1})">
                    <i class="el-icon-zoom-in" ></i>
                </div>
                <div  @click="imgZoom({wheelDelta:-1})">
                    <i class="el-icon-zoom-out"></i>
                </div>
                <div @click="reset">
                     <!-- @click="switchGroup(1)" -->
                    <div class="tab">
                        复位
                    </div>
                    <!-- <img src="../../assets/catalog/loca.png" alt=""> -->
                </div>
                <div @click="amplify">
                    <div class="tab" v-if="isAmp">
                        折叠
                    </div>
                    <div class="tab" v-else>
                        展开
                    </div>
                    <!-- <i class="el-icon-back" v-if="isAmp"></i>
                    <i class="el-icon-right" v-else></i> -->
                </div>
            </div>
        </div>

        <!-- <div class="han-rigt">
            <div @click="imgZoom({wheelDelta:1})">
                <i class="el-icon-zoom-in" ></i>
            </div>
            <div  @click="imgZoom({wheelDelta:-1})">
                <i class="el-icon-zoom-out"></i>
            </div>
            <div @click="reset">

                <img src="../../assets/catalog/loca.png" alt="">
            </div>
            <div @click="amplify">
                
                <i class="el-icon-back" v-if="isAmp"></i>
                <i class="el-icon-right" v-else></i>
            </div>
        </div> -->
        
    </div>
</div>
</template>

<script>
export default {
    data(){
        return{
            canvaScale:0.5,
            isMove:false,
            // 鼠标对象
            cansBox:{

            },
            startPos:{

            },
         
            //  "transform":`scale(0.2)`,
            canvaSty:{
                w:0,
                h:0,
                "transform":`scale(0.5)`,
                left:"0",
                top:"0"
            },
            isAmp:true,
            initScale:'',
            imgH:0
        }
    },
    methods:{
       
        imgZoom(event){
            this.$store.commit("UpDateState",{
                isBoxScrool:"hidden"
            })
           
            if(event.wheelDelta>0){
            
                if(this.canvaScale<1.2){
                    this.canvaScale =  this.$hnwTools.plus(Number(this.canvaScale),0.05)
                    
                }
            }else{
            
                if(this.canvaScale>0.1){
                    this.canvaScale = this.$hnwTools.minus(Number(this.canvaScale),0.05)
                }
            }
            
            this.canvaSty.transform = `scale(${this.canvaScale})`
            this.$emit("canvaScaleChange",this.canvaScale)
        },
        reset(){
            // this.canvaSty.transform = "rotate(90deg)"
            
            this.canvaSty.transform = `scale(${this.initScale})`
            this.canvaSty.left = 0 + "px"
            this.canvaSty.top = 0 + "px"
            this.canvaScale = this.initScale
        },
        amplify(){
            if(this.isAmp){
                
                this.$emit("amplify",this.isAmp)
                this.isAmp = false
            }else{
                this.$emit("amplify",this.isAmp)
                this.isAmp = true
            }
        },
        dragStart(e){
            this.isMove = true
            // console.log("鼠标按下")
          
            // 鼠标开始在元素上得位置
            
            // 鼠标进入时跟元素零点得距离 老方法
          
            this.startPos.x = e.x -  this.cansBox.x - Number(this.canvaSty.left.split("px")[0])
            this.startPos.y = e.y -  this.cansBox.y - Number(this.canvaSty.top.split("px")[0])
           
            // 新方法
            // this.startPos.x = e.layerX - Number(this.canvaSty.left.split("px")[0])
            // this.startPos.y = e.layerY - Number(this.canvaSty.top.split("px")[0])
        },
        canvasdDrag(e){
            // console.log("鼠标移动")
            let x,y
            if(this.isMove){
                
            //    鼠标在盒子里得位置减去 鼠标进入盒子时 距离元素得偏移值
            //    老方法
               x = e.x  - this.cansBox.x - this.startPos.x 
               y = e.y  - this.cansBox.y - this.startPos.y
               // 新方法
            //    x = e.layerX - this.startPos.x  
            //    y = e.layerY - this.startPos.y 
               this.canvaSty.left = x + "px"
               this.canvaSty.top = y  + "px"

            }
            
        },
        
        dragEnd(){
            // console.log("鼠标松开")
            this.isMove = false
           
        },
        dragOut1(){ 
        },
        dragOut(){

            this.isMove = false
            
            this.$store.commit("UpDateState",{
                isBoxScrool:"auto"
            })
        },
        lauImg(){
            let imgElement = document.getElementById('imgElement')
            if(imgElement.requestFullScreen) {
                imgElement.requestFullScreen();
            } else if(imgElement.mozRequestFullScreen) {
                imgElement.mozRequestFullScreen();
            } else if(imgElement.webkitRequestFullScreen) {
                imgElement.webkitRequestFullScreen();
            }
        },
        sevenDrawCanvas(){
            const cas =document.getElementById("imgElement");
        
            let canvaImg = new Image()

            canvaImg.src = this.imgSrc  
            // let boxW = 350
            // 清空画布上的矩形
            canvaImg.onload = ()=>{  
                this.canvaSty.w = canvaImg.width
                this.canvaSty.h = canvaImg.height
                // this.canvaScale =  boxW / canvaImg.width
                if(this.imgSrc.indexOf('.xi.')==-1){
                    this.canvaScale =  0.1
                }else{
                    this.canvaScale =  0.5
                }
                
                this.canvaScale = this.canvaScale.toFixed(2) > 0.1?this.canvaScale.toFixed(2):0.1
                this.canvaSty.transform = `scale(${this.canvaScale})`
                this.initScale = this.canvaScale
                this.$emit("canvaScaleChange",this.canvaScale)
            }
        },
        sevenCanvasClick(index){  
            this.$emit("acDotChange",index,true)
        },
    },
    created(){  
    },
    mounted(){
        this.$nextTick(()=>{
            this.cansBox.x = this.$refs.cansBox.offsetLeft
            this.cansBox.y = this.$refs.cansBox.offsetTop
            this.sevenDrawCanvas()
        })
    },
    updated(){
        // if(this.imgSrc.indexOf('.xi.')==-1){
        //     this.sevenDrawCanvas() 
        // }
    },
    watch:{
            
    },
    props:{
        partImgSty:{
            required:true
        },
        // 所有点数
        hotspots:{
            required:true
        },
        imgSrc:{
            default:"../../assets/catalog/876807000.png"
        }
    }
}
</script>

<style lang="scss">
.scd-part-tem-img{
    height: calc(100% - 38px);
    .epc-img{                
        width: 100%;
        height: 100%;
        background: #fff;
        user-select: none;
        // overflow: auto;
        overflow: hidden;
        position: relative;

        .img-box{
            position: absolute;
            transform-origin: left top;
            user-select: none;
            
            img{
                user-select: none;
                position: absolute;
                pointer-events: none; // 元素永远不会成为鼠标事件的target
            }
            .img-dot{
                position: absolute;
                cursor: pointer;
                z-index: 1;
                user-select: none;
                border-radius: 50%;
                transform: scale(1.5);
            }
            .cur{
                background: rgba(238, 238, 238,.4);
                cursor: pointer;
            }
            .active{
                z-index: 2;
                background: #007bff66;
                color: #fff;
            }
            
        }
        
        .scaleNum{
            background: #000;
            color: #fff;
            padding: 2px 4px;
            position: absolute;
            right: 0;
            bottom: 0;
        }
        // 操作按钮
        .han-row{
            position: absolute;
            top: 10px;
            padding-right: 20px;
            padding-left: 10px;
            box-sizing: border-box;
            display: flex;
            justify-content: space-between;
            width: 100%;
            z-index: 2;
            
           
            .right{
              display: flex;
              align-items: center;
              >div{
                padding: 0 8px;
                color: #015f8b;
                font-weight: 700;
                font-size: 28px;
                cursor: pointer;
              }
              .tab{
                font-weight: 700;
                cursor: pointer;
                width: 40px;
                height: 28px;
                line-height: 28px;
                text-align: center;
                background: $scdBlue;
                color: #015f8b;
                font-size: 14px;
                border-radius: 6px;
                i{
                    font-size: 20px;
                }
              }
            }
        }

        .han-rigt{
            position: absolute;
            right: 20px;
            top: 50px;
            z-index: 3;
            >div{
                width: 26px;
                height: 26px;
                display: flex;
                justify-content: center;
                background: #fff;
                align-items: center;
                box-shadow: 0px 0px 4px 0px rgba(167, 167, 167, 0.35);
                border-radius: 3px;
                margin-top: 10px;
                cursor: pointer;
                i{

                }
                img{
                    width: 16px;
                    height: 20px;
                }
            }
        }
        // .hanlde-box{
        //     position: absolute;
        //     width: 100%;
        //     height: 100%;
        //     overflow: hidden;
        //     // z-index: -1;
        // }
    }
}
</style>