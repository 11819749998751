<template>
  <div class="produce-list">
    <div class="produce-item" v-for="(item,index) in productInfo" :key="index">
        <div class="prodecu-content">
            <div class="img" @click="showactiveCar(item)"> 
                <img v-if="item.liyangInfo.productImgFirst" :src="item.liyangInfo.productImgFirst" alt="">
                <img v-else src="../../../assets/ybj/noimage.png" alt="">
            </div>
            <div class="content-list" @click="showactiveCar(item,index)">
                <div class="top">
                    <div class="top-left">{{item.oenaturebrand}}</div>
                    <div class="top-right">{{item.goods_name}}</div>
                </div>
                <div class="OE-Code">
                    <div class="Code">生产编码：{{item.sccode}}</div>
                    <div class="OE">OE:{{item.oecode}}</div>
                </div>
                <div class="price-storage">
                    <div class="price">￥{{item.goods_price}}</div>
                    <div class="storage">库存：{{item.goods_storage>0?'有货':'无货'}}</div>
                </div>
            </div>
            <div class="errorBack"> 
                <div class="fankui" @click="fankuiCenter">
                    <img src="../../../assets/ybj/fankui.png" alt="">
                    反馈
                </div>
                <!-- <el-button type="primary" size="mini" @click="joinCart(item)">加入购物车</el-button> -->
                <div class="shopcar" @click="joinCart(item)" v-if="item.goods_storage>0">加入购物车</div>
                <div class="shopcar" @click="joinCart(item)" v-else style="color:red">加入预购单</div>
            </div>

        </div>
    </div>

    <div class="dialog">
            <el-dialog 
            :visible.sync="showpartInfo"
            width="40%" >
            <div class="activeCar">
                <el-tabs v-model="activeCar" type="card">
                    <el-tab-pane label="产品详情" name="first">
                        <div class="activeCar-content">
                            <el-carousel height="400px">
                                <el-carousel-item v-for="(ite,ind) in productImgs" :key="ind"> 
                                    <div style="width: 100%;height: 100%;text-align: center;">
                                        <img style="width: auto;height: 100%;" :src="ite" alt="">
                                    </div> 
                                </el-carousel-item>
                            </el-carousel>
                            <!-- <div class="activeCar-img">
                                <img src="../../../assets/ybj/noneimg.png" alt=""> 
                            </div> -->
                            <el-row>
                                <el-col :span="24">
                                    <div class="grid-content bg-purple-dark">
                                        <div class="content">产品详情</div>
                                        <div class="img">
                                            <img v-if="showproductInfo=='block'" @click="showproductInfo='none'" src="../../../assets/ybj/arrow_bottom.png" alt="">
                                            <img v-if="showproductInfo=='none'" @click="showproductInfo='block'" src="../../../assets/ybj/arrow-right-white.png" alt="">
                                        </div>
                                    </div>
                                </el-col> 
                            </el-row>
                            <div :style="'display:'+showproductInfo">
                                <el-row> 
                                <el-col :span="12"><div class="grid-content grid-content-item bg-purple">品牌</div></el-col>
                                <el-col :span="12"><div class="grid-content grid-content-item bg-purple-light">{{carName}}</div></el-col>
                            </el-row>
                            <el-row> 
                                <el-col :span="12"><div class="grid-content grid-content-item bg-purple">编号</div></el-col>
                                <el-col :span="12"><div class="grid-content grid-content-item bg-purple-light">{{productNo}}</div></el-col>
                            </el-row>
                            <el-row> 
                                <el-col :span="12"><div class="grid-content grid-content-item bg-purple">OE</div></el-col>
                                <el-col :span="12"><div class="grid-content grid-content-item bg-purple-light">{{oecode}}</div></el-col>
                            </el-row>
                            </div>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="适用车型" name="second">
                        <el-table
                        :data="cartable"
                        style="width: 100%">
                        <el-table-column
                            prop="Brand"
                            label="品牌"
                            width="100">
                        </el-table-column>
                        <el-table-column
                            prop="Models"
                            label="车型"
                            width="100">
                        </el-table-column>
                        <el-table-column
                            prop="ChassisCode"
                            label="底盘号">
                        </el-table-column>
                        <el-table-column
                            prop="ProducedYear"
                            label="年号">
                        </el-table-column>
                        <el-table-column
                            prop="Displacement"
                            label="排量">
                        </el-table-column>
                        </el-table>
                    </el-tab-pane> 
                </el-tabs>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showpartInfo = false" style="color:#4564d3">关闭</el-button> 
            </span>
            </el-dialog>
        </div>
</div>
</template>

<script>
export default {
    
    props:{
        productInfo:{
            require:true
        },
        levelidsInfo:{
            require:true
        }
    },
    data(){
        return{
            showpartInfo:false,
            carName:"",
            productNo:"",
            showproductInfo:'block',
            oecode:"",
            activeCar:"first",
            cartable:[],
            timer:null,
            productImgs:[],
        }
    },
    mounted(){  
        // this.productInfo.forEach(item => {
        //     this.getImages(item.liyangInfo.productbrandid,item.liyangInfo.productcategoryid,item.liyangInfo,productUuid)
        // }) 
    },
    methods:{
        //  // 获取图片
        // async getImages(productbrandid,productcategoryid,productUuid){ 
        //     let res = await this.$http(
        //         `index.php?act=data&op=liyang&model=ProductImg`,
        //         {  
        //             productcategoryid,
        //             productbrandid,
        //             pageindex:'',
        //             pagesize:"10",
        //             data:'1',
        //         }
        //         );
                
        //         res.Result.forEach(image => {
        //             if(image.productImgs&&image.productUuid==productUuid){ 
        //                 this.iamgesList.push(...image.productImgs.split("^"))
        //             } 
        //         });
        //         console.log("this.iamgesList",this.iamgesList)
        // },
    // 加入购物车
    async joinCart(carItem) {
        carItem = carItem.goods_list   
        if(this.timer){
            return
        }
        this.timer = setTimeout(async ()=> {
            if(this.$store.state.store_id==this.shopId){
                return this.$warMsg("不能购买自己配件")
            }
            if(this.$store.state.zero_buy!=1&&this.$store.state.shop!=1974){
                if(carItem.goods_storage<1){ 
                  if(carItem.group_storage&&Number(carItem.group_storage)>0){
                      if(carItem.goods_num+Number(carItem.cartNum)>carItem.group_storage){
                        return this.$store.commit('UpDateState',{invDia:true})
                      }
                  }
                }else if(carItem.goods_num+Number(carItem.cartNum)>carItem.goods_storage){
                    return this.$store.commit('UpDateState',{invDia:true})
                }
            }
            if(this.$store.state.shop==1974){
                if(carItem.goods_storage==0){
                    carItem.goods_storage = 1
                }
            }
            let carMessage = {
                goods_id:carItem.goods_id,
                sccode:carItem.sccode,
                oecode:carItem.oecode,
                goods_name:carItem.goods_name,
                goods_price:carItem.goods_price,
                goods_num:carItem.goods_num||1,
                goods_storage:carItem.goods_storage,
                group_storage:carItem.group_storage||"",
                buyer_id:'',
                order_name:'',
                store_id:carItem.store_id,
                member_id:'',
                buyer_name:'',
                store_name:carItem.store_name,
                cart_type:'1',
                setlist_id:'',
                groupbuy_id:'',
                promotion_id:''
            }
            let res = await this.$api.joinCart([carMessage])
            //更新记录中 已添加X件 字段
            carItem.cartNum = Number(carItem.cartNum) + Number(carItem.goods_num)
            this.timer = null
        },100)
       
    }, 
        fankuiCenter(){ 
            return
            this.$emit("fankuiCenter",5)
        },
        async showactiveCar(item,index){
            this.showpartInfo=true;
            this.$emit("showpartDioag",this.showpartInfo) 
            this.carName = item.oenaturebrand;
            this.productImgs = item.liyangInfo.productImgs 
            this.productNo = item.sccode
            this.oecode = item.oecode

            // if(item.levelId){
            //     let result = await this.$http(`index.php?act=data&op=liyang&model=VehicleByLids`,{
            //     data:item.levelId,
            //     AppKey:"3dd4ed48ea913068",
            //     AppSecret:"431595e67f114da9a839e8b49605b4b6" 
            //     })
            //     console.log(result)
            //     this.cartable = result.Result[0] 
            // }
            this.cartable = []
            this.cartable.push(this.levelidsInfo[0])
 
        },
    }

}
</script>

<style lang="scss" scoped>

.produce-list{
            
            margin:10px 0;
    .dialog{
        ::v-deep .el-dialog__headerbtn{
            top:8px;
            position: absolute; 
            right: 20px;
            padding: 0;
            background: 0 0;
            border: none;
            outline: 0;
            cursor: pointer;
            font-size: 16px;
        }
        .activeCar{
        margin:10px 0;
        ::v-deep .el-tabs__item.is-active {
            color: #fff;
            background: #346ae1;
        }
        
        .activeCar-content{
            width: 100%;
            ::v-deep .el-row{
                margin: 0;
                border: 1px solid #ccc;
            }
            .activeCar-img{
                height: 200px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            .bg-purple-dark{ 
                display: flex; 
                justify-content: space-between;
                background: #eee;
                height: 45px;
                line-height: 25px;
                padding: 10px;
                box-sizing: border-box; 
                .content{
                    color: #0f58eb;
                } 
            }
            .grid-content-item{
                height: 40px;
                line-height: 22px;
                padding: 10px;
                box-sizing: border-box;
            }
        }
    }
    }
            .produce-item{
                background-color: #FFFFFF;
                border:1px solid #ccc;
                padding: 5px 15px;
                margin: 5px 0;
                .prodecu-content{
                    display: flex;
                    align-items: center;
                    .img{
                        width: 90px;
                        height: 90px;
                        margin-right: 10px;
                        flex-shrink: 0;
                        >img{
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .content-list{
                        flex-grow: 1;
                         
                        .top{
                            font-size: 16px;
                            color: #4564d3;
                            font-weight: bold;
                            display: flex;
                            .top-right{
                                margin-left: 20px;
                            }
                        }
                        .OE-Code{
                            margin-top: 12px;
                            width:300px;
                            color: #999;
                            display: flex;
                            justify-content: space-between;
                        }
                        .price-storage{
                            width:300px; 
                            display: flex;
                            justify-content: space-between;
                            margin-top: 30px;
                            .price{
                                font-size: 16px;
                                color: red;
                                font-weight: bold;
                            }
                            .storage{
                                color: #999;
                            }
                        }
                    }
                    .errorBack{
                        height: 83px; 
                        width: 100px;
                        .fankui{
                            color: red;
                            margin-bottom: 36px;
                            >img{
                                vertical-align: middle;
                            }
                        }
                        .shopcar{
                            margin-top:40px;
                            color: #0f58eb;
                            >img{
                                width:18px
                            }
                        }
                    }
                }
            }
        }

</style>