<template>
    <div class="purchase-index">
        <router-view/>
    </div>
</template>

<script>

export default {
    data(){
        return{
           
        }
    },
    methods:{
    },
    async created(){
        
    },
    props:{
        
    }
}
</script>
<style lang="scss">
.purchase-index{
    background: $backGray;
}
</style>