<template>
<!-- 精准找件页 -->
<div class="vin-Goods">
    <!-- 分组列表 -->
    <div class="group-list" >
        <template v-for="(item,index) in vinPartList">
            <div class="group-item" :class="{'mg-active':activeIndex==index}"  :key="index" v-if="!item.loaded||item.parts.length>0"  @click="changeShowPart(index)">
                {{item.label}} 
                <div class="msg-num">
                    ({{item.parts.length}})
                </div>
            </div>
        </template>
    </div>
    <el-table
        :data="tableData"
        border
        style="width: 100%"
        header-cell-class-name="ta-head"
        >
        <el-table-column
        fixed
        prop="oecode"
        label="OE号"
        >
        </el-table-column>
        <el-table-column
        prop="goods_name"
        label="商品名称"
        >
        </el-table-column>
        <el-table-column
        prop="sccode"
        label="生产编码"
        >
        </el-table-column>
        <el-table-column
        prop="oenaturebrand"
        label="配件品牌"
        width="120">
        </el-table-column>
        <el-table-column
        prop="spec"
        label="规格"
        width="120">
        </el-table-column>
        
        <el-table-column
        prop="isPep"
        label="是否是互换件"
        width="120">
        </el-table-column>

        <el-table-column
        prop="goods_storage"
        label="库存"
        width="120">
            <template slot-scope="scope">
                <span v-if="$store.state.look_inv==1">{{scope.row.goods_storage}}</span>
                <span v-else>{{scope.row.goods_storage>0?"有货":"无货"}}</span>
            </template> 
        </el-table-column>
        <el-table-column
        prop="goods_price"
        label="价格"
        width="120">
        </el-table-column>
        <el-table-column
        label="购买数量"
        align="center"
        width="160">
            <template slot-scope="scope">
                <el-input-number v-model="scope.row.goods_num" :min="1" :max="scope.row.goods_storage*1" size="mini"></el-input-number>
            </template>
        </el-table-column>
        <el-table-column
        fixed="right"
        label="操作"
        width="100">
            <template slot-scope="scope">
                <div class="buy-btn"  @click="joinCart(scope.row)" >
                    加入购物车
                </div>
                <!-- <el-button  @click="joinCart(scope.row)" type="text" size="small"></el-button> -->
            </template>
        </el-table-column>
    </el-table>
</div>
</template>

<script>
export default {
    data(){
        return{
            vinCode:"",
            brandCode:"",
            mcid:"",
            vinPartList:[],
            mainGroupList:[],
            activeIndex:0
        }
    },
    methods:{
        async joinCart(goods){
            if(this.$isCompany()){

                if(this.$store.state.zero_buy!=1){
                    if(goods.goods_storage<1){
                        return this.$store.commit('UpDateState',{invDia:true})
                    }else if(goods.goods_num+Number(goods.cartNum)>goods.goods_storage){
                        return this.$store.commit('UpDateState',{invDia:true})
                    }
                }
                let carMessage = {
                    goods_id:goods.goods_id,
                    sccode:goods.sccode,
                    oecode:goods.oecode,
                    goods_name:goods.goods_name,
                    goods_price:goods.goods_price,
                    goods_num:goods.goods_num,
                    goods_storage:goods.goods_storage,
                    buyer_id:this.$store.state.member_id,
                    order_name:'',
                    member_id:this.$store.state.member_id,
                    buyer_name:this.$store.state.member_name,
                    store_id:goods.store_id,
                    store_name:goods.store_name,
                    cart_type:'1',
                    
                }
                await this.$api.joinCart([carMessage])
            }
        },
        async getVinPartList(groupItem){
            let startTime = new Date().getTime()
            let res = await this.$api007.getBranchGroupList({
                vin:this.vinCode,
                brandCode:this.brandCode,
                mcid:groupItem.mcid,
                num:groupItem.num
            })
            // cuijunshuai123
            // 直接就是零件了
            // console.log("res.data",res.data);
            if(!res.data||!res.data[0]){
                return this.$nextTick(()=>{
                    this.$set(groupItem,"parts",[]) 
                    this.$set(groupItem,"loaded",true) 
                    this.$set(groupItem,"loadTime","0")
                })
            }
            if(res.data&&res.data[0]&&res.data[0].has_child==0){
                let sonList = res.data.filter(item=>item.colorvalue!=0)
                
                // console.log("当前子组是",sonList);
                
                let partReqList = []
                sonList.forEach(branchGroup=>{
                    let p = new Promise(async resolve=>{
                        let res = await this.getVinPartOeList(branchGroup)
                        resolve(res)
                    })
                    partReqList.push(p)
                })
                let oeList = await Promise.all(partReqList)
               
                // 拍平
                oeList = oeList.flat()
                // 去重
                oeList = [...new Set(oeList)]
                
                let hnwGoodsRes = await this.$api.getVinPartsByOes({
                    oeList:oeList.join(","),
                    for_branch:this.$store.state.for_branch,
                    shop:this.$store.state.shop
                })
                console.log("hnwGoodsRes",hnwGoodsRes);
                let goodsList = []
                if(hnwGoodsRes.data){
                    hnwGoodsRes.data.forEach(goods=>{
                        this.$set(goods,"isPep","否")
                    })
                    goodsList = [...hnwGoodsRes.data]
                }
                if(hnwGoodsRes.exchange){
                    hnwGoodsRes.exchange.forEach(goods=>{
                        this.$set(goods,"isPep","是")
                        let g2 = goodsList.find(g1=>g1.goods_id==goods.goods_id)
                        if(!g2){
                            goodsList.push(goods)
                        }else{
                            console.log("存在重复商品",g2);
                        }

                    })
                   
                }
                // console.log("hnwGoodsRes",hnwGoodsRes);
                 
                goodsList.forEach(goods=>{
                    this.$set(goods,"goods_num",1)
                })
                let endTime = new Date().getTime()
                // console.log("总耗时",endTime - startTime);
                
                this.$nextTick(()=>{
                    this.$set(groupItem,"parts",goodsList) 
                    this.$set(groupItem,"loaded",true) 
                    this.$set(groupItem,"loadTime",((endTime - startTime)/1000).toFixed(2))
                })
            }else{
                // 如果还有分组
            
                
                this.branchGroupList  = res.data
                this.branchGroupList.forEach(part=>{
                    part.img = res.imgs[part.imageLarge]
                })
                this.branchGroupList = this.branchGroupList.filter(item=>item.name!='概述'&&item.name!='杂项')

                let partReqList = []
                this.branchGroupList.forEach(branchGroup=>{
                    let p = new Promise(async resolve=>{
                        let res = await this.getSonParts(branchGroup)
                        resolve(res)
                    })
                    partReqList.push(p)
                })
                let sonList = sonList = await Promise.all(partReqList)
                console.log("sonList",sonList);
                sonList = sonList.flat()
                let partReqList2 = []
                sonList.forEach(sonGroup=>{
                    let p = new Promise(async resolve=>{
                        let res = await this.getVinPartOeList(sonGroup)
                        resolve(res)
                    })
                    partReqList2.push(p)
                })
                let oeList = await Promise.all(partReqList2)
                console.log("oeList",oeList);
                // 拍平
                oeList = oeList.flat()
                // 去重
                oeList = [...new Set(oeList)]
                // let goodsList = await this.$api.getVinPartsByOes(oeList.join(","))

                let hnwGoodsRes = await this.$api.getVinPartsByOes({
                    oeList:oeList.join(","),
                    for_branch:this.$store.state.for_branch,
                    shop:this.$store.state.shop
                })
                console.log("hnwGoodsRes1",hnwGoodsRes);
                let goodsList = []
                if(hnwGoodsRes.data){
                    hnwGoodsRes.data.forEach(goods=>{
                        this.$set(goods,"isPep","否")
                    })
                    goodsList = [...hnwGoodsRes.data]
                }
                if(hnwGoodsRes.exchange){
                    hnwGoodsRes.exchange.forEach(goods=>{
                        this.$set(goods,"isPep","是")
                        let g2 = goodsList.find(g1=>g1.goods_id==goods.goods_id)
                        if(!g2){
                            goodsList.push(goods)
                        }else{
                            console.log("存在重复商品",g2);
                        }

                    })
                   
                }
               
                goodsList.forEach(goods=>{
                    this.$set(goods,"goods_num",1)
                })
                let endTime = new Date().getTime()
                console.log("总耗时",endTime - startTime);
                
                this.$nextTick(function(){
                    this.$set(groupItem,"parts",goodsList) 
                    this.$set(groupItem,"loaded",true) 
                    this.$set(groupItem,"loadTime",((endTime - startTime)/1000).toFixed(2))
                })
                
            }
        },
        async getSonParts(item){
            this.branchGroup = item.num + item.name
            let params = {
                vin:this.vinCode,
                brandCode:this.brandCode,
                mcid:item.mcid,
                num:item.num,
                level:item.level,
                p_name:item.p_name,
                subgroup:item.subgroup
            }
            
            let res  = await this.$api007.getBranchChildList(params)
            res.data = res.data.filter(item=>item.colorvalue==1)
            return res.data
        },
        // 获取华诺威配件号
        async getVinPartOeList(part){
            let res = await this.$api007.vinPartSearch({
                ...part,
                vin:this.vinCode,
                filter:1
            })
            if(!res.data.part_detail){
                return console.error("未查询配件列表")
            }
            let nearList = []
            if(res.data.part_detail){
                res.data.part_detail.forEach(parts=>{
                    if(Array.isArray(parts)){
                        
                        parts.forEach(part=>{
                            nearList.push(part)
                        })
                        
                    }else{
                        nearList.push(parts)
                    }
                })
            }
            let pid_list = []
            nearList.forEach(part=>{
                if(part.pid){
                    pid_list.push(part.pid)
                }
            })
            return pid_list
        },
        async changeShowPart(index){
            if(!this.vinPartList[index].loaded){
                this.$warMsg("该分组尚未查询完毕")
            }
           this.activeIndex = index
        },
    },
    async created(){
        console.log("path",this.$route);
        this.vinCode =  this.$route.params.vin
        this.vinCode = this.vinCode.replace(/\s/g,'').toUpperCase()
        const vinRes = await this.$api007.vinSearch(this.vinCode)
        this.brandCode = vinRes.data.brandCode
        this.mcid = vinRes.data.mcid
        // 查询主组
        
        let res = await this.$api007.getMainGroupList({
            vin:this.vinCode,
            brandCode:this.brandCode,
            mcid:this.mcid
        })
        this.mainGroupList = res.data
        // 附件及其他调整位置
        this.mainGroupList.push(this.mainGroupList.shift())
        
        let tempList = this.mainGroupList.map(item=>{
            this.$set(item,"parts",[])
            this.$set(item,"showpart",false)
            this.$set(item,"loaded",false)
            return item
        })
        this.$set(this,"vinPartList",tempList)
        // this.getVinPartList(this.vinPartList[0])
        
        console.log('vinPartList',this.vinPartList)

        this.$nextTick(async ()=>{
            for(let i=0;i<this.vinPartList.length;i++){
                await this.getVinPartList(this.vinPartList[i])
            }
            // this.activeIndex = 
            if(this.vinPartList[this.activeIndex].parts.length==0){
                this.activeIndex = this.vinPartList.findIndex(item=>item.parts.length>0)
            }
            console.log("所有分组配件查询完毕!",this.vinPartList[this.activeIndex]);
        })
    },
    computed:{
        tableData(){
            let list = []
            if(this.vinPartList.length>0){

                list = this.vinPartList[this.activeIndex].parts || []
                list.forEach(goods=>{
                    if(!this.$store.state.shop||this.$store.state.shop==1780){
                        goods.goods_price = this.$handlePrice(goods) 
                    }else{
                        this.$handleStorePrice(goods)
                    }
                })
            }
            return list
        }
    },
    props:{
        
    }
}
</script>

<style lang="scss">
.vin-Goods{
    padding: 10px 20px;
    background: #f7f7f7;
    .group-list{
        display: flex;
        border: 1px  solid #EBEEF5;
        background: #fff;
        flex-wrap: wrap;
        border-bottom: none;
        .group-item{
            padding: 8px .30303rem /* 10/33 */;
            display: flex;
            cursor: pointer;
            border-right: 1px solid #EBEEF5;
            border-bottom: 1px  solid #EBEEF5;
            .msg-num{
                padding-left: 10px;
            }
        }
        .mg-active{
            background: $vin-blue;
            color: #fff;
            border-right: none;
        }
    }
    .ta-head{
        background: #fcfcfc;
        color: $vin-blue;
    }
    .buy-btn{
        color: #FF7519;
        cursor: pointer;
    }
}
</style>