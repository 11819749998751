<template>
<div class="SortHandler">
    <span class="sort" >
        <i class="el-icon-caret-top" :class="{'act-i':sortText==type+'asc'}" @click="sortCutWay(type,'asc')"></i>
        <i class="el-icon-caret-bottom" :class="{'act-i':sortText==type+'desc'}"  @click="sortCutWay(type,'desc')"></i>
    </span>
</div>
</template>

<script>
export default {
    data(){
        return{
            sortText:""   
        }
    },
    methods:{
        sortCutWay(sort_type,sort_way){
            this.sortText = sort_type + "" +sort_way
            this.$emit("sortCutWay",{sort_type,sort_way})
        }
    },
    created(){

    },
    props:{
        type:{
            required:true
        },
        // 选中类型
        activeType:""
    },
    watch:{
        activeType(n){
            
            if(n!=this.type){
                this.sortText = ""
            }
            
        }
    }
}
</script>

<style lang="scss">
.SortHandler{
    .sort{
        display: flex;
        flex-direction: column;
        justify-content: center;  
        i{
            color: #333;
            transform: scale(.8);
            cursor: pointer;
            position: relative;
            top: 2px;
        }
        i:hover{
            transform: scale(1.2);
        }
        .el-icon-caret-bottom{
            position: relative;
            top: -2px;
        }
        .act-i{
            color: #3E84D6;
        }
    }
}
</style>