import { post } from "axios"
import store from '../../../store'

//所有店铺

const getShopList = async ()=>{
        const res = await post("index.php?act=store_shop&op=getShopList")
        return res 
    }
// 推荐商品
const getRecommend = async (data)=>{
    const res =  await post("index.php?act=store_shop&op=shopGoods",{
        ...data
    })
    return res

}


// 店铺商品
const getShopGoods = async (data)=>{
    
    const res =  await post("index.php?act=store_shop&op=shopGoodsList",{
        ...data
    })
    return res
    
}

//店铺推荐商品
const getShopRecommend = async (data)=>{
    const res =  await post("index.php?act=store_shop&op=shopGoodsListRecommend",{
        ...data
    })
    return res
    
}


//添加店铺收藏
const addCollect= async (data)=>{
    const res =  await post("index.php?act=favorites&op=favoriteShop",{
        ...data
    })
    return res
    
}
//店铺收藏列表
const getCollectList = async (data)=>{
    const res =  await post("index.php?act=favorites&op=fglist",{
        ...data
    })
    return res
    
}
//取消店铺收藏
const delCollect= async (data)=>{
    const res =  await post("index.php?act=favorites&op=fgCancel",{
        ...data
    })
    return res
    
}
const isCollect= async (data)=>{
    const res =  await post("index.php?act=favorites&op=isFavorites",{
        ...data
    })
    return res
    
}
const getrecord= async (data)=>{
    const res =  await post("index.php?act=orders&op=buy_record",{
        ...data
    })
    return res
    
}
// 获取首图
const getGoodsListHomeImg= async (data)=>{
    const res =  await post("index.php?act=setting&op=goods_default",{
        ...data
    })
    return res
    
}
// 获取需求列表
export {
    getShopList,
    getShopGoods,
    getRecommend,
    getShopRecommend,
    addCollect,
    getCollectList,
    delCollect,
    isCollect,
    getrecord,
    getGoodsListHomeImg
}
