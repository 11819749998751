<template>
<div class="inte-goodsList">
      <div class="group-sort">
        <inteGroups :groups="clasList" @modeChange="modeChange" :noBor="true"></inteGroups>
        <div class="sort-nav">
            <div class="label">
                排序方式：
            </div>
            <div class="sort-list">
                <div class="clear-sort" @click="clearSort" :class="{'active':sort_k==''}">
                    综合排序
                </div>
                <div class="sort-item">
                    销量
                    <SortHandler @sortCutWay="sortCutWay" type="sales" :activeType="sort_k"></SortHandler>
                </div>
                <div class="sort-item">
                    积分
                    <SortHandler @sortCutWay="sortCutWay" type="price"  :activeType="sort_k"></SortHandler>
                </div>
            </div>
        </div>
      </div>
     
     <div class="goods-list">
        <el-row  :gutter="8">
            <el-col :span="6" v-for="(item,index) in goodsList" :key="index" class="goods-col">
                
                <div class="goods-card"  @click="toGoodsInfo(item)">
                    <div class="img-box" >
                        <img :src="item.images[0]" alt="">

                    </div>
                    <div class="card-fot">
                        <!-- 价格和仓位 -->
                        <div class="pri_warehouse">
                            <div class="price">
                                <div class="red">
                                    <div class="num">
                                        {{ item.price }}
                                    </div>
                                    <div class="jf">
                                        积分
                                    </div>
                                </div>
                                <div class="old-price">
                                    ￥ {{item.old_price||"暂无原价"}}
                                </div>
                            </div>
                            <div class="ware">
                                限量 {{item.stock}} 件
                            </div>
                        </div>
                        <div class="goods-name">
                             {{item.name}}
                        </div>
                        <div class="handle">
                            <el-button size="mini" type="primary">去兑换</el-button>
                        </div>
                    </div>
                </div>
            </el-col>
        </el-row>
     </div>
    <div class="paging">
        <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="page"
            :page-sizes="[12,24,36,120]"
            :page-size.sync="limit"
            layout="total, sizes, prev, pager, next, jumper"
            :total="Math.ceil(total)">
        </el-pagination>
    </div>  
</div>
</template>

<script>
import inteGroups from "./inteGroups.vue"
import SortHandler from "../../../components/SortHandler.vue"
import HnwOssImg from "../../../components/hnwOssImg.vue";
export default {
    data(){
        return{
            groups:[
                {
                    title:"综合",
                    value:""
                },
                {
                    title:"礼品兑换",
                    value:"goods"
                },
                {
                    title:"配件兑换",
                    value:"parts"
                },
            ],
            clasList:[
                {
                    title:"综合",
                    value:""
                }
            ],
            
            page:1,
            limit:12,
            mode:"",
            total:20,
            goodsList:[],
            sort_k:"",
            sort_v:"",
            classify:""
        }
    },
    methods:{
        async handleSizeChange(val) {
            await this.getList()
            
            scrollTo(0,0)
        },
        clearSort(){
           this.sort_k = ""
           this.sort_v = ""
           this.getList()
        },
        sortCutWay(sort){
           this.sort_k = sort.sort_type
           this.sort_v = sort.sort_way
           this.getList()
        },
        handleCurrentChange(val) {
            this.getList()
        },
        modeChange(mode){
            this.classify = mode
            
            this.getList()
        },
        async getList(){
            let params ={
            }
            if(this.$route.params.id){
                params.shop = this.$route.params.id || "1780"
            }
            let res = await this.$api.getIntegrallist({
                limit:this.limit,
                page:this.page,
                mode:this.mode,
                classify:this.classify,
                sort_k:this.sort_k,
                sort_v:this.sort_v,
                ...params
            })
            

            this.total = res.count
            if(res.data){
                res.data.forEach(goods => {
                    if(goods.images){
                        // console.log(goods.images)
                        goods.images = JSON.parse(goods.images)
                    }
                })
                this.goodsList = res.data 
            } 
            
        },
        
        toGoodsInfo(goods){
            if(this.$isCompany()){
                let rou = '/integral/info'
                if(this.$route.params.id){
                    goods.preRou = "积分礼品"
                    localStorage.setItem("hnw-web-item-data",JSON.stringify(goods))
                    this.$emit("showInteInfo")
                }else{
                    goods.preRou = "积分礼品"
                    
                    localStorage.setItem("hnw-web-item-data",JSON.stringify(goods))
                    
                    this.$router.push(rou)
                }
            }
            
        }
    },
    async created(){
        this.getList()
        let res = await this.$api.getInGoodsClass({
            shop:this.$route.params.id||"1780"
        })
       
        if(res&&res.length>0){
            res.forEach(item=>{
                if(item.classify){
                    this.clasList.push({
                        title:item.classify,
                        value:item.classify
                    })
                }
            })
        }
    },
    components:{
        inteGroups,
        SortHandler,
        HnwOssImg
    },
    props:{
        
    }
}
</script>

<style lang="scss">
.inte-goodsList{
    .group-sort{
        display: flex;
        margin-bottom: 10px;
        justify-content: space-between;
        border-bottom: 2px solid #3E84D6;
        .sort-nav{
            display: flex;
            align-items: center;
            // padding: 8px 12px;
            background: #fff;
            height: 38px;
            line-height: 38px;
            .label{
                font-size: 14px;
            }
            .sort-list{
                display: flex;
                align-items: center;
                >div{
                    padding: 0 12px;
                }
                .clear-sort{
                    cursor: pointer;
                }
                .active{
                    color: #3E84D6;
                }
                .sort-item{
                    display: flex;
                    align-items: center;
                }
            }
        }
    }
    .goods-list{
        background: $backGray;
       
        .goods-col{
            
            .goods-card{
                background: #fff;
                cursor: pointer;
                height: 310px;
                display: flex;
                margin-bottom: 10px;
                flex-direction: column;
                // justify-content: space-between;		
                box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
                border-radius: 6px ;
                .img-box{
                    // cursor: pointer;
                    height: 210px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    img{
                        // width: 229px;
                        // height: 130px;
                        width: 210px;
                        height: 210px;
                    }
                }
                .card-fot{
                    box-sizing: border-box;
                    padding: 10px;
                    .pri_warehouse{
                        display: flex;
                        justify-content: space-between;
                        align-items: flex-end;
                        .price{
                            display: flex;
                            align-items: flex-end;
                            .red{
                                display: flex;
                                .num{
                                    font-size: 22px;
                                }
                                .jf{
                                    position: relative;
                                    padding-left: 3px;
                                    top: 5px;
                                }
                            }
                            .old-price{
                                font-size: 14px;
                                text-decoration: line-through;
                                color: #999999;
                                padding-top: 8px;
                                padding-left: 7px;
                            }
                        }
                        .ware{
                            color: #999999;
                        }
                    }
                    .goods-name{
                        font-weight: 600;
                        line-height: 22px;
                        min-height: 22px;
                        font-size: 16px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                    .handle{
                        text-align: right;
                    }
                }
            }
        }
        
    }
    
    .paging{
        display: flex;
        justify-content:right;
        padding-top: 10px;
        .el-pagination{

        }
    }
}
</style>