<template>
<div class="inte-detail">
    <inteGroups :groups="groups" @modeChange="modeChange"></inteGroups>
    <div class="inte-table">
        <div class="inte-table-head">
            <div>订单编号</div>
            <div>时间</div>
            <div>订单总金额</div>
            <div>积分数量</div>
            <div>类型</div>
        </div>
        <div class="inte-table-body">
            <div class="inte-body-row" v-for="order in  orderList" :key="order.id">
                <div>{{order.orderId||"---"}}</div>
                <div>{{$hnwTools.formatDateTime(order.time,'ymd')}}</div>
                <div>{{order.total||"---"}}</div>
                <div class="num" :class="{'out':order.mode=='积分兑换'}">
                   <span v-if="order.number!='待发货结算'">
                       {{order.direction}}
                   </span>
                    {{order.number}}
                </div>
                <div>{{order.mode_text}}</div>
            </div>
        </div>
    </div>
    <div class="paging">
        <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="page"
            :page-sizes="[10,15, 20, 30, 40]"
            :page-size.sync="limit"
            layout="total, sizes, prev, pager, next, jumper"
            :total="Math.ceil(total)">
        </el-pagination>
    </div>  
</div>
</template>
<script>
import inteGroups from "./inteGroups.vue"
export default {

    data(){
        return{
            groups:[
                {
                    title:"综合",
                    value:""
                },
                {
                    title:"签到积分",
                    value:"sign"
                },
                {
                    title:"完成订单",
                    value:"get"
                },
                {
                    title:"积分兑换",
                    value:"out"
                },
                {
                    title:"抽奖明细",
                    value:"lot"
                }
            ],

            page:1,
            limit:10,
            mode:"",
            total:20,
            orderList:[]
        }
    },
    methods:{
        async handleSizeChange(val) {
            await this.getList()
            scrollTo(0,0)
        },
        handleCurrentChange(val) {
            this.getList()
        },
        modeChange(mode){
            this.mode = mode
            this.getList()
        },
        async getList(){
            let params ={
            }
            if(this.$route.params.id){
                params.shop = this.$route.params.id || "1780"
            }
            let res = await this.$api.getIntegralDelist({
                limit:this.limit,
                page:this.page,
                mode:this.mode,
                ...params
            })
            this.total = res.count 
            let mode_text = ""
            if(res.data){
                res.data.forEach(order => {
                    let direction = "-"
					switch (order.mode){
						case "out":
							mode_text  = "积分兑换"
							break;
						case "get":
                            if(order.number!=0){
                                direction = "+"
                            }
							mode_text  = "订单"
                            order.number = order.number==0?"待发货结算":order.number
							break;
						case "sign":
							mode_text  = "签到"
							direction = "+"
							break;
						case "lotinc":
							mode_text  = "抽奖消耗"+""
							break;
						case "lotinc1":
							mode_text  = "(抽奖)谢谢惠顾"
							break;
						case "lotadd":
							mode_text  = "抽奖赠送"+""
							direction = "+"
							break;
						
						default:
							
							break;
					}
					order.direction = direction
					order.mode_text = mode_text
                    // switch (order.mode) {
                    //     case "out":
                    //         order.mode =  "积分兑换"
                    //         break;
                    //     case "sign":
                    //         order.mode =   "签到"
                    //         break;
                    //     case "get":
                    //         order.mode =   "订单获取"
                            
                    //         break;
                        
                    //     default:
                    //         break;
                    // }
                });
            }
            this.orderList = res.data
        }
    },
    created(){
        this.getList()
    },
    components:{
        inteGroups
    },
    props:{
        
    }
}
</script>

<style lang="scss">
.inte-detail{
    .inte-table{
        background: #fff;
        padding: 20px 25px;
        text-align: center;
        .inte-table-head{
            display: flex;
            background: #F5F5F5;

            >div{
                flex: 1;
                line-height: 32px;
            }
        }
        .inte-table-body{
            .inte-body-row{
                border-bottom: 2px solid #f5f5f5;
                display: flex;
                >div{
                    flex: 1;
                    line-height: 32px;
                    border-right: 2px solid #f5f5f5;
                }
                >div:last-child{
                    border-right: none;
                }
                .num{
                    color: red;
                }
                .out{
                    color: #666;
                }
            }
        }
    }
    .paging{
        display: flex;
        justify-content:right;
        padding-top: 10px;
        .el-pagination{

        }
    }
}
</style>