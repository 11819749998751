<template>
<div class="sysCompany">
    <!-- 企业信息 -->
    <div class="company-handle">
        <div class="yy" v-if="tempNum==1 || tempNum==2 || tempNum==3 || tempNum==4 ">
            <div @click="tempNum=1 " :class="{'active': tempNum==1}">基本信息</div>
            <div @click="tempNum=2 " :class="{'active': tempNum==2 }">经营信息</div>
            <div @click="tempNum=3 " :class="{'active': tempNum==3 }">身份证信息</div>
            <div v-if="store_id!=1780" @click="tempNum=4 " :class="{'active': tempNum==4 }">采购协议</div>
        </div>
    </div>
    <template >
        <section class="information-box" v-if="tempNum==1">
            <el-button type="primary" @click="swiEdit" size="mini">
                {{btnText}}
            </el-button> 
            <div class="container">
                <div class="con-left">
                    <div class="infor-item">
                        <div class="label">企业名称：</div>
                        <input type="text" disabled :value="companyInfo.store_company_name" class="valueInput">                
                    </div>
                    <div class="infor-item">
                        <div class="label">企业简称：</div>                 
                        <input type="text" disabled :value="companyInfo.company_name_jc" class="valueInput">
                    </div>
                    
                    <div class="infor-item">
                        <div class="label">企业联系人：</div>                       
                        <input type="text" disabled :value="companyInfo.contacts_name" class="valueInput">
                    </div>
                    <div class="infor-item">
                        <div class="label">联系电话：</div>                       
                        <input type="text" disabled :value="companyInfo.contacts_phone" class="valueInput">
                    </div>
                    <div class="infor-item">
                        <div class="label">企业地址：</div>                     
                        <input type="text" disabled :value="companyInfo.area_info+companyInfo.store_address" class="valueInput">
                    </div>
                    <div class="infor-item">
                        <div class="label">付款方式：</div>                      
                        <input type="text" disabled :value="companyInfo.pay_type" class="valueInput">
                    </div>
                    <div class="infor-item">
                        <div class="label">企业标识：</div>
                            <div class="img-list-item">
                                <div class="img-box">
                                    <img :src="companyInfo.store_logo_url" alt="" @click="redBigImg(companyInfo.store_logo_url)">
                                </div>
                                <span class="handle-span"  >点击放大查看</span>
                            </div>
                    </div>
                </div>
            </div>
        </section>  
        <section class="information-box" v-if="tempNum==2">
            <el-button type="primary" @click="swiEdits" size="mini">
                {{btnText}}
            </el-button> 
            <div class="container">
                <div class="con-left">
                    <div class="infor-item">
                        <div class="label">统一社会信用代码：</div>                   
                        <input type="text" disabled :value="companyInfo.business_licence_number" class="valueInput">
                    </div>
                    <div class="infor-item">
                        <div class="label">企业名称：</div>                       
                        <input type="text" disabled :value="companyInfo.store_company_name" class="valueInput">
                    </div>
                    <div class="infor-item">
                        <div class="label">法定代表人：</div>
                        
                        <input type="text" disabled :value="companyInfo.corporation_name" class="valueInput">
                    </div>
                    <div class="infor-item">
                        <div class="label">省市区：</div>
                      
                        <input type="text" disabled :value="companyInfo.store_address" class="valueInput">
                    </div>
                    <div class="infor-item">
                        <div class="label">主营产品：</div>
                   
                        <input type="text" disabled :value="companyInfo.store_keywords" class="valueInput">
                    </div>

                        <div class="infor-item">
                        <div class="label">企业公众号：</div>
                   
                        <input type="text" disabled :value="companyInfo.company_gzcode" class="valueInput">
                    </div>
                    <div class="infor-item">
                        <div class="label">企业网址：</div>
                   
                        <input type="text" disabled :value="companyInfo.company_site" class="valueInput">
                    </div>
                    
                    <div class="infor-item">
                        <div class="label">经销商配件品牌：</div>
                   
                        <input type="text" disabled :value="companyInfo.parts_brand" class="valueInput">
                    </div>
                    <div class="infor-item">
                        <div class="label">经销商汽车品牌：</div>
                   
                        <input type="text" disabled :value="companyInfo.vehicle_brand" class="valueInput">
                    </div>
                    <div class="infor-item">
                        <div class="label">营业执照：</div>
                            <div class="img-list-item">
                                <div class="img-box">
                                    <img :src="companyInfo.business_licence_number_elc_url" alt="" @click="redBigImg(companyInfo.business_licence_number_elc_url)">
                                </div>
                                <span class="handle-span"  >点击放大查看</span>
                            </div>
                    </div>
                </div>

            </div>
        </section>  
        <section class="information-box" v-if="tempNum==3">
            <el-button type="primary" @click="swiEditss" size="mini">
                {{btnText}}
            </el-button> 
            <div class="container">
                <div class="con-left">
                    <div class="infor-item">
                        <div class="label">姓名：</div>
                        
                        <input type="text" disabled :value="companyInfo.corporation_name" class="valueInput">
                    </div>
                    <div class="infor-item">
                        <div class="label">身份证号：</div>
                       
                        <input type="text" disabled :value="companyInfo.corporation_code" class="valueInput">
                    </div>
                   <div class="infor-item">
                        <div class="label">身份证：</div>
                            <div class="img-list-item">
                                <div class="img-box">
                                    <img :src="companyInfo.id_just_url" alt="" @click="redBigImg(companyInfo.id_just_url)">
                                </div>
                                <span class="handle-span"  >点击放大查看</span>
                            </div>
                            <div class="img-list-item">
                                <div class="img-box">
                                    <img :src="companyInfo.id_back_url" alt="" @click="redBigImg(companyInfo.id_back_url)">
                                </div>
                                <span class="handle-span"  >点击放大查看</span>
                            </div>
                    </div>
                </div>
            </div>
        </section>  
        <section class="information-box" v-if="store_id!=1780 && tempNum==4 ">
            <el-button type="primary" @click="swiEditsss" size="mini">
                {{btnText}}
            </el-button> 
            <div class="container" >
                <div class="con-left">
                     <div class="infor-item">
                        <div class="label">合同签署日期：</div>
                      
                        <input type="text" disabled :value="companyInfo.contract_starttime" class="valueInput">
                    </div>
                    <div class="infor-item">
                        <div class="label">合通截止日期：</div>
                
                        <input type="text" disabled :value="companyInfo.contract_endtime" class="valueInput">
                    </div>
                    <div class="infor-item">
                        <div class="label">采购额度：</div>
                      
                        <input type="text" disabled :value="companyInfo.purchase_quota" class="valueInput">
                    </div>
                  <div class="infor-item">
                        <div class="label">采购协议：</div>
                            <div class="img-list-item">
                                <div class="img-box">
                                    <img :src="companyInfo.purchase_contract_url"  alt="" @click="redBigImg(companyInfo.purchase_contract_url)">
                                </div>
                                <span class="handle-span down"  @click="downProp(companyInfo.purchase_contract)">
                                    <i class="el-icon-download"></i>
                                    预览/下载
                                </span>
                                
                            </div>
                    </div>
                </div>
               
            </div>
        </section>  
    </template>
    <template >
        <div class="from-box">
            <el-form :model="companyInfo" label-width="130px" size="mini">
                <section class="information-box" v-if="tempNum==6">
                    <div class="title">
                        基本信息
                    </div>
                    <el-button type="primary" @click="swiEdit" size="mini">
                        {{btnText}}
                    </el-button> 
                    <div class="info-from">
                        <el-form-item label="企业名称:">
                            <el-input v-model="companyInfo.store_company_name"></el-input>
                        </el-form-item>
                        <el-form-item label="企业简称:">
                            <el-input v-model="companyInfo.company_name_jc"></el-input>
                        </el-form-item>
                        <el-form-item label="企业联系人:">
                            <el-input v-model="companyInfo.contacts_name"></el-input>
                        </el-form-item>
                        <el-form-item label="联系电话:">
                            <el-input v-model="companyInfo.contacts_phone"></el-input>
                        </el-form-item>
                    
                        <el-form-item label="所属区域:">
                            <template v-if="hasAddr">
                                <AdressSelect :site="site" @upAdress="upAdress"></AdressSelect>
                            </template>
                        </el-form-item>
                        <el-form-item label="详细地址:">
                            <el-input v-model="companyInfo.store_address"></el-input>
                        </el-form-item>
                        <el-form-item label="付款方式:">
                            <el-select v-model="companyInfo.pay_id" placeholder="付款方式" size="mini">
                                <el-option v-for="(option,index) in payTypeList" :value="option.pay_id" :key="index"  :label="option.pay_type">
                                {{ option.pay_type }}
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="上传企业标识">
                            <el-upload
                                :action="$axios.defaults.baseURL+'index.php?act=store_info&op=storeUp'"
                                list-type="picture-card"
                                :on-preview="handlePictureCardPreview"
                                :data="upImgData"
                                :headers="{'token':$store.state.token}"
                                name="pic"
                                :on-success="upImgSuccessLogo"
                                :limit="1"
                                :file-list="[{url:companyInfo.store_logo_url}]"
                                >
                                <i class="el-icon-plus"></i>
                            </el-upload>
                        </el-form-item>
                    </div>
                    
                </section>
                <section class="information-box" v-if="tempNum==7">
                    <div class="title">
                        经营信息
                    </div>
                    <el-button type="primary" @click="swiEdits" size="mini">
                        {{btnText}}
                    </el-button> 
                    <div class="info-from">
                        <el-form-item label="统一社会信用代码:">
                            <el-input v-model="companyInfo.business_licence_number"></el-input>
                        </el-form-item>
                        <el-form-item label="企业名称：">
                            <el-input v-model="companyInfo.store_company_name"></el-input>
                        </el-form-item>
                      
                        <el-form-item label="法定代表人：">
                            <el-input v-model="companyInfo.corporation_name"></el-input>
                        </el-form-item>
                        <el-form-item label="省市区：">
                            <el-input style="width: 350px" v-model="companyInfo.area_info" type="textarea" resize="none"></el-input>
                        </el-form-item>
                        
                        <el-form-item label="主营产品：">
                            <el-input v-model="companyInfo.store_keywords"></el-input>
                        </el-form-item>
                        <el-form-item label="企业网址:">
                            <el-input v-model="companyInfo.company_site"></el-input>
                        </el-form-item>
                        <el-form-item label="企业公众号：">
                            <el-input v-model="companyInfo.company_gzcode"></el-input>
                        </el-form-item>
                        <el-form-item label="经销商配件品牌:">
                            <el-input v-model="companyInfo.parts_brand"></el-input>
                        </el-form-item>
                        <el-form-item label="经销商汽车品牌:">
                            <el-input v-model="companyInfo.vehicle_brand"></el-input>
                        </el-form-item>

                        <el-form-item label="上传营业执照:">
                            <el-upload
                                :action="$axios.defaults.baseURL+'index.php?act=store_info&op=storeUp'"
                                list-type="picture-card"
                                :on-preview="handlePictureCardPreview"
                                :data="upImgData"
                                :headers="{'token':$store.state.token}"
                                name="pic"
                                :on-success="upImgSuccessBus"
                                :limit="1"
                                :file-list="[{url:companyInfo.business_licence_number_elc_url}]"
                                >
                                <i class="el-icon-plus"></i>
                            </el-upload>
                        </el-form-item>
                    </div>
                </section>
                <section class="information-box" v-if="tempNum==8">
                    <div class="title">
                        身份信息
                    </div>
                    <el-button type="primary" @click="swiEditss" size="mini">
                        {{btnText}}
                    </el-button> 
                    <div class="info-from">
                        <el-form-item label="姓名:">
                            <el-input v-model="companyInfo.corporation_name"></el-input>
                        </el-form-item>
                        <el-form-item label="身份证号">
                            <el-input v-model="companyInfo.corporation_code"></el-input>
                        </el-form-item>
                        <el-form-item label="上传身份证">
                            <div class="up-box">
                                <el-upload
                                    :action="$axios.defaults.baseURL+'index.php?act=store_info&op=storeUp'"
                                    list-type="picture-card"
                                    :on-preview="handlePictureCardPreview"
                                    :data="upImgData"
                                    :headers="{'token':$store.state.token}"
                                    name="pic"
                                    :on-success="upImgSuccessId"
                                  
                                    :file-list="[{url:companyInfo.id_just_url}]"
                                    :limit="1"
                                    >
                                    <div class="up-item-text">
                                        <span class="text">正面</span>
                                        <span><i class="el-icon-plus"></i></span>
                                    </div>
                                    
                                </el-upload>
                                <el-upload
                                    :action="$axios.defaults.baseURL+'index.php?act=store_info&op=storeUp'"
                                    list-type="picture-card"
                                    :on-preview="handlePictureCardPreview"
                                    :data="upImgData"
                                    :headers="{'token':$store.state.token}"
                                    name="pic"
                                    :on-success="upImgSuccessIdBack"
                                    :file-list="[{url:companyInfo.id_back_url}]"
                                    :limit="1"
                                    >
                                    
                                    <div class="up-item-text">
                                        <span class="text">反面</span>
                                        <span><i class="el-icon-plus"></i></span>
                                    </div>
                                </el-upload>

                            </div>
                        </el-form-item>
                    </div>
                    
                </section>
            <section class="information-box" v-if="store_id!=1780 && tempNum==9">
            <div class="title">
                采购协议
            </div>
            <el-button type="primary" @click="swiEditsss" size="mini">
                        {{btnText}}
                    </el-button> 
            <div class="container">
                <div class="con-left">
                     <div class="infor-item">
                        <el-form-item label="合同签署日期:">
                            <el-input v-model="companyInfo.contract_starttime"></el-input>
                        </el-form-item>
                    </div>
                    <div class="infor-item">
                        <el-form-item label="合通截止日期:">
                            <el-input v-model="companyInfo.contract_endtime"></el-input>
                        </el-form-item>
                    </div>
                    <div class="infor-item">
                         <el-form-item label="采购额度:">
                            <el-input v-model="companyInfo.purchase_quota"></el-input>
                        </el-form-item>
                    </div>
                    <el-form-item label="上传采购协议">
                            <el-upload
                                :action="$axios.defaults.baseURL+'index.php?act=store_info&op=storeUp'"
                                list-type="picture-card"
                                :on-preview="handlePictureCardPreview"
                                :data="upImgData"
                                :headers="{'token':$store.state.token}"
                                name="pic"
                                :on-success="upImgSuccessPur"
                                :file-list="[{url:companyInfo.purchase_contract_url}]"
                                >
                                <i class="el-icon-plus"></i>
                            </el-upload>
                        </el-form-item>
                </div>
            </div>
        </section>  
                </el-form>
        </div>
    
    </template>

    <el-dialog :visible.sync="dialogVisible">
        <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
</div>
</template>

<script>
import AdressSelect from "../../components/AdressSelect"
import { mapState } from "vuex"
export default {
    data(){
        return{
            redact:{},
            site:[],
            companyType:{},
            btnText:"",
            classTypeList:[],
            payTypeList:[],
            // typeList:{},
            isEdit:false,
            hasAddr:false,
            tempNum:1,
            btnText:"编辑",
            upImgData:{
                store_id:this.$store.state.store_id
            },
            companyInfo:{
                store_company_name:"",
                business_licence_number:"",
                area_info:"",
                store_address:"",
                sc_id:"",
                sc_name:"",
                contacts_name:"",
                contacts_phone:"",
                store_phone:"",
                store_logo_url:"",
                store_logo:"",
                company_name_jc:"",
                pay_id:"",
                pay_type:"",
                setup_time:"",
                setend_time:"",
                corporation_name:"",
                corporation_code:"",
                business_licence_number_elc:"",
                business_licence_number_elc_url:"",
                store_keywords:"",
                id_just:"",
                id_just_url:"",
                id_back:"",
                id_back_url:"",
                contract_starttime:"",
                contract_endtime:"",
                purchase_quota:"",
                purchase_price:"",
                purchase_contract:"",
                purchase_contract_url:"",
                consocia_nature:""
            },
            dialogImageUrl:"",
            dialogVisible:false,
            fileList:[[],[],[],[],[]]
        }
    },
    methods:{
        // logog上传
        upImgSuccessLogo(res){
            this.companyInfo.store_logo= res.data.file_name
            this.companyInfo.store_logo_url =  res.data.file_url
        },
        // 营业执照
        upImgSuccessBus(res){
            this.companyInfo.business_licence_number_elc= res.data.file_name
        },
        // 身份证
        upImgSuccessId(res){
            this.companyInfo.id_just= res.data.file_name
        },
        upImgSuccessIdBack(res){
            this.companyInfo.id_back= res.data.file_name

        },
        // 采购协议
        upImgSuccessPur(res){
            this.companyInfo.purchase_contract= res.data.file_name
        },
        async saveEdut(){

            let {pay_id,sc_id} = this.companyInfo

            this.payTypeList.forEach(item=>{
                if(item.pay_id==pay_id){
                    this.companyInfo.pay_type = item.pay_type
                }
            })
            
            this.classTypeList.forEach(item=>{ 
                if(item.sc_id==sc_id){
                    console.log("sss",this.classTypeList);
                    this.companyInfo.sc_name = item.sc_name
                }
                
            })
            
            let res = await this.$api.getRedact({
                 store_id:this.store_id,
                 ...this.companyInfo
                 
            })
          
            if(res){

                this.companyInfo = await this.$api.getCompanyInfo({
                        id:this.store_id
                })

               
                this.$store.commit("UpDateState",{
                    company_name_jc:this.companyInfo.company_name_jc,
                    logo_url:this.companyInfo.store_logo_url
                })

                localStorage.removeItem("hnw-web-state")
                let state = JSON.stringify(this.$store.state)
                localStorage.setItem("hnw-web-state",state)
            }
            
            
        },
        swiEdit(){
            if(this.isEdit){
                this.saveEdut()
                this.btnText = "编辑"
                this.isEdit = false
                this.tempNum = 1
                this.hasAddr=false            
            }else{
                // 赋值图片
                
                this.hasAddr=true
                this.btnText = "保存"
                this.isEdit = true
                this.tempNum = 6
                
            }
        },
        swiEdits(){
            if(this.isEdit){
                this.saveEdut()
                this.btnText = "编辑"
                this.isEdit = false
                this.tempNum = 2
                this.hasAddr=false            
            }else{
                // 赋值图片
                
                this.hasAddr=true
                this.btnText = "保存"
                this.isEdit = true
                this.tempNum = 7
            }
        },
        swiEditss(){
            if(this.isEdit){
                this.saveEdut()
                this.btnText = "编辑"
                this.isEdit = false
                this.tempNum = 3
                this.hasAddr=false            
            }else{
                // 赋值图片
                
                this.hasAddr=true
                this.btnText = "保存"
                this.isEdit = true
                this.tempNum = 8
            }
        },
        swiEditsss(){
            if(this.isEdit){
                this.saveEdut()
                this.btnText = "编辑"
                this.isEdit = false
                this.tempNum = 4
                this.hasAddr=false            
            }else{
                // 赋值图片
                
                this.hasAddr=true
                this.btnText = "保存"
                this.isEdit = true
                this.tempNum = 9
            }
        },
        upAdress(site){
            this.$set(this.companyInfo,"area_info",site)
        },
        handlePictureCardPreview(file) {
            console.log("file",file)
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
        },
        redBigImg(url){
            this.dialogImageUrl = url
            console.log(this.dialogImageUrl)
            this.dialogVisible  = true
        },
        payChange(){
            console.log("pay_id",this.companyInfo.pay_id)
        },
        async downProp(url){
            
            let src = `${this.$axios.defaults.baseURL}index.php?act=store_index&op=download&file=${url}`;
            let link = document.createElement('a');
            link.href = src;
            link.click();
        }
        
    },
    components:{
        AdressSelect
    },
    computed:{
        ...mapState(["store_id"])
    },
    async created(){
        let res= await this.$api.getCompanyInfo({
             id:this.store_id
        })
        console.log("res",res)
        this.companyInfo = res
        try{
            this.classtypeList = await this.$api.getAllCompanyType();
            // console.log("classtypeList",this.classtypeList)
            this.payTypeList = await this.$api.getPayType();
        
        }catch(e){
            console.log(e)
        }
       
    },
    
}
</script>

<style lang="scss" scoped>
.sysCompany{
    
    .company-handle{
        width: 70%;
        min-width: 1000px;
        .yy{
        display: flex;
        width: 1000px;
        height: 50px;
        background: #FFFFFF;
        line-height: 50px;
        // padding-left: 8px;
        margin: 10px 0 ;
        border-bottom: 1px solid #50A3F8;
        >div{
            width: 116px;
            text-align: center;
            cursor:pointer;
        }
        .active{
            background-color: #50A3F8;
            color: #fff;
        }
    }
    }
   
    .information-box{
        position: relative;
        background: #fff;
        padding: 10px 0 5px;
        border-bottom: 2px solid#eee;
        width: 70%;
        min-width: 1000px;
        .el-button{
            position: absolute;
            top: 20px;
            right: 20px;
            width: 90px;
            height: 32px;
            background: #59A8F8;
            border-radius: 3px;
            z-index: 1;
        }
        .title{
            padding-left:8px;
            color: #000;
            font-size: 18px;
            font-weight: 400;           
            display: flex;
            // margin-top: 20px;
            padding-top: 20px;
            margin-bottom: 10px;
            .right-title{
                min-width: 600px;
                text-align: right;
            }
        }
        .container{
            padding: 5px 0;
            padding-left: 40px;
            display: flex;
            
            .con-left{
                min-width: 500px;
                flex: 1;
                .infor-item{
                    display: flex;
                    line-height: 22px;
                    padding: 10px 0;
                    .label{
                       color: #999;
                        min-width: 130px;
                        text-align: right;
                        margin-right: 5px;
                    }
                    .img-list-item{
                            margin-right: 10px;
                          
                            .img-box{
                                width: 150px;
                                height: 105px;
                               
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                margin-bottom: 5px;
                                img{
                                    width: 90%;
                                    height: 90%;
                                    cursor: pointer;
                                }
                            }
                            .handle-span{
                                // cursor: pointer;
                                color: #3E84D6;
                                
                            }
                            .down{
                                cursor: pointer;
                                
                            }
                        }
                .value{

                    }
                .valueInput{
                    height: 28px;
                        padding-left: 5px;
                        border-radius: 0;
                        border: 1px solid #CBCBCB;
                        width: 350px;
                        background-color: #fff;
                    }
                }
                .img-list{
                        // display: flex;
                        // padding: 5px 0;                        
                        .img-list-item{
                            margin-right: 10px;
                          
                            .img-box{
                                width: 150px;
                                height: 105px;
                               
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                margin-bottom: 5px;
                                img{
                                    width: 90%;
                                    height: 90%;
                                    cursor: pointer;
                                }
                            }
                            .handle-span{
                                // cursor: pointer;
                                color: #3E84D6;
                                
                            }
                            .down{
                                cursor: pointer;
                                
                            }
                        }
                    }
            }
            .con-right{
                flex: 1;
                .right-container{
                    padding-left: 40px;
                   
                    box-sizing: border-box;
                    .img-list{
                        display: flex;
                        padding: 5px 0;                        
                        .img-list-item{
                            margin-right: 10px;
                          
                            .img-box{
                                width: 150px;
                                height: 105px;
                               
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                margin-bottom: 5px;
                                img{
                                    width: 90%;
                                    height: 90%;
                                    cursor: pointer;
                                }
                            }
                            .handle-span{
                                // cursor: pointer;
                                color: #3E84D6;
                                
                            }
                            .down{
                                cursor: pointer;
                                
                            }
                        }
                    }
                }
            }
        }
        .info-from{
            padding: 10px 0;
            .up-box{
                display: flex;
                .up-item-text{
                    position: relative;
                    .text{
                        position: absolute;
                        top: 55%;
                        left: 50%;
                        transform: translateX(-50%);
                        height: 20px;
                        line-height: 20px;
                    }
                    .el-icon-plus{
                        position: relative;
                        top: -15px;
                    }
                }
            }
        }
    }
    .information-box:first-child{
        padding-top: -1px;
    }
    .from-box{
        .el-form-item__label{
            text-align: right;
        } 
        .el-input{
            width: 350px;
        }
    }
}
</style>