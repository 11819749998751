import { post } from "axios"
// 获取验证码
const sendSms = async (data)=>{
    const res = await post("index.php?act=login&op=send_sms", {
        ...data
    });
    return res
}
// 登录
const login = async (data)=>{
    const res = await post("index.php?act=login&op=login",{
        ...data
    })
    return res
}
// 验证码登录
const meslogin = async (data)=>{
    const res = await post("index.php?act=login&op=mesLogin",{
        ...data
    })
    return res
}
// 注册账号
const register = async (data)=>{
    const res = await post("index.php?act=login&op=register",{
        ...data
    })
    return res
}
// 认证
const authenAcc = async (data)=>{
    const res = await post("index.php?act=login&op=authentication",{
        ...data
    })
    return res
}
// 获取认证状态
const getAuthenState = async (member_id)=>{
    const res = await post("index.php?act=login&op=authenticationInfo",{
        member_id
    })
    return res
}
const checkSMS = async (data)=>{
    const res = await post("index.php?act=login&op=checkSMS",{
        ...data
    })
    return res
}
// 修改密码
const forgetPassword = async (data)=>{
    const res = await post("index.php?act=login&op=forget_password",{
        ...data
    })
    return res
}
export {
    sendSms,
    login,
    meslogin,
    register,
    authenAcc,
    getAuthenState,
    checkSMS,
    forgetPassword
}