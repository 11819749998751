<template>
    <div class="purchase-list" >
        <div  v-if="tempNum==1" class="hnw-area-center"> 
            <div class="pur-bannder " v-if="bannerList.length>0">
                <el-carousel height="360px">
                    <el-carousel-item v-for="(banner,index) in bannerList" :key="index"  >
                        <img :src="banner.adv_pic" alt="" @click="bannerJump(banner)">
                    </el-carousel-item>
                </el-carousel>
            </div>

            <div class="pur-module-list">
                <div class="module-item">
                    <div class="module-title">
                        <div class="title-l">
                            <div class="title-text">
                                正在集单
                                <span class="bot-line"></span>
                            </div>
                        </div>
                        <div class="title-r">
                            更多 <i class="el-icon-arrow-right"></i>
                        </div>
                    </div>
                    <div class="module-content">
                        <div     class="module-content-item" v-for="(acti,index) in nowList" :key="index">
                            <img  :src="acti.groupbuy_image" alt=""  class="act-img" @click="tododetail(acti)">
                           
                            <div class="act-info">
                                <div class="act-title">
                                    <div class="set-name">
                                        {{acti.setlist_name}}
                                        <span class="subsidiary-tit">{{acti.remark}}</span>
                                    </div>
                                </div>
                                <div class="act-desc">
                                    <div class="price">￥{{acti.totalsum}}</div>
                                    大单来袭
                                    <div class="desc">
                                        <img src="../../assets/commom/75.png" alt="">
                                        <span>{{acti.buyer_count}}</span>
                                        <img src="../../assets/commom/qian.png" alt="">
                                        <span>{{acti.totalsum_now}}</span>
                                        <span>完成率{{ acti.rote}}%</span>
                                    </div>
                                </div>
                            </div>   
                        </div>
                    </div>
                </div>
                <!-- 即将集单 -->
                <div class="module-item">
                    <div class="module-title">
                        <div class="title-l">
                            <div class="title-text">
                                即将集单
                                <span class="bot-line"></span>
                            </div>
                            
                        </div>
                        <div class="title-r">
                            更多 <i class="el-icon-arrow-right"></i>
                        </div>
                    </div>
                    <div class="module-content">
                        <div class="module-content-item" v-for="(acti,index) in prefoList" :key="index">
                            <img :src="acti.groupbuy_image" alt=""  class="act-img" @click="tododetail(acti)" >
                            <div class="act-info">
                                <div class="act-title">
                                    <div class="set-name">
                                        {{acti.setlist_name}}
                                        <span class="subsidiary-tit">{{acti.remark}}</span>
                                    </div>
                                    <!-- {{acti.goods_name}} -->
                                    <div class="title-count-down">
                                        距离开始，还剩
                                        <div class="num">{{acti.leftDay}}</div>天
                                        <CountDwon bgrColor="#000" fontColor="#fff" fontSize="12px" :timer="acti.leftTime" width="16px" v-if="acti.leftTime"></CountDwon> 
                                        <!-- <div class="num">10</div>:
                                        <div class="num">09</div>:
                                        <div class="num">09</div> -->
                                    </div>
                                </div>

                                <div class="act-desc">
                                    <div class="price">￥{{acti.totalsum}}</div>
                                    大单来袭
                                    
                                </div>
                            </div>   
                        </div>
                    </div>
                </div>
                <!-- 已完成集单 -->
                <div class="module-item">
                    <div class="module-title">
                        <div class="title-l">
                            <div class="title-text">
                                已完成集单
                                <span class="bot-line"></span>
                            </div>
                            
                        </div>
                        <div class="title-r">
                            更多 <i class="el-icon-arrow-right"></i>
                        </div>
                    </div>
                    <div class="module-content">
                        <div class="module-content-item" v-for="(acti,index) in overList" :key="index">
                            <img :src="acti.groupbuy_image"  alt=""  class="act-img" @click="tododetail(acti)">
                            
                            <div class="act-info">
                                <div class="act-title">
                                    <div class="set-name">
                                        {{acti.setlist_name}}
                                        <span class="subsidiary-tit">{{acti.remark}}</span>
                                    </div>
                                </div>
                                <div class="act-overInfo">
                                    <img src="../../assets/commom/75.png" alt="">
                                    <span>{{acti.buyer_count}}</span>
                                    <img src="../../assets/commom/qian.png" alt="">
                                    <span>{{acti.totalsum_now}}</span>
                                    <span>完成率{{acti.rote}}%</span>
                                    <img src="../../assets/index/pur/成功.png" class="overImg" alt="" v-if="acti.rote>100">
                                    <img src="../../assets/index/pur/失败.png" class="overImg" alt="" v-else>
                                </div>
                            </div>   
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <template v-else-if="tempNum==2">
            <router-view/>
            <!-- <div class="detail-box">
                <detail @reBack="tempNum=1" :itemData="itemData" />
            </div> -->
        </template>
</div>
</template>

<script>

export default {
   
    data(){
        return{
            tempNum:1,
            bannerList:[],
            prefoList:[],
            nowList:[],
            overList:[]
        }
    },
    methods:{
        async bannerJump(banner){
			
            if(banner.goods_id){
                try {
                    let jumpMsg = JSON.parse(banner.goods_id)
                    if(jumpMsg.type=='goods'){
                        const res = await this.$api.getGoodsInfo({
                            goods_id:jumpMsg.data
                        })
                        console.log("当前商品是",res);
                        this.goRecommend(res)
                    }else if(jumpMsg.type=='brand'){

                        this.$store.commit('UpDateState',{
                            searchText:jumpMsg.data
                        })

                        this.$router.push('/find/index')
                    }else if(jumpMsg.type=='link'){
                        this.$router.push(jumpMsg.data)
                    }else if(jumpMsg.type=='bodyImg'){
                        console.log("jumpMsg.data",);
                        localStorage.setItem("web-banner-body-img",jumpMsg.data)
                        this.$router.push("/bodyImg")
                    }
                } catch (error) {
                    console.log("解goods_id信息发生错误",banner.goods_id);
                }
                
            }else if(banner.img.search('vin')!=-1){
                if(!this.token){
                    this.$warMsg("未登录账号")
                    return this.$router.push("/login")
                    
                }else if(this.$isCompany()){
                    let link = location.href.split("#")[0]
                    window.open(link + '#/epc/home/vin')
                    // if(this.$store.state.shop==1780){
                    // }else{
                    // 	this.$warMsg("您的账号不能查看电子目录")
                    // }
                }
            }
        },
        tododetail(acti){
            if(acti.isHnwBanner){
                return 
            }
            if(this.$isCompany()){

                console.log("acti",acti)
                acti.preRou = "集单采购"
                localStorage.setItem("hnw-web-item-data",JSON.stringify(acti))
                
                this.tempNum = 2
                this.$router.push("/purchase/goodsinfo")
            }
        },
        goRecommend(acti){
            if(this.$isCompany()){
                acti.preRou = "集单轮播图"
                let rou = '/index/goodsinfo'
                localStorage.setItem("hnw-web-item-data",JSON.stringify(acti))
                
                this.$router.push(rou)
            }
        },
        // 处理时间大于一天的情况和完成率，以及标记上是否可以购买
        handlerDay(){

            // 计算即将开始时间
            // (acti.totalsum_now*10000) / (acti.totalsum*10000*10000)
            let arrs = ["prefoList","nowList","overList"]
            
            let key = ""
            let dayTime =  60*60*24*1000
            arrs.forEach(arr=>{
                if(arr=='prefoList'){
                    key = "start_time"
                    
                }else{
                    key = "end_time"
                }
                
                this[arr].forEach(acti=>{
                    let leftTime = 0
                    if(arr=='prefoList'){
                        
                        acti[key] = acti[key]*1000
                        
                        leftTime =  acti[key] - new Date()
                    }else{
                        leftTime =  acti[key] - new Date()
                    }

                   
                    if(leftTime>dayTime){
                        let leftDay = parseInt(leftTime/dayTime)
                        this.$set(acti,"leftDay",leftDay)
                        // 剩余总时间减去大于一天的天数
                        
                        this.$set(acti,"leftTime", acti[key] - leftDay*dayTime )
                         
                    }else{
                         this.$set(acti,"leftDay",0)
                        this.$set(acti,"leftTime",acti[key])
                    }

                
                    if(arr=='nowList'||arr=='overList'){
                        // let rote = parseInt(acti.totalsum_now*100) / (acti.totalsum*100) *100

                        let rote = this.$hnwTools.div(acti.totalsum_now,acti.totalsum) *100
                        this.$set(acti,"rote",Number(rote.toFixed(2)))
                    }   
                    // 标记
                    if(arr=="overList"){
                        this.$set(acti,"notbuy",'over')
                    }else if(arr=='prefoList'){
                        this.$set(acti,"notbuy",'pre')
                    }                 
                })
                
            })
        }

    },
    async created(){
        this.nowList = await this.$api.getSetList({mark:1})
        let bannerRes = await this.$api.getHomeBanner({
            source:4
        })
        console.log("bannerRes",bannerRes);
        if(bannerRes.length>0){
            this.bannerList = bannerRes
        }
        
        this.prefoList = await this.$api.getSetList({mark:2})
        this.overList = await this.$api.getSetList({mark:3})
       
        // 处理时间
        this.handlerDay()
    }
}
</script>

<style lang="scss">
.purchase-list{

    .hnw-area-center{

    }
    .detail-box{
        background: #fff;
    }
    .pur-bannder{
        height: 360px;
        overflow: hidden;
        margin-top: 10px;
        img{
            width: 100%;
            height: 100%;
            cursor: pointer;
        }
    }
    .pur-module-list{
        // padding-bottom: 20px;
        
        .module-item{
            .module-title{
                height: 40px;
                display: flex;
                justify-content: space-between;
                border-bottom: 1px solid $split-color;
                align-items: center;
                .title-l{
                    display: flex;
                    align-items: center;
                    font-size: 20px;
                    .title-text{
                        font-weight: bold;
                        font-size: 18px;
                        position: relative;
                        .bot-line{
                            position: absolute;
                            width: 100%;
                            background: #3E84D6;
                            height: 4px;
                            left: 0;
                            bottom: -15px;
                        }   
                    }
                    .surplus{
                        font-size: 14px;
                        display: flex;
                        margin-left: 12px;
                        .num{
                            font-size: 14px;
                            color: #fff;
                            background: #000;
                            border-radius: 2px;
                            padding: 0 2px;
                            margin: 0 2px;
                        }
                    }
                }
                .title-r{
                    font-size: 16px;
                    color: #999999;
                    cursor: pointer;
                    i{
                        font-size: 12px;
                    }
                }
            }
            .module-content{
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                background: #fff;
                padding-bottom: 10px;
                .module-content-item{
                    width: 570px;
                    
                   padding-top: 10px;
                    .act-img{
                        padding-bottom: 10px;
                        width: 570px;
                        height: 170px;
                        cursor: pointer;
                    }
                    .act-info{
                        .act-title{
                            font-size: 16px;
                            font-weight: 700;
                            display: flex;
                            justify-content: space-between;
                            .set-name{
                                .subsidiary-tit{
									padding-left: 6px;
								}   
                            }
                            .title-count-down{
                                min-width: 230px;
                                font-weight: 500;
                                display: flex;
                                align-items: center;
                                font-size: 14px;
                                .num{
                                    font-size: 14px;
                                    color: #fff;
                                    background: #000;
                                    border-radius: 2px;
                                    width: 18px;
                                    height: 18px;
                                    text-align: center;
                                    line-height: 18px;
                                    margin: 0 2px;
                                }
                            }
                            
                        }
                        .act-desc{
                            padding-top: 6px;
                            display: flex;
                            align-items: center;
                            font-size: 14px;
                            .price{
                                color: #3E84D6;
                                font-size: 14px;
                            }
                            .desc{
                                display: flex;
                                align-items: center;
                                border-left: 1px solid $split-color;
                                padding-left: 10px;
                                margin-left: 10px;
                                img{

                                }
                                span{
                                    padding: 0 5px;
                                }
                            }
                        }
                        // 结束的集单信息
                        .act-overInfo{
                            display: flex;
                            align-items: center;
                            margin-top: 5px;
                            font-size: 16px;
                            img{

                            }
                            span{
                                margin: 0 22px 0 4px;
                            }
                            .overImg{

                            }
                        }
                    }
                }
            }
        }
    }
}
</style>