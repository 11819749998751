<template>
<div class="BtnBox" >
    <el-button type="primary" @click="btnTigg" size="mini">{{btnText}}</el-button>
</div>
</template>

<script>
export default {
    data(){
        return {

        }
    },
    methods:{
        btnTigg(){
            this.$emit("click1")
        }
    },
    props:{
        btnText:""
    }
}
</script>

<style lang="scss">
.BtnBox{
    // padding: 10px 0;
    padding: 5px 0;
    padding-top: 0;
    text-align: right;
}
</style>