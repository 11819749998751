import { post } from "axios"
import store from '../../../store'
// 获取所有店铺推荐商品
const getAllShopGoods = async ()=>{
    return await post("index.php?act=web_index&op=shopGoods",{
        page:8
    })
}
// 需求详情去联系
const connectionDema = async (dem_id)=>{
    return await post("index.php?act=store_demand&op=demand_ov",{
        dem_id
    })
}

const getHomeBanner = async (data)=>{
    return await post("index.php?act=web_index&op=getBannerList",{
        ...data
    })
}
// 推荐列表
const getlistRecom = async (data)=>{
     let res = await post("index.php?act=store_shop&op=listRecom",{
        ...data
    })
    return res
}
// 折扣列表
const getDisList = async (data)=>{
    let house = ""
    if(store.state.shop==1780){
        house = store.state.is_sh=="0"?'丹阳仓':"上海仓"
    }
     let res = await post("index.php?act=groupbuy&op=get_discount",{
        ...data,
        house
    })
    return res
}

const getlistRecomImg = async (data)=>{
     let res = await post("index.php?act=goods&op=goods_default",{
        ...data
    })
    return res
}
const getSeckiList = async (data)=>{
    let res = await post("index.php?act=groupbuy&op=getlist",{
        ...data
    })
    return res
}
// 采购报表
const getReport = async (data)=>{
    let res = await post("index.php?act=favorites&op=report",{
        ...data
    })
    return res
}
// 添加别名
const addAlias = async (data)=>{
    let res = await post("index.php?act=web_index&op=alias_goods",{
        ...data
    })
    return res
}
export {
    getAllShopGoods,
    connectionDema,
    getHomeBanner,
    getlistRecom,
    getDisList,
    getlistRecomImg,
    getSeckiList,
    getReport,
    addAlias,
}