<template>
<div class="my-order">
    <div class="order-state">
        <div class="order-state-item" :class="{'active':order_state===item.value}" v-for="(item,index) in  orderStateList" :key="index" @click="order_state=item.value">
            {{item.label}}
            {{item.num}}
        </div>
    </div>
    <ParaSearch 
        api="getOrderList" 
        ref="ParaSearch"
        :prammsList="prammsList"
        :searchData="searchData" 
        @searchEnd="searchEnd"
        :type="searchData.type"
        :order_state="order_state"
    ></ParaSearch>

    
    <div class="order-list">
        <div class="batch-handle">
            <input type="text" v-model="webVers" v-if="$store.state.store_id=='1989'">
            <el-button size="mini" type="primary" @click="upVerson" v-if="$store.state.store_id=='1989'">版本升级</el-button>
            <!-- <input
            type="file"
            v-if="$store.state.store_id=='1989'"
            ref="ExelFile"
            @change="skuBatch"
            
            class="eleclFIle"
            /> -->
            
            <el-button size="mini" type="primary" @click="exportOrder">订单导出</el-button>
        </div>
        <div class="order-head">

            <div class="order-goods">
                <div class="goods-info">
                     <div class="chec-box">
                        <el-checkbox v-model="allChoose" @change="allChooseChange">全选</el-checkbox>
                    </div>
                    商品详情
                </div>
                <div class="price">单价</div>
                <div class="num">数量</div>
                <div class="handle">商品操作</div>
            </div>
            <div class="order-handler">
                <div class="deal-handle">收货确认</div>
                <div class="actual">实付款</div>
                <div class="deal-state">交易状态</div>
                <div class="deal-handle">交易操作</div>
            </div>
        </div>
        <div class="order-body">
            <div class="order-row" v-for="(order,index) in orderList" :key="index">
                <div class="order-msg">
                    <div class="chec-box">
                        <el-checkbox v-model="order.choose"></el-checkbox>
                    </div>
                    <div>{{ $hnwTools.formatDateTime(order.order_list.add_time)}}</div>
                    <div>订单号：{{order.order_list.order_sn}}</div>
                    <div>
                        <!-- <span v-if="$store.state.is_sh==0">
                            华诺威汽车零部件（江苏）有限公司
                        </span> -->
                        <span v-if="$store.state.for_branch">
                            {{$store.state.shopInfo.shop_name}}
                        </span>
                        <span v-else-if="$store.state.for_branch||order.store_info.store_id==1780||order.store_info.store_id=='2197'">
                            {{order.store_info.store_name}}
                        </span>
                        <span v-else>
                             {{order.store_info.company_plat_name}}
                        </span>
                    </div>
                    
                    <img src="../../assets/commom/storeIcon.png" alt="">
                    <div class="jdr">制单人：{{order.order_list.member_name}}</div>
                    <img src="../../assets/commom/del.png" alt="" class="del-btn" @click="orderDel(order.order_list.order_sn)">
                </div>
                <div class="list-container">
                    
                    <div class="order-goods">
                        <div class="row" v-for="(goods,index1) in order.order_list.goods" :key="index1">
                            <div class="goods-info">
                                

                                <div class="img-info" v-if="goods.type==2">
                                    <div class="img-box">
                                        <img class="goods-img" :src="goods.goods_image||require('../../assets/temp/mschuizi.png')" alt="">
                                    </div>
                                    <div class="info">
                                        <div @click="getGoodsInfo(goods.goods_id)" class="cur">
                                            <div class="name" :title="goods.goods_name">{{goods.goods_name}}</div>
                                            <div class="bag">{{goods.type==2?'集':(goods.type==3?"秒杀":"采购")}}</div>
                                        </div>
                                        <div>
                                            {{goods.oecode}}
                                        </div>
                                        <div class="vehicle">
                                            车型:{{goods.vehicletype}}
                                        </div>
                                    </div>
                                </div>

                                <div class="no-img-info" v-else>
                                    <div class="type">
                                        <!-- 找件 -->
                                        
                                        {{goods.type==1?"采购":(goods.type==2?"集单":"秒杀")}}
                                    </div>
                                    <div class="info">
                                       <div @click="getGoodsInfo(goods.goods_id)" class="cur">
                                            <div class="name">{{goods.goods_name}}</div>
                                            <div>{{goods.oecode}}</div>
                                       </div>
                                       <div class="vehicle">
                                           车型:{{goods.vehicletype}}
                                       </div>
                                    </div>
                                </div>

                            </div>
                            <div class="price" v-if="$store.state.shop==1948">
                                 价格面议
                            </div>
                            <div class="price" v-else>
                                ￥{{goods.goods_price}}

                            </div>
                            <!-- <div class="price" v-if="$store.state.shop!='2136'"> ¥ {{goods.goods_price}}</div>
                            <div class="price" v-else> ¥ {{goods.taxSellPrice2||goods.goods_price}}</div> -->
                            <div class="num">
                                {{goods.goods_num}}
                                <span class="goods-zbyh-tit" v-if="order.order_list.order_state!=4&&order.order_list.chain==3&&Number(goods.storage)==0">
                                    总仓发货，预计1~3天发货
                                </span>
                            </div>
                            <div class="handle" >
                                <!-- <div @click="$router.push('/my/ReSales')"> -->
                                 <!--  -->
                                <div @click="barter(order,goods)" v-if="order.order_list.order_state==4">
                                    退款/退换货
                                </div>
                                
                            </div>
                            
                        </div>
                    </div>
                    <div class="order-handler">
                        <div class="deal-handle-acquire">
                            <!-- <template v-if="order.order_list.order_state==2">

                            </template> -->
                            <template v-if="order.order_list.receiveGoods_type==1">
                                <div v-for="(goods,index) in order.order_list.goods" :key="index">
                                    <!-- <div class="btn"  @click="orderAff(order.order_list,goods)">
                                        确认收货
                                    </div> -->
                                    <el-button size="mini" type="primary" @click="orderAff(order.order_list,goods)">确认收货</el-button>
                                </div>
                            </template>
                            <div v-if="order.order_list.receiveGoods_type==2" class="one">
                                <!-- <div class="btn"  @click="orderAff(order.order_list)">
                                    确认收货
                                </div> -->
                                <el-button size="mini" type="primary" @click="orderAff(order.order_list,goods)">确认收货</el-button>
                            </div>
                        </div>
                        <div>
                            <div class="actual">
                                <div class="price">
                                    <div>应付</div> 
                                    <div v-if="$store.state.shop==1948">价格面议</div> 
                                    <div v-else>￥{{order.order_list.total_sum}}</div> 
                                </div>

                                <div class="type_feight" :class="{'no-feight':order.order_list.goods.length>0}">
                                    <!-- <div>(含运费￥00.00)</div>  -->
                                    <div>{{order.order_list.set_type}}</div> 
                                </div>

                            </div>
                        </div>
                        <div>
                            <div class="deal-state">
                                <div>{{$hnwTools.getOrderStateText(order.order_list.order_state)}}</div>
                                <div @click="orderInfo(order.order_list.id)">订单详情</div>
                                <div>查看物流</div>
                            </div>
                        </div>
                        <div class="deal-handle-box">
                            <div class="deal-handle">
                                <div class="left-time">

                                    <!-- <div v-if="order.order_list.order_state==0&&order.order_list.set_type=='在线支付'&&order.order_list.s!=0" class="red">
                                        <img src="../../assets/my/redClock.png" alt="" >
                                        <span>
                                            {{ order.order_list.i}}分{{ order.order_list.s}}秒
                                       
                                        </span>
                                    </div> -->
                                        <!-- 剩余<CountDwon bgrColor="#fff" :bor="false" splitorColor="#f00" fontColor="#f00" fontSize="12px" :timer="order.order_list.payment_time_over*1000" width="16px" splitorText="时分"></CountDwon> -->

                                    <div v-if="order.order_list.order_state==2">
                                        <img src="../../assets/my/clock.png" alt="">
                                        <span>还剩{{order.order_list.leftTimeText}}</span>
                                    </div>
                                   
                                </div>
                                <!-- &&order.order_list.s!=0 -->
                                <template v-if="order.order_list.set_type=='支付宝'||order.order_list.set_type=='微信'">
                                    <div class="btn" v-if="order.order_list.order_state==0&&order.order_list.cus_id!=1"  @click="orderPay(order.order_list.id)">
                                        付款
                                    </div>
                                </template>
                                
                                <template v-if="order.order_list.set_type=='对公转账'">
                                    <div class="btn" v-if="order.order_list.order_state==0&&!order.order_list.qrcode"  @click="orderPay(order.order_list.id)">
                                        上传凭证
                                    </div>
                                </template>
                            
                                <div  @click="orderCancel(order.order_list)" v-if="order.order_list.order_state==1||order.order_list.order_state==0">
                                    取消订单
                                </div>
                                <div v-if="order.order_list.order_state==-2" @click="again(order.order_list)">
                                    重新下单 
                                </div>

                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
     <!-- 分页 -->
     <div class="pagin-box">
        <el-pagination
        @size-change="sizeChange"
        @current-change="currentChange"
        @reset="reset"
        :current-page.sync="searchData.curpage"
        :page-size.sync="searchData.page"

        :page-sizes="[5, 10, 20, 50]"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        :hide-on-single-page="true"
        >
        </el-pagination>
     </div>
     <el-dialog
		:visible.sync="dialogVisible"
		width="980px"
        top="8vh"
		>
		<DiaGoodsInfo :id="goods_id"></DiaGoodsInfo>
	</el-dialog>
      <!--  -->
</div>
</template>

<script>
export default {
    name:"orderIndex",
    data(){
        return{
            orderStateList:[
                {
                    label:"所有订单",
                    value:"",
                    num:0,
                    sign:"all"
                },
                
                {
                    label:"已提交",
                    value:0,
                    num:0,
                    sign:"submit"
                },
                {
                    label:"待发货",
                    value:1,
                    num:0,
                    sign:"send_no"
                },
                {
                    label:"待收货",
                    value:2,
                    num:0,
                    sign:"send_ok"
                },
                {
                    label:"已完成",
                    value:4,
                    num:0,
                    sign:"over"
                }
            ],
            orderList:[],
            allChoose:false,
            total:0,
            goods_id:"",
            dialogVisible:false,
            order_state:"",
            searchData:{
                curpage:1,
                page:10,
                type:"",
                oecode:"",
                sccode:"",
                goods_name:"",
                store_name:"",
                order_date:"",
                order_sn:""
            },
            batchOrderArr:[],
            prammsList:[
        
                {
                    key: "oecode",
                    title: "OE号",
                },
                {
                    key: "order_sn",
                    title: "订单号",
                },
                {
                    key: "sccode",
                    title: "生产编码",
                },
                {
                    key: "goods_name",
                    title: "配件名称",
                },
                
                {
                    key: "oecarbrand",
                    title: "配件品牌",
                }
            ],
            // web版本
            webVers:"",
        }
    },
    methods:{
        async upVerson(){
            // location.reload();
            await this.$api.handleVer({
                model:1,
                data:this.webVers
            })
            this.$suMsg("升级成功")
        },
        async batchOrder(){
            // console.log("this.batchOrderArr",this.batchOrderArr);
            
            
            // http://test.huanuowei.cn/apiStore/v1.0/store/index.php?act=store_manage&op=storeList
            // http://b.huanuowei.cn/api/store/index.php?act=store_manage&op=storeList
            this.batchOrderArr.forEach(async batOrder=>{
                let comRes = ""
                if(batOrder.store_id&&batOrder.member_id){

                }else{

                    comRes = await this.$http("https://api.huanuowei.cn/store/index.php?act=store_manage&op=storeList",{
                        company_name:batOrder.company_name,
                        store_id:1780
                    })
                    let com = comRes.data[0]
                    if(com){
                    
                        batOrder.store_id = com.store_id
                        batOrder.member_id = com.member_id
                    }else{
                        batOrder.erMsg = "公司查询失败"
                    }
                }
                // console.log("batOrder",batOrder);
                if(batOrder.store_id&&batOrder.member_id){

                    batOrder.orders.forEach((ordermsg,index)=>{
                        
                            setTimeout(async ()=>{
                                let orderData =  await this.$api.getOrderList({
                                    order_sn:ordermsg.sn,
                                    buyer_id: batOrder.store_id,
                                    member_id: batOrder.member_id,
                                    curpage:1,
                                    page:10,
                                    store_id:batOrder.store_id
                                })
                                // order_type:1
                    
    
                                if(orderData.data){
                                    orderData = orderData.data[0].order_list
                                    // 先取消订单
                                   
                                    let cancelRes = await this.$api.cancelOrder({
                                        cancel_remarks:"错误订单取消",
                                        id:orderData.id,
                                        order_sn:orderData.order_sn
                                    })
                                    if(cancelRes){
                                        ordermsg.cancel = true
                                    }
                                    orderData.goods.forEach(goods=>{
                                        goods.order_type = 1
                                        goods.car_id = "batch"
                                    })

                                    let chainID = ''
                                    if(this.$store.state.shop_type==1){
                                        chainID = 1
                                    }else if(this.$store.state.shop_type==2){
                                        chainID = 3
                                    }else if(this.$store.state.shop_type==3){
                                        chainID = 4
                                    }

                                    let subData = {
                                        set_type:"挂账",
                                        zero_buy:this.$store.state.zero_buy,
                                        store_id:batOrder.store_id,
                                        batch:"batch",
                                        chain:chainID,
                                        store:[
                                            {
                                                ...orderData,
                                                
                                                goods_list:orderData.goods,
                                                goods:[],
                                                store_id:"1780",
                                                store_name:"华诺威汽车零部件（上海）股份有限公司",
                                                buyer_remarks:"原订单号:"+orderData.order_sn,
                                            }
                                        ]
                                    }
                                    let creteRes = await this.$api.createOrder(subData)
                                    if(creteRes){
                                        ordermsg.create = true
                                    }
                                }else{
                                    ordermsg.erMsg = "未查到该订单"
                                }
    
                            
                            },index*2000)
                    })
                }
                
            })
            // console.log("this.batchOrderArr",this.batchOrderArr);
        },
        
        async skuBatch(ev){
             let file = ev.target.files[0];
            this.importfxx(file)
        },
        importfxx(obj) {
            let _self = this;
            // 通过DOM取文件数据
            this.file = obj
            let rABS = false; //是否将文件读取为二进制字符串
            let f = this.file;
            let reader = new FileReader();
        
            FileReader.prototype.readAsBinaryString = function(f) {
                
                    let binary = "";
                    let rABS = false; //是否将文件读取为二进制字符串
                    let wb; //读取完成的数据
                    let outdata = [];
                                      
                    let reader = new FileReader();
                    reader.onload = function(e) {

                        let bytes = new Uint8Array(reader.result);
                        let length = bytes.byteLength;
                        
                        for(let i = 0; i < length; i++) {
                            binary += String.fromCharCode(bytes[i]);
                        }
                        let XLSX = require('xlsx');
                        if(rABS) {
                            wb = XLSX.read(btoa(fixdata(binary)), { //手动转化
                                type: 'base64'
                            });
                        } else {
                            wb = XLSX.read(binary, {
                                type: 'binary'
                            });
                        }
                        
                        outdata = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]]);//outdata就是你想要的东西
                        console.log("outdata",outdata)
                        let oem = ""
                        outdata.forEach((item,index)=>{
                            setTimeout(async ()=>{
                                oem = ""
                                // let joinStr = ""
                                let res = await _self.$apiScd.getHnwPartReplace({
                                    manufacturer_id:12,
                                    part_number:item.oecode
                                })
                                if(res&&res.content){
                                    let oems = res.content
                                    if(oems&&oems.length>0){
                                        oems.forEach(item=>{
                                            if(!oem.includes(item.replace_number)&&item.replace_number!=item.oecode){

                                                oem +=  item.replace_number +","
                                            }
                                        })
                                        
                                        
                                        console.log("item.oecode",item.oecode+","+oem);
                                        if(oem!=""){
                                            oem = oem.substring(0,oem.length-1)
                                            _self.$http("index.php?act=web_index&op=hp_add",{
                                                oem:item.oecode+","+oem
                                            })
                                        }
    
                                    }
                                }
                               
                            },index*1000)
                            // if(index<999999){
                            // }
                            
                        })
                    }
                // 再以二进制读取
                reader.readAsArrayBuffer(f);
            }
        
            if(rABS) {
                reader.readAsArrayBuffer(f);
            } else {
                reader.readAsBinaryString(f);
            }
        },
        sizeChange(){
            
        },
        currentChange(){
            
        },
        reset(){
            this.searchData.curpage = 1
            this.searchData.page = 10
        },
        getGoodsInfo(goods_id){
            this.goods_id =goods_id
            this.dialogVisible = true
        },
        searchEnd(res){
           
            this.orderList = res.data
            // console.log("this.orderList",this.orderList)
            // 对需要倒计时的订单进行处理
            this.orderList.forEach(order=>{
                this.$set(order,"choose",false)
                if(order.order_list.order_state==0&&order.order_list.set_type=='在线支付'){
                    if(order.order_list.payment_time_over*1000>new Date().getTime()){  
                         
                
                        this.$set(order.order_list,"leftTime",order.order_list.payment_time_over*1000)
                        this.$set(order.order_list,"timerInter","")
                        this.$set(order.order_list,"h",0)
                        this.$set(order.order_list,"i",0)
                        this.$set(order.order_list,"s",0)
                        
                        this.$hnwTools.startCountDown(order.order_list)

                    }else{
                        this.$set(order.order_list,"s",0)
                        
                    }
                }else if(order.order_list.order_state==2){
                    // 如果是待确认收货状态

                    let rece = order.order_list.goods.some(goods=>{
                        return goods.send_num!=goods.goods_num
                    })
                    order.order_list.receiveGoods_type = rece?1:2
                 
                    

                    order.order_list.takeover_time_over =  order.order_list.delay_time*1000 + 60*60*24*15*1000
                    let obj = this.$hnwTools.reLeftTime(order.order_list.takeover_time_over)
                    
                    
                    order.order_list.leftTimeText = obj.day+"天"+obj.hour+"时"
                    
                }
                
                // 处理库存
                if(order.order_list.chain==3){
                    order.order_list.goods.forEach(async (goods)=>{
                        this.$hnwTools.formatGoodsKey(goods)
                    })
                }
            })
            this.total = Number(res.total)
            this.allChoose = false
        },
        exportOrder(){
            let str1 = this.$axios.defaults.baseURL.substr(0,this.$axios.defaults.baseURL.length-4)
            // console.log("str1",str1)
            let baseUrl =  str1 + "store/index.php?act=store_orders&op=orders_all" + "&"  
            baseUrl += `store_id=${this.$store.state.shop||1780}&buyer_id=${this.$store.state.store_id}&curpage=${this.searchData.curpage}&limit=${this.searchData.curpage}&page=${this.searchData.page}&`
            // console.log("baseUrl",baseUrl)
            let arr = this.orderList.filter(item=>{
                return item.choose
            })
            // console.log("arr",arr)
            arr = arr.map(item=>item.order_list.order_sn)
            if(arr.length==0){
                return this.$warMsg("请选择导出的订单")
            }
            let str = arr.join(",")
            // console.log("str",str)
            // console.log("baseUrl",baseUrl +  "mode=" + str)
            location.href = baseUrl +  "mode=" + str 
            // console.log("location.href",location.href)
        },
        orderInfo(id){
            this.$router.push("/my/orderInfo/"+id)
        },
        orderPay(id){
            console.log("id",id)
            this.$router.push("/shopcar/3_"+id+"/1")
        },
        async orderAff(order,goods){

            this.$affMsg("确定收货？",async ()=>{
                let obj= {}
                if(goods){
                    obj.id = goods.id
                    obj.send_num = goods.send_num
                }
                let res=  await this.$api.affOrder({
                    id:order.id,
                    order_sn:order.order_sn,
                    member_name:this.$store.state.member_name,
                    goods:[obj]
                })
                if(res){
                    this.$suMsg("确认成功")
                    this.$refs.ParaSearch.getList()
                }
            })
        },

        // barter(id){
        //      this.$router.push("/my/ReSales/"+id)
        // },
        barter(order,goods){
            // console.log("order",order);
            // console.log("order",);
            if(order.order_list.chain==3){
                // console.log("分仓订单");
                localStorage.setItem("hnw-reorder-data",JSON.stringify(goods))
                this.$router.push("/my/BranchReSales/"+order.order_list.id)
            }else{
                this.$router.push("/my/ReSales/"+order.order_list.id)
            }
            
        },

        regooods(goods){
            this.$affMsg("确定要退货？",async ()=>{
                // console.log(goods)
            })
        },
        orderCancel(order){

            this.$prompt('请输入取消原因', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            }).then(async ({ value }) => {
                let res = await this.$api.cancelOrder({
                    cancel_remarks:value,
                    id:order.id,
                    order_sn:order.order_sn
                })
                if(res){
                    this.$suMsg("取消成功")
                    this.$refs.ParaSearch.getList()
                }
            })
        },
        async again(order){
            // console.log("order",);
            let cart = []
           
            order.goods.forEach(goods=>{
                cart.push({
                    goods_id:goods.goods_id,
                    sccode:goods.sccode,
                    oecode:goods.oecode,
                    goods_name:goods.goods_name,
                    goods_price:goods.goods_price,
                    goods_storage:goods.goods_storage,
                    goods_num:goods.goods_num,
                    buyer_id:goods.buyer_id,
                    order_name:'',
                    member_id:order.member_id,
                    buyer_name:order.buyer_name,
                    store_id:goods.store_id,
                    store_name:goods.store_name,
                    cart_type:goods.type,
                    setlist_id:goods.setlist_id,
                    groupbuy_id:goods.groupbuy_id
                })
            })
            let res = await this.$api.joinCart(cart)
            if(res){
                this.$suMsg("已全部添加进购物车")
                this.$router.push("/shopcar/1/1")
            }else{
                this.$erMsg("加入购物车失败")
            }
        },
        // 删除订单
        orderDel(order_sn){
            this.$affMsg("确定删除该订单？",async ()=>{
                let res=  await this.$api.delOrder(order_sn)
                if(res){
                    this.$suMsg("删除成功")
                    this.$refs.ParaSearch.getList()
                }
            })
        },
        allChooseChange(flag){
            this.orderList.forEach(order=>{
                this.$set(order,"choose",flag)
            })
        }
    },
    async created(){
        // 传入结束的时间 用于计算时间 
        // this.$hnwTools.reLeftTime()
        if(this.$store.getters.notLvD){
            this.orderStateList.push(
                {
                    label:"联盟订单",
                    value:'lmth',
                    num:0,
                    sign:"lmth"
                }
            )
        }
        let nums = await this.$api.getOrderNums()
        // orderStateList
        
        this.orderStateList.forEach(item=>{
            item.num =  nums[item.sign]
        })
        
        // data:this.webVers
        let vers = localStorage.getItem("hnw-web-vers")
        this.webVers = JSON.parse(vers)
        
    },
    mounted(){
        this.$refs.ParaSearch.getList()
    },
    props:{
        
    }
}
</script>

<style lang="scss">
.my-order{
    padding: 10px;
    background: #fff;
    
    .order-state{
        padding: 5px 0;
        border-bottom: 1px solid #E1E1E1;
        display: flex;
        .order-state-item{
            color: #666;
            cursor: pointer;
            border-right: 1px solid #ccc;
            padding: 0 20px;
        }
        .order-state-item:first-child{
            padding-left: 0;
        }
        .order-state-item:last-child{
            border: none;
        }
        .active{
            color: #3E84D6;
        }
    }
    // 筛选跳过
    .sear_sreen{
    }

    .order-list{
        position: relative;
        .batch-handle{
            position: absolute;
            right: 10px;
            top: -38px;
        }
        .order-head{
            background: $backGray;
            margin-bottom: 10px;
            height: 38px;
            line-height: 38px;
            text-align: center;
            display: flex;
            .order-goods{
                width: 600px;
                display: flex;
                .goods-info{
                    flex: 1;
                    position: relative;
                    .chec-box{
                        position: absolute;
                        left: 10px;
                        top: 0;
                    }
                }
                .price{
                    width: 150px;
                }
                .num{
                    width: 80px;
                }
                .handle{
                    width: 180px;
                }
            }
            .order-handler{
                display: flex;
                flex: 1;
                .actual{
                    width: 120px;
                }
                .deal-state{
                    width: 120px;
                }
                .deal-handle{
                    flex: 1;
                }

            }
        }

        .order-body{
            .order-row{
                .order-msg{
                    background: $backGray;
                    display: flex;
                    padding: 10px;
                    position: relative;
                    >div{
                        margin-right: 10px;
                    }
                    img{
                        margin-left: 10px;
                    }
                    .del-btn{
                        cursor: pointer;
                        position: absolute;
                        right: 10px;
                        top: 10px;
                    }
                    .jdr{
                        padding-left: 10px;
                        box-sizing: border-box;
                        max-width: 330px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }

                .list-container{
                    display: flex;
                    text-align: center;
                     border: 1px solid#ccc;
                    .order-goods{
                        width: 600px;
                       
                        .row{
                            display: flex;
                            padding: 20px 0;
                            border-bottom: 1px solid #ccc;
                            .goods-info{
                                // 有图模式
                                flex: 1;
                                padding-left: 10px;
                                .img-info{
                                    display: flex;
                                    .img-box{
                                        width: 80px;
                                        height: 80px;
                                        display: flex;
                                        border: 1px solid #ccc;
                                        justify-content: center;
                                        align-items: center;
                                        .goods-img{
                                            width: 70px;
                                            height: 52px;
                                        }
                                    }
                                    .info{
                                        margin-left: 10px;
                                        text-align: left;
                                        flex: 1;
                                        max-width: 125px;
                                        >div{
                                            display: flex;
                                            margin-top: 10px;
                                            .name{
                                                white-space: nowrap;
                                                overflow: hidden;
                                                text-overflow: ellipsis;
                                                width: 97px;
                                            }
                                            .bag{
                                                width: 32px;
                                                height: 20px;
                                                line-height: 20px;
                                                text-align: center;
                                                background: #3E84D6;
                                                color: #fff;
                                                margin-left: 10px;
                                            }
                                        }
                                        .vehicle{
                                            color: #666666;
                                            max-width: 125px;
                                            white-space: nowrap;
                                            overflow: hidden;
                                            text-overflow: ellipsis;
                                        }
                                    }
                                    .info{
                                        >div:first-child{
                                            align-items: center;
                                        }
                                    }
                                }

                                // 无图模式
                                .no-img-info{
                                    display: flex;
                                    min-height: 82px;
                                    // align-items: center;
                                    padding-top: 10px;
                                    .type{
                                        width: 32px;
                                        height: 20px;
                                        line-height: 20px;
                                        margin-right: 8px;
                                        text-align: center;
                                        background: #3E84D6;
                                        color: #fff;
                                    }
                                    .info{
                                        flex: 1;
                                        // max-width: 125px;
                                        >div{
                                            display: flex;
                                            .name{
                                                font-weight: 700;
                                                margin-right: 10px;
                                                min-width: 60px;
                                                max-width: 60px;
                                                white-space: nowrap;
                                                overflow: hidden;
                                                text-overflow: ellipsis;
                                            }
                                        }
                                        .vehicle{
                                            color: #666;
                                            margin-top: 10px;
                                            max-width: 125px;
                                            white-space: nowrap;
                                            overflow: hidden;
                                            text-overflow: ellipsis;
                                        }
                                    }
                                }
                                .cur{
                                    cursor: pointer;
                                }
                            }
                            .price{
                                width: 150px;
                                padding-top: 10px;
                                
                                
                            }
                            .num{
                                width: 80px;
                                padding-top: 10px;
                                position: relative;
                                .goods-zbyh-tit{
                                        position: absolute;
                                        left: 50%;
                                        // top: -20px;
                                        bottom: 45px;
                                        width: 200px;
                                        transform: translateX(-50%);
                                        text-align: center;
                                        
                                        padding-left: 10px;
                                        // color: #999;
                                        color: red;
                                }
                            }
                            .handle{
                                padding-top: 10px;
                                // display: flex;
                                width: 180px;
                                >div{
                                     margin-top: 10px;
                                     cursor: pointer;
                                }
                                >div:first-child{
                                    margin: 0;
                                }
                            }
                            
                        }
                        .row:last-child{
                            border:none;
                        }
                    }
                    .order-handler{
                        flex: 1;
                        display: flex;
                        >div{
                            border-left: 1px solid#ccc;
                            >div{
                                height: 122px;
                            }
                        }
                        .actual{
                            width: 120px;
                            display: flex;
                            flex-direction: column;
                            .price{
                                flex: 1;
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                >div{
                                    margin-bottom: 10px;
                                }
                            }
                            .type_feight{
                                color: #666;
                                border-top: 1px solid #ccc;
                                padding-top: 10px;
                                box-sizing: border-box;
                                height: 40px;
                                >div{

                                }
                            }
                            .no-feight{
                                border-top: none;
                            }
                        }
                        .deal-state{
                            width: 120px;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            >div{
                                cursor: pointer;
                                padding-bottom: 10px;
                            }
                            >div:first-child{
                                color: #3E84D6;
                                cursor: default;
                            }
                        }
                        .deal-handle-box{
                            flex: 1;
                            .deal-handle{
                                padding-top: 20px;
                                box-sizing: border-box;
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                flex: 1;
                                >div{
                                    cursor: pointer;
                                    margin: 5px 0;
                                }
                                .left-time{
                                    cursor: default;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    img{
                                        margin-right: 5px;
                                    }
                                    span{

                                    }
                                    .red{
                                        color: red;
                                    }
                                }
                                .btn{
                                    cursor: pointer;
                                    width: 74px;
                                    height: 28px;
                                    text-align: center;
                                    line-height: 28px;
                                    // padding: 6px 22px;
                                    border:1px solid #FF2C2C;
                                    color:#FF2C2C;
                                    
                                }
                                .plain{
                                    border:1px solid #3E84D6;
                                    color:#3E84D6;
                                    
                                }
                            }
                        }
                        // 确认收货
                        .deal-handle-acquire{
                            flex: 1;
                            display: flex;
                            flex-direction: column;

                            >div{
                                flex: 1;
                                border-bottom: 1px solid #ccc;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                >.btn{
                                    cursor: pointer;
                                }
                            }
                            .one{
                                border-bottom: none;
                                max-height: 122px;
                            }
                            >div:last-child{
                                border-bottom: none;
                            }
                        }
                    }
                }
            }
        }
    }
    .pagin-box{
        padding: 10px 0;
        text-align: right;
    }
}
</style>