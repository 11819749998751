<template>
  <div class="store-busin">
    <!-- <div class="businessLogin">登录</div> -->
    <template v-if="$store.state.for_branch">
      <div style="background-color: #3e84d6">
        <div class="firm-head">
          <div class="head-left">
            <div class="logo1">
              <img src="../../assets/store/storelogo.png" alt="" />
            </div>
            <div class="vertical"></div>
            <div class="logo">
              <img :src="$store.state.shopInfo.store_all_banner_url" alt="" />
            </div>
            <div class="news">
              <div class="news-name">
                <img src="../../assets/store/house.png" alt="" />
                {{ $store.state.shopInfo.shop_name }}
              </div>
              <template>
                <div class="store-phone">
                  <img
                    src="../../assets/store/phone .png"
                    alt=""
                    style="vertical-align: middle"
                  />
                  {{ $store.state.shopInfo.shop_contactphone }}
                </div>
              </template>
            </div>
          </div>

          <div class="branch-right">
            <div class="search-box">
              <el-autocomplete
                class="inline-input"
                v-model="storeText"
                :fetch-suggestions="querySearch"
                placeholder="请输入OE号、商品名称、生产编码"
                @select="handleSelect"
                @clear="searchStore"
                clearable
                :hide-loading="true"
                @keyup.enter.native="searchStore"
                value-key="record_name"
              ></el-autocomplete>
              <el-button type="primary" @click="searchStore">搜本店</el-button>
            </div>
            <div class="shopcart-btns">
              <div
                class="shopCart"
                v-if="
                  $store.state.shop != 1974 &&
                  $store.state.shop != 1871 &&
                  $store.state.shop != 1948
                "
              >
                <div @click="toCart(2)" class="cart-adv-btn">
                  <div class="aog" v-if="$store.state.advCart">
                    <div class="aog-text">到货</div>
                    <div class="btn-text">预购单</div>
                  </div>
                  <div v-else>预购单</div>
                </div>
              </div>

              <div class="shopCart">
                <div @click="toCart(1)">
                  <el-button class="cart-btn">
                    <img src="../../assets/index/购物车.png" alt="" />
                    <el-badge
                      :value="cartNums"
                      class="num"
                      type="primary"
                      :max="99"
                      :hidden="!cartNums"
                    >
                    </el-badge>
                    <span class="btn-text"> 我的购物车 </span>
                  </el-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="kfpt-right-fixed-nav" v-if="$store.state.isWebKfpt">
        <div class="nav-list">
          <div class="nav-item" @click="toCart(2)">
            <img src="../../assets/store/kfpt/clock.png" alt="" srcset="" />
            <div class="text">预购单</div>
          </div>
          <div class="nav-item" @click="toCart(1)">
            <img src="../../assets/store/kfpt/car.png" alt="" srcset="" />
            <div class="text">购物车</div>
          </div>
          <div class="nav-item">
            <img src="../../assets/store/kfpt/services.png" alt="" srcset="" />
            <div class="text">客服中心</div>
          </div>
        </div>

        <div class="nav-item top" @click="toTop">
          <!-- <img src="../../assets/store/kfpt/up.png" alt="" srcset=""> -->
          <img src="../../assets/store/kfpt/top.png" alt="" srcset="" />
          <!-- <i class="el-icon-arrow-up"></i>
            <div class="text">
              Top
            </div> -->
        </div>
      </div>
      <div class="store-head-box">
        <div class="store-head">
          <div class="store-head-left">
            <div class="store-logo">
              <img
                v-if="!$store.state.isWebKfpt"
                :src="$store.state.shopInfo.store_all_banner_url"
                alt=""
              />
              <!-- <img :src="$store.state.shopInfo.store_label_url" alt=""> -->

              <!-- <img class="text-logo" v-if="$store.state.shop==2018" src="../../assets/store/ybjlogotext.png" alt="" srcset=""> -->
              <img
                class="text-logo"
                v-if="shopId == 2018"
                src="../../assets/store/ybjlogotext.png"
                alt=""
                srcset=""
              />
              <img
                class="text-logo"
                v-else-if="shopId == 1974"
                src="../../assets/store/xyqpzylogotext.png"
                alt=""
                srcset=""
              />
              <img
                class="text-logo"
                v-else-if="shopId == 1871"
                src="../../assets/store/thqpzyd.png"
                alt=""
                srcset=""
              />
              <img
                class="text-logo"
                v-else-if="shopId == 2097"
                src="../../assets/store/shxsfqp.png"
                alt=""
                srcset=""
              />
              <!-- <img
                class="text-logo"
                v-else-if="shopId == 1876"
                src="../../assets/store/hqqpzyd.png"
                alt=""
                srcset=""
              /> -->
              <img
                class="text-logo"
                v-else-if="shopId == 1948"
                src="../../assets/store/cszcqp.png"
                alt=""
                srcset=""
              />
              <!-- <img class="text-logo-img" v-else-if="$store.state.isWebKfpt" src="../../assets/store/kfptlogotext.png" alt="" srcset="">  -->
              <img
                class="text-logo-img"
                v-else-if="$store.state.isWebKfpt"
                :src="require('../../assets/store/122(1).svg')"
                alt=""
                srcset=""
              />
            </div>
            <!-- <div class="store-name" v-if="">{{$store.state.shopInfo.shop_name}}昕豫汽配自营店</div> -->
          </div>
          <div class="store-head-right">
            <div
              class="announcement"
              v-if="moduType == 1 && $store.state.isWebKfpt"
            >
              <i class="el-icon-message-solid"></i>公告:
              <div class="ann-text">新品发布 低价抢购</div>
            </div>

            <div
              class="search-box"
              v-if="!$store.state.isWebKfpt || moduType != 1"
            >
              <el-autocomplete
                class="inline-input"
                v-model="storeText"
                :fetch-suggestions="querySearch"
                placeholder="请输入OE号、商品名称、生产编码"
                @select="handleSelect"
                @clear="searchStore"
                clearable
                :hide-loading="true"
                @keyup.enter.native="searchStore"
                value-key="record_name"
              ></el-autocomplete>
              <el-button type="primary" @click="searchStore">搜本店</el-button>
            </div>

            <div class="shopcart-btns" v-if="!$store.state.isWebKfpt">
              <div
                class="shopCart"
                v-if="
                  $store.state.shop != 1974 &&
                  $store.state.shop != 1871 &&
                  $store.state.shop != 1948
                "
              >
                <div @click="toCart(2)" class="cart-adv-btn">
                  <div class="aog" v-if="$store.state.advCart">
                    <div class="aog-text">到货</div>
                    <div class="btn-text">预购单</div>
                  </div>
                  <div v-else>预购单</div>
                </div>
              </div>

              <div class="shopCart">
                <div @click="toCart(1)">
                  <el-button class="cart-btn">
                    <img src="../../assets/index/购物车.png" alt="" />
                    <el-badge
                      :value="cartNums"
                      class="num"
                      type="primary"
                      :max="99"
                      :hidden="!cartNums"
                    >
                    </el-badge>
                    <span class="btn-text"> 我的购物车 </span>
                  </el-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>

    <div class="store-tab" v-if="moduType != 4">
      <div class="branch-title">
        <template v-if="$store.state.for_branch">
          <div class="tab-title">
            <div
              class="title-recommend"
              @click="tabModu(1)"
              :class="{ active: moduType == 1 }"
            >
              首页
            </div>
            <div
              class="title-all"
              @click="tabModu(2)"
              :class="{ active: moduType == 2 }"
            >
              所有配件
            </div>
            <div
              class="title-all"
              @click="tabModu(8)"
              :class="{ active: moduType == 8 }"
            >
              清库存
            </div>
            <div
              class="title-all"
              @click="tabModu(9)"
              :class="{ active: moduType == 9 }"
            >
              热销商品
            </div>
            <div
              class="title-all"
              @click="tabModu(10)"
              :class="{ active: moduType == 10 }"
            >
              商品推荐
            </div>
            <div
              v-if="chain != 3"
              class="title-introduce"
              @click="tabModu(3)"
              :class="{ active: moduType == 3 }"
            >
              店铺介绍
            </div>
          </div>
        </template>
        <template v-else>
          <div class="tab-title">
            <div
              class="title-recommend"
              @click="tabModu(1)"
              :class="{ active1: moduType == 1 }"
            >
              首页
            </div>
            <div
              class="title-all"
              @click="tabModu(2)"
              :class="{ active1: moduType == 2 }"
            >
              配件
            </div>
            <!-- &&$store.state.shop != 1876 -->
            <div
              class="title-all"
              v-if="
                $store.state.shop &&
                $store.state.shop != 1780 
              "
              @click="tabModu(15)"
              :class="{ active1: moduType == 15 }"
            >
              品牌中心
            </div>
            <template v-if="$store.state.isWebKfpt">
              <div
                class="title-all"
                :class="{ active: moduType == 12 }"
                @click="tabModu(12)"
              >
                库存
              </div>
              <div
                class="title-all"
                :class="{ active: moduType == 13 }"
                @click="tabModu(13)"
              >
                预售
              </div>
              <div
                class="title-all"
                :class="{ active: moduType == 14 }"
                @click="tabModu(14)"
              >
                加盟
              </div>
            </template>
            <div
              class="title-all"
              @click="tabModu(5)"
              :class="{ active1: moduType == 5 }"
              v-if="$store.state.shop != 2136"
            >
              积分商城
            </div>
          </div>
        </template>
      </div>

      <!-- <div class="tab-title" v-else>
        <div class="title-recommend" @click="tabModu(1)" :class="{active:moduType==1}">
          <img src="../../assets/store/icon/home1.png" alt="" v-if="moduType==1">
          <img src="../../assets/store/icon/home.png" alt="" v-else>
          首页
        </div>
        <div class="title-all" @click="tabModu(2)" :class="{active:moduType==2}">
          <img src="../../assets/store/icon/car1.png" alt="" v-if="moduType==2">
          <img src="../../assets/store/icon/car.png" alt="" v-else>
          所有配件
        </div>
        <div class="title-introduce" @click="tabModu(3)" :class="{active:moduType==3}">
          <img src="../../assets/store/icon/stor1.png" alt="" v-if="moduType==3">
          <img src="../../assets/store/icon/stor.png" alt="" v-else>
          店铺介绍
        </div>
        <div class="title-introduce" @click="tabModu(5)" :class="{active:moduType==5}">
          <img src="../../assets/store/icon/stor1.png" alt="" v-if="moduType==5">
          <img src="../../assets/store/icon/stor.png" alt="" v-else>
          积分商城
        </div>
      </div> -->
    </div>
    <template v-if="!$store.state.for_branch">
      <div class="banner" v-if="moduType == 1 || moduType == 3">
        <!-- <img src="../../assets/buissess/6376f61b60658dc072bc1e235dcd5dc.png" alt="" v-if="moduType==1"> -->

        <!-- <div class="img-banner" v-if="moduType==1" :style="'background-image:url('+$store.state.shopInfo.store_recom_banner_url+');background-size:100% 430px;background-repeat:no-repeat;'" ></div> -->
        <img
          :src="$store.state.shopInfo.store_recom_banner_url"
          alt=""
          v-if="moduType == 1"
        />
        <img :src="$store.state.shopInfo.store_banner_url" alt="" v-else />
        <div class="input-box" v-if="$store.state.isWebKfpt">
          <div class="search-text-input">
            <div class="search-types">
              <div
                class="search-type-item"
                :class="{ 'type-acti': seleSearch == 1 }"
                @click="seleStpye(1)"
              >
                配件
              </div>
              <div
                class="search-type-item"
                :class="{ 'type-acti': seleSearch == 2 }"
                @click="seleStpye(2)"
              >
                碰撞件
              </div>
            </div>
            <div class="search-text-content">
              <el-input
                type="textarea"
                :rows="9"
                placeholder="请输入内容"
                v-model="storeText"
              >
              </el-input>
            </div>
            <div class="btn-box">
              <div class="btn search" @click="searchStore">查询</div>
              <div class="btn" @click="storeText = ''">清空</div>
            </div>
            <div class="ex-point">
              <div class="po-title">热门车型</div>
              <div class="po-list-box">
                <div class="po-list">奔驰 奥迪 宝马 路虎 捷豹</div>
                <div class="po-more">更多>></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>

    <template v-if="moduType == 1">
      <StoreBranchGoodsHome
        v-if="chain == 3"
        :chain="chain"
        @showInfo="moduType = 4"
      ></StoreBranchGoodsHome>
      <StoreGoodsHome
        v-else
        :chain="chain"
        @showInfo="moduType = 4"
      ></StoreGoodsHome>
    </template>

    <PartsXY
      ref="Parts"
      :chain="chain"
      v-else-if="moduType == 2 && (shopId == 1974 || shopId == 1871)"
      @showInfo="moduType = 4"
      @changeNum="changeNum"
    ></PartsXY>
    <Parts
      ref="Parts"
      :chain="chain"
      v-else-if="moduType == 2 && shopId != 1974 && shopId != 1871"
      @showInfo="moduType = 4"
      @changeNum="changeNum"
    ></Parts>

    <ShopBrand
      ref="ShopBrand"
      :chain="chain"
      v-else-if="moduType == 15 && shopId == 2136"
      @showInfo="moduType = 4"
      @changeNum="changeNum"
    ></ShopBrand>
    <ShopBrandXY
      ref="ShopBrand"
      :chain="chain"
      v-else-if="moduType == 15 && (shopId == 1974 || shopId == 1871)"
      @showInfo="moduType = 4"
      @changeNum="changeNum"
    ></ShopBrandXY>
    <ShopBrand
      ref="ShopBrand"
      :chain="chain"
      v-else-if="moduType == 15 && shopId != 1974 && shopId != 1871"
      @showInfo="moduType = 4"
      @changeNum="changeNum"
    ></ShopBrand>

    <StoreIntr :chain="chain" v-else-if="moduType == 3"></StoreIntr>
    <div v-if="moduType == 4">
      <GoodsInfo :chain="chain" @changeNum="changeNum"></GoodsInfo>
    </div>
    <div v-if="moduType == 5">
      <StoreInte :chain="chain" @showInteInfo="moduType = 6"></StoreInte>
    </div>

    <div v-if="moduType == 6">
      <storeIntrInfo
        :chain="chain"
        @tosub="moduType = 7"
        @changeNum="changeNum"
      ></storeIntrInfo>
    </div>
    <div v-if="moduType == 7">
      <storeSubOrder :chain="chain" @changeNum="changeNum"></storeSubOrder>
    </div>

    <div v-if="moduType == 8">
      <BranchClearGoods
        :chain="chain"
        @changeNum="changeNum"
        @showInfo="moduType = 4"
      ></BranchClearGoods>
    </div>
    <div v-if="moduType == 9">
      <BranchHotGoods
        :chain="chain"
        @changeNum="changeNum"
        @showInfo="moduType = 4"
      ></BranchHotGoods>
    </div>
    <div v-if="moduType == 10">
      <BranchRemGoods
        :chain="chain"
        @changeNum="changeNum"
        @showInfo="moduType = 4"
      ></BranchRemGoods>
    </div>
    <div v-if="moduType == 13">
      <OpenGather
        :chain="chain"
        @changeNum="changeNum"
        @showInfo="moduType = 4"
        @searchText="openSearchText"
      ></OpenGather>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import StoreGoodsHome from "./component/StoreGoodsHome.vue";
import StoreBranchGoodsHome from "./component/StoreBranchGoodsHome.vue";
import StoreIntr from "./component/StoreIntr.vue";
import StoreInte from "./component/StoreIntegral";

import storeIntrInfo from "./component/storeIntrInfo.vue";
import storeSubOrder from "./component/storeSubOrder.vue";

import BranchClearGoods from "./component/BranchClearGoods.vue";
import BranchHotGoods from "./component/BranchHotGoods.vue";
import BranchRemGoods from "./component/BranchRemGoods.vue";

import OpenGather from "./component/OpenGather.vue";
import ShopBrand from "../../components/ShopBrand.vue";

export default {
  data() {
    return {
      log_id: "",
      favorite: false,
      moduType: "1",
      title: "1",
      shopInfo: {},
      currentPage: 1,
      pageSize: 15,
      totalParts: 0, //搜索配件数
      total: 0,
      storeText: "",
      contentIndex: 1,
      shopId: "",
      chain: "",
      seleSearch: 1,
    };
  },
  methods: {
    changeNum(num) {
      this.moduType = num;
      if (num == 2) {
        this.$nextTick(() => {
          this.$refs.Parts.setParams(this.storeText);
          this.$store.commit("UpDateState", { batchs: [] });
        });
      } 
    },
    seleStpye(type) {
      this.seleSearch = type;
    },
    openSearchText(text) {
      this.storeText = text;
      this.changeNum(2);
    },
    Submit(key) {
      this.contentIndex = key;
      // 获取点击的按钮对应页面的id
      let PageId = document.querySelector("#page" + key);
      // 打印出对应页面与窗口的距离

      // 使用平滑属性，滑动到上方获取的距离
      // 下方我只设置了top，当然 你也可以加上 left 让他横向滑动
      // widow 根据浏览器滚动条，如果你是要在某个盒子里面产生滑动，记得修改
      window.scrollTo({
        top: PageId.offsetTop,
        behavior: "smooth",
      });
    },

    searchStore() { 
      if(!this.$store.state.token){
					this.$warMsg("请先去登录")
					return this.$router.push("/login")
				}
      if (
        this.$store.state.shop != this.shopId &&
        this.$store.state.store_id != this.shopId &&
        this.$store.state.for_branch != this.shopId
      ) {
        return this.$warMsg("暂无权限");
      }

      this.moduType = 2;
      this.$nextTick(() => {
        this.$refs.Parts.setParams(this.storeText);
        this.$store.commit("UpDateState", { batchs: [] });
      }); 
    },

    async querySearch(str, cb) {
      if (!this.searchText) {
        // ...
        let res = await this.$api.getSearchRecord({
          record_type: 3,
        });
        cb(res);
      } else {
        cb([]);
      }
    },
    tabModu(n) {
      if(!this.$store.state.token){
					this.$warMsg("请先去登录")
					return this.$router.push("/login")
				}
      if (n != 1) {
        if (!this.$store.state.member_id) {
          return this.$router.push("/login");
        }
        if (
          this.$store.state.shop != this.shopId &&
          this.$store.state.store_id != this.shopId &&
          this.$store.state.for_branch != this.shopId
        ) {
          return this.$warMsg("暂无权限");
        }
      }
      this.moduType = n;
      if (n == 2) {
        this.$nextTick(() => {
          this.$refs.Parts.setParams(this.storeText);
          this.$store.commit("UpDateState", { batchs: [] });
        });
      } 
    },
    handleSelect(item) { 
    },

    handleSizeChange(val) { 
    },
    handleCurrentChange(val) {},
    toCart(type) {
      if(!this.$store.state.token){
					this.$warMsg("请先去登录")
					return this.$router.push("/login")
				}
      if (!this.$isCompany()) {
        return;
      }
      this.$router.push("/shopcar/1/" + type);
    },

    toMymodule(path) {
      if (!this.$isCompany()) {
        return;
      }
      this.$router.push(path);
    },
    loginOut() {
      this.$router.push("/login");
      localStorage.removeItem("hnw-web-state");
      this.$store.commit("resetState");
    },
    toTop() {
      document.getElementById("hnw-header").scrollIntoView();
    },
  },
  computed: {
    ...mapState(["cartNums", "for_branch"]),
    favoriteText() {
      return this.favorite ? "已收藏" : "收藏";
    },
  },

  async created() {
    this.shopId = this.$route.params.id;

    // 判断是否是开放平台
    if (
      this.$store.state.shop == 2136 ||
      this.$store.state.shop == 2117 ||
      window.location.origin == "https://open.huanuowei.cn"
    ) {
      // 这是开放平台
      this.$store.commit("UpDateState", {
        isWebKfpt: true,
      });
    } else {
      this.$store.commit("UpDateState", {
        isWebKfpt: false,
      });
    }
 

    if (this.$store.state.token) {
      let resAcc = await this.$api.getAccMsg({
        member_id: this.$store.state.member_id,
        type: this.$store.state.role_name == "all" ? 1 : 2,
      });
      if(resAcc.store_info){
        this.$store.commit("UpDateState", { 
          is_limit: resAcc.store_info.is_limit,
          is_int_show: resAcc.store_info.is_int_show
        });
      } 
      // 获取企业类型
      let com = await this.$api.getCompanyInfo({ 
        store_id: this.$store.state.store_id,
        member_id: this.$store.state.member_id,
      });
      this.$store.commit("UpDateState", {
        nickname: resAcc.nickname,
        store_id: com.store_id,
        sc_id: com.sc_id,
        sc_name: com.sc_name,
      }); 
    }

    if (this.$store.state.shop == 1974) {
      document.title = "上海昕豫汽配自营店";
    } else if (this.$store.state.shop == 1905) {
      document.title = "成都华诺威汽配";
    } else if (this.$store.state.shop == 1780) {
      document.title = "华诺威_汽配供应链专家";
    } else if (this.$store.state.shop == 1871) {
      document.title = "上海挺海汽配自营店";
    } else if (this.$store.state.shop == 1870) {
      document.title = "济南祥泰";
    } else if (this.$store.state.shop == 2018) {
      document.title = "优百佳汽配自营店";
    } else if (this.$store.state.shop == 1881) {
      document.title = "楚佑汽配";
    } else if (this.$store.state.shop == 1975) {
      document.title = "中润丰汽配";
    } else if (this.$store.state.shop == 2097) {
      document.title = "上海鑫崇芳汽配自营店";
    } else if (this.$store.state.shop == 2136) {
      document.title = "开放店铺";
    } else if (this.$store.state.shop == 2029) {
      document.title = "景阳城汽配";
    } else if (this.$store.state.shop == 1855) {
      document.title = "西铁汽配";
    } else if (this.$store.state.shop == 1880) {
      document.title = "北京鸿宇";
    } else if (this.$store.state.shop == 1948) {
      document.title = "长沙中驰汽配自营店";
    } else {
      document.title = "华诺威_汽配供应链专家";
    }
    // else if (this.$store.state.shop == 1876) {
    //   document.title = "航黔汽配自营店";
    // } 
    if (!this.shopId) { 
      return this.$router.push("/login");
    }
    if (this.$store.state.for_branch) {
      // 直营
      this.chain = 3;
    }
    this.$store.commit("UpDateState", { shop: this.shopId });
  },
  props: {},
  components: {
    StoreGoodsHome,
    StoreBranchGoodsHome,
    StoreIntr,
    StoreInte,
    storeIntrInfo,
    storeSubOrder,
    BranchClearGoods,
    BranchHotGoods,
    BranchRemGoods,
    OpenGather,
    ShopBrand,
  },
  watch: {
    "$store.state.batchs": {
      deep: true,
      immediate: true,
      handler(n) {
        if (n.length > 0) {
          this.moduType = 2;
          this.$nextTick(() => {
            this.$refs.Parts.searches();
          });
        }
      },
    },
  },
};
</script>

<style lang="scss" >
@font-face {
  font-family: HZGBYS;
  src: url(../../styles/汉真广标艺术字体.ttf);
}
.store-busin {
  background-color: #f7f7f7;
  .businessLogin {
    width: 100px;
    height: 50px;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 100;
  }
  .shopcart-btns {
    display: flex;
    width: 275px;

    justify-content: flex-end;
    .shopCart {
      position: relative;

      .cart-btn {
        width: 128px;
        height: 42px;
        background: #eeeeee;
        border-radius: 4px;
        border: none;

        display: flex;
        align-items: center;
        box-sizing: border-box;
        padding-left: 13px;

        color: #3e84d6;
        img {
        }
        .btn-text {
          font-size: 14px;
          margin-left: 10px;
          position: relative;
          top: -2px;
        }
      }

      .num {
        position: absolute;
        top: 4px;
        left: 24px;
      }
    }
  }
  .search-box {
    display: flex;
    align-items: center;
    .el-input {
      width: 420px;
      height: 40px;
      .el-input__inner {
        height: 100%;
        border-radius: 6px 0 0 6px;
      }
    }
  }
  .kfpt-right-fixed-nav {
    // background: #EB3232;
    background: #488fec;
    height: 100%;
    position: fixed;
    right: 0;
    top: 0;
    width: 34px;
    z-index: 100;
    // padding-top: 150px;
    padding-top: 120px;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    .nav-list {
      .nav-item {
        margin-bottom: 40px;
      }
    }
    .nav-item {
      cursor: pointer;
      position: relative;
      text-align: center;
      img {
        width: 14px;
        margin-bottom: 5px;
        // position: absolute;
        // top: 0;
        // left: 50%;
        // transform: translateX(-50%);
      }
      .text {
        color: #fff;
        font-size: 14px;
        text-align: center;
        width: 20px;
        // margin: 130px 0 0 5px;
        // padding: 25px 0 0 2px;
      }
    }
    .top {
      position: absolute;
      bottom: 45px;
      img {
        width: 19px;
      }
      i {
        font-size: 16px;
      }
    }
  }
  .store-head-box {
    background: #fff;

    .store-head {
      width: 1200px;
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 1200px;
      margin: 0 auto;
      .store-head-left {
        display: flex;
        .store-logo {
          // width: 280px;
          height: 70px;
          overflow: hidden;
          display: flex;
          align-items: center;
          img {
            height: 40px;
          }
          .text-logo {
            height: 29px;
            margin-left: 10px;
          }
          .text-logo-img {
            width: 350px;
            height: auto;
          }
          .text-log-kfpt {
            height: 40px;
          }
        }
        .store-name {
          font-size: 26px;
          margin-left: 10px;
          line-height: 40px;
          font-family: HZGBYS;
          color: #2a6dbb;
        }
      }
      .store-head-right {
        display: flex;
        align-items: center;
        .announcement {
          display: flex;
          align-items: center;
          border: 1px solid #f2f2f2;
          border-radius: 15px;
          float: left;
          height: 28px;
          line-height: 28px;
          padding: 0 13px;
          .el-icon-message-solid {
            margin-right: 5px;
            color: red;
          }
        }
      }
    }
  }
  .firm-head {
    width: 1200px;
    margin: 0 auto;
    height: 100px;
    display: flex;
    justify-content: space-between;
    background-color: #3e84d6;

    .head-left {
      // float: flex;
      // width: 600px;
      height: 100px;
      // background-color: skyblue;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .logo1 {
        width: 78px;
        // height: 37px;
        // margin-right: 30px;
        > img {
          width: 100%;
          height: 100%;
        }
      }
      .vertical {
        width: 2px;
        height: 30px;
        background-color: #ffffff;
        margin: 0 19px 0 15px;
      }
      .logo {
        width: 40px;
        height: 40px;
        margin-right: 26px;
        > img {
          width: 100%;
          height: 100%;
        }
      }
      .news {
        .news-name {
          font-weight: 800;
          font-size: 18px;
          padding-bottom: 10px;
          color: #fff;
          > img {
            width: 16px;
            position: relative;
            top: 2px;
          }
        }
        .xiaoshou {
          max-width: 60px;
          cursor: pointer;
        }
        > span {
          display: inline-block;
          height: 16px;
          margin-right: 10px;
        }
        .store-phone {
          color: #fff;
          background: #508fd8;
          display: flex;
          align-items: center;
          padding: 5px 5px 2px;
          img {
            width: 14px;
            margin-right: 3px;
          }
        }
        .store-msg {
          color: #fff;
          font-size: 14px;
          img {
            width: 15x;
            height: 16px;
          }
        }
      }
    }
    .branch-right {
      display: flex;
      align-items: center;
    }
    .head-right {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-right: 20px;
      .el-input {
        width: 336px;
        height: 36px;
        .el-input__inner {
          height: 100%;
          border-radius: 6px 0 0 6px;
        }
      }

      .el-button {
        width: 64px;
        height: 36px;

        padding: 8px 10px;
        border-radius: 0 6px 6px 0;
      }
      .el-button + .el-button {
        margin-left: 0px;
      }

      .el-menu {
        background: transparent;

        .shu {
          float: left;
          margin-top: 7px;
          background: #999999;
        }
        .el-submenu {
          .el-submenu__title {
            padding: 0 !important;
            font-size: 12px;
            color: #333;
            line-height: 38px;
            height: 38px;
            flex-shrink: 0;
            background: transparent;
            border-bottom: none;
            &:hover {
              background: transparent;
            }
            i {
              margin-left: 0;
            }
          }
        }
        .is-active {
          .el-submenu__title {
            border-bottom: none;
            padding: 0 !important;
            font-size: 12px;
          }
        }

        .el-menu-item {
          height: 30px;
          line-height: 30px;
          font-size: 12px;
          color: #333;
          padding: 0;
          border: none;
          flex-shrink: 0;
          background: transparent;
          text-align: center;
          > img {
            width: 16px;
            height: 16px;
          }
          &:hover {
            background: transparent;
          }
        }
      }
      .el-menu--horizontal > .el-submenu .el-submenu__icon-arrow {
        display: none;
      }

      .el-menu--horizontal {
        border-bottom: none;
      }

      .portrait-img {
        width: 34px;
        height: 34px;
        border: 2px solid #fff;
        border-radius: 34px;
        margin: 0 20px;
        background-color: #fff;
        img {
          width: 100%;
          height: 100%;
          border-radius: 34px;
          vertical-align: baseline;
        }
      }
      // 预购单 公用样式
      .cart-adv-btn {
        width: 100px;
        height: 42px;
        background: #eeeeee;
        border-radius: 4px;
        justify-content: space-evenly;
        display: flex;
        align-items: center;
        margin: 0 20px;
        cursor: pointer;
        color: #858585;
      }
      // 到货
      .aog {
        display: flex;
        align-items: center;
        color: red;
        font-size: 12px;
        .aog-text {
          animation: smallToBig 0.8s infinite linear alternate;
          width: 32px;
          height: 20px;
          line-height: 20px;
          text-align: center;
          background: #ff0000;
          border-radius: 4px;
          color: #fff;
          margin-right: 10px;
        }
        .btn-text {
          font-size: 14px;
        }
      }
      @keyframes smallToBig {
        0% {
          transform: scale(0.8) translateZ(0);
        }
        100% {
          transform: scale(1.5) translateZ(0);
        }
      }
      .buShopCart {
        position: relative;
        // margin-left: 26px;
        .cart-btn {
          height: 26px;
          width: 26px;
          padding: 0;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          // padding-left: 13px;
          border-radius: 0;
          background-color: #3e84d6;
          border: none;
          span {
            width: 26px;
            height: 26px;
            > img {
              width: 26px;
              height: 26px;
            }
          }
        }
        .btn-text {
          font-size: 14px;
          margin-left: 10px;
          position: relative;
          top: -2px;
        }
        .num {
          position: absolute;
          top: -4px;
          left: 20px;
        }
      }
    }
  }
  .store-tab {
    height: 32px;
    box-shadow: 0px 0px 6px 0px rgba(41, 49, 56, 0.19);
    background-color: #f7f7f7;
    .tab-title {
      width: 1200px;
      margin: 0 auto;
      color: #999;
      line-height: 32px;
      height: 32px;
      display: flex;

      border-top: 2px solid #eee;
      .active {
        // border-bottom: 2px solid #3E84D6;
        // color: #3E84D6;
        color: #0e1ccb;
        font-weight: 700;
        background: #ffff;
      }

      > div {
        color: #3171bb;
        cursor: pointer;
        padding: 0 10px;
        img {
          width: 14px;
        }
      }
    }
    .branch-title {
      width: 100%;
      background: #2a6dbb;
      .tab-title {
        border: none;

        > div {
          color: #fff;
          padding: 0 25px;
        }
        .active {
          color: #3e84d6;
        }
        .active1 {
          background: #215da2;
        }
      }
    }
  }
  .banner {
    position: relative;
    max-height: 460px;
    overflow: hidden;
    // width: 1920px;
    // width: 100%;
    // margin: 0 auto;
    // .img-banner{
    //   width: 100%;
    //   height: 430px;
    //   position: absolute;
    //   left: 0;
    //   background: no-repeat center;
    // }
    img {
      // width: 100%;
      // height: 430px;
      // margin: 0 auto;

      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;

      // width: 1903px;
    }
    .input-box {
      width: 1200px;
      margin: 0 auto;
      // height: 100%;
      max-height: 460px;
      position: absolute;
      top: 0%;
      left: 50%;
      transform: translateX(-50%);
      .search-text-input {
        width: 300px;
        height: 430px;
        display: flex;
        flex-direction: column;
        position: absolute;
        z-index: 2;
        background: #fff;
        .search-types {
          color: #666;
          display: flex;
          .search-type-item {
            color: #666666;
            flex: 1;
            background: #e9ebf1;
            cursor: pointer;
            height: 38px;
            line-height: 38px;
            text-align: center;
            // width: 50%;
          }
          .type-acti {
            background: #fff;
            color: #2a6dbb;
          }
        }
        .search-text-content {
          padding: 0 20px;
          // flex: 1;
          display: flex;
          align-items: center;
          // margin:10px 0;
          .el-textarea {
          }
        }
        .btn-box {
          padding: 5px 20px;
          display: flex;
          justify-content: space-between;
          .btn {
            background-color: #fff;
            border: 1px solid #e1e1e1;
            cursor: pointer;
            height: 36px;
            text-align: center;
            line-height: 36px;
            width: 124px;
            color: #999999;
          }
          .search {
            background: #2a6dbb;
            color: #fff;
          }
        }
        .ex-point {
          margin-top: 10px;
          padding: 0 20px;
          .po-title {
            color: #999;
            font-size: 12px;
            padding-bottom: 5px;
          }
          .po-list-box {
            display: flex;
            justify-content: space-between;
            padding-bottom: 20px;
            .po-list {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              width: 210px;
              color: #666;
            }
            .po-more {
              color: #999;
              font-size: 12px;
            }
          }
        }
      }
    }
  }
  .w {
    width: 1200px;
    margin: 0 auto;
    // box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.13);
    background: #fff;
    .content {
      margin-top: 10px;

      // 推荐商品
      .content-recommend {
        display: flex;
        flex-wrap: wrap;
        // justify-content: space-between;
        .recommend-msg {
          cursor: pointer;
          width: 290px;
          height: 280px;
          background-color: #fff;
          margin: 0px 13px 10px 0;
          position: relative;
          box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
          img {
            width: 210px;
            height: 210px;
            padding: 5px 40px;
          }
          p {
            padding: 0 20px 14px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .price {
            padding: 0 0 0 20px;
          }
          .el-button {
            position: absolute;
            bottom: 10px;
            right: 20px;
            background-color: #3E84D6;
            color: #fff;
          }
        }
        .recommend-msg:nth-child(4n) {
          margin-right: 0;
        }
        .paging1 {
          width: 1200px;
          .el-pagination {
            text-align: center;
            padding-bottom: 20px;
          }
        }
      }

      // 所有配件

      // 店铺介绍
      .content-introduce {
        display: flex;
        .content-l {
          width: 300px;
          height: 498px;
          border-radius: 5px;
          background-color: #fff;
          overflow: hidden;
          box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.13);
          .content-l-title {
            height: 40px;
            background-color: #eee;
            font-size: 14px;
            line-height: 40px;
            padding-left: 18px;
          }
          .title-list {
            height: 200px;
            border-radius: 5px;
            // background-color: #fff;
            padding-top: 10px;
            > div {
              line-height: 36px;
              padding-left: 20px;
              color: #999;
              cursor: pointer;
            }
            .active {
              color: #4ea3f7;
            }
          }
        }
        .content-r {
          margin-left: 10px;
          flex: 1;
          height: 500px;
          background-color: #f7f7f7;
          .content-up {
            height: 200px;
            // border: 1px solid #999;
            box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.13);
            // background-color: #f00;
            .content-top {
              height: 40px;
              background-color: #eee;
              font-size: 14px;
              line-height: 40px;
              padding-left: 18px;
            }
            .content-bottom {
              height: 140px;
              line-height: 18px;
              padding: 10px 20px;
              background-color: #fff;
              color: #666;
            }
          }
          .content-centre {
            margin-top: 10px;
            height: 130px;
            // border: 1px solid #999;
            box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.13);
            // background-color: #f00;
            .content-relation {
              height: 40px;
              background-color: #eee;
              font-size: 14px;
              line-height: 40px;
              padding-left: 18px;
            }
            .content-body {
              display: flex;
              background-color: #fff;
              height: 90px;
              .body-l {
                margin-top: 10px;
                width: 80px;
                text-align: right;
                line-height: 22px;
                color: #666;
              }
              .body-r {
                margin-top: 10px;
                // width: 70px;
                // text-align:right;
                line-height: 22px;
                padding-left: 10px;
              }
            }
          }
          .content-down {
            box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.13);
            margin-top: 10px;
            height: 148px;
            background-color: #fff;
            .down-title {
              height: 40px;
              background-color: #eee;
              font-size: 14px;
              line-height: 40px;
              padding-left: 18px;
            }
            .identification {
              // height: 110px;
              display: flex;
              flex-wrap: wrap;
              > span {
                padding: 10px 20px;
                background-color: #f7f7f7;
                color: #000;
                margin: 5px 5px;
              }
            }
          }
        }
        // //   height: 5555px;
        // .picture{
        //   height: 360px;
        //   background-color: skyblue;
        //   // margin-bottom: 20px;
        //   >img{
        //     width: 100%;
        //     height: 100%;
        //   }
        // }
        // .details{
        //   // margin-top: 20px;
        //   // padding-top: 20px;
        // //   height: 248px;
        //   background-color: #fff;
        //   .corporate{
        //     height: 40px;
        //     text-align: center;
        //     line-height: 40px;
        //     background-color: #50a3f8;
        //   }
        //   .condition{
        //     // height: 186px;
        //     .condition-top{
        //       // height: 44px;
        //       padding: 0 10px;
        //       >h3{
        //         margin: 16px 0 10px 0;
        //       }
        //       >p{
        //         margin-bottom: 10px;
        //         line-height: 18px;
        //       }
        //     }
        //     .condition-bottom{
        //       padding: 0 10px 10px;
        //       margin-top: 30px;
        //       >h3{
        //         margin: 10px 0;
        //       }
        //       >p{
        //         margin: 5px 0;
        //       }
        //     }
        //   }
        // }

        // .contact-us{
        //     height: 40px;
        //     text-align: center;
        //     line-height: 40px;
        //     background-color: #f7f7f7;
        // }
        // .contact{
        //   display: flex;
        //   justify-content: space-between;
        //   margin-bottom: 20px;
        //   >div{
        //     height: 40px;
        //     line-height: 40px;
        //   }
        // }
        // .product1{
        // //   padding-top: 20px;
        //   height: 180px;
        //   background-color: #fff;
        // //   margin-bottom: 10px;
        //   .main{
        //     height: 40px;
        //     text-align: center;
        //     line-height: 40px;
        //     background-color: #50a3f8;
        //   }
        //   .main-name{
        //     padding: 20px 0px;
        //     display: flex;
        //      flex-wrap: wrap;
        //     >span{
        //       padding: 10px 20px;
        //       background-color: #abd5ff;
        //       margin: 5px 5px;
        //     }
        //   }

        // }
        // .base{
        //       height: 10px;
        //       background-color: #f7f7f7;
        //   }
      }
    }
  }
}
</style>