<template>
  <div class="car-info">
        <div class="car-info-title">
            <div class="back" @click="BackPage">
                <img src="../../../assets/ybj/arrow_left.png" alt="">
                 返回
            </div>
            <div class="vin-code">{{vincode}}</div>
            <div class="carInfo">
                <div>{{`${levelidsInfo[0].EmissionStandard}/${levelidsInfo[0].Brand}/${levelidsInfo[0].ChassisCode}/[${levelidsInfo[0].ListingYear}-${levelidsInfo[0].ProducedYear}]/${levelidsInfo[0].Displacement+levelidsInfo[0].CylinderArrangement}/${levelidsInfo[0].FuelType}/${levelidsInfo[0].EngineModel}/${levelidsInfo[0].GearNumber}/${levelidsInfo[0].TransmissionDescription}`}}</div>
                <!-- <div>{{levelidsInfo}}</div> -->
                <div class="show-car-info" @click="showCarInfo=true">
                    <img  src="../../../assets/ybj/arrow_right.png" alt=""> 
                </div>
            </div>
        </div>
        <div >
            <el-tabs v-model="activeName" @tab-click="handleClick" style="background:#fff;padding:10px">
            <el-tab-pane label="全部" name="first">
                <div class="produceMsg">
                    <div class="product-title">
                        <div class="content">
                            <img src="../../../assets/ybj/shopbag.png" alt="">
                            全部
                        </div>
                        <div v-if="showCarInfoTwo==true"><img  @click="showCarInfoTwo=false" src="../../../assets/ybj/arrow_bottom.png" alt=""></div>
                        <div v-else><img  @click="showCarInfoTwo=true" src="../../../assets/ybj/arrow-right-white.png" alt=""></div>
                    </div>
                    <carlist v-if="showCarInfoTwo" :productInfo="productInfo" @showpartDioag="showpartDioag" @fankuiCenter="fankuiCenter" :levelidsInfo="levelidsInfo"></carlist>
                    
                </div>
            </el-tab-pane>
            <el-tab-pane :label="item.CategoryName" :name="index+''" v-for="(item,index) in cateList" :key="index">
                <div class="produceMsg">
                    <div class="product-title">
                        <div class="content">
                            <img src="../../../assets/ybj/shopbag.png" alt="">
                            {{item.CategoryName}}
                        </div>
                        <!-- <div><img src="../../../assets/ybj/arrow_bottom.png" alt=""></div> -->
                        <div v-if="showCarInfoTwo==true"><img  @click="showCarInfoTwo=false" src="../../../assets/ybj/arrow_bottom.png" alt=""></div>
                        <div v-else><img  @click="showCarInfoTwo=true" src="../../../assets/ybj/arrow-right-white.png" alt=""></div>
                    </div>

                    <carlist v-if="showCarInfoTwo" :productInfo="carlistInfo" @showpartDioag="showpartDioag" :levelidsInfo="levelidsInfo"></carlist>
                </div>
            </el-tab-pane> 
            </el-tabs>
        </div>
        

        

        

        <div class="cardialog">
            <el-dialog
        title="车型参数"
        :visible.sync="showCarInfo"
        width="40%" >
        <el-row>
            <el-col :span="12"><div class="grid-content bg-purple">品牌</div></el-col>
            <el-col :span="12"><div class="grid-content bg-purple-light">{{levelidsInfo[0].Brand||'无'}}</div></el-col>
        </el-row>
        <el-row>
            <el-col :span="12"><div class="grid-content bg-purple">厂商</div></el-col>
            <el-col :span="12"><div class="grid-content bg-purple-light">{{levelidsInfo[0].Manufacturers?levelidsInfo[0].Manufacturers:'无'}}</div></el-col>
        </el-row>
        <el-row>
            <el-col :span="12"><div class="grid-content bg-purple">车型</div></el-col>
            <el-col :span="12"><div class="grid-content bg-purple-light">{{levelidsInfo[0].Models?levelidsInfo[0].Models:"无"}}</div></el-col>
        </el-row>
        <el-row>
            <el-col :span="12"><div class="grid-content bg-purple">底盘号</div></el-col>
            <el-col :span="12"><div class="grid-content bg-purple-light">{{levelidsInfo[0].ChassisCode?levelidsInfo[0].ChassisCode:'无'}}</div></el-col>
        </el-row>
        <el-row>
            <el-col :span="12"><div class="grid-content bg-purple">年份</div></el-col>
            <el-col :span="12"><div class="grid-content bg-purple-light">{{(levelidsInfo[0].ListingYear+'-' + levelidsInfo[0].ProducedYear)||'无'}}</div></el-col>
        </el-row>
        <el-row>
            <el-col :span="12"><div class="grid-content bg-purple">排量</div></el-col>
            <el-col :span="12"><div class="grid-content bg-purple-light">{{(levelidsInfo[0].Displacement+levelidsInfo[0].CylinderArrangement)||'无'}}</div></el-col>
        </el-row>
        <el-row>
            <el-col :span="12"><div class="grid-content bg-purple">燃油类型</div></el-col>
            <el-col :span="12"><div class="grid-content bg-purple-light">{{levelidsInfo[0].FuelType?levelidsInfo[0].FuelType:'无'}}</div></el-col>
        </el-row>
        <el-row>
            <el-col :span="12"><div class="grid-content bg-purple">发动机型号</div></el-col>
            <el-col :span="12"><div class="grid-content bg-purple-light">{{levelidsInfo[0].EngineModel||'无'}}</div></el-col>
        </el-row>
        <el-row>
            <el-col :span="12"><div class="grid-content bg-purple">档位数</div></el-col>
            <el-col :span="12"><div class="grid-content bg-purple-light">{{levelidsInfo[0].GearNumber||'无'}}</div></el-col>
        </el-row>
        <el-row>
            <el-col :span="12"><div class="grid-content bg-purple">变速箱描述</div></el-col>
            <el-col :span="12"><div class="grid-content bg-purple-light">{{levelidsInfo[0].TransmissionDescription||'无'}}</div></el-col>
        </el-row>
        <el-row>
            <el-col :span="12"><div class="grid-content bg-purple">变速箱型号</div></el-col>
            <el-col :span="12"><div class="grid-content bg-purple-light">{{levelidsInfo[0].TransmissionType||'无'}}</div></el-col>
        </el-row>
        <el-row>
            <el-col :span="12"><div class="grid-content bg-purple">驱动形式</div></el-col>
            <el-col :span="12"><div class="grid-content bg-purple-light">{{levelidsInfo[0].DriveModel||'无'}}</div></el-col>
        </el-row>
        <el-row>
            <el-col :span="12"><div class="grid-content bg-purple">排放标准</div></el-col>
            <el-col :span="12"><div class="grid-content bg-purple-light">{{levelidsInfo[0].EmissionStandard||'无'}}</div></el-col>
        </el-row>
         <el-row>
            <el-col :span="12"><div class="grid-content bg-purple">启停</div></el-col>
            <el-col :span="12"><div class="grid-content bg-purple-light">{{levelidsInfo[0].IntelligentStopStart||'无'}}</div></el-col>
        </el-row>
         <el-row>
            <el-col :span="12"><div class="grid-content bg-purple">转向助力类型</div></el-col>
            <el-col :span="12"><div class="grid-content bg-purple-light">{{levelidsInfo[0].Steering||'无'}}</div></el-col>
        </el-row>
         <el-row>
            <el-col :span="12"><div class="grid-content bg-purple">前制动类型</div></el-col>
            <el-col :span="12"><div class="grid-content bg-purple-light">{{levelidsInfo[0].FrontBrake||'无'}}</div></el-col>
        </el-row>
        <el-row>
            <el-col :span="12"><div class="grid-content bg-purple">后制动类型</div></el-col>
            <el-col :span="12"><div class="grid-content bg-purple-light">{{levelidsInfo[0].RearBrake||'无'}}</div></el-col>
        </el-row>
        <span slot="footer" class="dialog-footer">
            <el-button @click="showCarInfo = false" style="color:#4564d3">关闭</el-button> 
        </span>
        </el-dialog>

        </div>
        
  </div>
</template>

<script>

import carlist  from "./carlist.vue"
export default {
    components:{
        carlist,
    },
    
    data(){
        return{
            showCarInfo:false,
            activeName:"first",
            showpartInfo:false,//显示配件详情
            activeCar:"first",
            carName:"",
            productNo:"",
            showproductInfo:'block',
            oecode:"",
            cartable:[],
            cateList:[],
            timer:null,
            carlistInfo:[],
            vincode:'',
            showCarInfoTwo:true,//展示产品列表
        }
    },
    props:{
        VinCode:{
            require:true,
        },
        levelidsInfo:{
            require:true
        },
        productInfo:{
            require:true
        },
        LevelId:{
            require:true
        }
    },
    methods:{
        fankuiCenter(option){
            this.$emit("fankuiCenter",option)
        },
         handleClick(tab, event) { 
            if(this.LevelId){
                this.cateList.forEach(async (item,index) => {
                    if(item.CategoryName == tab.label){
                        if(this.LevelId){
                            let res = await this.$http(`index.php?act=data&op=liyang&model=productwithnote&data=${this.LevelId}`,{ 
                            productcategoryid:item.CategoryId,
                            productbrandid:item.Brands[0].BrandId
                        }) 
                        this.carlistInfo = res.Result 
                        let productList = []


                        this.carlistInfo.forEach(item => {
                            productList.push(item.productNo)
                        })
                        let getSearches = await this.$api.getSearches({
                            searchs:productList.join(","),
                            shopId:this.$store.state.shop,
                            store_id:this.$store.state.store_id
                            }) 

                        getSearches.data[0].goods_list.forEach((item,index) => {
                        
                        this.$set(this.carlistInfo[index],"oecode",item.oecode)
                        this.$set(this.carlistInfo[index],"goods_storage",item.goods_storage)
                        this.$set(this.carlistInfo[index],"goods_price",item.goods_price)
                        this.$set(this.carlistInfo[index],"goods_list",item)
                        })
 
            
                        }
                        
                    }
                })
            }
        },
        showpartDioag(option){
            this.showpartInfo = option
        },
        async showactiveCar(item){
            this.showpartInfo=true; 
            this.carName = item.productBrandName;
            this.productNo = item.productNo
            this.oecode = item.oecode
            if(item.levelId){
                let result = await this.$http(`index.php?act=data&op=liyang&model=VehicleByLids`,{
                data:item.levelId,
                AppKey:"3dd4ed48ea913068",
                AppSecret:"431595e67f114da9a839e8b49605b4b6" 
                }) 
                this.cartable = result.Result 
            }
            
        }, 
        
        BackPage(){
            this.$emit('backPage',1)
        }
    },
    async created(){ 
        this.vincode = this.VinCode
        let res = await this.$http(`index.php?act=data&op=liyang&model=ProductConfig&data=1`,{ 
                AppKey:"3dd4ed48ea913068",
                AppSecret:"431595e67f114da9a839e8b49605b4b6" 
            })
        // this.cateList = res.Result
        this.cateList = []  
    },
    updated(){
        this.vincode = this.VinCode
    },
    
}
</script>

<style lang="scss" scoped>
.car-info{
    min-height: 100vh;
    ::v-deep .el-dialog__body{
        padding: 0px 20px;
        color: #606266;
        font-size: 14px;
        word-break: break-all;
        border-top: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
        
    }
    .cardialog{
        ::v-deep .el-dialog__header{
        padding: 20px 0 10px;
        }
        ::v-deep .el-dialog__title {
            line-height: 24px;
            font-size: 20px;
            color: #0f58eb; 
            border-left: 2px solid #0f58eb;
            padding-left: 10px;
        }
    }
    
    ::v-deep .el-row{
            margin: 30px;
            .bg-purple{
                color: #999;
            }
        }
    ::v-deep .el-dialog__headerbtn{
            top:8px;
            position: absolute; 
            right: 20px;
            padding: 0;
            background: 0 0;
            border: none;
            outline: 0;
            cursor: pointer;
            font-size: 16px;
        }
    .dialog{
        .activeCar{
        margin:10px 0;
        ::v-deep .el-tabs__item.is-active {
            color: #fff;
            background: #346ae1;
        }
        
        .activeCar-content{
            width: 100%;
            ::v-deep .el-row{
                margin: 0;
                border: 1px solid #ccc;
            }
            .activeCar-img{
                height: 200px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            .bg-purple-dark{ 
                display: flex; 
                justify-content: space-between;
                background: #eee;
                height: 45px;
                line-height: 25px;
                padding: 10px;
                box-sizing: border-box; 
                .content{
                    color: #0f58eb;
                } 
            }
            .grid-content-item{
                height: 40px;
                line-height: 22px;
                padding: 10px;
                box-sizing: border-box;
            }
        }
    }
    }
    
    .produceMsg{
        .product-title{
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 5px 20px;
                margin-bottom: 5px;
                height: 36px;
                background-color: #4564d3;
                .content{
                    color: #fff;
                    font-weight: bold;
                    >img{
                        vertical-align: middle;
                    }
                }
        }
        .produce-list{
            
            margin:10px 0;
            .produce-item{
                background-color: #FFFFFF;
                border:1px solid #ccc;
                padding: 5px 15px;
                margin: 5px 0;
                .prodecu-content{
                    display: flex;
                    align-items: center;
                    .img{
                        width: 90px;
                        height: 90px;
                        margin-right: 10px;
                        flex-shrink: 0;
                        >img{
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .content-list{
                        flex-grow: 1;
                         
                        .top{
                            font-size: 16px;
                            color: #4564d3;
                            font-weight: bold;
                        }
                        .OE-Code{
                            margin-top: 12px;
                            width:300px;
                            color: #999;
                            display: flex;
                            justify-content: space-between;
                        }
                        .price-storage{
                            width:300px; 
                            display: flex;
                            justify-content: space-between;
                            margin-top: 30px;
                            .price{
                                font-size: 16px;
                                color: red;
                                font-weight: bold;
                            }
                            .storage{
                                color: #999;
                            }
                        }
                    }
                    .errorBack{
                        height: 83px; 
                        width: 50px;
                        .fankui{
                            color: red;
                            >img{
                                vertical-align: middle;
                            }
                        }
                        .shopcar{
                            margin-top:40px;
                            >img{
                                width:18px
                            }
                        }
                    }
                }
            }
        }
    }
    .car-info-title{
        display: flex;
        height: 40px;
        .back{
            font-size: 14px;
            width: 60px;
            text-align: center;
            color: #4564d3;
            height: 40px;
            line-height: 40px;
            flex-shrink: 0;
            border: 1px solid #D9D9D9;
            margin-right: 5px;
            padding-right: 5px;
            background-color: rgb(207, 214, 232);
            font-weight: bold;
            >img{
                vertical-align: middle;
            }
        }
        .vin-code{
            height: 100%;
            width: 280px;
            flex-shrink: 0;
            border: 1px solid #D9D9D9;
            margin-right: 5px;
            padding: 0 10px;
            background-color: rgb(207, 214, 232);
            line-height: 40px;
            font-size: 16px;
            font-weight: bold;
        }
        .carInfo{
            background-color:rgb(207, 214, 232);
            flex-grow: 1;
            border: 1px solid #D9D9D9;
            height: 100%;
            line-height: 40px;
            padding: 0 10px;
            display: flex;
            justify-content: space-between;
            >div:nth-child(1){
                color: #4564d3;
                border-left: 2px solid #4564d3;
                font-weight: bold;
                padding-left: 10px;
                height: 26px;
                line-height: 26px;
                margin-top: 8px;
            } 
            .show-car-info{
                color: #4564d3;
                font-weight: bold;
                >img{
                    vertical-align: middle;
                }
            }
        }
    }
}


</style>