<template>
	<el-container class="hnw-union-index">

		
	  <el-header height="30px">
		  <!-- <hnw-header></hnw-header> -->
		 <!-- 头部 -->
		  	<div class="hnw-header">
				<div class="logo">
					
					<template >
						<div class="re-home" @click="toHome">
							<img src="../../assets/shopcar/house.png" alt="">
							<span>华诺威首页</span>
						</div>
						<div class="shu">			
						</div>
						<div>
							帮助中心
						</div>
						
					</template>
					
				
				</div>
				
				<div class="header-right">
					
					<el-menu mode="horizontal"   active-text-color="#666" text-color="#666" >
						<template v-if="token">
							<el-submenu index="1">
								
								<template slot="title">
									<span class="my-hnw">{{nickname}} </span>
								</template>
								
								<el-menu-item style=" height: 57px;background-color:#EDEFF4;  " index="1-1" >
									<div style="font-size: 16px; color: #666666; text-align: center;  height: 30px;">{{$store.state.company_name_jc}}</div>
									<div style="font-size: 12px; color: #999999; text-align: center;  height: 20px; margin-top: -10px;">{{$store.state.nickname}} / {{$store.state.sc_name}}</div>
								</el-menu-item>

								<el-menu-item style="text-align: center; " index="1-1" @click="toMymodule(menu.path)" v-for="(menu,index) in $hnwTools.storeMenuList" :key="index">
									<img style="width: 16px; height: 16px; padding-right: 5px;" :src="require(`../../assets/my/msg/${menu.img}.png`)" alt="">
										{{menu.title}}
								</el-menu-item>
								<el-menu-item style="text-align: center; height: 57px; line-height: 57px; border-top: 1px solid #F6F6F6;" index="1-1" @click="loginOut" >
									<img style="width: 16px; height: 16px; padding-right: 5px;" src="../../assets/store/quit.png" alt="">
									退出登录
								</el-menu-item>
								
							</el-submenu>
							
							<div class="shu" ></div>
						</template>

						<el-menu-item index="4" @click="toOrder" >
							我的订单
						</el-menu-item>
						<div class="shu" ></div>
						
						
						<el-menu-item index="3" @click="toHnwPur" v-if="$store.getters.notLvD">
							<div class="to-hnw-pur">
								<img class="store-icon" src="../../assets/index/storeicon.png" alt="">
								企业中心
							</div>
						</el-menu-item>
						<div class="shu" v-if="$store.getters.notLvD"></div>
						<el-menu-item index="4" @click="toVin" class="jd-red">
							<img style="width: 14px;height: 14px;" src="../../assets/store/vin.png" alt="">
							电子目录
						</el-menu-item>
						<div class="shu" v-if="$store.getters.notLvD"></div>
						<el-submenu index="5" v-if="$store.getters.notLvD"> 
							<template slot="title">手机版</template>
							<el-menu-item index="4-1" class="home-menu-erm">
								<div class="home-menu-erm-row">
									<div class="erm-item">
										<div class="img-box">
											<img src="../../assets/index/anzhuoerweima.png" alt="">
										</div>
										<div class="tit">
											APP安卓版
										</div>
									</div>

									<div class="erm-item">
										<div class="img-box">
											<img src="../../assets/index/iosewm.png" alt="">
										</div>
										<div class="tit">
											APP苹果版
										</div>
									</div>

								</div>

							</el-menu-item>
							
						</el-submenu>

						<div class="shu" v-if="$store.getters.notLvD"></div>
						<!-- <el-menu-item index="2" disabled v-if="$store.getters.notLvD">合伙人计划</el-menu-item> -->
						
					</el-menu>
				</div>
			</div>
	  </el-header>
	   <el-main>
		   
		<section class="main-box">
			<!-- 主体的头部 -->
			
			<div class="main-head">
				<div class="logo">
					<img src="../../assets/index/logo.png" alt="">
					<img src="../../assets/union/unionLogo.png" alt="" class="logo-text-img">
					<!-- <div class="re-home-btn" @click="toHome">
						返回主页  @click="toHome"
					</div> -->
				</div>
				<div class="search">
					<!-- 去抖延时 debounce getSearchRecord-->
					<el-autocomplete
						class="inline-input"
						v-model="searchText"
						:fetch-suggestions="querySearch"
						placeholder="请输入搜索内容，关键词用空格隔开"
						clearable
						:hide-loading="true"
						value-key="record_name"
						@keyup.enter.native="search"
					></el-autocomplete>
					<el-button class="seach-btn" @click="search">
						搜索
					</el-button>
					<!-- 搜索的历史纪录 -->
					
				</div>
				<div class="shopcart-btns">
					<div class="shopCart" v-if="$store.state.shop!=1974&&$store.state.shop!=1871&&$store.state.shop!=1948">
						<div @click="toCart(2)" class="cart-adv-btn">
							<div class="aog" v-if="$store.state.advCart">
								<div class="aog-text">
									到货
								</div>
								<div class="btn-text">
									预购单
								</div>
							</div>
							<div v-else>
								预购单
							</div>
						</div>
					</div>

					<div class="shopCart">
						
						<div @click="toCart(1)">
							<el-button class="cart-btn">
								<img src="../../assets/index/购物车.png" alt="">
									<el-badge :value="cartNums" class="num" type="primary" :max="99" :hidden="!cartNums">
								</el-badge>
								<span class="btn-text">
									我的购物车
								</span>
							</el-button>
						</div>
					</div>
				</div>
				

			</div>
			
			<div class="main-nav-box" >
				<div class="main-nav" >
					
					<div class="main-tabs" >
						
						<div class="tab-item" v-for="tab in tabsList" :key="tab.name" @click="tabClick(tab)" :class="{'active':activePath==tab.path}">
							{{tab.name}}
						</div>

						
					</div>
				</div>
				
			</div>


			
			<div class="main-body-box" :style="{'background':$route.path=='/union/goodsinfo'?'#fff':'#f7f7f7'}">
				<div class="main-body">
					<!--  -->
					<keep-alive  exclude="unGoodsInfo">
						<router-view :searchText="searchFinalText"/>
					</keep-alive>
				</div>
				
			</div>
		</section>
	  </el-main>
	  <div style="background:#f7f7f7; height: 10px;"></div>
	  <el-footer height="346px" >
		 
		  	<div class="hnw-footer white" >
				<div class="fot-top">
					<div class="promise">
						
					<div class="pro-item">
						<div class="bagde">
							正
						</div>
						<span>货真价实，不卖假件</span>
					</div>
					
					<div class="pro-item-shu">
					</div>
					
					<div class="pro-item">
						<div class="bagde">
							保
						</div>
						<span>30天保障，不高于行价</span>
					</div>
					<div class="pro-item-shu">
					</div>
					<div class="pro-item">
						<div class="bagde">
							无
						</div>
						<span>售后无忧，服务至上</span>
					</div>
					<div class="pro-item-shu">
					</div>
					<div class="pro-item">
						<div class="bagde">
							全
						</div>
						<span>南北大众全车系</span>
					</div>

					</div>
					<div class="line">
					</div>
					<!-- 导航 -->
					<div class="guide">
						<div class="te-phone">
							<div>
							
								服务热线
							</div>
							<div class="phone">
								021-69585015
							</div>
						</div>
						<div class="link-box">
							<div class="link-rol">
								<div>
									<span @click="$router.push('/service/0')">
									新手入门
									</span>
								</div>
								<div>
									<span @click="$router.push('/service/1')">
										注册认证
									</span>
									
								</div>
							</div>
							<div class="link-rol">
								<div>
									<span @click="$router.push('/service/2')">
										集单业务
									</span>
									
								</div>
								<div>
									<span @click="$router.push('/service/7')">
										联系方式
									</span>  
								</div>
								<!-- <div>
									<span @click="$router.push('/service/3')">
										
										支付证明
									</span>
								</div> -->
							</div>
						
							<div class="link-rol">
								<div>
									<span @click="$router.push('/service/4')" >
										关于退换货
									</span>
									
								</div>
								<div>
									<span @click="$router.push('/service/6')">
										关于我们  
									</span>  
								</div>					
							</div>
							<div class="link-rol">
								
								
								<div>
									<span @click="$router.push('/service/8')">
										法律声明
									</span>  
								</div>
							</div>
						
							
						</div>
						<div class="erweima-box">
							<div class="erwei-gzh">
								<div class="img-box">
									<img src="../../assets/index/erweima1.jpg" alt="">
								</div>
								<div class="tit">
									华诺威公众号
								</div>
							</div>
							<div class="erwei--app-and">
								<div class="img-box">
									<img src="../../assets/index/anzhuoerweima.png" alt="">
								</div>
								<div class="tit">
									APP安卓版
								</div>
							</div>
							<div class="erwei-app-ios">
								<div class="img-box">
									<img src="../../assets/index/iosewm.png" alt="">
								</div>
								<div class="tit">
									APP苹果版
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="fot-botm">
					<div>
						版权所有©2020华诺威汽车零部件（上海）股份有限公司&nbsp;&nbsp;Copyright©2020chinawayutoparts
					</div>
					<a href="https://beian.miit.gov.cn" target="_blank" >沪ICP备16048698号-3</a>
				</div>
			</div>
	  </el-footer>
	  
	  
	</el-container>
</template>

<script>
import { mapState } from "vuex" 

	export default{
		data(){
			return{
				searchText:"",
				searchFinalText:"",
                tabsList:[
                    {
                        name:"首页",
                        path:"/union/home"
                    },
                    {
                        name:"所有配件",
                        path:"/union/allGoods"
                    },
                    {
                        name:"清库存",
                        path:"/union/clear"
                    },
                    {
                        name:"热销商品",
                        path:"/union/hotGoods"
                    },
                    {
                        name:"独家品牌",
                        path:"/union/brandGoods"
                    }
                ],
				// 当前路由 
				activePath:"/union/home",
				currentPage:1,
            	pageSize:15,
				sort_type:'',
            	sort_way:'',
				banner:"",
				searchData:{ //筛选框选中列表
					
					oecarbrand:[],
					oenaturebrand:[],
					oesort:[],
					store_name:[]
				}
			}
		},
        methods:{
			
			
			
			async querySearch(str, cb){
				if(!this.searchText){
					
					// ... 
					let res =  await this.$api.getSearchRecord({
						record_type:3,
					})
					cb(res)
				}else{
					cb([])
				}
			},
			
			// 搜索方法
			
			search(){
				this.searchFinalText = this.searchText
				this.activePath = "/union/allGoods"
				this.$router.push("/union/allGoods")
			},
			tabClick(tab){
				this.activePath = tab.path
				this.$router.push(tab.path)
			},
			// 退出登录deng
			loginOut(){
				
				
				if(!this.$store.getters.notLvD){
					this.$router.push("/login")
				}else{
					this.$router.push("/")
				}
				localStorage.removeItem("hnw-web-state")
				this.$store.commit("resetState")
			},
			toMymodule(path){
				if(!this.$isCompany()){
					return 
				}
				this.$router.push(path)
			},
			toCart(type){
				if(!this.$isCompany()){
					return 
				}
				this.$router.push("/shopcar/1/"+type)
			},
			toHome(){
				
                this.$router.push('/')
			},
			toOrder(){
				if(!this.token){
					this.$warMsg("未登录账号")
					return this.$router.push("/login")
					// window.open("http://www.huanuowei.cn/#/login/self")
				}else{
					this.$router.push({path:"/my/order"})
				}
			},
			
			toHnwPur(){
				
				if(!this.token){
					this.$warMsg("未登录账号")
					return this.$router.push("/login")
					
					// window.open("http://www.huanuowei.cn/#/login/self")
				}else if(this.register_from==2){
					return this.$warMsg("您的账号不能登录企业中心")
					// window.open("http://www.huanuowei.cn/#/login/self")
				}else if(this.$store.state.shop&&this.$store.state.shop!=1780){
					console.log("检测到shop",this.$store.state.shop);
				}else{
					let acc = this.account_number + "|" + this.password
					let mixText =  this.$hnwTools.mixStr(acc,1)
					// console.log("window.location.origin",window.location.origin);
					
					// window.open(window.location.origin+'/store/#/login/'+mixText)
					
					if(this.$axios.defaults.baseURL=="https://jc.hnwmall.com/web/"){
						window.open("http://jc.hnwmall.com:6600/#/login/"+mixText)

					}else if(this.$axios.defaults.baseURL=="https://test.huanuowei.cn/api/web/"){

						window.open("https://test.huanuowei.cn/store/#/login/"+mixText)
						
					}else if(this.$axios.defaults.baseURL==`https://api.huanuowei.cn/web/`){
						console.log("member.id",this.$store.state.member_id.indexOf("-"))
						if(this.$store.state.member_id.indexOf("-")==-1){
							// window.open("https://b.huanuowei.cn/store/#/login/"+mixText)
							window.open("https://huanuowei.cn/store/#/login/"+mixText)
							// window.open(`https://api.huanuowei.cn/store/#/login/`+mixText)
						}
						
					}else{
						console.log("未找到对应网址");
						window.open("https://www.huanuowei.cn/#/login/"+mixText)
					}
				}
			},
			toVin(){
				
				// 判断是否登录
				if(!this.token){
					this.$warMsg("未登录账号")
					return this.$router.push("/login")
					
				}else if(this.$isCompany()){
					let link = location.href.split("#")[0]
					window.open(link + '#/epc/home/vin')
					
					// if(this.$store.state.shop==1780){
					// }else{
					// 	this.$warMsg("您的账号不能查看电子目录")
					// }
				}
				
			}
			
		},
		computed:{
			...mapState(["company_name_jc","store_id","cartNums","token","account_number","password","member_name","register_from","role_name","member_id","storeShopId","nickname"]),
			
			
			favoriteText(){
				return this.favorite ? '已收藏': '收藏'
			},
			showPlaceh(){
           		return this.batchs.length<1
        	}
		},
		watch:{
			
			textarea(new2){
				if(!new2){
					return this.batchs.splice(0,this.batchs.length)
				}

				this.batchs = []
				
				let space = / /
				// console.log("是否有空格",space.test(new2)?'有':'无')
				
				let data = new2.trim()
				// 按换行切割
				let datas = data.split(/\n/)
				
				let hasgoods_storage = /\s{}/
				datas.forEach(item=>{
					// 按空格切割
					let row = item.split(/\t/)
					let search = row[0]
					let oecode =  row[0].replace(/\s/g,'')
					let goods_storage = row[1]||1
					// 有的oe号可能有空格
					
					oecode = oecode.trim()
					this.batchs.push({
						oecode,
						search,
						goods_storage
					})
				})

			},
		},
		
		
		async created(){
			this.activePath = this.$route.path
			this.$store.dispatch("getCartNums")
			console.log("store",this.$store)
			
			// let res1 =  await this.$api.getSearchRecord({
			// 	record_type:3
			// })
			// if(res1.length>0){
			// 	this.searchText = res1[0].record_name
			// }
			
		}
	}
</script>

<style lang="scss">
     .gray{
		 background: $backGray;
	 }
	 .white{
		 background: #fff;
	 }
	.hnw-union-index{
		overflow: hidden;
		min-width: 1200px;
		// position: relative;
		.hnw-home-index-dia{
			.el-dialog{
				background: transparent;
			}
			.el-dialog__header{
				padding: 0;
				border-bottom:none;
			}
			.el-dialog__body{
				padding: 0;
				background: transparent;
			}
			.adver-banner{
				position: relative;
				.clear-btn{
					position: absolute;
					border-radius: 50%;
					width: 40px;
					height: 40px;
					border: 1px solid #2041af;
					right: 10px;
					top: 4%;
					z-index: 5;
					display: flex;
					align-items: center;
					justify-content: center;
					color: #2041af;
					cursor: pointer;
					i{
						font-size: 18px;
						font-weight: 700;
					}
				}
				.el-carousel__indicator--horizontal{
					.el-carousel__button{
						background: #C1E2FF;
					}
				}
				.is-active{
					.el-carousel__button{
						background: #2851C3;
					}
				}
				
				.el-carousel__arrow{
					transform: scale(2);
					i{
						font-size: 18px;
					}
				}
				.el-carousel__item{
					img{
						width: 100%;
						height: 100%;
					}
				}
			}
		}
		.advertisement-ball{
			position: fixed;
			bottom: 30%;
			right:10px;
			
			background: #3E84D6;
			padding: 10px;
			border-radius: 50%;
			cursor: pointer;
			z-index: 10;
			i{
				font-size: 24px;
				color:#fff;
			}
		}

		.active-text{
			color: #3E84D6;
		}
		// 预购单 公用样式
		.cart-adv-btn{
			width: 100px;
			height: 42px;
			background: #EEEEEE;
			border-radius: 4px;
			justify-content: space-evenly;
			display: flex;
			align-items: center;
			margin: 0 20px;
			cursor: pointer;
			color: #858585;
		}
		// 到货
		.aog{
			display: flex;
			align-items: center;
			color: red;
			font-size: 12px;
			.aog-text{
				animation: smallToBig .8s infinite linear alternate;
				width: 32px;
				height: 20px;
				line-height: 20px;
				text-align: center;
				background: #FF0000;
				border-radius: 4px;
				color: #fff;
				margin-right: 10px;
			}
			.btn-text{
				font-size: 14px;
			}
		}
		@keyframes smallToBig {
			0%{ 
				transform: scale(.8) translateZ(0);
			}
			100%{
				transform: scale(1.5) translateZ(0) ;
			}
		}
		.el-header{
			padding: 0;
			// 减去了滚动条的宽度
			background: $gray;
			.hnw-header{
				font-size: 12px;
				height: 100%;
				padding: 12px 0;
				width: 1200px;
				margin: 0 auto;
				box-sizing: border-box;
				display: flex;
				align-items: center;
				justify-content: space-between;
				color: #666;
				.shu{
					height: 14px;
					width: 1px;
					// background: #333333;
					margin: 0 10px;
				}
				.logo{
					display: flex;
					align-items: center;
					.re-home{
						cursor: pointer;
						display: flex;
						
						img{
							margin-right: 4px;
							width: 12px;
						}
						span{
							position: relative;
							top: 1px;
						}
					}
					
				}
				.img-box{
					width: 66px;
					height: 66px;
					img{

					}
				}
				.header-right{
					
					height: 30px;
					.el-menu{
						background: transparent;
						.shu{
							float: left;
							margin-top: 7px;
							// background: #999999;
						}
						.to-hnw-pur{
							display: flex;
							align-items: center;
							img{
								width: 14px;
								margin-right: 2px;
							}
						}
						.el-submenu{
							
							.el-submenu__title{
								padding: 0;
								font-size: 12px;
								color: #333;
								height: 30px;
								flex-shrink: 0;
								background: transparent;
								border-bottom: none;
								display: flex;
								align-items: center;
								&:hover{
									background: transparent;
								}
								i{
									margin-left: 3px;
									transform: scale(.7);
									color: #999;
								}
								.my-hnw{
									height: 30px;
									line-height: 30px;
								}
							}
							.el-submenu__icon-arrow{
								margin-top: 0;
							}
						}
						.is-active{
							.el-submenu__title{
								border-bottom: none;
								padding: 0;
								font-size: 12px;
								
							}
						}
						.el-menu-item{
							
							height:30px;
							line-height: 30px;
							font-size: 12px;
							color: #333;
							padding: 0;
							border: none;
							flex-shrink: 0;
							background: transparent;
							&:hover{
								background: transparent;
							}
						}
					}
					
					.el-menu--horizontal{
						border-bottom: none;
					}
					
				}
			}
		}
		.el-main{
			overflow: hidden;
			padding: 0;
			
			.main-box{
				.main-head{
					justify-content: space-between;
					display: flex;
					align-items: center;
					
					width: $centerW;
					margin: 12px auto 0;
					.logo{
						display: flex;
						align-items: center;
						position: relative;
						cursor: pointer;
						img{
							width: 112px;
							height: 50px;
						}
						.logo-text-img{
							height: 30px;
							width: 177px;
						}
						.re-home-btn{
							position: absolute;
							cursor: pointer;
							z-index: 999;
							bottom: -18px;
							right: 54px;
							border-radius: 5px;
							height: 20px;
							line-height: 20px;
							width: 68px;
							text-align: center;
							color: #2041af;
							border: 1px solid #2041af;
						}
					}
					.search{
						display: flex;
						align-items: center;
						// 预购单要向后来点
						margin-right: -50px;
						// margin-right: 98px;
						.inline-input{
							width: 494px;
							height: 34px;
							border-radius: 0;
							.el-input__inner{
								border-radius: 0;
								height: 34px;
							}
						}
						.seach-btn{
							width: 100px;
							background: #3E84D6;
							color: #fff;
							border-radius: 0;
							height: 36px;
							position: relative;
							left: -2px;
						}
						.search-history{
							display: flex;
							.his-item{
								padding: 10px 10px 0 0;
								font-size: 12px;
								color: #999;
								cursor: pointer;
							}
							.his-item:hover{
								color: #3E84D6;
							}
						}
					}
					.shopcart-btns{
						display: flex;
						width: 275px;
						// 预购单 
						// justify-content: flex-end;
						justify-content: space-evenly;
						.shopCart{
							position: relative;
							
							.cart-btn{
								width: 128px;
								height: 42px;
								background: #EEEEEE;
								border-radius: 4px;
								border: none;
								
								display: flex;
								align-items: center;
								box-sizing: border-box;
								padding-left: 13px;
								border-radius: 0;
								color: #3E84D6;
								img{
									
								}
								.btn-text{
									font-size: 14px;
									margin-left: 10px;
									position:relative;
									top: -2px;
									
								}
								
							}
							
							.num{
								position: absolute;
								top: 4px;
								left: 24px;
							}
						}
					}
				}
				.my-header{
					height: 72px;
					background: #3E84D6;
					color: #fff;
					.hnw-area-center{
						display: flex;
						justify-content: space-between;
						align-items: center;
						height: 100%;
						.head-left{
							display: flex;
							align-items: center;
							.logoimg{
								width: 76px;
								height: 35px;
								cursor: pointer;
							}
							.titleimg{
								width: 160px;
								height: 22px;
								margin: 0 15px 0 8px;
							}
							.title{
								font-size: 14px;
								text-align: center;
								>div{

								}
								>div:last-child {

									font-size: 12px;
									border-radius: 10px;
									border: 1px solid #DFEEFD;
									padding: 4px 6px;
									cursor: pointer;
									margin-top: 6px;
								}
							}
							
							.find-cls{
								background: #c81623;
								border:none !important;
							}
						}
						.head-right{
							display: flex;
							.search-box{
								margin-right: 60px;
								display: flex;
								input{
									outline: none;
									padding: 0 6px;
									width: 220px;
									border: none;
								}
								div{
									background: #3E84D6;
									border: 1px solid #fff;
									color: #fff;
									cursor: pointer;
									line-height: 36px;
									padding: 0 16px;
								}
							}
							.cart-adv-btn{
								height: 36px;
								margin-right: 10px;
							}
							.shopCart{
								width: 140px;
								height: 36px;
								display: flex;
								align-items: center;
								justify-content: center;
								background: #fff;
								.cart-btn{
									position: relative;
									cursor: pointer;
									display: flex;
									align-items: center;
									.carimg{
										width: 20px;
										height: 20px;
										margin-right: 10px;
									}
									.btn-text{
										color: #333;
									}
									.num{
										position: absolute;
										top: -6px;
										left: 10px;
									}
								}
							}
						}
					}
				}
				
				.main-nav-box{
					
					height: 35px;
					position: relative;
					box-shadow: 0px 1px 1px 0px rgba(41, 49, 56, 0.19);
					.main-nav{
						width: $centerW;
						// display: flex;
						align-items: center;
                        justify-content: center;
						margin: 0 auto;
						
						box-sizing: border-box;
						border-bottom: 1px solid #eee;
					
						.goods-classify{
							display: flex;
							align-items: center;
							cursor: pointer;
							>img{

							}
							>span{
								padding: 0 4px;
								font-size: 14px;
							}
							i{
								font-weight: 700;
								transition: all .3s;
							}
							i.active-text{
								transform: rotate(180deg);
							}
						}
						.goods-classify-list{
							position: absolute;
							top: 40px;
							height: 60px;
							z-index: 5;
							width: 220px;
							height: 480px;
							box-sizing: border-box;
							padding: 16px 7px;
							color: #F2F2F2;
							font-size: 14px;
							line-height: 24px;
							
							background: rgba($color: #000000, $alpha: 0.6);
							.classify-row{
								display: flex;
								align-items: center;
								padding: 5px 0;
								img{

								}
								span{
									padding-left: 8px;
									cursor: pointer;
								}
								.first-cls{
									font-weight: bold;
									padding-left: 10px;
								}
							}
						}
						.main-eltabs{

						}
						.el-tabs{
							margin-left: 235px;
							max-height: 40px;
							.el-tabs__active-bar{
								display: none;
							}
							.el-tabs__nav-wrap::after{
								background: transparent;
							}
						}
						.main-tabs{
							height: 35px;
							display: flex;
							justify-content: center;
							align-items: center;
							.tab-item{
								padding: 0 20px;
								cursor: pointer;
							}
							.active{
								color: #409EFF;
								// background: #ccc;
							}
							.tab-item:hover{
								color: #409EFF;
							}
						}
					}
				}
				.main-body-box{
					position: relative;
					// background: #f7f7f7;
					.main-body{
						// width: 1200px;
						// margin: 0 auto
					}
					
				}
			}
			
		}
		.el-footer{
			@include hnwFooter;
		}
		
		// 批量采购弹框
		.el-dialog__header{
			border-bottom: 1px solid #dadada;
			padding: 14px 20px;
			.el-dialog__headerbtn{
				top: 15px;
			}
		}
		.el-dialog__body{
			padding: 10px 20px 20px 20px;
			.btn{
				display: flex;
				justify-content:center;
				>div{
					width: 99px;
					height: 39px;
					border: 1px solid #3E84D6;
					border-radius: 4px;
					line-height: 39px;
					text-align: center;
					font-size: 16px;
					color: #3E84D6;
					margin-right: 12px;
					cursor:pointer;
				}
				.atv{
					background-color: #3E84D6;
					color: #fff;
				}
			}
				.textBox{
					margin-top: 21px;
				}
				.hint{
					width: 96%;
					position: absolute;
					top: 22%;
					left: 2%;
					z-index: 6;
					background: #fff;
					pointer-events: none;
					.example-info{
						// width:100%;
						background: #F1F1F1;
						border-radius: 10px 10px 0px 0px;
						
						.red{
							color: red;
						}
						.blue{
							color: #3E84D6;
						}
						.gray{
							color: #999;
						}
						p{
							color: #333;
							line-height: 26px;
							padding-left: 20px;
						}
					}
					.example-table{
						padding-top: 10px;
						.table-head{
							background: #3E84D6;
							display: flex;
							>div{
								font-weight: 600;
								color: #fff;
								padding: 10px 20px;
								flex: 1;
								text-align: center;
							}
						}
						.table-body{
							border: 3px solid #f00;
							box-sizing: border-box;
							.body-row{
								display: flex;
								>div{
									flex: 1;
									padding: 10px ;
									text-align: center;
								}
								>div:first-child{
									border-right: 1px solid #ccc;
								}
							}
							.body-row{
								border-bottom: 1px solid #ccc;
							}
						}
					}
			}
			.lead{
				.lead-text{
					font-size: 18px;
					color: #3E84D6;
					padding: 20px 0 10px;
					
				}
				.inp{
					display: flex;
					justify-content:space-between;
					width: 96%;
					height: 44px;
					line-height: 44px;
					background: #F1F1F1;
					border: 1px solid #C2C2C2;
					border-radius: 8px;
					.import{
						width: 111px;
						height: 44px;
						background: #3E84D6;
						border: 1px solid #C2C2C2;
						border-radius: 8px;
						color: #fff;
						text-align: center;
						line-height: 44px;
						img{
							vertical-align: middle;
							width: 16px;
							height: 13px;
							
						}
					}
				}
					.require{
						margin-top: 20px;
						p{
							color: #333;
							line-height: 26px;
						}
						.red{
							color: red;
							font-size: 16px;
						}
						.blue{
							color: #3E84D6;
						}
						.download{
							width: 60px;
							padding: 5px;
							background-color: #3E84D6;
							color: #fff;
							border-radius: 2px;
							cursor:pointer;
						}
					}
			}
		}
		.el-dialog__footer{
			padding-bottom: 10px;
			background: #F1F2F5;
			text-align: center;
		}
		.batch-table{
			
			.paste-res{
				display: flex;
				justify-content: space-between;
				padding: 20px 0;
				align-items: center;
			}
			.table{
				border: 1px solid#eee;
				.el-table{
					&::before{
						height: 0;
					}
				}
			}
		}
		.index-kernel-dialog{
			.el-dialog__header{
				padding: 10px;
				border-bottom: .5px solid #a0c0ff;
				.el-dialog__headerbtn{
					top: 12px;
				}
			}
			.el-dialog__body{
				padding: 15px;
				padding-bottom: 22px;
				.body-center{
					text-align: center;
					.msg{
						margin-top: 10px;
					}
				}
			}
		}
	}
	
</style>
