<template>
  <div class="step-list">
        <div class="step-item" v-for="(step,index) in stepList" :key="index" :class="{'active':stepIndex>index}">
            <div class="through-line">
                <div class="ball">
                    {{index+1}}
                </div>
            </div>
            <div class="step-text">
                {{step}}
            </div>
        </div>
  </div>
</template>

<script>
import {mapState} from "vuex"
export default {
   
    computed:{
        ...mapState(["stepList","stepIndex"])
    }
}

</script>

<style lang="scss">
.step-list{
    display: flex;
    
    >div{
        width: 160px;
        font-size: 14px;
        
        color: #AFD2FD;
        .through-line{
            background: #72B1FC;
            height: 6px;
            position: relative;
            .ball{
                background:#72B1FC;
                border-radius: 50%;
                width: 22px;
                height: 22px;
                color: #AFD2FD;
                line-height: 22px;
                text-align: center;
                transform: translate(-50%,-50%);
                position: absolute;
                top: 50%;
                left: 50%;

            }
        }
        .step-text{
            text-align: center;
            margin-top: 18px;
        }
    }
    .active{
        // color: $blue;
        color: #fff;
       
        .through-line{
            background: #B5D7FF;

            .ball{
                background:#B5D7FF;
                color: #fff;
            }
        }
    }
   
}
</style>