// 导入的方法的作用就是将 当前文件夹下的所有js文件里面的所有方法挂载到apis上并进行导出
import {deCentralization} from '../utils/index';
// 三个参数 
// 要搜索的文件夹目录
// 是否还应该搜索它的子目录，
// 以及一个匹配文件的正则表达式。  =>  匹配任意的js文件
const apis = deCentralization(require.context('./', true,/\w+\/\w+\.js/),/indexjs/);
/**
 * @param
 */
export default apis;