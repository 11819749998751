<template>
<div class="MoreUp" :style="{'background':backColor}">
    <!-- 将原有商品数组分为oneList 和showList 展示showList   
        当点击展开时，将原有数组赋值给showList 收起时 将oneList 赋值给showList 
        toggle(obj){
            console.log(obj)
            if(obj.flag){
                this.$set(obj.order,"showList",obj.order.goods_list)
            }else{
                this.$set(obj.order,"showList",obj.order.oneList)
            }
            
        },
    -->
     <div class="more">
            <i class="el-icon-d-arrow-right" :class="{'unfold':moreFlag}" @click="changeState"></i>
     </div>
</div>
</template>

<script>
export default {
    data(){
        return{
            moreFlag:true
        }
    },
    mounted(){
        // this.moreFlag = this.defaFlag
        // console.log("调用");
    },
    methods:{
        changeState(){
            this.moreFlag = !this.moreFlag
        }
    },
    props:{
        backColor:{
            default:"#fff"
        },
        order:{
            type:Object
        },
        defaFlag:{
            default:false
        }
    },
    watch:{
        moreFlag(flag){
            let obj = {
                order:this.order,
                flag
            }
            this.$emit("toggle",obj)
        }
    //   上面的使用方法
    //     toggle(obj){
    //         // console.log(obj)
    //         if(obj.flag){
    //             this.$set(obj.order,"showList",obj.order.goods)
    //         }else{
    //             this.$set(obj.order,"showList",obj.order.oneList)
    //         }
    //    },
    },
    computed:{
        tit(){
            return this.moreFlag?"收起":"展开更多"
        }
    },
    created(){
    }
}
</script>

<style lang="scss" scoped>
.MoreUp{
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
     .more{
         display: flex;
         justify-content: center;
         align-items: center;
         color: #fff;
         background: #3E84D6;
         padding: 1px 2px;
     }
     i{
        transform: rotate(90deg);
        cursor: pointer;
        transition: transform .3s;
     }
     .unfold{
        transform: rotate(270deg);
     }
}
</style>