import { post } from "axios"
import store from '../../../store'

const addAddr = async (data)=>{
    let res = await post("index.php?act=address&op=address_add",{
        member_id:store.state.member_id,
        ...data
    })
    return res
}
const delAddr = async (address_id)=>{
    let res = await post("index.php?act=address&op=address_del",{
        address_id
    })
    return res
}
const editAddr = async (data)=>{
    let res = await post("index.php?act=address&op=address_edit",{
        member_id:store.state.member_id,
        ...data
    })
    return res
}
const defaAddr = async (address_id)=>{
    let res = await post("index.php?act=address&op=address_default",{
        member_id:store.state.member_id,
        address_id
    })
    return res
}

const getAddrList = async ()=>{
    let list = await post("index.php?act=address&op=address_list",{
        member_id:store.state.member_id
    })
    return list
}

export {
    addAddr,
    delAddr,
    editAddr,
    defaAddr,
    getAddrList
}