<template>
<div class="init">
   <div class="title">
       我的收藏
   </div>
    <div class="enshrine" >
        <div class="change">
            <img src="../../assets/my/dianpu.png" alt="">
        </div>
        店铺收藏
    </div>
    <div class="details">
        <div class="sss" >
          <div class="store" v-for="(item,index) of collectList" :key="index" >
            <div class="select" v-if="isSelect">
              <el-checkbox  v-model="item.choose" ></el-checkbox>
            </div>
            <div class="store-img" @click.stop="goBus(item.store_id)">
              <img :src="item.store_label_url" >
            </div>
            <h3 @click.stop="goBus(item.store_id)">{{item.shop_name}}</h3 >
            <span @click.stop="goBus(item.store_id)" class="phone">电话:{{item.shop_contactphone}} </span>
            <span class="collect-btn" @click.stop="collect(item.log_id)">
              <img src="../../assets/my/redheart.png" alt="">
              <!-- <img v-show="!favorite" src="../../assets/my/redheart.png" alt=""> -->
              <!-- <img v-show="favorite" src="../../assets/my/greyheart.png" alt=""> -->
            </span>
          </div>
          
        </div>
    </div>
    <div class="cancel">
       <el-checkbox  v-model="allChoose" @change="allChange">全选</el-checkbox>
        <span  @click="delcancel('betch')">取消收藏</span>
    </div>
</div>
</template>

<script>
export default {
    data(){
        return{
          isSelect:false,
          allChoose: false,
          isIndeterminate: true,
          // favorite:false,
          collectList:[],
        }
    },
    methods:{
      goBus(store_id){
          this.$router.push(`/store/businesses/${store_id}`)
      },
      async collectArrList(){
          let res= await this.$api.getCollectList({
					member_id:this.$store.state.member_id,
        })
        this.collectList=res
        console.log("this.collectList",this.collectList);
      },


     async collect(log_id){

       this.$affMsg("确定要取消收藏吗?",async ()=>{
          let res= await this.$api.delCollect({
            log_id:log_id
          })
          // this.favorite=!this.favorite
          if(res){
            this.collectArrList()
            this.$suMsg("删除成功")
          }else{
            this.$erMsg("删除失败")
          }
			},()=>{
          this.$infoMsg("已取消")
      })
      },
      allChange(val){
			  this.collectList.forEach(item=>{
          this.$set(item,"choose",val
          
          
          )
        })
      this.isSelect=!this.isSelect
    },
    // 全选取消收藏
    delcancel(log_id){
      let logId = ""
      let tit = "确定要删除这个需求?"
      let ids = []
      if(log_id=='betch'){
        this.collectList.forEach(item=>{
            if(item.choose){
                logId +=item.log_id + ","
                ids.push(item.log_id)
            }
        })   
        tit= "确定要删除这些需求？"
    }else{
        logId = log_id + ""
        ids.push(log_id)
    }
    this.$confirm(tit, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
        })
        .then(async () => {
            this.$message({
                type: 'success',
                message: '删除成功!'
            });
            await this.$api.delCollect({
                log_id:logId
            })
            // await this.$http("index.php?act=store_demand&op=demand_del",{
            //     dem_id:demId
            // })
            
            let temp =  this.collectList.filter(item=>ids.indexOf(item.log_id)==-1)
            this.$set(this,"collectList",temp)
        })
        .catch(() => {
            this.$message({
                type: 'info',
                message: '已取消删除'
            });          
      });
    }
    },
  created(){
    this.collectArrList()
    },
    
    props:{
        
    }
}
</script>

<style lang="scss" scoped>
.init{
   .title{
       width: 1097px;
       height: 40px;
       background-color: #fff;
       line-height: 40px;
       padding-left: 10px;
       margin: 20px 0;
   } 
   .enshrine{
       width: 1097px;
       height: 56px;
       background-color: #fff;
       line-height: 56px;
       display: flex;
        margin-bottom: 10px;
       
       .change{
           width: 32px;
           height: 32px;
           background: #C3CCD3;
           border-radius:50%;
           text-align: center;
           line-height: 40px;
           margin: auto 12px;
       }
   }
   .details{
       width: 1096px;
      
      //  justify-content: space-between;
       .sss{
          display:flex;
       flex-wrap: wrap;
        // border: 1px solid #000;
        // width: 267px;
        // height: 160px;
        background-color: #f7f7f7;
        margin-bottom: 10px;
        .store{
          cursor:pointer;
        //   display:flex;
        // flex-wrap: wrap;
          width: 267px;
          height: 160px;
          background-color: #ffffff;
          margin:0 7px 10px 0;
         
          // box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
          .select{
            height: 20px;

          }
          .store-img{
            width: 267px;
            height: 79px;
            border-bottom: 1px solid #eee;
            >img{
              width: 100%;
              height: 100%;
            }
          }
          >h3{
            
            color: #666;
            margin: 20px 0 10px 0;
            padding: 0 10px;
          }
          >span{
            color: #666;
            // display: inline-flex;
            //  flex-wrap: nowrap;
            //  justify-content: space-between;
          }
          .phone{
            padding: 0 70px 0 10px;
          }
          .collect-btn{
            cursor: pointer;
          }
        }
       }
   }
   .cancel{
      cursor:pointer;
       width: 1096px;
       height: 72px;
       background-color: #fff;
       margin-top: 50px;
       line-height: 72px;
       padding-left: 10px;
       >span{
         color:#3E84D6;
         padding: 0 10px;
       }
   }
}
</style>