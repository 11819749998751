import { post } from "axios"
import store from '../../../store'
// 企业信息
const getCompanyInfo = async (data)=>{
    const res =  await post("index.php?act=store_info&op=store_info",{
        ...data
    })
    return res
}

// 企业信息编辑
const getRedact = async (data)=>{
    return await post("index.php?act=store_info&op=store_info_edit",{
        ...data
    })
    
}
// 退款原因列表
const getReasonList = async (data)=>{
    const res =  await post("index.php?act=store_refund&op=refund_reasonList",{
        ...data
    })
    return res
    
}
// 提交退款申请
const addRefund = async (data)=>{
    const res =  await post("index.php?act=store_refund&op=add_refund",{
        ...data
    })
    return res
    
}
// 退款订单详情
const getRefundOrdersInfo = async (data)=>{
    const res =  await post("index.php?act=store_refund&op=refundOrdersInfo",{
        ...data
    })
    return res
    
}
// 绑定手机号
const bindPhone = async (data) =>{
    const res = await post("index.php?act=login&op=BindingPhone",{
        ...data
    })
}


// 获取账号信息
const getAccMsg = async (data)=>{
    let res = await post("index.php?act=store_info&op=account_info",{
       ...data
    })
    return res
}

// 修改员工账号和密码
const editStaffPass = async (data) =>{
    let res = await post("index.php?act=login&op=staff_password_edit",{
        ...data
    })
    return res
}
// 修改密码
const editPass = async (data)=>{
    let res = await post("index.php?act=login&op=password_edit",{
        ...data
    })
    return res
}
// 修改头像
const editImage = async (data)=>{
    let res = await post("index.php?act=login&op=editImage",{
        ...data
    })
    return res
}


// 发布需求
const pubDemand = async (data)=>{
    let res = await post("index.php?act=store_demand&op=demandSave",{
        ...data
    })
    return res
}
const editDemand = async (data)=>{
    let res = await post("index.php?act=store_demand&op=demandEdit",{
        ...data
    })
    return res
}
const getDemandList = async (data)=>{
    let res = await post("index.php?act=web_index&op=demand_list",{
        ...data
    })
    return res
}
// 删除需求
const delDemand = async (data)=>{
    let res = await post("index.php?act=store_demand&op=demand_del",{
        ...data
    })
    return res
}
// 查看需求详情
const getDemandInfo = async (data)=>{
    let res = await post("index.php?act=store_demand&op=demand_info",{
        ...data
    })
    return res
}
// 回复
const verifyDemadn = async (data)=>{
    let res = await post("index.php?act=store_demand&op=demand_verify",{
        ...data
    })
}
// 需求详情
// const getDemandInfo = async (data)=>{
//     let res = await post("index.php?act=store_demand&op=demand_info",{
//         ...data
//     })
// }


// 我的消息
const getMessage= async (data)=>{
    let res = await post("index.php?act=message&op=messageList",{
        ...data
    })
    return res
}
//删除活动信息
const delMessage= async (data)=>{
    let res = await post("index.php?act=message&op=messageDel",{
        ...data
    })
    return res
}
// 物流消息
const wlMessage= async (data)=>{
    let res = await post("index.php?act=message&op=wlMessageList",{
        ...data
    })
    return res
}
//删除物流信息
const delWlMessage= async (data)=>{
    let res = await post("index.php?act=message&op=wlMessageDel",{
        ...data
    })
    return res
}
// 发票
const getInvoList = async ()=>{
    return await post("index.php?act=invoice&op=invoiceList",{
        member_id:store.state.member_id,
        store_id:store.state.store_id,
    })
}
const addInvo = async (data)=>{
    return await post("index.php?act=invoice&op=invoiceAdd",{
        member_id:store.state.member_id,
        store_id:store.state.store_id,
        ...data
    })
}
const editInvo = async (data)=>{
    return await post("index.php?act=invoice&op=invoiceEdit",{
        member_id:store.state.member_id,
        store_id:store.state.store_id,
        ...data
    })
}
const delInvo = async (inv_id)=>{
    return await post("index.php?act=invoice&op=invoiceDel",{
        inv_id  
    })
}
// 收票地址
const getRessList = async ()=>{
    return await post("index.php?act=address&op=ressList",{
        member_id:store.state.member_id,
        store_id:store.state.store_id,
    })
}
const addRess = async (data)=>{
    return await post("index.php?act=address&op=ressadd",{
        member_id:store.state.member_id,
        store_id:store.state.store_id,
        ...data
    })
}
const editRess = async (data)=>{
    return await post("index.php?act=address&op=ressedit",{
        member_id:store.state.member_id,
        store_id:store.state.store_id,
        ...data
    })
}
const delRess = async (id)=>{
    return await post("index.php?act=address&op=ressdel",{
        id  
    })
}
// 退货相关接口
// 退货搜索配件
const afitSearchsale = async (data)=>{
    return await post("index.php?act=afitsale&op=searchsale",{
        ...data  
    })
}
// 获取商品对应退货列表
const getRecordByReGoodsId = async (data)=>{
    return await post("index.php?act=afitsale&op=listDetails",{
        ...data  
    })
}
// 新增退货/换货配件
const addAfitsale = async (data)=>{
    return await post("index.php?act=afitsale&op=addsale",{
        ...data  
    })
}
// 退换货列表
const getAfitsList = async (data)=>{
    return await post("index.php?act=afitsale&op=list",{
        ...data  
    })
}
// 分仓退换货列表
const getBranchAfitsList = async (data)=>{
    
    return await post("index.php?act=afitsale&op=reslut_list",{
        ...data  
    })
}
// 退货详情
const getAfitsInfo = async (data)=>{
    return await post("index.php?act=afitsale&op=detile",{
        ...data  
    })
}


// 提交退货
const commitRetreat = async (data)=>{
    return await post("index.php?op=retreat&act=orders",{
        ...data  
    })
}
// 获取采购额度
const getPurQuota = async ()=>{
    return await post("index.php?act=orders&op=pay_info",{
        store_id:store.state.store_id
    })
}
// 添加物流
const addAfitsLogi = async (data)=>{
    return await post("index.php?act=afitsale&op=reslut_upload",{
        ...data  
    })
    
}
// 获取商品可退货数量
const getGoodsRenum = async (data)=>{
    return await post("index.php?act=afitsale&op=reslut_number",{
        ...data
    })
}
export {
    getRessList,
    addRess,
    editRess,
    delRess,
    getCompanyInfo,
    getRedact,
    bindPhone,
    getAccMsg,
    editPass,
    editStaffPass,
    pubDemand,
    editDemand,
    delDemand,
    getDemandInfo,
    getDemandList,
    verifyDemadn,
    getMessage,
    delMessage,
    getInvoList,
    addInvo,
    editInvo,
    delInvo,
    wlMessage,
    delWlMessage,
    getReasonList,
    addRefund,
    getRefundOrdersInfo,
    editImage,
    afitSearchsale,
    getRecordByReGoodsId,
    addAfitsale,
    getAfitsList,
    getBranchAfitsList,
    getAfitsInfo,
    addAfitsLogi,
    commitRetreat,
    getPurQuota,
    getGoodsRenum
}
