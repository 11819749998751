import  { post } from "axios"

const getCartNums = async ({state,commit})=>{ 
    let num = await post("index.php?act=store_cart&op=cartNum",{
        member_id:state.member_id ,
        shop_id:state.shop
    }) 
    num = Number(num)
    commit("UpDateState",{
        cartNums:num||0
    })
}
// 获取预购单是否到货
const getAdvCart = async ({state,commit})=>{
   if(state.token){
       
        let num = await post("index.php?act=store_cart&op=news",{
            member_id:state.member_id
        }) 
        num = Number(num)
        commit("UpDateState",{
            advCart:num||0
        })
   }
    
}
// 获取积分数量
const getInteNum = async ({state,commit})=>{
   
    let num = await post("index.php?act=integral&op=integral",{
        member_id:state.member_id,
        shop:state.for_branch||state.shop|| "1780",
        store_id:state.store_id
    })
    
    num = Number(num)
    commit("UpDateState",{
        integral_num:num||0
    })
}
// 获取提升比例

const getThan= async ({state,commit})=>{
   
    let thanRes = await post("index.php?act=integral&op=customer",{
        member_id:state.member_id,
    })
    
    let num = Number(thanRes.than)
    commit("UpDateState",{
        than:num||0
    })
}
// 获取vin查询次数
const getVinNum = async ({state,commit})=>{
    
    const res=  await post("index.php?act=store_info&op=getStoreVinNum",{
        store_id:state.store_id,
        member_id:state.member_id,
    })
    let vin_over_time = 0
    let nowTime = new Date().getTime()/1000
    
    if(res.cycle_over_time>nowTime){
        let leftTime = res.cycle_over_time - nowTime
       
        vin_over_time = Math.ceil(leftTime/(24*3600)) || 1
    }else{
        
    }
    if(res.vin_num_cycle){
       
        res.vin_num_ok =  Number(res.vin_num_cycle) + Number(res.vin_num_ok)
    }
    if(res){

        commit("UpDateState",{

            vinTotal:res.vin_num,
            vinNum:res.vin_num_ok,
            canVinSearch:res.is_epc,
            vin_over_time
        })
    }else{
        console.log("次数获取失败")
    }
    
}
// 扣除vin查询次数
const delVinNum = async ({state,dispatch})=>{
    const res=  await post("index.php?act=store_info&op=storeVinNumLow",{
        store_id:state.store_id
    })
    if(res){
        dispatch("getVinNum")
    }else{
       console.log("删除vin失败") 
    }
}

// 获取品牌整箱价比例降低
const getBrandRatioList = async ({state,commit})=>{
    const res=  await post("index.php?act=setting&op=brandPrice&mode=list",{
        store_id:state.store_id
    })
    console.log("品牌整箱",res);
    if(res){
        commit("UpDateState",{
            ratioBrandList:res.value||[],
        })
    }else{
        console.log("次数获取失败")
    }
}
const test = ({state},data)=>{
    console.log("state",state)
    console.log("data",data)
}
export default {
   getCartNums,
   getAdvCart,
   getVinNum,
   getThan,
   getInteNum,
   delVinNum,
   getBrandRatioList,
   test

}