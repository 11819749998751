<template>
<div class="novaSearch">
    <div class="search">
        <div class="left">
            <el-form :inline="true" :model="searchParams"  size="mini">
                <!-- <el-form-item label="订单日期范围：" v-if="showOption('timetype')&&ismyPur " >
                        <el-select v-model="searchParams.timetype" class="small-select">
                            <el-option label="最近三天" value="day"></el-option>
                            <el-option label="最近一周" value="week"></el-option>
                        </el-select>
                </el-form-item> -->

                <el-form-item label="订单日期" v-if="showOption('date')">
                    <el-date-picker
                        v-model="searchParams.date"
                        type="daterange"
                        range-separator="-"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        value-format="timestamp"
                        :unlink-panels="true"
                        @change="dateChange"
                        >
                    </el-date-picker>
                </el-form-item>

                <el-form-item label="订单编号" v-if="showOption('order_sn')">
                    <el-input v-model="searchParams.order_sn" class="sn-inbput"></el-input>
                </el-form-item>

                 <el-form-item label="处理结果" v-if="showOption('disposeOver')">
                    <el-select v-model="searchParams.disposeOver" placeholder="处理结果" class="small-select">
                    <el-option label="拒绝" value="0"></el-option>
                    <el-option label="同意" value="1"></el-option>
                   
                    </el-select>
                </el-form-item>
                 <el-form-item label="处理状态" v-if="showOption('disposeState')">
                    <el-select v-model="searchParams.disposeState" placeholder="处理状态" class="small-select">
                        <el-option label="未处理" value="0"></el-option>
                        <el-option label="已处理" value="1"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="企业账号" v-if="showOption('member_name')">
                    <el-input v-model="searchParams.member_name" placeholder="企业账号" ></el-input>
                </el-form-item>
                <el-form-item label="企业名称" v-if="showOption('company_name')">
                    <el-input v-model="searchParams.company_name" placeholder="企业名称" ></el-input>
                </el-form-item>
                <el-form-item label="企业类型" v-if="showOption('sc_id')">
                    <el-select v-model="searchParams.sc_id" placeholder="企业类型" class="small-select" >
                    <el-option :label="item.sc_name" :value="item.sc_id" v-for="(item,index) in comTypes" :key="index"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="审核状态" v-if="showOption('joinin_state')">
                    <el-select v-model="searchParams.joinin_state" placeholder="审核状态" class="small-select">
                    <el-option label="已通过" value="40"></el-option>
                    <el-option label="审核中" value="10"></el-option>
                    <el-option label="未通过" value="30"></el-option>
                    </el-select>
                </el-form-item>

                          
                <el-form-item label="OE号" v-if="showOption('oecode')">
                    <el-input  @keydown.enter.native="getList" v-model="searchParams.oecode"></el-input>
                </el-form-item>
                <el-form-item label="生产编码" v-if="showOption('sccode')">    
                    <el-input @keydown.enter.native="getList" v-model="searchParams.sccode"></el-input>
                </el-form-item>
                <el-form-item label="配件名称" v-if="showOption('goods_name')">
                    <el-input @keydown.enter.native="getList" v-model="searchParams.goods_name"></el-input>
                </el-form-item>

                 <!-- <el-form-item label="车型品牌" v-if="showOption('oecarbrand')">
                    <el-input v-model="searchParams.oecarbrand"></el-input>
                </el-form-item> -->

                <el-form-item label="配件品牌" v-if="showOption('oenaturebrand')">
                    <el-input @keydown.enter.native="getList" v-model="searchParams.oenaturebrand"></el-input>
                </el-form-item>
                <el-form-item label="配件类型" v-if="showOption('goods_type')">
                   <el-select v-model="searchParams.goods_type"  class="small-select">
                    </el-select>
                </el-form-item>
                <el-form-item label="仓库"  v-if="showOption('storage')">
                    <el-select v-model="searchParams.storage" class="small-select">
                    <el-option :label="ware.war_name" :value="ware.war_name" v-for="ware in  storageList" :key="ware.war_id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="清洗状态"  v-if="showOption('mateMode')">
                    <el-select v-model="searchParams.mateMode" class="small-select">
                        <el-option label="已清洗" value="1" ></el-option>
                        
                        <el-option label="未清洗" value="0" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="是否上架"  v-if="showOption('goods_state')">
                    <el-select v-model="searchParams.goods_state" class="small-select">
                        <el-option label="已上架" value="1" ></el-option>
                        <el-option label="未上架" value="0" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="库存数量" v-if="showOption('storage_snum')">
                    <el-select v-model="searchParams.storage_num"  class="small-select">
                    <el-option label="大于100" value="d100" ></el-option>
                    <el-option label="小于100" value="x100" ></el-option>
                    <el-option label="小于50" value="x50" ></el-option>
                
                    </el-select>
                </el-form-item>
                
                <el-form-item label="买家名称" v-if="showOption('buyer_name')">
                    <el-input v-model="searchParams.buyer_name"></el-input>
                </el-form-item>
                 <el-form-item label="卖家名称" v-if="showOption('store_name')">
                    <el-input v-model="searchParams.store_name"></el-input>
                </el-form-item>
                 <el-form-item label="库存数量" v-if="showOption('goods_storage')">
                    <el-input v-model="searchParams.goods_storage"></el-input>
                </el-form-item>
                <el-form-item label="仓库" v-if="showOption('war_id')">
                    <el-select v-model="searchParams.war_id" class="small-select">
                        <el-option :label="stor.war_name" :value="stor.war_id" v-for="stor in  storageList" :key="stor.war_id"></el-option>
                        
                    </el-select>
                </el-form-item >
                <el-form-item label="订单类型" v-if="showOption('order_type')">
                        <el-select v-model="searchParams.order_type" placeholder="类型"  class="small-select">
                        <el-option label="采购订单" value="1"></el-option>
                        <el-option label="联盟调货" value="2"></el-option>
                        <el-option label="销售下单" value="3"></el-option>
                        </el-select>
                </el-form-item>
                <el-form-item label="订单来源" v-if="showOption('order_from')">
                        <el-select v-model="searchParams.order_from" class="small-select">
                        <el-option label="采购订单" value="1"></el-option>
                        <el-option label="联盟调货" value="2"></el-option>
                        <el-option label="销售订单" value="3"></el-option>
                        <el-option label="电商订单" value="4"></el-option>
                        <el-option label="app订单" value="5"></el-option>
                        <el-option label="配件看板" value="6"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="审核状态" v-if="showOption('examine')">
                    <el-select v-model="searchParams.examine"  class="small-select">
                        
                        <el-option label="未审核" value="0"></el-option>
                        <el-option label="审核失败" value="-1"></el-option>
                        <el-option label="审核成功" value="1"></el-option>
                    </el-select>
                </el-form-item>
                
                <el-form-item v-if="showOption('timetype')">
                    <div class="short">
                        
                        <span :class="{'active':searchParams.timetype=='today'}" @click="getList('today')">今天</span>
                        <span :class="{'active':searchParams.timetype=='day'}" @click="getList('day')">最近三天</span>
                        <span :class="{'active':searchParams.timetype=='week'}" @click="getList('week')">本周内</span>
                        <span :class="{'active':searchParams.timetype=='lastweek'}" @click="getList('lastweek')">上周</span>
                    </div>
                </el-form-item>
            </el-form>
        </div>
        <div class="right">
            <el-button size="mini" type="primary" @click="getList">搜索</el-button>
            <el-button size="mini" @click="reset">清空</el-button>
        </div>
    </div>
</div>
</template>

<script>
export default {
    data(){
        return{
            // 所有的搜索参数
            searchParams:{
                // 时间
                date:[],
                // 时间范围，平台订单使用
                start_time:"",
                end_time:"",
                timetype:"",
                order_sn:"",
                oecode:"",
                goods_name:"",
                sccode:"",
                store_name:"",
                buyer_name:"",
                // 订单状态 发没发货 付没付款
                state_type:"",
                // 配件品牌
                oenaturebrand:"",
                // 仓库id
                war_id:"",
                order_from:"",
                storage:"",
                storage_num:"",
                oecarbrand:"",
                // 审核状态
                examine:"",
                goods_storage:"",
                // 订单类型
                order_type:"",
                mateMode:"",
                goods_state:""
            },

            comTypes:[
            ],
            // 这次是搜索是否包含该
            keys:[],
            // 仓库列表
            storageList:[]
        }
    },
    methods:{
        async getList(timetype){
            if(!this.api){
                return console.log("未填写api");
            }
            if(this.searchParams.date.length>0){
                this.searchParams.start_time = this.searchParams.date[0]/1000
                this.searchParams.end_time = this.searchParams.date[1]/1000
                
            }else{
                this.searchParams.start_time = ""
                this.searchParams.end_time = ""
            }
            
            let res = await this.$api[this.api]({
                store_id:this.$store.state.store_id,
                member_id:this.$store.state.member_id,
                page:this.searchData.page,
                limit:this.searchData.limit,
                sort_type:this.sort_type,
                sort_way:this.sort_way,
                oecode:this.searchParams.oecode,
                sccode:this.searchParams.sccode,
                goods_name:this.searchParams.goods_name,
                oenaturebrand:this.searchParams.oenaturebrand
            })
           
            this.$emit("searchEnd",res)
        },
        dateChange(){
            this.searchParams.timetype = ""
            this.$emit("changeTimeType","")
        },
        reset(){
           
            for(let key in this.searchParams){
                if(key!="curpage"&&key!="page"){
                    
                    this.searchParams[key] = ""
                }
            }
            
        },
       
        showOption(option){
            return this.keys.indexOf(option)!=-1
        }
    },
    async created(){
        
    },
    watch:{
        // 监听父组件的页码个个数变化
        "searchData.limit":{
            deep:true,
            handler(n){
                this.getList()
            }
        },
        "searchData.page":{
            deep:true,
            handler(n){
                this.getList()
            }
        },
        
    },
    props:{
        
        searchData:{
            type:Object,
            required:true
        },
        api:{
            type:String,
            required:true
        }
    },
    mounted(){
        
        this.keys = Object.keys(this.searchData)
    }
}
</script>

<style lang="scss" scoped>
$baInH:25px;
.novaSearch{
    .search{
        // padding-top: 10px;
        display: flex;
        align-items: center;
        height: 35px;
        padding-right: 2px;
        padding-left: 8px;
        box-sizing: border-box;
        ::v-deep .el-form--inline{
            .el-form-item{  
                    // margin-bottom: 8px;
                    margin-bottom: 5px;
                    // margin-bottom: 10px;
                    // 为了看起来像垂直居中
                    position: relative;
                    top: 2px;
                .el-form-item__label{
                    background: #ccc;
                    padding: 0 6px!important; 
                    height: $baInH!important; 
                    line-height: $baInH!important;
                    font-size: 12px !important;
                    transform: translateY(.5px);
                    text-align: center;
                }
                .el-form-item__content{
                    height: $baInH;
                    line-height: $baInH;
                    .el-input{
                        width: 90px;
                        height: $baInH;
                        input{
                            padding-right: 0;
                            padding-left: 4px;
                            height: $baInH;
                            border-radius: 0;
                        }
                    }
                    .groupOe{
                        width: 140px;
                    }
                    // 选择框的
                    .small-select{
                        width: 120px;
                        height: $baInH;
                        .el-input{
                            width: 120px;
                        }
                        .el-input__inner{
                            border-radius: 0;
                            height: $baInH;
                        }
                            i{
                            line-height: normal;
                            position: relative;
                            top: -1px;
                        }
                    }
                    // 日期的
                    .el-date-editor{
                        width: 240px;
                        height: $baInH;
                        padding: 1px 10px;
                        border-radius: 0;
                        .el-range__icon{
                            line-height: normal;
                        }
                        
                        .el-range__close-icon{
                            line-height: normal;
                        }
                        
                        .el-range-separator{
                            line-height: normal;
                        }
                    }
                    // 快捷日期的
                    .short{
                        span{
                            margin: 0 10px;
                            cursor: pointer;
                            font-size: 12px;
                        }
                        .active{
                            color: #3E84D6;
                        }
                    }
                    .el-button{
                        height: 22px !important;
                        line-height: normal !important;
                        padding: 0 15px !important;
                    }
                }
            }
            
        }
        .right{
            display: flex;
            >div{
                flex: 1;
            }
            .el-button{
                height: 25px;
                line-height: normal;
                padding: 0 15px;
            }
            
        }
    }
}
</style>