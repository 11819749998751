<template>
<!-- 爆炸图加商品列表页 -->
<div class="scd-epc-img-list"  v-loading="epcimgLoading">
    <div class="part-img-box">
        <slot name="img-handle">
        </slot>       
        <PartImg 
            :partImgSty="partImgSty"
            :imgSrc="imgSrc" 
            :hotspots="hotspots"
            @amplify="amplify"  
            @acDotChange="acDotChange" 
            v-if="showImg"
        >
        </PartImg>
    </div>

    <div class="part-list">
        <div class="goods-list-box">  
            <VinGoodsList 
                ref="vinGoodsList"
                :carInfo="carInfo"
                :partdatas="partdatas"
                :goodsList="epcPartList"  
                :manufacturer_id="manufacturer_id"
                :listFilter="listFilter" 
                :brandCode="brandCode" 
                :activePnum="activePnum" 
                :activeCode="activeCode" 
                @rowClick="rowClick" 
                @showRefmid="showRefmid"
                v-if="showEpcPart"
            ></VinGoodsList>
        </div>
    </div>   
</div>
</template>

<script>
import VinGoodsList from "./VinGoodsList"
import PartImg from "./PartImg"


export default {
    data(){
        return{
            showImg:false,
            showEpcPart:false,
            epcIndex:0,
            partsName:"",
            partImgSty:{
                width:"28rem"
            },
            imgSrc:"",
            hotspots:[],
            partList:[],
          
            drawer:false,
            drawerNum:1,
            epcPartList:[],
            searchType:"1",
            
            goodsList:[],
    
            activePnum:"",
            activeCode:"",
            dot:{},
            vin:"",
            brandCode:"",
            manufacturer_id:"",
            ispop:false,
            configKeys:"",
            allConfig:{},
            hideHandle:false,
            epcimgLoading:false,
            image_lock_flag:""
        }
    },
    methods:{
        amplify(isAmp){
            if(isAmp){
                this.partImgSty.width = "20rem"
            }else{
                this.partImgSty.width = "28rem"
            }
        },
        rowClick(pots_number){
            this.hotspots.forEach(dot=>{
                if(dot.pots_number==pots_number){
                    dot.active = true
                }else{
                    dot.active = false
                }

            })
        },

        acDotChange(pots_number){
            this.activePnum = pots_number
            this.hotspots.forEach(dot=>{

                if(dot.pots_number==pots_number){
                    dot.active = true
                }else{
                    dot.active = false
                }

            })
            
        },
        async querySearch(str,cb){
            cb([])
        },
        async epcSearch(part){
            
            this.epcimgLoading = true
            this.showEpcPart = false
           
            if(part.pots_number){

                // this.acDotChange(part.pots_number)
                this.activePnum = part.pots_number
                this.activeCode = part.part_number
            }else{
                this.activePnum = ""
            }
            
            this.image_lock_flag = part.lock_flag
            let res = await this.$apiScd.vinPartSearch({
                ...part,
                image_lock_flag:part.lock_flag
            })
            this.manufacturer_id = part.manufacturer_id
            this.vin = part.vin
            this.imgSrc = part.image_url
           
            this.epcPartList = res 
            if(res&&res.length>0){
                this.hotspots  =  this.epcPartList.map(item=>{
                    // \n 替换
                    if(item.part_name){
                        // replace(/\e/g,'0')
                        item.part_name = item.part_name.replace(/\n/g,'</br>')
                    }
                    if(item.part_memo){
                        item.part_memo = item.part_memo.replace(/\n/g,'</br>')
                    }
                    if(item.part_number){
                        item.part_number = item.part_number.replace(/\n/g,'</br>')
                    }
                    if(item.descmodel){
                        item.descmodel = item.descmodel.replace(/\n/g,'</br>')
                    }
                    this.$set(item,"active",false)
                    if(item.jump_flag==1&&item.part_memo){

                        let partMemoArr = item.part_memo.split("</br>")
                        item.partMemoArr = []
                        partMemoArr.forEach(mono=>{
                            if(mono){
                                mono = mono.replace(/<\/br>/g,"")
                                item.partMemoArr.push(mono)
                            }
                        })
                        
                    }
                    let dot = {}
                    let dots = item.coordinates.map(dot => {
                        return {
                            left:dot.pots_x1+'px',
                            top:dot.pots_y1+'px',
                            width:(dot.pots_x2 -  dot.pots_x1)==0?'100px':(dot.pots_x2 -  dot.pots_x1 + "px"),
                            height:(dot.pots_y2 -  dot.pots_y1)==0?'100px':(dot.pots_y2 -  dot.pots_y1 + "px")
                            // width:dot.pots_x2 -  dot.pots_x1 + "px",
                            // height:dot.pots_y2 -  dot.pots_y1 + "px",
                        }
                    }) 

                    dot.left = item.coordinates[0].pots_x1 + "px"
                    dot.top = item.coordinates[0].pots_y1 + "px"
                    dot.width = (item.coordinates[0].pots_x2 -  item.coordinates[0].pots_x1)==0?'100px':(item.coordinates[0].pots_x2 -  item.coordinates[0].pots_x1 + "px")
                    dot.height = (item.coordinates[0].pots_y2 -  item.coordinates[0].pots_y1)==0?'100px':(item.coordinates[0].pots_y2 -  item.coordinates[0].pots_y1 + "px")
                    // dot.width = item.coordinates[0].pots_x2 -  item.coordinates[0].pots_x1 + "px"
                    // dot.height = item.coordinates[0].pots_y2 -  item.coordinates[0].pots_y1 + "px"


                    this.$set(dot,"active",false)
                    
                    return {
                        ...item.coordinates[0],
                        ...dot,
                        dots,
                        pots_number:item.pots_number
                    }
                })
                let tempPost = [] 
                this.hotspots.forEach(item2=>{ 
                    let obj = tempPost.find(pos=>pos.pots_number==item2.pots_number)
                    if(obj){

                    }else{
                        tempPost.push(item2)
                    }
                })
                
                this.hotspots = tempPost
                this.epcimgLoading = false
                this.showImg = true
                this.showEpcPart = true
                if(part.part_number){ 
                    setTimeout(() => {
                        this.$refs.vinGoodsList.changePart(part.part_number)
                    },50)
                }
            }else{
                // this.$warMsg("详情获取失败")
                this.epcimgLoading = false
                this.epcPartList = []
                this.imgSrc = ''
            }
        },
        reback(){
            let list = this.$store.state.epcNavList.slice(0,this.$store.state.epcNavList.length-1)
            this.$store.commit("UpDateState",{
                epcNavList:list
            })
            if(this.$store.state.epvPreNav){
                this.$router.push(this.$store.state.epvPreNav)
            }else{
                this.$router.go(-1)
            }
        },
        async oeSearch(){
            this.$refs.vinforname.partsName = this.partsName
            this.hideHandle = false
            this.$refs.vinforname.oeSearch()
            
        },
        
        // 获取配置
        async showConfig(){
            this.drawer = true
            let res = await this.$api007.vinSearch(this.$route.params.vin)
            if(res.data.maindata){
                this.allConfig = {
                    ...res.data.maindata
                }
            }
            if(res.data.subdata){
                this.allConfig = {
                    ...this.allConfig,
                    ...res.data.subdata
                }
            }
        },
        // 展示跳转配件

        async showRefmid(jumpObj){
           
            let jumpList = await this.$apiScd.getPartJump({
                ...this.partInfo,
                ...jumpObj
            })
            jumpList.forEach(part=>{
                part.lock_flag = this.image_lock_flag
            })
            if(jumpList&&jumpList){
                this.$emit("readJump",{
                    jumpList,
                    jumpitem:jumpObj.jumpitem,
                    tapIndex:jumpObj.tapIndex
                })
                // this.$emit("readJump",jumpList)
               
            }else{
                this.$warMsg("跳转失败!")
            }
        }
    },
    async created(){ 
        this.partImgSty.height = document.documentElement.clientHeight -240 + "px"
    },
    watch:{
        partInfo(n){ 
            if(n&&n.manufacturer_id){
                this.epcSearch(n)
            }
        }
    },
    components:{
        VinGoodsList,
        PartImg
    },
    props:{
        partdatas:{
            require:true
        },
        partInfo:{
            required:true
        },
        imgList:{
            required:true
        },
        listFilter:true,
        carInfo:{
            required:true,
            default(){
                return{

                }
            }
        } 
    }
}
</script>

<style lang="scss">
.scd-epc-img-list{
    display: flex;
    height: 100%;
    background: #F4F6F7;
    padding: 0 10px;
    box-sizing: border-box;
    .btn{
        color: #fff;
        cursor: pointer;
        background: #3158C6;
        width: 86px;
        height: 28px;
        display: flex;
        justify-content: center;
        align-items: center;
        i{
            margin-right: 5px;
        }
    }
    .part-img-box{
        
    }
    .part-list{
        margin-left: 10px;
        flex: 1;
        overflow: auto;
        position: relative;
        background: #fff;
        display: flex;
        flex-direction: column;
        
        .goods-list-box{
            position: relative;
            flex: 1;
        }
    }
    .config-content{
        
        .config-search{
            padding: 10px;
            box-shadow: 0 2px 4px 0 #ccc;
        }
        .config-list{
            height: calc(100% - 92px);
            padding-top: 5px;
            overflow: auto;
            .config-item{
                display: flex;
                border-bottom: 1px solid rgba(0,0,0,.09);
                padding: 5px 0;
                margin: 0 10px;
                line-height: 22px;
                .config-label{
                    position: relative;
                    width: 40%;
                    min-width: 100px;
                    white-space: nowrap;
                    overflow: hidden; //超出的文本隐藏
                    text-overflow: ellipsis; //溢出用省略号显示
                    &:after {
                        content: "";
                        position: absolute;
                        top: 0;
                        right: 0;
                        width: 1px;
                        height: 22px;
                        background-color: #ddd;
                    }
                }
                .config-value{
                    width: 60%;
                    padding-left: 10px;
                    white-space: nowrap;
                    overflow: hidden; //超出的文本隐藏
                    text-overflow: ellipsis; //溢出用省略号显示
                }
            }
        }
    }
}
</style>