<template>
  <!-- 精准找件页 -->
  <div class="epc-Vin-Goods">
    <!-- 分组列表 -->
    <div class="search-by-key">
      <el-input
        v-model="searchValue"
        placeholder="请输入内容"
        class="input-with-select"
        size="mini"
        clearable
      >
        <!-- <el-select v-model="searchKey" slot="prepend" placeholder="请选择">
          <el-option
            v-for="(param, index) in prammsList"
            :key="index"
            :label="param.title"
            :value="param.key"
          >
          </el-option>
        </el-select> -->
      </el-input>
      <el-button type="primary" @click="fliterByKey" size="mini"
        >查询</el-button
      >
      <el-button type="info" @click="reset" size="mini">清空</el-button>
    </div>
    <div class="group-list">
      <el-button size="mini" type="primary" @click="$router.go(-1)">
        返回
      </el-button>
      <template v-for="(item, index) in vinPartList">
        <div
          class="group-item"
          :class="{ 'mg-active': activeTit == item.oesort }"
          :key="index"
          @click="changeShowPart(item)"
        >
          {{ item.oesort }}
          <div class="msg-num">({{ item.goodsList.length }})</div>
        </div>
      </template>
    </div>
    <el-table
      v-loading="loading"
      :data="goodsList"
      border
      :height="maxH"
      style="width: 100%"
      header-cell-class-name="ta-head"
    >
      <el-table-column fixed prop="oecode" label="OE号"> </el-table-column>
      <el-table-column prop="goods_name" label="商品名称"> </el-table-column>
      <el-table-column prop="sccode" label="生产编码"> </el-table-column>
      <el-table-column prop="oenaturebrand" label="配件品牌" width="120">
      </el-table-column>

      <el-table-column prop="oesort" label="配件分类" width="120">
      </el-table-column>

      <el-table-column prop="spec" label="规格" width="120"> </el-table-column>

      <el-table-column prop="isPep" label="是否是互换件" width="120">
      </el-table-column>

      <el-table-column prop="goods_storage" label="库存" width="120">
        <template slot-scope="scope">
          <template v-if="!$store.state.member_id.includes('1780-')">
            <span v-if="$store.state.look_inv == 1">{{
              scope.row.goods_storage
            }}</span>
            <span v-else-if="$store.state.shop == 1948">有货</span>
            <span v-else>{{scope.row.goods_storage > 0 ? "有货" : "无货"}}</span>
          </template>

          <div style="font-size: 12px;color: red;" v-if="$store.state.member_id.indexOf('1780-')!=-1"> 
            <div>上海:
              <span v-if="$store.state.look_inv==1">{{scope.row.sh_storage}}</span>
              <span v-else>{{scope.row.sh_storage>0?"有货":"无货"}}</span> 
            </div>
            <div>丹阳:
              <span v-if="$store.state.look_inv==1">{{scope.row.dy_storage}}</span>
              <span v-else>{{scope.row.dy_storage>0?"有货":"无货"}}</span> 
            </div>
            <div @click="openAll(scope.row)" style="cursor: pointer;margin: 10px 0;"><span style="padding: 2px;color: #59a8f8;border: 1px solid #59a8f8;">货位详情</span></div>
          </div>
          <div v-else style="font-size: 12px; color: red">{{ scope.row.cangTip }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="goods_price" label="价格" width="120">
        <template slot-scope="scope">
          <span v-if="$store.state.shop == 1948"> 价格面议 </span>
          <span v-else>
            {{ scope.row.goods_price }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="购买数量" align="center" width="160">
        <template slot-scope="scope">
          <el-input-number
            v-model="scope.row.goods_num"
            :min="1"
            size="mini"
            v-if="$store.state.shop == 1948"
          ></el-input-number>
          <el-input-number
            v-model="scope.row.goods_num"
            :min="1"
            :max="
              scope.row.goods_storage * 1 > 0
                ? scope.row.goods_storage * 1
                : 9999999999
            "
            size="mini"
            v-else
          ></el-input-number>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="100">
        <template slot-scope="scope">
          <div
            class="buy-btn"
            @click="joinCart(scope.row)"
            v-if="$store.state.shop == 1948"
          >
            <!-- {{Number(scope.row.goods_storage)>0?'加入购物车':'加入预购单'}} -->
            加入购物车
          </div>
          <div class="buy-btn" @click="joinCart(scope.row)" v-else>
            {{
              Number(scope.row.goods_storage) > 0 ? "加入购物车" : "加入预购单"
            }}
          </div>
          <!-- <el-button  @click="joinCart(scope.row)" type="text" size="small"></el-button> -->
        </template>
      </el-table-column>
    </el-table>
    <!-- 货位详情 -->
    <el-dialog
          title="货位详情"
          :visible.sync="openAllotion"
          width="50%"
          :before-close="handleClose">
          
          <div class="alltion">
            <div>OE号</div>
            <div>仓库</div>
            <div>货位</div>
            <div>库存</div>
        </div>

        <template v-if="goodsAllotion.dy.list.length>0">
            <div class="alltion">
            <div style="width: 100%;">丹阳</div>
            </div>
            <div class="alltion" v-for="(item) in goodsAllotion.dy.list" :key="item.shelfId">
            <div class="item">{{item.code}}</div>
            <div class="item">{{item.warehouseName}}</div>
            <div class="item">{{item.shelfName}}</div>
            <div class="item">{{item.shelfStockNum}}</div>
            </div>
        </template>
        <template v-if="goodsAllotion.sh.list.length>0">
            <div class="alltion">
            <div style="width: 100%;">上海</div>
            </div>
            <div class="alltion" v-for="(item) in goodsAllotion.sh.list" :key="item.shelfId">
            <div class="item">{{item.code}}</div>
            <div class="item">{{item.warehouseName}}</div>
            <div class="item">{{item.shelfName}}</div>
            <div class="item">{{item.shelfStockNum}}</div>
            </div>
        </template>
          <span slot="footer" class="dialog-footer">
            <el-button size="mini" @click="openAllotion = false">取 消</el-button>
            <el-button size="mini" type="primary" @click="openAllotion = false">确 定</el-button>
          </span>
      </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      vinCode: "",
      brandCode: "",
      mcid: "",
      vinPartList: [],
      comVinPartList: [],
      vin: [],
      mainGroupList: [],
      activeTit: 0,
      carInfo: {},
      goodsList: [],
      loading: true,
      maxH: 0,
      searchKey: "",
      searchValue: "",
      openAllotion:false,//货位详情
      goodsAllotion:{
          dy:{
              title:"丹阳",
              list:[]
          },
          sh:{
              title:"上海",
              list:[]
          }
      },
      prammsList: [
        {
          title: "OE号",
          key: "oecode",
        },
        {
          title: "商品名称",
          key: "goods_name",
        },
        {
          title: "生产编码",
          key: "sccode",
        },
        {
          title: "配件品牌",
          key: "oenaturebrand",
        },
      ],
    };
  },
  methods: {
    async openAll(data){  
      // 显示货位
    if(this.$store.state.member_id.indexOf('1780-')!=-1){ 
      let allotion = await this.$http(`${this.$axios.defaults.baseURL.replace("web",'public')}index.php?act=search&op=stockInfo`,{skuId:data.skuId})
        this.goodsAllotion = this.$hnwTools.getAllotion(allotion) 
      } 
      this.openAllotion= true   
    },
    handleClose(done) {
      done();
      },
    async joinCart(goods) { 
      if (this.$store.state.shop == 1948) {
        if (goods.goods_storage == 0 && this.$store.state.shop == 1948) {
          goods.goods_storage = 1;
        }
        let carMessage = {
          goods_id: goods.goods_id,
          sccode: goods.sccode,
          oecode: goods.oecode,
          goods_name: goods.goods_name,
          goods_price: goods.goods_price,
          goods_num: goods.goods_num,
          goods_storage: goods.goods_storage,
          buyer_id: this.$store.state.member_id,
          order_name: "",
          member_id: this.$store.state.member_id,
          buyer_name: this.$store.state.member_name,
          store_id: goods.store_id,
          store_name: goods.store_name,
          cart_type: "1",
        };

        await this.$api.joinCart([carMessage]);
      } else if (this.$isCompany()) {
        if (this.$store.state.zero_buy != 1) {
          if (goods.goods_storage < 1) {
            return this.$store.commit("UpDateState", { invDia: true });
          } else if (
            goods.goods_num + Number(goods.cartNum) >
            goods.goods_storage
          ) {
            return this.$store.commit("UpDateState", { invDia: true });
          }
        }

        let carMessage = {
          goods_id: goods.goods_id,
          sccode: goods.sccode,
          oecode: goods.oecode,
          goods_name: goods.goods_name,
          goods_price: goods.goods_price,
          goods_num: goods.goods_num,
          goods_storage: goods.goods_storage,
          buyer_id: this.$store.state.member_id,
          order_name: "",
          member_id: this.$store.state.member_id,
          buyer_name: this.$store.state.member_name,
          store_id: goods.store_id,
          store_name: goods.store_name,
          cart_type: "1",
        };

        await this.$api.joinCart([carMessage]);
      }
    },
    changeShowPart(item) {
      this.activeTit = item.oesort;
      this.goodsList = item.goodsList;
    },
    async fliterByKey() {
      // console.log("1223")
      // return
      this.activeTit = "全部";
      if (this.searchValue == "") {
        this.vinPartList = this.comVinPartList.map((part) => part);
      } else {
        // if (!this.searchKey) {
        //   return this.$warMsg("请选择搜索类型");
        // }
        this.vinPartList = [];
        // 获取品牌别名
        let aliasRes = await this.$api.getBrandAliasName({
          limit: "99",
        }); 
        let brand = aliasRes.data.filter(item => {
          return item.brand.includes(this.searchValue)||item.like_name.includes(this.searchValue)
        }) 
        if(brand.length!=0){
          console.log("存在别名")
          let temp = brand[0].brand
          this.comVinPartList.forEach((titObj) => {
            let list = titObj.goodsList.filter((goods) => { 
              return goods.oenaturebrand.includes(temp);
            }) 
            if (list.length > 0) {
              this.vinPartList.push({
                oesort: titObj.oesort,
                goodsList: list,
              });
            }
          })
        }else{
          let Hanzi = /[\u4E00-\u9FA5]/g.test(this.searchValue)
          if(Hanzi){
            console.log("存在汉字")
            let keyWord = await this.$api.getKeyWordName({
              keywords:this.searchValue
            })   
            if(keyWord.length!=0&&keyWord[0].another_name.includes(this.searchValue)){
              console.log("关键词")
              this.comVinPartList.forEach((titObj) => {
                let list = titObj.goodsList.filter((goods) => { 
                  return goods.goods_name.includes(keyWord[0].seven_name)||goods.oenaturebrand.includes(keyWord[0].seven_name);
                }) 
                if (list.length > 0) {
                  this.vinPartList.push({
                    oesort: titObj.oesort,
                    goodsList: list,
                  });
                }
              })
            }else{
              console.log("不存在关键词")
              this.comVinPartList.forEach((titObj) => {
                let list = titObj.goodsList.filter((goods) => { 
                  return goods.goods_name.includes(this.searchValue)||goods.oenaturebrand.includes(this.searchValue);
                }) 
                if (list.length > 0) {
                  this.vinPartList.push({
                    oesort: titObj.oesort,
                    goodsList: list,
                  });
                }
              })
            }
             
          }else{ 
            console.log("数字，英文")
            this.comVinPartList.forEach((titObj) => {
              let list = titObj.goodsList.filter((goods) => { 
                return goods.oecode.includes(this.searchValue)||goods.sccode.includes(this.searchValue);
              }) 
              if (list.length > 0) {
                this.vinPartList.push({
                  oesort: titObj.oesort,
                  goodsList: list,
                });
              }
            }) 
          }
        }
         
        
        // this.vinPartList = [];
        // this.comVinPartList.forEach((titObj) => {
        //   let list = titObj.goodsList.filter((goods) => {
        //     if (goods[this.searchKey]) {
        //       return goods[this.searchKey].includes(this.searchValue);
        //     } else {
        //       return false;
        //     }
        //   });

        //   if (list.length > 0) {
        //     this.vinPartList.push({
        //       oesort: titObj.oesort,
        //       goodsList: list,
        //     });
        //   }
        // });
        if (this.vinPartList[0]) {
          this.goodsList = this.vinPartList[0].goodsList;
        } else {
          this.goodsList = [];
        }
      }
    },
    reset() {
      this.searchKey = "";
      this.searchValue = "";
      this.searchValue = "";
      this.vinPartList = this.comVinPartList.map((part) => part);
      this.activeTit = "全部";
      this.goodsList = this.vinPartList[0].goodsList;
    },
  },
  async created() { 
    this.maxH = document.documentElement.clientHeight - 220;
    let carInfo = localStorage.getItem("hnw-epc-carInfo");
    carInfo = JSON.parse(carInfo);
    let res = await this.$apiScd.getCustomVinSearch({
      manufacturer_id: carInfo.manufacturer_id,
      vin: carInfo.vin,
      brand_id: carInfo.brand_id,
      series_id: carInfo.series_id,
      models_id: carInfo.models_id,
      production_year: carInfo.production_year,
    });

    if (res) {
      let oeList = res.map((item) => item.part_number);
      oeList = [...new Set(oeList)];
      let hnwGoodsRes = await this.$api.getVinPartsByOes({
        oeList: oeList.join(","),
        for_branch: this.$store.state.for_branch,
        shop: this.$store.state.shop,
      });
       
      let goodsList = [];
      if (hnwGoodsRes.data) {
        hnwGoodsRes.data.forEach((goods) => {
          this.$set(goods, "isPep", "否");
        });
        goodsList = [...hnwGoodsRes.data];
      }
      if (hnwGoodsRes.exchange) {
        hnwGoodsRes.exchange.forEach((goods) => {
          this.$set(goods, "isPep", "是");
          let g2 = goodsList.find((g1) => g1.goods_id == goods.goods_id);
          if (!g2) {
            goodsList.push(goods);
          } else {
            // console.log("存在重复商品", g2);
          }
        });
      }
      goodsList.forEach((goods) => {
        this.$set(goods, "goods_num", 1);
        if (!goods.oesort) {
          goods.oesort = "其他";
        }
        if (goods.store_id != 1780) {
          this.$handleStorePrice(goods);

          this.$hnwTools.formatGoodsKey(goods);
        }
      });

      this.vinPartList = [
        {
          oesort: "全部",
          goodsList,
        },
      ];

      goodsList.forEach((goods) => {
        let obj = this.vinPartList.find(
          (oesorts) => oesorts.oesort == goods.oesort
        );
        if (obj) {
          obj.goodsList.push(goods);
        } else {
          this.vinPartList.push({
            oesort: goods.oesort,
            goodsList: [goods],
          });
        }
      });

      this.comVinPartList = this.vinPartList.map((part) => part);
      this.goodsList = goodsList.map((part) => part);
      this.loading = false; 
    } else {
      this.$warMsg("查找失败");
      this.loading = false;
      // this.$router.go(-1)
    }
    // 分仓
      let indexRes = [];
      let houseInfo = [];
      if (this.$store.state.shop == 1780) {
        // this.goodsList = this.goodsList.filter(item => item.house_info!=null)
        // Cang
        this.goodsList.forEach((goods) => {
          this.$storageCangInfo(goods)
        }); 
      }
     
  },
};
</script>

<style lang="scss">
.epc-Vin-Goods {
  padding: 10px 20px;
  background: #f7f7f7;
  .search-by-key {
    width: 30%;
    display: flex;
    padding-bottom: 10px;
    .el-input-group {
      width: 300px;
      .el-input-group__prepend {
        width: 100px;
      }
      > .el-input__inner {
        width: 200px;
      }
    }
    .el-button {
      margin-left: 10px;
    }
  }
  .group-list {
    display: flex;
    align-items: center;
    background: #fff;
    flex-wrap: wrap;
    padding: 10px 0;
    .el-button {
      margin-right: 5px;
    }
    .group-item {
      padding: 0 5px;
      height: 28px;
      line-height: 28px;
      display: flex;
      cursor: pointer;
      border-right: 1px solid #ebeef5;
      // border-bottom: 1px  solid #EBEEF5;
      .msg-num {
        padding-left: 10px;
      }
    }
    .mg-active {
      background: $vin-blue;
      color: #fff;
      border-right: none;
    }
  }
  .ta-head {
    background: #fcfcfc;
    color: $vin-blue;
  }
  .buy-btn {
    color: #ff7519;
    cursor: pointer;
  }
}
.alltion{
  width: 100%;
  display: flex; 
  align-items: center;
  height: 40px;
  border-bottom: 1px solid #eee;
  >div{
    width: 25%;
    text-align: center; 
  }
}
</style>