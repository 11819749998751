<template>
    <div class="dis-goods-list">
        <div class="goods-list  hnw-area-center">
            <el-row  :gutter="8">
                <el-col :xs="12" :sm="12" :lg="{span: '4-8'}" v-for="(item,index) in storeGoodsList" :key="index" class="goods-col">

                    <div class="dis-good-tips">
                        {{ item.roi*10 }}折
                    </div>
                    <div class="goods-card" @click="goRecommend(item)">
                        <div class="img-box" >
                            <!-- <img :src="item.homeImg||item.goods_image" alt=""> -->
                            <!-- <img :src="item.homeImg" alt=""> -->
                            <HnwOssImg :src="item.homeImg||item.goods_image"></HnwOssImg>
                        </div>
                        <div class="card-fot">
                            <div class="fot-bot-price" >
                                <div class="showPrice blue" v-if="!$store.state.member_id" >
                                    会员可见
                                </div>
                                <div v-else class="showPrice">
                                    <div style="font-size:14px; color:#FF2020; padding-top: 8px;">￥</div>
                                    <div class="Price">{{item.goods_marketprice}}</div>
                                    <div class="salePrice">￥{{item.goods_price}}</div> 
                                </div>
                            </div>
                            <div class="fot-top">
                                <span class="goods-name">{{item.goods_name}}</span>
                                <div class="joinCart" @click.stop="joinCart(item)">
                                    <img src="../../assets/index/shop.png" alt="">
                                </div>
                            </div>
                            <div class="fot-bot">
                                <div class="fot-right">
                                    <div class="store-name" >
                                        {{item.oenaturebrand}}
                                    </div>
                                    <div class="store-oe">
                                        {{item.oecode}}
                                    </div>
                                </div> 
                                <div class="store-inventory">
                                    <span v-if="$store.state.look_inv==1">还剩{{item.goods_storage}}</span>
                                    <span v-else>{{item.goods_storage>0?"有货":"无货"}}</span>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </el-col>
                
            </el-row>
        </div>
    </div>
    </template>
    
    <script>
    import HnwOssImg from "../../components/hnwOssImg.vue";
    export default {
        data(){
            return{
                storeGoodsList:[]
            }
        },
        methods:{
            goRecommend(acti){
				
				if(this.$isCompany()){
					acti.preRou = "首页"
					let rou = '/index/goodsinfo'
					localStorage.setItem("hnw-web-item-data",JSON.stringify(acti))
					
					this.$router.push(rou)
				}
			}
        },
        async created(){
            // 推荐
			let res  = await this.$api.getDisList({
				id:"all",
                type:1,
                page:1,
				limit:999
			})
            if(res.db.length>0){
				let goods_id = res.db.map(item=>item.goods_id).join(",")
				let listimgs = await this.$api.getGoodsListHomeImg({
					goods_id,
					goods_type: "折扣首图",
					shop_id:1780,
					store_id:1780 
				}) 
				listimgs.forEach(item=>{
					res.db.forEach(goods=>{
						if(goods.goods_id==item.goods_id){
							this.$set(goods,"homeImg",item.url)
						}
					})
				})
			}
            this.storeGoodsList = res.db
        },
        components:{
			HnwOssImg
		},
        props:{
            
        }
    }
    </script>
    
    <style lang="scss">
    .dis-goods-list{
        padding: 10px 0;
        background: #F7F7F7;
        .goods-list{
            background: $backGray;
            .el-col-lg-4-8 {
                width: 20%;
            }
            .goods-list-content{
                display: flex; 
                position: relative;
                .brand-list{
                    width: 234px;
                    border: 1px solid #ccc;
                    margin: 0 4px;
                    background: #3E84D6;
                    border-radius: 6px;
                    margin-bottom: 10px;
                    padding: 10px 0;
                    box-sizing: border-box;
                    .brand-img{ 
                        height: 300px; 
                        // border-radius: 6px;
                        overflow: auto;
                        .img-item{
                            background: #ccc;
                            border-radius: 3px;
                            width: 192px;
                            height: 50px;
                            background: #fff;
                            padding: 0 15px; 
                            box-sizing: border-box; 
                            margin: 10px 20px;
                            text-align: center;
                            overflow: hidden;
                            >img{
                                width: auto;
                                height: 96px;
                                margin-top: -23px;
                            }
                        }
                        .active{
                            width: 213px;
                        }
                    }
                }
                .brand-img::-webkit-scrollbar{
                    display: none;
                }
                .goods-col-list::-webkit-scrollbar{
                    display: none;
                }
                .less-than{
                    position: absolute;
                    top: 40%;
                    left: 273px;
                    width: 60px;
                    height: 60px;
                    background: rgba(0, 0, 0, 0.2);
                    border-radius: 50%;
                    font-size: 30px;
                    color: #fff;
                    text-align: center;
                    line-height: 60px;
                }
                .great-than{
                    position: absolute;
                    top: 40%;
                    right: 36px;
                    width: 60px;
                    height: 60px;
                    background: rgba(0, 0, 0, 0.2);
                    border-radius: 50%;
                    font-size: 30px;
                    color: #fff;
                    text-align: center;
                    line-height: 60px;
                }
                .goods-col-list{
                    display: flex;
                    overflow-x: auto;
                    flex-wrap: nowrap;
                    justify-content: space-between; 
                    
                    .goods-col-all{
                        width: 234px;
                        margin: 0 4px;
                        .goods-card{
                            background: #fff;
                            width: 232px;
                            height: 336px;
                            display: flex;
                            margin-bottom: 10px;
                            flex-direction: column;
                            // justify-content: space-between;		
                            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
                            border-radius: 6px ;
                            .img-box{
                                // cursor: pointer;
                                height: 210px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                img{
                                    
                                    width: 210px;
                                    height: 210px;
                                }
                            }
                            .card-fot{
                                // padding: 14px 10px;
                                padding: 0px 14px 10px;
                                box-sizing: border-box;
                                .fot-bot-price{
                                        font-size: 18px;
                                        padding-right: 10px;
                                        .dot{
                                            font-size: 14px;
                                            position: relative;
                                            left: -3px;
                                        }
                                        .blue{
                                            font-size: 14px;
                                            color: #3E84D6;
                                        }
                                        .showPrice{
                                            display: flex;
                                            margin: 20px 0 10px 0;
                                            .Price{
                                                font-size: 24px;
                                                color: #FF2020;
                                                
                                            }
                                            .salePrice{
                                                font-size: 14px;
                                                text-decoration: line-through;
                                                color: #999999;
                                                padding-top: 8px;
                                                padding-left: 7px;
                                            }
                                        }
                                    }
                                .fot-top{
                                    display: flex;
                                    height: 22px;
                                    position: relative;
                                    .store-name{
                                        font-size: 12px;
                                        border:1px solid #3E84D6;
                                        padding: 3px 1px;
                                        margin-right: 5px;
                                        
                                        border-radius: 2px;
                                    }
                                    .goods-name{
                                        font-size: 16px;
                                        font-weight: 500;
                                        color: #333333;
                                        white-space: nowrap;
                                        text-overflow: ellipsis;
                                        overflow: hidden;
                                        width: 179px;
                                        
                                    }
                                    .joinCart{
                                        width: 25px;
                                        height: 23px;
                                        position: absolute;
                                        top: 0;
                                        right: 0;
                                        cursor:pointer;
                                        img{
                                            width: 25px;
                                            height: 23px;
                                        }
                                        }
                                }
                                .fot-bot{
                                    display: flex;
                                    justify-content: space-between;
                                    padding-top: 6px;
                                    height: 28px;
                                    line-height: 28px;
                                    .fot-right{
                                        display: flex;
                                        .store-name{
                                            min-width: 47px;
                                            height: 28px;
                                            padding: 0 4px;
                                            line-height: 28px;
                                            text-align: center;
                                            background: #3E84D6;
                                            font-size: 14px;
                                            color: #fff;
                                            margin-right: 4px;
                                            // cursor: pointer;
                                        }
                                        .store-oe{
                                            max-width: 130px;
                                            font-size: 14px;
                                            color: #3E84D6;
                                            overflow:hidden;
                                            white-space:nowrap;
                                            text-overflow:ellipsis;
                                        }
                                    }
                                    .store-inventory{
                                        font-size: 14px;
                                        color: #999999;
                                    }
                                }
                            }
                        }
                    }
                }
                
            }
            
            .goods-col{
                position: relative;
                
                .dis-good-tips{
                    position: absolute;
                    right: 4px;
                    top: 10px;
                    background: url(../../assets/index/newHome/折扣.png);
                    width: 64px;
                    height: 30px;
                    text-align: center;
                    line-height: 30px;
                    font-family: Source Han Sans CN;
                    font-weight: 500;
                    font-size: 20px;
                    color: #FFFFFF;
                    z-index: 99;
                    
                }
                .goods-card{
                    background: #fff;
                    
                    height: 336px;
                    display: flex;
                    margin-bottom: 10px;
                    flex-direction: column;
                    // justify-content: space-between;		
                    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
                    border-radius: 6px ;
                    .img-box{
                        // cursor: pointer;
                        height: 210px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        img{
                            
                            width: 210px;
                            height: 210px;
                        }
                    }
                    .card-fot{
                        // padding: 14px 10px;
                        padding: 0px 14px 10px;
                        box-sizing: border-box;
                        .fot-bot-price{
                                font-size: 18px;
                                padding-right: 10px;
                                .dot{
                                    font-size: 14px;
                                    position: relative;
                                    left: -3px;
                                }
                                .blue{
                                    font-size: 14px;
                                    color: #3E84D6;
                                }
                                .showPrice{
                                    display: flex;
                                    margin: 20px 0 10px 0;
                                    .Price{
                                        font-size: 24px;
                                        color: #FF2020;
                                        
                                    }
                                    .salePrice{
                                        font-size: 14px;
                                        text-decoration: line-through;
                                        color: #999999;
                                        padding-top: 8px;
                                        padding-left: 7px;
                                    }
                                }
                            }
                        .fot-top{
                            display: flex;
                            height: 22px;
                            position: relative;
                            .store-name{
                                font-size: 12px;
                                border:1px solid #3E84D6;
                                padding: 3px 1px;
                                margin-right: 5px;
                                
                                border-radius: 2px;
                            }
                            .goods-name{
                                font-size: 16px;
                                font-weight: 500;
                                color: #333333;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                overflow: hidden;
                                width: 179px;
                                
                            }
                            .joinCart{
                                width: 25px;
                                height: 23px;
                                position: absolute;
                                top: 0;
                                right: 0;
                                cursor:pointer;
                                img{
                                    width: 25px;
                                    height: 23px;
                                }
                                }
                        }
                        .fot-bot{
                            display: flex;
                            justify-content: space-between;
                            padding-top: 6px;
                            height: 28px;
                            line-height: 28px;
                            .fot-right{
                                display: flex;
                                .store-name{
                                    min-width: 47px;
                                    height: 28px;
                                    padding: 0 4px;
                                    line-height: 28px;
                                    text-align: center;
                                    background: #3E84D6;
                                    font-size: 14px;
                                    color: #fff;
                                    margin-right: 14px;
                                    // cursor: pointer;
                                }
                                .store-oe{
                                    max-width: 130px;
                                    font-size: 14px;
                                    color: #3E84D6;
                                    overflow:hidden;
                                    white-space:nowrap;
                                    text-overflow:ellipsis;
                                }
                            }
                            .store-inventory{
                                font-size: 14px;
                                color: #999999;
                            }
                        }
                    }
                }
            }
            
        }
    }
    </style>