// 007接口
import Vue from 'vue'
import { Message } from 'element-ui';
const $erMsg = (message)=>{
    Message({
        message,
        type:"error",
        duration:800
     })
}
const fetch = async (url,data,method)=>{
   
    // data.token = "5519deb15b5ba75f9917bb8034ae1b3a"
    // data.open_id = "4bef7a69f121bcb9ae224af409df8f36"

    // let baseUrl = "https://sapi.007vin.com"
    let baseUrl = "https://api.huanuowei.cn/web/?act=data&op=api007&"
    
    if(!url){
        
    }
    data.url = url
    // let str = url +'?'
    let str = ""
    for(let key in data){
        if(key=='mcid'){
            data.mcid  = data.mcid.replace(/=/g,"%3D")

            data.mcid  = data.mcid.replace(/\+/g,"%2B")
        }
        str += `${key}=${data[key]}&` 
    }
    
    str = str.substring(0,str.length-1)
    
    let httpRequest = new XMLHttpRequest();//第一步：建立所需的对象
    if(method=='post'){
        httpRequest.open('POST',baseUrl + str, true);//第二步：打开连接  将请求参数写在url中  ps:"./Ptest.php?name=test&nameone=testone"
        httpRequest.send(data);//第三步：
    }else{
        // 
        httpRequest.open('GET',baseUrl + str, true);//第二步：打开连接  将请求参数写在url中  ps:"./Ptest.php?name=test&nameone=testone"
        httpRequest.send();//第三步：发送请求  将请求参数写在URL中
    }    
    /**
     * 获取数据后的处理程序
     */
    
    let res = await new Promise((rep,rej)=>{
        httpRequest.onreadystatechange = function () {
            if (httpRequest.readyState == 4 && httpRequest.status == 200) {
                let json = httpRequest.responseText;//获取到json字符串，还需解析
                try{
                    json = JSON.parse(json)
                    
                    if(json.code==1){
                        // 只有1成功
                        if(json.data.length==0){
                            // $erMsg("未搜索到结果")
                        }
                    }else if(json.code==0){
                        
                        if(json.msg!=''){
                            // $erMsg(json.msg)
                        }else{
                            
                            // $erMsg("未找到结果")
                        }
                    }else if(json.code==2){
                        // ，车架号可能有误，请核实-车架号
                       $erMsg("原厂无相关信息")
                    }else if(json.code==3){
                    //    $erMsg("抱歉,没有找到相关信息")
                    }else if(json.code==7){
                       $erMsg("rpc初始化错误")
                    }else if(json.code==9){
                       $erMsg("品牌数据正在维护，暂停无法提供车架号查询")
                    }else if(json.code==4001){
                       $erMsg("请选择品牌")
                    }else if(json.code==4005){
                       $erMsg("匹配多个车型，覆盖车型奥迪，丰田，本田等")
                    }else {
                    //    $erMsg("未匹配到对应状态码!")
                        // $erMsg(json.msg)
                    }
                    rep(json)
                }catch(e){
                    console.error(e)
                //    $erMsg("解析失败")
                    rej(e)
                }
            }
        };
    })
    return res  
}

// fetch
// vin查询
const vinSearch = async (vin)=>{
    return await fetch("/api/cars/vin",{
        vin 
    },'get')
}
// 通过名称搜索该vin下的零件列表
const vinSearchByName = async (data)=>{
    return await fetch("/api/engine/parts/search_key_v2",{
        ...data
    },'post')
}
// 搜索零件的图和相邻配件
const vinPartSearch = async (data)=>{
    return await fetch("/api/cars/part",{
        ...data
    })
}
// ,'post'
// 全车件零件标签查询
const VinTagsQuery = async (data)=>{
    return await fetch("/api/cars/part/tags",{
        
        ...data
    })
}

// 获取主分组
const getMainGroupList = async (data)=>{
    // console.log(data)
    return await fetch("/api/cars/group",{
        
        ...data
    })
}

// 获取子分组
const getBranchGroupList = async (data)=>{
    return await fetch("/api/cars/structure",{
        ...data
    })
}
// 获取子分组 下的配件
const getBranchChildList = async (data)=>{
    return await fetch("/api/cars/structure/subs",{
        ...data
    })
}

// 获取配件基础信息
const getGoodsBaseInfo = async (data)=>{
    return await fetch("/api/parts/baseinfo",{
        ...data
       
    })
}
// 获取参考价格
const getPartPrice = async (data)=>{
    return await fetch("/api/parts/price",{
        ...data
    })
}
// 获取替换件
const getPartReplace = async (data)=>{
    return await fetch("/api/parts/replace",{
        ...data
    })
}
// 获取适配车型
const getPartVeh = async (data)=>{
    return await fetch("/api/parts/generacars",{
        ...data
    })
}
// 获取所有品牌
const getAllBrand = async ()=>{
    return await fetch("/api/brands",{})
}
// 基础车型参数选择
const getBrandTitle = async (data)=>{
    return await fetch("/api/cars/brand/title",{
        ...data
    })
}
// 基础车型选择
const getBrandBase = async (data)=>{
    return await fetch("/api/cars/models",{
        ...data
    })
}
// 模糊搜索配件
const getPartByOe = async (data)=>{
    return await fetch("/api/engine/parts/search",{
        ...data
    })
}
// 查找车型查设备编号
const getFacilityBysa = async (data)=>{
    return await fetch("/epc/car/parts/sa_info",{
        ...data
    })
}
//  获取配件配置
const getPartConfig = async (data)=>{
    return await fetch("/api/epc/car/parts/sa_info",{
        ...data
    })
}
//  定制接口 通过vin号查询华诺威包含商品
const getCustomVinSearch = async (data)=>{
    return await fetch("/api/epc/dz/vincars/oeslist/new",{
        ...data
    })
}


const api007 = {
    vinSearch,
    vinSearchByName,
    vinPartSearch,
    VinTagsQuery,
    getMainGroupList,
    getBranchGroupList,
    getBranchChildList,
    getGoodsBaseInfo,
    getPartPrice,
    getPartReplace,
    getPartVeh,
    getAllBrand,
    getBrandTitle,
    getBrandBase,
    getPartByOe,
    getFacilityBysa,
    getPartConfig,
    getCustomVinSearch
}

export default api007