<template>
  <div class="register">
    <header class="regisHead">
      <div class="logo">
        <img src="../../assets/regist/logo-pjlm - 副本.png" alt="" />
        <!-- <span>华诺威汽配联盟平台</span> -->
      </div>
      <div class="reg-right">
        <span class="has">已有帐号？</span>
        <span class="tologin" @click="toLogin">立即登录</span>
      </div>
    </header>
    <div class="cotainer" v-if="tempNum == 1">
      <div class="box">
        <div class="step">
          <div class="step-item" :class="{ active: step > 0 }">
            <div class="serial">
              <div class="num">1</div>
              <div class="line"></div>
            </div>
            <div class="step-text">企业注册</div>
          </div>
          <div class="step-item" :class="{ active: step > 1 }">
            <div class="serial">
              <div class="num">2</div>
              <div class="line"></div>
            </div>
            <div class="step-text">填写资料</div>
          </div>
          <div class="step-item" :class="{ active: step > 2 }">
            <div class="serial">
              <div class="num">3</div>
              <div class="line"></div>
            </div>
            <div class="step-text">注册成功</div>
          </div>
        </div>
        <div class="form">
          <template v-if="step == 1">
            <el-form
              :model="regitsData"
              :rules="rule1"
              ref="reRef1"
              label-width="100px"
            >
              <el-form-item label="手机号码：" prop="phone">
                <el-input
                  v-model="regitsData.phone"
                  placeholder="请输入手机号"
                ></el-input>
              </el-form-item>
              <el-form-item label="密码：" prop="password">
                <el-input
                  v-model="regitsData.password"
                  placeholder="密码"
                  type="password"
                  autocomplete="new-password"
                ></el-input>
              </el-form-item>
              <el-form-item label="确认密码：" prop="password_confirm">
                <el-input
                  v-model="regitsData.password_confirm"
                  placeholder="请输入确认密码"
                  type="password"
                  autocomplete="new-password"
                ></el-input>
              </el-form-item>
              <el-form-item label="验证码：" prop="captcha">
                <div class="captcha-box">
                  <el-input
                    v-model="regitsData.captcha"
                    placeholder="请输入验证码"
                    class="capIpt"
                  ></el-input>

                  <el-button
                    type="primary"
                    class="capBtn"
                    @click="getCaptcha"
                    >{{ vcodeBtnName }}</el-button
                  >
                </div>
              </el-form-item>
              <el-form-item label="">
                <el-button
                  type="primary"
                  class="next"
                  @click="register"
                  :disabled="!isAgree"
                  >下一步</el-button
                >
              </el-form-item>
              <div class="regis">
                <div class="regis-img-box" @click="isAgree = !isAgree">
                   <img src="../../assets/regist/checked.png" alt="" v-if="isAgree">
                                        <img src="../../assets/regist/beCheck.png" alt="" v-else > 
                </div>
                您已阅读并同意
                <span class="protocol" @click="tempNum = 2"
                  >《华诺威系统服务协议》</span
                >
              </div>
            </el-form>
          </template>
          <template v-if="step == 2">
            <el-form
              :model="regitsData"
              :rules="rule2"
              ref="reRef2"
              label-width="180px"
            >
              <div class="action">
                <div class="title">基础信息：</div>
                
                <el-form-item label="注册方式：" >
                  <el-select v-model="regisType" placeholder="请选择">
                    <el-option label="华诺威客户" value="1"></el-option>
                    <el-option label="其他" value="2"></el-option>
                  </el-select>
                </el-form-item>

                <el-form-item label="企业名称：" prop="store_company_name" >
                  <el-input v-model="regitsData.store_company_name"></el-input>
                </el-form-item>
                <el-form-item label="企业简称：" prop="company_name_jc" v-if="regisType==1">
                  <el-input v-model="regitsData.company_name_jc"></el-input>
                </el-form-item>
                <el-form-item label="企业类型：" prop="sc_id" v-if="regisType==1">
                  <el-select v-model="regitsData.sc_id" placeholder="请选择">
                    <el-option
                      v-for="item in typeList"
                      :key="item.sc_id"
                      :label="item.sc_name"
                      :value="item.sc_id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                
                <el-form-item label="企业联系人：" prop="contacts_name">
                  <el-input v-model="regitsData.contacts_name"></el-input>
                </el-form-item>
                <el-form-item label="联系人电话：" prop="contacts_phone">
                  <el-input v-model="regitsData.contacts_phone"></el-input>
                </el-form-item>
                <el-form-item label="企业电话：" prop="store_phone" v-if="regisType==1">
                  <el-input v-model="regitsData.store_phone"></el-input>
                </el-form-item>

                <el-form-item label="企业地址：" prop="area_info" v-if="regisType==1"> 
                  <AdressSelect @upAdress="upAdress"></AdressSelect>
                </el-form-item>
                <el-form-item label="企业详细地址：" prop="store_address" v-if="regisType==1">
                  <el-input v-model="regitsData.store_address"></el-input>
                </el-form-item>
              </div>
              
            </el-form>
            <el-form :model="regitsData" :rules="rule2" ref="reRef3">
              <div class="action">
                <div class="title">认证信息:</div>
                <el-form-item label="" prop="id_just" v-if="regisType==1">
                  <div class="img-infor-item">
                    <div class="up-left">
                      <span class="upText"> 身份证正面 </span>
                      <el-upload
                        :action="$axios.defaults.baseURL+'index.php?act=login&op=storeUp'"
                        :data="upImgData"
                        :headers="{'token':$store.state.token}"

                        list-type="picture-card"
                        :on-success="upImgOverJus"
                        :limit="1"
                        name="pic"
                        :on-exceed="onExceed"
                      >
                        <div class="upIcon">
                          <i class="el-icon-plus"></i>
                        </div>
                      </el-upload>
                    </div>
                    <div class="example-right">
                      <div class="label">示例图：</div>
                      <div class="eximg">
                        <img src="../../assets/regist/sfz1.png" alt="" />
                      </div>
                    </div>
                  </div>
                </el-form-item>
                <el-form-item label="" prop="id_back" v-if="regisType==1">
                  <div class="img-infor-item">
                    <div class="up-left">
                      <span class="upText"> 身份证反面 </span>
                      <el-upload
                        :action="$axios.defaults.baseURL+'index.php?act=login&op=storeUp'"
                        :data="upImgData"
                        :headers="{'token':$store.state.token}"
              
                        list-type="picture-card"
                        :on-success="upImgOverBac"
                        name="pic"
                        :limit="1"
                        :on-exceed="onExceed"
                      >
                        <div class="upIcon">
                          <i class="el-icon-plus"></i>
                        </div>
                      </el-upload>
                    </div>
                    <div class="example-right">
                      <div class="label">示例图：</div>
                      <div class="eximg">
                        <img src="../../assets/regist/sfz2.png" alt="" />
                      </div>
                    </div>
                  </div>
                </el-form-item>
                <el-form-item label="" prop="business_licence_number_elc" >
                  <div class="img-infor-item">
                    <div class="up-left">
                      <span class="upText"> 营业执照 </span>
                      <el-upload
                        :action="$axios.defaults.baseURL+'index.php?act=login&op=storeUp'"
                        :data="upImgData"
                        :headers="{'token':$store.state.token}"
                        list-type="picture-card"
                        :on-success="upImgOverElc"
                        name="pic"
                        :limit="1"
                        :on-exceed="onExceed"
                      >
                        <div class="upIcon">
                          <i class="el-icon-plus"></i>
                        </div>
                      </el-upload>
                    </div>
                    <div class="example-right">
                      <div class="label">示例图：</div>
                      <div class="eximg">
                        <img src="../../assets/regist/yyzz.png" alt="" />
                      </div>
                    </div>
                  </div>
                </el-form-item>
                <el-form-item label="" prop="store_doorway">
                  <div class="img-infor-item">
                    <div class="up-left">
                      <span class="upText">门头照</span>
                      <el-upload
                        :action="$axios.defaults.baseURL+'index.php?act=login&op=storeUp'"
                        :data="upImgData"
                        :headers="{'token':$store.state.token}"
                        list-type="picture-card"
                        :on-success="upImgOverDoo"
                        name="pic"
                        :limit="1"
                        :on-exceed="onExceed"
                      >
                        <div class="upIcon">
                          <i class="el-icon-plus"></i>
                        </div>
                      </el-upload>
                    </div>
                    <div class="example-right">
                      <div class="label">示例图：</div>
                      <div class="eximg">
                        <img src="../../assets/regist/dpzp.png" alt="" />
                      </div>
                    </div>
                  </div>
                </el-form-item>
              </div>
              <div class="action" v-if="regisType==1">
                <div class="title">经营信息(非必填)：</div>
                <el-form-item
                  label="统一社会信用代码："
                >
                  <el-input
                    v-model="regitsData.business_licence_number"
                  ></el-input>
                </el-form-item>
                <!-- <el-form-item label="企业名称：" prop="store_company_name">
                                        <el-input v-model="regitsData.store_company_name" ></el-input>
                                    </el-form-item> -->
                <el-form-item label="成立日期：">
                  <el-input v-model="regitsData.setup_time"></el-input>
                </el-form-item>
                <el-form-item label="法定代表人：" >
                  <el-input v-model="regitsData.corporation_name"></el-input>
                </el-form-item>
                <el-form-item label="法定人身份证号：">
                  <el-input v-model="regitsData.corporation_code"></el-input>
                </el-form-item>
                <el-form-item label="营业期限：">
                  <el-input v-model="regitsData.setend_time"></el-input>
                </el-form-item>
                <el-form-item label="主营产品：" >
                  <el-input v-model="regitsData.store_keywords"></el-input>
                </el-form-item>
              </div>
            </el-form>
            <div class="from-btn">
              <el-button type="primary" class="next" @click="commit">提交认证</el-button>
              <el-button type="primary" class="next" @click="$router.push('/login')">暂不认证</el-button>
            </div>
          </template>
          <template v-if="step == 3">
            <div class="reSuccess">
              <img src="../../assets/regist/registerOk.png" alt="" />
              <div class="suText">提交完成,等待审核</div>
              <div class="suText">如有问题，请联系华诺威客服人员:021-69585015</div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div v-if="tempNum == 2">
      <div class="regis-protocol">
        <div class="regis-protocol-container">
          <div class="reback">
            <el-button size="mini" type="primary" @click="tempNum = 1"
              >返回</el-button
            >
          </div>
          <div class="pro-tit">《华诺威汽配交易系统用户服务协议》</div>
          <Section>
            <p>感谢您成为华诺威汽配交易系统的注册用户。</p>
            <p>特别提示：</p>
            <p>
              当您按照注册页面提示填写信息、阅读并同意本协议且完成全部注册程序后，或您按照激活页面提示填写信息、阅读并同意本协议且完成全部激活程序后，或您以其他华诺威汽配交易系统允许的方式实际使用本服务时，即表示您已充分阅读、理解并接受本协议的全部内容，并与华诺威汽配交易系统达成协议。
            </p>
            <p>
              您承诺接受并遵守本协议的约定，届时您不应以未阅读本协议的内容或者未获得华诺威汽配交易系统对您问询的解答等理由，主张本协议无效，或要求撤销本协议。
            </p>
          </Section>
          <Section>
            <p>一、 协议相关说明</p>
            <p>
              1.
              协议内容包括协议正文及所有华诺威汽配交易系统已经发布的或将来可能发布的各类规则。所有规则为本协议不可分割的组成部分，与协议正文具有同等法律效力。
            </p>
            <p>
              2.
              您在同意所有协议条款并完成注册所有程序后，才能成为本站的正式用户，此时您可以享受本站完整的服务。只要您使用华诺威汽配交易系统，本协议即对您产生约束，届时您不应以未阅读本协议的内容或者未获得华诺威汽配交易系统对您问询的解答等理由，主张本协议无效，或要求撤销本协议。
            </p>
            <p>
              3.
              您同意：本协议条款是处理双方权利义务的契约，始终有效，法律另有强制性规定或双方另有特别约定的，依其规定。
            </p>
            <p>
              4.
              您承诺接受并遵守本协议的约定。如果您不同意本协议的约定，您应立即停止注册程序或停止使用华诺威汽配交易系统平台服务。
            </p>
            <p>
              5.
              华诺威汽配交易系统有权根据需要不定期地制订、修改本协议及/或各类规则，并在华诺威汽配交易系统公示，不再另行单独通知用户。变更后的协议和规则经网站公示期满后，立即生效。如您不同意相关变更，应当立即停止使用华诺威汽配交易系统平台服务。您继续使用华诺威汽配交易系统平台服务，即表明您接受修订后的协议和规则。
            </p>
            <p>6. 用户有义务不时关注并阅读最新版的协议及网站公告。</p>
            <p>
              7.
              如果本协议中任何一条被视为废止、无效或因任何理由不可执行，该条应视为可分的且并不影响任何其余条款的有效性和可执行性。
            </p>
          </Section>
          <Section>
            <p>二、 账户管理</p>
            <p>1、主体资格</p>
            <p>
              您确认，在您完成注册程序或以其他华诺威汽配交易系统允许的方式实际使用本服务时，您应当是具备完全民事行为能力和完全民事力的自然人、法人或其他组织。
            </p>
            <p>2、注册和账户</p>
            <p>
              ①　当您按照注册页面提示填写信息、阅读并同意本协议且完成全部注册程序后，或在您按照激活页面提示填写信息、阅读并同意本协议且完成全部激活程序后，或您以其他华诺威汽配交易系统允许的方式实际使用本服务时，您即受本协议约束。您可以使用您提供或确认手机号码或者华诺威汽配交易系统允许的其它方式作为登录手段进入华诺威汽配交易系统。
            </p>
            <p>
              ②　您注册成功后，华诺威汽配交易系统会为您开通
              系统平台账户。如有证据证明或华诺威汽配交易系统有理由相信您存在注册或使用多个华诺威汽配交易系统平台账户的情形，华诺威有权采取冻结或关闭账户、取消订单、拒绝提供服务等措施，如给华诺威汽配交易系统平台及相关方造成损失的，您还应承担赔偿责任。
            </p>
            <p>
              ②　您对通过您的账户所进行的活动、行为和事件依法享有权利和承担责任，且不得以任何形式转让账户、授权他人使用您的账户以及与他人交易账户。
            </p>
            <p>
              ③　鉴于网络服务的特殊性，
              华诺威汽配交易系统无义务审核是否是您本人使用该组用户名及密码，仅审核用户名及密码是否与数据库中保存的一致，任何人只要输入的用户名及密码与数据库中保存的一致，即可凭借该组用户名及密码登陆并获得华诺威汽配交易系统所提供的各类服务，所以即使您认为您的账户登陆行为并非您本人所为，华诺威将不承担因此而产生的任何责任。
            </p>
            <p>
              ④　您可以通过在华诺威汽配交易系统平台参与商品评价、晒单、网站活动等方式获得积分、返利和抵用券等。积分和抵用券都具有特定的使用规则和有效期，逾期将被清零；请您不时关注您账户中的积分和抵用券的有效期，在有效期届满前，华诺威将不再另行作特别通知；华诺威不对逾期清零的积分和抵用券负责。对于恶意购买和不正当手段获得的积分和抵用券，华诺威有权作出独立判断并采取包含但不限于冻结您的用户账户或清空积分、抵用券等措施。
            </p>
            <p>5、华诺威汽配交易系统登录名注销</p>
            <p>
              a）若您在华诺威汽配交易系统成功注册账户后，连续24个月时间内未使用该账户（包括使用此账户订购商家商品，发表使用体验，参与商品讨论，参加华诺威汽配交易系统平台的有关活动以及使用其他服务），则华诺威有权对您的账户进行清理，届时您的华诺威汽配交易系统账号可能被注销，不能再登录华诺威汽配交易系统，所有华诺威汽配交易系统服务同时终止。
            </p>
            <p>
              b）您向华诺威汽配交易系统提出注销账户申请，经华诺威汽配交易系统平台审核同意后，由华诺威汽配交易系统注销该用户账户，本协议即告终止。但在注销该用户账户后，华诺威仍有权：
            </p>
            <p>① 保留该用户的注册信息及过往的全部交易行为记录；</p>
            <p>
              ②
              如用户在账户注销前在华诺威汽配交易系统上存在违法行为或违反本协议的行为，华诺威仍可行使本协议所规定的权利。
            </p>
            <p>
              c）在下列情况下，华诺威汽配交易系统可以通过注销用户账户的方式单方解除本协议：
            </p>
            <p>
              ①
              在用户违反本协议相关规定时，华诺威有权暂停或终止向该用户提供服务。如该用户在华诺威汽配交易系统暂停或终止提供服务后，再一次直接或间接或以他人名义注册为华诺威汽配交易系统平台用户的，则华诺威有权再次暂停或终止向该用户提供服务；
            </p>
            <p>
              ②
              一经发现用户注册信息中的内容是虚假的，华诺威即有权终止向该用户提供服务；
            </p>
            <p>
              ③
              本协议修改或更新时，如用户表示不愿接受新的服务协议的，华诺威有权终止向该用户提供服务；
            </p>
            <p>④ 华诺威认为需终止提供服务的其他情况。</p>
            <p>
              d）用户理解并同意，即便在本协议终止及用户的服务被终止后，华诺威仍有权：
            </p>
            <p>① 继续保存并使用您的用户信息；</p>
            <p>
              ②
              继续向用户主张在其使用华诺威汽配交易系统交易平台服务期间因违反法律法规、本协议及平台规则而应承担的责任。
            </p>
          </Section>
          <Section>
            <p>三、 用户规范</p>
            <p>
              通过华诺威汽配交易系统，您发布信息的内容须严格遵守以下禁止事项：
            </p>
            <p>
              1.
              禁止发布发表损害国家、民族、社会公共利益和涉及国家安全的信息资料或言论；
            </p>
            <p>
              2.
              禁止利用本站从事洗钱、窃取商业秘密、窃取用户信息等违法犯罪活动；
            </p>
            <p>3. 禁止干扰本站的正常运转，不得侵入本站信息系统；</p>
            <p>4. 禁止发表任何违法犯罪、淫秽、不文明等信息资料；</p>
            <p>
              5.
              禁止传输或发表损害国家社会公共利益和涉及国家安全的信息资料或言论；
            </p>
            <p>6. 禁止利用在本站注册的账户进行非法牟利性经营活动；</p>
            <p>
              7. 禁止发布任何侵犯他人著作权、商标权等知识产权或合法权利的内容；
            </p>
            <p>
              本站保有删除站内各类不符合法律政策或不真实信息的内容而无须通知您的权利。
            </p>
            <p>
              若您未遵守以上规定，本站有权做出独立判断并采取暂停或关闭您的帐号等措施。您须对自己在网上的言论和行为承担法律责任。
            </p>
          </Section>
          <Section>
            <p>四、华诺威汽配交易系统服务</p>
            <p>
              1、通过华诺威汽配交易系统提供服务，商家可在华诺威汽配交易系统上发布信息、查询商品和服务信息、达成交易意向并进行交易、对其他商家进行评价、参加华诺威组织的活动以及使用其它信息服务及技术服务，具体以所开通的华诺威汽配交易系统提供的服务内容为准。
            </p>
            <p>
              2、您在华诺威汽配交易系统上交易过程中与其他商家发生交易纠纷时，一旦您或其他商家任一方或双方共同提交华诺威要求调处，则华诺威作为独立第三方，有权根据单方判断做出调处决定，您了解并同意接受华诺威的判断和调处决定。
            </p>
            <p>
              3、您了解并同意，华诺威有权应政府部门（包括司法及行政部门）的要求，向其提供您向华诺威汽配交易系统提供的用户信息和交易记录等必要信息。如您涉嫌侵犯他人知识产权等合法权益，则华诺威汽配交易系统亦有权在初步判断涉嫌侵权行为存在的情况下，向权利人提供您必要的身份信息。
            </p>
            <p>
              4、您在使用华诺威汽配交易系统服务过程中，所产生的应纳税赋，以及一切硬件、软件、服务及其他方面的费用，均由您独自承担。
            </p>
            <p>华诺威汽配交易系统服务使用规范</p>
            <p>
              1、在华诺威汽配交易系统上使用本服务过程中，您承诺遵守以下约定：
            </p>
            <p>
              ①　实施的所有行为均遵守国家法律、法规等规范性文件及华诺威汽配交易系统各项规则的规定和要求，不违背社会公共利益或公共道德，不损害他人的合法权益，不偷逃应缴税费，不违反本协议及相关规则。
            </p>
            ②　不以虚构或歪曲事实的方式不当评价其他商家，不采取不正当方式制造或提高自身的信用度，不采取不正当方式制造或提高（降低）其他商家的信用度。
            ③　不对华诺威汽配交易系统上的任何数据作商业性利用，包括但不限于在未经华诺威事先书面同意的情况下，以复制、传播等任何方式使用华诺威汽配交易系统站上展示的资料。
            ④　不使用任何装置、软件或例行程序干预或试图干预华诺威汽配交易系统的正常运作或正在华诺威汽配交易系统上进行的任何交易、活动。您不得采取任何将导致不合理的庞大数据负载加诸华诺威汽配交易系统网络设备的行动。
          </Section>
          <Section>
            <p>五、 订单生效规则</p>
            <p>
              1、当您下订单时，请您仔细确认所购商品的名称、价格、数量、型号、规格、尺寸、联系地址、电话、收货人等信息。收货人与您本人不一致的，收货人的行为视为您的行为，您对收货人的行为承担连带责任。
            </p>
            <p>
              您提交订单即表示对订单中所确认的订购商品、收货地址等信息的准确性负责。如果因为您填写的收货人联系电话、地址等信息错误，导致未能如约定获取华诺威汽配交易系统提供的产品和服务的，由此造成的损失需由您自行承担。
            </p>
            2、您理解并认可华诺威汽配交易系统平台上的订单生效规则：<br />
            ①华诺威汽配交易系统平台上商家展示的商品信息（如商品名称、价格、商品描述等）仅构成要约邀请。当您通过华诺威汽配交易系统平台订购商家商品，确定购买并成功提交订单时（订单内容应包含购买的商品数量、价格及支付方式、收货人、联系方式、收货地址等信息），即视为您通过华诺威汽配交易系统向平台的商家发出了购买订单商品的要约。<br />
            ②华诺威汽配交易系统平台上展示的商家商品和价格等信息仅构成要约邀请，您下单时须填写您希望购买的商品数量、价款及支付方式、收货人、联系方式、收货地址（合同履行地点）、合同履行方式等内容，当用户提交订单时即视为用户通过华诺威汽配交易系统向华诺威汽配交易系统平台上的商家发出了购买订单商品的要约；<br />
            ③系统生成的订单信息是计算机信息系统根据您填写的内容自动生成的数据，仅是您向华诺威汽配交易系统平台商家发出的合同要约；
            华诺威汽配交易系统平台商家收到您的订单信息后，只有商家将您在订单中订购的商品从仓库实际直接向您发出时（以商品出库为标志），方视为您与华诺威汽配交易系统平台商家之间就实际直接向您发出的商品建立了合同关系；<br />
            ④如果您在一份订单里订购了多种商品并且华诺威汽配交易系统平台商家只给您发出了部分商品时，您与华诺威汽配交易系统平台商家之间仅就实际直接向您发出的商品建立了合同关系；只有在华诺威汽配交易系统平台商家实际直接向您发出了订单中订购的其他商品时，您和华诺威汽配交易系统平台商家之间就订单中该其他已实际直接向您发出的商品才成立合同关系。<br />
            3、华诺威汽配交易系统平台商家仅向消费者提供商品销售服务，若您并非因生活消费购买商品，而是中间商、零售商或批发商，则华诺威汽配交易系统平台商家有权单方取消订单。<br />
            4、若您拟购买的商品超过网站内公示的购买数量限制，请您与华诺威汽配交易系统客服联系。对于您已经从非团购渠道或非第三方商家指定渠道提交的订单，华诺威汽配交易系统或华诺威汽配交易系统平台商家有权单方予以取消。<br />
            5、 您有权在下列情况下，取消订单：<br />
            ①经与华诺威平台商家协商达成一致的；<br />
            ②华诺威平台商家对用户订单做出承诺之前；<br />
            ③华诺威平台商家网站上公布的商品价格发生变化或错误，用户在华诺威平台商家发货之前通知华诺威平台商家的。
            <br />
            6、华诺威汽配交易系统平台商家在下列情况下，可以取消用户订单：<br />
            ① 经与用户协商达成一致的；<br />
            ② 华诺威平台商家网站上显示的商品信息明显错误或缺货的；<br />
            ③ 用户订单信息明显错误或用户订购数量超出华诺威平台商家备货量的。<br />
            7、 华诺威平台商家在下列情况下，亦可取消用户订单：<br />
            ①
            因不可抗力、华诺威平台系统发生故障或遭受第三方攻击，及其他华诺威平台无法控制的情形，根据华诺威平台判断需要取消用户订单的；<br />
            ②
            经华诺威平台判断，用户订购行为不符合公平原则或诚实信用原则的情形；<br />
            ③
            按华诺威平台已经发布的或将来可能发布或更新的各类规则，可取消用户订单的其他情形。<br />
          </Section>
          <Section>
            六、 协议终止<br />
            协议终止情况：<br />
            1. 注册信息中的主要内容不真实或不准确或不及时或不完整时。<br />
            2.
            本协议（含规则）变更时，您明示并通知华诺威不愿接受新的服务协议的；<br />
            3.
            您主动向华诺威汽配交易系统要求注销您的账户，经华诺威汽配交易系统审核同意，
            华诺威汽配交易系统注销（永久冻结）您的账户。<br />
          </Section>
          <Section>
            协议终止后相关事宜：<br />
            1.您的账户被注销（永久冻结）后，华诺威没有义务为您保留或向您披露您账户中的任何信息，也没有义务向您或第三方转发任何您未曾阅读或发送过的信息。<br />
            2.华诺威有权继续保存您的注册信息及您使用华诺威汽配交易系统平台服务期间的所有交易信息。<br />
            3.您在使用华诺威汽配交易系统平台服务期间存在违法行为或违反本协议和/或规则的行为，华诺威仍可依据本协议向您主张权利。<br />
          </Section>
          <Section>
            七、 知识产权<br />
            1.
            用户一旦接受本协议，即表明该用户主动将其在任何时间段在本平台发表的任何形式的信息内容（包括但不限于客户评价、客户咨询、各类话题文章等信息内容）的财产性权利等任何可转让的权利，如著作权财产权（包括并不限于：复制权、发行权、出租权、展览权、表演权、放映权、广播权、信息网络传播权、摄制权、改编权、翻译权、汇编权以及应当由著作权人享有的其他可转让权利），全部独家且不可撤销地转让给平台所有，您同意平台有权就任何主体侵权而单独提起诉讼。<br />
            2.
            本协议已经构成《中华人民共和国著作权法》第二十五条及相关法律规定的著作财产权等权利转让书面协议，其效力及于用户在平台上发布的任何受著作权法保护的作品内容，无论该等内容形成于本协议订立前还是本协议订立后。<br />
            3.
            除法律另有强制性规定外，未经平台明确的特别书面许可，任何单位或个人不得以任何方式非法地全部或部分复制、转载、引用、链接、抓取或以其他方式使用本平台的信息内容，否则，华诺威有权追究其法律责任。<br />
            4.
            华诺威汽配交易系统所刊登的资料信息（诸如文字、图表、标识、按钮图标、图像、声音文件片段、数字下载、数据编辑和软件），均是华诺威的财产，受中国和国际版权法的保护。华诺威汽配交易系统上所有内容的汇编是华诺威的排他财产，受中国和国际版权法的保护。华诺威汽配交易系统上所有软件都是华诺威或其关联公司或其软件供货商的财产，受中国和国际版权法的保护。<br />
            5.
            华诺威是本站的制作者，拥有此网站内容及资源的著作权等合法权利，受国家法律保护，有权不时地对本协议及本站的内容进行修改，并在本站公布，无须另行通知您。在法律允许的最大限度范围内，华诺威对本协议及本站内容拥有解释权。<br />
          </Section>
          <Section>
            八、 免责声明<br />
            华诺威有义务确保华诺威汽配交易系统平台的正常运行，尽力避免服务中断或将中断时间限制在最短时间内，保证您在平台交易活动的顺利进行。但以下情况，华诺威无需承担任何责任：<br />
            1.
            您将账号密码告知他人或与他人共享注册账户，由此导致任何资料的泄露。<br />
            2.
            黑客攻击、计算机病毒侵入、政府管理等不可抗力造成本平台崩溃或无法正常使用，从而导致交易无法完成或信息、记录丢失。<br />
            3.
            您向本平台提供错误、不完整、不实信息等造成不能正常使用本平台服务或遭受任何其他损失。<br />
          </Section>
          <Section>
            九、 法律适用、管辖与其他 1.
            本协议之效力、解释、变更、执行与争议解决均适用中华人民共和国大陆地区法律，如无相关法律规定的，则应参照通用国际商业惯例和（或）行业惯例。<br />
            2.
            本协议包含了您使用平台需遵守的一般性规范，您在使用平台时还需遵守适用于该平台的特殊性规范。一般性规范如与特殊性规范不一致或有冲突，则特殊性规范具有优先效力。<br />
            3.
            如您对本协议或其执行内容有任何异议，双方应尽力友好协商解决，协商不成时，您同意以平台注册地（上海嘉定区）所在地人民法院为第一审管辖法院。<br />
          </Section>
          <Section>
            其他<br />
            华诺威汽配交易系统尊重用户和消费者的合法权利，本协议及本网站上发布的各类规则、声明等其他内容，均是为了更好的、更加便利的为您提供服务。本站欢迎您提出意见和建议，华诺威将虚心接受并适时修改本协议及本站上的各类规则。<br />
          </Section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AdressSelect from "../../components/AdressSelect";

export default {
  data() {
    
    const validatePass = (rule, value, callback) => {
      if (value == "") {
        callback(new Error("请填写确认密码"));
      } else if (value !== this.regitsData.password) {
        callback(new Error("两次输入的密码不同"));
      } else {
        callback();
      }
    };
    const validatePass2 = (rule, value, callback) => {
        let reg = new RegExp(/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,16}$/)
        if(!reg.test(value)){
            callback(new Error("密码需要包含字母和数字;长度在8~16位之间"));
        }else{
            callback();
        }
    };
    return {
      step: 1,
      regitsData:{
        phone: "",
        password: "",
        password_confirm: "",
        captcha: "",
        member_id: "",
        member_name: "",
        store_company_name: "",
        company_name_jc: "",
        business_licence_number: "",
        area_info: "",
        store_address: "",
        sc_id: "",
        sc_name: "",
        contacts_name: "",
        contacts_phone: "",
        store_phone: "",
      
        pay_type: "",
        setup_time: "",
        setend_time: "",
        corporation_name: "",
        corporation_code: "",
        business_licence_number_elc: "",
        store_keywords: "",
        id_just: "",
        id_back: "",
        store_doorway: "",
      },
      upImgData:{
          store_id:this.$store.state.store_id
      },
      // 公司类型
      typeList: [],
      rule1: {
        phone: [{ required: true, message: "请填写手机号", trigger: "blur" }],
        password: [
          { required: true, message: "请填写密码", trigger: "blur" },
          { validator: validatePass2, trigger: "blur" },
        ],
        password_confirm: [
          { required: true, message: "请填写密码", trigger: "blur" },
          { validator: validatePass, trigger: "blur" },
          { validator: validatePass2, trigger: "blur" },
        ],
        captcha: [{ required: true, message: "请填写验证码", trigger: "blur" }],
      },
      rule2: {
        store_company_name: [
          { required: true, message: "请填写企业名称", trigger: "blur" },
        ],
        company_name_jc: [
          { required: true, message: "请填写企业简称", trigger: "blur" },
        ],
        
        area_info: [{ required: true, message: "请填写地址", trigger: "blur" }],
        store_address: [
          { required: true, message: "请填写企业详细地址", trigger: "blur" },
        ],
        sc_id: [{ required: true, message: "请填写企业类型", trigger: "blur" }],

        contacts_name: [
          { required: true, message: "请填写企业联系人", trigger: "blur" },
        ],
        contacts_phone: [
          { required: true, message: "请填写联系人电话", trigger: "blur" },
        ],
        store_phone: [
          { required: true, message: "请填写企业电话", trigger: "blur" },
        ],
        
        
        business_licence_number_elc: [
          { required: true, message: "请上传营业执照", trigger: "blur" },
        ],
  
        id_just: [{ required: true, message: "请上传身份证", trigger: "blur" }],
        id_back: [{ required: true, message: "请上传身份证", trigger: "blur" }],
        store_doorway: [
          { required: true, message: "请上传门头照", trigger: "blur" },
        ],
      },
      vcodeBtnName: "获取验证码",
      hasCaptcha: false,
      countDownTimer: "",
      countNum: 120,
      isAgree: false,
      tempNum: 1,
      regisType:"2"
    };
  },
  methods: {
    onExceed() {
      this.$warMsg("最多上传一张");
    },
    toLogin() {
      this.$router.push("/login");
    },
    upAdress(area_info) {
      this.regitsData.area_info = area_info;
    },
    upImgOverJus(res) {
      // this.regitsData.id_just = res.data.file_name;
      this.regitsData.id_just = res.data.file_url;
    },
    upImgOverBac(res) {
      // this.regitsData.id_back = res.data.file_name;
      this.regitsData.id_back = res.data.file_url;
    },
    upImgOverElc(res) {
      // this.regitsData.business_licence_number_elc = res.data.file_name;
      this.regitsData.business_licence_number_elc = res.data.file_url;
    },
    upImgOverDoo(res) {
      // this.regitsData.store_doorway = res.data.file_name;
      this.regitsData.store_doorway = res.data.file_url;
    },

    async getCaptcha() {
        if (this.regitsData.phone.length != 11) {
          return this.$erMsg("请填写正确的手机号");
        }
        if (this.regitsData.password.length<6) {
          return this.$erMsg("密码必须大于6位");
        }
        if (this.vcodeBtnName != "获取验证码" &&this.vcodeBtnName != "重新发送") {
          return;
        }
        // 与后端 api 交互，发送验证码 【自己写的具体业务代码】
        const res =  await this.$api.sendSms({
          phone:this.regitsData.phone,
          type:1
        })
        if(res){
            this.vcodeBtnName = "发送中...";
            this.$suMsg("短信已发送，请注意查收");
            this.countNum = 120;
            this.countDownTimer = setInterval(
              function () {
                this.countDown();
              }.bind(this),
              1000
            );
            this.hasCaptcha = true;
        }
    },
    countDown() {
      if (this.countNum < 1) {
        clearInterval(this.countDownTimer);
        this.vcodeBtnName = "重新发送";
        return;
      }
      this.countNum--;
      this.vcodeBtnName = this.countNum + "秒重发";
    },
    // 下一步
    register() {
      if (!this.hasCaptcha) {
        return this.$erMsg("请先获取验证码");
      }
      this.$refs.reRef1.validate(async (flag) => {
        if (flag) {
          
  
          let res = await this.$api.register(this.regitsData)
          //  注册
          if (res) {
            this.regitsData.member_id = res.member_id;
            this.regitsData.member_name = res.member_name;
            this.step = 2;
          } else {
            return this.$erMsg("验证码不正确");
          }
        }
      });
      
    },
    async commit() {
      
      this.$refs.reRef2.validate((flag) => {
        if (!flag) {
          return this.$erMsg("提交失败,注意字段填写完整");
        } else {
          this.$refs.reRef3.validate(async (flag) => {
            if (!flag) {
              
              return this.$erMsg("请上传对应图片");
            } else {
              
              
              this.typeList.forEach((item) => {
                if (item.sc_id == this.regitsData.sc_id) {
                  this.regitsData.sc_name = item.sc_name;
                }
              });
             
              let res = await this.$api.authenAcc(this.regitsData);

              
              if (res) {
                this.$suMsg("提交成功");
                this.step = 3;
              } else {
                this.$erMsg("提交失败");
              }
            }
          });
        }
      });
    },
  },
  async created() {
    this.typeList = await this.$api.getAllCompanyType();
    
    if(this.$store.state.member_id){
 
      this.step = 2
      // 获取认证状态
      let data = await this.$api.getAuthenState(this.$store.state.member_id) 
      if(data.joinin_state==11){
        this.step = 3
      }
      this.regitsData.member_id = this.$store.state.member_id
      this.regitsData.member_name = this.$store.state.member_name
      
    }else{
      console.log("暂未登录")
    }
    // this.step = 2;
    // 改变提交步骤
    
  },
  components: {
    AdressSelect,
  },
};

// 4F0201511C   hnw =>1620
// hnwcaigou02
// 23232323
</script>

<style lang="scss">
.register {
  .regisHead{
    @include hnwHeader;
    justify-content: space-between;
    align-items: center;
    .reg-right {
      padding-right: 40px;
      .has {
        margin: 0 10px;
      }
      .tologin {
        font-size: 18px;
        cursor: pointer;
      }
    }
  }
  .cotainer {
    padding: 2.121212rem /* 70/33 */ 0;
    background: #f2f2f2;
    display: flex;
    justify-content: center;
    .box {
      // padding: 1.515152rem /* 50/33 */ 9.090909rem /* 300/33 */;
      width: 60%;
      min-width: 1000px;
      padding: 1.515152   /* 50/33 */ 0;
      background: #fff;
      .step {
        display: flex;
        width: 90%;
        transform: translateX(5%);
        .step-item {
          flex: 1;
          .serial {
            position: relative;
            height: 45px;
            .num {
              // background: #3E84D6;
              // color: #fff;
              border: 1px solid #a8a8a8;
              color: #999;
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 45px;
              height: 45px;
              font-size: 30px;
              position: absolute;
              left: 50%;
              background: #fff;
              transform: translateX(-50%);
              z-index: 2;
            }
            .line {
              border-top: 1px dashed #ccc;
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              width: 100%;
            }
          }
          .step-text {
            color: #999;
            text-align: center;
            padding: 20px 0;
            font-size: 0.727273rem /* 24/33 */;
          }
        }
        .active {
          .serial {
            .num {
              background: #3E84D6;
              border: 1px solid #3E84D6;
              color: #fff;
            }
            .line {
              border-top: 1px solid #3E84D6;
            }
          }
          .step-text {
            color: #3E84D6;
          }
        }
      }
      .form {
        padding: 1.818182rem /* 60/33 */ 3.636364rem /* 120/33 */;
        box-sizing: border-box;

        .captcha-box {
          display: flex;
          .capIpt {
          }
          .capBtn {
            margin-left: 40px;
          }
        }
        .next {
          width: 100%;
        }
        .regis {
          width: 100%;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          .regis-img-box {
            margin-right: 5px;
          }
          img {
            cursor: pointer;
          }
          .protocol {
            cursor: pointer;
            color: #3E84D6;
          }
        }
        .action {
          .title {
            color: #3E84D6;
            padding: 15px 0;
            font-size: 26px;
            border-bottom: 1px solid #cccccc;
            margin-bottom: 1.515152rem /* 50/33 */;
          }
          .img-infor-item {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            .up-left {
              .upText {
                color: #d3d3d3;
                display: flex;
                flex-direction: column;
                align-items: center;
                position: relative;
                i {
                  position: absolute;
                  top: 60%;
                }
              }
            }
            .example-right {
              display: flex;
              align-items: center;
              height: 188px;
              .label {
                padding: 0 10px;
              }
              .eximg {
                img {
                  height: 188px;
                }
              }
            }
          }
        }
        .from-btn{
          display: flex;
          justify-content: center;
          padding-top: 10px;
          .next{
            width: auto;
          }
        }
      }
      .reSuccess {
        text-align: center;
        .suText {
          text-align: center;
          font-size: 24px;
          padding: 10px 0;
          color: #3E84D6;
        }
      }
    }
  }
  .regis-protocol {
    background: #f2f2f2;
    padding: 20px 60px;
    .regis-protocol-container {
      background: #fff;
      padding: 10px 40px;
      line-height: 16px;
      .pro-tit {
        font-size: 16px;
        text-align: center;
      }
      Section {
        padding-top: 10px;
      }
    }
  }
}
</style>