<template>
<div class="bra-goods-info">
    <GoodsInfo></GoodsInfo>
</div>
</template>

<script>
export default {
    data(){
        return{
            
        }
    },
    methods:{

    },
    created(){

    },
    props:{
        
    }
}
</script>

<style lang="scss">
.bra-goods-info{
}
</style>