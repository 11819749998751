<template name="countDown">
	<!-- 倒计时组件 -->
	<div class="count-down">
			<div class="countdown-numbers" :style="{border:hasBorder,borderColor:borderColor, marginTop:marginTop, minWidth:minWidth, height:width, fontSize:fontSize, lineHeight:width, color:fontColor, background:bgrColor,fontWeight:fontW}">{{h}}</div>
			<div class="countdown-splitor" :style="{color:splitorColor,marginTop:marginTop, lineHeight:width, fontSize:fontSize}">{{splitorText[0]}}</div>
			<div class="countdown-numbers" :style="{border:hasBorder,marginTop:marginTop,borderColor:borderColor, minWidth:minWidth, height:width, fontSize:fontSize, lineHeight:width, color:fontColor, background:bgrColor,fontWeight:fontW}">{{i}}</div>
			<div class="countdown-splitor" :style="{color:splitorColor,marginTop:marginTop,lineHeight:width, fontSize:fontSize}">{{splitorText[1]}}</div>
			<div class="countdown-numbers" :style="{border:hasBorder,marginTop:marginTop,borderColor:borderColor, minWidth:minWidth, height:width, fontSize:fontSize, lineHeight:width, color:fontColor, background:bgrColor,fontWeight:fontW}">{{s}}</div>
			<div class="countdown-splitor" :style="{color:splitorColor,lineHeight:width, fontSize:fontSize,marginTop:marginTop}">{{splitorText[2]}}</div>
	</div>
</template>
<script>
export default {
	props: {
		bgrColor: {
			type: String,
			default: "#FFFFFF"
		},
		borderColor:{
			type:String,
			default : "#000000"
		},
		fontColor: {
			type: String,
			default: "#000000"
		},
		fontSize: {
			type: String,
			default: "22px"
		},
		splitorColor: {
			type: String,
			default: "#000000"
		},
		
		timer:{
			default:0
		},
		width : {
			type:String,
			default:"36px"
		},
		minWidth : {
			type:String,
			default:"36px"
		},
		marginTop : {
			type:String,
			default:""
		},
		splitorText :{
			default(){
				return [':',':','']
			}
		},
		// 加的字体粗细
		fontW:{
			default:400
		},
		bor:{
			default:true
		}
	},
	data() {
		return {
			h : "00",
			i : "00",
			s : "00",
			leftTime:0,
			timerInter : null
		}
	},
	created(e){
		// 如果是纯数字。转成对应格式
		let tempTimer = ""
		if(Number(this.timer)>0){
			tempTimer = this.$hnwTools.formatDateTime(this.timer/1000)
		}else{
			tempTimer = this.timer
		}
		let reg = /^([0-9]{4})-([0-9]{2})-([0-9]{2}) ([0-9]{2}):([0-9]{2}):([0-9]{2})$/;
		// 时间格式 2020-10-26 23:23:20
		let res = tempTimer.match(reg);
		
		if (res == null){
			console.log('时间格式错误');
			return false;
		}else{
			let year = parseInt(res[1]);
			if (year < 1000) { console.log('时间格式错误'); return false; }
			
			let month = parseInt(res[2]);
			let day = parseInt(res[3]);
			let h = parseInt(res[4]);
			if (h < 0 || h > 24) { console.log('时间格式错误'); return false; }
			let i = parseInt(res[5]);
			if (i < 0 || i > 60) { console.log('时间格式错误'); return false; }
			let s = parseInt(res[6]);
			if (s < 0 || s > 60) { console.log('时间格式错误'); return false; }
			
			let leftTime = new Date(year, month - 1, day, h, i, s);
			
			this.leftTime = leftTime;
			this.countDown();
			this.setInterValFunc();
		}
	},
	methods: {
		setInterValFunc(){
			this.timerInter = setInterval(()=>{ 
				this.countDown(this);
			}, 1000);
		},
		countDown(){
			let leftTime = this.leftTime - new Date();
			
			let hours = 0, minutes = 0, seconds = 0;
			if (leftTime > 0) {
				
				hours = parseInt(leftTime / 1000 / 60 / 60 , 10);
				minutes = parseInt(leftTime / 1000 / 60 % 60, 10);
				seconds = parseInt(leftTime / 1000 % 60, 10);
			}else{
				
				// clearTimeout(this.timerInter);
				clearInterval(this.timerInter)
				this.$emit('endDo');
			}
			if (hours < 10) { hours = '0' + hours;}
			
			if (minutes < 10) { minutes = '0' + minutes; }
			if (seconds < 10) { seconds = '0' + seconds; }
			this.h = hours; this.i = minutes; this.s = seconds;
		}
	},
	computed:{
		hasBorder(){
			if(!this.bor){
				return "none"
			}else{
				return ""
			}
		}
	}
}
</script>
<style lang="scss" scoped>
.count-down{
	display:flex;
	flex-wrap:nowrap;
	justify-content:center;
	.countdown-splitor{
		justify-content:center;
		margin: 0 4px;
	}
	.countdown-numbers{
		border-radius:0;
		text-align:center;
		border:1px solid #000000;
		font-size:18px;
		
	}
}
</style>