// index.php?act=setlist&op=getlist
import { post } from "axios"
import store from '../../../store'


const getGysNum = async (data)=>{
    return post("index.php?act=store_info&op=goodsInfoOe",{
        ...data,
        brands:store.state.brands||""
    })
}
// 调起获取支付宝的二维码
const getAlipayEwm = async (data)=>{
    return post("index.php?act=pay&op=Alipay",{
        ...data
    })
}
// 是否支付成功
const getMealOk = async (data)=>{
    return post("index.php?act=vin_meal&op=mealOk",{
        ...data
    })
}
// 获取套餐列表
const getMeaList = async ()=>{
    return post("index.php?act=vin_meal&op=vin_meal")
}

// 使用记录
const addUseHis = async (data)=>{
    return post("index.php?act=vin_meal&op=recharge_use",{
        ...data
    })
}
// 获取扣费设置
const getFeeSite = async (ins_id)=>{
    return post("index.php?act=vin_meal&op=deduction_install",{
        ins_id
    })
}
// 获取判断搜索记录时间
const getSearTime = async (data)=>{
    return post("index.php?act=vin_meal&op=recordInfo",{
        ...data
    })
}
// 获取车型品牌列表
const getOftenList = async (data)=>{
    return post("index.php?act=vin_meal&op=list_automobile")
}
// 获取热
const getRecomGoods = async ()=>{
    return post("index.php?act=web_index&op=epc_Recom")
}
// 批量查询商品

const getVinPartsByOes = async (data)=>{
    let code = ''
    if(store.state.shop==1780){
        code = store.state.is_sh=="0"?'丹阳仓':'上海仓'
    }
    return post("index.php?act=store_info&op=goodsInio",{
        ...data,
        brands:store.state.brands||"",
        code
    })
}
// 添加采购车
const addVinPur = async (data)=>{
    return post("index.php?act=voucher&op=cart_add",{
        ...data
    })
}
// 列表
const getVinPurList = async (data)=>{
    return post("index.php?act=voucher&op=cart_list",{
        ...data
    })
}
const delVinPur = async (data)=>{
    return post("index.php?act=voucher&op=cart_del",{
        ...data
    })
}
const addVinPurPlan = async (data)=>{
    return post("index.php?act=voucher&op=plan_add",{
        ...data
    })
}
const getVinPurPlan = async (data)=>{
    return post("index.php?act=voucher&op=plan_list",{
        ...data
    })
}

const delVinPurPlan = async (data)=>{
    return post("index.php?act=voucher&op=plan_del",{
        ...data
    })
} 
export {
    getGysNum,
    getAlipayEwm,
    getMealOk,
    getMeaList,
    addUseHis,
    getFeeSite,
    getSearTime,
    getOftenList,
    getRecomGoods,
    getVinPartsByOes,
    addVinPur,
    getVinPurList,
    delVinPur,
    addVinPurPlan,
    getVinPurPlan,
    delVinPurPlan, 
}