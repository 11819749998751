<template>
    <div class="UserTalking">
      <div class="comment">

        <div class="comment-tit">
            <span>商品评价</span>
        </div>

        <div class="comment-head">
          <el-row>

            <el-col :span="4">
              <div class="comment-head-left">
              <div class="haoping">
                  好评度
              </div>
              <div class="comment-percent">98%</div>
              </div>

            </el-col>
            <el-col :span="20">
              <div class="comment-butt-box">
              <div class="comment-butt"><div> 不错 </div>       </div>
              <div class="comment-butt"> <div> 好用 </div>   </div>
              <div class="comment-butt"> <div>  建议购买</div></div>
              <div class="comment-butt"> <div>  可以</div> 4</div>
              <div class="comment-butt"> <div> 很棒 </div>5</div>
              <div class="comment-butt"> <div>  很ok</div>6</div>
              <div class="comment-butt"> <div> 赞 </div>7</div>
              </div>

            </el-col>
          </el-row>

        </div>

      </div>


      <div class="all-comment">
        <el-row>
          <el-col :span="2">   <div class="all-comm-msg " ><span style="color:#FF2020" class="all-comm-tit">全部评价()</span></div>  </el-col>
          <el-col :span="2">   <div class="all-comm-msg "> <span class="all-comm-tit">晒单</span> </div> </el-col>
          <el-col :span="2">   <div class="all-comm-msg "> <span class="all-comm-tit">视频晒单</span></div></el-col>
          <el-col :span="2">  <div class="all-comm-msg ">  <span class="all-comm-tit">追评</span></div></el-col>
          <el-col :span="2">  <div class="all-comm-msg ">  <span class="all-comm-tit" > 好评</span></div></el-col>
          <el-col :span="2">  <div class="all-comm-msg ">  <span class="all-comm-tit">中评</span></div> </el-col>
          <el-col :span="2">  <div class="all-comm-msg ">  <span class="all-comm-tit">差评</span></div></el-col>
          <el-col :span="2">  <div class="all-comm-msg ">  <span class="all-comm-tit">只看当前评论</span></div></el-col>
        <el-col :span="2">   <div class="all-comm-msg "> <span class="all-comm-tit">推荐排序</span></div></el-col>
        </el-row>
      </div>


      <!-- 评论的详细信息 -->



      <div  class="comment-list">
        <div  v-for="(eva,index) in evaList" :key="index" class="eva-item"> 
          <div class="eva-head">
            <el-row>
              <el-col :span="4">
                
                <div class="user-box">   
                  <div class="user-head"><el-avatar :src='eva.headimg'></el-avatar></div>    
                  <div class="user-name"> {{eva.Uname}}</div> </div>  
                
              </el-col>

              <el-col :span="6">
                <el-rate
                  v-model="eva.score"
                  disabled
                  show-score
                  text-color="#ff9900"
                  score-template="{value}">
                </el-rate>
              </el-col>
            </el-row>
          </div>

           <div class="eva-body">
            <div class="user-talking">

              <span>
                {{eva.talk}}
              </span>


            </div>

            <div class="talk-footer-handler">

              <el-row>
                <el-col :span="4">{{eva.shop}}</el-col>
                <el-col :span="14">
                  <span>{{eva.time}}</span>
                </el-col>
                <el-col :span="1"  >
                  <div style="cursor: pointer;"> 举报  </div> 
                </el-col>

                <el-col :span="1"> 
                
                  <img    width="20px" height="20px"   src="../assets/detail/点赞.png" alt=""> 
                  </el-col>
                <el-col :span="1"> <span> {{eva.zan}}</span>  </el-col>
                <el-col  :span="1">     <img    width="19px" height="18px"   src="../assets/detail/评论.png" alt="">   </el-col>
                <el-col :span="1"> <span> {{eva.pl}}</span>  </el-col>
              </el-row>
            </div>
           
          </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  data(){
      return{
        evaList:[
              {Uname:"飞翔2020",zan:2,pl:9,time:'2020-05-06 13:15',shop:"塔图/悦动",score:2.3,headimg:"https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png",talk:"博世的机滤 看着不错 还没有换上 过两天再换京东plus会员良心好评，自从用了京东，再也不去超市了，也再也不用别的电商了，因为京东秒杀一切电商平台！产品满分，服务满分，售后满分，物流满分特别说一下物流速度真的很快，问我为什么喜欢在京东买东西，因为今天买明天就可以送到。然后是包装令人满意，京东自己logo的包装袋和纸盒，还可以再利用重点是产品真的非常好，打开包装惊艳到了，各种高大上，比实体店便宜多了好吧！京东购物这么久，没有一次失望过，产品全五分，整个购物过程非常满意，无论是服务还是产品本身都五分，会推荐朋友们来的，希望能推出更多优惠。即使是第三方商家的东西我都很信任因为京东的平台非常可靠总之很满意的一次购物，会继续买买买！期待更多折扣，期待双11,双12,京东618"},
              {Uname:"上海堡垒",zan:7,pl:9,time:'2020-05-06 13:15',shop:"塔图/悦动",score:4,headimg:"https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png",talk:"博世的机滤 看着不错 还没有换上 过两天再换京东plus会员良心好评，自从用了京东，再也不去超市了，也再也不用别的电商了，因为京东秒杀一切电商平台！产品满分，服务满分，售后满分，物流满分特别说一下物流速度真的很快，问我为什么喜欢在京东买东西，因为今天买明天就可以送到。然后是包装令人满意，京东自己logo的包装袋和纸盒，还可以再利用重点是产品真的非常好，打开包装惊艳到了，各种高大上，比实体店便宜多了好吧！京东购物这么久，没有一次失望过，产品全五分，整个购物过程非常满意，无论是服务还是产品本身都五分，会推荐朋友们来的，希望能推出更多优惠。即使是第三方商家的东西我都很信任因为京东的平台非常可靠总之很满意的一次购物，会继续买买买！期待更多折扣，期待双11,双12,京东618"}
          ]
      }
  },


  methods:{

  }

}
</script>



<style  lang="scss"  scoped>

.comment-tit{
  width: 980px;
  height: 38px;
  background: #F5F5F5;
}

.comment-tit span{
  width: 57px;
  height: 14px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #333333;
  line-height: 38px;
  margin-left: 20px;
}
.comment-head{
  width: 980px;
  height: 100px;
  margin-top: 20px;
}
.comment-percent{
  width: 93px;
  height: 35px;
  font-size: 45px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #FF2020;
}
.haoping{
  width: 50px;
  height: 13px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #666666;
  


}
.comment-head-left{
    width: 230px;
    height: 100px;
    margin-left: 20px;
}
.comment-head-left >div{
  margin-top: 10px;
}
.comment-butt-box{

  width: 800px;
  height: 100px;
  display: flex;
  flex-wrap: wrap;
}

.comment-butt{
  cursor: pointer;
  width: 120px;
  height: 30px;
  border: 1px solid #CCCCCC;
  margin-left: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.comment-butt > span{
  width: 83px;
  height: 13px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #333333;
}
.all-comment{
  width: 980px;
  height: 38px;
  background: #F5F5F5;
  display: flex;
  align-items: center;
}
.all-comm-tit{
  cursor: pointer;
  height: 14px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #333333;
}


.all-comm-msg{
  width: 90px;
  margin-left: 20px;
  background: #F5F5F5;
}

.comment-list{
  margin-top: 10px;
  .eva-item{
    padding-bottom: 10px;
    border-bottom: 1px solid #ccc;
    .user-box{
      width: 120px;
    
      display: flex;
      justify-content: space-around;
      align-items: center;
    }
  }
  .eva-item:last-child{
    border: none;
  }
}

.user-talking{
  width: 842px;
  height: 141px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 21px;
  margin-left: 133px
}
.talk-footer-handler{
  margin-left: 140px;
}

.user-head{
  width: 40px;
  height: 40px;
}
.user-name{
  width: 70px;
  align-items: center;
}
.comment-list >div{
  font-family: PingFang SC;
  font-weight: 500;
  color: #666666;
  line-height: 21px;
  margin-top: 10px;
}
</style>