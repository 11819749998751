<template>
  <div class="statement">
      <div class="w">
          <div class="monthly">
              <div class="monthly-title">
                  月度合作进展
              </div>
              <div id="columnar" style="height: 400px; margin-top: 10px;"></div>
          </div>
          <div class="annual">
              <div class="monthly-title">
                  年度合作进展
              </div>
              <div id="year" style="height: 200px; margin-top: 10px;"></div>
          </div>
      </div>
  </div>
</template>

<script>
    import * as echarts from 'echarts';
export default {
    
    data(){
      return{
          scClearList:[],
          allLsit:[],
      }
    },
    methods: {
        toDecimal(x) {    
        var val = Number(x)   
        if(!isNaN(parseFloat(val))) {    
        val = val.toFixed(1);
        }  
        return  val;     
    },
        histogram(){
            let chartDom = document.getElementById('columnar');
            let myChart = echarts.init(chartDom);
            let option;
           option = {
                // title: {
                //     text: '世界人口总量',
                //     subtext: '数据来自网络'
                // },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                legend: {
                    data: ['计划完成金额', '实际完成金额']
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                yAxis: {
                    type: 'value',
                    boundaryGap: [0, 0.01]
                },
                xAxis: {
                    type: 'category',
                    data: ['一月' + '('+this.scClearList[0].bestval + '%'+')', '二月' + '('+this.scClearList[1].bestval + '%'+')', '三月'+ '('+this.scClearList[2].bestval + '%'+')', '四月'+ '('+this.scClearList[3].bestval + '%'+')', '五月'+ '('+this.scClearList[4].bestval + '%'+')', '六月'+ '('+this.scClearList[5].bestval + '%'+')','七月'+ '('+this.scClearList[6].bestval + '%'+')','八月'+ '('+this.scClearList[7].bestval + '%'+')','九月'+ '('+this.scClearList[8].bestval + '%'+')','十月'+ '('+this.scClearList[9].bestval + '%'+')','十一月'+ '('+this.scClearList[10].bestval + '%'+')','十二月'+ '('+this.scClearList[11].bestval + '%'+')'],
                    axisTick: {
                        // alignWithLabel: false,
                        // show: false
                        },
                        axisLabel: {
                            fontSize: 12,
                            lineHeight: 20,
                            interval: 0
                        }
                        
                },
                series: [
                    {
                        name: '实际完成金额',
                        type: 'bar',
                        data: this.scClearList.map(item=>item.amount),
                        itemStyle: {
                            color: '#3E84D6'
                        },
                        label:{
                            position:'top',
                            show:true,
                            formatter(data){
                                return data.value;
                            }
                        }
                    },
                    {
                        name: '计划完成金额',
                        type: 'bar',
                        data: this.scClearList.map(item=>item.number),
                        itemStyle: {
                            color: '#7DB9FF'
                        },
                        label:{
                            position:'top',
                            show:true,
                            formatter(data){
                                return data.value;
                            }
                        }
                    }
                   
                    
                ]
            };

            option && myChart.setOption(option);
        },
        annualStatement(){
            var chartDom = document.getElementById('year');
            var myChart = echarts.init(chartDom);
            var option;

            option = {
                // title: {
                //     text: '世界人口总量',
                //     subtext: '数据来自网络'
                // },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                legend: {
                    data: ['计划完成金额', '实际完成金额']
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: {
                    type: 'value',
                    boundaryGap: [0, 0.01]
                },
                yAxis: {
                    type: 'category',
                    data: ['年度' + '(' + this.toDecimal(this.allLsit[1]/this.allLsit[0]*100) + '%'+')'],
                     show: false
                },
                series: [
                    {
                        name: '计划完成金额',
                        type: 'bar',
                        data: [this.allLsit[0]],
                        itemStyle: {
                            color: '#7DB9FF'
                        },
                        label:{
                            position:'right',
                            show:true,
                            formatter(data){
                                return data.value;
                            }
                        }
                    },
                    {
                        name: '实际完成金额',
                        type: 'bar',
                        data: [this.allLsit[1]],
                        itemStyle: {
                            color: '#3E84D6'
                        },
                        label:{
                            position:'right',
                            show:true,
                            formatter(data){
                                return data.value;
                            }
                        }
                    }
                ]
            };

            option && myChart.setOption(option);
        },
        
    },
    async created(){
        let res = await this.$api.getReport({
            store_id:this.$store.state.store_id
        })
        this.scClearList = res.sc_clear
        this.allLsit= res.all
        this.$nextTick(()=>{
            this.histogram()
            this.annualStatement()
        })
    }
}
</script>

<style lang="scss">
.statement{
    background: #F7F7F7;
    .w{
        width: 1200px;
        margin: 0 auto;
        padding-top: 10px;
        
        .monthly{
            height: 480px;
            background-color: #fff;
            overflow: hidden;  //解决盒子塌陷
            border-bottom: 1px solid #F1F1F1;
            .monthly-title{
                width: 1160px;
                height: 43px;
                line-height: 43px;
                background: #F1F1F1;
                margin-left: 20px;
                margin-top: 10px;
                font-size: 18px;
                font-weight: bold;
                color: #3E84D6;
                padding-left: 13px;
            }
        }
        .annual{
            height: 300px;
            background-color: #fff;
            overflow: hidden;  //解决盒子塌陷
            .monthly-title{
                width: 1160px;
                height: 43px;
                line-height: 43px;
                background: #F1F1F1;
                margin-left: 20px;
                margin-top: 27px;
                font-size: 18px;
                font-weight: bold;
                color: #3E84D6;
                padding-left: 13px;
            }
        }
    }
}
</style>