
export const deCentralization = (requireContext, exinclude = /index/, fn = (argv) => argv) => {
const sourceMap = {}

const paths = requireContext.keys().filter(p => {
    return !exinclude.test(p)
})
for (let p of paths) {
    const ss = fn(requireContext(p))
    for (let s in ss) {
    sourceMap[s] = ss[s]
    }
}
return sourceMap
};