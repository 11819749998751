<template>
<div class="store-Intr w">
    <div class="intr-modu">
        <div class="modu-title">
            <div class="text">
                公司介绍
            </div>
            <div class="title-line"></div>
        </div>
        <div class="modu-body com-modu">
            <div class="background"></div>
            <div class="company-msg">
                <div class="msg-title">
                    <div>
                        <div>
                        Company
                        </div>
                        <div>
                         Introduction 
                        </div>
                    </div>
                    <div>
                        公司介绍
                    </div>
                </div>
                <div class="msg-content">
                    <div class="msg-box">
                        <div class="msg-inte-text">
                            <div class="text-head">
                                <div class="text-gd">百年品牌</div>
                                <div class="text-gd">值得信赖</div>
                            </div>
                            <div class="text-body">
                                {{$store.state.shopInfo.store_description}}
                            </div>
                        </div>
                        <div class="dz-img">
                            <img src="../../../assets/store/introduce/che.png" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="intr-modu ">
        <div class="modu-title">
            <div class="text">
                联系我们
            </div>
            <div class="title-line"></div>
        </div>
        <div class="modu-body lx-modu">
            <div class="lx-group">
                <!-- <div>{{$store.state.shopInfo.}}</div>
                        <div>{{$store.state.shopInfo.}}</div> -->
                <div class="lx-item">
                    <img src="../../../assets/store/introduce/dizhi.png" alt="">
                    <div class="lx-title">
                        公司地址
                    </div>
                    <div class="lx-line"></div>
                    <div class="lx-msg">
                        {{$store.state.shopInfo.shop_area_info}}
                        
                    </div>
                </div>
                <div class="lx-item">
                    <img src="../../../assets/store/introduce/shouji.png" alt="">
                    <div class="lx-title">
                        联系电话
                    </div>
                    <div class="lx-line"></div>
                    <div class="lx-msg">
                        {{$store.state.shopInfo.shop_contactphone}}
                        
                    </div>
                </div>
                <div class="lx-item">
                    <img src="../../../assets/store/introduce/rentou.png" alt="">
                    <div class="lx-title">
                        联系人
                    </div>
                    <div class="lx-line"></div>
                    <div class="lx-msg">
                        {{$store.state.shopInfo.shop_contactname}}
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="experience">
        <div class="experience-title">
            行业经验丰富.价格从优服务质量好
        </div>
        <div class="title-line"></div>
        <div class="experience-vice-title">
            产品种类款式规格多样欢迎新老顾客前来咨询采购
        </div>
        <div class="experience-cards">
            <div class="experience-card">
                <div class="card-major">
                    <div class="title">
                        <div>经验丰富</div>
                        <div>省心省力</div>
                        <div class="line"></div>
                    </div>
                    <div class="desc">  
                        多年行业生产经验 本店产品均为厂家供应 质优价廉品质放心 
                    </div>
                </div>
                <div class="card-bottom">
                    01
                </div>
            </div>
            <div class="experience-card">
                <div class="card-major">
                    <div class="title">
                        <div>生产厂家</div>
                        <div>值得信赖</div>
                        <div class="line"></div>
                    </div>
                    <div class="desc">  
                        所有产品严格按照 标准生产 质量放心  
                    </div>
                </div>
                <div class="card-bottom">
                    02
                </div>
            </div>
            <div class="experience-card">
                <div class="card-major">
                    <div class="title">
                        <div>品质放心</div>
                        <div>贴心服务</div>
                        <div class="line"></div>
                    </div>
                    <div class="desc">  
                        如产品发生质量问题 请及时联求客服退换货 全程指导服务
                    </div>
                </div>
                <div class="card-bottom">
                    03
                </div>
            </div>
            <div class="experience-card">
                <div class="card-major">
                    <div class="title">
                        <div>发货及时</div>
                        <div>贴心售后</div>
                        <div class="line"></div>
                    </div>
                    <div class="desc">  
                        贴心的售前售后服务 欢迎新老顾客前来咨询
                    </div>
                </div>
                <div class="card-bottom">
                    04
                </div>
            </div>
        </div>
        <div class="background"></div>
    </div>
    <div class="intr-modu" v-if="$store.state.shopInfo.cerImgs.length>0">
        <div class="modu-title">
            <div class="text">
                荣获多项权威认证
            </div>
            <div class="title-line"></div>
        </div>
        <div class="modu-body cer-modu">
            <div class="cer-content">
                <div class="cer-list">
                    <div class="cer-item" v-for="item in $store.state.shopInfo.cerImgs" :key="item">
                        <img :src="item" class="cer-img" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="intr-modu">
        <div class="modu-title">
            <div class="text">
                主营产品
            </div>
            <div class="title-line"></div>
        </div>
        <div class="modu-body zy-modu">
            <div class="background"></div>
            <div class="zy-box">
                <div class="zy-list">
                    <div class="zy-item" v-for="zy in $store.state.shopInfo.zyGoods" :key="zy">
                        <img src="../../../assets/store/introduce/kuang.png" alt="">
                        <span> {{zy}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    data(){
        return{
            
        }
    },
    methods:{

    },
    created(){
        
    },
    props:{
        chain:""
    }
}
</script>

<style lang="scss">

$xyblue:#1B458B;
$intrblue:#16489B;
.store-Intr{
    .background{
        position: absolute;
        left: -800px;
        background: $intrblue;
        width: 3000px;
        // width: 1200px;
        // left: 50%;
        // transform: translateX(-50%);
    }
    .intr-modu{
        
        .modu-title{
            text-align: center;
            position: relative;
            margin-bottom: 20px;
            .text{
                color: $xyblue;
                padding: 10px 0;
                font-size: 24px;
            }
            .title-line{
                background: $intrblue;
                // width: 132px;
                width: 80px;
                height: 5px;
                
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
            }
        }
        .modu-body{
            position: relative;
        }
        .com-modu{
            height: 660px;
            .background{
                position: absolute;
                height: 300px;
                top: 16px;
            }
            
            .company-msg{
                position: absolute;
                top: 30px;
                
                .msg-title{
                
                    color: #fff;
                    font-size: 24px;
                    display: flex;
                    align-items: flex-end;
                    justify-content: space-between;
                    padding: 15px 0;
                    border-bottom: 2px solid #fff;
                    >div{
                        >div{

                        }
                    }
                }
                .msg-content{
                    background: #fff;
                    box-shadow: 0px 0px 8px 0px rgba(13,50,111,0.7000);
                    border-radius: 22px;
                    margin-top: 20px;
                    .msg-box{
                        display: flex;
                        .msg-inte-text{
                            padding: 10px;
                            flex: 1;
                            .text-head{
                                display: flex;
                                justify-content: space-between;
                                .text-gd{
                                    background: $xyblue;
                                    color: #fff;
                                    width: 284px;
                                    height: 70px;
                                    line-height: 70px;
                                    font-size: 24px;
                                    border-radius: 6px;
                                    text-align: center;
                                }
                            }
                            .text-body{
                                overflow: hidden;
                                padding: 10px 0;
                                font-size: 16px;
                                line-height: 24px;
                            }
                        }
                        .dz-img{
                            padding: 10px;
                            img{
                                width: 528px;
                            }
                        }
                    }
                }

            }
        }
        .lx-modu{
            .lx-group{
                display: flex;
                justify-content: space-between;
                .lx-item{
                    background: $intrblue;
                    color: #fff;
                    text-align: center;
                    width: 380px;
                    height: 240px;
                    display: flex;
                    box-sizing: border-box;
                    padding-top: 23px;
                    flex-direction: column;
                    align-items: center;
                    justify-content: space-evenly;
                    img{
                        padding: 5px ;
                        width: 55px;
                    }
                    .lx-title{

                    }
                    .lx-line{
                        width: 40px;
                        height: 3px;
                        background: #DE8834;
                        
                    }
                    .lx-msg{
                        padding: 20px 0;
                        padding-top: 30px;
                    }
                }
            }
        }
        .cer-modu{
         
            .cer-content{
                padding: 10px 0;
                .cer-list{
                    display: flex;
                    justify-content: space-between;
                    .cer-item{
                        width: 23%;
                        height: 300px;
                        img{
                            width: 100%;
                            height: 300px;
                        }
                    }
                }
            }
        }
        .zy-modu{
            height: 220px;
            .background{
                height: 150px;
                bottom: 0;
            }
            .zy-box{
                z-index: 5;
                position: absolute;
                background: #fff;
                width: 1205px;
                height: 180px;
                bottom: 30px;
                padding: 40px;
                box-sizing: border-box;
                .zy-list{
                    display: flex;
                    // justify-content: space-between;
                    flex-wrap: wrap;
                    .zy-item{
                        position: relative;
                        width: 70px;
                        height: 45px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-right: 20px;
                        img{
                            position: absolute;
                            left: 0;
                            top: 0;
                            width: 100%;
                            height: 100%;
                        }

                    }
                }
            }
        }
    }
    .experience{
        text-align: center;
        position: relative;

        height: 600px;
        .background{
            height: 540px;
            top: 20px;
        }
        .title-line{
            position: absolute;
            width: 1144px;
            height: 2px;
            background: #D1D1D1;
            opacity: 0.4;
            z-index: 5;
            left: 50%;
            top: 94px;
            z-index: 5;
            
            transform: translateX(-50%);
        }
        .experience-title{
            position: absolute;
            left: 50%;
            top: 40px;
            z-index: 5;
            color: #ffff;
            transform: translateX(-50%);
            font-size: 30px;
        }
        .experience-vice-title{
            position: absolute;
            top: 120px;
            left: 50%;
            z-index: 5;
            color: #ffff;
            transform: translateX(-50%);
            font-size: 24px;
        }
        .experience-cards{
            display: flex;
            justify-content: space-between;
            position: absolute;
            top: 200px;
            left: 0;
            z-index: 5;
            width: 1200px;
            .experience-card{
                width: 284px;
                height: 300px;
                background: #fff;
                // background: #DE8834;
                border-radius: 14px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-evenly;
                .card-major{
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: space-evenly;
                    padding: 20px 56px;
                    .title{
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        div{
                            font-size: 26px;
                            color: $xyblue;
                            line-height: 29px;
                            font-weight: 700;
                        }
                        .line{
                            width: 62px;
                            height: 7px;
                            background: $xyblue;
                            margin: 8px 0;
                        }
                    }
                    .desc{
                        font-size: 12px;
                        line-height: 14px;
                    }
                }
                .card-bottom{
                    background: #DE8834;
                    color: #fff;
                    padding: 5px 0;
                    height: 60px;
                    line-height: 60px;
                    width: 100%;
                    text-align: center;
                    font-size: 24px;
                    text-align: center;
                }

            }
        }
    }
}
</style>