<template>
  <div class="fankui">
    <div class="product-title">
      <div class="jianhao">反馈中心</div>
      <div class="back" @click="back">
        返回
        <img src="../../../assets/ybj/arrow_right.png" alt="" />
      </div>
    </div>
    <div class="fankui-content">
      <el-form
        label-position="top"
        :model="ruleForm"
        status-icon
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="手机号码" prop="pass">
          <el-input
            type="text"
            v-model="ruleForm.pass"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="邮箱" prop="checkPass">
          <el-input
            type="text"
            v-model="ruleForm.checkPass"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="问题描述或建议" prop="age">
          <el-input type="textarea" v-model.number="ruleForm.age"></el-input>
        </el-form-item>
        <el-form-item label="上传图片" prop="age">
          <el-upload
            action="https://jsonplaceholder.typicode.com/posts/"
            list-type="picture-card"
            :on-preview="handlePictureCardPreview"
            :on-remove="handleRemove"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
          <el-dialog :visible.sync="dialogVisible">
            <img width="100%" :src="dialogImageUrl" alt="" />
          </el-dialog>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')"
            >提交</el-button
          >
          <el-button @click="resetForm('ruleForm')">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    var checkAge = (rule, value, callback) => {
      // if (!value) {
      //   return callback(new Error("年龄不能为空"));
      // }
      // setTimeout(() => {
      //   if (!Number.isInteger(value)) {
      //     callback(new Error("请输入数字值"));
      //   } else {
      //     if (value < 18) {
      //       callback(new Error("必须年满18岁"));
      //     } else {
      //       callback();
      //     }
      //   }
      // }, 1000);
    };
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入手机号码"));
      } else {
        let reg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
        // 去除字符串内所有的空格
      value = value.replace(/\s/g, "");
        if(!reg.test(value)){
            callback(new Error("请输入正确的手机号码"));
        }else{
            if (this.ruleForm.checkPass !== "") {
              this.$refs.ruleForm.validateField("checkPass");
            }
            callback();
        }
        
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入邮箱地址"));
      }else {
        // let reg = /^[A-Za-z0-9]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
        value = value.replace(/\s/g, "");
        // if(reg.test(value)){
        //   callback(new Error("请输入正确的邮箱地址"));
        // }else{
          
        // } 
        callback();
      }
    };
    return {
      ruleForm: {
        pass: "",
        checkPass: "",
        age: "",
      },
      rules: {
        pass: [{ validator: validatePass, trigger: "blur" }],
        checkPass: [{ validator: validatePass2, trigger: "blur" }],
        age: [{ validator: checkAge, trigger: "blur" }],
      },
      dialogImageUrl: '',
      dialogVisible: false
    };
  },
  methods: {
     handleRemove(file, fileList) { 
      },
      handlePictureCardPreview(file) {
        this.dialogImageUrl = file.url;
        this.dialogVisible = true;
      },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert("submit!");
        } else { 
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    back() {
      // this.$emit("")
    },
  },
};
</script>

<style lang="scss" scoped>
.fankui {
  min-height: 100vh;
  .product-title {
    width: 100%;
    height: 40px;
    background: #cbd1e5;
    padding-left: 10px;
    padding-right: 10px;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    .jianhao {
      color: #4564d3;
      line-height: 20px;
      font-size: 14px;
      font-weight: bold;
      padding-left: 10px;
      border-left: 2px solid #4564d3;
      height: 20px;
      margin-top: 10px;
    }
    .back {
      color: #4564d3;
      line-height: 40px;
      font-size: 14px;
      font-weight: bold;
      > img {
        vertical-align: middle;
      }
    }
  }
  .fankui-content {
    background: #fff;
    ::v-deep .el-form-item {
      margin-bottom: 8px;
      padding: 0 10px;
    }
    ::v-deep .el-form-item__label {
      color: #344e8b;
      padding: 0;
    }
    ::v-deep .el-input__inner {
      background: #eee;
    }
    ::v-deep .el-textarea__inner {
      background: #eee;
    }
  }
}
</style>