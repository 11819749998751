<template>
  <div class="details">
      <div class="w">
        <!-- 头部 -->
        <div v class="head">
          <div class="nav">
              <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/find/index' }">找件儿</el-breadcrumb-item>
                <el-breadcrumb-item>{{goodsInfo.goods_name}}商品详情页</el-breadcrumb-item>
              </el-breadcrumb>
          </div>
          <div class="collect">
            <span class="hnw">华诺威总部</span>
            <i class="el-icon-star-off"></i>
            <span class="collect" >收藏店铺</span>
          </div>
        </div>
    <!-- 产品 -->
    <div class="product">
      <h2> {{goodsInfo.goods_name}} </h2>
      <span class="xinxi"> {{goodsInfo.vehicletype}} </span>
    </div>
    <!-- console.log(11) -->
    
    <!-- 产品信息 -->    
      <div class="message">
        <ul>
          <li>
            OE号: {{goodsInfo.oecode}}
          </li>
          <li>
            生产编码: {{goodsInfo.sccode}}
          </li>
          <li>
            库存：{{goodsInfo.goods_storage}}件
          </li>
          <li>
            配件品牌：{{goodsInfo.oenaturebrand}}
          </li>
          <li>
            配件产地: {{goodsInfo.oenatureplace}}
          </li>
        </ul>
        <div class="price">
          <span>价格</span>
          <span class="now">
            <i>¥</i>
             {{goodsInfo.goods_price}}
             </span>
          <del class="formerly">¥ 980.00</del>
        </div>
        
      </div>

      <!-- 数量 承诺 加入购物车 -->
      <div class="amount">
        <div class="quantity">
          数量
           <el-input-number v-model="num" @change="handleChange" :min="1" :max="10" size="mini"></el-input-number>
        </div>
        <div class="carefree ">  
          <span>承诺</span>
          <span>
            <i class="el-icon-circle-check"></i>
            正品
          </span>
          <span>
            <i class="el-icon-circle-check"></i>
            保价
          </span>
          <span>
            <i class="el-icon-circle-check"></i>
            无忧
          </span>
        </div>
        <div class="shopping">
          <el-button type="primary">

            <i class="el-icon-shopping-cart-2"></i>
            加入购物车
            
          </el-button>
        </div>
      </div>

      <!-- 商品详情 -->
      <div class="news">
        
        <h3>商品详情</h3>
        <ul>
          <li>
            <div> 
              <span class="daxiao">标准名称：</span>
               {{goodsInfo.goods_name}}
            </div>
            <div>               
              <span class="daxiao">配件分类: </span>
               {{goodsInfo.oesort}}
            </div>
          </li>
          <li>
            <div> 
              <span class="daxiao">原厂名称：</span>
               {{goodsInfo.factoryname}}
            </div>
            <div>               
                <span class="daxiao">计量单位: </span>
               {{goodsInfo.oecompany}}
            </div>
          </li>
          <li>
            <div> 
                <span class="daxiao">英文名称：</span>
                {{goodsInfo.oeelname}}
            </div>
            <div>               
               <span class="daxiao">规格: </span>
               {{goodsInfo.spec}}
            </div>
          </li>
          <li>
            <div> 
              <span class="daxiao">OE号：</span>
              {{goodsInfo.oecode}}
            </div>
            <div>               
              <span class="daxiao">重量: </span>
              {{goodsInfo.oeweight}}
            </div>
          </li>
          <li>
            <div>
             
              <span class="daxiao">生产编码：</span>
              {{goodsInfo.sccode}}
            </div>
            <div>           
              <span class="daxiao">长度: </span>
              {{goodsInfo.oelength}}
            </div>
          </li>
          <li>  
            <div> 
              <span class="daxiao">配件品牌：</span>
              {{goodsInfo.oenaturebrand}}
            </div>
            <div>           
              <span class="daxiao">宽度: </span>
              {{goodsInfo.oewidth}}
            </div>
          </li>
          <li>
           <div> 
              <span class="daxiao">产地：</span>
              {{goodsInfo.oenatureplace}}
            </div>
            <div>               
              <span class="daxiao">体积: </span>
              {{goodsInfo.oevolume}}
            </div>
          </li>
          <li>
            <div> 
              <span class="daxiao">件号：</span>
              {{goodsInfo.oepart}}
            </div>
            <div>               
              <span class="daxiao">指导售价: </span>
              150.00
            </div>
          </li>
          <li>
            <div> 
              <span class="daxiao">部位码：</span>
              {{goodsInfo.oeposition}}
            </div>
            <div>               
              <span class="daxiao">备注: </span>
              {{goodsInfo.oeremark}}
            </div>
          </li>
        </ul>
      </div>

      <!-- 套内商品 -->
      <div class="news">
        <h3>套内商品</h3>
        <ul>
          <li>
            <div> 
              <span class="daxiao">套装件号：</span>
              <!-- 1542144 -->
            </div>
            <div>               
              <span class="daxiao">套装名称: </span>
              <!-- 发动机 -->
            </div>
          </li>
           <li>
            <div> 
              <span class="daxiao">套装品牌：</span>
              <!-- 博世 -->
            </div>
            <div>               
              <span class="daxiao">套装价格: </span>
              <!-- 150.00 -->
            </div>
          </li>
           <li>
            <div> 
              <span class="daxiao">所属分类：</span>
              <!-- 发动机 -->
              </div>
            <div>               
              <span class="daxiao">套内商品数: </span>
              <!-- 2553796 -->
            </div>
          </li>
           <li>
            <div> 
              <span class="daxiao">仓库：</span>
              <!-- {{goodsInfo.storage}} -->
              </div>
            <div>               
              <span class="daxiao">库存: </span>
              <!-- {{goodsInfo.goods_storage}} -->
            </div>
          </li>
        </ul>
      </div>
      
      <!-- 适配车型 -->
       <div class="news">
        <h3>适配车型</h3>
        <ul>
           <li>
            <div> 
              <span class="daxiao">1：</span>
              <!-- {{goodsInfo.vehicletype}} -->
            </div>
            <div>               
              <span class="daxiao">2: </span>
              <!-- {{goodsInfo.vehicletype}} -->
            </div>
          </li>
          <li>
            <div> 
              <span class="daxiao">3：</span>
              <!-- {{goodsInfo.vehicletype}} -->
            </div>
            <div>               
              <span class="daxiao">4: </span>
              <!-- {{goodsInfo.vehicletype}} -->
            </div>
          </li>
        </ul>
      </div>

      <!-- 互换件 -->
    <div class="interchange">
      <h3>互换件</h3>
        <ul>
          <li>
            <div class="myColor">配件名称</div>
            <div class="myColor">配件品牌</div>
            <div class="myColor">配件编码</div>
            <div></div>
          </li>
          <li>
            <div>
              <!-- {{goodsInfo.oesort}} -->
            </div>
            <div> 
              <!-- {{goodsInfo.oenaturebrand}}  -->
            </div>
            <div>
              <!-- {{goodsInfo.sccode}} -->
            </div>
            <div></div>
          </li>
          <li>
            <div>
              <!-- {{goodsInfo.oesort}} -->
            </div>
            <div>
              <!-- {{goodsInfo.oenaturebrand}} -->
            </div>
            <div>
              <!-- {{goodsInfo.sccode}} -->
            </div>
            <div></div>
          </li>
        </ul>
    </div>

        <!-- 参考价格 -->
      <div class="interchange">
        <h3>参考价格</h3>
          <ul>
            <li>
              <div  class="myColor">序号</div>
              <div  class="myColor">OE号</div>
              <div  class="myColor">商品名称</div>
              <div  class="myColor">配件品牌</div>
              <div  class="myColor">价格</div>
              <div></div>
              <div></div>
            </li>
            <li>
              <div>1</div>
              <div> 
                <!-- {{goodsInfo.oecode}}  -->
              </div>
              <div> 
                <!-- {{goodsInfo.goods_name}}  -->
              </div>
              <div> 
                <!-- {{goodsInfo.oenaturebrand}}  -->
              </div>
              <div> 
                <!-- {{goodsInfo.goods_price}}  -->
              </div>
              <div></div>
              <div></div>
            </li>
            <li>
              <div>2</div>
              <div> 
                <!-- {{goodsInfo.oecode}}  -->
              </div>
              <div> 
                <!-- {{goodsInfo.goods_name}}  -->
              </div>
              <div> 
                <!-- {{goodsInfo.oenaturebrand}}  -->
              </div>
              <div> 
                <!-- {{goodsInfo.goods_price}}  -->
              </div>
              <div></div>
              <div></div>
            </li>
          </ul>
      </div>

      <!-- 配件商信息 -->
        <div class="news" style="padding-bottom: 20px;"> 
        <h3>配件商信息</h3>
        <ul v-if="goodsInfo.store_sup">
         <li>
            <div> 
              <span class="daxiao">企业名称：</span>
              {{goodsInfo.store_sup.store_company_name}}
              </div>
            <div>               
              <span class="daxiao">企业电话：</span>
                 {{goodsInfo.store_sup.store_phone}}
            </div>
          </li>
          <li>
            <div> 
              <span class="daxiao">企业类型：</span>
              {{goodsInfo.store_sup.sc_name}}
              </div>
            <div>               
              <span class="daxiao">所属区域：</span>
              {{goodsInfo.store_sup.area_info}}
            </div>
          </li>
          <li>
            <div> 
              <span class="daxiao">联系人：</span>
               {{goodsInfo.store_sup.contacts_name}}
              </div>
            <div>               
              <span class="daxiao">企业地址：</span>
              {{goodsInfo.store_sup.store_address}}
            </div>
          </li>
          <li>
            <div> 
              <span class="daxiao"> 企业网址：</span>
              {{goodsInfo.store_sup.company_site}}
              </div>
            <div>               
              <span class="daxiao">企业公众号：</span>
              华诺威
            </div>
          </li>
          <li>
            <div> <span class="ss" style=" width: 125px; display: inline-block; text-align:right;">经销商配件品牌：</span> {{goodsInfo.oecarbrand}} </div>
            <div><span class="ss" style=" width: 98px; display: inline-block; text-align:right; margin-left: -13px;">经营汽车品牌：</span>{{goodsInfo.oecarbrand}}</div>
          </li>
        </ul>
      </div> 

      </div>
  </div>
</template>

<script>
export default {

 data() {
      return {
        num: 1,
        goodsInfo:{},
        // store_sup:[]
      };
    },
    async created(){
      let res= await this.$api.getGoodsInfo({
        // goods_id:37234,
        goods_id:this.$route.params.id,
      }) 
      this.goodsInfo = res

      
    },
    methods: {
      handleChange(value) { 
      },
      
      

    },
}

</script>

<style lang="scss" scoped>
  .details{
    border-top: 1px solid #f7f7f7;
  }
  .myColor{
    color: #333 !important;
  }
  .w{
    width: $centerW;
    margin: 0 auto;
    // 头部
    .head{
      height: 50px;
      line-height: 58px;
      .nav{
        float: left;
      .el-breadcrumb{
              line-height: 58px;
          }
      }
      .collect{
        float: right;
        .hnw{
          margin-right: 46px;
        }
        .collect{
          margin-left: 5px;
        }
      }
     
    }
    // 产品
    .product{
      height: 65px;
      background-color: #3E84D6;
      h2{
        color: #fff;
        font-weight: 400;
        padding: 11px 0 13px 10px;
      }
      .xinxi{
        color: #fff;
       margin-left: 11px;
      }
    }
    // 产品信息
    .message{
      height: 65px;
      background-color: #f3f3f3;
      display: flex;
      flex-direction: column;
      ul li{
        float: left;
        padding-left: 23px;
        padding-top: 11px;
      }

      .price{
        padding-left: 23px;
        padding-top: 12px;
        
        .now {
          color: #FF2C2CFF;
          font-size: 24px;
          padding-left: 14px;
          i {
            font-size: 14px;
          }
        }
        .formerly{
          font-size: 12px;
          color: #999;
        }
      }
    }
    // 数量 承诺 加入购物车
    .amount{
      height: 148px;
 
      padding-left: 12px;
      .quantity{
        height: 28px;
        padding-top: 10px;
        
        .el-input-number{
          width: 100px;
          height: 28px;
        }
      }
      .carefree{
        height: 54px;
      line-height: 54px;
       span {
         padding-right: 45px;
         &:nth-child(1) {
         padding-right: 16px;

         }
         .el-icon-circle-check{
           color: #3E84D6FF;
         }
       }

      }
      .shopping {
        
        .el-button{
          width: 160px;
          height: 36px;
          padding: 0;
          line-height: 36px;
            .el-icon-shopping-cart-2{
              width: 28px;
              height: 26px;
              color: #fff;
              font-size: 20px;
            }
        }
      }
    }
    // 商品详情
    .news{
        // height: 304px;
        padding-top: 10px;
        

      h3{
        height: 32px;
        background-color: #f5f5f5;
        line-height: 32px;
        padding-left: 10px;
         }
         ul li{
           color: #666;
           height: 29px;
           border-bottom: 1px solid #ccc;
           line-height: 29px;
          //  display: flex;

           div{
            //  flex: 1;
             width: 570px !important;
             display: inline-block;
            //  margin-left: 10px;
             .daxiao{
              color: #333;
               display: inline-block;
               width: 84px;
              //  background-color: red;
               text-align:right;
             }
           }
         }
    }

  // 互换件
  .interchange{
    padding-top: 10px;
    h3{
        height: 32px;
        background-color: #f5f5f5;
        line-height: 32px;
        padding-left: 10px;
         }
         ul li{
           height: 29px;
           border-bottom: 1px solid #ccc;
           line-height: 29px;
           display: flex;

           div{
             color: #666;
             flex: 1;
            //  width: 580px;
             display: inline-block;
             text-align: center;
             margin-left: -60px;
             .daxiao{
              //  display: inline-block;
               width: 61px;
               
               text-align:left;
             }
           }
         }

  }
  }

</style>
