<template>
    <div class="open-gather hnw-area-center">
        <div  v-if="tempNum==1"> 

            <div class="pur-bannder " v-if="bannerList.length>0">
                <el-carousel height="360px">
                    <el-carousel-item v-for="(banner,index) in bannerList" :key="index"  >
                        <img :src="banner.adv_pic" alt="" @click="bannerJump(banner)">
                    </el-carousel-item>
                </el-carousel>
            </div>
            <div class="pur-module-list">
                
                <div class="module-item">
                    <div class="module-title">
                        <div class="tit-one">
                            <div class="split-char">//</div>
                            <div class="title">预售 秒杀</div>
                            <div class="split-char">//</div>
                        </div>
                        <div class="tit-two">
                            Goods in hot sale
                        </div>
                    </div>
                    <div class="module-content">
                        <div class="type-list">
                            <div class="type-item set-type">
                                <div class="now-head">
                                    <div class="head-title"><span class="red">集中采购</span> 正在预售</div>
                                    <div class="head-more" @click="readMore('coll')">更多<i class="el-icon-arrow-right"></i></div>
                                </div>
                                <div class="goods-list">
                                    <div class="goods-item" v-for="(collect,index) in nowList" :key="index" :class="{'hide':index>1}">
                                        <div class="img-box" @click="tododetail(collect)">
                                            <img :src="collect.groupbuy_image1" alt="">
                                            <div class="over-time">
                                                距结束
                                                <CountDwon 
                                                    fontColor="#fff"
                                                    splitorColor="#fff" 
                                                    :bor="false" 
                                                    bgrColor="transparent"
                                                    minWidth="24px" 
                                                    fontSize="12px"
                                                    :splitorText="['时','分','秒']" 
                                                    :timer="collect.leftTime" 
                                                    width="16px" 
                                                    v-if="collect.leftTime"
                                                ></CountDwon> 
                                            </div>
                                        </div>
                                        <div class="card-info">
                                            <div class="goods-msg">
                                                <div class="tit">
                                                    {{collect.setlist_name}}
                                                   
                                                </div>
                                                <div class="act-desc">
                                                    <div class="price">{{collect.totalsum}}</div>
                                                    大单来袭
                                                </div>
                                                <div class="goods-coll-msg">
                                                    <img src="../../../assets/commom/75.png" alt="">
                                                    <span style="padding-right: 20px;">
                                                    {{collect.buyer_count}}
                                                    </span>
                                                    <img src="../../../assets/commom/qian.png" alt="">
                                                    {{collect.totalsum_now}}
                                                    
                                                </div>
                                            </div>
                                            <div class="coll-rote">
                                                <div class="rote-num">{{collect.rote}}%</div>
                                                <div class="rote-tit">完成率</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="type-item sec-type">
                                <div class="now-head">
                                    <div class="head-title"><span class="red">天天低价</span> 正在秒杀</div>
                                    <div class="head-more" @click="readMore('seck')">更多<i class="el-icon-arrow-right"></i></div>
                                </div>
                                <div class="goods-list">
                                    <div class="goods-item" v-for="(seckil,index) in  seckiList" :key="index" :class="{'hide':index>1}">
                                        <div class="img-box" @click="tododetail(seckil)">
                                            <img :src="seckil.groupbuy_image1" alt="">
                                            <div class="over-time">
                                                距结束
                                                <CountDwon 
                                                    fontColor="#fff"
                                                    splitorColor="#fff" 
                                                    :bor="false" 
                                                    bgrColor="transparent" 
                                                    minWidth="24px" 
                                                    fontSize="12px"
                                                    :splitorText="['时','分','秒']" 
                                                    :timer="seckil.leftTime" 
                                                    width="16px" 
                                                    v-if="seckil.leftTime"
                                                ></CountDwon> 
                                            </div>
                                            
                                        </div>
                                        <div class="card-info">
                                            <div class="goods-msg">
                                                <div class="tit">
                                                   <span class="red"> {{ seckil.discount_rote}}折</span> {{seckil.groupbuy_name}}
                                                </div>
                                                <div class="progress-body">
                                                    <div class="progress1" :style="{width:seckil.buy_quantity/seckil.buy_total*100+'%'}"></div>
                                                </div>
                                                <div class="goods-coll-msg">
                                                    <div class="current red">￥{{seckil.groupbuy_price}}</div>
                                                    <div class="original">￥{{seckil.goods_price}}</div>
                                                </div>
                                            </div>
                                            <div class="coll-rote">
                                                <div class="rote-num">{{seckil.buy_quantity}}</div>
                                                <div class="rote-tit">已抢购件数</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="module-item">
                    <div class="module-title">
                        <div class="tit-one">
                            <div class="split-char">//</div>
                            <div class="title">商品预售 敬请期待</div>
                            <div class="split-char">//</div>
                        </div>
                        <div class="tit-two">
                            Coming soon
                        </div>
                    </div>
                    <div class="module-content">
                        <div class="module-content-item " v-for="(acti,index) in prefoList" :key="index">
                            <img :src="acti.groupbuy_image" alt=""  class="act-img" @click="tododetail(acti)" >
                            <div class="act-info">
                                <div class="act-title">
                                    <div class="set-name">
                                        {{acti.setlist_name}}
                                        <span class="subsidiary-tit">{{acti.remark}}</span>
                                    </div>
                                    <!-- {{acti.goods_name}} -->
                                    <div class="title-count-down">
                                        距离开始
                                        <CountDwon borderColor="#fff" fontColor="#2A6DBB" :bor="false" bgrColor="transparent"  minWidth="0" fontSize="16px" :splitorText="['时','分','秒']" :timer="acti.leftTime" width="16px" v-if="acti.leftTime"></CountDwon> 
                                      
                                    </div>
                                </div>
                                <div class="act-desc">
                                    <div class="price">{{acti.totalsum}}</div>
                                    大单来袭
                                </div>
                            </div>   
                        </div>
                    </div>
                </div>
                <div class="module-item">
                    <div class="module-title">
                        <div class="tit-one">
                            <div class="split-char">//</div>
                            <div class="title">秒杀未开始</div>
                            <div class="split-char">//</div>
                        </div>
                        <div class="tit-two">
                            Not Started
                        </div>
                    </div>
                    <div class="module-content">
                        <div class="ms-pre-list">
                            <div class="goods-item" v-for="(seckil,index) in  preSeckiList" :key="index" :class="{'no-border':(index+1)%4==0}">
                                <div class="img-box" @click="tododetail(seckil)">
                                    
                                    <img :src="seckil.groupbuy_image1" alt="">
                                </div>
                                <div class="card-info">
                                    <div class="goods-msg">
                                        <div class="tit">
                                          {{seckil.groupbuy_name}}
                                        </div>
                                      
                                        <div class="goods-coll-msg">
                                            <div class="current red">￥{{seckil.groupbuy_price}}</div>
                                            <div class="original">￥{{seckil.goods_price}}</div>
                                        </div>
                                    </div>
                                    <div class="coll-rote">
                                        <div class="rote-tit">限量{{seckil.buy_total||seckil.goods_storage}}件</div>
                                    </div>
                                </div>
                                <div class="pre-time">
                                    <span>距开始:</span> <CountDwon 
                                            borderColor="#fff" 
                                            fontColor="#3E84D6"
                                            splitorColor="#3E84D6" 
                                            :bor="false" 
                                            bgrColor="transparent"  
                                            minWidth="0"  
                                            fontSize="12px" 
                                            :splitorText="['时','分','秒']" 
                                            :timer="seckil.leftTime" 
                                            width="16px" 
                                            v-if="seckil.leftTime"
                                        ></CountDwon> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="module-item">
                    <div class="module-title">
                        <div class="tit-one">
                            <div class="split-char">//</div>
                            <div class="title">活动已结束 历史回顾</div>
                            <div class="split-char">//</div>
                        </div>
                        <div class="tit-two">
                            Historical Review
                        </div>
                    </div>
                    <div class="module-content">
                        <swiper :options="swiperOption" ref="mySwiper" class="goods-list">
                            <!-- slides -->
                            <swiper-slide v-for="(item,index) in allOverList" :key="index" >
                                 <div class="goods-item" >
                                        <div class="img-box" @click="tododetail(item)">
                                            
                                            <img :src="item.groupbuy_image1" alt="">
                                        </div>
                                        <div class="card-info" v-if="item.setlist_id">
                                            <div class="goods-msg">
                                                <div class="tit">
                                                    {{item.setlist_name}}
                                                 
                                                </div>
                                                
                                                <div class="goods-coll-msg">
                                                    <img src="../../../assets/commom/75.png" alt="">
                                                    <span style="padding-right: 20px;">
                                                    {{item.buyer_count}}
                                                    </span>
                                                    <img src="../../../assets/commom/qian.png" alt="">
                                                    {{item.totalsum_now}}
                                                    
                                                </div>
                                                <div class="sell-rote">
                                                    已售 {{item.rote}}%
                                                </div>
                                            </div>
                                            <div class="coll-tip">
                                                <img src="../../../assets/store/jdcg.png" class="overImg" alt="" v-if="item.rote>100">
                                                <img src="../../../assets/store/jder.png" class="overImg" alt="" v-else>
                                            </div>
                                            
                                        </div>

                                        <div class="card-info" v-else>
                                            <div class="goods-msg">
                                                <div class="tit">
                                                    {{item.groupbuy_name}}
                                                  
                                                </div>
                                                <div class="progress-body">
                                                    <div class="progress1" :style="{width:item.buy_quantity/item.buy_total*100+'%'}"></div>
                                                </div>
                                                <div class="goods-coll-msg">
                                                    <div class="current">￥{{item.groupbuy_price}}</div>
                                                    <div class="original">￥{{item.goods_price}}</div>
                                                </div>
                                            </div>
                                            <div class="coll-rote">
                                                <div class="sell-num">已售{{item.buy_quantity}}件</div>
                                                
                                            </div>
                                        </div>
                                    </div>
                            </swiper-slide>
                            <!-- <swiper-slide>I'm Slide 2</swiper-slide> -->
                            <!-- Optional controls -->
                            <div class="swiper-pagination"  slot="pagination"></div>
                            <div class="swiper-button-prev" slot="button-prev"></div>
                            <div class="swiper-button-next" slot="button-next"></div>
                            <div class="swiper-scrollbar"   slot="scrollbar"></div>
                        </swiper>
                    </div>
                </div>
            </div>
        </div>
        <template v-else-if="tempNum==2">
            <div class="nav-list" @click="tempNum=1">
               << 返回预售 
            </div>
            <div class="acti-list">
                <div class="acti-item" v-for="(actiItem,index) in actiList" :key="index" >
                    <div class="img-box" @click="tododetail(actiItem)">
                        <div class="over-time" :class="{'redback':!actiItem.setlist_id}">
                            距结束
                            <CountDwon 
                                fontColor="#fff"
                                splitorColor="#fff" 
                                :bor="false" 
                                bgrColor="transparent" 
                                minWidth="24px" 
                                fontSize="12px"
                                :splitorText="['时','分','秒']" 
                                :timer="actiItem.leftTime" 
                                width="16px" 
                                v-if="actiItem.leftTime"
                            ></CountDwon> 
                        </div>
                        <img :src="actiItem.groupbuy_image1" alt="">
                    </div>
                    <div class="card-info" v-if="actiItem.setlist_id">
                        <div class="goods-msg">
                            <div class="tit">
                                {{actiItem.setlist_name}}
                                
                            </div>
                            <div class="act-desc">
                                <div class="price">{{actiItem.totalsum}}</div>
                                大单来袭
                            </div>
                            <div class="goods-coll-msg">
                                <img src="../../../assets/commom/75.png" alt="">
                                <span style="padding-right: 20px;">
                                {{actiItem.buyer_count}}
                                </span>
                                <img src="../../../assets/commom/qian.png" alt="">
                                {{actiItem.totalsum_now}}
                                
                            </div>
                        </div>
                        <div class="coll-rote">
                            <div class="rote-num">{{actiItem.rote}}%</div>
                            <div class="rote-tit">完成率</div>
                        </div>
                    </div>
                    <div class="card-info" v-else>
                        <div class="goods-msg">
                            <div class="tit">
                                <span class="red"> {{ actiItem.discount_rote}}折</span> {{actiItem.groupbuy_name}}
                            </div>
                            <div class="progress-body">
                                <div class="progress1" :style="{width:actiItem.buy_quantity/actiItem.buy_total*100+'%'}"></div>
                            </div>
                            <div class="goods-coll-msg">
                                <div class="current red">￥{{actiItem.groupbuy_price}}</div>
                                <div class="original">￥{{actiItem.goods_price}}</div>
                            </div>
                        </div>
                        <div class="coll-rote">
                            <div class="rote-num">{{actiItem.buy_quantity}}</div>
                            <div class="rote-tit">已抢购件数</div>
                        </div>
                    </div>

                </div>
                
                

            </div>
        </template>
        <template v-else-if="tempNum==3">
            <div class="nav-list" @click="tempNum=1">
               << 返回预售 
            </div>
            <img :src="bodyImg" alt="" srcset="">
        </template>
</div>
</template>

<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
export default {
   
    data(){
        return{
            tempNum:1,
            bannerList:[],
            prefoList:[],
            nowList:[],
            overList:[],
            seckiList:[],
            preSeckiList:[],
            overSeckiList:[],
            swiperOption: { 
                notNextTick: true,
                loop:true,
                slidesPerView : 'auto',
                navigation: { //最新版本的需要这样配置，按钮才有效
                    prevEl:'.swiper-button-prev',//上一张
                    nextEl:'.swiper-button-next'//下一张
                },
                scrollbar: {
                    el: '.swiper-scrollbar',
                    draggable: true
                },
            },
            allOverList:[],
            actiList:[],
            bodyImg:""
        }
    },
    methods:{
        async bannerJump(banner){
			
            if(banner.goods_id){
                try {
                    let jumpMsg = JSON.parse(banner.goods_id)
                    if(jumpMsg.type=='goods'){
                        const res = await this.$api.getGoodsInfo({
                            goods_id:jumpMsg.data,
                            chain:this.chain,
                            shop:this.$store.state.for_branch||this.$store.state.shop,
                        })
                        console.log("当前商品是",res);
                        this.goRecommend(res)
                    }else if(jumpMsg.type=='brand'){

                        this.$emit("searchText",jumpMsg.data)
                        // this.$router.push('/find/index')
                    }else if(jumpMsg.type=='link'){
                        return 
                        this.$router.push(jumpMsg.data)
                    }else if(jumpMsg.type=='bodyImg'){
                        // localStorage.setItem("web-banner-body-img",jumpMsg.data)
                        this.bodyImg = jumpMsg.data
                        this.tempNum = 3
                        // this.$router.push("/bodyImg")
                    }
                } catch (error) {
                    console.log("解goods_id信息发生错误",banner.goods_id);
                }
                
            }else if(banner.img.search('vin')!=-1){
                if(!this.token){
                    this.$warMsg("未登录账号")
                    return this.$router.push("/login")
                    
                }else if(this.$isCompany()){
                    let link = location.href.split("#")[0]
                    window.open(link + '#/epc/home/vin')
                    // if(this.$store.state.shop==1780){
                    // }else{
                    // 	this.$warMsg("您的账号不能查看电子目录")
                    // }
                }
            }
        },
        readMore(type){
            if(type=='coll'){
                this.actiList = this.nowList
            }else{
                this.actiList = this.seckiList
            }
            this.tempNum = 2
        },
        tododetail(acti){
            if(acti.isHnwBanner){
                return 
            }
            if(this.$isCompany()){

                console.log("acti",acti)
                acti.preRou = "开放预售"
                acti.jd_shop = this.$store.state.for_branch||this.$store.state.shop
                acti.jd_chain = this.$store.state.for_branch?3:1
                localStorage.setItem("hnw-web-item-data",JSON.stringify(acti))
                this.$emit("showInfo")

            }
        },
        goRecommend(acti){
            if(this.$isCompany()){
                acti.preRou = "店铺轮播图"
                localStorage.setItem("hnw-web-item-data",JSON.stringify(acti))
                this.$emit("showInfo")
                // this.$router.push(rou)
            }
        },
        // 处理时间大于一天的情况和完成率，以及标记上是否可以购买
        handlerDay(){

            // 计算即将开始时间
            // (acti.totalsum_now*10000) / (acti.totalsum*10000*10000)
            // let arrs = ["prefoList","nowList","overList"]

            let arrs = ["prefoList","nowList","preSeckiList","seckiList","overList","overSeckiList"]
            
            let key = ""
            // let dayTime =  60*60*24*1000
            arrs.forEach(arr=>{
                if(arr=='prefoList'||arr=='preSeckiList'){
                    key = "start_time"
                    
                }else{
                    key = "end_time"
                }
                
                this[arr].forEach(acti=>{
                    let leftTime  = 0
                    if(arr=='prefoList'||arr=='preSeckiList'){
                        
                        acti[key] = acti[key]*1000
                         this.$set(acti,"notbuy",'pre')
                        leftTime =  acti[key] - new Date()
                    }else{
                        leftTime =  acti[key] - new Date()
                    }
                   
                    this.$set(acti,"leftTime",acti[key])
                   
                    if(arr=='nowList'||arr=='overList'||arr=='seckiList'||arr=='overSeckiList'){
                        // let rote = parseInt(acti.totalsum_now*100) / (acti.totalsum*100) *100
                        
                        let rote = ""
                        if(Number(acti.totalsum)>0){
                            rote = this.$hnwTools.div(acti.totalsum_now,acti.totalsum) *100
                        }else{
                            rote =  0
                           
                        }
                        this.$set(acti,"rote",Number(rote.toFixed(2)))
                        if(arr=="overList"||arr=='overSeckiList'){
                            this.$set(acti,"notbuy",'over')
                        }

                        if(arr=='seckiList'){
                            let discount_rote = this.$hnwTools.div(acti.groupbuy_price,acti.goods_price) *10
                            this.$set(acti,"discount_rote",Number(discount_rote.toFixed(1)))
                        }
                    }
                    
                })


                
            })
        }

    },
    async created(){
        if(this.$store.state.for_branch){
            this.chain = 3
        }else{
            this.chain = 1
        }

        let bannerRes = await this.$api.getHomeBanner({
            source:4,
            chain:this.chain,
            shop:this.$store.state.for_branch||this.$store.state.shop
        })
        
        if(bannerRes.length>0){
            this.bannerList = bannerRes
        }
        
        this.nowList = await this.$api.getSetList({
            chain:this.chain,
            shop:this.$store.state.for_branch||this.$store.state.shop,
            mark:1
        })
        
        this.prefoList = await this.$api.getSetList({
            mark:2,
            chain:this.chain,
             shop:this.$store.state.for_branch||this.$store.state.shop,
        })
        this.overList = await this.$api.getSetList({
            mark:3,
            chain:this.chain,
            shop:this.$store.state.for_branch||this.$store.state.shop
        })

       this.seckiList = await this.$api.getSeckiList({
            mark:1,
            chain:this.chain,
            shop:this.$store.state.for_branch||this.$store.state.shop
        })
       this.preSeckiList = await this.$api.getSeckiList({
            mark:2,
            chain:this.chain,
            shop:this.$store.state.for_branch||this.$store.state.shop,
        })
        this.overSeckiList = await this.$api.getSeckiList({
            mark:3,
            chain:this.chain,
            shop:this.$store.state.for_branch||this.$store.state.shop
        })

        // 处理时间
        this.handlerDay()
        
        this.allOverList = [
            ...this.overList,
            ...this.overSeckiList,
        ]
        // console.log('this.allOverList',this.allOverList)
    },
    components:{
        swiper,
        swiperSlide
    }
}
</script>

<style lang="scss">
.open-gather{
    background: #fff;
    .hnw-area-center{

    }
    .detail-box{
        background: #fff;
    }
    
    .card-info{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 0;
        .goods-msg{
            .tit{
                font-size: 18px;
                font-family: PingFang SC;
                font-weight: 500;
                color: #333333;
            }
            .act-desc{
                padding-top: 6px;
                display: flex;
                align-items: center;
                font-size: 14px;
                padding: 10px 0;
                .price{
                    color: #3E84D6;
                    font-size: 14px;
                }
                .desc{
                    display: flex;
                    align-items: center;
                    border-left: 1px solid $split-color;
                    padding-left: 10px;
                    margin-left: 10px;
                    img{

                    }
                    span{
                        padding: 0 5px;
                    }
                }
            }
            .progress-body{
                display: flex;
                margin: 10px 0;
                width: 150px;
                height: 8px;
                
                background-color: #DCDCDC;
                border-radius:  4px;
                overflow: hidden;
                .progress1{
                    // width: 200px;
                    // height: 14px;
                    text-align: center;
                    line-height: 14px;
                    // background-color: #DD5044;
                    background-color: #4385D1;
                    color: #fff;
                    border-radius:  4px 0 0 4px ;
                }
                

            }
            .goods-coll-msg{
                display: flex;
                align-items: center;
                font-size: 12px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #999999;
                
                img{
                    margin-right: 3px;
                    width: 14px;
                }
                .current{
                    font-size: 24px;
                    color: #FF2020;
                    
                }
                .original{
                    font-size: 14px;
                    text-decoration: line-through;
                    color: #999999;
                    margin-top: 2px;
                    margin-left: 5px;
                }
            }
        }
        .coll-rote{
            text-align: center;
            .rote-num{
                font-size: 16px;
                font-family: PingFang SC;
                font-weight: 500;
                color: #FF0000;
                padding-bottom: 5px;
            }
            .rote-tit{
                font-size: 14px;
                font-family: PingFang SC;
                font-weight: 500;
                color: #333333;
            }
        }
    }
    .nav-list{
        padding: 5px 10px;
        color: #999;
        cursor: pointer;
    }
    .acti-list{
        display: flex;
        flex-wrap: wrap;
        padding: 20px 0;
        .acti-item{
            width: 25%;
            box-sizing: border-box;
            .img-box{
                width: 80%;
                padding-top: 10px;
                text-align: center;
                margin: 0 auto;
                img{
                    width: 100%;
                }
                .over-time{
                    display: flex;
                    color: #fff;
                    width: 100%;
                    height: 28px;
                    background: #3E84D6;
                    opacity: 0.7;
                    justify-content: center;
                    align-items: center;
                }
                .redback{
                    background: #FF6666;
                }
            }
            .card-info{
                padding: 10px 20px;
            }
        }
    }
    .pur-bannder{
        height: 360px;
        overflow: hidden;
        margin-top: 10px;
        img{
            width: 100%;
            height: 100%;
            cursor: pointer;
        }
    }
    
    .pur-module-list{
        // padding-bottom: 20px;
        padding: 0 20px;
        .module-item{
            .module-title{
                padding: 5px 0;
               .tit-one{
                font-size: 24px;
                display: flex;
                justify-content: center;
                font-weight: bold;
                color: #333333;
                padding: 10px 0;
                .split-char{
                    
                    }
                .title{
                    padding: 0 10px;

                }
               }
               .tit-two{
                text-align: center;
                font-size: 12px;
                font-family: PingFang SC;
                font-weight: 500;
                color: #C7C7C7;
               }
            }
            .module-content{
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                background: #fff;
                padding-bottom: 10px;
                
                .goods-list{
                    display: flex;
                    justify-content: space-between;
                    .hide{
                        display: none;
                    }
                    .goods-item{
                        cursor: pointer;
                        width: 260px;
                        padding-top: 20px;
                        box-sizing: border-box;
                        .img-box{
                            // padding: 20px 0;
                            text-align: center;
                            img{
                                width: 160px;
                            }
                            .over-time{
                                display: flex;
                                color: #fff;
                                width: 260px;
                                height: 28px;
                                background: #3E84D6;
                                opacity: 0.7;
                                justify-content: center;
                                align-items: center;
                            }
                        }
                        
                    }
                }
                
                // 正在的
                .type-list{
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    .type-item{
                        width: 571px;
                        height: 361px;
                        padding: 15px 20px;
                        box-sizing: border-box;
                        border: 1px solid #E5E5E5;
                        .now-head{
                            display: flex;
                            align-items: flex-end;
                            justify-content: space-between;
                            padding-bottom: 10px;
                            border-bottom: 1px solid #e5efe5;
                            .head-title{
                                font-weight: 700;
                                font-size: 22px;
                            }
                            .head-more{
                                color: #999999;
                                cursor: pointer;
                                i{
                                    padding: 0 3px;
                                }
                            }
                        }
                        
                    }
                    .sec-type{
                        .over-time{
                            background: #FF6666 !important;
                        }
                        .tit{
                            .red{
                                font-weight: 700;
                            }
                        }
                    }
                }
                // 即将秒杀 
                .ms-pre-list{
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    width: 100%;
                    .goods-item{
                        cursor: pointer;
                        width: 270px;
                        padding-top: 20px;
                        // margin-right: 20px;
                        box-sizing: border-box;
                        .img-box{
                            // padding: 20px 0;
                            text-align: center;
                            img{
                                width: 200px;
                            }
                           
                        }
                        .card-info{
                            display: flex;
                            align-items: flex-end;
                            justify-content: space-between;
                            padding: 10px 0;
                            
                            .goods-msg{
                                .tit{
                                    font-size: 18px;
                                    font-family: PingFang SC;
                                    font-weight: 500;
                                    color: #333333;
                                }
                               
                                
                                .goods-coll-msg{
                                    display: flex;
                                    align-items:flex-end;
                                    font-size: 12px;
                                    font-family: Source Han Sans CN;
                                    font-weight: 400;
                                    color: #999999;
                                    padding-top: 5px;
                                    .current{
                                        font-size: 24px;
                                        color: #FF2020;
                                        
                                    }
                                    .original{
                                        font-size: 14px;
                                        text-decoration: line-through;
                                        color: #999999;
                                        margin-top: 2px;
                                        margin-left: 5px;
                                    }
                                }
                            }
                            .coll-rote{
                                text-align: center;
                                .rote-tit{
                                    font-size: 14px;
                                    font-family: PingFang SC;
                                    font-weight: 500;
                                    color: #999999;
                                }
                            }
                        }
                        .pre-time{
                            border: 1px solid #E5E5E5;
                            display: flex;
                            color: #999999;
                            align-items: center;
                            padding: 5px 10px;
                            width: 160px;
                            span{
                                margin-right: 3px;
                            }
                        }
                    }
                }
                // 结束的
                .swiper-container{
                    width: 100%;
                    .swiper-slide{
                        width: 260px;
                        .goods-item{
                            .card-info{
                                padding: 10px 30px;
                                .goods-msg{
                                    text-align: left;
                                    .tit{
                                        font-size: 18px;
                                    }
                                    .goods-coll-msg{
                                        padding: 10px 0;
                                        .current{
                                            font-size: 14px;
                                            color: #333;
                                        }
                                        .original{
                                            font-size: 12px;
                                        }
                                    }
                                    .sell-rote{
                                        color: #999999;
                                    }
                                    .progress-body{
                                        width: 120px;
                                        .progress1{
                                            background: #FFCFCF;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .module-content-item{
                    width: 570px;
                    
                   padding-top: 10px;
                    .act-img{
                        padding-bottom: 10px;
                        width: 570px;
                        height: 170px;
                        cursor: pointer;
                    }
                    .act-info{
                        .act-title{
                            font-size: 16px;
                            font-weight: 700;
                            display: flex;
                            justify-content: space-between;
                            .set-name{
                                .subsidiary-tit{
									padding-left: 6px;
								}   
                            }
                            .title-count-down{
                                min-width: 230px;
                                font-weight: 500;
                                display: flex;

                                align-items: center;
                                justify-content: flex-end;
                                font-size: 16px;
                                .num{
                                    font-size: 14px;
                                    color: #fff;
                                    background: #000;
                                    border-radius: 2px;
                                    width: 18px;
                                    height: 18px;
                                    text-align: center;
                                    line-height: 18px;
                                    margin: 0 2px;
                                }
                            }
                            
                        }
                        .act-desc{
                            padding-top: 6px;
                            display: flex;
                            align-items: center;
                            font-size: 14px;
                            .price{
                                color: #3E84D6;
                                font-size: 14px;
                            }
                            .desc{
                                display: flex;
                                align-items: center;
                                border-left: 1px solid $split-color;
                                padding-left: 10px;
                                margin-left: 10px;
                                img{

                                }
                                span{
                                    padding: 0 5px;
                                }
                            }
                        }
                        
                    }
                }
            }
        }
    }
    
}
</style>