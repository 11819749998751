<template>
    <div class="find-list">
        <div class="w" v-loading="$store.state.showLoding">
            <!-- 综合排序 -->
            <div class="sort">
                <div class="sort-left" >
                    综合排序
                </div>
                <div class="sort-right" >
                    {{message}}
                    <i class="el-icon-arrow-up" v-if="showScreen"></i>
                    <i class="el-icon-arrow-down" v-else></i>
                </div>
            </div>

            <div class="screen-list" v-if="!showScreen&&pitchScreen.length>0" >
                <div class="screen-item" v-for="(scr,index) in pitchScreen" :key="index">{{scr}}</div>
                <i class="el-icon-close" ></i>
            </div>
            
            <div class="condition-tabel" v-show="showCond" >
                <!-- 配件品牌 -->
                <div class="coudition-group">
                    <div class="label">配件品牌</div>
                    <div class="coudition">
                        <div class="coudition-left pabo10" v-if="screenList && screenList.oenaturebrand" :style="{'height':couditionH}">
                            <template v-for="(item,index) of screenList.oenaturebrand" >
                                <div class="coudition-daxiao" :key='"oenaturebrand"+index' v-if="item.oenaturebrand">
                                    <span  class="item" :class="{'active':searchData.oenaturebrand.includes(item.oenaturebrand)}">
                                        {{item.oenaturebrand}}
                                    </span>
                                </div>
                            </template>
                            <div class="toggle">
                                <i v-if="couditionH=='auto'" class="el-icon-arrow-up"  ></i>
                                <i v-else class="el-icon-arrow-down" ></i>
                            </div>
                        </div>
                        <div class="coudition-right">
                            <span >
                                取消选中
                            </span>
                        </div>
                    </div>
                </div>

                <!-- 配件分类 -->
                <div class="coudition-group">
                    <div class="label">配件分类</div>
                    <div class="coudition">
                        <div class="coudition-left" v-if="screenList && screenList.oesort">
                            <template v-for="(item,index) of screenList.oesort" >
                                <div  class="coudition-daxiao" :key='"oesort"+index' v-if="item.oesort">
                                    <span  class="item" :class="{'active':searchData.oesort.includes(item.oesort)}">
                                        {{item.oesort}}
                                    </span>
                                </div>
                            </template>
                        </div>
                        <div class="coudition-right">
                            <span >
                                取消选中
                            </span>
                        </div>
                    </div>
                </div>
                <!-- 供应商 -->
                <div class="coudition-group">
                    <div class="label">供应商</div>
                    <div class="coudition">
                        <div class="coudition-left"  v-if="screenList && screenList.store">
                            <template v-for="(item,index) of screenList.store">
                                <div  class="coudition-daxiao"  :key='index' v-if="item.store_id">
                                    <span class="item" :class="{'active':searchData.store_name.includes(item.store_name)}" >
                                        {{item.store_name}}
                                    </span> 
                                </div>
                            </template>
                        </div>
                        <div class="coudition-right">
                            <span >
                                取消选中
                            </span>
                        </div>
                    </div>
                </div>

            </div>
            <!-- 分组按钮 -->
            <div class="top-handle">
                <div class="group-list">
                    <div class="group-item" @click="autoCli" :class="{'active':group_type==''}">
                        按商家分组
                    </div>
                    <!-- group_type -->
                    <div class="group-item" :class="{'active':group_type=='parts'}">
                        按配件分组
                    </div>
                </div>
                <div class="short-pag" v-if="findInfo.length>0">
                    <el-checkbox class="filterZero"  v-model="showZero" @change="query(true)">过滤0库存</el-checkbox>
                    <div class="all">
                        {{findInfo[0].total}}条
                    </div>
                    <!-- layout="prev, next" -->
                    <div class="up-down" >
                       <div class="el-icon-caret-top" :class="{'notCli':findInfo[0].curpage==1}">
                       </div>
                       <div class="el-icon-caret-bottom"  :class="{'notCli':findInfo[0].curpage==Math.ceil(findInfo[0].total/findInfo[0].page)}">

                       </div>
                    </div>
                   
                </div>
            </div>
            
            <!-- 商品目录 -->
            <div class="product">
                <ul>
                    <li v-for="(item,index) of listAttr" :key="index" >
                        {{item.label}}
                        <div class="ico">
                            <i class="el-icon-caret-top"  :class="{'active':sortActive==item.key+1}"></i>
                            <i class="el-icon-caret-bottom"  :class="{'active':sortActive==item.key+2}"></i>
                        </div>
                        
                    </li> 

                    <li>
                        订货数量
                    </li>
                    <li>
                        操作
                    </li>  
                </ul>
            </div>

            <!-- <div class="history" v-if="!$store.state.searchText">
                <span>历史搜索记录</span>
            </div> -->
            <!-- 商品详情 -->
            <div class="cargo" v-for="(item,index) of findInfo" :key='"list"+index'>
                <ul>
                    <!-- <li  class="case">
                        <template v-if="group_type==''">
                            <div class=" company">{{item.store_info.store_name}}</div>
                            <span class="phone">
                                <img src="../../assets/commom/phone.png"  alt="" >
                                {{item.store_info.store_phone}}
                            </span>
                            <span>
                                <i class="el-icon-location-outline"></i>
                                {{item.store_info.area_info}}
                            </span>
                            
                        </template>
                        <template v-else>
                            <div class=" company">配件编码：{{item.oecode}}</div>
                        </template>
                        <el-checkbox class="filterZero"  v-model="showZero" @change="query(true)">过滤0库存</el-checkbox>
                        
                    </li> -->
                    
                    <li :class="{'case-list':true,'isAll':item.isShowAll}">
                        <div 
                            class="details"
                            v-for="(goods_item,childIndex) of item.goods_list"
                            :key="'child'+childIndex">
                            <el-checkbox v-model="goods_item.checked" >  
                                <div class="name">{{goods_item.oenaturebrand}}</div>
                            </el-checkbox>
                            <!-- <el-button type="text" class="number"  >{{group_type?goods_item.store_name:goods_item.oecode}}</el-button> -->
                            <div class="number" >{{group_type?goods_item.store_name:goods_item.oecode}}</div>
                            <div class="productName" :title="goods_item.goods_name">{{goods_item.goods_name}}</div>
                            
                            <div class="encoding" :title="goods_item.sccode">{{goods_item.sccode}}</div>
                            <div class="brand" :title="goods_item.oenaturebrand">{{goods_item.oenaturebrand}}</div>
                            
                            <div class=" inventory">{{goods_item.goods_storage}}</div>
                            <div class="TheOrderPrice"> ¥ {{goods_item.goods_price}}</div>
                            <div class="numAd">
                                <el-input-number v-model="goods_item.goods_num" @change="handleChange" :min="1"  size="mini"></el-input-number>
                            </div>
                            <div>
                                <el-button>加入购物车</el-button> 
                            </div>
                                <div class="vehicleModel" :title="goods_item.vehicletype">车型:{{goods_item.vehicletype}}</div>
                                 <div class="buyHis">
                                      <template v-if="goods_item.orderInfo" >

                                        上次购买信息：
                                        <span>
                                            {{$hnwTools.formatDateTime(goods_item.orderInfo.add_time,'ymd')}}
                                        </span>
                                        <span>
                                            {{goods_item.orderInfo.member_name}}
                                        </span>
                                        <span>
                                            {{goods_item.orderInfo.goods_num}}
                                        </span>
                                        <span>
                                            {{$hnwTools.getOrderStateText(goods_item.orderInfo.order_state)}}
                                        </span>
                                        <span class="readMore">
                                            查看更多
                                        </span>
                                    </template>
                                 </div>
                            <div class="stock" v-if="goods_item.self_storage">自有库存{{goods_item.self_storage || 0}}</div>
                            <div class="addition"  v-if="goods_item.cartNum">已添加 {{goods_item.cartNum}} 件</div>
                        </div>
                        <div class="pagings" v-if="group_type==''">
                            <el-pagination
                                background
                                @size-change="handleStoreSizeChange(item)"
                                @current-change="handleStoreCurrentChange(item)"
                                :current-page.sync="item.curpage"
                                :page-sizes="[10, 15, 20, 30]"
                                :page-size.sync="item.page"
                                layout="total, sizes, prev, pager, next, jumper"
                                :total="Math.ceil(item.total)">
                            </el-pagination>
                        </div>
                    </li>
                </ul>
            </div>
            <el-dialog title="历史购买记录" :visible.sync="hisVis" center  width="800px">
        <div class="his-list">
            <div class="list-head">
                <div>购买时间</div>
                <div>购买人</div>
                <div class="col-100">购买个数</div>
                <div class="col-100">订单状态</div>
            </div>
            <div class="list-body">
                <div class="row" v-for="(his,index) in gooodsHisList" :key="index">
                    <div>{{$hnwTools.formatDateTime(his.add_time,'ymd')}}</div>
                    <div>{{his.member_name}}</div>
                    <div  class="col-100">{{his.goods_num}}</div>
                    <div class="col-100">{{$hnwTools.getOrderStateText(his.order_state)}}</div>
                </div>
            </div>
        </div>
    </el-dialog>
            <!-- 分页 -->
            <!-- <div class="paging">
            <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page.sync="currentPage"
                :page-sizes="[10, 20, 30, 40]"
                :page-size.sync="pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="Math.ceil(total)">
            </el-pagination>
            </div> -->

            <!-- 弹出框 -->
            <div class="hezi" >
                <el-dialog
                :visible.sync="dialogVisible"
                width="760px"
                top="8vh"
                >
                    <DiaGoodsInfo :id="goods_id"></DiaGoodsInfo>
                </el-dialog>
            </div>
            <!-- 展开箭头 -->
            <!-- <div class="pull" >
                <i class="el-icon-arrow-down jiantou" v-if="!showAll"  ></i>
            <i class="el-icon-arrow-up  jiantou" v-if="showAll" ></i>
            </div> -->
        </div>
    </div>
</template>

<script>

export default {
    data() {
        return{
            gooodsHisList:[],
            hisVis:false,
            findInfo:[],            //搜索框数据
            // showPull:false,         //弹框-隐藏套内商品
            dialogVisible: false,   //显示隐藏弹出框
            message:"展开筛选",    
            showCond:false,         //筛选显示
            checked: false,         //多选框
            showSearch:false,       //数据展示
            // showAll:false,          //弹出框数据展示
            showbelow:false,        //筛选展示
            showScreen:false,
            showZero:false,
            num: 1,
            sort_type:'',
            sort_way:'',
            showList:[],
            start_list:[],
            sortActive:'',
            group_type:'',
            
            listAttr: [             
                { key:"oecode",label:"OE号",},
                { key:"goods_name",label:"商品名称",},
                { key:"sccode",label:"生产编码",},
                { key:"oenaturebrand",label:"配件品牌",},
                
                { key:"goods_storage",label:"库存",},
                { key:"goods_price",label:"订货价",}
            ],
            // { key:"oenatureplace",label:"产地",},
            //     { key:"oenature",label:"配件性质",},
            attrList:[],
            goods_id:"",
            currentPage:1,
            pageSize:15,
            totalParts:0,     //搜索配件数
            total:0,             //搜索总条数
            screenList:{},
            searchData:{ //筛选框选中列表
                city:[],
                oecarbrand:[],
                oenaturebrand:[],
                oesort:[],
                store_name:[],
                st_id:[]
            },
            couditionH:"20px",
           
      }
    },
    created(){
        // if(!this.$isCompany()){
        //     return 
        // }
        if(!this.$store.state.searchText){
            
            this.query(true,1)
        }else{
            this.query(true)
        }
    },
   
    methods: {
        autoCli(){
            
            console.log("自动点击成功")
        },
        handleChange(value) {
            // console.log(value);
        },
        //关闭标签
        closeTag(value){
            let deleteType = ''
            this.tags.splice(this.tags.indexOf(value),1)
            for(let [key,tagsList] of Object.entries(this.searchData)){
                console.log(key,tagsList)
                if(tagsList.indexOf(value)>-1){
                    deleteType = key
                }
            }
            if(deleteType){
                this.searchData[deleteType].splice(this.searchData[deleteType].indexOf(value),1)
            }
        },
        // 弹出框数据
        async showGoodsInfo(goods){
            // this.goods_id =goods_id
            // this.dialogVisible = true
            if(this.$isCompany()){
                goods.preRou = "找件"
                localStorage.setItem("hnw-web-item-data",JSON.stringify(goods))
                this.$router.push("/find/info")
            }
        },

        // 升序降序
        sortChange(type,way){
            this.sort_type = type
            this.sort_way = way
            this.sortActive = type + way
            this.query()
        },
        cutGroup(type){
            this.group_type = type
            if(type=''){
                this.listAttr[0].label = 'OE号'
            }else{
                this.listAttr[0].label = '商家'
            }
            this.query()
        },
        // 去下一页
        shortCurpageChange(type){

            if(type==1){
                if(this.findInfo[0].curpage>1){
                    this.findInfo[0].curpage --
                    this.handleStoreCurrentChange(this.findInfo[0])
                }else{
                    // this.$warMsg("已经第一个了")
                }
            }else{
                if(this.findInfo[0].curpage<this.findInfo[0].total/this.findInfo[0].page){
                    this.findInfo[0].curpage ++
                    this.handleStoreCurrentChange(this.findInfo[0])
                }else{
                    // this.$warMsg("已经最后一页了")
                }
                
            }
           
        },
        //展开收起筛选
        toggleScreen(){
            
            this.showCond = !this.showCond
            this.showScreen = !this.showScreen
            if(this.showCond){
                this.showCond=true;
                this.message="收起筛选"
            }else{
                this.showCond=false;
                this.message="展开筛选"
            }
       
         },
         zhpx(){
            if(this.$store.state.store_id=='1856'){
                console.log('触发彩蛋')
                this.$router.push("/find/test")
            }
         },
         toggleCH(){
             this.couditionH = this.couditionH == 'auto'?'20px':'auto'
         },
        //  弹出框展示数据
        // goPull() {
        //     this.showPull=!this.showPull
        //     this.showAll=!this.showAll
        // },

        //  分页
        async handleSizeChange(val) {
            await this.query()
            scrollTo(0,0)
        },
        handleCurrentChange(val) {

            this.query()
        },
        // 店铺商品分页
        async handleStoreSizeChange(store){
           
            let res =  await this.storeSearch(store.curpage,store.page,store.store_info.store_id)
           
            store.goods_list=res
            store.goods_list.forEach(goods=>{
                this.$set(goods,"goods_num",1)
                this.$set(goods,"choose",false)
            })
        },
        async handleStoreCurrentChange(store){
            let res = await this.storeSearch(store.curpage,store.page,store.store_info.store_id)
            
            store.goods_list=res
            store.goods_list.forEach(goods=>{
                this.$set(goods,"goods_num",1)
                this.$set(goods,"choose",false)
            })
        },
        //头部筛选切换
        activeFilter(type,val,st_id){
            console.log("st_id",st_id)
            if(!this.searchData[type].includes(val)){
                this.searchData[type].push(val)
                if(st_id){
                    this.searchData.st_id.push(st_id)
                }
            }else{
               this.searchData[type] =   this.searchData[type].filter(scr=>scr!=val)
               if(st_id){
                    this.searchData.st_id = this.searchData.st_id.filter(st_id1=>st_id1!=st_id)
                }
            }

            this.query()
        },
        //头部筛选取消选择
        clearActiveFilter(type){
            if(type=='pitchScreen'){
                for(let key in this.searchData){
                    this.searchData[key] = []
                }

                return this.query(true)
            }
            if(type=='store'){
                this.searchData.store_name = []
                this.searchData.st_id = []
            }
            this.searchData[type] = []
            this.query() 
        },

        //加入购物车
        async joinCart(carItem,index,childIndex,store){
            if(this.$isCompany()){

                let carMessage = {
                    goods_id:carItem.goods_id,
                    sccode:carItem.sccode,
                    oecode:carItem.oecode,
                    goods_name:carItem.goods_name,
                    goods_price:carItem.goods_price,
                    goods_num:carItem.goods_num,
                    buyer_id:'',
                    order_name:'',
                    member_id:'',
                    buyer_name:'',
                    store_id:this.group_type?carItem.store_id:store.store_id,
                    store_name:this.group_type?carItem.store_name:store.store_name,
                    cart_type:'1',
                    setlist_id:'',
                    groupbuy_id:'',
                    promotion_id:''
                }
                console.log("carMessage",carMessage)
                let res = await this.$api.joinCart([carMessage])
                //更新记录中 已添加X件 字段
                this.findInfo[index].goods_list[childIndex].cartNum = Number(carItem.cartNum) + Number(carItem.goods_num)
            }
            
        },

        async storeSearch(curpage,page,store_id){
            return  await this.$api.getStoreSearch({
                store_id,
                member_id:this.$store.state.member_id,
                curpage:curpage,
                page:page,
                group_type:this.group_type,
                sort_type:this.sort_type,
                sort_way:this.sort_way,
                search:this.$store.state.searchText,
                storage_type:this.showZero?'ok':'',
                type:screen?"screen":'',
                oenaturebrand:this.searchData.oenaturebrand.join(","),
                oesort:this.searchData.oesort.join(",")
            })
        },

      /*
      调用接口返回搜索结果
      * */
     
        async query(screen,num){
            
            let res1 =  await this.$api.getSearchRecord({
                record_type:3
            })
            let search = ""
            
            if(res1.length>0&&num==1){
                // search = res1[0].record_name

                this.$store.commit('UpDateState',{
                    searchText:res1[0].record_name
                })
            }
            search = this.$store.state.searchText

            search = search.replace(this.$hnwTools.spec,"")
            this.$store.commit("toggleLoding",true)

            let res = await this.$api.getFindList({
                store_id:this.$store.state.store_id,
                member_id:this.$store.state.member_id,
                store_curpage:this.currentPage,
                store_page:this.pageSize,
                group_type:this.group_type,
                sort_type:this.sort_type,
                sort_way:this.sort_way,
                search,
                storage_type:this.showZero?'ok':'',
                type:screen?"screen":'',
                oenaturebrand:this.searchData.oenaturebrand.join(","),
                oesort:this.searchData.oesort.join(","),
                st_id:this.searchData.st_id.join(","),
                shop_type:this.$store.state.shop_type
            })

            this.$store.commit("toggleLoding",false)
            if(!res.data){
                if(this.$store.state.searchText){
                    // console.log("this.$store.state.searchText",this.$store.state.searchText)
                    this.$warMsg("未搜索到任何商品")
                }
               return this.findInfo = []
            }
            if(res.data.length>0){
                for(let store of res.data){
                    this.$set(store,"curpage",1)
                    this.$set(store,"page",15)
                    for(let list_item of store.goods_list){

                        if(!list_item.self_storage){
                            list_item.self_storage = 0
                        }
                        if(!list_item.cartNum){
                            list_item.cartNum = 0
                        }

                        if(!list_item.self_storage){
                            list_item.self_storage = 0
                        }
                        this.$set(list_item,"goods_num",1)
                        this.$set(list_item,"checked",false)
                       
                    }

                }
            }
            // 
            this.findInfo = [...res.data]
            
            if(!this.$store.state.token||this.$store.state.sc_id==14){
                
                this.findInfo.forEach(store=>{
                    this.$set(store,"curpage",1)
                    this.$set(store,"page",15)
                    
                    store.goods_list.forEach(goods=>{
                        
                        goods.goods_price = goods.goodprice2

                    })
                })
               
            }else{

                this.findInfo.forEach(store=>{
                    this.$set(store,"curpage",1)
                    this.$set(store,"page",15)
                    
                    store.goods_list.forEach(goods=>{
                        goods.goods_price = goods.goodprice1>0 ?goods.goodprice1 : goods.goods_price
                    })
                })
            }
           
            if(screen){
                this.screenList = res.screen
            }else if(res.screen1){
                for(let key in res.screen1){
                    this.screenList[key] = res.screen1[key]
                }
            }else{
                console.log(" 没 用 筛 选 值 ")
            }
            this.total = res.total;
        },

        async readHisList(goods_id){
            let res = await this.$api.getGoodsRecord(goods_id)
            this.gooodsHisList = res
            this.hisVis = true
        },
    },
    watch:{
        "$store.state.searchText":{
            deep:true,
            handler(n){
                this.query(true)
            }
        }
    },
    computed:{
        pitchScreen(){
            
            let arr = []
            let str = ''
            for(let key in this.searchData){
                if(this.searchData[key]&&key!='st_id'){
                    this.searchData[key].forEach(item=>{
                        str += item + "；"
                    })
                }
                str = str.substr(0,str.length-1)
                if(str){
                    arr.push(str)
                }
                str = ''
            }
            
            return arr
        }
    }
}
</script>

<style lang="scss" scoped>
    .index{
        border-top: 1px solid #f7f7f7;
        padding-bottom: 10px;
    }
    .find-list{
        // 弹出框
        .hezi{
            ::v-deep .el-dialog__wrapper{
                .el-dialog{
                   
                    .el-dialog__header{
                        position: relative;
                        padding: 10px 20px 20px;
                         .el-icon-close:before{
                            position: absolute;
                            top: -10px;
                            right: 0px;   
                        }
                    }
                    .el-dialog__body {
                        overflow-y:auto;
                        height: 500px;
                        padding: 0 10px;
                        box-sizing: border-box; 
                    }
                }
            }
        }
    }
    .w{
        width: 1200px;
        margin: 0 auto;
        .sort{
            position: relative;
            height: 38px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .sort-left{
                width: 86px;
                font-size: 16px;
                font-weight:bold;
                color: #333;
                height: 20px;
                border-bottom: 2px solid $blue;
                padding-top: 10px;
            }
            .sort-right {
                height: 20px;
                text-align: center;
               
                font-size: 14px;
                font-weight: 400;
                color: #333333;
                line-height: 20px;
                cursor:pointer;
            }
            .screen{
                display: flex;
                position: absolute;
                top: 20px;
                right: 0px;
                text-align: right;
               
            }
        }

        .condition-tabel{
            border: 1px solid #ccc;
            border-bottom: none;
            margin-top: 8px;
            .coudition-group{
                display: flex;
                .label{
                    min-width: 80px;
                    border-right: 1px solid #ccc;
                    border-bottom: 1px solid #ccc;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                .coudition{
                    border-bottom: 1px solid #ccc;
                    flex: 1;
                    display: flex;
                    justify-content: space-between;
                    min-height: 28px;
                    .active{
                        color:$blue;
                    }
                    .coudition-left{
                        display: flex;
                        flex-wrap: wrap;
                        overflow: auto;
                        overflow: hidden;
                        padding: 2px 0;
                        position: relative;
                        flex: 1;
                        
                        .coudition-daxiao{
                            margin: 5px;
                            min-width: 10%;
                            border-right: 1px solid #ccc;
                            text-align: center;

                        }
                        .toggle{
                            position: absolute;
                            bottom: -2px;
                            left: 50%;
                            i{
                                cursor: pointer;
                            }
                        }
                    }
                    .pabo10{
                        padding-bottom: 10px;
                    }
                    .item{
                        text-align: center;
                        line-height: 12px;
                        padding: 0 2px;
                        min-width: 5%;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        border-right: 1px solid #ccc;
                        cursor: pointer;
                        margin: 5px;
                    }
                    .item:last-child{
                        border-right: none;
                    }
                    .item:hover{
                        color:$blue;
                    }
                }

                .coudition-right{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    min-width: 100px;
                    span{
                        cursor: pointer;
                    }
                }

            }
        }
        .top-handle{
            display: flex;
            justify-content: space-between;
            .group-list{
                display: flex;
                padding: 5px 0;
                .group-item{
                    cursor: pointer;
                    line-height: 28px;
                    border: 1px solid #ccc;
                    height: 28px;
                    padding: 0 12px;
                }
                .active{
                    background: $blue;
                    border: 1px solid $blue;
                    color: #fff;
                }
            }
            .short-pag{
                display: flex;
                align-items: center;
                padding-right: 20px;
                .all{
                    padding: 0 10px;
                    
                }
                .filterZero{

                }
                >.up-down{
                   display: flex;
                   flex-direction: column;
                   padding-left: 5px;
                   div{
                       cursor: pointer;
                   }
                   .notCli{
                       cursor: not-allowed;
                   }
                }
            }
        }
        .screen-list{
            display: flex;
            justify-content: flex-end;
            align-items: center;
            .screen-item{
                position: relative;
                padding: 0 10px;
                &::before{
                    content: "";
                    position: absolute;
                    width: 1px;
                    height: 60%;
                    left: 0;
                    top: 30%;
                    background: #888;
                }
                
            }
            .screen-item:first-child{
                 &::before{
                    width: 0;
                 }
            }
            
            i{
                cursor: pointer;
            }
            i:hover{
                transform: scale(1.2);
                color: $blue;
            }
        }
        // 商品目录
        .product{
            height: 40px;
            background: #F7F7F7;
            margin-top: 8px;
            
            ul{
                display: flex;
                padding-left: 102px;
                box-sizing: border-box;
            li{
                display: flex;
                height: 40px;
                justify-content:center;
                align-items: center;
                position: relative;
                .ico{
                    display: flex;
                    flex-direction: column; 
                    justify-content: center; 
                    align-items: center;
                    >i{
                        cursor: pointer;
                    }
                    i:hover{
                    transform: scale(1.5);
                    color: $blue;
                    }
                    .el-icon-caret-top{
                        position: relative;
                        top: 3px;
                    }
                    .el-icon-caret-bottom{
                        position: relative;
                        top: -3px;
                    }
                }
                .active{
                    color: $blue;
                }

                &:nth-child(1){
                    // margin: 0 0 0 102px;
                    min-width: 180px;
                    // max-width: 82px;
                    max-width: 180px;
                }
                 &:nth-child(2){
                    // min-width: 150px;
                    // max-width: 150px;
                    min-width: 200px;
                    max-width: 200px;
                }
                 &:nth-child(3){
                    min-width: 110px;
                    max-width: 110px;
                }
                 &:nth-child(4){
                    min-width: 116px;
                    max-width: 116px;
              
                }

                
                 &:nth-child(5){
                    min-width: 64px;
                    // max-width: 64px;
                    flex: 1;
                }
                 &:nth-child(6){
                    min-width: 124px;
                    // max-width: 124px;
                    flex: 1;
                }
                 &:nth-child(7){
                    min-width: 138px;
                    max-width: 138px;
                }
                 &:nth-child(8){
                    min-width: 80px;
                    max-width: 80px;
                }
                .filterZero{
                    position: absolute;
                    top: 24px;
                   
                }
            }
        }
     }
        .history{   
            margin-top: 10px;
            height: 14px;
            text-align: center;
            color: #999;
        }
        // 商品信息
        .cargo{
            display: flex;
            box-sizing: border-box;
            position: relative;
            margin-top: 20px;
            >ul{
                width: 100%;
            }
            .case-list{
                &.isAll{
                 height: auto;
                }
            }
            .case{
                padding-bottom: 10px;
                height: 30px;
                line-height: 44px;
                border-bottom:  1px solid #9e9e9e;
                position: relative;
                .company {
                    display:inline-block;
                    padding:0 20px 0 17px;
                    font-size: 14px;
                    color: #333;
                    font-weight: 700;
                }
                .phone{
                    font-size: 14px;
                    color: #666;
                    padding-right: 20px;
                    img{
                        width: 14px;
                        height: 14px;
                    }
                }
                .filterZero{
                    margin-left: 10px;
                }
            }
                

            .details{
                display: flex;
                height: 69px;
                line-height: 55px;
                border-bottom: 1px solid #ccc;
                position: relative;
                
                    .el-checkbox {
                    width: 84px;
                    padding-left: 18px;
                }
                .name{
                    width: 48px;
                    height: 20px;
                    background-color:$blue;
                    font-size: 12px;
                    text-align: center;
                    line-height: 20px;
                    color: #fff;
                }
                .number{
                    cursor:pointer ;
                    min-width: 180px;
                    // max-width: 82px;
                    max-width: 180px;
                    overflow: hidden;
                    height: 40px;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    padding-top: 0;
                    color: #333;
                }
                
                .productName{
                    // min-width: 150px;
                    // max-width: 150px;
                    min-width: 200px;
                    max-width: 200px;
                    overflow: hidden;
                    text-align: center;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
                .encoding{
                    overflow: hidden;
                    min-width: 110px;
                    max-width: 110px;
                    text-align: center;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
                .brand{
                     overflow: hidden;
                    min-width: 116px;
                    max-width: 116px;
                    text-align: center;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
                .place{
                    overflow: hidden;
                    min-width: 68px;
                    max-width: 68px;
                    text-align: center;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
                .nature{
                    overflow: hidden;
                    min-width: 104px;
                    // max-width: 104px;
                    flex: 1;
                    text-align: center;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
                .inventory{
                    overflow: hidden;
                    min-width: 64px;
                    flex: 1;
                    // max-width: 64px;
                    text-align: center;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
                .TheOrderPrice{
                    overflow: hidden;
                    min-width: 124px;
                    // max-width: 124px;
                    flex: 1;
                    text-align: center;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
                .numAd{
                   
                    min-width: 138px;
                    max-width: 138px;
                   
                }
                .el-input-number{
                    width: 100px;
                    margin: 0 64px 0 20px;
                }
            .el-button--default {
                width: 80px;
                height: 22px;
                border: 1px solid $blue;
                padding: 0;
                color: #59A8F8;
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                border-radius: 0px;
            }
                .vehicleModel{
                    // max-width: 180px;
                    max-width: 360px;
                    // min-width: 180px;
                    box-sizing: border-box;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    cursor:pointer;
                    position: absolute;
                    left: 38px;
                    // top: 26px;
                    top: 45px;
                    height: 20px;
                    line-height: 20px;
                    font-size: 14px;
                    color: #999;
                }
                .buyHis{
                    font-style: italic;
                    position: absolute;
                    // left: 260px;
                    right: 192px;
                    bottom: -4px;
                    text-align: right;
                    // top: 26px;
                    // top: 10px;
                   
                    line-height: 0;
                    height: 20px;
                    color: red;
                    >span{
                            // margin-right: 20px;
                            margin-right: 10px;
                            align-items: center;
                        }
                        .readMore{
                            cursor: pointer;
                        }
                }
                .stock{
                    position: absolute;
                    right: 160px;
                    top: 26px;
                    font-size: 14px;
                    color: #999;
                }
                .addition{
                    position: absolute;
                    right: 5px;
                    top: 44px;
                    height: 15px;
                    line-height: 15px;
                    font-size: 14px;
                    color: #999;
                }
            }
            .pagings{
                display: flex;
                justify-content:flex-end;
                align-items: center;
                margin: 20px 0;
            } 
                


        }
        .paging{
            display: flex;
            justify-content:center;
            margin: 20px 0;
        } 
        ::v-deep .el-dialog__body{
            padding: 0 !important;
            
            .his-list{
                .list-head{
                    display: flex;
                    height: 40px;
                    align-items: center;
                    background: #EEEEEE;
                    margin: 10px 0;
                    padding: 0 8px;
                    >div{
                        flex: 1;
                        text-align: center;
                    }
                    .col-100{
                        max-width: 100px;
                    }
                }
                .list-body{
                    .row{
                        display: flex;
                        padding: 0 8px;
                        >div{
                            flex: 1;
                            padding: 2px 0;
                            border-bottom: 1px solid #eee;
                            flex: 1;
                            text-align: center;
                            line-height: 28px;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                        .col-100{
                            max-width: 100px;
                        }
                    }
                }
            }
        }
}
</style>