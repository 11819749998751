
import axios from "axios"
import { Message } from 'element-ui';
const $erMsg = (message) => {
    Message({
        message,
        type: "error",
        duration: 800
    })
}

// vin查询 
const fetch = async (url, data, method) => {

    data.AppKey = "3dd4ed48ea913068"
    data.AppSecret = "431595e67f114da9a839e8b49605b4b6" 
    let baseUrl = "https://sdmservice.dat881.com"



    let httpRequest = new XMLHttpRequest();//第一步：建立所需的对象
    if (method == 'post') {
        //创建xhr对象
        // var xhr = new XMLHttpRequest()
        // xhr.open('POST','https://sdmservice.dat881.com/api/VehicleOnline/VehicleByLids/Post',true)
        // xhr.setRequestHeader('Content-Type','application/json')
        // xhr.send('{"levelids": "CFV0618A8919", "AppKey": "3dd4ed48ea913068", "AppSecret": "431595e67f114da9a839e8b49605b4b6"}')
        //调用open函数
        httpRequest.open('POST', baseUrl + url)
        //设置Content-Type属性
        httpRequest.setRequestHeader('Content-Type', 'application/json') 
        //调用send函数
        httpRequest.send(JSON.stringify(data))
        //监听onreadystatechange事件
        // httpRequest.onreadystatechange = function () {
        //     // if (xhr.readyState === 4 && xhr.status === 200) {
        //     console.log(httpRequest.responseText);
        //     // }
        // }
        // httpRequest.open('POST',baseUrl + url, true);//第二步：打开连接  将请求参数写在url中  ps:"./Ptest.php?name=test&nameone=testone"
        // httpRequest.setRequestHeader("Content-Type","application/json; charset=utf-8");
        // httpRequest.setRequestHeader("Accept","application/json");
        // httpRequest.withCredentials = false;
        // httpRequest.send(JSON.stringify(data));//第三步：
    } else {
        // 
        httpRequest.open('GET', baseUrl + url, true);//第二步：打开连接  将请求参数写在url中  ps:"./Ptest.php?name=test&nameone=testone"
        httpRequest.send();//第三步：发送请求  将请求参数写在URL中
    }
    /**
     * 获取数据后的处理程序
     */

    let res = await new Promise((rep, rej) => {
        httpRequest.onreadystatechange = function () {
            if (httpRequest.readyState == 4 && httpRequest.status == 200) {
                let json = httpRequest.responseText;//获取到json字符串，还需解析
                try {
                    json = JSON.parse(json)
                    rep(json)
                } catch (e) {
                    console.error(e)
                    //    $erMsg("解析失败")
                    rej(e)
                }
            }
        };
    })
    return res
}

// const fetch = async (url,data,method='post')=>{
//     let res = ''

//     let  headUrl  = "http://sdmservice.dat881.com"


//     if(method=='post'){
//         res = await axios({
//             method:"post",
//             url: headUrl + url,  
//             headers:{
//                 "Content-Type":'application/json'
//             },
//             data:{
//                 appkey:'3dd4ed48ea913068',
//                 appsecret:'431595e67f114da9a839e8b49605b4b6',
//                 ...data,

//             } 
//         })
//     }else{    
//         res = await axios({
//             method:"get",
//             url: headUrl + url, 
//             showMsg:data.showMsg||'', 
//             params:data
//         })
//     }
//     return res
// } 


// vin查询
const getCarInfo = async (data) => {
    return await fetch("/api/VehicleOnline/VehicleByLids/Post", {

        ...data
    }, 'post')
}

const getImages = async (data) => {
    return await fetch("/api/SdmProduct/ProductImg/Post",{
        ...data
    },'post')
}



const apiYbj = {
    getCarInfo,
    getImages,
}

export default apiYbj