<template>
  <div class="goods-info">

    <template v-if="tempNum==1">
         <!-- v-loading="loading" -->
         <div class="hnw-goods-detail" v-if="!loading">
            <div class="top">
              <div class="hnw-area-center" >
                <div class="title"> 
                  <span class="" @click="reBack(itemData)">
                    {{goods.goods_name}}配件列表
                    <i class="el-icon-arrow-right"></i>
                  </span>  
                  <span>
                    {{goods.goods_name}}详情页
                  </span>
                </div>

               
              </div>
            </div>  
            <div class="hnw-area-center">
            <div class="detail-container">
                  <div class="detail-pic">
                    <Photo   :imagesList="goods.goods_images" />
                  </div> 
                  <div class="detail-handler">

                    <div class="detail-name">
                      {{goods.goods_name}}
                      <span class="detail-desc" v-if="itemData.setlist_id || itemData.groupbuy_id">
                        {{itemData.remark}}
                      </span>
                      <span class="detail-desc" v-else>适用车型{{goods.MjVe||goods.vehicletype}}</span>
                      <img class="union-img" src="../assets/union/lmicon.png" alt="" v-if="$route.path=='/union/goodsinfo'">
                    </div>
                    <div class="total-order12" v-if="itemData.setlist_id">
                <div class="details-top12">
                  <!-- <div>
              <img :src="$store.state.shopInfo.store_all_banner_url" alt="">
              <img v-if="$store.state.shop==2018" src="../../assets/store/ybjlogotext.png" alt="" srcset="">
            </div> -->
                  <div style="display: flex;">
                    <!-- <div >
                    <img style="
                        width: 30px;
                        color: #fff;
                        margin-top: 6px;
                        margin-left: 10px;" :src="$store.state.shopInfo.store_all_banner_url" alt="">
                    <img style="
                        width: 30px;
                        color: #fff;
                        margin-top: 6px;
                        margin-left: 10px;" v-if="$store.state.shop==2018" src="../assets/store/ybjlogotext.png" alt="">
                  </div> -->
                  <div
                    style="font-size: 16px;
                      line-height: 40px;
                      padding-left: 10px;
                      color: #fff;
                      font-weight: 700;
                    "
                  >
                    {{$store.state.shopInfo.shop_name}}集单
                  </div>
                  </div>
                  <div>
                    <div class="current-time12">
                      <div class="introduce12" v-if="itemData.notbuy == 'pre'">
                        距离集单开始，还剩:
                      </div>
                      <div class="introduce12" v-else>集单剩余:</div>

                      <div class="specific-time12">
                        <template v-if="itemData.leftDay">
                          <div class="i-time-box12">
                            <div class="i-time12">{{ itemData.leftDay }}</div>
                          </div>
                          <div class="day12">天</div>
                        </template>
                        <CountDwon
                          bgrColor="#fff"
                          paddingRight="10px"
                          :bor="false"
                          splitorColor="#fff"
                          fontColor="#F00"
                          fontSize="14px"
                          :splitorText="splist"
                          :timer="itemData.leftTime"
                          minWidth="22px"
                          width="22px"
                          v-if="itemData.leftTime"
                        ></CountDwon>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="details-price12">
                  <div style="width: 80%">
                    <div class="current-price12">
                      <div
                        style="
                          font-size: 38px;
                          margin: 0 10px -4px 15px;
                          color: #f00;
                        "
                      >
                        <span style="font-size: 18px; color: #f00">¥</span
                        ><span style="color: #f00">{{
                          itemData.groupbuy_price
                        }}</span>
                      </div>
                      <span
                        style="
                       color: #ccc;
                          font-size: 14px;
                          padding-right: 10px;
                          color: rgb(118, 117, 117);
                        "
                        >参考价</span
                      >
                      <div
                        style="
                          text-decoration: line-through;
                          font-size: 18px;
                          color: #ccc;
                        "
                      >
                        ¥{{ goods.goods_price }}
                      </div>
                    </div>

                    <div style="display: flex; margin: 10px 0">
                      <div style="color: #ccc">
                        <span style="color: rgb(118, 117, 117); padding: 10px"
                          >集单总额</span
                        >{{ itemData.totalsum }}元
                      </div>
                      <div style="color: #ccc">
                        <span style="color: rgb(118, 117, 117); padding: 10px"
                          >集单人数</span
                        >{{ itemData.buyer_count }}
                      </div>
                    </div>
                  </div>
                  <div
                    style="
                      width: 20%;
                      border-left: 1px solid #ccc;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                    "
                  >
                    <div>
                      <div >完成率</div>
                      <div>
                        <div class="progress-body">
                          <!-- itemData.buy_total 改成商品库存22-1-5 -->
                          <div
                            class="progress1"
                            :style="{
                              // width:
                              //   (itemData.buy_quantity / goods.goods_storage) *
                              //     100 +
                              //   '%',
                              width:itemData.rote+'%'
                            }"
                          ></div>
                        </div>
                        <div style="color: #f00; font-size: 16px">
                          {{ itemData.rote }}%
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
                    <!-- <div class="total-order" v-if="itemData.setlist_id">
                        <div class="details-top">
                           <div class="details-price">
                             <div class="current-price">
                               集单价 <div class="big"> <span style="font-size: 18px;">¥</span>{{itemData.groupbuy_price}}
                               </div>
                             </div>
                             <div style="text-decoration: line-through; font-size: 18px;">¥{{goods.goods_price}}</div>
                           </div>
                           <div class="current-time" >
                             <div class="introduce" v-if="itemData.notbuy=='pre'">距离集单开始，还剩:</div>
                             <div class="introduce" v-else>集单还剩:</div>
                             
                             <div class="specific-time ">
                               <template v-if="itemData.leftDay">
                              <div class="i-time-box">
                                <div class="i-time">{{itemData.leftDay}}</div>
                              </div>
                              <div class="day">天</div>
                            </template>
                              <CountDwon bgrColor="#3E84D6" :bor="false" splitorColor="#3E84D6" fontColor="#FFFFFF" fontSize="12px" :splitorText="splist" :timer="itemData.leftTime" minWidth="22px" width="22px" v-if="itemData.leftTime"></CountDwon> 
                              
                             </div>
                           </div>
                        </div> -->
                        <!-- 集单总额{{itemData.totalsum}}元 -->
                        <!-- <div class="details-bottom">
                          <div class="plan">
                            <div> 集单总额{{itemData.totalsum}}元</div>
                            <div class="progress">
                              <div class="percentage">完成率</div>
                              <div class="progress-body">
                                <div class="progress1" :style="{width:itemData.totalsum_now/itemData.totalsum*100+'%'}"></div>
                              </div> -->
                                <!-- <div>{{Math.floor(itemData.buy_quantity/itemData.buy_total*100).toFixed(1)+'%'}}</div> -->
                                <!-- <div>{{itemData.rote}}%</div>
                              
                              
                              </div>
                            </div>
                          
                          <div>
                            <span class="total-people">
                            <img src="../assets/commom/user1.png" alt=""> {{itemData.buyer_count}}
                            </span>
                            
                          </div>
                        </div>
                    </div> -->
                    <!-- 秒杀 -->
                    <div class="total-orders" v-else-if="itemData.groupbuy_id">
                        <div class="details-top">
                           <div class="details-price">
                             <div class="current-price">
                               秒杀价 <div class="big"> <span style="font-size: 18px;">¥</span>{{itemData.groupbuy_price}}
                               </div>
                             </div>
                             <!-- <div style="text-decoration: line-through; font-size: 18px;">¥{{itemData.goods_price}}</div> -->
                             <div style="text-decoration: line-through; font-size: 18px;">¥{{goods.goods_price}}</div>
                           </div>
                           <div class="current-time" v-if="itemData.state!='32'">
                             <div class="introduce" v-if="itemData.state=='40'">距离秒杀开始，还剩:</div>
                             <div class="introduce" v-else>秒杀剩余:</div>
                             
                             <div class="specific-time "  v-if="sckilStartTime">
                               <template v-if="itemData.leftDay">
                              <div class="i-time-box">
                                <div class="i-time">{{itemData.leftDay}}</div>
                              </div>
                              <div class="day">天</div>
                            </template>
                                <CountDwon  v-if="itemData.state==20" bgrColor="#f00" :bor="false" splitorColor="#f00" fontColor="#FFFFFF" fontSize="12px" :splitorText="splist" :timer="sckilStartTime"  minWidth="22px" width="22px" ></CountDwon> 

                                <CountDwon bgrColor="#f00" :bor="false" splitorColor="#f00" fontColor="#FFFFFF" fontSize="12px" :splitorText="splist" :timer="sckilStartTime" minWidth="22px" width="22px" v-if="itemData.state==40"></CountDwon> 
                             </div>
                           </div>
                        </div>
                        <div class="details-bottom">
                          <div class="plan">
                            <!-- <div> 秒杀总数{{itemData.buy_total}}件</div> -->
                            <div> 秒杀总数 {{goods.goods_storage}}件</div>
                            <div class="progress">
                              <div class="percentage">完成率</div>
                              <div class="progress-body">
                                <!-- itemData.buy_total 改成商品库存22-1-5 -->
                                <div class="progress1" :style="{width:itemData.buy_quantity/goods.goods_storage*100+'%'}"></div>
                              </div>
                                <!-- <div>{{Math.floor(itemData.buy_quantity/itemData.buy_total*100).toFixed(1)+'%'}}</div> -->
                                <div>已售{{itemData.buy_quantity}}件</div>
                              
                              
                              </div>
                            </div>
                          
                          <div>
                            <span class="total-people">
                            <img src="../assets/commom/user1.png" alt=""> {{itemData.buyer_count}}
                            </span>
                            
                          </div>
                        </div>
                    </div>
                    
                    <!-- 采购 -->
                    <div class="detail-price-v" v-else>
                        <div class="weizi">
                         商品价格: 
                        </div> 
                        <div class="fsz38" v-if="$store.state.shop==1948">
                          <span style="font-size: 18px;">价格面议</span>
                          <!-- <span style="font-size: 38px;" v-if="$store.state.shop=='2136'">￥{{ goods.taxSellPrice2||goods.goods_price }}</span>
                          <span style="font-size: 38px;" v-else>￥{{ goods.goods_price }}</span> -->

                          <span class="fcl-price" v-if="itemData.brand_ratio_price">
                            (整箱单价:{{itemData.brand_ratio_price}})
                          </span>
                        </div> 
                        <div class="fsz38" v-else>
                          <span style="font-size: 18px;">￥</span>{{goods.goods_price}}
                          <!-- <span style="font-size: 38px;" v-if="$store.state.shop=='2136'">￥{{ goods.taxSellPrice2||goods.goods_price }}</span>
                          <span style="font-size: 38px;" v-else>￥{{ goods.goods_price }}</span> -->

                          <span class="fcl-price" v-if="itemData.brand_ratio_price">
                            (整箱单价:{{itemData.brand_ratio_price}})
                          </span>
                        </div> 
                    </div>
                    

                    <div class="datail-footer">
                      <div class="detail-msg">
                            <span>OE号：{{goods.oecode }}</span>
                        
                            <span>生产编码：{{goods.sccode }}</span>

                            <template v-if="$store.state.shop!=1974&&$store.state.shop!=1871">
                                <span v-if="itemData.setlist_id || itemData.groupbuy_id">
                              {{itemData.setlist_id?"集单":"秒杀"}}库存：
                              <span v-if="itemData.setlist_id">
                                {{itemData.coutDownStorage}}
                              </span>
                              <span v-else>
                                {{itemData.buy_total||goods.goods_storage}}
                              </span>
                            </span>
                            <span v-else>
                              
                              库存：
                              <span v-if="$store.state.look_inv==1">{{goods.goods_storage}}</span>
                                    <span v-else>
                                      <span v-if="$store.state.shop==1948">有货</span>
                                      <span v-else>{{goods.goods_storage>0?"有货":"无货"}}</span>
                                    </span>
                            </span>
                            </template>

                            
                        
                            <span>配件品牌：{{goods.oenaturebrand }}</span>
                        
                            <span>规格：{{goods.spec }}</span>
                      </div>
                      <!--配送  -->
                      <div class="item-row">
                        <div class="label">配送</div>
                        <el-cascader
                          class="change"
                          size="mini"
                          v-model="dispacthSite"
                          :options="bournList"
                          :props="{ expandTrigger: 'hover',value:'name',label:'name',children:'sub'}"
                          @change="handleChange"
                        ></el-cascader>
                      </div>
                      <!-- 运费 -->
                      <div class="item-row">
                        <span class="label">运费</span>
                        <span class="cost">免费</span>
                      </div>
                      
                      <!-- ,秒杀数量 -->
                      <div class="item-row" v-if="itemData.groupbuy_id">
                        <span class="label">数量</span>
                        <!-- 限购 -->
                        <el-input-number
                          v-if="itemData.upper_limit!=0"
                          size="mini"
                          v-model="goods_num"
                          @change="handleChange"
                          :min="itemData.upper_limit*1"
                          :max="itemData.upper_limit*1"
                          label="描述文字"
                        ></el-input-number>
                        <!-- 不限购 -->
                        <el-input-number
                          v-else
                          size="mini"
                          v-model="goods_num"
                          @change="handleChange"
                          :min="1"
                          label="描述文字"
                        ></el-input-number>
                      </div>

                      <!-- 数量 -->
                      <div class="item-row" v-else>
                        <span class="label">数量</span>
                        <template v-if="itemData.setlist_id">
                            <el-input-number
                              size="mini"
                              v-model="goods_num"
                              @change="handleChange"
                              :min="1"
  
                            ></el-input-number>
                        </template>
                        
                        
                        <template v-else>
                           
                          <el-input-number
                            v-if="$store.state.zero_buy!=1||itemData.goods_commend==2"
                            :step="itemData.goods_commend==2?2:1"
                            step-strictly
                            size="mini"
                            v-model="goods_num"
                            @change="impueNumChange"
                            :min="1"
                            
                          ></el-input-number>
                        
                         
                          <el-input-number
                            v-else
                            size="mini"
                            v-model="goods_num"
                            @change="handleChange"
                            :min="1"
                            
                          ></el-input-number>
                          <span class="clear-tip red" v-if="itemData.goods_commend==2">
                            订购数量已包含赠送数量
                          </span>
                          <span class="clear-tip goods-Fcl-tit" v-if="itemData.brand_ratio_price">
                            整箱更优惠
                          </span>
                        </template>
                        <div style="margin-left:20px;font-weight:bold;color:red">{{itemData.cangTip}}</div>
                      </div>

                      <!-- 承诺 -->

                      <div class="item-row" v-if="itemData.groupbuy_id">
                        <span class="label">承诺</span>
                        <span class="i-threes">
                          <i class="el-icon-circle-check"></i>正品
                        </span>
                        <span class="i-threes">
                          <i class="el-icon-circle-check"></i>保价
                        </span>
                        <span class="i-threes">
                          <i class="el-icon-circle-check"></i>无忧
                        </span>

                      </div>

                      <div class="item-row" v-else>
                        <span class="label">承诺</span>
                        <span class="i-three">
                          <i class="el-icon-circle-check"></i>正品
                        </span>
                        <span class="i-three">
                          <i class="el-icon-circle-check"></i>保价
                        </span>
                        <span class="i-three">
                          <i class="el-icon-circle-check"></i>无忧
                        </span>

                      </div>
                      
                      <!-- 集单按钮样式 -->
                      <div class="handler" v-if="itemData.setlist_id">
                            <div class="join" @click="joinCart">
                              <div class="join-in" >{{btnText}}</div>
                            </div>
                            <div class="handler-text">
                              <span>
                                累计购买 {{hisTotal}} {{goods.oecompany}}
                              </span>
                              <span class="handler-click" @click="hisBuyVis=true" v-if="hisTotal>0">
                                查看详情
                              </span>
                            </div>                         
                      </div>
                      <!-- 秒杀按钮样式 -->
                      <div class="handler" v-else-if="itemData.groupbuy_id">
                            <div class="joins" @click="joinCart">
                              <div class="join-in" >{{btnText}}</div>
                            </div>
                            <div class="handler-text">
                              <span>
                                累计购买 {{hisTotal}} {{goods.oecompany}}
                              </span>
                              <span class="colorRed" @click="hisBuyVis=true" v-if="hisTotal>0">
                                查看详情
                              </span>
                            </div>                         
                      </div>
                      <!-- 采购按钮样式 -->
                      <div class="handler" v-else>
                            <div class="join" :class="{'ycg-btn':goods.goods_storage==0}" @click="joinCart">
                              <div class="join-in"  >{{btnText}}</div>
                            </div>
                            <div class="handler-text">
                              <span>
                                累计购买 {{hisTotal}} {{goods.oecompany}}
                              </span>
                              <span class="handler-click" @click="hisBuyVis=true" v-if="hisTotal>0">
                                查看详情
                              </span>
                            </div>                         
                      </div>
                    </div>
                  </div>
              </div>
              

              <div class="sgj-box">
                <div class="share_atte">
                  <div class="han-item" @click="share">
                    <img src="../assets/detail/fenxiang-1.png" alt /> 分享
                  </div>
              
                </div>

                
              </div>

              <div class="goods_store">
                <!-- <div class="store-info" v-if="itemData.setlist_id || itemData.groupbuy_id"> 
                    <div class="hnw-left-t">
                      <span>华诺威总部</span>
                    </div>

                    <div class="hnw-left-t2">
                      <div class="hnw-left-in" @click="goBus()">
                        <img src="../assets/detail/dianpu.png" alt /> 进店逛逛
                      </div>
                      <div class="hnw-left-in" @click="collect">
                         <img src="../assets/store/heart.png" alt="" v-if="log_id">
                        <img src="../assets/store/huiseheart.png" v-else alt />
                        收藏店铺
                      </div>
                    </div>

                    <div class="detail-history-box">
                      <div class="detail-history">
                        <div>历史记录</div>
                      </div>

                      <div class="detail-history-list">
                        <div class="detail-history-item" v-for="(goods,index) in buyHisList" :key="index" @click="tododetail(goods)"> -->
                          <!-- <i class="el-icon-circle-plus" @click="hisJoinCart(goods)"></i> -->
                            <!-- {{goods.goods_name}}
                        </div>
                      </div>
                    </div>

                    <div class="shop-goods">
                        <div class="tab" v-if="itemData.groupbuy_id">
                          <div class="tab-item" :class="{'activeRed':shopGoodsHot}" @click="shopGoodsHot=true">店铺热销</div>
                          <div class="tab-item" :class="{'activeRed':!shopGoodsHot}" @click="shopGoodsHot=false">店铺新品</div>
                        </div>
                        <div class="tab" v-else>
                          <div class="tab-item" :class="{'active':shopGoodsHot}" @click="shopGoodsHot=true">店铺热销</div>
                          <div class="tab-item" :class="{'active':!shopGoodsHot}" @click="shopGoodsHot=false">店铺新品</div>
                        </div>
                        <div class="shop-goods-list">
                          <div v-show="shopGoodsHot">

                            <div class="goods-item" v-for="(collect,index) in storeHot" :key="index">
                              <div class="img-box" @click="tododetail(collect)">
                                <img :src="collect.goods_image" alt="">
                              </div>
                              <div class="num_price">
                                <div class="num">
                                  <div class="index">
                                    {{index+1}}
                                  </div>
                                  <div>
                                    热销{{collect.buyer_count}}
                                  </div>
                                </div>
                                <div class="price">
                                  ￥ {{collect.groupbuy_price}}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div v-show="!shopGoodsHot">
                            <div class="empty">
                              暂无商品
                            </div>
                          </div>
                        </div>
                    </div>
                </div> -->
                <div class="goods-info-box">
                  <div class="attr-table">
                    <div class="table-head" v-if="itemData.groupbuy_id">
                      <div class="head-item"  v-for="(item,index) in goodsAttr" :key="index" :class="{'activeRed':index==goodsAttrIndex}" @click="tabAttrIndex(index)">{{item}}</div>  
                    </div>
                    <div class="table-head" v-else>
                      <div class="head-item" v-for="(item,index) in goodsAttr" :key="index" :class="{'active':index==goodsAttrIndex}" @click="tabAttrIndex(index)">{{item}}</div>  
                    </div>
                    <div class="table-body">
                      <!-- 每个属性不同的展现形式 -->
                      <!-- 查看商品详情属性 -->
                      <template v-if="goodsAttrIndex==0">
                        <div class="goods-msg-body" v-if="!itemData.setlist_id">
                          <div class="goods-msg-col" v-for="(attr,index) in showGoodsMsgList" :key="index">  
                            <div class="label">{{attr.title}}：</div> 
                            <div class="value" >{{goods[attr.key]||'暂无数据'}}</div>
                          </div>
                        </div>
                      </template>
                      <template v-else-if="goodsAttrIndex==5">
                        <template v-if="goods.goods_bag.length>0">
                               <div class="head">
                                    <div class="sort">
                                        序号
                                    </div>
                                    <div class="oe">
                                        OE号
                                    </div>
                                    <div class="scode">
                                        生产码
                                    </div>
                                    <div class="name">
                                        商品名称
                                    </div>
                                    <div class="brand">
                                        配件品牌
                                    </div>
                                    <div class="num">
                                        套内数量
                                    </div>
                                    
                               </div>
                            <div class="partPrice" v-for="(goods,index) in goods.goods_bag" :key="index">
                               <!-- <span>{{goods.goods_name}}*{{goods.goods_num}}</span> -->
                               <div class="sort num-top2">
                                   {{index+1}}
                               </div>
                               <div class="oe  curpon num-top2" @click="redGoods(goods)">
                                   {{goods.oecode}}
                               </div>
                               <div class="scode num-top2">
                                   {{goods.sccode}}
                               </div>
                               <div class="name">
                                   {{goods.goods_name}}
                               </div>
                               <div class="brand">
                                   {{goods.oenaturebrand}}
                               </div>
                               <div class="num  num-top2">
                                   {{goods.goods_num}}
                               </div>
                            </div>
                        </template>
                        <div class="empty" v-else>
                          暂无数据
                        </div>
                      </template>

                      <template v-else-if="goodsAttrIndex==2">
                        <div class="comm-table-box" v-if="vehList.length>0">
                            <div class="comm-table-head">
                                <div>品牌名称</div>
                                <div>年款</div>
                                <div>车系名称</div>
                                <div>车型目录名称</div>
                                <div>配件编码</div>
                            </div>
                            <div class="comm-table-body">
                                <!-- substituteLink -->
                                <div class="body-row" v-for="(veh,index) in vehList" :key="index">
                                    <div>{{veh.brand_name}}</div>
                                    <div>{{veh.production_year}}</div>
                                    <div>{{veh.car_series_name}}</div>
                                    <div>{{veh.series_name}}</div>
                                    <div>{{veh.part_number}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="empty" v-else>
                          暂无数据
                        </div>
                      </template>
                      
                      <template v-else-if="goodsAttrIndex==1">
                        <div  v-loading="loadings">
                              <div class="comm-table-box" v-if="replaceList.length>0">
                                <!-- <div class="comm-table-head">
                                    <div class="cc-brand">车厂品牌</div>
                                    <div class="code">替换码</div>
                                    <div class="price">采购价</div>
                                    <div class="price">销售价</div>
                                    <div>备注</div>
                                
                                    <div class="handle" v-if="$store.getters.notLvD">操作</div>
                
                                </div> -->
                                <!-- 改为华诺威 -->
                                <div class="comm-table-head">
                                    <div class="xuhao">序号</div>
                                    <div class="cc-brand">OE</div>
                                    <div class="code">配件名称</div>
                                    <!-- <div class="price">品牌</div> -->
                                    <!-- <div class="price">是否可用</div>
                                    <div>替换类型</div> -->
                                
                                    <!-- <div class="handle" v-if="$store.getters.notLvD">操作</div> -->
                
                                </div>
                                <div class="comm-table-body">
                                    <!-- substituteLink -->
                                    <!-- <div class="body-row" v-for="(replace,index) in replaceList" :key="index">
                                      <div class="cc-brand">{{replace.brand_name}}</div>
                                      <div class="code">{{replace.replace_number}}</div>
                                      <div class="price">{{replace.purchase_price}}</div>
                                      <div class="price">{{replace.sale_price}}</div>
                                      <div>{{replace.remark}}</div>
                                      <div class="handle" v-if="$store.getters.notLvD"> 
                                          <i class="el-icon-shopping-cart-2 btn"   @click="toBuy(replace)" v-if="replace.gysNum&&$store.getters.notLvD"></i>
                                      </div>
                                    </div> -->
                                    <div class="body-row" v-for="(replace,index) in replaceList" :key="index">
                                      <div class="xuhao">{{index+1}}</div>
                                      <div class="cc-brand">{{replace.oecode}}</div>
                                      <div class="code">{{replace.lable}}</div>
                                      <!-- <div class="price">{{replace.oenaturebrand}}</div> -->
                                      <!-- <div class="price">{{replace.is_suit=='0'?'否':'是'}}</div>
                                      <div>{{12}}</div> -->
                                      <!-- <div class="handle" v-if="$store.getters.notLvD"> 
                                          <i class="el-icon-shopping-cart-2 btn"   @click="toBuy(replace)" v-if="replace.gysNum&&$store.getters.notLvD"></i>
                                      </div> -->
                                    </div>
                                </div>
                            </div>

                            <div class="empty" v-else>
                              暂无数据
                            </div>
                        </div>
                        
                      </template>
                      <template v-else-if="goodsAttrIndex==3">
                      
                        <div class="comm-table-box" v-if="priceList.length>0">
                            <div class="comm-table-head">
                              <div>车厂</div>
                              <div>采购价(不含税)</div>
                              <div>采购价(含税)</div>
                              <div>销售价(不含税)</div>
                              <div>销售价(含税)</div>
                              <div>销售价(含税)</div>
                            </div>
                            <div class="comm-table-body">
                               
                                <div class="body-row" v-for="(priceItem,index) in priceList" :key="index">
                                    <div>{{priceItem.brand_name}}</div>
                        
                                    <div>{{priceItem.purchase_price}}</div>
                                    <div>{{priceItem.purchase_price_tax}}</div>
                                    <div>{{priceItem.sale_price}}</div>
                                    <div>{{priceItem.sale_price_tax}}</div>
                                    <div>{{priceItem.sale_price_tax}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="empty" v-else>
                          暂无数据
                        </div>
                      </template>
                      <template v-else-if="goodsAttrIndex==4">
                         <div class="manu-box">
                            <div class="item">
                                <div class="label">
                                    企业名称
                                </div>
                                <div class="value">
                                    {{goods.store_sup.store_company_name}}
                                </div>
                            </div>
                            <div class="item">
                                <div class="label">
                                    企业电话
                                </div>
                                <div class="value num-top2">
                                    {{goods.store_sup.store_phone}}
                                </div>
                            </div>
                            <div class="item">
                                <div class="label">
                                    企业类型
                                </div>
                                <div class="value">
                                    {{goods.store_sup.sc_name}}
                                </div>
                            </div>
                            <div class="item">
                                <div class="label">
                                    所属区域
                                </div>
                                <div class="value">
                                    {{goods.store_sup.area_info}}
                                </div>
                            </div>
                            <div class="item">
                                <div class="label">
                                    联系人
                                </div>
                                <div class="value">
                                    {{goods.store_sup.contacts_name}}
                                </div>
                            </div>
                         
                            <div class="item">
                                <div class="label">
                                    企业地址
                                </div>
                                <div class="value">
                                   {{goods.store_sup.area_info}} {{goods.store_sup.store_address}}
                                </div>
                            </div>
                            <div class="item">
                                <div class="label">
                                    企业网址
                                </div>
                                <div class="value num-top2">
                                    <!-- http://www.chinawayautoparts.com/ -->
                                    {{goods.store_sup.company_site}}
                                    <!-- {{goods.store_sup.store_address}} -->
                                </div>
                            </div>
                            <div class="item">
                                <div class="label">
                                    企业公众号
                                </div>
                                <div class="value">
                                    {{goods.store_sup.company_gzcode}}
                                </div>
                            </div>
                            <div class="item">
                                <div class="label">
                                    经销商配件品牌
                                </div>
                                <div class="value">
                                   
                                    {{goods.store_sup.parts_brand}}
                                </div>
                            </div>
                            <div class="item">
                                <div class="label">
                                    经营汽车品牌
                                </div>
                                <div class="value">
                                    {{goods.store_sup.vehicle_brand}}
                                </div>
                            </div>
                        </div>
                        <!-- <div class="empty">
                          暂无数据
                        </div> -->
                      </template>
                      
                    </div>
                  </div>
                  <!-- 集单主体内容 -->
                  <div class="poster" v-html="itemData.groupbuy_intro">
                   
                  </div>
                 
                </div>
              </div>
            </div>
         </div>
    </template>
    <template v-if="tempNum==2">
      <div class="joinSuccess">
        <!-- 展示 -->
        <div class="goods-show-box">
          <div class="goods-show">
              <div class="show-left">
                <div class="tip">
                  <i class="el-icon-circle-check"></i>
                  商品已成功加入购物车
                </div>
                <div class="goods-first-show">
                    <div class="first" >
                      
                      <div class="img-box">
                        <img  style=""  :src="goods.goods_images[0]?goods.goods_images[0].img_url:'https://jc.hnwmall.com/data/upload/shop/common/default_goods_image_.gif'" alt="">
                      </div>
                    </div>
                    <div class="goods-msg" >
                      <ul>
                        <li @click="getGoodsInfo(goods.goods_id)" class="cur">
                          <div class="brand">{{goods.oenaturebrand}}</div>
                          <span class="xname" >{{goods.oecode}}</span>
                          <span class="name" :title="goods.goods_name">{{goods.goods_name}}</span>
                        </li>
                        <li class="gary-text middle"> <span >生产码：{{goods.sccode}} </span></li>
                        <li class="gary-text"><span>车型：{{goods.vehicletype}} </span></li>
                      </ul>
                      
                    </div>
                  </div>
              </div>
              <div class="show-right">
                <div class="read-info" @click="tempNum=1">
                  查看商品详情
                </div>
                <div class="to-cart" @click="$router.push('/shopcar/1/1')">
                  去购物车结算
                  <i class="el-icon-arrow-right"></i>
                </div>
              </div>
          </div>
        </div>
        <!-- 推荐 -->
        <div class="goods-reco hnw-area-center">
          <div class="reco-item two">
            <div class="reco-title">您可能还需要</div>
            <div class="goods-list">
              <div class="goods-item">
                <div class="goods-img">
                  <img src="../assets/temp/mschuizi.png" alt="">
                </div>
                <div class="info_handle">
                  <div class="goods-name">
                    汉高密封胶
                  </div>
                  <div class="goods-desc">
                    适用车型大众别克宝马北京现代
                  </div>
                  <div class="goods-price">
                    ¥149.00
                  </div>
                  <div class="joinCart"  @click="joinCart">
                    <i class="el-icon-shopping-cart-full"></i>
                    加入购物车
                  </div>
                </div>
              </div>
            </div>
            <div class="page-box">
              <div class="pagedot" v-for="i in 4" :key="i" :class="{'active':i==1}">
                {{i}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
       	<el-dialog
			:visible.sync="hisBuyVis"
			class="dema-info-dis"
			width="760px"
			top="20vh"
			>
			    <div slot="title" class="dema-info-title">
					<!-- <i class="el-icon-edit-outline"></i> -->
					<span>
						查看详情
					</span>
				</div>
				<div class="dama-info-msg">
					<div class="head">
            <span>累计金额:{{hisTotalPrice}}</span>
            <span>累计件数:{{hisTotal}}</span>
          </div>
          <div class="subject" v-for="(his,index) in hisList" :key="index">
            <div>{{$hnwTools.formatDateTime(his.add_time,'ymd')}}</div>
            <div class="member-name">{{his.member_name}}</div>
            <div>{{his.goods_num}}</div>
            <div>{{his.goods_price}}</div>
            <div>{{his.type==2?'集单':(his.type==3?"秒杀": "采购")}}</div>
            <div>{{$hnwTools.getOrderStateText(his.order_state)}}</div>
          </div>
          
				</div>
		</el-dialog>
  </div>
</template>

<script>

import Photo from "../components/Photo" 

import UserTalking from "../components/UserTalking"
// import area from '../tools/adress.js'
import area from "../tools/adress"
export default {
    components:{
        Photo,
        UserTalking
    },
  data(){

    return{
      
      tempNum:1,
      goodsAttrIndex:0,
      shopGoodsHot:true,
      imgSrc:"", //默认显示大图

      // 配送到的地址
      dispacthSite:"",
      goods_num:1,
     
      shop: ["店铺热销", "店铺新品"],
  
      storeHot: [
        
      ],
      nowList:[],
      prefoList:[],
        // "套内商品",
      goodsAttr: [
        "商品详情",
        "通用号码",
        "安装说明",
        "适配车型",
        // "适配车型",
        // "互换件",
        // "参考价格",
        // "配件商信息"
      ],
      groupbuy_intro:"<img src='https://cdn.cnbj1.fds.api.mi-img.com/middle.community.vip.bkt/1b0d46d07f83d2f19fe4b2b972dfee03' />",
      buyHisList: [],

      goods:{
        oecode: "",
        sccode: "",
        goods_storage:"",
        oenaturebrand:"",
        oenatureplace:"",
        store_sup:{}
      },
      // 商品详情那些属性
      showGoodsMsgList:[],

      bournList:[],
      // 控制是否可购买
      btnText:"参加集单",
      // 店铺是否被收藏
      log_id:false,
      hisBuyVis:false,
      hisTotal:0,
      hisTotalPrice:0,
      hisList:[],
      priceList:[],
      splist:['时','分','秒'],
      vehList:[],
      replaceList:[],
      sckilStartTime:0,
      loading:true,
      loadings:true,
      sort_type:'',
      sort_way:'', 
    }
  },
  async created(){
      
      scrollTo(0,0)
      // 是否是联盟详情或者是店铺
      if(this.$route.path=="/union/goodsinfo"||this.$route.path.includes("businesses")){
        this.goodsAttr = this.goodsAttr.slice(0,4)
        
      }
      // let showAttrs = ["标准名称","配件分类","原厂名称","计量单位","英文名称","规格","OE号","重量","生产编码","长度","配件品牌","宽度","产地","体积","件号","指导售价","部位码"]
      let showAttrs = ["产品名称","配件分类","英文名称","计量单位","OE号","规格","生产编码","重量","配件品牌","长度","宽度","体积"]

      showAttrs.forEach(attr=>{
        let hasAttr = this.$hnwTools.goodsAttrs.find(attr1=>{
          return attr1.title == attr
        })
        if(hasAttr){
          
          this.showGoodsMsgList.push({
            title:attr,
            key:hasAttr.key
          })
        }else{
          
          this.showGoodsMsgList.push({
            
            title:attr,
            key:'empty'
          })
  
        }

      })
      // 初始化 
      
      this.init()
      
      
      // 对配送至地址处理
     
      let temp = area.arrAll.slice(1,area.arrAll.length)
      // city   district
      temp = temp.filter(prov=>{
        if(prov.sub){
            prov.sub = prov.sub.filter(city=>{
              if(city.name!="请选择"){
                  if(city.sub){
                    city.sub = city.sub.filter(district=>{
                      if(district.name!="请选择"){
                        return district
                      }
                    })
                  }
                  return city
              }
            })
        }
        return prov
      })
      
      this.bournList = temp

      
      let colect = await this.$api.isCollect({
        member_id:this.$store.state.member_id,
        store_id:this.itemData.store_id
      })
      if(colect){

        this.log_id = colect.log_id
      }else{
        this.log_id = ""
        
      }
      // 热销放集单商品
      // 获取集单
      this.nowList = await this.$api.getSetList({mark:1})
      this.prefoList = await this.$api.getSetList({mark:2})
      this.storeHot = this.nowList.concat(this.prefoList)
      this.storeHot = this.storeHot.slice(0,2)
      
      
      this.storeHot.forEach(collect=>{
        if(this.$store.state.sc_id==14){
          collect.groupbuy_price = collect.groupbuy_price2
        }
      })
      this.$nextTick(()=>{
        if(this.itemData.state==20){
          let timeQuantum = this.itemData.leftDay*24*60*60*1000
          if(this.itemData.leftDay){
            this.sckilStartTime = this.itemData.end_time - timeQuantum
          }else{
            this.sckilStartTime = this.itemData.end_time
          }
        }else if(this.itemData.state==40){

          // let timeQuantum = this.itemData.leftDay*24*60*60*1000
          let timeQuantum = 0
          let leftQuantum = this.itemData.start_time 
          this.sckilStartTime = Number( leftQuantum - timeQuantum)
        }
 
      })
      this.handlerDay()
      
  },
  methods:{
    
    handleChange(value) {
      
      
    },
    impueNumChange() {
        if(isNaN(this.goods_num)){
          this.goods_num = 1
        }
        if(this.$store.state.shop!=1974&&this.$store.state.shop!=1871&&this.$store.state.shop!=1948){
            if(this.goods.goods_storage>0&&this.goods_num>this.goods.goods_storage){
          this.$nextTick(()=>{
            this.goods_num = this.goods.goods_storage
            })
            return this.$store.commit('UpDateState',{invDia:true})
          }
        }
        
    },
    // 初始化的方法
    async init(){
      this.loading = true
      this.itemData = JSON.parse(localStorage.getItem('hnw-web-item-data')) 
      
      if(!this.itemData){
        // 如果没有拿到数据
        // this.$router.push("/find/index")
      }
      if(this.itemData.setlist_id){
        this.itemData.coutDownStorage =  this.itemData.buy_total-this.itemData.buy_quantity
      }else if(this.itemData.groupbuy_id){
       
        this.itemData.coutDownStorage =  this.itemData.buy_total-this.itemData.buy_quantity
        
      }
      
      const res = await this.$api.getGoodsInfo({
        goods_id:this.itemData.goods_id,
        goods_commonid:this.itemData.goods_commonid
      }) 
     
      this.goods = res 
     
      // this.goods.goods_storage = Number(this.goods.goods_storage) 
      this.goods.goods_storage = this.itemData.goods_storage

      // *******************优百佳图片处理*****************************
      if(this.goods.store_id==2018){
        
        // 通过生产编码获取力洋的产品信息
        let rescode = await this.$http(`index.php?act=data&op=liyang&model=getproductbyproductno`,{data: this.goods.sccode,}); 
        
        if(rescode.Result.length!=0){
          rescode.Result.forEach(item => { 
            if(item.productImgs){
              this.goods.goods_images = []
              // 处理力洋接口的图片
              item.productImgs.split("^").forEach(iamge => {
                let arrImage = {}
                arrImage.goods_image = item.productUuid
                arrImage.img_url = iamge
                this.goods.goods_images.push(arrImage)
              })
            } 
          })
        }
      } 
      // **************************结束********************************

      if(this.goods.goods_images.length>0){
        this.imgSrc = this.goods.goods_images[0].img_url 
        // 商品有图但请求不到
        if(this.$store.state.for_branch&&this.$store.state.for_branch!=1780){
          try{
						
						let ImgObj = new Image(); // 生成一个Image对象
						ImgObj.src = this.goods.goods_images[0].img_url
						ImgObj.onerror = (err) =>{ 
							let goods_image_mobiles = []
							this.goods.goods_images.forEach(item=>{
								let imgArr = item.img_url.split("/")
								imgArr[imgArr.length-2] = "1780"
								// item = 
								goods_image_mobiles.push({
                  img_url:imgArr.join("/")
                })
							}) 
							this.goods.goods_images = goods_image_mobiles
						}
          }catch(e){ 
					}
        }
      }else{ 
        this.goods.goods_images.push({
          img_url:require("../assets/commom/noImg.png")
        })
      }
      if(!this.$store.state.token||!this.$store.state.store_id){
        // console.log("前",this.itemData.groupbuy_price,"后",this.itemData.groupbuy_price2)
        if(this.itemData.preRou!="企业B2b"){

          // this.itemData.groupbuy_price = this.itemData.groupbuy_price2
          this.goods.goods_price = this.goods.goodprice2
        }
      }
      
      
     
      
      if(this.itemData.preRou=='首页'||this.itemData.preRou=='集单采购'||this.itemData.preRou=='开放预售'){
          if(this.itemData.setlist_id){
              if(this.itemData.notbuy=='pre'){
                this.btnText = "集单未开始"
              }else if(this.itemData.notbuy=='over'){
                this.btnText = "集单已结束"
              }else{
                this.btnText = "参加集单"
              }
          }else if(this.itemData.groupbuy_id){
            if(this.itemData.state==40){
              this.btnText = "秒杀未开始"
            }else if(this.itemData.state==20){
              this.btnText = "马上抢"
            }else if(this.itemData.state==32){
              this.btnText = "秒杀已结束"
            }
          }else{
            
            if(this.itemData.goods_storage==0&&this.$store.state.shop!=1974&&this.$store.state.shop!=1871&&this.$store.state.shop!=1948){
              this.btnText = "加入预购单"
              // this.btnText = "加入购物车"
            }else{
              this.btnText = "加入购物车"
            }
            
          }
      }else{
        
        if(this.itemData.goods_storage==0&&this.$store.state.shop!=1974&&this.$store.state.shop!=1871&&this.$store.state.shop!=1948){
          this.btnText = "加入预购单"
          // this.btnText = "加入购物车"
        }else{
          this.btnText = "加入购物车"
        }
        
      }
    
      if(this.goods.store_id==1780){

        this.goods.goods_price = this.$handlePrice(this.goods)
      }else{
       
        this.$handleStorePrice(this.goods)
        if(this.goods.is_chain.includes("3")){
            // 处理直营属性
            this.$hnwTools.formatGoodsKey(this.goods)
        }
      }
     
     
      this.loading = false
      // 获取购买记录
      this.buyHisList = await this.$api.getBuyHisList()
      // 获取该商品的历史购买记录
      this.hisList = await this.$api.getGoodsRecord(this.itemData.goods_id)
      this.hisList.forEach(goods=>{
        this.hisTotal +=  Number(goods.goods_num)
        this.hisTotalPrice += Number(goods.goods_num) * goods.goods_price
      })

      if(this.goods.store_sup.store_id==1780){
          this.goods.store_sup.company_site = "http://www.chinawayautoparts.com/" 
          this.goods.store_sup.company_gzcode = "华诺威" 
          this.goods.store_sup.parts_brand = "大众"
          this.goods.store_sup.vehicle_brand = "大众" 
      }
      // let brandObj = {}
      // if(this.goods.oecarbrand){
      //     let branRes = await this.$api007.getAllBrand()
          
      //     brandObj = branRes.data.find(item=>{
      //         return item.name == this.goods.oecarbrand
      //     })
      //     brandObj = brandObj || {}
      // }else{
      //     let res = await this.$api007.getPartByOe({
      //         pid:this.goods.oecode
      //     })
      //     if(res.data&&res.data.length>0){
      //         brandObj.brandcode = res.data[0].brand_list[0].brandCode
      //     }
          
      // }
      // if(brandObj.brandcode){
      //     // 获取价格
      //     await this.getPrice(brandObj.brandcode)
      //     // 获取替换件
      //     await this.getPartReplace(brandObj.brandcode)
      // }else{
      //   console.log("未找到对应品牌",this.goods.oecarbrand);
      // }
      // // 获取适配车型
      // await this.getPartVeh('')
      
    },
    handleClick(tab, event) {
      
    },
    tododetail(acti){
				if(this.$isCompany()){
					
          // acti.preRou = "首页"
          acti.preRou = this.itemData.preRou
          // rou = "/purchase/goodsinfo"

					localStorage.setItem("hnw-web-item-data",JSON.stringify(acti))
					this.init()
          scrollTo(0,0)
					// this.$router.push(rou)
				}
				
		},
    // 处理时间大于一天的情况和完成率，以及标记上是否可以购买
    handlerDay(){

				// 计算即将开始时间
				// (acti.totalsum_now*10000) / (acti.totalsum*10000*10000)
				// "overList"

				let arrs = ["prefoList","nowList",]
				
				let key = ""
				let dayTime =  60*60*24*1000
				arrs.forEach(arr=>{

					if(arr=='prefoList'){
						key = "start_time"
						
					}else{
						key = "end_time"
					}
					
					this[arr].forEach(acti=>{
						let leftTime = 0
						if(arr=='prefoList'){
							
							acti[key] = acti[key]*1000
							
							leftTime =  acti[key] - new Date()
							acti.isNow = false
						}else{
							acti.isNow = true
							leftTime =  acti[key] - new Date()
						}

					
						if(leftTime>dayTime){
							let leftDay = parseInt(leftTime/dayTime)
							this.$set(acti,"leftDay",leftDay)
              
              
							// 剩余总时间减去大于一天的天数
							
							this.$set(acti,"leftTime", acti[key] - leftDay*dayTime )
							
						}else{
							this.$set(acti,"leftTime",acti[key])
						}

					
						if(arr=='nowList'||arr=='overList'){
							// let rote = parseInt(acti.totalsum_now*100) / (acti.totalsum*100) *100

						    let rote = ""
								if(Number(acti.totalsum)>0){
									rote = this.$hnwTools.div(acti.totalsum_now,acti.totalsum) *100
								}else{
									rote =  0
									// console.log("集单总金额发生错误,集单总金额或为0");
								}
								this.$set(acti,"rote",Number(rote.toFixed(2)))
						}   
						// 标记
						if(arr=="overList"){
							this.$set(acti,"notbuy",'over')
						}else if(arr=='prefoList'){

							this.$set(acti,"notbuy",'pre')
						}  

					})
					
				})
			},
    // 加入购物车
    async joinCart(){
      if(this.btnText=="马上抢"){
        // 秒杀看是否库存超出
        
        if(this.goods_num>this.goods.goods_storage){
          return this.$warMsg("商品库存不足");
        }
        if(this.itemData.upper_limit==0){
          let rou = '/shopcar/2/1'
          this.itemData.goods_num = this.goods_num
            localStorage.setItem("hnw-web-seckil-data",JSON.stringify(this.itemData))

            this.$router.push(rou)
        }else{

          if(this.itemData.shop){
            return this.$erMsg("已购买过")
          }else{
            
            let rou = '/shopcar/2/1'
            localStorage.setItem("hnw-web-seckil-data",JSON.stringify(this.itemData))

            this.$router.push(rou)
          }
        }

      }else if(this.btnText == "秒杀未开始"){
        this.$erMsg("秒杀未开始")
      }else if( this.btnText == "秒杀已结束"){
        this.$erMsg("秒杀已结束")
      }else{ 
          if(this.itemData.preRou=="店铺"){
            // console.log("店铺测试",);
            if(this.$store.state.store_id==this.itemData.store_id){
              return this.$warMsg("不能购买自己配件")
            }
          }
    
          if(this.itemData.notbuy){
            return this.$erMsg("当前不可购买")
          }
        //  对集单库存限制
        if(this.itemData.setlist_id){
          let temp = await this.$api.getCollectBuyerNum(this.itemData.setlist_id)
  
          if(Number(temp.buy_quantity)+Number(this.goods_num)>Number(temp.buy_total)&&temp.asnumover==1){
              this.itemData.coutDownStorage = temp.buy_total-temp.buy_quantity
              return this.$warMsg("集单库存不足")
          }
        }
        if(this.tempNum==1){
          let cart = []
          let cartGoods = {}
          cartGoods.goods_num = this.goods_num
          cartGoods.oecode = this.goods.oecode
          cartGoods.sccode = this.goods.sccode
          
          let keys1 = ["goods_id","goods_name","goods_price","goods_storage","store_id","store_name","setlist_id"]
          keys1.forEach(key=>{
            cartGoods[key] = this.itemData[key]
          })
          cartGoods.store_id = this.$store.state.shop
          // console.log("cartGoods",cartGoods,this.$store.state)
          // return
          //  console.log("this.itemData.groupbuy_price2",this.itemData.groupbuy_price2)
          // 对电商价格处理 
          
          
          if((this.btnText=="加入购物车"||this.btnText=="加入预购单")&&this.$store.state!=1974&&this.$store.state!=1871){
            cartGoods.cart_type = 1 // 采购类型
          }else{
            cartGoods.cart_type = 2 // 活动类型
            cartGoods.goods_price = this.itemData.groupbuy_price
            cartGoods.goods_storage = this.goods.goods_storage
          }
          
          if(this.itemData.preRou=="清库存"){ 
            cartGoods.goods_price = this.itemData.taxStoPrice2
          }

          if(this.$store.state.shop==1974||this.$store.state.shop==1871||this.$store.state.shop==1948){
            if(this.btnText=="加入购物车"||this.btnText=="加入预购单"){
              this.btnText="加入购物车"
            }
            if(cartGoods.goods_storage==0){
            cartGoods.goods_storage=1
          } 
          }
          
          // console.log("cartGoods",cartGoods)
          
          cart.push(cartGoods)
          // 跳转到加入成功页
          
          let res = await this.$api.joinCart(cart)
          
          // if(res){
           
          //     this.tempNum=2
          // }
        }else{
          
        }
      }

    },
    share(){
      const input = document.createElement('input');

      document.body.appendChild(input);

      if(this.$axios.defaults.baseURL=="https://jc.hnwmall.com/web/"){
          input.setAttribute('value', 'http://b.huanuowei.cn/');
          // window.open("http://jc.hnwmall.com:6600/#/login/"+mixText)
        }else{
          input.setAttribute('value', 'http://b2b.huanuowei.cn/');
          // window.open("http://www.huanuowei.cn/#/login/"+mixText)
        }
      input.select();
      if (document.execCommand('copy')) {

          document.execCommand('copy');
          // this.$message({
          //   message: '网址复制成功!',

          //   type: 'success'
          // })
        this.$suMsg("网址复制成功")
      }else{
          this.$erMsg("复制失败")
      }
      document.body.removeChild(input);
    },
    async hisJoinCart(goods){
          let carMessage = {
              goods_id:goods.goods_id,
              sccode:goods.sccode,
              oecode:goods.oecode,
              goods_name:goods.goods_name,
              goods_price:goods.goods_price,
              goods_storage:goods.goods_storage,
              goods_num:1,
              store_id:goods.store_id,
              store_name:goods.store_name,
              cart_type:'1',
          }
          let res = await this.$api.joinCart([carMessage])
          if(res){
            this.$suMsg("加入成功")
          }else{
            this.$erMsg("加入失败")
          }
    },
    joinCollect(){
      this.$suMsg("参加成功")
    },
   
    reBack(data){  
      this.$emit("reBack",{mode:6,data})
      // let preRou = this.itemData.preRou
      // if(preRou=='企业B2b'){
      //   this.$router.go(-1)
      // }else if(preRou=='店铺'||preRou.includes('直营')||preRou.includes('开放')){
        
      //   if(preRou.includes('直营')){

      //     if(preRou.includes('首页')){
      //       this.$emit("changeNum",1)
      //     }else if(preRou.includes('配件列表')){
      //       this.$emit("changeNum",2)
      //     }else if(preRou.includes('库存')){
      //       this.$emit("changeNum",8)
      //     }else if(preRou.includes('热销')){
      //       this.$emit("changeNum",9)
      //     }else if(preRou.includes('推荐')){
      //       this.$emit("changeNum",10)
      //     }else{
      //       this.$emit("changeNum",1)
      //     }
      //   }else if(preRou.includes('开放预售')){
      //      this.$emit("changeNum",13)
      //   }else{
      //     this.$emit("changeNum",1)
      //   }
      // }else{
      //   this.$router.go(-1)
      // }
      // this.$emit("reBack")
    },
    
    goBus(){
				this.$router.push("/store/businesses")
			},
    async collect(){
      
      if(this.log_id){

        let res = await this.$api.delCollect({
          log_id:this.log_id
        })
        if(res){
          this.$suMsg('取消成功')
          this.log_id = ""
        }else{
          this.$erMsg("取消失败")
        }
      }else{
          
          let res = await this.$api.addCollect({
            member_id:this.$store.state.member_id,
            member_name:this.$store.state.member_name,
            fav_id:this.goods.store_sup.store_id,
            fav_type:"store",
            store_id:this.goods.store_sup.store_id,
            shop_name:this.goods.store_sup.shop_name
          })
          if(res){
            this.$suMsg("收藏成功")
            this.log_id = res
          }else{
            this.$erMsg("收藏失败")
          }

      }

    },
    goBus(){
      this.$router.push(`/store/businesses/${this.goods.store_sup.store_id}`)
    },
    tabAttrIndex(index){ 

      this.goodsAttrIndex= index
      if(index==1){
        // this.getPartVeh()
        this.getPartRepalceHNW()
      }else if(index==2){
        // this.getPartReplace()

      }else if(index==3){
        // this.getPrice()
      }
    },
    async getPartRepalceHNW(){
        this.loadings=true
        let replaceRes = await this.$apiGET.getGoodsInfoReplace({
          oecode:this.goods.oecode
        }) 
        this.loadings=false  
        let goodsRes  = replaceRes.data||[]
        this.replaceList = goodsRes[0].info 
    },
    // async getPrice(){
    //     let res = await this.$apiScd.getPartPrice({
    //         manufacturer_id:12,
    //         part_number:this.goods.oecode,
    //     })
          
    //     this.priceList = res || []
    // },
    // async getPartReplace(){
    //     let res = await this.$apiScd.getPartReplace({
    //         manufacturer_id:12,
    //         part_number:this.goods.oecode
    //     })
        
    //     this.replaceList = res || []
    //     if(this.replaceList){
    //         let pid_list = this.replaceList.map(item=>item.replace_number)
    //         if(pid_list.length>0){
    //             let gysList = await this.$api.getGysNum({
    //                 oeList:pid_list,
    //                 for_branch:this.$store.state.for_branch,
    //                 shop:this.$store.state.shop
    //             })
                
    //             gysList.forEach(item=>{
    //                 if(item.data.length>0){
    //                     this.replaceList.forEach(part=>{
    //                         if(part.replace_number==item.oecode){
    //                             part.gysNum = item.data.length
    //                             part.storeList = item.data
    //                         }
    //                     }) 
    //                 }
                    
    //             })
    //         }
    //     }
    //   },
      // 适配车型
      // async getPartVeh(){
      //     let res = await this.$apiScd.getPartVeh({
      //         manufacturer_id:12,
      //         part_number:this.goods.oecode
      //     })
      //     this.vehList = res || []
          
      // },

      readVehInfo(veh){
          // let link = location.href.split("#")[0]
          
          // window.open(link + `#/catalog/vehinfo/${veh.uri_param.p}/${veh.uri_param.brandCode}/${veh.uri_param.mcid}`)


          // this.$emit("changeData",{
          //     brandCode:veh.uri_param.brandCode,

          //     mcid:veh.uri_param.mcid  
          // })
          
          veh.uri_param.mcid = veh.uri_param.mcid.replace(/\//g,"xiegang")
          this.$router.push(`/catalog/vehinfo/${veh.uri_param.p}/${veh.uri_param.brandCode}/${veh.uri_param.mcid}`)
          this.$store.commit("navPush",{
              label:`${veh.brandname}${veh.cars_model}${veh.year}${veh.market}`,
              route:`/catalog/vehinfo/${veh.uri_param.p}/${veh.uri_param.brandCode}/${veh.uri_param.mcid}`
          })
      },
      async toBuy(epcPart){
        if(epcPart.storeList[0]){
            let goods = epcPart.storeList[0]
            this.joinCart(goods)
        }  
      }
  },
  beforeDestroy(){
    localStorage.removeItem("hnw-web-item-data")
  }
};
</script>

<style   lang="scss" scoped>
.goods-info{
  // 商品详情页面
   background: #fff;
  .hnw-goods-detail{
    .top{
      // background: #f7f7f7;
      height: 40px;
      line-height: 40px;
      border-top: 1px solid #f7f7f7;
      >div{
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        padding-bottom: 10px;
        font-size: 12px;
        .title{
          >span{
            cursor: pointer;
          }
        }
        .hnw-logo{
          display: flex;
          align-items: flex-end;
          padding-top: 5px;
          .font{
  
          }
          .title-pic{
            display: flex;
            align-items: flex-end;
            margin-right: 20px;
            img{
              width: 55px;
              height: 24px;
              position: relative;
              margin-right: 8px;
            }
            .hnw{
              cursor: pointer;
            }
          }
          .collect{
            
            img{
              // width: 18px;
              // height: 18px;
              width: 14px;
              height: 14px;
              position: relative;
              margin-right: 4px;
              cursor: pointer;
              top: 1px;
            }
          }
  
        }
      }

    }
    
    .detail-container{
      display: flex;
    
      .detail-pic{

      }
      .detail-handler{
        // padding-top: 10px;
        box-sizing: border-box;
        height: 344px;
        margin-left: 30px;
        // display: flex;
        flex-direction: column;
        flex: 1;
        font-size: 14px;
        .detail-name{
          // margin-top: 10px;
          font-size: 20px;
          font-weight: bold;
          margin-bottom: 15px;
          position: relative;
          .deta-sename{
            padding-left: 20px;
          }
          .detail-desc{
            font-size: 14px;
            color: #999;
            padding: 10px 0;
          }
          .union-img{
            position: absolute;
            right: 20px;
            bottom: -75px;
            
          }
        }
        // 集单样式
        .total-order12 {
          height: 124px;
          .details-top12 {
            display: flex;
            justify-content: space-between;
            height: 40px;
            background: #f00;
            .current-time12 {
              display: flex;
              align-items: center;
              color: #fff;
              padding-top: 10px;
              margin-right: 10px;
            }
          }
          .details-price12 {
            background: rgb(255, 237, 239);
            display: flex;
            padding: 8px;
            .current-price12 {
              display: flex;
              align-items: flex-end;
              .big {
                font-size: 38px;
                margin: 0 10px -4px 15px;
              }
            }
            .progress-body {
              display: flex;
              width: 58px;
              height: 4px;
              border: 1px solid #f00;
              border-radius: 4px;
              overflow: hidden;
              margin: 5px 0;
              margin-right: 10px;
              .progress1 {
                text-align: center;
                // line-height: 14px;
                background-color: #f00;
                color: #fff;
                border-radius: 4px;
                
              }
            }
          }
        }

        .total-order{
          // display: flex;
          height: 105px;
          // align-items: center;
          background: #3E84D6;
          font-size: 14px;
          color: #fff;
          padding-left: 15px;
          // padding: 0 16px;justify-content: space-between;
          box-sizing: border-box;
          border-radius: 2px;
          box-shadow: 0px 0px 6px 0px rgba(52, 72, 92, 0.35);
          .details-top{
            height: 60px;
            display: flex;
            justify-content: space-between;
            // align-items:flex-end;
            // line-height: 60px;
            .details-price{
              display: flex;
              align-items:flex-end;
              .current-price{
                display: flex;
                align-items:flex-end;
                .big{
                  font-size: 38px;
                  margin: 0 10px -4px 15px;
                }
              }
            }
            .current-time{
              width: 193px;
              height: 59px;
              background: #E8F3FF;
              border-radius: 2px;
              .introduce{
                height: 28px;
                line-height: 28px;
                text-align: center;
                color: #3E84D6;
              }
              .specific-time{
                // padding-left: 10px;
                display: flex;
                 justify-content: center;
                .i-time-box{
                  min-width: 22px;
                  height: 22px;
                  // padding: 0 4px;
                  line-height: 24px;
                  text-align: center;
                  background: #3E84D6;
                  border-radius: 2px;
                }
                .day{
                  color:#3E84D6 ;
                  line-height: 24px;
                  font-size: 14px;
                  padding: 0 2px;
                }
              }
            }
          }
         .details-bottom{
            display: flex;
            justify-content: space-between;
            height: 50px;
            line-height: 50px;
            .plan{
              display: flex;
              color: #dfdfdf;
              .progress{
                display: flex;
                .percentage{
                  margin: 0 10px 0 16px;
                }
                .progress-body{
                  display: flex;
                  width: 180px;
                  height: 7px;
                  // background-color: #FEE8EA;
                  border: 1px solid #fff;
                  border-radius:  4px;
                  overflow: hidden;
                  margin-top: 21px;
                  margin-right: 10px;
                  .progress1{
                    text-align: center;
                    line-height: 14px;
                    background-color: #fff;
                    color: #fff;
                    border-radius:  4px;
                  }
                  

                }	
              }
            }
            .total-people{
              display: flex;
              align-items: center;
              padding-right: 20px;
              img{
                width: 20px;
                height: 20px;
                margin-top: -5px;
                padding-right: 10px;
              }
            }
          }


        //  以下是不用的
          .total-order-price{
            i{

            }
          }
          .total-center{
            display: flex;
            // align-items: center;
            >span{
              margin-right: 16px;
               img{
                width: 20px;
                height: 20px;
                margin-right: 10px;
              }
            }
            .total-people{
              display: flex;
              // align-items: center;
              img{
                width: 20px;
                height: 20px;
              }
            }
            .total-now-price{
              display: flex;
              // align-items: center;
              
            }
            .total-progress{
              span{

              }
            }
          }
          .total-odd{
            display: flex;
            // align-items: center;
            .day{
              margin: 0 4px;
            }
            .i-time-box{
              width: 16px;
              height: 16px;
              text-align: center;
              line-height: 16px;
              background: #fff;
              color: #3E84D6;
             
            }
          }
        }

        // 秒杀样式
        .total-orders{
          // display: flex;
          height: 105px;
          // align-items: center;
          background: #f00;
          font-size: 14px;
          color: #fff;
          padding-left: 15px;
          // padding: 0 16px;justify-content: space-between;
          box-sizing: border-box;
          box-shadow: 0px 0px 6px 0px rgba(52, 72, 92, 0.35);
          border-radius: 2px;
          background: linear-gradient(11deg, #FF0000, #FB1C31);
          .details-top{
            height: 60px;
            display: flex;
            justify-content: space-between;
            // align-items:flex-end;
            // line-height: 60px;
            .details-price{
              display: flex;
              align-items:flex-end;
              .current-price{
                display: flex;
                align-items:flex-end;
                .big{
                  font-size: 38px;
                  margin: 0 10px -4px 15px;
                }
              }
            }
            .current-time{
              width: 193px;
              height: 59px;
              background: #FFE1E1;
              border-radius: 2px;
              .introduce{
                height: 28px;
                line-height: 28px;
                text-align: center;
                color: #f00;
              }
              .specific-time{
                // padding-left: 20px;
                display: flex;
                justify-content: center;
                .i-time-box{
                  min-width: 22px;
                  height: 22px;
                  // padding: 0 4px;
                  line-height: 24px;
                  text-align: center;
                  background: #f00;
                  border-radius: 2px;
                }
                .day{
                  color:#f00 ;
                  line-height: 24px;
                  font-size: 12px;
                  padding: 0 2px;
                }
              }
            }
          }
          .details-bottom{
            display: flex;
            justify-content: space-between;
            height: 50px;
            line-height: 50px;
            .plan{
              display: flex;
              color: #dfdfdf;
              .progress{
                display: flex;
                .percentage{
                  margin: 0 10px 0 16px;
                }
                .progress-body{
                  display: flex;
                  width: 180px;
                  height: 7px;
                  // background-color: #FEE8EA;
                  border: 1px solid #fff;
                  border-radius:  4px;
                  overflow: hidden;
                  margin-top: 21px;
                  margin-right: 10px;
                  .progress1{
                    text-align: center;
                    line-height: 14px;
                    background-color: #fff;
                    color: #fff;
                    border-radius:  4px;
                  }
                  

                }	
              }
            }
            .total-people{
              display: flex;
              align-items: center;
              padding-right: 20px;
              img{
                width: 20px;
                height: 20px;
                margin-top: -5px;
                padding-right: 10px;
              }
            }
          }


        //  以下是不用的
          .total-order-price{
            i{

            }
          }
          .total-center{
            display: flex;
            // align-items: center;
            >span{
              margin-right: 16px;
               img{
                width: 20px;
                height: 20px;
                margin-right: 10px;
              }
            }
            .total-people{
              display: flex;
              // align-items: center;
              img{
                width: 20px;
                height: 20px;
              }
            }
            .total-now-price{
              display: flex;
              // align-items: center;
              
            }
            .total-progress{
              span{

              }
            }
          }
          .total-odd{
            display: flex;
            // align-items: center;
            .day{
              margin: 0 4px;
            }
            .i-time-box{
              width: 16px;
              height: 16px;
              text-align: center;
              line-height: 16px;
              background: #fff;
              color: #3E84D6;
             
            }
          }
        }

        
        .detail-price-v{
          padding: 20px;
          height: 85px;
          // line-height: 85px;
          background: #F5F5F5;
          box-sizing: border-box;
          display: flex;
          .weizi{
            line-height: 47px;
            font-size: 14px;
            color: #666666;
            padding-right: 5px;
          }
          .fsz38{
            font-size: 38px;
            color: #f00;
            display: flex;
            align-items: center;
            .fcl-price{
              font-size: 22px;
              color: #999;
              padding-left: 10px;
            }
          }
        }
        .datail-footer{
          
          padding: 0px 0 0 15px;
          
          box-sizing: border-box;
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          color: #666;
          .detail-msg{
            height: 40px;
            line-height: 40px;
            color: #999;
            span{
              margin-right: 18px;
            }
          }
          .item-row{
            height: 48px;
            line-height: 48px;
            display: flex;
            align-items: center;
            .label{
              width: 40px;
              color: #999;
            }
            .i-three{
              margin-right: 45px;
              i{
                color: #f00;
                margin-right: 3px;
              }
            }
            .i-threes{
              margin-right: 45px;
              i{
                color: #f00;
                margin-right: 3px;
              }
            }
            .clear-tip{
              margin-left: 10px;
              font-size: 12px;
              line-height: 12px;
            }
            .goods-Fcl-tit{
              color: #999;
            }
          }
          .handler{
            display: flex;
            .join{
              width: 189px;
              height: 46px;
              background: #f00;
              border-radius: 2px;
              display: flex;
              cursor: pointer;
              justify-content: center;
              align-items: center;
              color: #fff;
              margin-right: 8px;
              margin-top: 5px;
            }
            .joins{
              width: 189px;
              height: 46px;
              background: #f00;
              border-radius: 2px;
              display: flex;
              cursor: pointer;
              justify-content: center;
              align-items: center;
              color: #fff;
              margin-right: 8px;
              margin-top: 5px;
            }
            .handler-text{
              line-height: 60px;
              display: flex;
              justify-content: center;
              align-items: center;
              span{
                margin: 0 5px;
              }
              .colorRed{
                color: #f00;
                 cursor: pointer;
              }
              .handler-click{
                color: #f00;
                cursor: pointer;
              }
            }
          }
        }
        .block{
          .demonstration{
            .give{

            }
          }
        }
      }
    }
    
    // 分享举报关注等
    .sgj-box{
      display: flex;
      align-items: center;
      width: 420px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 0;
      .share_atte{
        display: flex;
        .han-item{
          margin-right: 20px;
          display: flex;
          align-items: center;
          cursor: pointer;
          img{
            margin-right: 5px;
          }
        }
      }
    }
  }
  // 商品详情下半部分
  .goods_store{
    display: flex;
    .store-info{
      width: 210px;
      margin-right: 10px;
      .hnw-left-t{
        width: 210px;
        height: 38px;
        line-height: 38px;
        padding-left: $leftPad;
        box-sizing: border-box;
        background: $backGray;
        border: 1px solid #ccc;
        // 
      }
      .hnw-left-t2{
        height: 68px;
        display: flex;
        align-items: center;
        border: 1px solid #ccc;
        border-top: none;
        .hnw-left-in{
          padding: 10px 8px;
          cursor: pointer;
          display: flex;
          align-items: center;
          img{
            margin-right: 5px;
          }
        }
      }
      .detail-history-box{
        margin-top: 20px;
        background: $backGray;
        border: 1px solid #ccc;
        .detail-history{
          height: 36px;
          padding-left: $leftPad;
          line-height: 36px;
          
          >div{
          }
        }
        .detail-history-list{
          .detail-history-item{
            height: 28px;
            border-top: 1px solid #ccc;
            padding-left: $leftPad;
            line-height: 28px;
            overflow: hidden; //超出的文本隐藏
            text-overflow: ellipsis; //溢出用省略号显示
            white-space: nowrap; //溢出不换行
            cursor: pointer;
            i{
              cursor: pointer;
            }
          }
        }
      }  
      .shop-goods{
        border: 1px solid #ccc;
        margin-top: 20px;
        .tab{
          display: flex;
          height: 38px;
          background: $backGray;
          .tab-item{
            flex: 1;
            text-align: center;
            line-height: 38px;
            cursor: pointer;
            border-bottom: 1px solid #ccc;
          }
          .active{
            border-bottom: 1px solid #3E84D6;
          }
        }
        .shop-goods-list{
          padding-bottom: 20px;
          .goods-item{
            .img-box{
              padding: 10px 0;
              text-align: center;
              img{
                width: 100px;
                height: 110px;
              }
            }
            .num_price{
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding: 0 10px;
              box-sizing: border-box;
              .num{
                display: flex;
                align-items: center;
                font-size: 12px;
                >div{

                }
                .index{
                  width: 16px;
                  height: 16px;
                  line-height: 16px;
                  
                  border-radius: 50%;
                  background: red;
                  color: #fff;
                  text-align: center;
                  margin-right: 4px;
                }
              }
              .price{
                color: red;
              }
            }
          }
          // 结果未空时
          .empty{
            text-align: center;
            padding-top: 5px;
            color: #999;
          }
        }
      }
    }
    .goods-info-box{
      // margin-left: 10px;
      // width: 980px;
      
      flex: 1;
      box-sizing: border-box;
      .attr-table {
        .table-head {
          display: flex;
          height: 38px;
          border-bottom: 2px solid #f00;
          box-sizing: border-box;
          .head-item {
            width: 100px;
            line-height: 38px;
            text-align: center;
            cursor: pointer;
          }
          .active {
            background: #f00;
            color:#fff;
          }
        }
        .table-body{
          .goods-msg-body{
            display: flex;
            flex-wrap: wrap;
             box-sizing: border-box;
            border: 1px solid #ccc;
            border-top: none;
            .goods-msg-col{
              width: 50%;
              display: flex;
              border-bottom: 1px solid #ccc;
              height: 28px;
              line-height: 28px;
              padding-left: 20px;
              box-sizing: border-box;
              .label{
                text-align: center;
                width: 90px;
                margin-right: 5px;
              }
              .value{

              }
            }
            .goods-msg-col:last-child{
              border: none;
            }
          }
          .empty{
            text-align: center;
            height: 100px;
            line-height: 100px;
            text-align: center;
            color: #999;
            border: 1px solid #ccc;
            border-top: none;
          }
          // 采购平台复制
            .label{
                // background: rgb(225, 239, 255);
                display: flex;
                justify-content: flex-end;
                align-items: center;
                padding: 5px 0;
                min-height: 15px;
                
            }
            
            .xiangqing-box{
                display: flex;
                border: 1px solid #E8E8E8;
                border-top: none;
                .col{
                    flex: 1;
                    
                    .item{
                        display: flex;
                        border-top: 1px solid #E8E8E8;
                        .label{
                            width: 80px;
                            // background: #59A8F8;
                            color: #666666;
                        }
                        .value{
                            padding: 5px 0;
                            color: #666;
                        }
                    }
                }
            }
            .chexing-box{
                border:1px solid#ccc;
                border-top: none;
                .item{
                    display: flex;
                    border-top: 1px solid #E8E8E8;
                    .label{
                        width: 80px;
                        justify-content: center;
                        border-right: 1px solid #ccc;
                    }
                    .value{
                        padding: 5px;
                    }
                }
                .item:first-child{
                  border-top: none;
                }
            }
            .comm-table-box{
                border: 1px solid #ccc;
                border-top: none;
                .comm-table-head{
                    display: flex;
                    border-right: none;
                    font-weight: 600;
                    >div{
                        border-right:1px solid #ccc; 
                        justify-content: center;
                        padding: 5px 0;
                        text-align: center;
                    }
                    .xuhao{
                      width: 200px;
                    }
                    .cc-brand{
                      width: 380px;
                    }
                    .code{
                      flex: 1;
                    }
                }
                .comm-table-body{
                    .body-row{
                        display: flex;
                        border-top: 1px solid #ccc;
                        >div{
                            // flex: 1;
                            padding: 5px 0;
                            // text-align: center;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border-right:1px solid #ccc;
                            .btn{
                              cursor: pointer;
                            }
                        }
                        .xuhao{
                          width: 200px;
                        }
                        .cc-brand{
                          width: 380px;
                        }
                        .code{
                          flex: 1;
                        }
                    }
                }
            }
            .manu-box{
                border: 1px solid #ccc;
                border-top: none;
                .item{
                    display: flex;
                    border-top: 1px solid #ccc;
                    .label{
                        width: 100px;
                    }
                    .value{
                        padding: 5px;
                        
                    }
                }
                .item:first-child{
                  border-top: none;
                }
            }
            .sort{
                width: 40px;
            }
            .oe{
                width: 120px;
            }
            .scode{
                width: 120px;
            }
            .name{
                width: 120px;
            }
            .brand{
                width: 80px;
            }
            .num{
                width: 100px;
            }
            .head{
                display: flex;
                
                background: $gray;
                height: 28px;
                line-height: 28px;
                text-align: center;
                justify-content: flex-start;
                padding:0  $leftPad;
            }
            .partPrice{
                padding: 5px $leftPad;
                border-bottom: 1px solid #ccc;
                display: flex;
                >div{
                    text-align: center;
                }
                .curpon{
                    cursor: pointer;
                    
                }
            }
            // 复制完毕
        }
      }
      
     
    
    }

  }
  .poster{
        margin-top: 20px;
        overflow: hidden;
        max-width: 1200px;
       p{
        max-width: 120px;
       }
      }
  //  加入成功页面部分
  .joinSuccess{
    .goods-show-box{
      background: #F7F7F7;
       padding: 10px 0;
    }
    .goods-show{
      width: 1200px;
      background: #fff;
      margin: 0 auto;
      display: flex;
      justify-content:space-between;
      
      .show-left{
        flex: 1;
        padding: 5px;
        .tip{
          color: #3E84D6;
          font-size: 18px;
          padding-bottom: 15px;
          display: flex;
          align-items: center;
          i{
            font-size: 24px;
            margin-right: 10px;
          }
        }
        .goods-first-show{
            // width: 330px;
            display: flex;
            .first{
              // width: 140px;
              display: flex;
              .img-box{
                width: 100px;
                height: 100px;
                border: 1px solid #EDEDED;
                margin-left: 10px;
                img{
                  width: 100%;
                  height: 100%;
                }
              }
              
              
            }
            
            .goods-msg{
              flex: 1;
              overflow: hidden;
              margin-left: 10px;
              .cur{
                cursor: pointer;
              }
              ul{
                li{
                  font-size: 14px;
                  text-align: center;
                  display: flex;
                  .brand{
                    background: #3E84D6;
                    color: #fff;
                    font-size: 12px;
                    min-width: 40px;
                    height: 18px;
                    line-height: 18px;
                    text-align: center;
                  }
                  .xname{
                    margin: 0 8px;
                  }
                  .name{
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  }
                }
                .gary-text{
                  color: #999;
                }
                .middle{
                  padding: 10px 0;
                }
              }
            }
          }
      }

      .show-right{
        display: flex;
        // align-items: flex-end;
        align-items: center;
        padding-right: 10px;
        >div{
          width: 162px;
          height: 36px;
          line-height: 36px;
          text-align: center;
          cursor: pointer;
        }
        .read-info{
          color: #3E84D6;
          background: #fff;
          margin-right: 10px;
        }
        .to-cart{
          background: #3E84D6;
          color: #fff;
          i{

          }
        }
      }
    }

    .goods-reco{
      padding: 15px 0;
      // 分页
      .page-box{
        display: flex;
        justify-content: center;
        .pagedot{
          width: 40px;
          height: 40px;
          line-height: 40px;
          border-radius: 50%;
          background: #f5f5f5;
          border:1px solid #f5f5f5;
          margin-right: 24px;
          text-align: center;
          cursor: pointer;
          &:hover{
            color: #3E84D6;
            background: #fff;
            border: 1px solid #3E84D6;
            box-sizing: border-box;
          }
        }
        
        .active{
          color: #3E84D6;
          background: #fff;
          border: 1px solid #3E84D6;
        }
      }

      .reco-item{
        .reco-title{
          font-size: 16px;
        }
        .goods-list{
          display: flex;
          flex-wrap: wrap;
          .goods-item{
            display: flex;
            width: 270px;
            margin-right: 25px;
            padding: 15px 0;
            .goods-img{
              width: 90px;
              height: 90px;
              border: 1px solid #ccc;
              margin-right: 10px;
              img{
                width: 100%;
                height: 100%;
              }
            }
            .info_handle{
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              .goods-name{

              }
              .goods-desc{

              }
              .goods-price{

              }
              .joinCart{
                border: 1px solid #ccc;
                width: 106px;
                height: 30px;
                font-size: 12px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                i{
                  font-size: 16px;
                  margin-right: 6px;
                }
              }
            }
          }
        }
       
      }
    }
  }
  
  		.dema-info-dis{
        
			::v-deep .el-dialog__header{
        
				padding: 10px 20px;
				background: #f00;
				.dema-info-title{
					font-size: 15px;
					color: #fff;
					i{
						margin-right: 5px;
						font-size: 18px;
					}
          
				}
				.el-dialog__headerbtn{
					top: 12px;
					i{
						color: #fff;
					}
				}
				
			}
			::v-deep .el-dialog__body{
				
				padding: 10px 20px;
				
				.dama-info-msg{
					padding-bottom: 10px;
					.head{
            height: 30px;
            background-color: #f3f4f5;
            line-height: 30px;
            span{
              padding-left: 20px;
            }
          }
          .subject{
            display: flex;
            height: 30px;
            line-height: 30px;
            >div{
              flex: 1;
              text-align: center;
            }
            .member-name{
              min-width: 220px;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }
          }
				}
			}
		}

}

.active{
  background: #3E84D6;
  color: #fff;
}
.activeRed{
  background: red;
  color: #fff;
}

</style>