<template>
  <div class="resales">
    <!-- <template v-if="orderData"> -->
      <div class="top-nav">
            <div @click="$router.push('/')">
                  我的华诺威
            </div>
              <i class="el-icon-arrow-right"></i>
              <div  @click="$router.go(-1)">
                  我的订单
              </div>
              <i class="el-icon-arrow-right"></i>
              <div>
                  订单号:
              </div>
              {{orderData.order_info.order_sn}}
          </div>
      <div class="details">
        退款详情
      </div>
      <div class="details">
        退款服务
      </div>
      <div class="steps">
        <el-steps :space="400" :active="1" align-center finish-status="success">
          <el-step title="买家申请退款"></el-step>
          <el-step title="商家处理退款申请"></el-step>
          <el-step title="完成退款"></el-step>
        </el-steps>
      </div>
      <div class="apply">
        先选择退款商品
      </div>
      <div class="return-details">
        <div class="refund-head">
          <el-checkbox v-model="allChoose" @change="Change"></el-checkbox>
          <span>订单号:{{orderData.order_info.order_sn}}</span>
          <span>订单类型:采购订单</span>
          <span class="client">提交人:{{orderData.store_info.seller_name}}</span>
        </div>
      </div>
      <div class="goods-list">
        <div class="list-head">
          <div class="head-item">
            <div class="oecode">OE号</div>
            <div class="goods_name">商品名称</div>
            <div class="goods_name">生产编码</div>
            <div class="goods_name">配件品牌</div>
          
            <div class="buy-nums">价格</div>
            <div class="buy-nums">订购数量</div>
            <div class="handle">退货数量</div>
          </div>
        </div>
      </div>
      <div class="list-body">
        <div class="list-goods" v-for="goods in orderData.goods" :key="goods.goods_id">
          <div class="row">
            <el-checkbox  v-model="goods.choose"></el-checkbox>
            <div class="row-item">
              <div class="oecode">{{goods.oecode}}</div>
            <div class="goods_name">{{goods.goods_name}}</div>
            <div class="goods_name">{{goods.sccode}}</div>
            <div class="goods_name">{{goods.oenaturebrand}}</div>
            
            <div class="buy-nums">{{goods.goods_price}}</div>
            <div class="buy-nums">{{goods.goods_num}}</div>
                <div class="handle"> 
                  <!-- @change="handleChange" -->
                  <el-input-number size="small" v-model="goods.goods_nums" :min="1" :max="parseInt(goods.goods_num)" ></el-input-number>
                </div>
            </div>
          </div>
        </div>
      </div>
      <div class="apply">
        买家退款申请
      </div>
      <div class="apply-body"  >
        <div class="apply-list">
          <span class="list-item">商品名称</span>
          <span>{{orderData.goods[0].goods_name}}</span>
        </div>
        <div class="apply-list">
          <span class="list-item">申请时间</span>
          <span>{{$hnwTools.formatDateTime(orderData.order_info.add_time)}}</span>
        </div>
        <div class="apply-list">
          <span class="list-item">退款金额</span>
          <span> {{refund}} </span>
        </div>

        <div class="apply-list">
          <span class="list-item">退款原因</span>
         <span class="selector">
          <el-select v-model="reason_id" placeholder="请选择">
            <el-option
              size="mini"
              v-for="item in reasonList"
              :key="item.reason_id"
              :label="item.reason_info"
              :value="item.reason_id"
              >
            </el-option>
          </el-select>
         </span>
        </div>
        
        <div class="apply-list">
          <span class="list-item">售后类型</span>
         <span class="selector">
          <el-select v-model="reType" placeholder="请选择">
            <el-option
              size="mini"
              label="退货"
              value="退货"
              >
            </el-option>
            <el-option
              size="mini"
              label="退款"
              value="退款"
              >
            </el-option>
         
          </el-select>
         </span>
        </div>

        <div class="apply-list">
          <span class="list-item">退款说明</span>
          <input type="text" v-model="buyer_message">
        </div>
        <div class="apply-lists">
          <span class="list-item">凭证说明</span>
          <span class="uploading">
            <el-upload
                :action="$axios.defaults.baseURL+'index.php?act=store_refund&op=upload_pic'"
                list-type="picture-card"
                :on-success="handlePictureCardpic"
                 name="pic"
                :data="{
                   store_id:$store.state.store_id
                }"
                :headers="{
                  token:$store.state.token
                }"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove">
                <i class="el-icon-plus"></i>
            </el-upload>
          </span>
          
        </div>
      </div>
      <div class="bottom">
        <el-button type="primary" @click="$router.go(-1)">返回</el-button>
        <el-button type="primary" @click="submit">提交</el-button>
      </div>
    <!-- </template> -->
  </div>
</template>

<script>
export default {
data() {
      return {
        reasonList:[],
         value: '',
         num: 1,
        //  orderData:"",
         allChoose: false,
         orderData:{
           goods:[
            {}
           ],
           order_info:[],
           store_info:[]
         },
         buyer_message:"",
         reason_id:"",
         reType:"退款",
         pic_info:"",
         infoStep:2
      }
    },
    methods: {
      handleChange(value) {
        
      },
        handleRemove(file, fileList) {
        
      },
      handlePictureCardPreview(file) {
        
      },
      handlePictureCardpic(res){
        this.pic_info= res.data.file_name
        console.log(res)
      },
       Change(val){
			  this.orderData.goods.forEach(item=>{
          this.$set(item,"choose",val
          )
        })
       },
      //  maxQuantity(){

      //  },
     async submit(){
       let goods = []
       this.orderData.goods.forEach(item=>{
          if(item.choose){
            goods.push({
              goods_id:item.goods_id,
              refund_num:item.goods_nums
            })
          }
        })
        let reason_info = ""

        console.log("this.reasonList",this.reason_id)
        if(this.reason_id){
          let pbj = this.reasonList.find(rea=>rea.reason_id==this.reason_id)
          reason_info = pbj.reason_info
        }

        let res =  await this.$api.addRefund({
            order_id:this.orderData.order_info.id,
            order_sn:this.orderData.order_info.order_sn,
            reason_id:this.reason_id,
            reason_info, 
            refund_amount:this.refund,
            refund_type:this.refund_type?'1':'2',
            buyer_message:this.buyer_message,
            pic_info:this.pic_info,
            goods
            
        })
        this.$suMsg('提交成功')
        this.$router.go(-1)
      }
    },
    async created(){
        // let orderGoods = localStorage.getItem("hnw-reorder-data")
       
        // orderGoods = JSON.parse(orderGoods)
        
        // console.log("orderGoods",orderGoods);
        this.orderData =  await this.$api.getOrderInfo({
            id:this.$route.params.id
        })
        // this.orderData.goods = [orderGoods]
        this.orderData.goods.forEach(goods=>{
          this.$set(goods,"goods_nums",1)
          this.$set(goods,"chose",false)
        })

        let res = await this.$api.getReasonList({
          type:''
        })
        this.reasonList=res
        
    },
    computed:{
      allChange(){
        let str = ''
        this.orderData.goods.forEach(item=>{
          if(item.choose){
            str += item.goods_name+','
            console.log(str);
          }
        })
        return str
      },
      refund(){
        let str = 0
        this.orderData.goods.forEach(item=>{
          str += item.goods_price * item.goods_nums
        })
        return str
      }
    }
}
</script>

<style lang="scss" scoped>
.resales{
  width: 1200px;
  margin: 0 auto;
  // border: 5px solid #ccc;
  box-sizing: border-box;
  .top-nav{
    padding-left: 10px;
      height: 30px;
      display: flex;
      align-items: center;
      background: #eee;
      color: #666;
        >div{
            cursor: pointer;
        }
        i{
          
        }
    }
    .steps{
      height: 50px;
      padding-left: 100px;
      text-align: center;
      margin: 40px 0;
    }
  .details{
    height: 30px;
    line-height: 30px;
    padding-left: 10px;
    border-bottom: 1px solid #ccc;
  }

  
    
  .return{
    height: 30px;
    line-height: 30px;
    padding-left: 10px;
    background-color: #ccc;
  }
  .return-details{
    .refund-head{
      position: relative;
      height: 30px;
      line-height: 30px;
      border-bottom: 1px solid #cccccc;
      .el-checkbox{
        margin: 0 20px;
      }
      >span{
        margin: 0 6px;
      }
      .client{
        position: absolute;
        top: 0;
        right: 10px;
        text-align: right;
      }
    }
  }
  .goods-list{
    .list-head{
      // padding-left: 52px;
      display: flex;
      height: 30px;
      align-items: center;
      // background: #EEEEEE;
      border-bottom: 1px solid #ccc;
      margin: 10px 0;
        .head-item{
          flex: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          >div{
            flex: 1;
            padding: 0 5px;
          }
        }
         .oecode{
                min-width: 130px;
                
            }
            .goods_name{
                min-width: 120px;
            }
             .warehouse{
                min-width: 190px;
            }
            .buy-nums{
                max-width: 120px;
                min-width: 120px;

            }
            .handle{
                min-width: 100px;
                max-width: 100px;
            }
    }
  }
  .list-body{
    // border-top: 1px solid #9E9E9E;
    .list-goods{
      // padding: 15px 0;
      border-bottom: 1px solid #CCCCCC;
      .row{
        display: flex;
        padding-bottom: 8px;
        
        
        .row-item{
          flex: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          >div{
            flex: 1;
            padding: 0 5px;
          }
        }  
        >.el-checkbox{
              margin: 8px 20px 0;
            }
            .oecode{
                min-width: 130px;
                
            }
            .goods_name{
                min-width: 120px;
            }
             .warehouse{
                min-width: 190px;
            }
            .buy-nums{
                max-width: 120px;
                min-width: 120px;

            }
            .handle{
                min-width: 100px;
                max-width: 100px;
                .el-input-number--small{
                  width: 100px;
                  line-height: 30px;
                  .el-input-number__decrease{
                    width: 28px;
                    font-size: 13px;
                  }
                }
            }
      }
    }
  }
  .apply{
    height: 30px;
    line-height: 30px;
    padding-left: 10px;
    background-color: #ccc;
    margin-top: 10px;
  }
  .apply-body{
    .apply-list{
      height: 28px;
      line-height: 28px;
      padding: 5px 0;
      border-bottom: 1px solid #ccc;
      .list-item{
        display: inline-block;
        width: 150px;
        text-align: center;
      }
      .selector{
       ::v-deep .el-select .el-input--suffix{
          >.el-input__inner{
            height: 28px;
            line-height: 28px;
            
          }
          .el-input__suffix{
            .el-input__icon{
              line-height: 30px;
            }
          }
         
        }
        
      }
     
    }
    .apply-lists{
      height: 150px;
      line-height: 150px;
      padding: 5px 0;
      border-bottom: 1px solid #ccc;
      .list-item{
        display: inline-block;
        width: 150px;
        text-align: center;
      }
      .uploading{
        display: inline-block;

      }
    }
  }
  .bottom{
    margin-top: 10px;
    height: 50px;
    text-align: center;
  }
}
</style>