<template>
<div class="inte-record">
    <inteGroups :groups="groups" @modeChange="modeChange"></inteGroups>
    <div class="inte-table">
        <div class="inte-table-head">
            <div>名称</div>
            <div class="time">时间</div>
            <div class="order-sn">订单编号</div>
            <div class="num">数量</div>
            <div>花费积分</div>
            <div>物流公司</div>
            <div>物流单号</div>
            <div class="handle">状态</div>
        </div>
        <div class="inte-table-body">
            <div class="inte-body-row" v-for="order in  orderList" :key="order.id">
                <div>{{order.name}}</div>
                <div class="time">{{$hnwTools.formatDateTime(order.time,'ymd')}}</div>
                <div class="order-sn">{{order.orderId}}</div>
                <div class="num">{{order.number}}</div>
                <div>{{order.total_price}}</div>
                <div>{{order.express}}</div>
                <div>{{order.expcode}}</div>
                <div class="handle" :class="'sta'+order.state">
                    {{order.state_text}}
                    <div v-if="order.state==1" class="aff-btn" @click="cencelOrder(order.orderId)">
                        取消订单
                    </div>
                    <div v-if="order.state==2" class="aff-btn" @click="affTake(order.id)">
                        确认收货
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="paging">

        <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="page"
            :page-sizes="[10,15, 20, 30, 40]"
            :page-size.sync="limit"
            layout="total, sizes, prev, pager, next, jumper"
            :total="Math.ceil(total)">
        </el-pagination>
    </div>  
</div>
</template>

<script>
import inteGroups from "./inteGroups.vue"
export default {
    data(){
        return{
            groups:[
                {
                    title:"综合",
                    value:""
                },
                {
                    title:"待发货",
                    value:"1"
                },
                {
                    title:"待收货",
                    value:"2"
                },
                {
                    title:"已完成",
                    value:"3"
                },
                {
                    title:"已取消",
                    value:"4"
                }
            ],
            page:1,
            limit:10,
            mode:"",
            total:20,

            orderList:[]
        }
    },
    methods:{
        async affTake(orderId){
            let params ={
            }
            if(this.$route.params.id){
                params.shop = this.$route.params.id
            }
            let res = await this.$http("index.php?act=integral&op=signfor",{
                orderId,
                member_id:this.$store.state.member_id,
                ...params
            })
            if(res){
                this.$suMsg("确认成功")
                this.getList()
            }else{
                this.$erMsg("确认失败")
            }
        },
        async cencelOrder(orderId){
            let params ={
            }
            if(this.$route.params.id){
                params.shop = this.$route.params.id
            }
            let res = await this.$http("index.php?act=integral&op=cancel",{
                orderId,
                member_id:this.$store.state.member_id,
                ...params
            })
            if(res){
                this.$suMsg("取消成功")
                this.getList()
                this.$store.dispatch("getInteNum")
            }else{
                this.$erMsg("取消成功")
            }
        },
        async handleSizeChange(val) {
            await this.getList()
            
            scrollTo(0,0)
        },
        handleCurrentChange(val) {
            this.getList()
        },
        modeChange(mode){
            this.mode = mode
            this.getList()
        },
        async getList(){
            let params ={
            }
            if(this.$route.params.id){
                params.shop = this.$route.params.id || "1780"
            }
            let res = await this.$api.getIntegralHilist({
                limit:this.limit,
                page:this.page,
                state:this.mode,
                ...params
            })
            this.total = res.count
            if(res.data){
                

                res.data.forEach(order => {
                    switch (order.state) {
                        case "1":
                            order.state_text =  "待发货"
                            break;
                        case "2":
                            order.state_text =   "待收货"
                            break;
                        case "3":
                            order.state_text =   "已完成"
                            break;
                        case "4":
                            order.state_text =   "已取消"
                            break;
                        default:
                            break;
                    }
                });
            } 
            this.orderList = res.data
        }
    },
    created(){
        this.getList()
    },
    components:{
        inteGroups
    },
    props:{
        
    }
}
</script>

<style lang="scss">
.inte-record{
    .inte-table{
        background: #fff;
        padding: 20px 25px;
        text-align: center;
        .inte-table-head{
            display: flex;
            background: #F5F5F5;

            >div{
                flex: 1;
                line-height: 32px;
            }
            .num{
                max-width: 40px;
            }
            .order-sn{
                max-width: 130px;
            }
            .time{
                max-width: 100px;
            }
            .handle{
                min-width: 150px;
            }
        }
        .inte-table-body{
            .inte-body-row{
                border-bottom: 2px solid #f5f5f5;
                display: flex;
                >div{
                    flex: 1;
                    line-height: 32px;
                    border-right: 2px solid #f5f5f5;
                }
                .num{
                    max-width: 40px;
                }
                .order-sn{
                    max-width: 130px;
                }
                .time{
                    max-width: 100px;
                }
                >div:last-child{
                    border-right: none;
                }
                .handle{
                    min-width: 150px;
                    display: flex;
                    justify-content: space-evenly;
                    >div{
                        color: #666;
                    }
                    
                    .aff-btn{
                        cursor: pointer;
                        color: #2B5B8D;
                    }
                }
                .sta1{
                    color: #50A3F8;
                }
                .sta2{
                    color: #D5B949;
                }
            }
        }
    }
    .paging{
        display: flex;
        justify-content:right;
        padding-top: 10px;
        .el-pagination{

        }
    }
}
</style>