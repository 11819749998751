<template>
<div class="my-address">
    <div class="address-title">
        <div>
            收货地址
        </div>
    </div>
    <div class="num_add">
        <div>
            已保存{{list.length}}条地址
        </div>
        <div class="handle">
            <div class="add-btn" @click="addForm">
                <i class="el-icon-plus"></i>
                新增收货地址
            </div>
        </div>
    </div>
    <div class="address-list">
        <div class="list-head">
            <div>收货人</div>
            <div>所在地区</div>
            <div>详细地址</div>
            <div>联系电话</div>
            <div class="handle">操作</div>
            <div class="defa"></div>
        </div>
        <div class="list-body">
            <div class="list-row" v-for="(site,index) in list" :key="index">
                <div>{{site.true_name}}</div>
                <div>{{site.area_info}}</div>
                <div>{{site.address}}</div>
                <div>{{site.mob_phone}}</div>
                <div class="handle">
                    <span @click="edit(site)">修改</span>
                    <div class="shu"></div>
                    <span @click="del(site.address_id)">删除</span>
                </div>
                <div class="defa">
                    <div class="defaSite" v-if="Number(site.is_default)">
                        默认地址
                    </div>
                    <div @click="defa(site)" v-else>设为默认</div>
                </div>
            </div>
        </div>
    </div>
    <SiteForm ref="siteform" :editData="editData" @getList="getList"  @closed="closed">

    </SiteForm>
</div>
</template>

<script>
import SiteForm from "../../components/SiteForm"
export default {
    data(){
        return{
            list:[],
            editData:{}
        }
    },
    methods:{
        edit(site){
            this.editData = site
           
            this.$refs.siteform.AddressVis=true
        },
        addForm(){
            this.$refs.siteform.AddressVis=true
            this.editData = {}
        },
        closed(){
			this.editData = {}
		},
        async del(address_id){
            this.$affMsg("确定删除该地址?",async ()=>{
                let res = await this.$api.delAddr(address_id)
                
				if(res){
					this.getList()
					this.$suMsg("删除成功")
				}else{
					this.$erMsg("删除失败")
				}
			},()=>{
				this.$infoMsg("已取消")
            })
            
        },
        async defa({address_id}){
            let res = await this.$api.defaAddr(address_id)
            if(res){
                this.$suMsg("设置成功")
                this.getList()
            }else{
                this.$erMsg("设置失败")
            }
        },
        async getList(){
            let res = await this.$api.getAddrList()
            this.list = res.data
        }
    },
    async created(){
        this.getList()
    },
    components:{
        SiteForm
    }
}
</script>

<style lang="scss">
.my-address{
    background: #fff;
    padding: 10px;
    box-sizing: border-box;
    .address-title{
        
        display: flex;
        border-bottom: 1px solid #ccc;
        
        >div{
            padding:0  22px;
            height: 100%;
            padding-bottom: 6px;
            border-bottom: 1px solid #3E84D6;
        }
    }
    .num_add{
        display: flex;
        justify-content: space-between;
        padding: 10px 0;
        >div{
            color: #3E84D6;
        }
        .handle{
        
            .add-btn{
                width: 120px;
                height: 32px;
                cursor: pointer;
                background: #3E84D6;
                color: #fff;
                display: flex;
                justify-content: center;
                align-items: center;
                i{
                    margin-right: 3px;
                }
                
            }
        }
    }
    .address-list{
        .list-head{
            height: 42px;
            line-height: 42px;
            display: flex;
            background: #F5F5F5;
            >div{
                flex: 1;
                text-align: center;
            }
            .handle{

            }
            .defa{

            }
        }
        .list-body{
            .list-row{
                display: flex;
                align-items: center;
                border: 1px solid #ccc;
                margin-top: 10px;
                padding: 20px 0;
                >div{
                    flex: 1;
                    text-align: center;
                    
                }
                .handle{
                    display: flex;
                    justify-content: center;
                    span{
                        cursor: pointer;
                    }
                    .shu{
                        width: 1px;
                        height: 12px;
                        background: #000;
                        margin: 0 5px;
                    }
                }
                .defa{
                    display: flex;
                    justify-content: center;
                    
                    >div{
                        cursor: pointer;
                        padding: 10px;
                    }
                    .defaSite{
                        padding: 10px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        color: #fff;
                        background: #3E84D6;
                    }
                }
            }
        }
    }
}
</style>