<template>
<!-- epc 商品列表展示 -->
<div class="Vin-Goods-List-table" id="list-table">
    <div class="Vin-Goods-List-body" @scroll="bodyScroll" :style="{'max-height':maxH+'px'}" id="list-body">
        <div class="vin-list-head-pla">

        </div>
        <div class="vin-list-head" :style="{'left':headL,}" >

            <div class="head-item" :class="{'copy-col':attr.title=='零件OE号'}" :style="{'min-width':attr.width,'max-width':attr.mxw?attr.mxw:''}" v-for="(attr,index2) in VinAttrs" :key="index2">
                <span>
                    {{attr.title}}
                </span>
            </div>
        </div>

        <div class="vin-list-body" v-if="showList">
                <div class="vin-list-pnum-row" v-for="(itidItem,index) in startGoodsList" :key="index" :id="itidItem.pnum" :class="{'active':itidItem.active}">

                    <div class="vin-list-part-row" 
                        v-for="(epcPart,index1) in itidItem.partList" 
                        :key="index1" 
                        :class="{'active':epcPart.active,'disabled':epcPart.colorvalue==0,'target':epcPart.target}" 
                        @click="rowClick(epcPart,itidItem)" 
                    >
                        <template v-if="epcPart.colorvalue==1||!listFilter">
                            <div class="row-col" 
                                :style="{'min-width':attr.width,'max-width'
                                :attr.mxw?attr.mxw:''}" v-for="(attr,index4) in VinAttrs"
                                
                                :key="index4" :class="{'copy-col':attr.title=='零件OE号','show-col':attr.title=='型号'}"
                                @click="copy(attr.title,epcPart[attr.key])"
                            >
                            <!-- :title="epcPart[attr.initKey]||epcPart[attr.key]" -->
                                    <div class="tag-icon" v-if="attr.title=='说明'&&epcPart.hasRe" title="含互换件">
                                        互
                                    </div>
                                    <!-- &&$store.getters.notLvD -->
                                    <div class="tag-icon" v-if="attr.title=='说明'&&!epcPart.hasRe&&epcPart.gysNum" title="可购买">
                                        货
                                    </div>

                                    <div  v-if="attr.title=='操作'" class="handle">
                                        <div class="btn" v-if="epcPart.pid" @click="readGoodsInfo(epcPart)">
                                            详情
                                        </div>

                                        <div class="shu" v-if="epcPart.gysNum">

                                        </div>
                                        <div class="btn" @click="toBuy(epcPart)" v-if="epcPart.gysNum">
                                        
                                            购买
                                        </div>
                                    </div>
                                    <el-tooltip class="item" effect="dark" :open-delay="300" :content="epcPart[attr.initKey]||epcPart[attr.key]" placement="top">
                                    <!-- <el-button>上边</el-button> -->
                                        <span >
                                            {{epcPart[attr.key]}}
                                        </span>
                                    </el-tooltip>
                                    
                                    <img class="copy-btn" v-if="attr.title=='零件OE号'&&epcPart[attr.key]" src="../../assets/vin/copy.png" alt="">
                                    <i class="el-icon-notebook-2 copy-btn"  @click="readPartConfig(epcPart)" v-if="$route.params.vin!='veh'&&attr.title=='型号'&&epcPart.sa_code&&showSa_code" src="../../assets/vin/copy.png" alt="">
                                    </i>
                                    <div class="re-fmid"  v-if="showRefm&&attr.title=='备注'&&epcPart.refmid&&epcPart.refmid.length>0" @click="toRefmid(epcPart)">
                                        参见 <span class="refmid-text">
                                            <span v-for="(re,index) in epcPart.refmid" :key="index">
                                                {{re}}
                                            </span>
                                        </span>
                                    </div>

                            </div>
                            
                        </template>

                    </div>

                </div>
        </div>
        
    </div>
    
    <div class="store-vis">
        <el-dialog
            :visible.sync="storeVis"
            width="900px"
            :show-close="false"
        >
        
            <div class="tit">
                供应商列表
                <i class="el-icon-close" @click="storeVis=false"></i>
            </div>
            <div class="list-body">
                <div class="list-head">
                    <div>供应商名称</div>
                    <div class="name">名称</div>
                    <div class="oe">OE号</div>
                    <div>品牌</div>
                    <div>价格</div>
                    <div>库存</div>
                    <div class="buy-nums list-rol">
                        购买数量
                    </div>
                    
                    <div>操作</div>
                </div>
                <div class="list-row" v-for="(goods,index) in buyStoreList" :key="index">
                    <!-- <div>{{goods.store_name}}</div> -->
                    <div>华诺威</div>
                    <div class="name">{{goods.goods_name}}</div>
                    <div class="oe">
                        <div class="tag-icon" v-if="goods.isRe" title="互换件">
                                互
                        </div> 
                        {{goods.oecode}}
                    </div>
                    <div>{{goods.oenaturebrand}}</div>
                    <div>￥{{goods.goods_price}}</div>

                    <div>
                        <!-- {{goods.goods_storage}} -->
                        <span v-if="$store.state.look_inv==1">{{goods.goods_storage}}</span>
                        <span v-else>{{goods.goods_storage>0?"有货":"无货"}}</span>
                    </div>
                    <div class="buy-nums list-rol">
                        <el-input-number v-if="$store.state.zero_buy==1" v-model="goods.goods_num" :min="1" size="mini"></el-input-number>
                        <el-input-number v-else @change="impueNumChange(goods)" v-model="goods.goods_num" :min="1" size="mini"></el-input-number>
                    </div>
                    
                    <div>
                        <span  class="btn" @click="joinCart(goods)">
                            {{goods.goods_storage>0?'加入购物车':'加入预购单'}}
                            <!-- 加入购物车  -->
                        </span>
                    </div>
                </div>
            </div>
        </el-dialog>
    </div>
    <el-drawer
        title="零件配置"
        :visible.sync="drawer"
        :with-header="false"
        size="40%"
        direction="ltr"
    >
        <div class="part-config-content">
            <div class="hint-title">
                编码与车辆配置信息匹配
            </div>
            <div class="config-data-table">
                <div class="table-head">
                    <div class="config-title" v-for="(title,index) in configTitle" :key="index">
                        {{title}}
                    </div>
                </div>
                <div class="table-content">
                    <div class="config-level" v-for="(config,index) in configData" :key="index">
                        <div class="sa-family">
                            {{config.sa_family}}
                        </div>
                        <div class="config-son-level" v-for="(sa_data,index1) in config.sa_data" :key="index1">
                            <div>{{sa_data.sa}}</div>
                            <div>
                                <el-tooltip class="item" effect="dark" :content="sa_data.desc" placement="top">
                                    <span>
                                        {{sa_data.desc}}
                                    </span>
                                </el-tooltip>
                            
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </el-drawer>
</div>
</template>

<script>

export default {
    data(){
        return{
            VinAttrs:[
                 {
                    title:"位置",
                    key:"itid",
                    width:"60px"
                },
                {
                    title:"零件OE号",
                    key:"pid",
                    width:"120px"
                },
                {
                    title:"名称",
                    key:'ellLabel',
                    initKey:"label",
                    width:"140px"
                },
                
                {
                    title:"型号",
                    key:'ellModel',
                    initKey:"model",
                    width:"140px"
                },
                
                {
                    title:"排量",
                    key:'',
                    initKey:"",
                    width:"30px"
                },
                
                {
                    title:"说明",
                    key:"a",
                    initKey:"a",
                    width:"80px",
                    mxw:"80px"
                },
                {
                    title:"备注",
                    key:"ellRemark",
                    initKey:"remark",
                    width:"180px"
                },
               
                {
                    title:"参考价格",
                    key:"sale_price",
                    width:"80px"
                },
                {   
                    title:"操作",
                    key:"handle",
                    width:"115px"
                }
            ],
            
            showList:false,
            headL:"0",
            handleT:"0",
            showInfo:false,
            storeVis:false,
            buyStoreList:[
               
            ],
            maxH:"",
            startGoodsList:[],
            exhiList:[],
            navTop:0,
            drawer:false,
            configTitle:['PR-家族', '设备编号', '名称'],
            configData:[{"sa_family":"GMO","sa_data":[{"sa":"T0K","desc":"4缸汽油发动机1.6L总成04E.4","filter":0,"available":1,"sa_family":"GMO","show":1},{"sa":"TJ5","desc":"1.6升","filter":1,"available":1,"sa_family":"GMO","show":0},{"sa":"TQ1","desc":"4缸汽油发动机1.4L总成04E.1","filter":1,"available":1,"sa_family":"GMO","show":0}]}],
        }
    },
    methods:{

        async toBuy(epcPart){
            
            this.storeVis = true
            
            this.buyStoreList = epcPart.storeList
            this.buyStoreList.forEach(item=>{
                // this.$set(item,'goods_num',1)
                // 价格体系
                item.goods_price = this.$handlePrice(item)
                this.$set(item,'goods_num',1)
            })
        },
        toRefmid(part){
            
            let subgroup = part.refmid[0].split(",")[1]
            let mid = part.refmid[0].split(",")[0]
            let params = {
                num:this.$route.params.num,
                mid,
                subgroup,
               
            }
            this.refmBrandCode = part.brandCode
            this.$emit("showRefmid",params)
        },
        async readPartConfig(part){
            let config = await this.$api007.getPartConfig({
                sa_code:part.sa_code,
                brandCode:this.$route.params.brandCode,
                vin:this.$route.params.vin
            })
            this.configTitle =  config.title
            this.configData =  config.data
            this.drawer = true
        },
        // async epcSearch(part){
            
        //     let res = await this.$api007.vinPartSearch(part)
        //     let nearList = []
        //     if(res.data.part_detail){

        //         res.data.part_detail.forEach(parts=>{
        //             if(Array.isArray(parts)){
        //                 parts.forEach(part=>{
                        
        //                     nearList.push(part)
        //                 })
        //             }else{
        //                 nearList.push(parts)
        //             }
        //         })
                
                
        //         nearList.forEach(part=>{
        //             part.remark = part.remark.replace(/<br\/>/g,"")
        //             part.label = part.label.replace(/<br\/>/g,"")
                    

        //         })
            
        //     }else{
        //         console.error("未查询到相邻配件")
        //     }
            
        //     this.epcPartList = nearList
        //     this.imgSrc = res.img.imagePath

            
        //     if(res.img.imgPoints){
        //         this.hotspots = res.img.imgPoints.hotspots
                
        //     }
        //     if(Array.isArray(this.hotspots)){
        //         this.hotspots.forEach(dot=>{
        //             dot.left = dot.hsX + "px"
        //             dot.top = dot.hsY + "px"
        //             dot.width = dot.maxX -  dot.hsX + "px"
        //             dot.height = dot.maxY -  dot.hsY + "px"
        //             // 该零件是否使用该车型
        //             let temp =  nearList.filter(item=>item.pnum==dot.hsKey)
        //             let isNot = temp.every(part=>part.colorvalue!=1)
        //             if(isNot){
        //                 dot.title = "零件不适用该车型"
        //             }
        //             this.$set(dot,"active",false)
        //         })
        //     }
            
        // },
        copy(title,text){
            if(title=='零件OE号'){
                // textareaEl.setAttribute('readonly', 'readonly'); // 防止手机上弹出软键盘
                if(text){

                    var textareaEl = document.createElement('textarea');
                    textareaEl.value = text;
                    document.body.appendChild(textareaEl);
                    textareaEl.select();
                    var res = document.execCommand('copy');
                    
                    document.body.removeChild(textareaEl);
                    this.$suMsg("复制成功")
                    return res;
                }
            }else{

            }
        },
        async joinCart(carItem){
            // this.storeVis = false

            let carMessage = {
                goods_id:carItem.goods_id,
                sccode:carItem.sccode,
                oecode:carItem.oecode,
                goods_name:carItem.goods_name,
                goods_price:carItem.goods_price,
                goods_num:carItem.goods_num,
                goods_storage:carItem.goods_storage,
                store_id:carItem.store_id,
                store_name:carItem.store_name,
                cart_type:'1'
            }
            
            await this.$api.joinCart([carMessage])
            
            // carItem.preRou = "找件"
            // localStorage.setItem("hnw-web-item-data",JSON.stringify(carItem))
            // let link = location.href.split("#")[0]
            // window.open(link + '#/find/info') 
        },
        impueNumChange(goods) {
            if(isNaN(goods.goods_num)){
                this.$nextTick(()=>{
					this.$set(goods,"goods_num",1)
				})
            }
            if(goods.goods_num>goods.goods_storage){
                this.$nextTick(()=>{
                    goods.goods_num = goods.goods_storage
                })
                return this.$store.commit('UpDateState',{invDia:true})
            }
        },
        bodyScroll(e){
           
            this.headL = -e.target.scrollLeft +"px"
            this.handleT = -e.target.scrollTop +"px"
        },
        readGoodsInfo(epcPart){
            
            if(!epcPart.pid){
                return this.$warMsg("")
            }
            this.$router.push(`/catalog/partinfo/${epcPart.pid}/${this.brandCode}/1`)
            this.$store.commit("navPush",{
                label:epcPart.ellLabel+"详情",
                route:`/catalog/partinfo/${epcPart.pid}/${this.brandCode}/1`
            })  
            
        },
        rowClick(epcPart,itidItem){
            
            if(epcPart.pnum){
                this.startGoodsList.forEach(itemItem=>{
                    itemItem.active= false
                    itemItem.partList.forEach(part=>{
                        this.$set(part,"active",false)
                    })
                })
                this.$emit("rowClick",epcPart.pnum)

                this.$set(epcPart,"active",true)
            }       
        },
       
    },
    async created(){
        // LS5A3ADE0AB046793
       
        let pid_list = []
        pid_list = this.goodsList.map(part=>{
            if(part.pid){

                return part.pid
            }else{
                return false
            }
        })

        
        
        pid_list = pid_list.filter(pid=>pid)
        
        let tempList = []

        tempList = this.goodsList.slice(0,this.goodsList.length)

        let priceRes = {}
       
        tempList.forEach(part=>{
         
            part.remark = part.remark.replace(/<br\/>/g,"")
            if(part.remark&&part.remark.length>7){
                part.ellRemark = part.remark.substr(0,20)+"..."
            }else{
                
                part.ellRemark = part.remark
            }
            // else  - - 
            part.label = part.label.replace(/<br\/>/g,"")
            if(part.label&&part.label.length>7){
                part.ellLabel = part.label.substr(0,20)+"..."
            }else{
                part.ellLabel = part.label
            }
            part.model = part.model.replace(/<br\/>/g,"")
            if(part.model&&part.model.length>7){
                part.ellModel = part.model.substr(0,20)+"..."
            }else{
                
                part.ellModel = part.model
            }
            if(part.refmid&&part.refmid.length>0){
                
                
            }
        })
        
        console.log("pid_list",pid_list);
        
         // 批量获取参考价格
        if(pid_list.length>0){
            priceRes = await this.$api007.VinTagsQuery({
                brandCode:this.brandCode,
                pid_list
            })
        }
        
        if(priceRes.data){
            if(Object.keys(priceRes.data).length>0){
                tempList.forEach(part=>{
                    if(part.pid){
                        part.sale_price = priceRes.data[part.pid].sale_price
                    }
                    
                })
            }
        }
        // 
        // 获取供应商数量
        
        // let gysList = await this.$api.getGysNum({
        //     oeList:pid_list
        // })
        
        // if(Array.isArray(gysList)){
        //     gysList.forEach(item=>{
        //         if(item.data.length>0){
        //             let part = tempList.find(part1=>part1.pid==item.oecode)
        //             part.gysNum = item.data.length
        //             part.storeList = item.data
        //         }
        //     })
        // }
       
        // 获取互换件列表
        
        this.$nextTick(async ()=>{
            if(pid_list.length>0){
                // console.log(pid_list)
                let replaceList = []
                let hnwGoodsRes = []
                let allRep = []
                // 互换件标记
                // 如果可以查看华诺威商品
                if(this.$store.state.sc_rent==1){
                    // replaceList  = await this.$api.getReplaceList({
                    //     oeList:pid_list.join(",")
                    // })
                
                }
                hnwGoodsRes = await this.$api.getGysNum({
                    oeList:pid_list,
                    for_branch:this.$store.state.for_branch,
                    shop:this.$store.state.shop
                })
                replaceList.forEach(item=>{

                    if(item.replace.length>0){

                        // let part = tempList.find(part1=>part1.pid==item.oecode)
                        tempList.forEach(part=>{
                            if(part.pid==item.oecode){
                                
                                // part.gysNum = item.replace.length
                                this.$set(part,"gysNum",item.replace.length)
                                part.storeList = item.replace
                                // 标记是否有互换件和零件列表的零件是不是互换件

                                let hasRe = false

                                part.storeList.forEach(goods=>{

                                    if(goods.oecode!=part.pid){
                                        hasRe = true
                                        goods.isRe = true
                                    }else{
                                        goods.isRe = false
                                    }
                                })

                                part.hasRe = hasRe
                                
                                allRep.push({
                                    oecode:item.oecode,
                                    rep:item.replace.map(item=>item.oecode)
                                })
                            }
                        }) 
                        

                    }
                })
                // 非互换件
                hnwGoodsRes.forEach(item=>{
                    if(item.data.length>0){
                        tempList.forEach(part=>{
                            if(part.pid==item.oecode){
                                // 如果有了数量
                                if(part.gysNum){
                                    let num = Number(part.gysNum) + item.data.length
                                    this.$set(part,"gysNum",num)
                                    part.storeList =  part.storeList.concat(item.data)
                                }else{
                                   
                                    this.$set(part,"gysNum",item.data.length)
                                    part.storeList = item.data

                                }

                                
                            }
                        }) 
                    }
                })
                tempList.forEach(part=>{
                    if(this.$store.state.for_branch||this.$store.state.shop){
                        if(part.storeList){
                            part.storeList.forEach(goods=>{
                                this.$handleStorePrice(goods)
                            })
                        }
                    }
                })
                console.log("tempList",tempList);
                // console.log("所有有的是",allRep)
            }
        })

        // 初始数组格式转化成二维
        
        tempList.forEach(part=>{

            // let obj = this.startGoodsList.find(item=>item.pnum==part.pnum)
            let isNew = true
            if(this.startGoodsList.length==0){
                this.startGoodsList.push({
                    active:false,
                    pnum:part.pnum,
                    partList:[part]
                })
            }else{
                if(part.pnum==this.startGoodsList[this.startGoodsList.length-1].pnum||part.pnum==''){
                    // 如果该元素序号等于数组最后一个或者是空，直接添加进最后一个
                    this.startGoodsList[this.startGoodsList.length-1].partList.push(part)
                }else{
                    this.startGoodsList.push({
                        active:false,
                        pnum:part.pnum,
                        partList:[part]
                    })
                }
            }

        })

        
        this.showList = true
        
    },
    mounted(){
        let partContent = document.querySelector("#list-table")
        this.maxH = partContent.offsetHeight
        
    },
    watch:{

        activePnum:{
            immediate:true,
            handler(n){
                this.$nextTick(()=>{
                    if(this.startGoodsList.find(item=>item.pnum==n)){
                        document.getElementById(n).scrollIntoView()
                    }
                    this.startGoodsList.forEach(epcPart=>{
                        
                        if(epcPart.pnum==n){
    
                        this.$set(epcPart,"active",true)
                        }else{
    
                        this.$set(epcPart,"active",false)
                        }
                        epcPart.partList.forEach(item=>item.active=false)
                    })
                })
            }
        
        }
    },
    
    props:{
       
        goodsList:{
            required:true
        },
        showSa_code:{
            default:true
        },
        brandCode:{

        },
        activePnum:"",
        
        filterText:"",
        listFilter:true,
        showRefm:{
            default:true
        }
    }
}
</script>

<style lang="scss" scoped>
$head:32px;
.Vin-Goods-List-table{
    position: relative;
    overflow: hidden;
    // margin-left: 10px;
    height: 100%;
    .Vin-Goods-List-body{    
        background: #fff;
        // max-height: 712px;
        max-height: 400px;
        overflow: auto;
        .vin-list-head{
            position: absolute;
            z-index: 2;
            top: 0;
            font-weight: 700;
            color: $vin-blue;
            display: flex;
            background: #FAFAFA;
            height: $head;
            line-height: $head;
            text-align: center;
            justify-content: flex-start;
            width: 0;
            min-width: 100%;
            padding-right: 14px;
            box-sizing: border-box;
            .head-item{
                background: #fafafa;
                flex: 1;
            }
        }
        .vin-list-head-pla{
            height: $head;
        }
        .vin-list-body{
            .vin-list-pnum-row{

                .vin-list-part-row{
                    display: flex;
                    align-items: center;
                    
                    // color: #333;
                    color: #666666;
                    font-weight: 700;
                    border-bottom: none ;
                    .row-col{
                        display: flex;
                        // justify-content: center;
                        justify-content: flex-start;
                        align-items: center;
                        flex: 1;
                        cursor: pointer;
                        height: 40px;
                        line-height: 18px;
                        border-bottom: 1px solid #ccc;
                        overflow: hidden;
                        position: relative;
                        font-size: 16px;
                        .tag-icon{
                            display: flex;
                            align-items: center;
                            transform: scale(.9);
                            justify-content: center;
                            width: 17px;
                            height: 17px;
                            border: 1px solid $vin-blue;
                            border-radius: 50%;
                            color: $vin-blue;
                            font-size: 12px;
                        }
                        .handle{
                            display: flex;
                            flex: 1;
                            justify-content: center;
                            color:#FF7519;
                            
                            box-sizing: border-box;
                            .shu{
                                // width: 1px;
                                width: 13px;
                                height: 14px;
                                // background: #FF7519;
                                position: relative;
                                &::before{
                                    content: "";
                                    position: absolute;
                                    width: 1px;
                                    height: 100%;
                                    background: #FF7519;
                                    left: 6px;
                                    top: 0;
                                }
                            }
                        }
                       .copy-btn{
                           transform: translate(2px,-4px);
                       }
                       .re-fmid{
                           .refmid-text{
                               color: #3E84D6;
                           }
                       }
                    }
                    .show-col{
                        position: relative;
                        .copy-btn{
                            position: absolute;
                            top: 5px;
                            right: 2px;
                        }
                    }
                    .button-box{
                        margin-left: 10px;
                        .buy-button{
                            cursor: pointer;
                            color: #409EFF;
                        }
                    }
                    .row-col:first-child{
                        justify-content: center;
                    }
                }
                .vin-list-row:hover{
                    background-color: #e6f7ff;
                }
                .disabled{
                    color: #f00;
                }
                // .disabled{
                //     color: #ccc;
                // }
                // .active.disabled{
                //     opacity: .5;
                // }
            }
            .active{
                border: 2px solid #1890ff !important;
            }
        }
        
    }
    ::v-deep .el-dialog__header{
        padding: 0;
    }
    ::v-deep .el-dialog__body{
        padding: 0;
        
    }
    .store-vis{
        .el-dialog{
            
            .tit{
                padding: 10px ;
                background: #3E84D6;
                color: #fff;
                display: flex;
                justify-content: space-between;
                .el-icon-close{
                    cursor: pointer;
                    color: #fff;
                }
            }
            .list-body{
                padding: 10px;
                .list-head{
                    display: flex;
                    background: #DFDFDF;
                    height: 28px;
                    line-height: 28px;
                    >div{
                        text-align: center;
                        flex: 1;
                    }
                    .name{
                        min-width: 120px;
                    }
                    .oe{
                        min-width: 140px;
                    }
                    >div:first-child{
                        text-align: left;
                        padding-left: 10px;
                    }
                    
                }
                .list-row{
                    display: flex;
                    align-items: center;
                    border-bottom: 1px solid#ccc;
                    >div{
                        text-align: center;
                        flex: 1;
                        padding: 8px 0 ;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        .btn{
                            cursor: pointer;
                            color: #3E84D6;
                        }
                    }
                    >div:first-child{
                        text-align: left;
                        padding-left: 10px;
                        justify-content: flex-start;
                    }
                    .tag-icon{
                        display: flex;
                        align-items: center;
                        transform: scale(.9);
                        justify-content: center;
                        width: 17px;
                        height: 17px;
                        border: 1px solid #ff781c;
                        border-radius: 50%;
                        color: #ff781c;
                        margin-right: 5px;
                        font-size: 12px;
                    }
                    .buy-nums{
                        .el-input-number{
                            width: 100%;
                        }
                    }
                     .name{
                        min-width: 120px;
                    }
                    .oe{
                        min-width: 140px;
                    }
                }
            }
           
        }
    }
    .part-config-content{
       
        .hint-title{
            padding:10px;
        }
        .config-data-table{
            .table-head{
                background: #fafafa;
                display: flex;
                .config-title{
                    padding: 8px;
                    width: 140px;
                    box-sizing: border-box;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden; 
                }
                .config-title:last-child{
                    flex:1;
                }
            }
            .table-content{
                .config-level{
                    .sa-family{
                        background: #eee;
                        padding: 8px;
                    }
                    .config-son-level{
                        display: flex;
                        padding-left: 140px;
                        border-bottom: 1px solid #eee;
                        >div{
                            padding: 8px;
                            width: 140px;
                            box-sizing: border-box;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow: hidden; 
                        }
                        >div:last-child{
                            flex:1;
                        }
                    }
                }
            }
        }
    }
}
</style>