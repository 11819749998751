<template>
<div class="vin-for-name-list">
    <el-drawer
        title="配件搜索"
        :visible.sync="drawer"
        direction="btt"
        :withHeader="false"
        @close="drawClosed"
        size="90%"
        >
        <!-- :wrapperClosable="false" -->
        <div class="dra-header">
            <div class="head-title">
                {{partsName}}
            </div>
            <div class="close">
                <i class="el-icon-close" @click="drawer=false"></i>
            </div>
        </div>
        <div class="dra-body" >
            <div class="body-search" v-if="!hideHandle">
                <div class="type-title">
                    <el-radio v-model="searchType" label="1">零件号</el-radio>
                    <el-radio v-model="searchType" label="2">故障码</el-radio>
                </div>
                <div class="sear-box">
                    <el-autocomplete
                            class="inline-input"
                            size="mini"
                            v-model="partsName"
                            :fetch-suggestions="querySearch" 
                            value-key="record_name"
                            :hide-loading="true"
                            @keyup.enter.native="oeSearch"
                    ></el-autocomplete>
                    <div class="btn" @click="oeSearch">
                        搜索
                    </div>
                </div>
                <div class="tip-tit" v-if="!listFilter">
                    *注意:红色字体零件不适配该车型
                </div>
                <div class="re-box" v-if="drawerNum==2">
                    <div class="filter"  :class="{'active':listFilter}" @click="listFilter=!listFilter">
                        过滤
                    </div>
                        <div class="btn" @click="drawerNum=1">
                        <img src="../../assets/vin/fh.png" alt="">
                        返回
                    </div>
                    

                </div>
            </div>

            <div class="body-list" v-if="drawerNum==1">
                <div class="list-tit">
                    为您找到相关结果约{{partList.length}}个
                </div>
                
                <div class="body-part-list">
                    <!-- @click="readInfo(part.pid)" -->
                    <div class="part-item" v-for="(part,index) in partList" :key="index" @click="partEpcSearch(part)">
                        <div class="img-box">
                            <img :src="part.url" alt="">
                        </div>
                        <div class="item-right">

                            <div class="part-label">
                                <template  v-for="(debris,index2) in part.label">
                                    <span :class="{'blue':debris==partsName}" :key="index2" v-if="debris">{{debris}}</span>
                                </template>
                            </div>
                            
                            <div class="part-params">

                                <div class="params-item">
                                    <div class="label">
                                        零件号：
                                    </div>
                                    <div class="value">
                                        <template  v-for="(debris,index2) in part.pid">
                                            
                                            <span :class="{'blue':debris==partsName}" :key="index2" v-if="debris">{{debris}}</span>
                                        </template>
                                    </div>
                                </div>

                                <div class="params-item">
                                    <div class="label">
                                        主组：
                                    </div>
                                    <div class="value"> 
                                        {{part.maingroupname}}
                                    </div>

                                    <div class="label">
                                        分组：
                                    </div>
                                    <div class="value"> 
                                        {{part.mid}}
                                        {{part.struct_label}}
                                    </div>
                                </div>

                                <div class="params-item">
                                    <div class="label">
                                        型号：
                                    </div>
                                    <div class="value"> 
                                        {{part.model}}
                                    </div>
                                </div>

                                <div class="params-item">
                                    <div class="label">
                                        备注：
                                    </div>
                                    <div class="value"> 
                                        {{part.remark}}
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <!-- 爆炸图页 -->
            <div class="epc-box" v-if="drawerNum==2" :style="{overflow:$store.state.isBoxScrool}">
                <div class="part-info">
                    <div class="part-img-box">
                        <div class="part-name">
                            {{partInfo.mid}}
                            {{partInfo.subgroup_label}}
                            
                        </div>
                    
                        <PartImg 
                            :partImgSty="partImgSty"
                            :imgSrc="imgSrc" 
                            :hotspots="hotspots"
                            @amplify="amplify"  
                            @acDotChange="acDotChange" 
                            v-if="showEpc"
                        >
                        </PartImg>
                        
                    </div>


                    <div class="part-list">
                        
                        <div class="goods-list-box">
                            <VinGoodsList 
                                :goodsList="epcPartList"  
                                :filterText="partsName" 
                                :listFilter="listFilter" 
                                :brandCode="brandCode" 
                                :activePnum="activePnum" 
                                @rowClick="rowClick"
                                :showRefm="false"
                                :showSa_code="false"
                                v-if="showEpc"
                            ></VinGoodsList>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    </el-drawer>
</div>
</template>

<script>
import VinGoodsList from "./VinGoodsList"
import PartImg from "./PartImg"

export default {
    data(){
        return{
            drawer:false,
            drawerNum:1,
            partsName:"",
            searchType:"1",
            listFilter:true,
            epcIndex:0,
            partImgSty:{
                width:"20rem",
                height:"100%"
            },
            imgSrc:"",
            hotspots:[],
            partList:[],
            partInfo:{},
            epcPartList:[],
            activePnum:"",
            dot:{},
            showEpc:false
        }
    },
    deactivated(){
        console.log("组件被隐藏")
        this.drawer = false
    },
    methods:{
        async querySearch(str,cb){

            // if(!this.searText){
            //     let res =  await this.$http("index.php?act=store_system&op=record_list",{
            //         store_id:this.$store.state.store_id,
            //         record_type:4
            //     })
            //     cb(res)
            // }else{
            //     cb([])
            // }
            cb([])
        },
        async oeSearch(){
            let params= {
                brandCode:this.brandCode,
                mcid:this.mcid,
                search_key:this.partsName
            }
            if(this.vin){
                params.vin = this.vin
            }
            const res = await this.$api007.vinSearchByName(params)
            this.partList = res.data
            
            // 需要获取标签得列表
            if(Array.isArray(this.partList)){

                let pid_list = this.partList.map(part=>part.pid)

                let priceRes = {}
                
                if(pid_list.length>0){
                    // 批量获取参考价格
                    priceRes = await this.$api007.VinTagsQuery({
                        brandCode:this.brandCode,
                        pid_list
                    })
                }
                this.partList.forEach(part=>{
                    part.remark = part.remark.replace(/<br\/>/g,"")
                    part.label = part.label.replace(/<br\/>/g,"")
                    part.sale_price = priceRes.data[part.pid].sale_price

                    part.pid = part.pid.replace(this.partsName,`%${this.partsName}%`)
                    part.pid = part.pid.split("%")

                    part.label = part.label.replace(this.partsName,`%${this.partsName}%`)
                    part.label = part.label.split("%")
                })
                this.drawer = true
                this.drawerNum = 1
            }else{
                
                this.$warMsg("未找到对应商品")
            }

            let res1 = await this.$api.addSearchRecord({
                record_name:this.partsName,
                record_type:3,
                car_type:"",
                record_from:2
            })

            console.log(res1)
        },
        async partEpcSearch(part){
            this.partInfo =  part
            let res = await this.$api007.vinPartSearch({
                vin:this.vin,
                brandCode:this.brandCode,
                mcid:this.mcid,
                num:part.num,
                mid:part.mid,
                subgroup:part.subgroup
            })
            
            let nearList = []
         
            if(res.img.imgPoints){
                this.hotspots = res.img.imgPoints.hotspots
            }
            if(Array.isArray(this.hotspots)){
                this.hotspots.forEach(dot=>{
                    dot.left = dot.hsX + "px"
                    dot.top = dot.hsY + "px"
                    dot.width = dot.maxX -  dot.hsX + "px"
                    dot.height = dot.maxY -  dot.hsY + "px"
                    this.$set(dot,"active",false)
                })
            }
            if(res.data.part_detail){

                res.data.part_detail.forEach(parts=>{
                    if(Array.isArray(parts)){
                        parts.forEach(part=>{
                            
                            nearList.push(part)
                        })
                    }else{
                        nearList.push(parts)
                    }
                })
            }else{
                console.error("未查询配件列表")
            }
            
            this.epcPartList = nearList

            if(res.img){
                this.imgSrc = res.img.imagePath
            }
            this.$emit("changeData",{
                brandCode:this.brandCode
            })
            this.drawer = true
            this.drawerNum  =2
            this.showEpc = true
            this.$nextTick(()=>{
                this.acDotChange(part.subgroup)
                
            })
        },
        amplify(isAmp){
            if(isAmp){
                this.partImgSty.width = "20rem"
            }else{
                this.partImgSty.width = "28rem"
            }
        },
       
        acDotChange(pnum){
            this.activePnum = pnum
            this.hotspots.forEach(dot=>{
                if(dot.hsKey==pnum){
                    dot.active = true
                }else{
                    dot.active = false
                }

            })
            
        },
        rowClick(pnum){
            this.hotspots.forEach(dot=>{

                if(dot.hsKey==pnum){
                    dot.active = true
                }else{
                    dot.active = false
                }

            })
        },
        drawClosed(){
            this.showEpc = false
            console.log("关闭抽屉",this.showEpc);
        }
    },
    created(){

    },
    components:{
        VinGoodsList,
        PartImg
    },
    props:{
        brandCode:"",
        mcid:"",
        vin:"",
        hideHandle:{
            default:false,

        }
    }
}
</script>

<style lang="scss">
.vin-for-name-list{
    // 列表弹框样式
    .el-drawer{
        .el-drawer__body{
            display: flex;
            flex-direction: column;
            overflow: auto;
            .dra-header{
                padding: 12px 24px;
                min-height: 38px;
                box-sizing: border-box;
                background: #1890ff;
                text-align: center;
                color: #fff;
                position: relative;
                display: flex;
                flex-shrink: 0;
                .head-title{
    
                }
                .close{
                    position: absolute;
                    right: 0;
                    font-size: 16px;
                    padding-right: 10px;
                    i{
                        cursor: pointer;
                    }
                }
            }
            
            .dra-body{
                flex: 1;
                overflow: auto;
                display: flex;
                flex-direction: column;
                .body-search{
                    display: flex;
                    position: relative;
                    justify-content: center;
                    align-items: center;
                    border-bottom: 5px solid #f0f2f5;
                    height: 40px;
                    flex-shrink: 0;
                    .type-title{
                        
                    }
                    .tip-tit{
                        margin-left: 1rem ;
                        color: #f00;
                    }
                    .sear-box{
                        padding-left: 10px;
                        display: flex;
                        align-items: center;

                        .el-input__inner{
                            padding: 1px 7px;
                            width: 200px;
                        }
                        .btn{

                            margin-left: 5px;
                            color: #fff;
                            background-color: #1890ff;
                            border-color: #1890ff;
                            text-shadow: 0 -1px 0 rgba(0,0,0,.12);
                            -webkit-box-shadow: 0 2px 0 rgba(0,0,0,.045);
                            box-shadow: 0 2px 0 rgba(0,0,0,.045);
                            height: 24px;
                            line-height: 24px;
                            cursor: pointer;
                            padding: 0 7px;
                            font-size: 14px;
                            border-radius: 4px;
                        }
                    }
                    .re-box{
                        position: absolute;
                        right: 30px;

                        display: flex;
                        align-items: center;
                        .btn{
                            box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.12);
                            border-radius: 6px;
                            img{
                                margin-right: 6px;
                                width: 13px;
                                height: 13px;
                            }
                        }
                        .btn{
                            color: #fff;
                            cursor: pointer;
                            background: $vin-blue;
                            width: 66px;
                            height: 30px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            i{
                                margin-right: 5px;
                            }
                        }
                        .filter{
                            @include fl-c;
                            cursor: pointer;
                            width: 66px;
                            height: 30px;
                            background: #FFFFFF;
                            color: #254385;
                            box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.12);
                            border-radius: 6px;
                            margin-right: 10px;
                        }
                        .active{
                            background: #254385;
                            color: #fff;
                        }
                        .tab{
                            @include fl-c;
                            cursor: pointer;
                            width: 66px;
                            height: 30px;
                            background: #FFFFFF;
                            box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.12);
                            border-radius: 6px;
                            margin-left: 10px;
                            i{
                                font-size: 20px;
                            }
                        }
                    }
                }
                .body-list{
                    flex: 1;
                    .list-tit{
                        color: rgba(0,0,0,.45);
                        height: 30px;
                        color: rgba(0,0,0,.45);
                        padding: 5px 10px;
                        display: flex;
                        align-items: center;
                    }
                    .body-part-list{
                        .part-item{
                            display: flex;
                            padding: 10px;
                            cursor: pointer;
                            border-bottom: 1px solid #eee;
                            .img-box{
                                width: 150px;
                                height: 150px;
                                img{
                                    width: 140px;
                                    height: 140px;
                                    border: 1px solid rgba(0,0,0,.1);
                                }
                            }
                            .item-right{
                                flex: 1;
                                padding-left: 10px;
                                .part-label{
                                    font-size: 16px;
                                    font-weight: 700;
                                    margin-top: 10px;
                                    margin-bottom: 10px;
                                    .blue{
                                        color: #1890ff;
                                    }
                                }
                                .part-params{
                                    .params-item{
                                        display: flex;
                                        height: 20px;
                                        line-height: 20px;
                                        .label{
                                            color: rgba(0,0,0,.45);
                                        }
                                        .value{
                                            margin-right: 10px;
                                            .blue{
                                                color: #1890ff;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        .part-item:hover {
                            box-shadow: 0 2px 12px 0 rgba(0,0,0,.15);
                        }
                    }
                }
                .epc-box{
                    flex: 1;
                    .part-info{
                        display: flex;
                        
                        height: 100%;
                        box-sizing: border-box;
                        background: #F4F6F7;
                        .part-img-box{
                            
                            padding-top: 44px;
                            .part-name{
                                color: #333333;
                                position: absolute;
                                height: 24px;
                                line-height: 24px;
                                background: #F7F7F7;
                                width: 100%;
                                top: 0;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }
                            position: relative;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        
                            margin-right: 5px;
                            margin-left: 5px;
                            background: #fff;
                            
                        
                            .hanlde-box{
                                position: absolute;
                                width: 100%;
                                height: 100%;
                                overflow: hidden;
                                .han-rigt{
                                    position: absolute;
                                    right: 20px;
                                    top: 60px;
                                    >div{
                                        width: 26px;
                                        height: 26px;
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        box-shadow: 0px 0px 4px 0px rgba(167, 167, 167, 0.35);
                                        border-radius: 3px;
                                        margin-top: 10px;
                                        cursor: pointer;
                                        i{

                                        }
                                        img{
                                            width: 16px;
                                            height: 20px;
                                        }
                                    }
                                }

                            }
                        }

                        .part-list{
                            flex: 1;
                            overflow: auto;
                            position: relative;
                            background: #fff;
                            display: flex;
                            flex-direction: column;
                        
                            .goods-list-box{
                                position: relative;
                                flex: 1;
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>