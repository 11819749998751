import { post } from "axios"
import store from "../../../store/index"

const getBrandGoodsList = async (data)=>{
    let code = ""
    if(store.state.shop==1780){
        code = store.state.is_sh=="0"?'丹阳仓':"上海仓"
    }
    let res = await post("index.php?act=brand_center&op=brandSearch",{
        ...data,
        code
    })
    return res
}
const getBrandGoodsListNew = async (data)=>{
    let house = ""
    if(store.state.shop==1780){
        house = store.state.is_sh=="0"?'丹阳仓':"上海仓"
    }
    let res = await post("index.php?act=search&op=brand",{
        ...data,
        house
    })
    return res
}

const getBrandMsgTotal = async (data)=>{
    let code = ""
    if(store.state.shop==1780){
        code = store.state.is_sh=="0"?'丹阳仓':"上海仓"
    }
    let res = await post("index.php?act=brand_center&op=brandCount",{
        ...data,
        code
    })
    return res
}

const getReplaceGoods = async (data)=>{
    let res = await post("index.php?act=brand_center&op=ReplaceGoods",{
        ...data
    })
    return res
}
// 品牌别名
const getBrandAliasName = async (data)=>{
    let res = await post("index.php?act=brand_center&op=brand_list",{
        ...data
    })
    return res
}
// 关键字别名
const getKeyWordName = async (data) => {
    let res = await post("index.php?act=web_index&op=alias",{
        ...data
    })
    return res
}

// 查询仓库货位
const searchAllocation = async (data) => {
    let res = await post("index.php?act=search&op=stockInfo",{
        ...data
    })
    return res
}
export {
    getBrandGoodsList,
    getBrandMsgTotal,
    getReplaceGoods,
    getBrandAliasName,
    getBrandGoodsListNew,
    searchAllocation,
    getKeyWordName,
}