<template>
<!-- 结构树查询 -->
<div class="Vin-tree-content">
    <div class="vehicle-search">
        
        <div class="vehicle-info">
            
            <div class="img-box" >
                <img src="../../assets/catalog/car.png" alt="">
            </div>

            <div class="info">
                <div class="info-item">
                    
                    <div>{{carMsg.brand}}</div>
                </div>
                <div class="info-item">
                    
                    <div>{{carMsg.VehicleSys}}</div>
                </div>
                <div class="info-item" v-if="carMsg.year">
                    
                    <div class="num">{{carMsg.year}}</div>
                </div>
                <div class="info-item" v-if="carMsg.displacement">
                    
                    <div class="num">{{carMsg.displacement}}</div>
                </div>
                <div class="info-item" v-if="carMsg.transmission">
                    <div class="num">{{carMsg.transmission}}</div>
                </div>
            </div>
            
            <div class="read-more" @click="showMoreMsg=true">
                查看更多 >>
                <!-- 车辆详情 -->
            </div>

            <div class="car-msg-info" v-show="showMoreMsg">
                <div class="msg-title">
                    车辆详细信息
                    <div class="close-btn"  @click="showMoreMsg=false">
                        <i class="el-icon-close"></i>
                    </div>
                </div>

                <div class="msg-body">
                        <div class="car-msg-row">
                        <div class="label">
                            车型:
                        </div>
                        <div class="value">
                            {{carAllMsg.model_name}}
                        </div>
                    </div>
                    <div class="car-msg-row" v-for="(v,k) in carAllMsg.maindata" :key="k">
                        <div class="label">
                            {{k}}:
                        </div>
                        <div class="value">
                            {{v}}
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <!-- 右边控制按钮 -->
        <div class="handle">
            <!-- v-if="$store.state.sc_rent==1&&$store.getters.notLvD" -->
            <div class="list-btn accurate" @click="toVinGoods" >
                精准找件
            </div>

            <div class="list-btn" :class="{'active':!listFilter}"  @click="listFilter=!listFilter" >
                    
                {{listFilter?'过滤':'未过滤'}}
            </div>
            <el-tooltip class="item"  effect="dark" :content="imgMode?'切换到列表':'切换到图片'" placement="top">
                <div class="list-btn" @click="imgMode=!imgMode"  :class="{'active':!imgMode}" >
                    {{imgMode?'图片':'列表'}}
                </div>
            </el-tooltip>

            <el-autocomplete
                class="inline-input"
                size="mini"
                v-model="partsName"
                :fetch-suggestions="querySearch"
                value-key="record_name"
                placeholder="请输入配件名称或者编号"
                :hide-loading="true"
                @keyup.enter.native="oeSearch"
            ></el-autocomplete>
            <div class="btn" @click="oeSearch">
                搜索
            </div>
        </div>
        
        <!-- <el-dialog
            title="车辆详细信息"
            :visible.sync="showMoreMsg"
            width="50%"
            center
            >
            <div class="car-msg-row">
                <div class="label">
                    车型:
                </div>
                <div class="value">
                    {{carAllMsg.model_name}}
                </div>
            </div>
            <div class="car-msg-row" v-for="(v,k) in carAllMsg.maindata" :key="k">
                <div class="label">
                    {{k}}:
                </div>
                <div class="value">
                    {{v}}
                </div>
            </div>

        </el-dialog> -->
        
    </div>
    
    <div class="part-content" ref="partContent" id="partContent">
        <!-- 计算完高度再渲染 -->
        <div class="part-group" >
            <div class="main-list" :style="{'max-height':maxH+'px'}">
                <div class="main-item" v-for="(group,index) in mainGroupList" :key="index" :class="{'active':group.label==mainGroup}"  @click="getBranch(group)">
                   <div class="img-box">
                       <img :src="group.img||'https://cdns.007vin.com/img/empty_subgrounp.png'" alt="">
                   </div>
                   <div class="tit">
                        {{group.num}}.
                        {{group.label}}
                   </div>
                </div>
            </div>

            <div class="branch-list" :style="{'max-height':maxH+'px'}" v-if="branchGroupList.length>0">

                <div class="branch-item" v-for="(group,index) in branchGroupList" :key="index" :class="{'active':group.name==branchGroup}" @click="getBranchChild(group)">
                    {{group.name}}
                </div>
            </div>
        </div>

        <div class="part-list-area" :style="{'max-height':maxH+'px'}">
            <div class="head">
                <div class="left">
                    共{{showImgList.length}}条
                </div>
                <div class="center-tit" v-show="!listFilter">
                    *蓝色字体：非此车架号的分组（参照原厂数据）
                </div>
                <div class="right" v-if="imgMode==true">
                    <template >
                        <div class="num">
                            {{listRowNum}}
                        </div>
                        <div class="handle">
                            <div>
                                <i class="el-icon-minus" @click="changeRNum(1)"></i>
                            </div>
                            <div>
                                <i class="el-icon-plus" @click="changeRNum(2)"></i>
                            </div>
                            <div class="defalut"  @click="changeRNum(3)">默认</div>
                        </div>
                    </template>
                </div>
                <div v-else>

                </div>
            </div>
            <!-- :style="{'max-height':maxH+'px'}" -->
            <div class="list-content" >
                <div class="img-list" v-if="imgMode==true">
                
                    <template v-for="(item,index) in showImgList">
                        <div class="img-item-box" :key="index" :style="{'width': (100/listRowNum).toFixed(2) + '%' }" >
                        
                            <div class="img-item "  @click="epcSearch(item)" :class="{'disabled':item.colorvalue==0}">
                                <img :src="item.src||'https://cdns.007vin.com/img/empty_subgrounp.png'" alt="">
                                <div class="part-num">
                                    {{item.mid}}
                                </div>
                                <div class="name">
                                    <div>
                                        {{item.name}}
                                    </div>
                                    <div v-if="item.model">
                                    型号： {{item.model}}
                                    </div>
                                    <div v-if="item.description">
                                    备注： {{item.description}}
                                    </div>
                                    
                                </div>
                                <div class="dis-vis">
                                    <i class="el-icon-close"></i>
                                </div>

                            </div>

                        </div>
                    </template>    
                </div>

                <div class="body-part-list" v-else>
                    <div class="li-head">
                        <div class="img">预览图</div>
                        <div class="main">主组</div>
                        <div class="group">分组</div>
                        <div class="inum">图号</div>
                        <div class="fl1">名称</div>
                        <div class="fl1">备注</div>
                        <div class="fl1">型号</div>
                        
                    </div>
                    <!-- -->
                    <div class="li-row" v-for="(item,index) in showImgList" :key="index"  :class="{'disabled':item.colorvalue===0}"  @click="epcSearch(item)">
                        <div class="img">
                            <el-popover
                                placement="left"
                                width="400px"
                                trigger="hover">
                                    
                                    <img :src="item.src||'https://cdns.007vin.com/img/empty_subgrounp.png'" alt="" style="width:300px"  @click="epcSearch(item)">
                                    <div class="img-box" slot="reference">
                                        <img :src="item.src||'https://cdns.007vin.com/img/empty_subgrounp.png'" alt="">
                                    </div>
                                </el-popover>

                        </div>
                        <div class="main">{{item.maingroup}}</div>
                        <div class="group">{{item.subgroup}}</div>
                        <div class="inum">{{item.mid}}</div>
                        <div class="fl1">{{item.name}}</div>
                        <div class="fl1">{{item.description}}</div>
                        <div class="fl1">{{item.model}}</div>
                        <div class="dis-vis">
                            <i class="el-icon-close"></i>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>
    <VinForNameList
        ref="vinforname"
        :brandCode="brandCode"
        :mcid="mcid"
        :vin="vinCode"
        >
    </VinForNameList>
</div>
</template>

<script>
import VinGoodsList from "./VinGoodsList"
import PartImg from "./PartImg"
import VinForNameList from "./VinForNameList"
export default {
    name:"vinTree",
    data(){
        return{
            radio:"1",
            mainGroupList:[],
            mainGroup:"",
            listFilter:true,
            listFilter1:true,
            
            branchGroupList:[],
            branchGroup:"",
            imgList:[],
            showImg:false,
            showEpcPart:false,
            epcIndex:1,
            partsName:"",
            partsListName:"",
            showTreeImg:true,
            imgMode:true,
            tempNum:1,
            partImgSty:{
                width:"20rem",
                height:"100%"
            },
            imgSrc:"",
            hotspots:[],
            partList:[],
            drawer:false,
            drawerNum:1,
            partInfo:{},
            epcPart:[],
            searchType:"1",
            showMoreMsg:false,
            goodsList:[],
            canvaScale:"",
            activePnum:"",
            dot:{},
            vinCode:"",
            brandCode:"",
            mcid:"",
            carAllMsg:{},
            // 每一行的个数
            listRowNum:5,
            maxH:800,
            ispop:false, 
            carMsg:{"brand":"","VehicleSys":"","year":"","displacement":"","transmission":"",displacementAll:''},
        }
    },
    methods:{
        changeRNum(type){
            if(type==1){
                if(this.listRowNum>1){
                     this.listRowNum--
                }
            }else if(type==2){
                this.listRowNum++
            }else if(type==3){
                this.listRowNum = 5
            }
        },
        async vinSearch(){
            
            this.vinCode = this.vinCode.replace(/\s/g,'').toUpperCase()
            if(!this.$store.state.vinTotal){
                console.log("总次数为0，应该刷新页面了");
                await this.$store.dispatch("getVinNum")
            }
            // 次数用完 不论是否查询过都不可查询 2021-11-27
            if(this.$store.state.vin_over_time<1&&this.$store.state.vinNum<1){
                this.$warMsg("您的次数已用完")
                return false
            }
            // 查询是否扣费

            let feeRes = await this.$api.getFeeSite(1)
            
            let ins_ok = 1
            // 永不扣费
            if(feeRes.ins_ok==0){
                ins_ok = 0

            }else if(feeRes.ins_repeat_ok==0){
                // 如果周期天数有
                if(this.$store.state.vin_over_time>0){
                    console.log("当前使用包月套餐");
                    ins_ok = 0
                }else{

                    let searTime = await this.$api.getSearTime({
                        record_name:this.vinCode,
                        store_id:this.$store.state.store_id
                    
                    })
                    if(Array.isArray(searTime)){
                        console.log("未找搜索记录，扣费")
                        
                    }else{

                        if(Number(feeRes.ins_date)==0){
                            ins_ok = 0
                            // console.log("永久不扣费")
                        }else{
                            // 当前时间是
                            let nowTime = new Date().getTime()/1000
                            // 扣费周期
                            
                            let feeDate = Number(feeRes.ins_date)*60*60*24 
                            
                            if(nowTime - searTime.add_time > feeDate){
                                ins_ok = 1
                                console.log("超出免费周期，扣费");
                            }else{
                                
                                ins_ok = 0
                            }
                        }
                        
                    }

                }
                // 重复扣费
            }
            
        
            // 添加查询记录
            await this.$api.addUseHis({
                use_model:"车架号解析全车件车型配置",
                use_content:this.vinCode,
                is_ok:ins_ok,
                store_id:this.$store.state.store_id,
                store_name:this.$store.state.store_name,
                member_id:this.$store.state.member_id,
                member_name:this.$store.state.member_name,
            })
            if(ins_ok==1){
                this.$store.dispatch("getVinNum")
            }
            
            const res = await this.$api007.vinSearch(this.vinCode)

            await this.$api.addSearchRecord({
                record_name:this.vinCode,
                record_type:3,
                car_type:this.carMsg.brand,
                record_from:1
            })
            console.log("res",res);
            if(Array.isArray(res.data)){
                
                return this.$router.go(-1)
            }
            if(res.title[0]){
                this.carMsg.brand = res.title[0]
            }
            if(res.title[1]){
                this.carMsg.VehicleSys = res.title[1]
            }
            if(res.title[2]){
                this.carMsg.year = res.title[2]
            }
            if(res.title[5]){
                this.carMsg.displacement = res.title[5].split(",")[0] ||""
                this.carMsg.displacementAll = res.title[5] ||''
            }
            if(res.data.maindata){
                this.carMsg.transmission = res.data.maindata.变速箱标识字母 || ''
                this.carMsg.bsx = res.data.maindata.变速箱 || ''
                this.carMsg.fdj = res.data.maindata.发动机标识字母 || ''
            }
            this.carAllMsg = res.data
            
            this.brandCode = res.data.brandCode

            this.mcid = res.data.mcid
            let veMsg = this.vinCode +"["+ this.carMsg.brand +"_"+this.carMsg.VehicleSys + "_"  +  (this.carMsg.year?this.carMsg.year:'' + "_")  + this.carMsg.fdj + "_"  + (this.carMsg.displacementAll?this.carMsg.displacementAll:''+"_")    + this.carMsg.bsx  + "]"
            // 查询
            this.$store.commit("UpDateState",{
                epcNavList:[{
                    label:veMsg+"结构树",
                    route:`/catalog/tree/${this.vinCode}/1`
                }]
            })
        },
        
        async getBranch(item){
            this.partsName = ""
            this.mainGroup = item.label
            let res = await this.$api007.getBranchGroupList({
                vin:this.$route.params.vin,
                brandCode:this.brandCode,
                mcid:item.mcid,
                num:item.num
            })
            // let res = await this.$api007.getBranchGroupList({
            //     vin:"LYVUFBAA4KB184478",
            //     brandCode:"volvos",
            //     mcid:"ZD18dns9JT89LConQC4pLSY9Yg%3D%3D",
            //     num:item.num
            // })
            // item.imageLarge

            if(!res.data||!res.data[0]){
                return this.imgList = []
            }
            
            // 直接就是零件了
            if(res.data&&res.data[0]&&res.data[0].has_child==0){
                
                this.imgList = res.data

                // this.imgList = this.imgList.filter(part=>part.colorvalue==1)

                this.imgList.forEach((part,index)=>{
                    part.src = res.imgs[part.imageLarge]
                    part.flagindex = index
                })
               
                this.branchGroupList = []
            }else{
                
                this.branchGroupList  = res.data
                this.branchGroupList = this.branchGroupList.filter(item=>item.name!='概述'&&item.name!='杂项')
                this.getBranchChild(this.branchGroupList[0])
            }
            
        },

        async getBranchChild(item){
            this.branchGroup = item.name
            let res  = await this.$api007.getBranchChildList({
                vin:this.$route.params.vin,
                brandCode:this.brandCode,
                mcid:item.mcid,
                num:item.num,
                level:item.level,
                p_name:item.p_name,
                subgroup:item.subgroup,
            })
            
            // let res  = await this.$api007.getBranchChildList({
            //     vin:"LYVUFBAA4KB184478",img-item 
            //     brandCode:"volvos",
            //     mcid:"ZD18dns9JT89LConQC4pLSY9Yg%3D%3D",
            //     num:item.num,
            //     level:item.level,
            //     p_name:item.p_name,
            //     subgroup:item.subgroup,
            // })

            

            this.imgList = res.data

            this.imgList.forEach((part,index)=>{
                part.src = res.imgs[part.imageLarge]
                part.flagindex = index
            })
            
        },

        async epcSearch(part){
            let bztMsg =""
            if(this.branchGroup){
                
                bztMsg = this.mainGroup +">"+ this.branchGroup 
            }else{
                bztMsg = this.mainGroup
            }
            
            

            localStorage.setItem("hnw-vin-img-list",JSON.stringify(this.showImgList))
            
            this.$router.push(`/catalog/epcimglist/${part.brandCode}/${part.mcid.replace(/\//g,'xiegang')}/${this.$route.params.vin}/${part.num}/${part.mid}/${part.subgroup}/${part.name.replace(/\//g,'xiegang')}`)

            this.$store.commit("navPush",{
                label:bztMsg,
                pop:this.ispop,
                route:`/catalog/tree/${this.vinCode}/${bztMsg}`
            }) 
            this.ispop = true
        },
       
        toVinFill(){

        },
        async oeSearch(){
            this.$refs.vinforname.partsName = this.partsName
            this.$refs.vinforname.oeSearch()
        },
        // 去到精准找件
        toVinGoods(){
            this.$router.push(`/catalog/vinGoods/${this.vinCode}`)
            // 将导航重置
            
        },
        async partEpcSearch(part){
            this.partInfo =  part
            let res = await this.$api007.vinPartSearch({
                brandCode:this.brandCode,
                mcid:this.mcid,
                num:part.num,
                mid:part.mid,
                subgroup:part.subgroup
            })
           
            let nearList = []
         
            if(res.img.imgPoints){
                this.hotspots = res.img.imgPoints.hotspots
            }
            if(Array.isArray(this.hotspots)){
                this.hotspots.forEach(dot=>{
                    dot.left = dot.hsX + "px"
                    dot.top = dot.hsY + "px"
                    dot.width = dot.maxX -  dot.hsX + "px"
                    dot.height = dot.maxY -  dot.hsY + "px"
                    this.$set(dot,"active",false)

                })
            }
            

            if(res.data.part_detail){

                res.data.part_detail.forEach(parts=>{
                    if(Array.isArray(parts)){
                        parts.forEach(part=>{
                            nearList.push(part)
                        })
                    }else{
                        nearList.push(parts)
                    }
                })
                
                
              
            }else{
                console.error("未查询配件列表")
            }

            this.epcPart = nearList

            if(res.img){
                this.imgSrc = res.img.imagePath
            }
            this.$emit("changeData",{
                brandCode:this.brandCode
            })
           
            this.drawerNum  =2
        },
       
        partListSearch(){

        },
        amplify(isAmp){
            if(isAmp){
                this.partImgSty.width = "20rem"
            }else{
                this.partImgSty.width = "28rem"
            }
        },
       
        acDotChange(pnum){

            this.activePnum = pnum
            this.hotspots.forEach(dot=>{
                if(dot.hsKey==pnum){
                    dot.active = true
                }else{
                    dot.active = false
                }

            })
            
        },
        switchImg(){
            console.log("切换图片")
        },
        async querySearch(str,cb){

            // if(!this.searText){
            //     let res =  await this.$http("index.php?act=store_system&op=record_list",{
            //         store_id:this.$store.state.store_id,
            //         record_type:4
            //     })
            //     cb(res)
            // }else{
            //     cb([])
            // }
            cb([])
        },
        
    },
    computed:{
        showImgList(){
            let list = []
            list = this.imgList.filter(item=>{
                if(this.listFilter){
                    return item.colorvalue==1
                }else{
                    return item
                }
            })
            return list
        }
    },
    watch:{
        
    },
    activated(){
        // console.log("组件被激活")
        // this.isloo
    },
    async created(){
        
        this.$store.commit("UpDateState",{
           epvPreNav:this.$route.fullPath
        })
        this.vinCode =  this.$route.params.vin

        // this.$route.params.groups
        this.vinCode = this.vinCode.replace(/\s/g,'').toUpperCase()

        await this.vinSearch()

        // 查询主组
        
        let res = await this.$api007.getMainGroupList({
            vin:this.vinCode,
            brandCode:this.brandCode,
            mcid:this.mcid
        })
        this.mainGroupList = res.data
        // 附件及其他调整位置
        this.mainGroupList.push(this.mainGroupList.shift())
        this.getBranch(this.mainGroupList[0])
        // let res = await this.$api007.getMainGroupList({
        //         vin:"LYVUFBAA4KB184478",
        //         brandCode:"volvos",
        //         mcid:"ZD18dns9JT89LConQC4pLSY9Yg%3D%3D",
        // })

        
        
        
        
        // this.$store.commit("UpDateState",{
        //     epcNavList:[{
        //         label:"结构树查询",
        //         route:`/catalog/tree/${this.$route.params.vin}/${this.tempNum}`
        //     }]
        // })
    },
    mounted(){
        let partContent = document.querySelector("#partContent")
        this.maxH = partContent.offsetHeight
       
    },
    components:{
        VinGoodsList,
        PartImg,
        VinForNameList
    },
    //  brandCode:{
            
    //     },
    //     mcid:{

    //     },
    props:{
       
        params:{

        }
    }
}
</script>

<style lang="scss">
.Vin-tree-content{
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .btn{
        cursor: pointer;
    }
    .vehicle-search{
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: $vin-blue;
        height: 50px;
       padding: 0 20px;
        color: #fff;
        .vehicle-info{
            display: flex;
            align-items: center;
             position: relative;
            .img-box{
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: 20px;
                img{
                    width: 20px;
                    height: 20px;
                }
            }
        
            .info{
                display: flex;
                align-items: center;
                .info-item{
                    position: relative;
                    padding: 0 15px;
                    text-align: center;
                    
                 
                    &::before{
                        position:absolute;
                        right: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        content: "";
                        width:1px;
                        height:8px;
                        background: #fff;
                    }
                }
               
            }
            .read-more{
                padding: 0 15px;
                cursor: pointer;
               
            }
            .car-msg-info{
                width: 420px;
                position: absolute;
                right: -430px;
                top: -5px;
                background: #F7FBFF;
                box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
                border-radius: 4px;
                z-index: 2;
                .msg-title{
                    background: #2767DD;
                    color: #fff;
                    text-align: center;
                    position: relative;
                    height: 40px;
                    line-height: 40px;
                    .close-btn{
                        cursor: pointer;
                        position: absolute;
                        right: 20px;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                }
                .msg-body{
                    padding: 10px 20px;
                    .car-msg-row{
                        font-size: 16px;
                        display: flex;
                        line-height: 26px;
                        .label{
                            color: #999999;
                        }
                        .value{
                            color: #666666;
                            padding-left: 10px;
                        }
                    }
                }
                
            }
        }
        .handle{
            display: flex;
            align-items: center;
           
            .el-input__inner{
                border-radius: 3px;
                height: 26px;
                width: 280px;
                // height: 1.060606rem /* 35/33 */;
                // width: 11.212121rem /* 370/33 */;
            }
            .btn{
                width: 60px;
                height: 26px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                color: #fff;
                border-radius: 3px;
                background: #198AFF;
            }
            .list-btn{
                border: 1px solid #d9d9d9;
                // width: 1.818182rem /* 60/33 */;
                // height: 1.060606rem /* 35/33 */;
                width: 60px ;
                height: 26px ;
                
                text-align: center;
                // line-height: 1.060606rem /* 35/33 */;
                line-height: 26px;
                cursor: pointer;
                margin-right: 10px;
                color: #1F3E82;
                background: #fff;
            }
            .accurate{
                width: 80px ;
                background: red;
                color: #fff;
            }
            .accurate:hover{
                width: 80px ;
                background: red;
                color: #fff;
            }
            .active{
                background: #254385;
                color: #fff;
                border: none;
                box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
            }
            .list-btn:hover{
                border: 1px solid #40a9ff;
                color: #40a9ff;
            }
        }
    }
   
    .part-content{
        display: flex;
        flex: 1;
        overflow: auto;
        
        .part-group{
            display: flex;
            height: 100%;
            box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.12);
            .main-list{
                width: 100px;
                
                overflow: auto;
                .main-item{
                    padding: .242424rem /* 8/33 */ .484848rem /* 16/33 */;
                   
                    cursor: pointer;
                    .img-box{
                        width: 40px;
                        height: 40px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        img{
                            width: 80%;
                            height: 80%;
                        }   
                    }
                    .tit{
                        font-size: 12px;
                        padding-top: 2px;
                        color: #666;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                    }
                }
                .active{
                    background: #DFDFDF;
                }
            }
            .branch-list{
                overflow: auto;
                width: 100px;
                border-left: 1px solid #DEDEDE;
                padding: 10px 0;
                
                .branch-item{
                    cursor: pointer;
                    padding: 10px;
                    font-size: 14px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                }
                .active{
                    border-left: 1px solid $vin-blue;
                    color: $vin-blue;
                }
            }
        }
        .part-list-area{
            // max-height: 0;
            flex:1;
            // padding: .606061rem /* 20/33 */;
            padding-left: .424242rem /* 14/33 */;
            padding-bottom: .424242rem /* 14/33 */;

            box-sizing: border-box;
            overflow: auto;
            .head{
                display: flex;
                justify-content: space-between;
                align-items: center;
                box-sizing: border-box;
                padding: 10px 0;
                padding-right: .606061rem;
                .left{
                    font-size: 14px;
                    color: #666;
                }
                .center-tit{
                    color: #FF7519;
                    font-size: 16px;

                }

                .right{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 160px;
                    height: 34px;
                    background: #FFFFFF;
                    border: 1px solid #EFEFF0;
                    border-radius: 4px;
                    box-sizing: border-box;
                    padding: 6px 14px;
                    .num{
                        background: #F5F5F5;
                        padding: 4px;
                        box-sizing: border-box;
                    }
                    .handle{
                        display: flex;
                        >div{
                            cursor: pointer;
                            padding: 0 6px;
                        }
                        .defalut{
                            color: #198AFF;
                            font-size: 12px;
                        }
                    }
                }
            }
            .list-content{
                .img-list{
                    display: flex;
                    flex-wrap: wrap;
                    .img-item-box{
                        padding-right: .606061rem;
                        box-sizing: border-box;   
                    }
                    .img-item{
                        border:1px solid #EEEEEE;
                        overflow: hidden;
                        // margin-right: .606061rem /* 20/33 */;
                        margin-bottom: .606061rem /* 20/33 */;
                        cursor: pointer;
                        position: relative;
                        text-align: center;
                        
                        img{
                          
                            height: 4.484848rem /* 148/33 */;
                        }
                        
                        .part-num{
                            height: 1.212121rem /* 40/33 */;
                            padding-left: 20px;
                            padding-right: 10px;
                            box-sizing: border-box;
                            line-height: 1.212121rem /* 40/33 */;
                            background: #EEEEEE;
                            overflow: hidden;
                        }
                        .name{
                            position: absolute;
                            top: 0;
                            width: 100%;
                            
                            text-align: left;
                            padding: 2px;
                            overflow: hidden;
                            background-color: rgba(31, 62, 130, .6);
                            color: #fff;
                            display: none;
                            >div{
                                padding: 2px 0;
                                font-size: 12px;
                            }
                        }
                        .dis-vis{
                            display: none;
                        }
                    }
                    .disabled{
                       .part-num{
                           color: $vin-blue;
                       }
                    }
                    .img-item:nth-child(7n){
                        margin-right: 0;
                    }
                    .img-item:hover{
                        box-shadow: 0 4px 8px rgba(0,0,0,.1);
                        .name{
                            display: block;
                        }
                    }
                }
                 // 列表形式
                .body-part-list{
                    flex: 1;
                    .li-head{
                        display: flex;
                        height: 32px;
                        line-height: 32px;
                        border: 1px solid #e8e8e8;
                        border-left: none;
                        border-right: none;
                        padding-left: 10px;
                        .fl1{
                            flex: 1;
                        }
                        
                        .img{
                            width: 80px;
                        }
                        .main{
                            width: 50px;
                        }
                        .group{
                            width: 80px;
                        }
                        .inum{
                            width: 120px;
                        }
                    }
                    .li-row{
                        
                        cursor: pointer;
                        display: flex;
                        padding: 5px 0;
                        padding-left: 10px;
                        border-bottom: 1px solid #ccc;
                        position: relative;
                        >div{
                            min-height: 1px;
                            word-break: break-all;
                            display: flex;
                            align-items: center;
                        }
                        .fl1{
                            flex: 1;
                            word-break:break-all;
                        }
                        .dis-vis{
                            display: none;
                        }
                        .img{
                            width: 80px;
                            display: flex;
                            align-items: center;
                            ::v-deep .big-img{
                                width: 400px;
                            }
                            .img-box{
                                width: 60px;
                                height: 60px;
                                border: 1px solid #eee;
                                img{
                                    width: 100%;
                                    height: 100%;
                                }
                            }
                        }
                        .main{
                            width: 50px;
                        }
                        .group{
                            width: 80px;
                        }
                        .inum{
                            width: 120px;
                        }
                    }

                    .li-row:hover{
                        background: #e6f7ff;
                    }
                    .disabled{
                       >div{
                           color: $vin-blue;
                       }
                    }

                }
            }
        }
    }

}
</style>