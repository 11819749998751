<template>
<div class="Epc-Vehicle">
    <div class="veh-body" >
        <div class="veh-item" v-for="(veh,index) in vehList" :key="index">
            <!-- @click="drawer=true" -->
            <div class="veh-title" >
                {{veh.codition_name}}
            </div>
            
            <div class="veh-list" :style="{'height':maxH+'px'}">
                <div class="body-option" :title="option.name" :class="{'active':veh.activeIndex==index1}"  v-for="(option,index1) in veh.list" :key="index1" @click="addNav(option,veh,index,index1)">
                    {{option.name}}
                </div>
            </div>
        </div>
    </div>

    <div class="specific-model" id="specific-model">
        <div class="title">
            选择具体车型
        </div>
        <div class="model-list" :style="{'height':modelH+'px'}">
            <div class="model-items" v-for="(carModel,index) in allModeList" :key="index" >
                <div class="spec-item" :title="carModel.models" @click="sele(carModel)">
                    {{carModel.models}}
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>

export default {
    data(){
        return{
            vehList:[],
            allModeList:[],
            carInfo:{},
            series_id:"",
            maxH:0,
            modelH:0
        }
    },
    methods:{ 
        //  vehList:this.vehList
        sele(item){
            
            let vehicle_full_name = ""
            if(item.models){
                vehicle_full_name = item.models
            }else{
                let names = this.vehList.map(veh=>{
                    if(veh.activeIndex&&veh.activeIndex!=-1){
                        
                        return veh.list[veh.activeIndex].name
                    }
                })
                names = names.filter(item=>item)
                vehicle_full_name =  names.join(",")
            }
           
            this.$emit("getMainGroup",{
                ...this.carInfo,
                ...item,
                brand_name:this.leveShow[0],
                vehicle_name:this.leveShow[2]||this.leveShow[1],
                vehicle_full_name
            })
            this.$api.addSearchRecord({
                record_name:vehicle_full_name,
                record_type:3,
                car_type:"",
                record_from:2
            })
            // 添加查询记录
            this.$api.addUseHis({
                use_model:"品牌查询",
                use_content:vehicle_full_name,
                is_ok:0,
                store_id:this.$store.state.store_id,
                store_name:this.$store.state.store_name,
                member_id:this.$store.state.member_id,
                member_name:this.$store.state.member_name,
            })
        },
        async addNav(option,veh,index,index1){

            
            
            this.vehList.forEach((item,veIndex)=>{
                if(veIndex>index){
                    item.list = []
                    item.activeIndex = -1
                }
            })
            
            if(index==0){
                let carses = await this.$apiScd.getCarseries({
                    manufacturer_id:this.manufacturer_id,
                    vin:"",
                    brand_id:option.brand_id
                })
                if(carses){
                    carses.forEach(item=>{
                        item.name = item.car_series_name
                        item.id = item.car_series_id
                    })
    
                }
                this.vehList[1].list = carses
                veh.activeIndex = index1
                this.carInfo = {
                    manufacturer_id:this.manufacturer_id,
                    brand_id:option.brand_id
                }
            }else if(index==1){
                
                
                let condition = ""
                veh.activeIndex = index1
                this.vehList.forEach(vehItem=>{
                    if(vehItem.list.length>0){
                        
                        condition += `@${vehItem.codition_id}${vehItem.list[vehItem.activeIndex].id}`
                    }
                })
                let models = await this.$apiScd.getModels({
                    manufacturer_id:this.manufacturer_id,
                    vin:"",
                    brand_id:this.carInfo.brand_id,
                    car_series_id:option.car_series_id,
                    condition
                })
                this.allModeList = models || []

                let list = await this.$apiScd.getSeriescondition({
                    manufacturer_id:this.manufacturer_id,
                    vin:"",
                    brand_id:this.carInfo.brand_id,
                    car_series_id:option.car_series_id,
                    codition_id:this.vehList[2].codition_id,
                    condition
                })
                this.carInfo.condition = condition
                if(list){
                    list.forEach(item=>{
                        
                        item.name = item.series_condition_name
                        item.id = item.series_condition_id
                    })
                }
                this.vehList[2].list = list

                
                this.carInfo.car_series_id = option.car_series_id
            }else{
                
                let condition = ""
                veh.activeIndex = index1
                let vehObj = this.vehList[index+1]
                this.vehList.forEach(vehItem=>{
                    if(vehItem.list.length>0){
                        condition += `@${vehItem.codition_id}${vehItem.list[vehItem.activeIndex].id}`
                    }
                })
                if(vehObj){
                    let list = await this.$apiScd.getSeriescondition({
                        manufacturer_id:this.manufacturer_id,
                        vin:"",
                        brand_id:this.carInfo.brand_id,
                        car_series_id:this.carInfo.car_series_id,
                        codition_id:vehObj.codition_id,
                        condition
                    })

                    let models = await this.$apiScd.getModels({
                        manufacturer_id:this.manufacturer_id,
                        vin:"",
                        brand_id:this.carInfo.brand_id,
                        car_series_id:this.carInfo.car_series_id,
                        condition
                    })
                    this.allModeList = models || []

                    this.carInfo.condition = condition
                    if(list){
                        list.forEach(item=>{
                            if(vehObj.codition_name.includes('年')){
                                item.name = item.series_condition_id
                            }else{
                                item.name = item.series_condition_name
                            }
                            item.id = item.series_condition_id
                        })
                    }else{
                        console.log("查询后续失败");
                        this.sele({
                            series_id:option.series_id
                        })
                    }
                    vehObj.list = list || []
                    
                    this.series_id = option.series_id
                    
                }else{

                    let vehInfo = this.allModeList.find(item=>item.models.includes(option.name))
                    console.log("vehInfo",vehInfo);
                    if(vehInfo){
                        
                        this.sele(vehInfo)
                    }else{
                        this.$warMsg("查询失败")
                    }
                    // this.sele(this.carInfo)
                }
                
            }

            
        },
        async init(){
            this.vehList = []
            this.allModeList = []
            this.carInfo = {}
            this.series_id = ""

            let titList = await this.$apiScd.getBrandTitle({
                manufacturer_id:this.manufacturer_id,
                vin:""
            })
            titList.forEach(tit=>{
                this.vehList.push({         
                    ...tit,
                    list:[]
                })
            })
            
            // 获取二级品牌
            let nextBrand = await this.$apiScd.getNextBrand({
                manufacturer_id:this.manufacturer_id,
                vin:""
            })

            nextBrand.forEach(item=>{
                item.name = item.brand_name
                item.id = item.brand_id
            })
            
            this.vehList[0].list = nextBrand
            // let drawEl = document.querySelector(".el-drawer__body")
            // this.maxH = drawEl.offsetHeight- 244 -25
            // let drawEl = document.querySelector(".el-drawer__body")

            // this.maxH = document.documentElement.clientHeight - 250 - 220

            // console.log("drawEl.clientHeight",drawEl.clientHeight);
            let drawEl = document.querySelector("#specific-model")
            this.maxH = document.documentElement.clientHeight - 250- drawEl.clientHeight
            this.modelH = drawEl.clientHeight - 24

            // window.onresize = ()=>{ 
                
            //     let drawEl = document.querySelector("#specific-model")
            //     this.maxH = document.documentElement.clientHeight - 250- drawEl.clientHeight
            //     this.modelH = drawEl.clientHeight-24
            // }
        }
    },
   
    created(){
    //    this.init()
    },
    computed:{
      
        leveShow(){
            let list = []
            this.vehList.forEach(veh=>{
                if(veh.activeIndex!=-1){
                    list.push(veh.list[veh.activeIndex].name)
                }
            })
            return list
        }
    },
    props:{
        // this.$route.params.manufacturerId
        manufacturer_id:""
    }
}
</script>

<style lang="scss">
.Epc-Vehicle{
    height: 100%;
    display: flex;
    flex-direction: column;
   
    .veh-body{
        flex: 1;
        background: #f5f5f5;
        display: flex;
       
        .veh-item{
            margin-right: 10px;
            background: #fff;
            flex: 1;
            .veh-title{
                height: 25px;
                line-height: 25px;
                padding-left: .606061rem /* 20/33 */;
                border: 1px solid #EEEEEE;
            }
            .veh-list{
                
                overflow:auto;
                .body-option{
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    padding: 5px;
                    // padding-left: .606061rem /* 20/33 */;
                    // height: 1.212121rem /* 40/33 */;
                    // height: 1.5rem;
                }
               
                .active{
                    // background: #3158C6!important;
                    background: #add8e6!important;
                    color: #fff;
                     
                }
                .body-option:hover{
                    background: #add8e6!important;
                    color: #fff; 
                    opacity: .9;
                }
            }
        }
        .veh-item:first-child{
            .veh-title{
                padding-left: 1.212121rem /* 40/33 */;
            }
            .veh-list{
                .body-option{
                    padding-left: 1.212121rem /* 40/33 */;
                }
                .body-option:nth-child(2n){
                    background: #F7F7F7;
                }
            }
        }
        .veh-item:last-child{
            margin-right: 0;
        }
    }
    .specific-model{
        background: #fff;
        // height: 324px;
        flex: 1;
        box-sizing: border-box;
        padding: 10px;
        .title{
            background: #f0f0f0;
            padding:5px 0;
        }
        .model-list{
            display: flex;
            flex-wrap: wrap;
           
            overflow: auto;
            // height: 300px;
            .model-items{
                // width: 25%;
                // padding: 5px;
                margin: 5px;
                box-sizing: border-box;
                .spec-item{
                    padding: 8px 4px;
                    width: 320px;
                    cursor: pointer;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    border: 1px solid #3158C6;
                    box-sizing: border-box;
                }
            }
        }
    }
}
</style>