<template>
<div class="re-part">
    <div class="order-state">
        <div class="order-state-item" :class="{'active':state===item.value}" v-for="(item,index) in  tabLabels" :key="index" @click="state=item.value">
            {{item.label}}
        </div>
    </div>


    <ParaSearch 
        api="getBranchAfitsList" 
        ref="ParaSearch"
        :prammsList="prammsList"
        :state="state"
        :searchData="searchData" 
        @searchEnd="searchEnd"
        :type="searchData.type"
    ></ParaSearch>

    <div class="order-list">
        <div class="batch-handle">
            <el-button size="mini" plain type="primary" @click="exportOrder">订单导出</el-button>
        </div>
        <div class="order-head table-head">

            <div class="order-goods">
                <div class="chec-box">
                    <el-checkbox v-model="allChoose" @change="allChooseChange"></el-checkbox>
                </div>
                <div class="w-80">OE号</div>
                <div class="w-80">生产编码</div>
                <div class="w-80">商品名称</div>
                <div class="w-80">品牌</div>
                <div class="w-60">单价</div>
                <div class="w-60">申请数量</div>
                <div class="w-60">赔付数量</div>
                
            </div>
            <div class="order-right">
                
                <div class="status">售后状态</div>
                <div class="deal-handle">操作</div>
            </div>
        </div>
        <div class="order-body">
            <div v-for="(order,index) in orderList" :key="index">
                <div class="order-row" >

                    <div class="order-msg">
                        <div class="chec-box">
                            <el-checkbox v-model="order.choose"></el-checkbox>
                        </div>
                        <div class="time">{{ $hnwTools.formatDateTime(order.create_time)}}</div>
                        <div>退货单号：{{order.retreatId}}</div>
                        <div>原订单号：{{order.order_id}}</div>
                        <div class="jdr">申请人：{{order.member_name}}</div>
                        <div>
                            安装状态：{{order.is_install==0?"安装前":"安装后"}}
                        </div>
                        <div>
                            处理方式：{{order.after_model}}
                        </div>
                        <div class="re-tip" v-if="$store.state.transpor_type!='同汽配城'&&!order.logistics&&order.state==3">
                            请上传物流凭证
                        </div>
                        <div class="re-tip" v-else-if="order.is_install==1&&$store.state.transpor_type!='同汽配城'&&!order.logistics&&order.state==1">
                            请上传物流凭证
                        </div>
                    </div>
                    <div class="list-container">
                        <div class="order-goods">
                            <div class="row" >
                                <div class="chec-box"></div>
                                <div class="w-80">{{order.oecode}}</div>
                                <div class="w-80">{{order.sccode}}</div>
                                <div class="w-80">{{order.goods_name}}</div>
                                <div class="w-80">{{order.oenaturebrand}}</div>
                                <div class="w-60">{{order.after_price}}</div>
                                <div class="w-60">{{order.after_number}}</div>
                                <div class="w-60" >
                                    <span v-if="order.state==5||order.state==7">
                                        {{order.trial_number||order.after_number}}
                                    </span>
                                    <span v-else>
                                        --
                                    </span>
                                </div>
                                
                            
                            </div>
                        </div>
                        <div class="order-right">
                            
                            <div class="status">
                                <span>
                                    {{order.state_text}}
                                </span>
                                
                            </div>
                            <div class="handle-btn">
                                <!-- &&!order.logistics&&!order.logistics -->
                             

                                <div class="re-order-info" v-if="order.is_install==1&&order.state==0" @click="handleOrder(order,'-2')">
                                    取消退单
                                </div> 
                                <div class="re-order-info" v-if="order.is_install==0&&order.state==1" @click="handleOrder(order,'-2')">
                                    取消退单
                                </div>

                                <div class="re-order-info" v-if="order.is_install==1&&order.state==5&&order.evidence" @click="handleOrder(order,8)">
                                    确认收货
                                </div>
                                <div class="re-order-info" v-if="$store.state.transpor_type!='同汽配城'&&order.state==3" @click="addLogsi(order)" >
                                    <span v-if="!order.logistics">
                                        添加物流凭证
                                    </span>
                                    <span v-else-if="order.logistics">
                                        修改物流凭证
                                    </span>
                                </div>
                                <div class="re-order-info" v-else-if="order.is_install==1&&$store.state.transpor_type!='同汽配城'&&order.state==1" @click="addLogsi(order)" >
                                    <span v-if="!order.logistics">
                                        添加物流凭证
                                    </span>
                                    <span v-else-if="order.logistics">
                                        修改物流凭证
                                    </span>
                                </div>
                                
                                <div class="re-order-info" @click="reOrderInfo(order)">订单详情</div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
    <div class="pagin-box">
        <el-pagination
        :current-page.sync="searchData.curpage"
        :page-size.sync="searchData.page"

        :page-sizes="[5, 10, 20, 50]"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        :hide-on-single-page="true"
        >
        </el-pagination>
    </div>
    <div class="uplog-box">
        <el-dialog
            :visible.sync="logisticsVis"
            center
            width="980px"
            title="上传物流图片"
            top="8vh"
            >
            <!-- :on-preview="redBigImg" -->
            <el-upload
                list-type="picture-card"
                :action="$axios.defaults.baseURL+'index.php?act=upload&op=image'"
                :on-success="handlePictureCardpic"
                
                :on-remove="handleRemove"
                :file-list="logisList"
                name="image"
                :data="{
                store_id:$store.state.store_id
                }"
                :headers="{
                token:$store.state.token
                }"
                multiple
                :limit="10" 
                >
                <i class="el-icon-plus"></i>
                
            </el-upload>
            <el-button size="small" type="primary" @click="upLogImg">确认上传</el-button>
        </el-dialog>
    </div>
</div>
</template>

<script>

import NovaSearch from "../../components/NovaSearch.vue"
         
export default {
    data(){
        return{
            
            tabLabels:[
                {
                    label:"所有订单",
                    value:""
                },
                {
                    label:"待审核",
                    value:"0,1,3"
                },
                
                {
                    label:"待赔付",
                    value:"5"
                },
                {
                    label:"已拒赔",
                    value:"2,4,6",
                },
                {
                    label:"已赔付",
                    value:"7,8"
                },
                {
                    label:"已取消",
                    value:"-2"
                }
            ],
            // {
            //         label:"赔付中",
            //         value:"",
            //     },
            rules:
            {
                ress_order:[
                    { required: true, message: '请填写物流单号', trigger: 'blur' }
                ],
                ress_code:[
                    { required: true, message: '请填写物流公司', trigger: 'blur' }
                ],
            },
            orderList:[],
            allChoose:false,
           
            dialogVisible:false,
            logisticsVis:false,
            canVisible:false,
            state:"",
            rePartList:[],
            searchData:{
                curpage:1,
                page:10,
                oecode:"",
                sccode:"",
                goods_name:"",
                oenaturebrand:"",
                oecarbrand:"",
                // 退货订单号
                retreatId:""
            },
            prammsList:[
        
                {
                    key: "oecode",
                    title: "OE号",
                },
                
                {
                    key: "sccode",
                    title: "生产编码",
                },
                {
                    key: "goods_name",
                    title: "配件名称",
                },
                
                {
                    key: "oecarbrand",
                    title: "配件品牌",
                },
                
                {
                    key: "retreatId",
                    title: "退货单号",
                }
            ],
            logisList:[],
            upImgData:{
                store_id:this.$store.state.store_id
            },
            reStateTab:[
                {
                  title:"已取消",
                  key:"create_time",
                  state:"-2"
                },
                {
                  title:"待审核",
                  key:"create_time",
                  state:"0"
                },
                {
                    title:"待审核",
                    key:"total_trial_time",
                    state:"1"
                },
                {
                    title:"已拒绝",
                    key:"total_trial_time",
                    state:"2"
                },
                {
                    title:"待审核",
                    key:"shop_trial_time",
                    state:"3"
                },
                {
                    title:"已拒绝",
                    key:"shop_trial_time",
                    state:"4"
                },
                {
                    title:"待赔付",
                    key:"final_trial_time",
                    state:"5"
                },
                {
                    title:"已拒绝",
                    key:"final_trial_time",
                    state:"6"
                },
                {
                    title:"赔付完成",
                    key:"finance_trial_time",
                    state:"7"
                },
                // 确认收货
                {
                    title:"赔付完成",
                    key:"finance_trial_time",
                    state:"8"
                }
            ],
            logisData:{

            },
            // 订单详情
            orderData:{},
          
            tempNum:1,
            dialogImageUrl:"",
            total_price:"0",
            total:1,
            imgs:[],
            orderInfo:{

            }
        }
    },
    methods:{
        
        handlePictureCardpic(res){
            if(res.code==0){
                this.imgs.push(res.data)
            // re.data.url = 
            // re.data.type=="image/webp"
            // re.data.type=="image/png"
            // re.data.type=="image/jpeg"
            // re.data.type=="video/mp4"
            }
        },
        async upLogImg(){
            // return console.log("this.imgs",this.imgs);
            let res = await this.$api.addAfitsLogi({
                id:this.orderInfo.id,
                logistics:JSON.stringify(this.imgs)
            })
            if(res){
                this.$suMsg("添加成功")
                this.$refs.ParaSearch.getList()
                this.logisticsVis = false
            }
        },
        handleRemove(file, fileList) {
            this.imgs = []
            fileList.forEach(element => {
            this.imgs.push(element.response.data)
            });
        },
        // 添加物流凭证
        addLogsi(order){
            this.logisticsVis = true
            this.orderInfo = order
            if(this.orderInfo.logistics){
                this.logisList = JSON.parse(this.orderInfo.logistics)
            }else{
                this.logisList = []
            }
        },
        reOrderInfo(order){
        
            localStorage.setItem("hnw-reorder-data",JSON.stringify(order))
            console.log("order",order);
            this.$router.push("/my/BranchReSales/"+order.retreatId)
        },
        handleOrder(order,state){
            let title = "确定要取消退单吗?"
            if(state==8){
                title = "确认要收货吗"
            }
            this.$affMsg(title,async ()=>{
                let res = await this.$api.commitRetreat({
                    models:"state",
                    id:order.id,
                    state
                })
                if(res){
                    this.$suMsg("取消成功")
                    this.$refs.ParaSearch.getList()
                }
            })
        },
        redBigImg(file){
            this.dialogImageUrl = file.url;
            this.dialogVisible  = true
        },
        
        searchEnd(res){
            
            this.orderList = res.data || [] 
            
            this.orderList = res.data || []
            this.orderList.forEach(order=>{

                this.$hnwTools.formatGoodsKey(order)
                // order.info.goods_price = order.info.sellPrice1
                let obj = this.reStateTab.find(item=>item.state==order.state)
                order.state_text = obj.title
            })
            this.total = Number(res.count)
            this.allChoose = false
        },
     
        
        
        
        exportOrder(){
            // .substr(0,this.$axios.defaults.baseURL.length-4)
            let str1 = this.$axios.defaults.baseURL
            let baseUrl =  str1 + "index.php?act=afitsale&op=reslut_list" + "&"  
            baseUrl += `store_id=${this.$store.state.store_id}&curpage=${this.searchData.curpage}&page=${this.searchData.page}&`
            
            let arr = this.orderList.filter(item=>{
                return item.choose
            })
            arr = arr.map(item=>item.retreatId)
            if(arr.length==0){
                return this.$warMsg("请选择导出的订单")
            }
            let str = arr.join(",")
            
            console.log("baseUrl",baseUrl +  "mode=" + str)
            location.href = baseUrl +  "mode=" + str 
        },

        
        // 删除订单
        orderDel(order_sn){
            this.$affMsg("确定删除该订单？",async ()=>{
                let res=  await this.$api.delOrder(order_sn)
                if(res){
                    this.$suMsg("删除成功")
                    this.$refs.ParaSearch.getList()
                }
            })
        },
        allChooseChange(flag){
            this.orderList.forEach(order=>{
                this.$set(order,"choose",flag)
                order.goods.forEach(goods=>[
                    // this.$set(goods,"choose",flag)
                ])
            })
        }
    },
    async created(){
       
        
    },
    watch:{
        tempNum(n){
            if(n==1){
                this.rePartList = []
               
                this.$nextTick(()=>{
                    this.$refs.ParaSearch.getList()
                })
            }
        },
        state(n){
            this.$nextTick(()=>{
                this.$refs.ParaSearch.getList()
            })
        }
    },

    computed:{
        reTotal(){
			let num = 0
			let price = 0
			this.rePartList.forEach(goods=>{
				num = this.$hnwTools.plus(num,goods.number)
				
				let temp = 0
				
				
				temp = this.$hnwTools.times(goods.goods_price,goods.number)
				price = this.$hnwTools.plus(price,temp)
				// price +=  *  
			})

			price = price.toFixed(2)
			return {
				num,
				price
			}

		}
    },
    mounted(){
        if(this.tempNum==1){

            this.$refs.ParaSearch.getList()
        }
    },
    components:{
        NovaSearch
    },
    props:{
        
    }
}
</script>

<style lang="scss">
.re-part{
    
    background: #fff;
    .table-head{
        background: $vin-blue;
        color: #fff;
        height: 38px;
        line-height: 38px;
        text-align: center;
        display: flex;
        padding: 0 5px;
        >div{
            flex: 1;
        }
    }
    .uplog-box{
        text-align: center;
        .el-dialog__body{
            text-align: center;
            button{
                margin-top: 10px;
            }
        }
    }
    .table-body{
        .part-row{
            display: flex;
            >div{
                flex: 1;
                height: 38px;
                line-height: 38px;
                text-align: center;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .btn{
                cursor: pointer;
            }
        }
        .repart-total{
            text-align: right;
            padding: 5px 10px;
        }
        .part-row:nth-child(2n){
            background: #eee;
        }
        .order-xs-msg{
            padding:  5px;
            border-top: 5px solid #eee;
            .order-sn-num{
                padding: 5px 0;
                display: flex;
                align-items: center;
                >div{
                    margin-right: 10px;
                }
                // justify-content: space-between;
            }
            
            .xsd{

            }
            .total{
                
                padding-bottom: 0;
                text-align: right;
            }
        }
        .table-type{
            padding: 5px 8px;
            font-weight: 700;
            display: flex;
            .total{
                color: red;
                padding-left: 5px;
            }
        }
    }
    .order-state{
        
        border-bottom: 2px solid #3E84D6;
        position: relative;
        display: flex;
        .order-state-item{
            color: #666;
            cursor: pointer;
           
            padding: 15px;
            text-align: center;
        }
       
        .active{
            background: #3E84D6;
            color: #fff;
        }
        .apply-btn{
            position: absolute;
            right: 5px;
            top: 50%;
            transform: translateY(-50%);
        }
        .cont{
            right: 100px;
        }
    }
    // 筛选跳过
    .sear_sreen{
    }

    .order-list{
        position: relative;
        .batch-handle{
            position: absolute;
            right: 10px;
            top: -38px;
        }
        .order-head{
            .w-60{
                max-width: 60px;
            }
            .order-goods{
                display: flex;
                
                flex: 1;
                .chec-box{
                    max-width: 15px;
                    min-width: 15px;
                }
                >div{
                    flex: 1;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                
                .w-60{
                    max-width: 60px;
                }
                .w-120{
                    min-width: 120px;
                }
            }
            .order-right{
                display: flex;
                justify-content: center;
                max-width: 260px;
                min-width: 260px;
                justify-content: space-evenly;
                align-items: center;
                >div{
                    flex: 1;
                }
            }
        }

        .order-body{
            background: $backGray;
            border: 5px solid #fff;
            .order-row{
                border-top: 5px solid #fff;
                .order-msg{
                    
                    display: flex;
                    align-items: center;
                    padding: 10px 5px;
                    font-size: 12px;
                    position: relative;
                    >div{
                        margin-right: 10px;
                    }
                    .time{
                        background: $vin-blue;
                        color: #fff;
                        text-align: center;
                        padding: 3px;
                    }
                    img{
                        margin-left: 10px;
                    }
                    .del-btn{
                        cursor: pointer;
                        position: absolute;
                        right: 10px;
                        top: 10px;
                    }
                    .jdr{
                        padding-left: 10px;
                        box-sizing: border-box;
                        max-width: 330px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                    .re-tip{
                        position: absolute;
                        right: 10px;
                        top: 50%;
                        transform: translateY(-50%);
                        color: red;
                    }
                }

                .list-container{
                    display: flex;
                    text-align: center;
                    border: 1px solid#ddd;
                    border-left: none;
                    border-right: none;
                    padding: 5px 0;
                    .order-goods{
                        flex: 1;
                        .row{
                            display: flex;
                            align-items: center;
                            padding: 10px 0;
                            height: 100%;
                            box-sizing: border-box;
                            // border-bottom: 1px solid #ccc;
                            .chec-box{
                                max-width: 15px;
                            }
                            >div{
                                flex: 1;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }
                            
                            .w-60{
                                max-width: 60px;
                            }
                            .w-120{
                                min-width: 120px;
                            }
                        }
                        .row:last-child{
                            border:none;
                        }
                    }
                    .order-right{
                        display: flex;
                        width: 260px;
                       
                        justify-content: space-evenly;
                        align-items: center;
                        >div{
                            flex: 1;
                        }
                        .handle-btn{
                            
                            >div{
                                cursor: pointer;
                                margin: 5px 0;
                            }
                        }
                        .logic{
                            color: #FF7519;
                            margin-bottom: 5px;
                        }
                        .re-order-info{
                            color: #3E84D6;
                        }
                    }
                }
            }
        }
    }
    .el-dialog{
        
        .re-part-table{
            
            .goods-rnumber{
                min-width: 110px;
                .el-input-number{
                    width: 100px;
                }
            }
        }
        .re-sear-pagi{
            text-align: right;
            padding: 10px 0;
        }
    }
    // 退款申请列表
    .re-nav{
        display: flex;
        height: 20px;
        line-height: 20px;
        padding: 8px 10px;
        border-bottom: 2px solid #3E84D6;
        .nav-all{
            color: #3E84D6;
            cursor: pointer;
            i{
                color: #3E84D6;
            }
        }
    }
    .re-head{
        display: flex;
        padding-right: 10px;
        justify-content: space-between;
        align-items:center;

    }
    .re-apply-body{
        padding-top: 10px;
        .apply-item{
            padding-bottom: 10px;
            padding-top: 5px;
            border-bottom: 5px solid #eee;
            .apply-title{
                border-left: 2px solid #3E84D6;
                padding:5px;
                margin-bottom: 5px;
                color: #3E84D6;
                .red{
                    color: red;
                }
            }
            .apply-from{
                padding: 0 15px;
                .flex-item{
                    display: flex;
                }
            }
            .appley-body{
                img{
                    width: 186px;
                    margin: 0 10px;
                    cursor: pointer;
                }
            }
            
        }
        .center{
            text-align: center;
            padding-top: 10px;
        }
        .apply-item:last-child{
            border: none;
        }
        .order-user-msg{
            height: 150px;
            display: flex;
            .user-msg-item{
                flex: 1;
                padding: 20px;
                padding-bottom: 0;
                border-right: 1px solid #ccc;
                .title{
                    padding-bottom: 10px;
                    color: $vin-blue;
                }
                .msg-body{
                    .msg-row{
                        margin-bottom: 7px;
                        >span{

                        }
                    }
                }
            }
            .user-msg-item:last-child{
                border: none;
            }
        }
        .order-info-msg{
            display: flex;
            height: 100px;
            box-sizing: border-box;
            
            >div{
                padding: 20px;
                box-sizing: border-box;
            }
            .msg-left{
                text-align: center;
                min-width: 255px;
                border-right: 1px solid #ccc;
                >div{

                }
                .re-order-state{
                    color: red;
                    font-size: 24px;
                    margin: 24px 0;
                }
                .btn{
                    width: 88px;
                    height: 24px;
                    line-height: 24px;
                    cursor: pointer;
                    border: 1px solid #CCCCCC;
                    margin: 0 auto;
                }
            }
            .msg-right{
                flex: 1;
            }
        }
    }
    .pagin-box{
        padding: 10px 0;
        text-align: right;
    }
    .el-dialog__footer{
        background: #fff;
    }
}
</style>