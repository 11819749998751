<template>
  <div class="select-main">
    <!-- <div class="select-left">
        <div class="top">
            车型选择
        </div>
        <div class="select-car">
            <div class="select-A">
                <div class="select-item" :id="item.item+'1'" v-for="(item,index) in EnglishBrand" :key="index" :class="{active:EngActive==item.item}" @click="modifyEnglish(item.item,$event)"><a @click="stopEvent" :href="'#'+item.item">{{item.item}}</a></div>
            </div>
            <div class="select-B" @scroll="scrollBrand">
                <div v-for="(brand,index) in EnglishBrand" :key="index" :id="brand.item" class="select-div">
                    <div class="select-B-item">{{brand.item}}</div>
                    <ul>
                        <li class="select-brand" v-for="(ite,ind) in brand.list" :key="ind" @click="addTab(ite)">{{ite}}</li> 
                    </ul>
                </div>
                <div class="space"></div>
            </div>
        </div> 
    </div> -->
    <div class="select-left">
        <div class="top">
            产品目录
        </div>
        <div class="productLog"> 
            <div class="productLog-item" @click="selectProduct('节气门')">
                <img src="../../../assets/elecatalog/节气门.png" alt="">
                节气门
            </div>  
            <div class="productLog-item" @click="selectProduct('火花塞')">
                <img src="../../../assets/elecatalog/火花塞.png" alt="">
                火花塞
            </div>  
            <div class="productLog-item" @click="selectProduct('高压泵')">
                <img src="../../../assets/elecatalog/高压泵.png" alt="">
                高压泵
            </div>  
            <div class="productLog-item" @click="selectProduct('电子油泵')">
                <img src="../../../assets/elecatalog/电子油泵.png" alt="">
                电子油泵
            </div>  
            <div class="productLog-item" @click="selectProduct('氧传感器')">
                <img src="../../../assets/elecatalog/氧传感器.png" alt="">
                氧传感器
            </div>  
            <div class="productLog-item" @click="selectProduct('曲轴位置传感器')">
                <img src="../../../assets/elecatalog/曲轴传感器.png" alt="">
                曲轴传感器
            </div>  
            <div class="productLog-item" @click="selectProduct('凸轮轴位置传感器')">
                <img src="../../../assets/elecatalog/曲轴传感器.png" alt="">
                凸轮轴传感器
            </div>  
            <div class="productLog-item" @click="selectProduct('空气流量计')">
                <img src="../../../assets/elecatalog/空气流量计.png" alt="">
                空气流量计
            </div>  
            <div class="productLog-item" @click="selectProduct('点火线圈')">
                <img src="../../../assets/elecatalog/点火线圈.png" alt="">
                点火线圈
            </div>  
            <div class="productLog-item" @click="selectProduct('喷油咀 ')">
                <img src="../../../assets/elecatalog/喷油咀.png" alt="">
                喷油咀 
            </div>  
        </div>
    </div>

    <div class="select-right">
        <!-- <div class="clear-search">
            <div class="clear">
                <input type="text" class="clear-input">
                 <div class="clear-button">清空</div>
            </div>
        </div> -->

        <div v-if="tempNum">
            <div class="hot-brand" v-if="TabArr.length==0">
                <!-- <div class="hot-brand-title">
                    <div class="text">新品推荐</div>
                </div> -->
                <!-- <div class="hot-brand-content">
                    <div class="hot-brant-item">
                        <div class="hot-brant-content">
                            <div><img src="../../../assets/brand/卢卡斯.png" alt=""></div>
                        
                            <div>卢卡斯</div>
                        </div>
                    </div>
                </div> -->
                <div class="hot-brand-content">
                    <div class="hot-image">
                        <img src="../../../assets/elecatalog/banner1.png" alt="">
                    </div>
                    <div class="hot-image">
                        <img src="../../../assets/elecatalog/banner2.png" alt="">
                    </div>
                </div> 

            </div>  
            <div class="hot-brand-two" v-else>
                <div class="hot-brand-title">
                    <div class="Tab">
                            <el-tag
                            v-for="(tag,index) in TabArr"
                            :key="tag"
                            closable
                            type=""
                            size="mini"
                            @close="closeTab(index)">
                            {{tag}}
                            </el-tag> 
                    </div>
                    <div class="text" @click="TabArr=[]">热门品牌
                        <img src="../../../assets/ybj/arrow_right.png" alt="">
                    </div>
                </div>
                <div class="hot-brand-content">
                    
                </div>
            </div> 
        </div> 
            
    </div> 
  </div>
</template>

<script>  


export default {
    
    data(){
        return{ 
            EngActive:'A',
            EnglishBrand:[
                {
                    item:"A",
                    list:['Adria','AITO','Alpina','ARCFOX','阿尔法-罗密欧','阿斯顿马丁','阿维塔','爱驰','安驰','奥迪']
                }, 
            ],
            TabArr:[],//标签小组
            productslog:["节气门","火花塞","高压泵","电子油泵","氧传感器","曲轴传感器","凸轮轴传感器","空气流量计"],
            currentPage:"1",
            pageSize:'15',
            tempNum:true,  
            tableDataPart:[],
            iamgesList:[],
        }
    },
    
    methods:{
        async selectProduct(item){   
            
            this.$emit("productPartNum",['6',item])
        },
        // 阻止默认行为
        stopEvent(event){ 
            event.preventDefault()
        },
        closeTab(index){
            this.TabArr.splice(index,1)
        },
        // 添加标签
        addTab(data){  
            this.TabArr.splice(0,1,data) 
        },
        // 点击左侧菜单栏跳转到相应的区域
        modifyEnglish(data,event){
            this.EngActive = data 
            let selectB = document.querySelector(".select-B")
            let brandDom = document.getElementById(data) 

            let toToParent = brandDom.offsetTop - selectB.offsetTop 
            if(toToParent!=0){ 
                selectB.scrollTop = toToParent
            }  
        },
        // 滚动时左边的菜单栏也滚动
        scrollBrand(){
            let selectB = document.querySelector(".select-B") 
            let toToParent = []
            let EngID = []
            for(let i = 0; i<selectB.children.length;i++){
                 toToParent[i] = selectB.children[i].offsetTop - selectB.offsetTop 
                 EngID[i] = selectB.children[i].id
                 
            }   
            for(let j = 0;j< toToParent.length;j++){
                if(selectB.scrollTop>=toToParent[j]&&selectB.scrollTop<toToParent[j+1]){
                    let DomItem = document.getElementById(EngID[j]+'1') 
                    DomItem.classList.add('active')
                    let selectDiv = document.querySelectorAll(".select-item") 
                    for(let k = 0;k<selectDiv.length;k++){
                        if(selectDiv[k].id!=DomItem.id){
                        selectDiv[k].classList.remove('active')
                        }
                    }
                }
            }
        }
    },
    
}
</script>
<style lang="scss" scoped> 
.select-main{
    display: flex; 
    .select-left{
        width: 200px; 
        
        .top{
            width: 100%;
            height: 50px;
            background: rgb(52,101,211);
            color: #fff;
            text-align: center;
            line-height: 50px;
            font-size: 18px;
            font-weight: bold;
        }
        // 产品目录
        .productLog{
            background: #fff;
            overflow: hidden;
            height: 700px;
            .productLog-item{
                height: 40px;
                line-height: 40px;
                text-align: center;
                margin: 10px;
                border-bottom: 1px solid #ccc;
                font-size: 16px; 
                color: #333;
                >img{
                    width: 26px;
                    height: auto;
                    vertical-align: sub;
                }
            }
        }
        .select-car{
            display: flex;
            .select-A{
                width: 10%;
                padding: 5px 10px;
                background: #fff;
                .select-item{
                    width: 10px;
                    height: 8px;
                    padding: 5px;
                    margin: 5px;
                    text-align: center; 
                    line-height: 8px;
                    border: 1px solid #ccc;
                }
                .active{
                    background: #054ed1;
                    a{

                        color: #fff;
                    }
                }
            }
            .select-B{
                flex: 1;
                border-left: 1px solid #ccc; 
                height: 665px;
                overflow: auto;
                .space{
                    width: 100%;
                    height: 300px;
                    background: #fff;
                }
                .select-B-item{
                    height: 36px;
                    font-size: 16px;
                    font-weight: bold;
                    line-height: 36px;
                    border-bottom: 1px solid #3e84d6;
                    padding-left: 10px;
                }
                .select-brand{
                    height: 30px; 
                    line-height: 30px;
                    border-bottom: 1px solid #cbced3;
                    padding-left: 10px;
                    background: #fff;
                }
            }
            ::-webkit-scrollbar{
                display: none;
            }
        }
    }
    

    .select-right{
        flex: 1;
        .clear-search{
            width: 870px;
            height: 70px;
            background: #fff;
            display: flex;
            margin-left: 29px;
            .clear{
                margin:21px auto;
                display: flex;
                .clear-input{
                width: 350px;
                height: 30px;
                // background: #ccc;
                outline: none;
                }
                .clear-button{
                    width: 70px;
                    height: 34px;
                    line-height: 34px;
                    text-align: center;
                    background: #3465d3;
                    color: #fff;
                    font-size: 14px;
                }
            }
            
        }
        // 产品目录
        .product-part{
            width: 870px; 
             
            margin-left: 29px;
            margin-top: 10px;
              
            padding-top: 11px;
        }
        .hot-brand{
            width: 870px;
            height: 30px;
             
            margin-left: 29px;
            // margin-top: 10px;
              
            // padding-top: 11px;
            .hot-brand-title{
                background: #c5d2f1;
                color: #054ed1;
                line-height: 20px;
                font-weight: bold;
                padding-left: 10px; 
                height: 20px;
                padding: 10px;
                display: flex;
                justify-content: space-between; 
                .text{ 
                    padding-left: 10px;
                    border-left: 2px solid #054ed1; 
                }
            }
            
            .hot-brand-content{
                width: 100%; 
                background: #fff;
                padding: 10px;
                box-sizing: border-box;
                display: flex;
                flex-wrap: wrap;
                .hot-image{
                    margin: 10px;
                    >img{
                        width: 100%;
                        height: auto;
                    }
                }
                .hot-brant-item{ 
                    height: 100px;
                    background: #eee;
                    display: flex; 
                    justify-content: center;
                    align-items: center;
                    border: 1px solid #ccc;
                    width: 162px;
                    .hot-brant-content{
                        display: flex;
                    }
                }
            }
        }
        .hot-brand-two{
            width: 870px;
            height: 30px;
             
            margin-left: 29px;
            margin-top: 10px;
              
            padding-top: 11px;
            .hot-brand-title{
                background: #c5d2f1;
                color: #054ed1;
                line-height: 20px;
                font-weight: bold;
                padding-left: 10px; 
                height: 20px;
                padding: 10px;
                display: flex;
                justify-content: space-between;
                .Tab{
                    border-left: 2px solid #054ed1;
                    padding-left: 10px; 
                    ::v-deep .el-tag{
                        margin-right: 10px;
                    }
                }
                .text{ 
                    padding-left: 10px;
                    >img{
                        vertical-align: middle;
                    }
                }
            }
            .hot-brand-content{
                width: 100%; 
                background: #fff;
                padding: 25px;
                box-sizing: border-box;
                display: flex;
                flex-wrap: wrap;
                .hot-brant-item{ 
                    height: 100px;
                    background: #eee;
                    display: flex; 
                    justify-content: center;
                    align-items: center;
                    border: 1px solid #ccc;
                    width: 162px;
                    .hot-brant-content{
                        display: flex;
                    }
                }
            }
        }
    }
}

</style>