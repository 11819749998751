import { render, staticRenderFns } from "./MoreUp.vue?vue&type=template&id=664fa23e&scoped=true"
import script from "./MoreUp.vue?vue&type=script&lang=js"
export * from "./MoreUp.vue?vue&type=script&lang=js"
import style0 from "./MoreUp.vue?vue&type=style&index=0&id=664fa23e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "664fa23e",
  null
  
)

export default component.exports