import { post } from "axios"
import store from '../../../store/index'

// 找件查询
const getFindList = async (data)=>{
    let code = ""
    if(store.state.shop==1780){
        code = store.state.is_sh=="0"?'丹阳仓':"上海仓"
    }
    let res = await post("index.php?act=web_index&op=sale_search",{
        ...data,
        code
    })
    return res
}

// 商家分页找件查询
const getStoreSearch = async (data)=>{
    let res = await post("index.php?act=web_index&op=storeSearch",{
        ...data
    })
    return res
}
// 批量搜索
const getSearches = async (data)=>{
    let res = await post("index.php?act=web_index&op=searches",{
        ...data
    })
    return res
}
// 获取所有平台配件权限商家
const getDisAllStore = async (data)=>{
    let res = await post("index.php?act=web_index&op=getStore",{
        ...data
    })
    return res
}

export {
    getStoreSearch,
    getFindList,
    getSearches,
    getDisAllStore
}