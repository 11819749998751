<template>
<div class="my-index">
   
    <div class="hnw-area-center my-body">
        <template v-if="tempNum==1">
            <ul class="my-menu">
                <li class="my-logo">
                    <div class="head-portrait">
                        <img :src="$store.state.member_avatar || require('../../assets/index/home/face.svg')" alt="">
                    </div>
                    <div class="logo-name">{{$store.state.company_name_jc}}</div>
                    <div class="logo-info">{{$store.state.nickname}} / {{$store.state.position}}</div>
                </li>
                <li style="background-color:#F7F7F7; padding: 5px 0;"></li>
               
                <li v-for="(menu,index) in $hnwTools.myMenuList" :key="index" :class="{'active':menu.path==$route.path}" @click="$router.push(menu.path)">
                    <span >
                        <img v-if="menu.path!=$route.path" :src="require(`../../assets/my/msg/${menu.img}.png`)" alt="">
                        <img v-else :src="require(`../../assets/my/msg/${menu.img1}.png`)" alt="">
                        {{menu.title}}
                    </span>
                </li>
            </ul>
            <div class="my-container">
                <!-- <keep-alive include="orderIndex">
                        <router-view/>
                </keep-alive> -->
                <router-view/>
            </div>
        </template>
        <template v-else-if="tempNum==2">
            <router-view/>
        </template>
    </div>

</div>
</template>

<script>

export default {
    data(){
        return{
            tempNum:1
        }
    },
    methods:{

    },
    created(){
        if(this.$store.state.for_branch&&this.$store.state.for_branch!=1780){
            // console.log("goods");
            this.$hnwTools.myMenuList.forEach( item=> {
                if(item.title=='退货/换货'){
                    item.path = "/my/reBranchPart"
                }

            })
        }
        // this.$hnwTools.myMenuList
    },
    watch:{
        "$route.path":{
            deep:true,
            immediate:true,
            handler(n){
                if(n.startsWith('/my/orderInfo')){
                    this.tempNum = 2
                }else if(n.startsWith('/my/ReSales')||n.startsWith('/my/BranchReSales/')){
                    this.tempNum = 2
                }else if(n.startsWith('/my/RefundInfo')){
                    this.tempNum = 2
                }
                else{
                    this.tempNum = 1
                }
            }
        }
    },
    props:{
        
    }
}
</script>

<style lang="scss">
.my-index{
    background: $backGray;
  
    .my-body{
        padding-top: 10px;
        display: flex;
        .my-menu{
            min-width: 148px;
            background: #fff;
            .my-logo{
                min-width: 148px;
                display: inline-block;
                height: 148px;
                padding: 0;
                .head-portrait{
                    width: 58px;
                    height: 58px;
                    border-radius: 58px;
                    overflow: hidden;
                    border: 2px solid #f7f7f7;
                    margin: 20px 0 0 42px;
                    >img{
                        width: 100%;
                        height: 100%;
                    }
                }
                .logo-name{
                    text-align: center;
                    color: #333333;
                    font-size: 16px;
                    margin: 10px 0 7px 0;
                }
                .logo-info{
                    text-align: center;
                    color: #999;
                    font-size: 14px;
                }
            }
            li{
                text-align: center;
                cursor: pointer;
                padding: 10px 0;
                    color: #666;
                span{
                    img{
                        width: 20px;
                        height: 20px;
                         vertical-align: middle;
                    }
                }
            }
            .active{
                // color: #3E84D6;
                color: #fff;
                background-color: #3E84D6;
            }
            .menu-title{
                cursor: default;
            }
        }
        .my-container{
            margin-left: 10px;
            flex: 1;
        }
    }
}
</style>