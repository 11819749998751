<template>
<div class="oe-search" >
    
    <div class="search-box">
        <div class="search">
            <el-autocomplete
                class="inline-input"
                size="mini"
                v-model="oecode"
                :fetch-suggestions="querySearch"
                value-key="record_name"
                placeholder="请输入配件名称"
                clearable
                :hide-loading="true"
            ></el-autocomplete>
            <div class="btn" @click="oeSearch(false)" v-loading="showLoding">
                查询
            </div>
        </div>
        <!-- 搜索历史 -->
        <div class="sear-his-list" >
            <div class="label">
                历史记录:
            </div>

            <div class="list">
                <div class="his-item" v-for="(his,index) in hisList.slice(0,5)" :key="index" @click="oeSearch(his.record_name)">
                    {{his.record_name}}
                    <div class="img-box"></div>
                </div>
            </div>
        </div>
        
    </div>
    <template v-if="tempNum==1">
        <div class="sear-result">
            <!-- <div class="total">
                <span class="title">
                    请确认想要查询结果
                </span>
                共 {{total}} 个结果
            </div> -->
            <div class="head-area">
                <div class="brand-list">
                    <!-- <div @click="getPartVeh('')" :class="{'fil-brand':filBrand==''}">全部</div> -->
                    <div v-for="(brand,index) in brandList" :key="index" @click="getPartVeh(brand.brandCode)" :class="{'fil-brand':filBrand==brand.brandCode}">
                        {{brand.label}}
                    </div>
                </div>
                <div class="total">
                    共计<span>{{resultList.length}}</span>组
                </div>
            </div>
            <div class="ult-table">
                <div class="ult-head">
                    <div class="sort-num">序号</div>
                    <div  v-if="isVague" class="brand">市场</div>
                    <div v-if="isVague" class="goods-veh-name">车型名称</div>
                    <div v-if="isVague" class="year">年份</div>
                    
                    <div class="oecode">配件号</div>
                    
                    <div class="goods-name">配件名称</div>
                    <div class="goods-name"  v-if="isVague">配件备注</div>
                    <!-- <div v-if="isVague" class="group-name">排量</div> -->
                    <!-- <div v-if="isVague" class="model">型号</div> -->
                    <div v-if="isVague" class="group-name">图组名</div>
                    <div v-if="isVague" class="img-g">图组</div>
                    <div v-if="isVague" class="img-i">图号</div>
                    
                    <div  class="handle">操作</div>
                    
                </div>
                <div class="ult-body" >
                    <div v-for="(re,index) in filterList" :key="index">
                        
                        <div class="ult-row" v-for="(part,index1) in re.partList" :key="index1" @click="readPartInfo(part,index1)" :id="index1" :class="{'active':part.active}">

                            <div class="sort-num">{{index1+1}}</div>
                            <div class="brand"  v-if="isVague">{{part.market}}</div>
                            <div v-if="isVague"  class="goods-veh-name">{{part.cars_model}}</div>
                            <div v-if="isVague" class="year">{{part.year}}</div>
                            <div class="oecode">
                                <template  v-for="(debris,index2) in part.pid" >
                                    
                                    <span :class="{'sign':debris==oecode}" :key="index2" v-if="debris">{{debris}}</span>
                                </template>
                            </div>
                           
                            
                            
                            <div class="goods-name">{{part.label}}</div>
                            <div class="goods-name"  v-if="isVague">
                                <span v-html="part.remark"></span>
                                <!-- {{part.remark}} -->
                            </div>
                         
                             <!-- <div v-if="isVague" class="group-name"></div> -->
                            <!-- <div v-if="isVague" class="model">{{part.model}}</div> -->
                            <div v-if="isVague" class="group-name">{{part.subgroup_label}}</div>
                            <div v-if="isVague" class="img-g">{{part.subgroup}}</div>
                            <div v-if="isVague" class="img-i">{{part.pnum}}</div>
                           
                            <div  class="handle">
                                <!-- <div class="btn" @click.stop="readInfo(part)">
                                    件
                                </div>
                                
                                <div class="btn" v-if="part.uri_param"  @click.stop="readVehInfo(part,re.brand)">
                                    图
                                </div> -->
                                <img @click.stop="readInfo(part)" src="../../assets/vin/pj.png" alt="">
                                <img class="bzt" v-if="part.uri_param"  @click.stop="readVehInfo(part,re.brand)" src="../../assets/vin/bzt.png" alt="">
                            </div>
                            
                        </div>
                        <div class="dia-big-box" v-show="partDraw">

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </template>
    <!-- @closed="draClosed" -->
    <el-drawer
        title="配件选择"
        :visible.sync="drawer"
        direction="btt"
        :withHeader="false"
        
        size="90%"
        >
        <div class="body-list">
            <div class="list-tit">
                为您找到相关结果约{{altPartList.length}}个
            </div>
            
            <div class="body-part-list">
                <!-- @click="readInfo(part.pid)" -->
                <div class="part-item" v-for="(part,index) in altPartList" :key="index" @click="toImgList(part)">
                    <div class="img-box">
                        <img :src="part.url" alt="">
                    </div>
                    <div class="item-right">

                        <div class="part-label">
                            <template  v-for="(debris,index2) in part.label">
                                <span :class="{'blue':debris==partsName}" :key="index2" v-if="debris">{{debris}}</span>
                            </template>
                        </div>
                        
                        <div class="part-params">

                            <div class="params-item">
                                <div class="label">
                                    零件号：
                                </div>
                                <div class="value">
                                    {{part.pid}}
                                    <!-- <template  v-for="(debris,index2) in part.pid">
                                        
                                        <span :class="{'blue':debris==partsName}" :key="index2" v-if="debris">{{debris}}</span>
                                    </template> -->
                                </div>
                            </div>

                            <div class="params-item">
                                <div class="label">
                                    主组：
                                </div>
                                <div class="value"> 
                                    {{part.maingroupname}}
                                </div>

                                <div class="label">
                                    分组：
                                </div>
                                <div class="value"> 
                                    {{part.mid}}
                                    {{part.struct_label}}
                                </div>
                            </div>

                            <div class="params-item">
                                <div class="label">
                                    型号：
                                </div>
                                <div class="value"> 
                                    {{part.model}}
                                </div>
                            </div>

                            <div class="params-item">
                                <div class="label">
                                    备注：
                                </div>
                                <div class="value"> 
                                    {{part.remark}}
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    </el-drawer>
    <el-drawer
        title="配件选择"
        :visible.sync="partDraw"
        direction="btt"
        :withHeader="false"
        :modal="false"
        size="300px"
        >
        <div class="part-info-box">
            <div class="part-info">
                <div class="part-info-title" >
                    <div v-for="mode in titles" :class="{'active-nav':activeModu==mode}" @click="moduCheck(mode)" :key="mode">{{mode}}</div>
                </div>
               
                <div class="msg-list"  @scroll="bodyScroll" :class="{'p0':p0}">
            
                    <div class="link-msg" v-if="activeModu=='替换件'">
                    
                        <div class="mode-body link-list" >
                            <!-- :class="{'posHead':headPos}" -->
                            <div class="list-head" :style="{top:headTop+'px'}" >
                                <div class="brand">品牌</div>
                                <div>零件号</div>
                               
                                <div class="label">零件名称</div>
                                <div class="num">件数</div>
                                <div class="remake">备注</div>
                                <div class="price">销售价</div>
                                
                            </div>
                        
                            <div class="list-body">
                                <div class="list-row" v-for="(replace,index) in replaceList" :key="index" >
                                    <div class="brand">{{replace.pid[0].brandcn}}</div>
                                    <div>{{replace.pid[0].pid}}</div>
                                   
                                    <div class="label">{{replace.pid[0].lable}}</div>
                                    <div class="num">{{replace.pid[0].num}}</div>
                                    <div>{{replace.pid[0].remark}}</div>
                                    <div class="price">{{replace.pid[0].prices}}</div>
                                   
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="price-msg"  v-else-if="activeModu=='渠道价格'">
                    
                        <div class="mode-body price-list">
                            <div class="list-head" :style="{top:headTop+'px'}">
                                <div>零件类型</div>
                                <div>厂商</div>
                                <div>说明</div>
                                <div>进价(未含税)</div>
                                <div>进价(含税)</div>
                                <div>销售价(未含税)</div>
                                <div>销售价(含税)</div>
                                <div>服务商</div>
                            </div>
                            <div class="list-body">
                                <div class="list-row" v-for="(priceItem,index) in priceList" :key="index">
                                    <div>{{priceItem.parttype}}</div>
                                    <div>{{priceItem.mill}}</div>
                                    <div>{{priceItem.remark}}</div>
                                    <div>{{priceItem.eot_price}}</div>
                                    <div>{{priceItem.cost_price}}</div>
                                    <div>{{priceItem.sale_price1}}</div>
                                    <div>{{priceItem.sale_price}}</div>
                                    <div>{{priceItem.supplier}}</div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="veh-msg" v-else-if="activeModu=='适用车型'">
                        
                        <div class="mode-body veh-list">
                            <div class="list-head" :style="{top:headTop+'px'}">
                                <div>品牌</div>
                                <div>车型</div>
                                <div>年份</div>
                                <div>市场</div>
                             
                                <div class="handle" >操作</div>            
                            </div>

                            <div class="list-body">
                                <div class="list-row" v-for="(veh,index) in vehList" :key="index">
                                    <div>{{veh.brandname}}</div>
                                    <div>{{veh.cars_model}}</div>
                                    <div>{{veh.year}}</div>
                                    <div>{{veh.market}}</div>
                                
                                    <div class="handle" >
                                        
                                        <span class="btn" @click="readVehInfo(veh)">查看</span>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>

                </div>
            </div>

        </div>
    </el-drawer>
</div>
</template>

<script>
export default {
    data(){
        return{
            tempNum:1,
            total:0,
            resultList:[],
            oecode:"",
            filBrand:"",
            hisList:[],
            brandList:[{
                label:"全部",
                brandCode:""
            }],
            showLoding:false,
            // 是精准查找还是模糊 默认模糊
            isVague:false,
            altPartList:[],
            drawer:false,
            partDraw:false,
            activeModu:"替换件",
            titles:["替换件","渠道价格","适用车型"],
            vehInfo:{},
            infoOecode:"",
            brandCode:"",
            priceList:[],
            replaceList:[],
            vehList:[],
            headTop:0,
            p0:false,
        }
    },
    methods:{
       
        // wqface1543
        async oeSearch(record_name){

            this.isVague = false
            if(record_name){
                this.oecode = record_name
            }
            this.filBrand = ""
            this.oecode = this.oecode.replace(/\s/g,'').toUpperCase()
            if(this.oecode.length<5){
                this.resultList = []
                
                return this.$warMsg("零件号长度不够5位")
            }
            
            location.href = location.href.split("#")[0]+`#/catalog/oesearch/${this.oecode}/1`
            
            this.$store.commit("UpDateState",{
                epcNavList:[{
                    label:"零件号查询",
                    route:`/catalog/oesearch/${this.oecode}/1`
                }]
            })
            
            // 查询是否扣费

            let feeRes = await this.$api.getFeeSite(3)
            let ins_ok = 1 

            if(feeRes.ins_ok==0){
                ins_ok = 0
            }else if(feeRes.ins_repeat_ok==0){

                let searTime = await this.$api.getSearTime({
                    record_name:this.oecode,
                    store_id:this.$store.state.store_id
                })
                if(Array.isArray(searTime)){
                    console.log("未找搜索记录")
                }else{
                    
                    if(Number(feeRes.ins_date)==0){
                        ins_ok = 0
                        // console.log("永久不扣费")
                    }else{
                        // 当前时间是
                        let nowTime = new Date().getTime()/1000
                        // 扣费周期
                        
                        let feeDate = Number(feeRes.ins_date)*60*60*24 
    
                        if(nowTime - searTime.add_time > feeDate){
                            ins_ok = 1
                        }else{
                            ins_ok = 0
                        }
                        
                    }
                }
                
            }
            // 添加查询记录
            let res1 = await this.$api.addSearchRecord({
                record_name:this.oecode,
                record_type:3,
                car_type:"",
                record_from:3
            })
            console.log(res1)

            this.$api.addUseHis({
                use_model:"oe号查询",
                use_content:this.oecode,
                is_ok:ins_ok,
                store_id:this.$store.state.store_id,
                store_name:this.$store.state.store_name,
                member_id:this.$store.state.member_id,
                member_name:this.$store.state.member_name,
            })
            if(ins_ok==1){
                this.$store.dispatch("getVinNum")
            }
            // 通过oe号查找
            let res = await this.$api007.getPartByOe({
                pid:this.oecode
            })
           
            let tempBrand = {}
            let tempArr = []
            
            this.resultList= []
            let allPart = []
            // 查找车型
            if(!Array.isArray(res.data)){
                return this.$warMsg("未找到配件")
            }

            if(res.data.length==1){
                this.isVague = true
                
                console.log("精准搜索");
            }

            res.data.forEach(item=>{

                tempArr.push(item.pid)

             
                item.pid = item.pid.replace(this.oecode,`%${this.oecode}%`)
                item.pid = item.pid.split("%")
                // allPart.push({
                //     label:item.label,
                //     pid:item.pid,
                //     price:part.price
                // })
                item.brand_list.forEach(part=>{

                    tempBrand = this.resultList.find(resitem=>resitem.brand==part.name)
                    console.log("tempBrand",tempBrand);
                    // 如果没有找到这个品牌就添加
                    if(!tempBrand){
                        
                        this.resultList.push({
                            brand:part.name,
                            brandCode:part.brandCode,
                            
                            partList:[
                               {
                                label:item.label,
                                pid:item.pid,
                                price:part.price
                               }
                            ]
                            
                        })

                        
                        // 如果找到，存到临时列表
                    }else{
                        
                        tempBrand.partList.push({
                            label:item.label,
                            pid:item.pid,
                            price:part.price
                        })

                        
                    }
                    

                })
                if(!this.isVague){
                    allPart.push({
                        label:item.label,
                        pid:item.pid,
                        price:"" //拿不到价格
                    })

                }
            })
            this.resultList.unshift({
                brand:"全部",
                brandCode:"",            
                partList:allPart
            })
            
            this.total = 0
            this.brandList = [
              
            ]
            this.resultList.forEach(item=>{
                this.total += item.partList.length
                this.brandList.push({
                    label:item.brand,
                    brandCode:item.brandCode
                })
            })
            // console.log("this.brandList",);
            // 更新历史记录列表
            this.hisList = await this.$api.getSearchRecord({
                store_id:this.$store.state.store_id,
                record_type:3,
                record_from:3
            })
            
            if(this.isVague){
                // 如果oe号是精准的
                this.getPartVehData()
            }else{
                let index = 1
                this.resultList.forEach(brand=>{
                    brand.partList.forEach(part=>{
                        part.pIndex = index
                        index++
                    })
                })
            }

        },
        // 获取车型等信息
        async getPartVehData(){
            
            let index = 1
            // 找到需要更新的数组
            
            let upBrand =  this.resultList.find(item=>item.brandCode==this.filBrand)
            if(upBrand.partList==0){
                
                let tempRes = await this.$api007.getPartVeh({
                    part:this.oecode,
                    filter_brand:this.filBrand
                })
         
                if(Array.isArray(tempRes.data)&&tempRes.data.length>0){
                    this.showLoding = true
                    let tempList = []
                    tempRes.data.forEach(item1=>{
                        

                        let sPid = item1.uri_param.p.replace(this.oecode,`%${this.oecode}%`)
                        sPid = sPid.split("%")
                        
                        tempList.push({
                            cars_model:item1.cars_model,
                            year:item1.year,
                            pid:sPid,
                            market:item1.market,
                           
                            // cars_model:item1.cars_model.replace(/<br\/>/g," "),
                           
                            uri_param:item1.uri_param,
                            pIndex:index
                        })

                        index++
                    })
                    let partReqList = []
                    tempRes.data.forEach(item1=>{
                        let p = new Promise(async resolve=>{
                            let res = await this.$api007.vinSearchByName({
                                brandCode:item1.uri_param.brandCode,
                                mcid:item1.uri_param.mcid,
                                search_key:item1.uri_param.p
                            })
                            resolve(res)
                        })
                        partReqList.push(p)
                    })
                    let collectRes = await Promise.all(partReqList)
                    
                    tempList = tempList.map((temp,index)=>{
                        // label:partInfo.label.replace(/<br\/>/g," "),
                        // remark:partInfo.remark.replace(/<br\/>/g," "),
                        // model:partInfo.model.replace(/<br\/>/g," "),
                        // subgroup_label:partInfo.subgroup_label.replace(/<br\/>/g," "),
                        // subgroup:partInfo.subgroup.replace(/<br\/>/g," "),
                        if(collectRes[index].data[0]){
                            let partInfo = collectRes[index].data[0]
                            // .replace(/<br\/>/g," ")
                            temp.label = partInfo.label
                            temp = {
                                ...temp,
                                label:partInfo.label,
                                remark:partInfo.remark,
                                model:partInfo.model,
                                subgroup_label:partInfo.subgroup_label,
                                subgroup:partInfo.subgroup,
                                pnum:partInfo.pnum,
                            }
                        }else{
                           
                        }
                        return temp
                    })
                    
                    upBrand.partList = tempList
                    this.showLoding = false
                }
            }else{
                console.log("此品牌已有数据");
            }
        },
        
        async querySearch(str,cb){
            if(!this.vinCode){
                let res =  await this.$api.getSearchRecord({
                    store_id:this.$store.state.store_id,
                    record_type:3,
                    record_from:3
                })
                cb(res)
            }else{
                cb([])
            }
        },
        readInfo(part){
            let brandCode = ""
            if(this.isVague){
                brandCode = part.uri_param.brandCode
            }else{
                try{
                    brandCode = this.filBrand?this.filBrand:this.brandList[1].brandCode
                }catch(e){
                    console.log("获取品牌出错!",e);
                }
            }
            
            let oecode = part.pid.join("")
            this.$router.push("/catalog/partinfo/"+oecode+"/"+brandCode+'/part')
            this.$store.commit("navPush",{
                label:`${part.label}详情`,
                route:"/catalog/partinfo/"+oecode+"/"+brandCode+'/part'
            })
            // let link = location.href.split("#")[0]
            // console.log("link",link)

            // window.open(link + "#/catalog/partinfo/"+oecode+'/part'+"/"+brandCode)
        },
        moduCheck(mode){
            this.activeModu = mode
            
        },
        bodyScroll(e){
            // console.log("log",e.target.scrollTop);
            // if(e.target.scrollTop<10){
            //     this.headTop = e.target.scrollTop
                
            // }else{
            //     this.headTop = e.target.scrollTop+10
            // }
            this.p0 =  e.target.scrollTop>10
            if(this.p0){
                this.headTop = e.target.scrollTop -10
            }else{
                this.headTop = e.target.scrollTop
            }
        },
        async readPartInfo(part,index){
            this.resultList.forEach(item=>{
                item.partList.forEach(part1=>{
                    part1.active = false
                })
            })
            
            this.$set(part,'active',true)

            if(this.isVague){
                this.brandCode = part.uri_param.brandCode
            }else{
                try{
                   this.brandCode = this.filBrand?this.filBrand:this.brandList[1].brandCode
                }catch(e){
                    console.log("获取品牌出错!",e);
                }
            }
            
            // this.brandCode = part.uri_param.brandCode

            this.infoOecode = part.uri_param?part.uri_param.p:part.pid.join("")
            
            // 获取替换件
            await this.getPartReplace()
            
            this.activeModu = "替换件" 
            this.partDraw = true
            
            this.$nextTick(async ()=>{

                if(index>5){
                    // 为了让选中得行能出现在遮罩层上方
                    if(index>this.filterList[0].partList.length-7){
                        // console.log("到底了");
                        document.getElementById(index).scrollIntoView()
                    }else{
                        document.getElementById(index-5).scrollIntoView()
                    }

                }
                // 获取价格
                await this.getPrice()
                // 获取适配车型
                await this.getParInfotVeh()
            })
        },
        
        async getPrice(){
            let res = await this.$api007.getPartPrice({
                pid:this.infoOecode,
                brandCode:this.brandCode
            })
             
            this.priceList = res.data
            if(Array.isArray(this.priceList)){
                this.priceList.forEach(item=>{
    
                    item.sale_price1 = this.$hnwTools.div(item.sale_price,1.13).toFixed(2)
                })
            }

        },
        async getPartReplace(){
             let res = await this.$api007.getPartReplace({
                pid:this.infoOecode,
                brandCode:this.brandCode
            })
            
            this.replaceList = res.data
           
            
            let pid_list = []
            // 获取对应商品能否购买
           
            if(Array.isArray(this.replaceList)){
                pid_list = this.replaceList.map(item=>item.pid[0].pid)
                // 相同平台归类
                const classifyDataBySame = (initData)=>{
                    let result = []
                    // 缓存 
                    let cache = {}
                    // 下标二维数组，把有相同属性的元素的下标位置，记录到同一个下标数组 
                    let indexs = [ ]

                    initData.forEach((item,index)=>{
                        let brancn = item.pid[0].brandcn
                        let brandIndex = cache[brancn]
                        if(brandIndex||brandIndex==0){
                            indexs[brandIndex].push(index)
                        }else{
                            indexs.push([index])
                            cache[brancn] = indexs.length - 1
                        }
                    })
                    indexs.forEach(item=>{
                        item.forEach(index=>{
                            result.push(initData[index])
                        })
                    })
                    
                    return result
                }
                classifyDataBySame(this.replaceList)
                this.replaceList = classifyDataBySame(this.replaceList)   
            }
            
            if(pid_list.length>0){
                let gysList = await this.$api.getGysNum({
                    oeList:pid_list,
                    for_branch:this.$store.state.for_branch,
                    shop:this.$store.state.shop
                })
                
                gysList.forEach(item=>{
                    if(item.data.length>0){
                        this.replaceList.forEach(part=>{
                            if(part.pid[0].pid==item.oecode){
                                part.gysNum = item.data.length
                                part.storeList = item.data
                            }
                        }) 
                        
                    }
                    
                })

            }

        },
        async getParInfotVeh(){
            // brandCode:this.brandCode,
            let res = await this.$api007.getPartVeh({
                part:this.infoOecode,
            })
           
            this.vehList = res.data
        },
        toImgList(part){
            
            this.$router.push(`/catalog/epcimglist/${part.brandCode}/${part.mcid.replace(/\//g,'xiegang')}/veh/${part.num}/${part.mid}/${part.subgroup}/${part.name.replace(/\//g,'xiegang')}`)

            this.$store.commit("navPush",{
                // label:`${this.vehInfo.brand}${this.vehInfo.cars_model}${this.vehInfo.year}${this.vehInfo.market}`,
                label:`${this.oecode}查询列表`,
                route:`/catalog/oesearch/${this.oecode}/1}`
            })
        },
        async readVehInfo(veh,brand){
            this.vehInfo = {
                brand,
                cars_model:veh.cars_model,
                cars_model:veh.year,
                cars_model:veh.market,
            }
            let res = await this.$api007.vinSearchByName({

                brandCode:veh.uri_param.brandCode,
                mcid:veh.uri_param.mcid,
                search_key:veh.uri_param.p
            })
            
            if(res.data.length==1){

                this.toImgList(res.data[0])
            }else{
                this.altPartList = res.data
                this.drawer = true
            }  
        },
        
        async getPartVeh(filterBrand){
            this.filBrand = filterBrand
            if(this.isVague){
                
                this.getPartVehData()
            }
        }
    },
    async created(){
        
        this.oecode = this.$route.params.oecode
        
        this.oeSearch(false)
        
        // let res = await this.$api007.getPartVeh({
        //     part:'06L115401M',
        //     filterBrand:""
        // })
        // console.log("res",res)
    },
    watch:{
        "$route.params.num":{
            handler(n){
                this.tempNum = this.$route.params.num
            },
            immediate:true,
            deep:true
        }
    },
    computed:{
        
        filterList(){
            return this.resultList.filter(item=>item.brandCode==this.filBrand)
           
        }
    },
   
    props:{
        
    }
}
</script>

<style lang="scss">
.oe-search{
    flex: 1;
    display: flex;
    flex-direction: column;

    .search-box{
        display: flex;
        // padding: 10px 0;
        height: 45px;
        min-height: 45px;
        box-sizing: border-box;
        padding: 5px 0;
        background: #F7F7F7;
        .search{
            display: flex;
            align-items: center;
            justify-content: center;
            // padding-left: 3.030303rem /* 100/33 */;
            padding-left: 20px;
            // flex: 1;
            margin-right: 1.212121rem /* 40/33 */;
            .el-input__inner{
                border-radius: 0;
                width: 9.090909rem /* 300/33 */;
                
                height: 35px;
            }
            .btn{
                width: 80px;
                // height: 40px;
                height: 35px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                color: #fff;
                background: #198AFF;
            }
        }
        .sear-his-list{
            display: flex;
            align-items: center;
            flex: 1;
            // padding-left: 20px;
            .label{
                margin-right: 10px;
            }
            .list{
                display: flex;
                .his-item{

                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    color: #aaa;
                    .img-box{
                        width: 20px;
                        height: 20px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        overflow: hidden;
                        img{
                            width: 40px;
                        }
                    }
                }
            }
        }
    }
    .sear-result{
        padding: 10px 20px;
        .dia-big-box{
            height:300px;
        }
        .total{
            display: flex;
            align-items: center;
            padding: 10px 0;
            .title{
                color: #3E84D6;
                margin-right: 5px;
            }
        }
        .head-area{
            display: flex;
            justify-content: space-between;
            border: 1px solid #EDEDED;
            border-radius: 4px;
            padding: 0 10px;
            .brand-list{
                display: flex;
                
                >div{
                    cursor: pointer;
                    margin: 0 2px;
                    min-width: 30px;
                    height: 20px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-right: 6px;
                    padding: 5px 0;
                }
                
                .fil-brand{
                    
                    border-bottom: 2px solid $vin-blue;
                }
            }
            .total{
                color: #999999;
                span{
                    color: #333;
                }
            }
        }
        .ult-table{
            .ult-head{
                display: flex;
                align-items: center;
                background: #FAFAFA;
                height: 40px;
                >div{
                    flex: 1;
                    text-align: left;
                    color: $vin-blue;
                    box-sizing: border-box;
                    padding-left: 10px;
                    border-right: 1px solid #ccc;
                }
                .sort-num{
                    min-width: 50px;
                    max-width: 50px;
                }
                .handle{
                    min-width: 80px;
                    max-width: 80px;
                    text-align: center;
                }
                .group-name{
                    // max-width: 100px;
                    min-width: 100px;
                }
                .oecode{
                    max-width: 160px;
                    padding-left: 15px;
                    text-align: left;
                }
                .brand{
                    max-width: 80px;
                }
                .year{
                    max-width: 100px;
                    padding: 0 10px;
                }
                .goods-veh-name{
                    // max-width: 100px;
                    min-width: 100px;
                }
                .goods-name{
                    // max-width: 170px;
                    min-width: 170px;
                }
                
                .img-g{
                    max-width: 60px;
                }
                .img-i{
                    max-width: 60px;
                }
                .model{
                    max-width: 80px;
                    min-width: 80px;
                }
            }
            .ult-body{
                .ult-row{
                    display: flex;
                    align-items: center;
                    // min-height: 20px;
                    min-height: 18px;
                    padding: 5px 0;
                    border-bottom: 1px solid #EEEEEE;
                    .sign{
                        color: #3E84D6;
                    }
                    >div{
                        flex: 1;
                        
                        min-height: 2px;
                        padding-left: 10px;
                        line-height: 18px;
                        box-sizing: border-box;
                        word-break:break-all;
                        .btn{
                            // border:1px solid #3E84D6;
                            color: #FF7519;
                            cursor: pointer;
                            // width: 80px;
                            // height: 30px;
                            padding: 4px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            box-sizing: border-box;
                            border: 1px solid #FF7519;
                            border-radius: 50%;
                            
                        }
                        
                    }
                    .sort-num{
                        min-width: 50px;
                        max-width: 50px;
                    }
                    .handle{
                        max-width: 80px;
                        min-width: 80px;
                        display: flex;
                        justify-content: space-evenly;
                        .shu{
                            width: 6px;
                        }
                        img{
                            width: 18px;
                            height: 20px;
                            // height: 16px;
                            cursor: pointer;
                        }
                        img:last-child{
                            width: 20px;
                        }
                        .bzt{
                            width: 20px;
                        }
                    }
                    .group-name{
                        // max-width: 100px;
                        min-width: 100px;
                        // line-height: 16px;
                    }
                    .brand{
                        max-width: 80px;
                    }
                    .oecode{
                        padding-left: 15px;
                        max-width: 160px;
                        font-weight: 700;
                    }
                    .year{
                        max-width: 100px;
                        padding: 0 10px;
                    }
                    .model{
                        max-width: 80px;
                        min-width: 80px;
                    }
                    .goods-name{
                        // max-width: 170px;
                        min-width: 170px;
                    }
                    .goods-veh-name{
                        // max-width: 100px;
                        min-width: 100px;
                    }
                    .img-g{
                        max-width: 60px;
                    }
                    .img-i{
                        max-width: 60px;
                    }
                }
                .active{
                    border: 2px solid #1890ff !important;
                }
            }
           
        }
    }
    
    .body-list{
        flex: 1;
        .list-tit{
            color: rgba(0,0,0,.45);
            height: 30px;
            color: rgba(0,0,0,.45);
            padding: 5px 10px;
            display: flex;
            align-items: center;
        }
        .body-part-list{
            .part-item{
                display: flex;
                padding: 10px;
                cursor: pointer;
                border-bottom: 1px solid #eee;
                .img-box{
                    width: 150px;
                    height: 150px;
                    img{
                        width: 140px;
                        height: 140px;
                        border: 1px solid rgba(0,0,0,.1);
                    }
                }
                .item-right{
                    flex: 1;
                    padding-left: 10px;
                    .part-label{
                        font-size: 16px;
                        font-weight: 700;
                        margin-top: 10px;
                        margin-bottom: 10px;
                        .blue{
                            color: #1890ff;
                        }
                    }
                    .part-params{
                        .params-item{
                            display: flex;
                            height: 20px;
                            line-height: 20px;
                            .label{
                                color: rgba(0,0,0,.45);
                            }
                            .value{
                                margin-right: 10px;
                                .blue{
                                    color: #1890ff;
                                }
                            }
                        }
                    }
                }
            }
            .part-item:hover {
                box-shadow: 0 2px 12px 0 rgba(0,0,0,.15);
            }
        }
    }
    .part-info-box{
        max-height: 300px;
        
        .part-info{
            // height: 100px;
            .part-info-title{
                display: flex;
                background: #3E84D6;
                color: #fff;
                
                >div{
                    height: 22px;
                    padding: .242424rem /* 8/33 */ .30303rem /* 10/33 */;
                    padding-left: 30px;
                    cursor: pointer;
                    line-height: 22px;
                    text-align: center;
                }
                .active-nav{
                    background: #fff;
                    
                    color: #333;
                }
            }
            .msg-list{
                max-height: 272px;
                overflow: auto;
                padding: 10px 30px;
                
                >div{
                    background: #fff;
                    margin-top: 10px;
                    overflow: hidden;
                }
                >div:first-child{
                    margin: 0;
                }
                .mode-body{
                    position: relative;
                    border: 1px solid #ccc;
                    overflow: hidden;
                    margin-top: 10px;
                    .list-head{
                        display: flex;
                        height: 30px;
                        align-items: center;
                        background: #fafafa;

                        text-align: center;
                        border-bottom: 1px solid rgb(220, 234, 248);
                        // position: absolute;
                        width: 100%;
                        top: 0;
                        left: 0;
                        >div{
                            flex: 1;
                            color: #3E84D6;
                            text-align: start;
                        }
                        >div:first-child{
                            text-align: left;
                            padding-left: 20px;
                        }
                        .remake{
                            text-align: center;
                        }
                        .brand{
                            max-width: 120px;
                        }
                        .price{
                            max-width: 120px;
                        }
                        .num{
                            max-width: 40px;
                        }
                    }
                    
                    .list-body{
                        // max-height: 242px;
                        // overflow: auto;
                        .list-row{
                            display: flex;
                            align-items: center;
                            min-height: 30px;
                            // border-bottom: 1px solid #ccc;
                            border-bottom: 1px solid #eee;
                            text-align: center;
                            padding: .242424rem /* 8/33 */ 0;
                            >div{
                                flex: 1;
                                line-height: 16px;
                                text-align: start;
                            }
                            >div:first-child{
                                text-align: left;
                                padding-left: 20px;
                            }
                            .brand{
                                max-width: 120px;
                            }
                            .price{
                                max-width: 120px;
                            }
                            .num{
                                max-width: 40px;
                            }
                        }
                        .list-row:last-child{
                            border:none;
                        }
                    }
                }
                
                .link-list{
                    .label{
                        padding: 0 5px;
                        text-align: left;
                    }
                }
                .btn{
                    cursor: pointer;
                    color: $vin-blue;
                }
                .part-msg{
                    display: flex;
                    .base{
                        flex: 1;
                        border-right: 10px solid #f7f7f7;
                        min-height: 138px;
                
                        .handle{
                            display: flex;
                            justify-content: flex-end;
                            padding-right: 1.636364rem /* 54/33 */;
                            .btn{
                                width: 120px;
                                height: 30px;
                                background: #3E84D6;
                                color: #fff;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                i{
                                    margin-right: 5px;
                                    font-size: 18px;
                                }
                            }
                        }
                    }
                    .big-img{
                        // height: 138px;
                        display: flex;
                        align-items: center;
                        img{
                            // height: 100%;
                            height: 138px;
                            cursor: pointer;
                        }
                    }
                }
                .store-msg{
                    .store-list{
                        .list-head{
                            .handle{

                            }
                        }
                        .list-body{
                            .handle{
                            .btn{

                            } 
                            }
                        }
                    }
                    .more-store{
                        display: flex;
                        justify-content: center;
                        padding: 10px 0;
                        .more-btn{
                            width: 124px;
                            height: 30px;
                            border: 1px solid #3E84D6;
                            color: #3E84D6;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            border-radius: 15px;
                            cursor: pointer;
                            span{

                            }
                        }
                    }
                }
                .price-msg{
                    .price-list{

                    }
                }
                .link-msg{
                    .handle{
                        max-width: 50px;
                        min-width: 50px;
                        color: #3E84D6;
                        cursor: pointer;
                        text-align: left;
                    }
                    .btn{
                        color: #fff;
                        margin-left: 10px;
                        background: #3E84D6;
                        width: 84px;
                        height: 20px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        
                    }
                    .sort-handle{
                        display: flex;
                        align-items: center;
                        background: #f7f7f7;
                        margin-top: 0;
                        >div{

                        }
                        .sele{
                            margin-left: 20px;
                        }
                    }
                }
                .veh-msg{
                    .brand{
                        display: flex;
                        align-items: center;
                        padding-bottom: 5px;
                        .label{
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            padding-left: 10px;
                            margin-right: 10px;
                        }
                        .brand-list{
                            display: flex;
                            >div{
                                cursor: pointer;
                                margin: 0 2px;
                                min-width: 46px;
                                height: 20px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                            }
                        
                            .fil-brand{
                                background: #254385;
                                color: #fff;
                            }
                        }
                        .handle{
                            span{

                            }
                        }
                    }
                }
            }
            .p0{
                padding-top: 0;
                .list-head{
                    box-shadow: rgba(0,0,0,.2) 1px 1px 10px;
                    position: absolute;
                }
            }
        }
    }
}
</style>