<template>
  <div class="resales">
    <div class="re-sale-box">
      <div class="re-left">
        <div class="order-info-msg">
          <div class="order-title">
            订单摘要
          </div>
          <div class="order-msg">
            <template  v-if="isSubmit">

              <div class="order-row">
                <div class="order-label">订单编号:</div>
                <div class="order-value">
                  {{orderData.order_sn}}
                </div>
              </div>
              
              <div class="order-row" >
                <div class="order-label">下单时间:</div>
                <div class="order-value">
                  {{ $hnwTools.formatDateTime(orderData.add_time) }}
                </div>
              </div>
              
              <div class="order-row" >
                <div class="order-label">售后时间:</div>
                <div class="order-value">
                
                  {{ $hnwTools.formatDateTime(new Date().getTime()/1000)}}
                </div>
              </div>
             
              <div class="order-row">
                <div class="order-label">订单状态 :</div>
                <div class="order-value">
                  {{$hnwTools.getOrderStateText(orderData.order_state)}}
                </div>
              </div>
              

              <div class="order-row">
                <div class="order-label">结算方式 :</div>
                <div class="order-value">
                  {{orderData.set_type}}
                </div>
              </div>
              <div class="order-row">
                <div class="order-label">运费结算方式 :</div>
                <div class="order-value">
                  客户到付
                </div>
              </div>
            </template>
            <template v-else>
              <div class="order-row">
                <div class="order-label">原单号:</div>
                <div class="order-value">
                  {{orderData.order_id}}
                </div>
              </div>
              <div class="order-row">
                <div class="order-label">退货单号:</div>
                <div class="order-value">
                  {{orderData.retreatId}}
                </div>
              </div>
              <div class="order-row" >
                <div class="order-label">售后状态:</div>
                <div class="order-value">
                  {{orderData.state_text}}
                </div>
              </div>
              <div class="order-row" >
                <div class="order-label">售后时间:</div>
                <div class="order-value">
                  {{ $hnwTools.formatDateTime(orderData.create_time) }}
                </div>
              </div>
              <div class="order-row" >
                <div class="order-label">卖方留言:</div>
                <div class="order-value">
                  {{ orderData.desc }}
                </div>
              </div>
              <div class="order-row" >
                <div class="order-label">申请人:</div>
                <div class="order-value">
                  {{ orderData.member_name }}
                </div>
              </div>

            </template>
          </div>
        </div>
        <div class="order-info-msg">
          <div class="order-title">
              商品摘要
          </div>
          <div class="order-msg">
              <div class="order-row">
                <div class="order-label">商品名称:</div>
                <div class="order-value">
                  {{orderGoods.goods_name}}
                </div>
              </div>
              <div class="order-row">
                <div class="order-label">oe号:</div>
                <div class="order-value">
                   {{orderGoods.oecode}}
                </div>
              </div>
              <div class="order-row">
                <div class="order-label">生产编码:</div>
                <div class="order-value">
                   {{orderGoods.sccode}}
                </div>
              </div>
              <div class="order-row">
                <div class="order-label">品牌:</div>
                <div class="order-value">
                   {{orderGoods.oenaturebrand}}
                </div>
              </div>
              <div class="order-row">
                <div class="order-label">商品价格:</div>
                <div class="order-value">
                   {{orderGoods.after_price}}
                </div>
              </div>

              <div class="order-row" v-if="isSubmit">
                <div class="order-label">购买数量:</div>
                <div class="order-value">
                   {{orderGoods.goods_num}}
                </div>
              </div>
              
              <div class="order-row" v-if="isSubmit||canSubmit">
                <div class="order-label">可用售后数量:</div>
                <div class="order-value" :class="{'red':reNum==0}">
                   {{reNum}}
                </div>
              </div>

          </div>
        </div>
        <div class="order-info-msg">
          <div class="order-title">
              退货摘要
          </div>
          <div class="order-msg">
              <div class="order-row">
                <div class="order-label">退货联系人:</div>
                <div class="order-value">
                  
                  {{$store.state.shopInfo.shop_contactname}}
                  <!-- {{$store.state.shopInfo.shop_name}} -->
                </div>
              </div>
              <div class="order-row">
                <div class="order-label">退货地址:</div>
                <div class="order-value">
                   {{$store.state.shopInfo.shop_area_info}}
                </div>
              </div>
              
              <div class="order-row">
                <div class="order-label">退货电话:</div>
                <div class="order-value">
                   {{$store.state.shopInfo.shop_contactphone}}
                </div>
              </div>
              
              <div class="order-row">
                <div class="order-label">退货运费:</div>
                <div class="order-value">
                  运费由客户先行垫资，待总部收货无异后退还相应运费
                </div>
              </div>

          </div>
        </div>

      </div>
      <div class="re-right">
        <div class="app-body">
          <el-form ref="form" :model="reOrderInfo" label-width="120px" size="mini" :rules="rules" :disabled="!canSubmit">
            <el-form-item label="安装情况" prop="is_install" >
              <el-radio-group v-model="reOrderInfo.is_install" :disabled="Boolean(orderGoods.retreatId)" @change="inChange">
                <el-radio  label="0">安装前</el-radio>
                <el-radio  label="1">安装后</el-radio>
              </el-radio-group>
            </el-form-item>
            
            <el-form-item label="售后类型" prop="after_type" >
              <el-select v-model="reOrderInfo.after_type" placeholder="请选择" :disabled="Boolean(orderGoods.retreatId)">
                 <el-option v-for="(option,index) in afSales" :value="option" :key="index" :label="option">
                    {{ option }}
                 </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="售后处理方式" prop="after_model">
              <el-select v-model="reOrderInfo.after_model" placeholder="请选择" :disabled="Boolean(orderGoods.retreatId)">
                 <el-option v-for="(option,index) in afSaleHandle" :value="option" :key="index" :label="option">
                    {{ option }}
                 </el-option>
              </el-select>

            </el-form-item>
            <!-- 
              <el-form-item label="联系人" prop="contacts_name">
                <el-input v-model="reOrderInfo.contacts_name"></el-input>
              </el-form-item>
              <el-form-item label="联系电话" prop="contacts_iphone">
                <el-input v-model="reOrderInfo.contacts_iphone"></el-input>
              </el-form-item> 
            -->
            <el-form-item label="退/换/补货数量" prop="after_number" >
              <el-input-number size="mini" v-model="reOrderInfo.after_number"  :min="1" :max="reNum"></el-input-number>
            </el-form-item>
            <template v-if="reOrderInfo.is_install=='1'">
              <el-form-item label="安装车型/排量" prop="after_vehicle">
                <el-input v-model="reOrderInfo.after_vehicle" placeholder="例 ：凯越1.6/帕萨特1.8" ></el-input>
              </el-form-item>
              <el-form-item label="安装日期" prop="vehicle_time">
                
                <el-date-picker type="date" value-format="yyyy-MM-dd" placeholder="选择日期" v-model="reOrderInfo.vehicle_time" style="width: 100%;"></el-date-picker>
              </el-form-item>
              <el-form-item label="产品使用里程" prop="use_mileage">
                <el-input v-model="reOrderInfo.use_mileage">
                  <template slot="append">公里/KM</template>
                </el-input>
              </el-form-item>
            </template>

            <el-form-item label="问题描述" prop="describe">
              <el-input type="textarea" v-model="reOrderInfo.describe"></el-input>
            </el-form-item>

            <el-form-item label="上传退货凭证" prop="card"  v-if="canSubmit">
              <el-upload
                  list-type="picture-card"
                  :action="$axios.defaults.baseURL+'index.php?act=upload&op=image'"
                  :on-success="handlePictureCardpic"
                  :on-preview="redBigImg"
                  :on-remove="handleRemove"
                  :file-list="cardList"
                  name="image"
                  :data="{
                  store_id:$store.state.store_id
                  }"
                  :headers="{
                    token:$store.state.token
                  }"
                  multiple
                  :limit="10" 
                  >
                  <i class="el-icon-plus"></i>
              </el-upload>
            </el-form-item>

            <template  v-if="!isSubmit&&!canSubmit">
              
              <el-form-item label="买家退货凭证">
                 <div class="img-list">
                    <img v-for="(img,index) in reOrderInfo.card" :src="img.url" @click="redBigImg(img)" :key="index" alt="">
                 </div>
              </el-form-item>
              
              <template v-if="reOrderInfo.logistics">
                <el-form-item label="卖家取货凭证" v-if="$store.state.transpor_type=='同汽配城'">
                  <div class="img-list">
                      <img v-for="(img,index) in reOrderInfo.logistics" :src="img.url" @click="redBigImg(img)" :key="index" alt="">
                  </div>
                </el-form-item>
                <el-form-item label="买家物流凭证" v-else>
                  <div class="img-list">
                      <img v-for="(img,index) in reOrderInfo.logistics" :src="img.url" @click="redBigImg(img)" :key="index" alt="">
                  </div>
                </el-form-item>
              </template>
            
              <el-form-item label="退货遣返凭证" v-if="reOrderInfo.seller">
                 <div class="img-list">
                    <img v-for="(img,index) in reOrderInfo.seller" :src="img.url" @click="redBigImg(img)" :key="index" alt="">
                 </div>
              </el-form-item>
              <el-form-item label="退款凭证" v-if="reOrderInfo.voucher">
                 <div class="img-list">
                    <img v-for="(img,index) in reOrderInfo.voucher" :src="img.url" @click="redBigImg(img)" :key="index" alt="">
                 </div>
              </el-form-item>

            </template>
          </el-form>
          <div class="sub-row">
            <el-button type="primary" size="small" @click="$router.go(-1)">返回</el-button>
            <el-button type="primary" size="small" @click="submit('')"  v-if="isSubmit&&canSubmit">提交申请</el-button>
            <el-button type="primary" size="small" @click="submit('edit')"  v-else-if="canSubmit">提交修改</el-button>
          </div>
          
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="dialogVisible">
        <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
  </div>
</template>

<script>
export default {
data() {
      return {
        orderData:{
          order_info:{},
          store_info:{}
        },
        orderGoods:{},
        buyer_message:"",
        reason_id:"",
       
        dialogVisible:false,
        dialogImageUrl:"",
        
        reOrderInfo:{
          is_install:"0",
          shop_id:"",
          buyer_id:"",
          member_id:"",
          order_id:"",
          goods_id:"",
          after_type:"",
          after_model:"",
          
          after_number:"1",
          after_vehicle:"",
          use_mileage:"",
          describe:"",
          card:"",
          vehicle_time:"",
          create_time:"",
          state:""
        },
        cardList:[],
        imgs:[],
        showImgs:[],
        rules:{
        },
        reNum:99999999,
        isSubmit:true,
        canSubmit:true,
      }
    },
    methods: {
      submit(models){

        this.$refs.form.validate(async (valid) => {
          if (valid) {
            if(this.imgs.length>0){
              this.reOrderInfo.card = JSON.stringify(this.imgs)
            }else{
              this.reOrderInfo.card = ""
            }
            let params = {
              ...this.reOrderInfo,
              shop_id:this.$store.state.for_branch,
              buyer_id:this.$store.state.store_id,
              buyer_name:this.$store.state.store_name,
              member_name:this.$store.state.member_name,
              member_id:this.$store.state.member_id,
              order_id:this.orderData.order_sn,
              goods_id:this.orderGoods.goods_id
            }
            if(models){
              params.models = models
            }
            console.log("params",params)
            let res = await this.$api.commitRetreat(params)
            if(res){
              this.$suMsg('提交成功')
              
              this.$router.go(-1)
            }else{
              this.$erMsg("提交失败")
            }
          }else{    
              return this.$erMsg("请填写必填字段!")
          }
        })

        
      },
      handleRemove(file, fileList) {
        this.imgs = []
        fileList.forEach(element => {
          this.imgs.push(element.response.data)
        });
      },
      redBigImg(file){
        
          this.dialogImageUrl = file.url;
          this.dialogVisible  = true
      },
     
      // beforeRemove(file, fileList) {
        
      //   // return this.$confirm(`确定移除 ${ file.name }？`);
      // },
      handlePictureCardpic(res){
        if(res.code==0){
          this.imgs.push(res.data)
          this.reOrderInfo.card  = JSON.stringify(this.imgs)
          // re.data.url = 
          // re.data.type=="image/webp"
          // re.data.type=="image/png"
          // re.data.type=="image/jpeg"
          // re.data.type=="video/mp4"
        }
      },
      inChange(){
        this.reOrderInfo.reType = ""
        this.reOrderInfo.handle_way = ""
      }
    },
    
    async created(){
      let orderGoods = localStorage.getItem("hnw-reorder-data")
      this.orderGoods = JSON.parse(orderGoods)
      // 如果是退货订单
      if(this.orderGoods.retreatId){
        this.orderGoods = {
          ...this.orderGoods,
          describe:this.orderGoods.text_desc
        }
        this.orderData = this.orderGoods
        this.reOrderInfo = this.orderGoods
        this.isSubmit = false
        if(this.orderData.is_install==1&&this.orderData.state==0){
          let gnRes = await this.$api.getGoodsRenum({
            order_id:this.orderData.order_id,
            goods_id:this.orderGoods.goods_id
          })
          this.reNum = this.orderGoods.goods_num  - Number(gnRes) + Number(this.reOrderInfo.after_number)
        }else if(this.orderData.is_install==0&&this.orderData.state==1){
          let gnRes = await this.$api.getGoodsRenum({
            order_id:this.orderData.order_id,
            goods_id:this.orderGoods.goods_id
          })
          this.reNum = this.orderGoods.goods_num  - Number(gnRes) + Number(this.reOrderInfo.after_number)
        }else{
          this.canSubmit = false
        }
        if(this.orderData.card){
          this.orderData.card = JSON.parse(this.orderData.card)
          this.cardList = this.orderData.card
        }
        if(this.orderData.logistics){
          this.orderData.logistics = JSON.parse(this.orderData.logistics)
        }
        if(this.orderData.seller){
          this.orderData.seller = JSON.parse(this.orderData.seller)
        }
        if(this.orderData.voucher){
          this.orderData.voucher = JSON.parse(this.orderData.voucher)
        }
        
      }else{
        // contacts_name:"联系人",
        // contacts_iphone:"联系电话",

        let explain = {
          is_install:"安装情况",
          after_type:"售后类型",
          after_model:"处理方式",
          after_number:"退货数量",
          after_vehicle:"安装车型",
          use_mileage:"使用里程",
          describe:"备注描述",
          card:"上传凭证",
          vehicle_time:"安装时间"
        }
        
        for(let key in this.reOrderInfo){
          this.rules[key] = { required: true, message: `请输入${explain[key]}`, trigger: 'blur' }
        }
        
        this.orderData =  await this.$api.getOrderInfo({
            id:this.$route.params.id
        })
         
        this.orderData = this.orderData.order_info
        let gnRes = await this.$api.getGoodsRenum({
          order_id:this.orderData.order_sn,
          goods_id:this.orderGoods.goods_id
        })
        this.reNum = this.orderGoods.goods_num  - Number(gnRes)
        if(this.reNum==0){
          this.canSubmit = false
          this.$erMsg("可用赔付数量不足!!")
        }
      }
     
    },
    computed:{
      afSales(){

        if(this.reOrderInfo.is_install=='0'){
          return ["货不对板","少发","品质差异"]
        }else{
          return ["质量问题","其他"]
        }
      },
      afSaleHandle(){
        
        if(this.reOrderInfo.is_install=='1'){
          return ["换货"]
        }else{
          return ["退货退款"]
        }
      },

    }
}
</script>

<style lang="scss" scoped>
.resales{
  width: 100%;
  .re-sale-box{
    display: flex;
    justify-content: space-between;
    .re-left{
      background: #fff;
      padding: 10px;
      // max-width: 300px;
      .order-info-msg{
        margin-bottom: 10px;
        .order-title{
          line-height: 16px;
          font-size: 16px;
          margin: 10px 0;
          font-weight: 700;
          padding-left: 10px;
          border-left: 2px solid #f00;
        }
        .order-msg{
          .order-row{
            display: flex;
            padding: 5px 0;
            >div{
              color: #666;
            }
            .order-label{
              padding-right: 2px;
            }
            .order-value{

            }
            .red{
              font-size: 16px;
              color: red;
            }
          }
        }
      }
    }
    .re-right{
      background: #fff;
      flex: 1;
      margin-left: 10px;
      .app-body{
        padding: 10px 20px;
        .img-list{
          img{
            width: 100px;
            cursor: pointer;
          }
        }
      }
    }
  }
  .sub-row{
    text-align: center;
  }
}
</style>