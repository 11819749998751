<template>
<div class="vin-pur-cart">
    <div class="pur-cart-list">
        <el-form :model="searData"   ref="purFrom" size="mini" :inline="true" class="search-form">
            <el-form-item label="计划人">
                <!-- <el-select v-model="searData.member_name" class="jhr-se" size="mini">
                    <el-option :label="brand.member_name" :value="brand.member_name"  :key="index" v-for="(brand,index) in urserList">
                    </el-option>
                </el-select> -->
                <el-input  class="sear-input" v-model="searData.member_name" ></el-input>
            </el-form-item>
            <el-form-item label="供应商" >
                <el-input  class="sear-input" v-model="searData.supplier" ></el-input>
            </el-form-item>
            <el-form-item label="编码">
                <el-input v-model.number="searData.goods_oecode" class="sear-input"></el-input>
            </el-form-item>
            <el-form-item label="名称">
                <el-input v-model.number="searData.goods_name" class="sear-input"></el-input>
            </el-form-item>
            <el-form-item label="车型">
                <el-input v-model.number="searData.models" class="sear-input"></el-input>
            </el-form-item>

            <el-form-item label="日期">
                <el-select v-model="searData.date"  class="rq-se"  size="mini">
                    <el-option label="近1日" value="day"></el-option>
                    <el-option label="近7日" value="week"></el-option>
                    <el-option label="近15日" value="week2"></el-option>
                    
                </el-select>
            </el-form-item>
            <el-form-item label="排序">
                <el-select v-model="searData.sort_name"  class="px-se"  size="mini">
                    <el-option label="时间" value="time"></el-option>
                    <el-option label="车架号" value="data"></el-option>
                    <el-option label="供应商" value="supplier"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item>
                <el-button type="primary" @click="getList('sear')">查询</el-button>
                <el-button @click="resetFields">清除</el-button>
            </el-form-item>
        </el-form>
        <div class="list-content">
            <div class="ult-table">
                <div class="ult-head">
                    
                    <div class="check">
                        <el-checkbox v-model="allChecked" @change="allChange"></el-checkbox>
                    </div>
                    <div class="index-num">
                        #
                    </div>
                    <div v-for="tit in purPartAttrs" :key="tit.key" :class="tit.key" >
                        {{tit.title}}
                    </div>
                    
                </div>
                <div class="ult-body"  :style="{'height':partlistH+'px'}" v-loading="resultLoading">
                    
                    <div class="ult-row" v-for="(part,index1) in resultList"  :class="{'active':partActiveIndex==index1}" :key="index1" @click="rowClick(part,index1)">   
                        <div class="check">
                            <el-checkbox v-model="part.choose"></el-checkbox>
                        </div>
                        <div class="index-num">
                            {{index1+1}}
                        </div>
                        <div  v-for="tit in purPartAttrs" :class="tit.key" :key="tit.key" >
                            {{part[tit.key]}}
                        </div>    
                    </div>

                </div>
                <div class="draw-replace">
                </div>
            </div>
            <!-- 采购单显示 -->
            <div class="control-center">
                <div class="list-pagin">
                    
                    <el-pagination
                        background
                        @size-change="getList('')"
                        @current-change="getList('')"
                        :current-page.sync="currentPage"
                        :page-sizes="[ 10,20,30,50,100,200,300,500]"
                        :page-size.sync="pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="Math.ceil(total)">
                    </el-pagination>
                </div>
                <div class="jhd-btns">
                    <div class="btn" @click="hisJhd">
                        最近计划单
                    </div>
                    <div class="btn" @click="handlePart('copy')">
                        复制
                    </div>
                    <div class="btn" @click="handlePart('edit')">
                        修改
                    </div>
                    <div class="btn" @click="delPart">
                        删除
                    </div>
                    <div class="btn" @click="exportPart">
                        导出
                    </div>
                    <div class="btn" @click="createJhdbf">
                        生成采购计划单
                    </div>
                </div>
            </div>
            <div class="pur-list-page" v-show="showPurListPage">
                <div class="pur-title">
                    <div class="main-title">
                        采购计划单
                    </div>
                    <div class="his-tit" @click="purHisListVis=true;getHisPurList()">
                        历史计划单
                    </div>
                    <div class="del-btn" @click="showPurListPage=false">
                        <i class="el-icon-close" ></i>
                    </div>
                </div>
                <div class="jhd-details">
                    <div class="jhd-msg">
                        <div class="msg-col">
                            <div class="label">
                                供应商:
                            </div>
                            {{jhdInfo.supplier}}
                        </div>
                        <div class="msg-col">
                            <div class="label">
                                联系人:
                            </div>
                            {{jhdInfo.supplier_contact}}
                        </div>
                        <div class="msg-col">
                            <div class="label">
                                计划时间:
                            </div>
                            {{jhdInfo.time_text}}
                        </div>
                    </div>
                    <div class="jhd-msg">
                        <div class="msg-col">
                            <div class="label">
                                备注:
                            </div>
                            {{jhdInfo.remarks}}
                        </div>
                        
                    </div>

                    <div class="jhd-table">
                        <div class="ult-head">
                            <div class="index-num">
                                序号
                            </div>
                            <div v-for="tit in purJhdPartAttrs" :key="tit.key" :class="tit.key">
                                {{tit.title}}
                            </div>
                            
                        </div>
                        <div class="ult-body"  :style="{'height':jhdlistH+'px'}" >
                            
                            <div class="ult-row" v-for="(part,index1) in jhdList"  :key="index1" >   
                                <div class="index-num">
                                    {{index1+1}}
                                </div>
                                <div  v-for="tit in purJhdPartAttrs" :class="tit.key" :key="tit.key" >
                                    {{part[tit.key]}}
                                </div>    
                            </div>

                        </div>
                        
                    </div>

                </div>
                <div class="pur-list-total">
                    <div class="total">
                        <span v-if="jhdInfo.goods_model">{{jhdInfo.goods_model}}种</span> <span v-if="jhdInfo.goods_number">{{jhdInfo.goods_number}}件</span> <span v-if="jhdInfo.goods_total">合计:￥{{jhdInfo.goods_total}}</span>
                    </div>
                    <div class="btn" @click="exExcel(jhdInfo.order_id)">
                        导出Excel
                    </div>
                </div>
            </div>
        </div>
    </div>
    <el-dialog
        title="生成采购计划单"
        :visible.sync="purListVis"
        width="500px"
        custom-class="pur-form-dia"
    >
        <el-form :model="jhdInfo" size="mini" label-width="120px">
            <el-form-item label="采购联系人" >
                <el-input v-model="jhdInfo.contacts" ></el-input>
            </el-form-item>
            <el-form-item label="采购联系电话" >
                <el-input v-model="jhdInfo.iphone" ></el-input>
            </el-form-item>
            <el-form-item label="供应商" >
                <el-input v-model="jhdInfo.supplier" ></el-input>
            </el-form-item>
            <el-form-item label="供应商联系人" >
                <el-input v-model="jhdInfo.supplier_contact" ></el-input>
            </el-form-item>
            <el-form-item label="供应商联系电话" >
                <el-input v-model="jhdInfo.supplieriphone" ></el-input>
            </el-form-item>
            <el-form-item label="备注">
                <el-input v-model="jhdInfo.remarks" ></el-input>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="purListVis=false" size="mini">取 消</el-button>
            <el-button type="primary" @click="createJhd" size="mini">确 定</el-button>
        </span>
    </el-dialog>
    <EpcPurFrom ref="EpcPurFrom" @over="getList"></EpcPurFrom>

    <el-dialog
        title="历史计划单"
        :visible.sync="purHisListVis"
        width="60%"
        top="80px"
        custom-class="pur-his-list"
    >
        <el-form :model="hisSearData"   size="mini" :inline="true" class="search-form">
            <el-form-item >
                <el-input v-model="hisSearData.keyword" placeholder="供应商/联系人/联系电话" ></el-input>
            </el-form-item>
            <el-form-item >
                <el-input v-model="hisSearData.goods" placeholder="配件名称/配件编码" ></el-input>
            </el-form-item>
            
            <el-form-item label="日期">
                <el-select v-model="hisSearData.date"  class="rq-se"  size="mini">
                    <el-option label="近1日" value="day"></el-option>
                    <el-option label="近7日" value="week"></el-option>
                    <el-option label="近15日" value="week2"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="getHisPurList('sear')">查询</el-button>
                <el-button @click="resetFields1">清除</el-button>
            </el-form-item>
        </el-form>
        <div class="ult-table">
            <div class="ult-head">
              
                <div class="index-num">
                    #
                </div>
                <div v-for="attr in jhdAttrs" :key="attr.key" :class="attr.key" :style="{'width':attr.width,'min-width':attr.width}">
                    {{attr.title}}
                </div>
            </div>
            <div class="ult-body"  :style="{'height':jhdhislistH+'px'}">
                
                <div class="ult-row" v-for="(part,index) in purHisList"  :class="{'active':partHisActiveIndex==index}" :key="index" @click="rowClickHis(part,index)">   
                    
                    <div class="index-num">
                        {{index+1}}
                    </div>
                    <div v-for="attr in jhdAttrs" :key="attr.key" :class="attr.key" :style="{'width':attr.width,'min-width':attr.width}">
                        {{part[attr.key]}}
                    </div>
                </div>

            </div>
            <div class="draw-replace">
            </div>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" size="mini" @click="getHisInfo">详情</el-button>
            <el-button type="primary" size="mini" @click="delHis">删除</el-button>
            <el-button type="primary" @click="purHisListVis=false" size="mini">取 消</el-button>
        </span>
    </el-dialog>
    
    <el-dialog
        title="计划单详情"
        :visible.sync="purHisInfoVis"
        width="80%"
        top="80px"
        custom-class="pur-his-info"
    >
        <div class="pur-list-page" >
            <div class="pur-title">
                <div class="main-title">
                    采购计划单
                </div>
            </div>
            <div class="jhd-details">
                <div class="jhd-msg">
                    <div class="msg-col">
                        <div class="label">
                            供应商:
                        </div>
                        {{hisPartInfo.supplier}}
                    </div>
                    <div class="msg-col">
                        <div class="label">
                            联系人:
                        </div>
                        {{hisPartInfo.supplier_contact}}
                    </div>
                    <div class="msg-col">
                        <div class="label">
                            计划时间:
                        </div>
                        {{hisPartInfo.time_text}}
                    </div>
                </div>
                <div class="jhd-msg">
                    <div class="msg-col">
                        <div class="label">
                            备注:
                        </div>
                        {{hisPartInfo.remarks}}
                    </div>
                    
                </div>

                <div class="jhd-table">
                    <div class="ult-head">
                        <div class="index-num">
                            序号
                        </div>
                        <div v-for="tit in purJhdPartAttrs" :key="tit.key" :class="tit.key">
                            {{tit.title}}
                        </div>
                        
                    </div>
                    <div class="ult-body"  :style="{'height':jhdhislistH+'px'}" >
                        
                        <div class="ult-row" v-for="(part,index1) in hisPartInfo.info"  :key="index1" >   
                            <div class="index-num">
                                {{index1+1}}
                            </div>
                            <div  v-for="tit in purJhdPartAttrs" :class="tit.key" :key="tit.key" >
                                {{part[tit.key]}}
                            </div>    
                        </div>

                    </div>
                    
                </div>

            </div>
            <div class="pur-list-total">
                <div class="total">
                   
                    <span v-if="hisPartInfo.goods_model">{{hisPartInfo.goods_model}}种</span> <span v-if="hisPartInfo.goods_number">{{hisPartInfo.goods_number}}件</span> <span v-if="hisPartInfo.goods_total">合计:￥{{hisPartInfo.goods_total}}</span>
                </div>
                <div class="btn" @click="exExcel(hisPartInfo.order_id)">
                    导出Excel
                </div>
            </div>
        </div>
    </el-dialog>
</div>
</template>

<script>
import { export_json_to_excel } from "../../common/utils/Export2Excel.js"
export default {
    data(){
        return{
            searData:{
                member_name:"",
                supplier:"",
                goods_oecode:"",
                goods_name:"",
                models:"",
                member_name:"",
                date:"",
                sort_name:"time",
                sort_type:"DESC"
            },
            hisSearData:{
                date:"",
                keyword:"",
                goods:""
            },
            urserList:[
                {
                    member_name:"测试人"
                }
            ],
            resultLoading:false,
            allChecked:true,
            partlistH:"",
            jhdlistH:"",
            resultList:[],
            partActiveIndex:-1,
            partHisActiveIndex:-1,
            purPartAttrs:[
                {
                    title:"配件编码",
                    key:"goods_oecode"
                },
                {
                    title:"配件名称",
                    key:"goods_name"
                },
                {
                    title:"适用车型",
                    key:"models"
                },
                {
                    title:"车架号",
                    key:"data"
                },
                {
                    title:"品牌",
                    key:"goods_brand"
                },
                {
                    title:"供应商",
                    key:"supplier"
                },
                {
                    title:"参考采购价(含税)",
                    key:"purchase_price"
                },
                {
                    title:"采购价",
                    key:"goods_price"
                },
                {
                    title:"数量",
                    key:"goods_number"
                },
                // {
                //     title:"状态",
                //     key:"starte"
                // },
                {
                    title:"计划人",
                    key:"member_name"
                },
                {
                    title:"计划时间",
                    key:"time_text"
                }
            ],
            jhdAttrs:[
                {
                    title:"计划时间",
                    key:"time_text",
                    width:"140px"
                },
                {
                    title:"采购联系人",
                    key:"contacts"
                },
                {
                    title:"采购联系电话",
                    key:"iphone"
                },
                {
                    title:"供应商",
                    key:"supplier"
                },
                {
                    title:"供应商联系人",
                    key:"supplier_contact"
                },
                {
                    title:"供应商联系电话",
                    key:"supplieriphone"
                },
                {
                    title:"配件种类",
                    key:"goods_model",
                    width:"70px"
                },
                {
                    title:"配件数量",
                    key:"goods_number",
                     width:"70px"
                },
                {
                    title:"合计金额",
                    key:"goods_total"
                },
                {
                    title:"备注",
                    key:"remarks"
                }
            ],

            jhdInfo:{
                time_text:""
            },
            currentPage:1,
            pageSize:10,
            purJhdPartAttrs:[],
            jhdList:[],
            total:0,
            actiPart:{},
            actiHisPart:{},
            hisPartInfo:{},
            purListVis:false,
            purHisListVis:false,
            purHisInfoVis:false,
            purHisList:[],
            showPurListPage:true   
        }
    },
    methods:{
        async getList(e){
            if(e){
                this.currentPage = 1
            }
            let end = new Date().getTime()
            let start = ""
            if(this.searData.date){
                let sta = ""
                if(this.searData.date=='day'){
                    sta = new Date(end) - 24*60*60*1000
                }else if(this.searData.date=='week'){
                    sta = new Date(end) - 24*60*60*7*1000
                }else if(this.searData.date=='week2'){
                    sta = new Date(end) - 24*60*60*15*1000
                }
                let y = new Date(sta).getFullYear()
                let m = new Date(sta).getMonth()+1
                let d = new Date(sta).getDate()
                start = new Date(`${y}-${m}-${d}`).getTime()
                end = parseInt(end/1000)
                start = parseInt(start/1000)
            }

            
            let res = await this.$api.getVinPurList({
                ...this.searData,
                start,
                end,
                member_id:this.$store.state.member_id,
                page:this.currentPage,
                limit:this.pageSize
            })
            this.total = res.total

            this.resultList = res.list || []
            this.resultList.forEach(part=>{
                this.$set(part,"choose",true)
                part.time_text = this.$hnwTools.formatDateTime(part.time)
            })
            // this.resultLoading = false
        },
        async getHisPurList(){
            let end = new Date().getTime()
            let start = ""
            if(this.searData.date){
                let sta = ""
                if(this.searData.date=='day'){
                    sta = new Date(end) - 24*60*60*1000
                }else if(this.searData.date=='week'){
                    sta = new Date(end) - 24*60*60*7*1000
                }else if(this.searData.date=='week2'){
                    sta = new Date(end) - 24*60*60*15*1000
                }
                let y = new Date(sta).getFullYear()
                let m = new Date(sta).getMonth()+1
                let d = new Date(sta).getDate()
                start = new Date(`${y}-${m}-${d}`).getTime()
                end = parseInt(end/1000)
                start = parseInt(start/1000)
            }

            
            let res = await this.$api.getVinPurPlan({
                ...this.hisSearData,
                start,
                end,
                page:1,
                limit:999,
                member_id:this.$store.state.member_id,
            })
            this.purHisList = res.list
            this.purHisList.forEach(part=>{
                part.time_text = this.$hnwTools.formatDateTime(part.time)
            })
        },
        async getHisInfo(){
            if(!this.actiHisPart.order_id){
                return this.$erMsg("请选择对应历史单")
            }
            this.hisPartInfo = await this.$api.getVinPurPlan({
                order_id:this.actiHisPart.order_id
            })
            console.log("this.hisPartInfo", this.hisPartInfo);
            this.hisPartInfo.time_text = this.$hnwTools.formatDateTime(this.hisPartInfo.time)

            // this.purHisListVis = false
            this.purHisInfoVis  = true
        },
        delHis(){
            if(!this.actiHisPart.order_id){
                return this.$erMsg("请选择对应历史单")
            }
            this.$affMsg("确定删除吗",async ()=>{
                let res = await this.$api.delVinPur({
                    order_id:this.actiHisPart.order_id
                })
                if(res){
                    this.$suMsg("删除成功!")
                    this.getHisPurList()
                }
            })
        },
        allChange(){
            this.resultList.forEach(part=>{
                this.$set(part,"choose",this.allChecked)
            })
        },
        rowClick(epcPart,index){
            this.actiPart = epcPart
            this.partActiveIndex = index
        },
        rowClickHis(epcPart,index){
            this.actiHisPart = epcPart
            this.partHisActiveIndex = index
        },
        exExcel(order_id){
         
            let baseUrl =  this.$axios.defaults.baseURL + "index.php?act=voucher&op=plan_list" + "&"  
            baseUrl += `download=1&order_id=${order_id}`
            console.log("baseUrl",baseUrl);
            location.href = baseUrl 
        },
        async hisJhd(){
            let res = await this.$api.getVinPurPlan({
                page:1,
                limit:999,
                member_id:this.$store.state.member_id
            })
            this.jhdInfo =  res.list[0]
            
            this.jhdInfo.time_text = this.$hnwTools.formatDateTime(new Date().getTime()/1000)
            let infoRes = await this.$api.getVinPurPlan({
                page:1,
                limit:999,
                member_id:this.$store.state.member_id,
                order_id:this.jhdInfo.order_id
            })
            this.jhdList = infoRes.info
            this.showPurListPage = true
            // this.
            // thi.jhdInfo = 
        },
        
        handlePart(way){
            if(way=='copy'){
                this.actiPart.id = ""
            }
            this.$refs.EpcPurFrom.actiPart = JSON.parse(JSON.stringify(this.actiPart))
            this.$refs.EpcPurFrom.purCartVis = true
        },
        delPart(){
            // let 
            let ids = ""
            this.resultList.forEach(item=>{
                if(item.choose){
                    ids +=item.id+","
                }
            })
            if(ids){
                this.$affMsg("确定删除吗",async ()=>{
                    ids = ids.substr(0,ids.length-1)
                    let res = await this.$api.delVinPur({
                        id:ids
                    })
                    if(res){
                        this.$suMsg("删除成功!")
                        this.getList()
                    }
                })
                // console.log("res",res);
            }else{
                return this.$warMsg("未选中配件")
            }
            
        },
        exportPart(){
            let list = []
            this.resultList.forEach(item=>{
                if(item.choose){
                    list.push(item)
                }
            })
             // 表头
            
            let tHeader = []
            // 哪些字段
            let filterVal = []
            this.purPartAttrs.forEach(item=>{
                tHeader.push(item.title)
                filterVal.push(item.key)
            })
            let data = this.formatJson(filterVal,list)
            
            export_json_to_excel({
                header:tHeader,
                data,
                filename:"采购单",
                autoWidth:true,
                bookType:'xlsx'
            })
        },
        formatJson(filterVal, tableData) {
            return tableData.map(v => {
                return filterVal.map(j => {
                        return v[j]
                    })
                }
            )
        },
        
        resetFields(){
            // this.$refs.purFrom.resetFields()
            this.searData = {
                member_name:"",
                supplier:"",
                goods_oecode:"",
                goods_name:"",
                models:"",
                member_name:"",
                date:"",
                sort_name:"time",
                sort_type:"DESC"
            }
            this.getList("1")
        },
        resetFields1(){
            this.hisSearData ={
                date:"",
                keyword:"",
                goods:""
            }
            this.getHisPurList()
        },
        createJhdbf(){
            let flag = this.resultList.some(item=>item.choose)
            if(flag){
                this.jhdInfo = {

                }
                this.purListVis = true
            }else{
                this.$warMsg("请选择配件")
            }
        },
        async createJhd(){
            this.jhdList = this.resultList.filter(part=>part.choose)
            let id = ""
            let goods_model=  this.jhdList.length
            
            let goods_total=  0
            let goods_number=  0

            this.jhdList.forEach(goods=>{
                let price = this.$hnwTools.times(goods.goods_price*1,goods.goods_number*1)
                goods_total = this.$hnwTools.plus(goods_total,price)
                goods_number = this.$hnwTools.plus(goods_number,goods.goods_number)
                id += goods.id + ","
            })
            id = id.substring(0,id.length-1)
            this.jhdInfo = {
                ...this.jhdInfo,
                goods_model,
                goods_total,
                goods_number,
                id
            }
            let res = await this.$api.addVinPurPlan({
                ...this.jhdInfo
            })
            this.jhdInfo.time_text = this.$hnwTools.formatDateTime(new Date().getTime()/1000)
            
            if(res){
                this.$suMsg("添加成功")
                this.purListVis = false
                this.showPurListPage = true
                this.jhdInfo.order_id = res

            }else{
                this.$erMsg("添加失败")
            }
            
        }
    },
    created(){
        let contenH =  document.documentElement.clientHeight - 220
        this.partlistH = contenH / 2- 30
        this.jhdlistH = this.partlistH - 120

        this.jhdhislistH = document.documentElement.clientHeight - 420
        let jhdtitle = ["配件编码","配件名称","适用车型","品牌","采购数量","采购价"]
        jhdtitle.forEach(tit=>{
            let obj = this.purPartAttrs.find(item=>item.title==tit)
           
            if(tit=="采购数量"){
                obj = {
                    title:tit,
                    key:"goods_number"
                }
            }
            this.purJhdPartAttrs.push(obj)
        })
        this.getList("")
    },
    watch:{
        showPurListPage(n){
            let contenH =  document.documentElement.clientHeight - 190

            this.partlistH = n?contenH / 2- 30: contenH -70
        }
    },
    props:{
        
    }
}
</script>

<style lang="scss">
.vin-pur-cart{
    .search-form{
        // padding: 5px 0;
        text-align: right;
        .el-form-item{
            margin-bottom: 8px;
            .jhr-se{
                width: 100px;
            }
            .rq-se{
                width: 70px;
            }
            .px-se{
                width: 60px;
            }
            .sear-input{
                .el-input__inner{
                    width: 120px;
                }
            }
            .el-input--suffix{
                .el-input__inner{
                    padding: 0 10px;
                }
            }
        }
    }
    .btn{
        font-weight: 700;
        cursor: pointer;
        padding: 0 10px;
        height: 28px;
        line-height: 28px;
        text-align: center;
        background: $scdBlue;
        color: #015f8b;
        
        border-radius: 6px;
        margin-left: 10px;
    }
    .ult-table{
        background: #fff;
        border: 2px solid$scdLine;
        margin: 2px 2px;
        border-radius: 10px;
        .data{
            max-width: 160px;
            min-width: 160px;
        }
        .goods_number{
            min-width: 80px;
            max-width: 80px;
        }
        
        .ult-head{
            display: flex;
            align-items: center;
            background: #FAFAFA;
            height: 28px;
            border-bottom: 2px solid $scdLine;
            border-radius: 10px 10px 0 0 ;
            >div{
                flex: 1;
                text-align: left;
                color:#56688a;
                box-sizing: border-box;
                padding-left: 5px;
                border-right: 1px solid $scdLine;
            }
            .check{
                min-width: 40px;
                max-width: 40px;
            }
            .index-num{
                max-width: 40px;
                min-width: 40px;
            }
        }
        .ult-body{
            overflow: auto;
            .ult-row{
                display: flex;
                align-items: center;
                // min-height: 20px;
                min-height: 18px;
                padding: 2px 0;
                border-bottom: 1px solid #dce1e8;
                cursor: pointer;
                .index-num{
                    max-width: 40px;
                    min-width: 40px;
                }
                .check{
                    min-width: 40px;
                    max-width: 40px;
                }
                >div{
                    flex: 1;
                    
                    min-height: 2px;
                    padding-left: 5px;
                    line-height: 18px;
                    box-sizing: border-box;
                    word-break:break-all;
                }
            }
            .active{
                background: #36af47;
                color: #fff;
            }
        }
    }
    .control-center{
        padding: 0 10px;
        .list-pagin{
            text-align: right;
        }
        .jhd-btns{
            display: flex;
            justify-content: flex-end;
            padding: 5px 0;
        }
    }
    // 计划单
    .pur-list-page{
        border: 2px solid $scdLine;
        margin: 0 20px;
        margin-bottom: 0;
        padding: 0 15px;
        .pur-title{
            display: flex;
            justify-content: center;
            padding: 5px 0;
            position: relative;
            .main-title{
                font-weight: 700;
                font-size: 18px;
            }
            .his-tit{
                color: #89d6f3;
                cursor: pointer;
                font-size: 12px;
                line-height: 18px;
                padding: 0 5px;
            }
            .del-btn{
                position: absolute;
                cursor: pointer;
                right: 10px;
                top: 5px;
                padding: 5px;
                font-weight: 700;
                font-size: 22px;
            }
        }
        .jhd-msg{
            display: flex;
            padding: 2px 0;
            justify-content: space-between;
            .msg-col{
                display: flex;
            }
        }
        .jhd-table{
            .ult-head{
                display: flex;
                align-items: center;
                background: #FAFAFA;
                height: 28px;
                
                border-radius: 10px 10px 0 0 ;
                >div{
                    flex: 1;
                    text-align: left;
                    color: #000;
                    box-sizing: border-box;
                    padding-left: 10px;
                    
                }
                
                .index-num{
                    max-width: 40px;
                    min-width: 40px;
                }
                .goods_number{
                    min-width: 80px;
                    max-width: 80px;
                }
            }
            .ult-body{
                overflow: auto;
                .ult-row{
                    display: flex;
                    align-items: center;
                    // min-height: 20px;
                    min-height: 18px;
                    padding: 2px 0;
                    border-bottom: 1px solid #dce1e8;
                    cursor: pointer;
                    .index-num{
                        max-width: 40px;
                        min-width: 40px;
                    }
                    .check{
                        min-width: 40px;
                        max-width: 40px;
                    }
                    >div{
                        flex: 1;
                        color: #5b6fa1;
                        min-height: 2px;
                        padding-left: 10px;
                        line-height: 18px;
                        box-sizing: border-box;
                        word-break:break-all;

                    }
                    .goods_number{
                        min-width: 80px;
                        max-width: 80px;
                    }
                }
            
            }
        }
    }
    .pur-list-total{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 5px 0;
        .total{
            margin-right: 10px;
        }
        .btn{
            height: 28px;
        }
    }  
}
</style>