<template>
  <div class="elecatalogout">
    <div class="elecatalogint">
      <header>
        <div class="head-left">
          <div>电子目录</div>
          <div>|</div>
          <div>免责条款</div>
        </div>
        <div class="head-right">
          <div>剩余次数：空</div>
          <div>|</div>
          <el-menu
            mode="horizontal"
            active-text-color="#666"
            text-color="#666"
            class="right-menu"
          >
            <el-submenu index="1">
              <template slot="title">
                <span class="my-hnw">{{ $store.state.nickname }} </span>
              </template>
              <div v-if="$store.state.sc_rent == 1">
                <el-menu-item
                  style="height: 57px; background-color: #edeff4"
                  index="1-1"
                  @click="$router.push(menu.path)"
                  v-for="(menu, index) in $hnwTools.myMenuList"
                  :key="index"
                  >{{ menu.title }}</el-menu-item
                >
              </div>

              <el-menu-item index="1-1" @click="loginOut"
                >退出登录</el-menu-item
              >
            </el-submenu>
          </el-menu>
          <div>|</div>
          <div @click="toCart(1)">购物车</div>
          <div>|</div>
          <div @click="toCart(2)">预购单</div>
        </div>
      </header>

      <div class="elecatalogTitle">
        <div class="elecatalogTitleMain">
          <div class="ybj-img" @click="pageMode = 1">
            <img src="../../assets/ybj/ybjdzml.png" alt="" />
          </div>
          <div class="elecatalog-tool">
            <div class="search-btn">
              <div @click="selectMode = 2" :class="{ active: selectMode == 2 }">
                按VIN
              </div>
              <div @click="selectMode = 3" :class="{ active: selectMode == 3 }">
                OE查询
              </div>
              <div @click="selectMode = 1" :class="{ active: selectMode == 1 }">
                按编码
              </div>  
            </div>
            <div class="search-input" v-if="selectMode == 1">
              <input
                class="search-code"
                v-model="ProductCode"
                placeholder="请输入至少4位产品编码"
              />
              <div class="search-button" @click="getProduct">查询</div>
            </div>  
            <div class="search-input" v-if="selectMode == 2">
              <img class="img" src="../../assets/ybj/photo.png" alt="" />
              <el-autocomplete
                class="inline-input"
                v-model="VinCode"
                @paste.native="pasteImg"
                :fetch-suggestions="querySearch"
                placeholder="请输入VIN码,或粘贴车架号图片"
                @select="handleSelect"
                @focus="getHisList"
                clearable
              ></el-autocomplete>
              <div class="search-button" @click="getVin">查询</div>
            </div>

            <div class="search-input" v-if="selectMode == 3">
              <input
                class="search-code"
                v-model="OECode"
                placeholder="请输入至少4位产品件码[FMSI,OE等]"
              />
              <div class="search-button" @click="getOE">查询</div>
            </div>
          </div>
        </div>
      </div>

      <!-- 主页 -->
      <div class="elecatalog-body" v-if="pageMode == 1" v-loading="loading">
        <selectCar @productPartNum="productPartNum"></selectCar>
      </div>
      <!-- VIN查询页面 -->
      <div class="elecatalog-body" v-if="pageMode == 2" v-loading="loading">
        <!-- <carInfo :VinCode="VinCode" @backPage="backPage" :levelidsInfo="levelidsInfo" @fankuiCenter='fankuiCenter' :productInfo="productInfo"></carInfo> -->
        <carInfo
          :LevelId="LevelId"
          :VinCode="VinCode"
          @backPage="backPage"
          :levelidsInfo="levelidsInfo"
          @fankuiCenter="fankuiCenter"
          :productInfo="productInfo"
        ></carInfo>
      </div>
      <!-- OE查询页面 -->
      <div class="elecatalog-body" v-if="pageMode == 3" v-loading="loading">
        <produceInfo
          @backpagemode="backpagemode"
          :productInfo="OEcarList"
          @fankuiCenter="fankuiCenter"
          :levelidsInfo="levelidsInfo"
        ></produceInfo>
      </div>
      <!-- 编码查询页面 -->
      <div class="elecatalog-body" v-if="pageMode == 4" v-loading="loading">
        <codeInfo
          @backPage="backPage"
          :levelidsInfo="levelidsInfo"
          @fankuiCenter="fankuiCenter"
          :productInfo="productCodeList"
        ></codeInfo>
      </div>

      <!-- 反馈页面 -->
      <div class="elecatalog-body" v-if="pageMode == 5" v-loading="loading">
        <fankui></fankui>
      </div>
      <!-- 配件页面 -->
      <div
        class="elecatalog-body-part"
        v-if="pageMode == 6"
        v-loading="loading"
      >
        <producrPart
          ref="partProduct"
          :selectProductPart="selectProductPart"
          @backPart="backPart"
          @showInfo="pageMode = 7"
        ></producrPart>
      </div>
      <!-- 配件的详情页 -->
      <div v-if="pageMode == 7">
        <GoodsInfoYbj @reBack="reBack"></GoodsInfoYbj>
      </div>
    </div>
  </div>
</template>


<script>
import selectCar from "./components/selectCar.vue";
import carInfo from "./components/carInfo.vue";
import produceInfo from "./components/produceInfo.vue";
import codeInfo from "./components/codeInfo.vue";
import fankui from "./components/fankui.vue";
import producrPart from "./components/productPart.vue";
// 引入组件
import GoodsInfoYbj from "../../components/GoodsInfoYbj.vue"
export default {
  data() {
    return {
      selectMode: 2, //切换搜索页
      VinCode: "",
      loading: false,
      pageMode: 1, // 切换内容,
      levelidsInfo: [], //查询Vin码得到的信息
      loading: false,
      OECode: "", //OE查询
      ProductCode: "", //产品编码
      productInfo: [], //卢卡斯的数据
      nickname: "",
      OEcarList: [], //OE获取列表
      LevelId: "",
      productCodeList: [],
      hisList: [], //历史记录
      getHis: false, //显示历史记录
      showArrow: true,
      state1: "",
      restaurants: [],
      selectProductPart: "",
    };
  },
  async created() {
    // 版本升级
    let verRes = await this.$api.handleVer({
      model: 2,
    });
    let vers = localStorage.getItem("hnw-web-vers");
    if (vers != "undefined") {
      vers = JSON.parse(vers);

      if (vers != verRes) {
        localStorage.setItem("hnw-web-vers", JSON.stringify(verRes));
        location.reload(true);
      }
    }
    if (this.$store.state.shop != 2018) {
      this.loginOut();
    }
    if (this.token) {
      this.$nextTick(async () => {
        let res = await this.$api.getAccMsg({
          member_id: this.member_id,
          type: this.$store.state.role_name == "all" ? 1 : 2,
        });
        this.nickname = res.nickname;
      });

      this.$store.dispatch("getAdvCart");
      this.$store.dispatch("getVinNum");
    }
  },
  updated() {},
  methods: {
    reBack(option){ 
      this.pageMode = option.mode;
      this.selectProductPart = option.data.goods_name;
    },
    backPart(option) {
      this.pageMode = option;
    },
    productPartNum(data) { 
      this.pageMode = data[0];
      this.selectProductPart = data[1];
    },
    loadAll() {
      this.hisList.forEach((item, index) => {
        this.$set(item, "value", item.record_name);
      });
      return this.hisList;
    },
    querySearch(queryString, cb) {
      var restaurants = this.restaurants;
      var results = queryString
        ? restaurants.filter(this.createFilter(queryString))
        : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (
          restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) ===
          0
        );
      };
    },
    handleSelect(item) {},
    // OE页面的返回
    backpagemode(option) {
      this.pageMode = option;
    },

    oeSearch(data) {
      this.VinCode = data;
      this.getVindata(data);
      this.getHis = false;
    },
    // 添加历史记录
    async addHisllist(data) {
      await this.$api.addSearchRecord({
        record_name: data,
        record_type: 3,
        car_type: "",
        record_from: 3,
      });
    },
    async changehis() {
      if (!this.VinCode) {
        this.getHis = true;
      } else {
        this.getHis = false;
        let res = await this.$api.getSearchRecord({
          store_id: this.$store.state.store_id,
          record_type: 3,
          record_from: 3,
        });
        this.hisList = res;
        this.restaurants = this.loadAll();
      }
    },
    // 获取历史记录
    async getHisList() {
      if (!this.VinCode) {
        this.getHis = true;
        let res = await this.$api.getSearchRecord({
          store_id: this.$store.state.store_id,
          record_type: 3,
          record_from: 3,
        });
        this.hisList = res;
        this.restaurants = this.loadAll();
      }
    },
    toCart(type) {
      if (!this.$isCompany()) {
        return;
      }
      this.$router.push("/shopcar/1/" + type);
    },
    loginOut() {
      if (!this.$store.getters.notLvD || this.$store.state.sc_rent != 1) {
        this.$router.push("/login");
      } else {
        this.$router.push("/");
      }
      localStorage.removeItem("hnw-web-state");
      this.$store.commit("resetState");
    },
    fankuiCenter(data) {
      this.pageMode = data;
    },
    // 按产品编码查询
    async getProduct() {
      // console.log('按产品编码查询')
      // this.VinCode = ''
      this.OECode = "";

      // let reg = /^[A-Za-z0-9]{4,}$/g
      // 去除字符串内所有的空格
      this.ProductCode = this.ProductCode.replace(/\s/g, "");
      // if(!reg.test(this.ProductCode)){
      //   return this.$warMsg("请输入至少4位产品编码")
      // }

      this.loading = true;
      if (this.ProductCode) {
        let res = await this.$http(
          `index.php?act=data&op=liyang&model=getproductbyproductno`,
          {
            data: this.ProductCode,
          }
        );

        if (res) {
          this.productCodeList = res.Result;
          let productList = [];

          this.productCodeList.forEach((item) => {
            productList.push(item.productNo);
          });
          let getSearches = await this.$api.getSearches({
            searchs: productList.join(","),
            shopId: this.$store.state.shop,
            store_id: this.$store.state.store_id,
          });
          if (getSearches.data[0].goods_list) {
            this.productCodeList = getSearches.data[0].goods_list; 
          }
          // 将力洋接口的数据添加到产品列表中
          this.productCodeList.forEach(item => {
              this.$set(item,"liyangInfo",{})
              res.Result.forEach(info => {
                if(info.productNo===item.sccode){
                  // 处理力洋图片
                  info.productImgs = info.productImgs.split("^")
                  this.$set(info,"productImgFirst",info.productImgs[0])
                  item.liyangInfo = info
                }
              })
            })
        }
        this.pageMode = 4;
        this.loading = false;
      }
    },
    // 按OE查询
    async getOE() {
      // console.log('按OE查询')
      // this.VinCode = ''
      // this.ProductCode = ''

      // let reg = /^[A-Za-z0-9]{4,}$/g
      // 去除字符串内所有的空格
      this.OECode = this.OECode.replace(/\s/g, "");
      // if(!reg.test(this.OECode)){
      //   return this.$warMsg("请输入至少4位产品件码[FMSI,OE等]")
      // }

      this.loading = true;
      if (this.OECode) {
        let res = await this.$http(`index.php?act=data&op=liyang&model=oe`, {
          data: this.OECode,
        });
        if (res) {
          this.OEcarList = res.Result;
          if (res.Result.length == 0) {
            this.pageMode = 3;
            this.loading = false;
            return this.$warMsg("没有产品信息");
          }

          let productList = [];

          this.OEcarList.forEach((item) => {
            productList.push(item.productNo);
          });
          let getSearches = await this.$api.getSearches({
            searchs: productList.join(","),
            shopId: this.$store.state.shop,
            store_id: this.$store.state.store_id,
          });
          this.OEcarList = getSearches.data[0].goods_list; 
          // 将力洋接口的数据添加到产品列表中
          this.OEcarList.forEach(item => {
              this.$set(item,"liyangInfo",{})
              res.Result.forEach(info => {
                if(info.productNo===item.sccode){
                  // 处理力洋图片
                  info.productImgs = info.productImgs.split("^")
                  this.$set(info,"productImgFirst",info.productImgs[0])
                  item.liyangInfo = info
                }
              })
            })
        }

        this.pageMode = 3;
        this.loading = false;
      }
    },
    searchVIN() {
      this.selectMode = 2;
    },          
    async getVin() {
      // console.log('按VIN查询')
      this.OECode = "";
      // this.ProductCode = ''

      this.getVindata(this.VinCode);
    },
    // 获取VIn数据
    async getVindata(option) {
      this.loading = true;
      option = option.replace(/\s/g, "").toUpperCase();
      if (option.length != 17) {
        this.loading = false;
        return this.$warMsg("车架号长度必须17位，请检查您的车架号");
      }
      if (option) {
        let res = await this.$http(`index.php?act=data&op=liyang&model=auto`, {
          data: option,
        });
        if (res) {
          if (res.Check != "E0") {
            this.loading = false;
            return this.$warMsg("查询失败！");
          }
          // 添加历史记录
          this.addHisllist(option);

          let modeId = "";

          if (!res.Model_ID) {
            this.loading = false;
            return this.$warMsg("车架号不存在");
          }

          if (res.Model_ID instanceof Array) {
            modeId = res.Model_ID.join(",");
          } else {
            modeId = res.Model_ID;
          }
          let res1 = await this.$http(
            `index.php?act=data&op=liyang&model=VehicleByLids`,
            {
              data: modeId,
              AppKey: "3dd4ed48ea913068",
              AppSecret: "431595e67f114da9a839e8b49605b4b6",
            }
          );

          this.levelidsInfo = res1.Result;
          if (this.levelidsInfo.length == 0) {
            this.loading = false;
            this.$warMsg("暂无产品信息");
            return;
          }
          this.$suMsg("查询成功");
          this.LevelId = this.levelidsInfo[0].LevelId; 

          
          if (modeId) {
            let res2 = await this.$http(
              `index.php?act=data&op=liyang&model=productwithnote`,
              {
                data: modeId,
              }
            );

            this.productInfo = res2.Result;
            

            for (let i = 0; i < this.productInfo.length - 1; i++) {
              for (let j = i + 1; j < this.productInfo.length; j++) { 
                if (
                  this.productInfo[i].productNo == this.productInfo[j].productNo
                ) {
                  this.productInfo.splice(j, 1);
                  j--;
                }
              }
            }  
            this.loading = false;
            if (res2.Result.length == 0) {
              return this.$warMsg("没有产品信息");
            }
            let productList = [];

            this.productInfo.forEach((item) => {
              productList.push(item.productNo);
            });
            

            let getSearches = await this.$api.getSearches({
              searchs: productList.join(","),
              shopId: this.$store.state.shop,
              store_id: this.$store.state.store_id,
            });
            this.productInfo = getSearches.data[0].goods_list;
            // 将力洋接口的数据添加到产品列表中
            this.productInfo.forEach(item => {
              this.$set(item,"liyangInfo",{})
              res2.Result.forEach(info => {
                if(info.productNo===item.sccode){
                  // 处理力洋图片
                  info.productImgs = info.productImgs.split("^")
                  this.$set(info,"productImgFirst",info.productImgs[0])
                  item.liyangInfo = info
                }
              })
            }) 
            this.pageMode = 2;
          }
        }
      }
    },
    pasteImg(event) {
      let clipboardData = event.clipboardData;
      if (!(clipboardData && clipboardData.items)) {
        return;
      }
      for (let i = 0, len = clipboardData.items.length; i < len; i++) {
        let item = clipboardData.items[i];
        if (item.type.indexOf("image") !== -1) {
          let f = item.getAsFile();

          this.fileReaderupdateImg(f);
        }
      }
    },
    fileReaderupdateImg(file) {
      this.loading = true;
      let reader = new FileReader();

      reader.readAsDataURL(file);
      reader.onload = (e) => {
        let img = new Image();
        // console.log(e.target.result)
        img.src = e.target.result;
        img.onload = async () => {
          // console.log("img",img)
          let data = this.compress(img);
          this.upImg(data);

          return;
          let blob = this.dataURItoBlob(data);
          // console.log("data",data)
          // console.log("blob",blob)
        };
      };
    },
    compress(img) {
      let canvas = document.createElement("canvas");
      let ctx = canvas.getContext("2d");
      let width = img.width;
      let height = img.height;
      canvas.width = width;
      canvas.height = height;
      ctx.fillStyle = "#fff";
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      ctx.drawImage(img, 0, 0, width, height);
      let ndata = canvas.toDataURL("image/jpeg", 0.5);
      return ndata;
    },
    async upImg(baseData) {
      this.loading = true;
      let orcRes = await this.$http(
        `index.php?act=data&op=liyang&model=image`,
        {
          data: baseData,
          member_id: this.$store.state.member_id,
          shop_id: this.$store.state.shop,
          member_name: this.$store.state.member_name,
          store_name: this.$store.state.store_name,
        }
      );
      if (orcRes) {
        this.$suMsg("解析成功");
        this.VinCode = orcRes.Result;
      }
      this.loading = false;
    },
    backPage(option) {
      this.pageMode = option;
    },
  },
  components: {
    selectCar,
    carInfo,
    produceInfo,
    codeInfo,
    fankui,
    producrPart,
    GoodsInfoYbj,
  },
};
</script>

<style lang="scss" scoped>
.elecatalogout {
  // width: 100vw;
  // height: 100vh;
  background: rgb(242, 242, 242);
  .elecatalogint {
    header {
      display: flex;
      justify-content: space-between;
      height: 40px;
      width: 1200px;
      margin: 0 auto;
      .head-left {
        display: flex;
        div {
          color: #2c2828;
          margin: 0 5px;
          line-height: 40px;
        }
      }
      .head-right {
        display: flex;
        .right-menu {
          background: #f2f2f2;
        }

        ::v-deep .el-submenu {
          line-height: 40px;
          .my-hnw {
            height: auto;
            line-height: 1;
          }
        }
        ::v-deep .el-submenu__title {
          line-height: 40px;
          padding: 0;
        }
        ::v-deep .el-submenu__title:hover {
          background-color: #f2f2f2;
          height: 40px;
        }
        div {
          color: #2c2828;
          margin: 0 5px;
          line-height: 40px;
        }
      }
    }
    .elecatalogTitle {
      width: 100%;
      height: 110px;
      background: #fff;
      .elecatalogTitleMain {
        width: 1200px;
        height: 110px;
        margin: 0 auto;
        display: flex;
        .ybj-img {
          width: 300px;
          height: 100%;
          text-align: center;
          line-height: 150px;
        }
        .elecatalog-tool {
          margin-left: 27px;

          .search-btn {
            display: flex;
            margin-top: 20px;
            div {
              margin: 0 10px;
              color: rgb(157, 177, 226);
              font-size: 14px;
              line-height: 30px;
            }
            .active {
              color: blue;
            }
          }
          .sear-input-box {
            position: relative;
            display: flex;
            justify-content: space-between;
            width: 800px;
            flex: 1;
            border: 1px solid #ccc;
            .img {
              width: 30px;
              height: 30px;
              margin: 6px 0 0 12px;
            }
            .search-button {
              width: 70px;
              height: 44px;
              background: rgb(52, 78, 139);
              color: #fff;
              text-align: center;
              line-height: 44px;
            }
          }
          .vin-code-input {
            position: absolute;
            left: 53px;
            top: 0;
            z-index: 10;
            width: 624px;
            // height: 2.121212rem /* 70/33 */;
            .el-input__inner {
              font-weight: 700;
              border-right: none;
              font-size: 16px;
              color: #3158c6;
              padding-right: 10px;
              height: 100%;

              &::placeholder {
                font-size: 12px;
              }
            }
            ::v-deep .el-input__inner {
              border: 1px solid #fff;
            }
          }
          .vin-his-box {
            position: absolute;
            left: 57px;
            top: 0;
            z-index: 8;
            width: 100%;

            height: 2.121212rem /* 70/33 */;
            ::v-deep .el-input {
              height: 2.121212rem /* 70/33 */;
              width: 673px;
              height: 40px;
            }
            ::v-deep .el-input__inner {
              border-right: none;
              height: 100%;
              border: 1px solid #fff;
            }
          }
          .search-input {
            display: flex;
            border: 1px solid #ccc;
            width: 800px;
            padding-left: 10px;
            position: relative;
            .inline-input {
              ::v-deep .el-input__inner {
                // border-right: none;
                // height: 100%;
                height: 44px;
                width: 709px;
                border-radius: 0;
                margin-right: -5px;
                border-color: #fff;
              }
              ::v-deep .el-input__inner:focus {
                border-color: #fff;
              }
              ::v-deep .el-input__inner:hover {
                border-color: #fff;
              }
            }
            .img1 {
              width: 14px;
              height: 14px;
              margin: 16px 24px 0 0;
            }
            .img2 {
              width: 8px;
              height: 14px;
              margin: 16px 11px 0 0;
            }
            .img3 {
              width: 15px;
              height: 15px;
              margin: 16px 11px 0 0;
            }
            .vin-his-box {
              position: absolute;
              left: 0;
              top: 0;
              z-index: 8;

              height: 2.121212rem /* 70/33 */;
              .el-input {
                height: 2.121212rem /* 70/33 */;
              }
              .el-input__inner {
                // border-right: none;
                // height: 100%;
                height: 44px;
                width: 709px;
                border-radius: 0;
                margin-right: -5px;
              }
            }
            .sear-his-list {
              border: 1px solid #ccc;
              position: absolute;
              top: 45px;
              left: -1px;
              width: 810px;
              background: #fff;
              z-index: 10;
              .list {
                .his-item {
                  height: 35px;
                  line-height: 35px;
                  font-size: 18px;
                  padding-left: 10px;
                  box-sizing: border-box;
                  &:hover {
                    background: #eee;
                  }
                }
              }
            }
            .search-code {
              flex: 1;
              height: 44px;
              vertical-align: middle;
              padding: 10px;
              box-sizing: border-box;
              outline: none;
              border: none;
            }
            ::v-deep .el-input__inner {
              height: 44px;
              width: 800px;
              border-radius: 0;
              margin-right: -5px;
            }
            .search-button {
              width: 77px;
              height: 44px;
              background: rgb(52, 78, 139);
              color: #fff;
              text-align: center;
              line-height: 44px;
            }
            .img {
              height: 20px;
              margin-top: 13px;
            }
          }
        }
      }
    }
    .elecatalog-body {
      width: 1200px;
      margin: 20px auto 0;
    }
    .elecatalog-body-part {
      width: 1200px;
      margin: 10px auto 0;
    }
  }
}
</style>