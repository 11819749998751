<template>
  <div class="brand-index">
    <!-- 品牌弹框 -->
   
    <div class="brand-logo" v-if="$store.state.shop==1871||$store.state.shop==1974"> 
      <div
        class="img-box-style"
        v-for="(brand, i) in putList"
        :key="i"
        @click="getList(1, brand)"
        :class="{'activeClass':oenaturebrand==brand}"
      > 
        <img :src="require(`../assets/brand/${brand}.png`)" alt="">   
        <div class="img-txt">{{ brand }}</div>
      </div>
    </div>
    <div class="brand-logo" v-else>
        <div
        class="img-box-style" 
        v-for="(brand, i) in putList"
        :key="i"
        @click="getList(1, brand.oenaturebrand)"
        :class="{'activeClass':oenaturebrand==brand.oenaturebrand}"
       > 
        <img :src="require(`../assets/brand/${brand.oenaturebrand}.png`)" alt="">   
        <div class="img-txt">{{ brand.oenaturebrand }}</div>
      </div>
    </div>

    <!-- 品牌配件信息 -->
    <div class="brand-msg">
      <!-- <div class="brand-name">
        <div class="brand-select" @click.stop="displayBrand">
          选择品牌
          <i class="el-icon-arrow-down" v-if="!showBrand"></i>
          <i class="el-icon-arrow-up" v-if="showBrand"></i>
        </div>
        <div class="brand-checked" @click.stop="displayBrand">
          {{ oenaturebrand || "全部" }}
        </div>
      </div> -->

      <div class="brandName" @click.stop="displayBrand">
        <div class="brand">品牌：{{ oenaturebrand || "全部" }}</div>
      </div>

      <div class="brandName" @click.stop="displayProduct">
        <div class="brand">产品分类：{{ oesort || "全部" }}
          <i class="el-icon-arrow-down" v-if="!showProduct"></i>
          <i class="el-icon-arrow-up" v-if="showProduct"></i> 
        </div>

        <div class="brand-cls" v-if="showProduct">
          <div class="jiao"></div>
          <div class="brand-search">
            <el-input
              @click.stop.native="stopstop"
              v-model="result"
              clearable
              placeholder="请输入内容"
            ></el-input>
            <div class="brand-btn" @click.stop="searchResult">搜索</div>
         </div>
          <div class="brand-info">
              <div
                class="cls-item"
                v-for="(cls, index) in clsList"
                :key="index"
                @click="getList(2, cls)"
                :class="{ active: oesort == cls }"
              >
                {{ cls }}
              </div>
          </div>
        </div>

      </div>

      <div class="brandName" @click="getList(3, '')">
        <div class="brand">配件数量：{{ goodsMsgTotal.total }}</div>
      </div>

      <div class="brandName" @click="getList(3, 'ok')">
        <div class="brand">有货：{{ goodsMsgTotal.in_total }}</div>
      </div>

      <div class="brandName" @click="getList(3, 'no')">
        <div class="brand">无货：{{ goodsMsgTotal.not_total }}</div>
      </div>
      <div class="close-btn" @click="clearBrand" v-if="oenaturebrand || oesort"> 
        清除
      </div>

      
      <!-- <div class="brand-name">
        <div class="brand-select" @click.stop="displayBrand">
          品牌
        </div>
        <div class="brand-checked" @click.stop="displayBrand">
          {{ oenaturebrand || "全部" }}
        </div>
      </div>
      <div class="brand-name">
        <div class="brand-select" @click.stop="displayProduct">
          产品分类
          <i class="el-icon-arrow-down" v-if="!showProduct"></i>
          <i class="el-icon-arrow-up" v-if="showProduct"></i>
        </div>
        <div class="brand-checked" @click.stop="displayProduct">
          {{ oesort || "全部" }}
        </div>

        <div class="brand-cls" v-if="showProduct">
          <div class="jiao"></div>
          <div class="brand-search">
            <el-input
              @click.stop.native="stopstop"
              v-model="result"
              clearable
              placeholder="请输入内容"
            ></el-input>
            <div class="brand-btn" @click.stop="searchResult">搜索</div>
         </div>
          <div class="brand-info">
              <div
                class="cls-item"
                v-for="(cls, index) in clsList"
                :key="index"
                @click="getList(2, cls)"
                :class="{ active: oesort == cls }"
              >
                {{ cls }}
              </div>
          </div>
        </div>
      </div>
      <div class="line"></div>
      <div @click="getList(3, '')" class="brand-names">
        <div class="brand-parts">配件数量</div>
        <div class="parts-text">{{ goodsMsgTotal.count }}</div>
      </div>
      <div @click="getList(3, 'ok')" class="brand-names">
        <div class="brand-parts">有货</div>
        <div class="parts-text">{{ goodsMsgTotal.count_ok }}</div>
      </div>
      <div @click="getList(3, 'no')" class="brand-names">
        <div class="brand-parts">无货</div>
        <div class="parts-text">{{ goodsMsgTotal.count_no }}</div>
      </div>
      <div class="close-btn" @click="clearBrand" v-if="oenaturebrand || oesort"> 
        清除
      </div> -->
    </div>
    

    <div class="goods-list">
      <div class="list-head">
        <div
          class="head-item"
          v-for="(attr, index) in attrList"
          :key="index"
          :class="attr.key"
        >
          <div>
            {{ attr.title  }}
            <span v-if="attr.title=='订货价'&&(shopId=='1871'||shopId=='1974')">(不含税)</span>
            <!-- <span v-if="attr.key=='goods_price'">
                        (整箱单价)
                    </span> -->
          </div>
          <div class="sort">
            <!-- <i class="el-icon-caret-top" :class="{'active':activeSort==attr.key+1}" @click="sortChange(attr.key,1)"></i> -->
            <!-- <i class="el-icon-caret-bottom" :class="{'active':activeSort==attr.key+2}" @click="sortChange(attr.key,2)"></i> -->
            <img
              src="../assets/brand/up.png"
              v-if="activeSort == attr.key + 1"
              alt=""
              @click="sortChange(attr.key, 2, index)"
            />
            <img
              src="../assets/brand/down.png"
              v-else
              alt=""
              @click="sortChange(attr.key, 1, index)"
            />
          </div>
          <!-- <el-checkbox class="filterZero" v-if="attr.key=='goods_storage'" v-model="showZero" @change="changeZero">过滤0库存</el-checkbox> -->
        </div>
        <!-- <div class="buy-nums head-item">
                规格
            </div> -->
        <div class="buy-nums head-item">订货数量</div>
        <div class="handle head-item">操作</div>
      </div>

      <div class="list-body">
        <div
          class="list-goods"
          v-for="(goods, index) in goodsList"
          :key="index"
        >
          <div class="row">
            <template v-for="(attr, index1) in attrList">
              <div
                class="list-rol"
                :key="index1"
                :class="attr.key"
                v-if="attr.title == 'OE号'"
                @click="getGoodsInfo(goods)"
                :title="goods[attr.key]"
              >
                <div class="brand">
                  {{ goods.oenaturebrand }}
                </div>
                {{ goods[attr.key] }}

                <!-- <div class="tag-icon" v-if="goods.exchange.length>0">
                                互
                            </div> -->
                <div class="vehicle" :title="goods.vehicletype">
                  车型:{{ goods.vehicletype }}
                </div>
              </div>

              <!-- <div class="list-rol"  :key="index1" :class="attr.key" v-else-if="attr.key=='goods_price'">

                            <span v-if="!$store.state.token" class="blue">
                                会员可见
                            </span>

                            <span v-else>
                                ￥{{goods[attr.key]}}
                                <span v-if="goods.brand_ratio_price">
                                    ({{goods.brand_ratio_price}})
                                </span>
                            </span>
                        </div> -->

              <!-- <div class="list-rol" :key="index1" :class="attr.key" v-else> -->
              <div class="list-rol"  :class="attr.key" v-else>
                <span
                  v-if="attr.key == 'goods_storage'"
                  :class="{ 'disabled-color': goods.goods_storage == 0 }"
                >
                  <span v-if="$store.state.look_inv == 1">{{
                    goods.goods_storage
                  }}</span>
                  <span v-else>{{
                    goods.goods_storage > 0 ? "有货" : "无货"
                  }}</span>
                </span>
                <!-- 会员可见 -->
                <span :title="goods[attr.key]" v-else>
                  {{ goods[attr.key] }}
                </span>
              </div>
            </template>
            <!-- 没有规格 -->
            <!-- <div class="list-rol">
                      {{goods.spec}}
                    </div> -->
            <div class="buy-nums list-rol">
              <el-input-number   v-if="$store.state.zero_buy==1" v-model="goods.goods_num" :min="1"  size="mini"></el-input-number>
            <el-input-number   v-else  v-model="goods.goods_num"  @change="impueNumChange(goods)" :min="1"  size="mini"></el-input-number>
            <span class="goods-Fcl-tit red" v-if="goods.batch_pur_num_bey" >
                库存不足{{goods.batch_pur_num||0}}
            </span>
            </div>
            <div class="handle list-rol">
              <div
                class="btn"
               
                @click="joinCart(goods)"
                v-if="$store.state.shop==1974||$store.state.shop==1871"
              >
                加入购物车
                <!-- 加入购物车 -->
              </div>
              <div
                class="btn"
                :class="{ 'ycg-btn': goods.goods_storage == 0 }"
                @click="joinCart(goods)"
                v-else
              >
                {{ goods.goods_storage > 0 ? "加入购物车" : "加入预购单" }} 
              </div>
              <div class="cart-num" v-if="goods.cartNum">
                已添加 {{ goods.cartNum || 0 }} {{ goods.oecompany }}
              </div>
              <!-- <div class="replace-btn" @click="readRpList(goods)" v-if="goods.exchange.length>0">查看互换件</div> -->
              <!-- <div class="replace-btn" @click="readRpList(goods)">
                查看互换件
              </div> -->
            </div>
          </div>

          <div class="row-two" v-if="goods.goodsorderInfo">
            <div class="buyHis" @click="readHisList(goods.goods_id)">
              <template>
                您最近一次购买：
                <!-- <span>
                                
                                {{$hnwTools.formatDateTime(goods.orderInfo.add_time,'ymd')}}
                            </span> -->
                <!-- <span>
                                {{goods.orderInfo.member_name}}
                            </span> -->
                <span> {{ goods.orderInfo.goods_num }}件 </span>

                <!-- <span>
                                {{$hnwTools.getOrderStateText(goods.orderInfo.order_state)}}
                            </span> -->
                <span class="readMore">
                  查看更多
                  <!-- 更多 -->
                </span>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>

    <el-dialog title="历史购买记录" :visible.sync="hisVis" center width="800px">
      <div class="his-list">
        <div class="list-head">
          <div>购买时间</div>
          <div>购买人</div>
          <div class="col-100">价格</div>
          <div class="col-100">购买个数</div>
          <div class="col-100">订单状态</div>
        </div>
        <div class="list-body">
          <div class="row" v-for="(his, index) in gooodsHisList" :key="index">
            <div>{{ $hnwTools.formatDateTime(his.add_time, "ymd") }}</div>
            <div>{{ his.member_name }}</div>
            <div class="col-100">{{ his.goods_price }}</div>
            <div class="col-100">{{ his.goods_num }}</div>

            <div class="col-100">
              {{ $hnwTools.getOrderStateText(his.order_state) }}
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
    <div class="hezi">
      <el-dialog :visible.sync="dialogVisible" width="760px" top="8vh">
        <DiaGoodsInfo :id="goods_id"></DiaGoodsInfo>
      </el-dialog>
    </div>
    <div class="store-vis">
      <el-dialog :visible.sync="storeVis" width="900px" :show-close="false">
        <div class="tit">
          供应商列表
          <i class="el-icon-close" @click="storeVis = false"></i>
        </div>
        <div class="list-body">
          <div class="list-head">
            <div>供应商名称</div>
            <div class="name">名称</div>
            <div class="oe">OE号</div>
            <div>品牌</div>
            <div>价格</div>
            <div>库存</div>
            <div class="buy-nums list-rol">购买数量</div>

            <div>操作</div>
          </div>
          <div
            class="list-row"
            v-for="(goods, index) in buyStoreList"
            :key="index"
          >
            <div>{{goods.store_name}}</div>
            <!-- <div>华诺威</div> -->
            <div class="name">{{ goods.goods_name }}</div>
            <div class="oe">
              {{ goods.oecode }}
            </div>

            <div>{{ goods.oenaturebrand }}</div>
            <div>￥{{ goods.goods_price }}</div>
            <div :class="{ 'disabled-color': goods.goods_storage == 0 }">
              <span v-if="$store.state.look_inv == 1">{{
                goods.goods_storage
              }}</span>
              <span v-else>{{
                goods.goods_storage > 0 ? "有货" : "无货"
              }}</span>
            </div>
            <div class="buy-nums list-rol">
              <el-input-number
                v-if="$store.state.zero_buy == 1"
                v-model="goods.goods_num"
                :min="1"
                size="mini"
              ></el-input-number>
              <el-input-number
                v-else
                @change="impueNumChange(goods)"
                v-model="goods.goods_num"
                :min="1"
                size="mini"
              ></el-input-number>
            </div>
            <div
                class="btn"
               
                @click="replaceJoinCart(goods)"
                v-if="$store.state.shop==1974||$store.state.shop==1871"
              >
                加入购物车
                <!-- 加入购物车 -->
              </div>
              <div
                class="btn"
                :class="{ 'ycg-btn': goods.goods_storage == 0 }"
                @click="replaceJoinCart(goods)"
                v-else
              >
                {{ goods.goods_storage > 0 ? "加入购物车" : "加入预购单" }}
                <!-- 加入购物车 -->
              </div>
            <!-- <div>
              <span
                class="btn"
                :class="{ 'ycg-btn': goods.goods_storage == 0 }"
                @click="replaceJoinCart(goods)"
              >
                {{ goods.goods_storage > 0 ? "加入购物车" : "加入预购单" }} 
              </span>
            </div> -->
          </div>
        </div>
      </el-dialog>
    </div>
    <div class="paging">
      <!-- <span class="database">共 {{total}} 件配件</span> -->
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage"
        :page-sizes="[10, 15, 20, 30, 40]"
        :page-size.sync="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="Math.ceil(total)"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      brandList:["博世","采埃孚","法雷奥","索恩格","德尔福","伦福德",
            "萨克斯","天合","卢卡斯","康迪泰克","车享配","汉高",
            "汉格斯特","马瑞利","博格华纳","海拉","华诺威","斯泰必鲁斯","SABS",
            "爱尔铃","NTK","天博","上海法雷奥","索菲玛","ATI",
            "华域三电","安庆帝伯格茨","三爱海陵","富奥","昌辉","信力",
            "上海来利","崇芳","永信","捷豹","厦门理研","凯吉","征和","欧博萨","上海兴盛",
            "上汽大众","皮尔伯格","科尔本","石川岛","FTE","捷成唯科","森萨塔","上海制动器"
            ],
      showBrand: false, //品牌弹框
      putList: [], //品牌弹框展示
      showProduct: false, //产品分类
      oenaturebrand: "",
      oesort: "",
      goodsMsgTotal: {},
      shopId: "",
      currentPage: 1,
      pageSize: 15,
      group_type: "",
      sort_type: "",
      sort_way: 1,
      storeText: "",
      showZero: false,
      searchData: {
        //筛选框选中列表
        city: [],
        oecarbrand: [],
        oenaturebrand: [],
        oesort: [],
        store: [],
      },
      result: "",
      clsList: [],
      attrList: [],
      goodsList: [],
      storeVis: false,
      gooodsHisList: [],
      activeSort: "",
      hisVis: false,
      dialogVisible: false,
      goods_id: "",
      buyStoreList: [],
      total: 0,
      storage_type:'',// 有货 无货
      timer:null,// 防抖的标志
    };
  },
  props: {
    chain: "",
  },
  async created() {
    // let url = "../assets/brand/11.png"
    // console.log("url",require(`../assets/brand/暂无图片1.png`))
    // this.putList = this.brandList
    this.shopId = this.$route.params.id;
    this.getList();

    // "产地","性质",
    let attr = ["OE号", "商品名称", "生产编码","库存", "配件品牌", "订货价"]; 
    // document.title = '华诺威_汽配供应链专家';
    if(this.$store.state.shop==1974||this.$store.state.shop==1871){
      attr = attr.filter(item => {
        return item!="库存"
      })
    }
    attr.forEach((title) => {
      let atrObj = this.$hnwTools.goodsAttrs.find(
        (item) => item.title == title
      );
      if (atrObj) {
        if (title == "OE号") {
          atrObj.method = "getGoodsInfo";
        }
        this.attrList.push(atrObj);
      }
    });
    
     this.putList= JSON.parse(JSON.stringify(this.brandList))
    //  console.log("this.putList", this.putList);
     // 获取从epc跳过来的品牌 
        // let activeBrand = localStorage.getItem("epc-brand-text")
        // let aliasRes =  await this.$api.getBrandAliasName({
        //   store_id:this.shopId
        // })
        // this.aliasList = aliasRes.data
        // if(activeBrand){
        //     this.getList(1,activeBrand)
        // }else{
        //     this.getList()
        // }
  },
  methods: { 
//     async setParams(storeText){
//             this.storeText = storeText
//             this.currentPage = 1
//             this.pageSize = 15 
//             let res2 = await this.$api.getFindList({
//                 shop:this.shopId, // 搜索哪家店铺
//                 is_chain:this.chain, // 商品类型 
//                 store_id:this.$store.state.store_id,
//                 member_id:this.$store.state.member_id,
//                 store_curpage:this.currentPage, 
//                 store_page:this.pageSize, // 每页多少个
//                 group_type:this.group_type, // 结果排序类型 oe 或者商家 用不着
//                 sort_type:this.sort_type, // 商品排序类型用哪个字段查询传哪个 例库存 
//                 sort_way:this.sort_way, // 排序方式 1倒序 0正序
//                 search:this.storeText, // 搜索内容
//                 type:screen?"screen":'', // 是否需要筛选分类
//                 storage_type:this.showZero?'ok':'', // 是否过滤0库存
//                 oenaturebrand:this.searchData.oenaturebrand.join(","),   // 商品品牌 通过返回的screen获取
//                 oesort:this.searchData.oesort.join(",") // 商品分类 通过返回的screen获取
//             })
//           this.putList = res2.screen.oenaturebrand;
//           this.goodsList = res2.data[0].goods_list
//           this.total = res2.data[0].total
//         },
    stopstop(){

    },
    //  分页
    async handleSizeChange(val) {
      await this.getList();

      scrollTo(0, 0);
    },
    handleCurrentChange(val) {
      this.getList();
    },
    async replaceJoinCart(carItem) {
      this.storeVis = false;
      if(this.timer){
        return
      }
      this.timer = setTimeout(async () => {
        if(this.$store.state.shop==1974){
                if(carItem.goods_storage==0){
                    carItem.goods_storage = 1
                }
            }
        let carMessage = {
        goods_id: carItem.goods_id,
        sccode: carItem.sccode,
        oecode: carItem.oecode,
        goods_name: carItem.goods_name,
        goods_price: carItem.goods_price,
        goods_storage: carItem.goods_storage,
        goods_num: carItem.goods_num,
        store_id: carItem.store_id,
        store_name: carItem.store_name,
        cart_type: "1",
      };

      await this.$api.joinCart([carMessage]);
      this.timer = null
      },100) 
    },
    sortChange(type, way, index) {
      this.sort_type = type;
      this.sort_way = way;
      this.activeSort = type + way;
      this.attrList[index].ckbox = !this.attrList[index].ckbox;
      this.getList();
    },
    async readHisList(goods_id) {
      // console.log("goods_id",goods_id) 
      this.$suMsg("请稍等");
      let res = await this.$api.getGoodsRecord(goods_id,this.chain,this.shopId);
      this.gooodsHisList = res;

      this.hisVis = true;
    },
    async readRpList(epcPart) { 
      this.storeVis = true;
      this.buyStoreList = epcPart.exchange || [];

      this.buyStoreList.forEach((item) => {
        this.$set(item, "goods_num", 1);
      });
    },
    // 加入购物车
    joinCart(carItem) {
        // console.log("carItem",carItem)  
        if(this.timer){
            return
        }
        this.timer = setTimeout(async ()=> {
            if(this.$store.state.store_id==this.shopId){
                return this.$warMsg("不能购买自己配件")
            }
            if(this.$store.state.zero_buy!=1&&this.$store.state.shop!=1974){
                if(carItem.goods_storage<1){
                  //  console.log("???",carItem.group_storage);
                  if(carItem.group_storage&&Number(carItem.group_storage)>0){
                      if(carItem.goods_num+Number(carItem.cartNum)>carItem.group_storage){
                        return this.$store.commit('UpDateState',{invDia:true})
                      }
                  }
                }else if(carItem.goods_num+Number(carItem.cartNum)>carItem.goods_storage){
                    return this.$store.commit('UpDateState',{invDia:true})
                }
            }
            if(this.$store.state.shop==1974||this.$store.state.shop==1871){
                if(carItem.goods_storage==0){
                    carItem.goods_storage = 1
                }
            }
            let carMessage = {
                goods_id:carItem.goods_id,
                sccode:carItem.sccode,
                oecode:carItem.oecode,
                goods_name:carItem.goods_name,
                goods_price:carItem.goods_price,
                goods_num:carItem.goods_num||1,
                goods_storage:carItem.goods_storage,
                group_storage:carItem.group_storage||"",
                buyer_id:'',
                order_name:'',
                store_id:carItem.store_id,
                member_id:'',
                buyer_name:'',
                store_name:carItem.store_name,
                cart_type:'1',
                setlist_id:'',
                groupbuy_id:'',
                promotion_id:''
            }
            let res = await this.$api.joinCart([carMessage])
            //更新记录中 已添加X件 字段
            carItem.cartNum = Number(carItem.cartNum) + Number(carItem.goods_num)
            this.timer = null
        },100)
       
    },
    impueNumChange(goods) {
        // console.log("goods_num",goods.goods_num)
            // group_storage
            if(isNaN(goods.goods_num)){
                
                
                this.$nextTick(()=>{
                    this.$set(goods,"goods_num",1)
                })
            }
            if(this.$store.state.shop!=1974&&this.$store.state.shop!=1871){
                if(this.chain==3){
                if(goods.goods_storage>0&&goods.goods_num>goods.goods_storage){
                    this.$nextTick(()=>{
                        goods.goods_num = goods.goods_storage
                    })
                    return this.$store.commit('UpDateState',{invDia:true})
                }else if(Number(goods.group_storage)>0&&goods.goods_num>Number(goods.group_storage)){
                    this.$nextTick(()=>{
                        goods.goods_num = Number(goods.group_storage)
                    })
                    return this.$store.commit('UpDateState',{invDia:true})
                }

            }else{
                if(goods.goods_storage>0&&goods.goods_num>goods.goods_storage){
                    this.$nextTick(()=>{
                        goods.goods_num = goods.goods_storage
                    })
                    return this.$store.commit('UpDateState',{invDia:true})
                }
            }
            }
            
        }, 
    getGoodsInfo(goods) { // 跳详情页 
            if(!goods.goods_id){
                return 
            }
            if(this.$store.state.shop!=this.shopId&&this.$store.state.store_id!=this.shopId&&this.$store.state.for_branch!=this.shopId){
                return this.$warMsg("暂无权限")
            }
            goods.preRou = "直营配件列表"
            localStorage.setItem("hnw-web-item-data",JSON.stringify(goods))
            this.$emit("showInfo")
    },
    // 产品分类的搜索
    searchResult() {
      if (this.result == "") {
        this.getList();
      } else {
        this.clsList = this.clsList.filter((item) => {
          return item.oesort.includes(this.result);
        });
      }
    },
    displayBrand() {
      this.showBrand = !this.showBrand;
      this.showProduct = false;
    },
    displayProduct() {
      this.showProduct = !this.showProduct;
      this.showBrand = false;
    },
    clearBrand() {
      this.putList = this.brandList;
      
      // this.showAll = true
      this.oenaturebrand = "";
      this.oesort = "";
      this.result = "";
      this.getList();
    }, 
            async getList(type,value){

            if(type==1){
                // 去掉数字
                this.oenaturebrand = value.replace(/\d/,'')
                this.oesort = ""
                // this.putList = this.brandList.slice(0,8)
                this.showBrand = false
                this.currentPage = 1

            }else if(type==2){ 
              this.storage_type = ""
                this.oesort = value
                this.showProduct=false
            }else if(type==3){
              this.currentPage = 1
              if(value=='ok'){
                  this.storage_type = 'gt'
              }else if(value=='no'){
                  this.storage_type = 'eq'
              }else{
                  this.storage_type = ""
              }
              // this.storage_type = value
            }
            //
            let sort_type  
            if(this.sort_type=='goods_storage'){
                sort_type = this.$store.state.is_sh=="0"?'goods_stcids':"goods_storage"
            }else{
                sort_type = this.sort_type
            }
            let  res = await this.$api.getBrandGoodsListNew({
                sort_key:sort_type||"goods_name",
                sort_value:this.sort_way==1?'ASC':'DESC',
                page:this.currentPage,
                limit:this.pageSize,
                shop_id:this.$store.state.shop, 
                store_id:this.$store.state.store_id,
                member_id:this.$store.state.member_id,
                stock:this.storage_type,
                oesort:this.oesort,
                search:this.oenaturebrand
            })
    //         let res = await this.$api.getBrandGoodsList({
    //             store_id:this.$store.state.store_id,
    //             shop_id:this.shopId,
    //             member_id:this.$store.state.member_id,
    //             curpage:this.currentPage,
    //             page:this.pageSize,
    //             sort_type:this.sort_type,
    //             sort_way:this.sort_way,
    //             oenaturebrand:this.oenaturebrand,
    //             oesort:this.oesort,
    //             storage_type:this.storage_type,
    //             shop_type:this.$store.state.shop_type
    //         }) 

            // 获取当前的品牌 
            if(this.$store.state.shop!=1871&&this.$store.state.shop!=1974){
                let res2 = await this.$api.getFindList({
                shop:this.shopId, // 搜索哪家店铺
                is_chain:this.chain, // 商品类型 
                store_id:this.$store.state.store_id,
                member_id:this.$store.state.member_id,
                store_curpage:this.currentPage, 
                store_page:this.pageSize, // 每页多少个
                group_type:this.group_type, // 结果排序类型 oe 或者商家 用不着
                sort_type:this.sort_type, // 商品排序类型用哪个字段查询传哪个 例库存 
                sort_way:this.sort_way, // 排序方式 1倒序 0正序
                search:this.storeText, // 搜索内容
                type:screen?"screen":'', // 是否需要筛选分类
                storage_type:this.showZero?'ok':'', // 是否过滤0库存
                oenaturebrand:this.searchData.oenaturebrand.join(","),   // 商品品牌 通过返回的screen获取
                oesort:this.searchData.oesort.join(",") ,// 商品分类 通过返回的screen获取
                shop_type:this.$store.state.shop_type
            })
 
            this.putList = res2.screen.oenaturebrand;
            }
 

            // if(type==1){

            //     if(res.goods.length==0){
            //         let brandAlis =  this.aliasList.find(item=>item.brand==this.oenaturebrand)
            //         if(brandAlis){
            //             console.log("当前品牌存在别名",brandAlis);
            //             if(brandAlis.like_name.split(",")[0]){
            //                 this.oenaturebrand = brandAlis.like_name.split(",")[0]
            //                 res = await this.$api.getBrandGoodsList({
            //                     store_id:this.$store.state.store_id,
            //                     shop_id:this.shopId,
            //                     member_id:this.$store.state.member_id,
            //                     curpage:this.currentPage,
            //                     page:this.pageSize,
            //                     sort_type:this.sort_type,
            //                     sort_way:this.sort_way,
            //                     oenaturebrand:this.oenaturebrand,
            //                     oesort:this.oesort,
            //                     storage_type:this.storage_type ,
            //                     shop_type:this.$store.state.shop_type
            //                 })
            //             }

            //         }else{
            //             console.log("未找到对应品牌别名");
            //         }
            //     }
            // }
            if(res.class){
                this.clsList = res.class.filter(oe=>oe)
            }else{
                this.clsList = []
            }
            this.goodsList = res.data

            if(res.stock){
                if(this.storage_type=='gt'){
                    this.total = res.stock.in_total
                }else if(this.storage_type=='eq'){
                    this.total = res.stock.not_total
                }else{
                    this.total = res.stock.total
                }
                this.goodsMsgTotal = res.stock
            }
            // this.storeInfo = res.store

            this.goodsList.forEach(goods=>{
                this.$set(goods,"choose",false)
                this.$set(goods,"goods_num",1)
                // 对电商价格处理

                if(!this.$store.state.token||this.$store.state.sc_id==14){
                    goods.goods_price = goods.goodprice2
                }else{
                    // goods.goods_price =  goods.goodprice1>0 ?goods.goodprice1 : goods.goods_price
                }
                goods.goods_price = this.$handlePrice(goods)
                 // 设置整箱单价
                if(this.$store.state.ratioBrandList){
                let obj = this.$store.state.ratioBrandList.find(brand=>{
                    if(brand.name==goods.oenaturebrand){
                        return brand
                    }
                })
                if(obj){
                    if(goods.spec){
                        let box = goods.spec.split("/")
                        // console.log("intact",box);
                        if(box[1]){
                            this.$set(goods,"boxNum",box[0])
                            goods.brand_ratio_price = this.$hnwTools.times(goods.goods_price,obj.ratio)
                        }
                    }
                }
              }

                if(!goods.cartNum){
                    goods.cartNum = 0
                }else{

                    // if(this.$store.state.zero_buy!=1){
                    //     if(goods.cartNum*1>goods.goods_storage*1){
                    //         goods.cartNum = goods.goods_storage
                    //     }
                    // }
                }
                if(!Array.isArray(goods.exchange)){
                    if(!goods.exchange){
                        goods.exchange = []
                    }else{
                        goods.exchange = [goods.exchange]
                    }
                }else{
                    goods.exchange.forEach(goods=>{
                        goods.goods_price = this.$handlePrice(goods)
                    })
                }
                this.$set(goods,"goodsorderInfo",'')
                if(!Array.isArray(goods.orderInfo)){
                    goods.goodsorderInfo = '1'
                }
            })

            // 选择品牌
            // let req = await this.$api.getBrandMsgTotal({
            //     oenaturebrand:this.oenaturebrand,
            //     shop_id:this.shopId,
            //     shop_type:this.$store.state.shop_type
            // })
            // this.goodsMsgTotal = res.total

        },
  },
};
</script>

<style lang="scss" scoped>
.brand-index {
  width: 1200px;
  margin: 0 auto;
  position: relative;
  padding-top: 10px;
  // position: relative;
  .brand-logo{
    width: 100%; 
    display: flex;
    flex-wrap: wrap; 
    .activeClass{
      border:1px solid #50a3f8
    }
    .img-box-style {
      width: 141px;
      height: 56px;
      display: flex;
      cursor: pointer;
      margin:0 4px 6px; 
      justify-content: center;
      align-items: center;
      background: #fff;
      flex-direction: column;
      justify-content: space-between;
      img {
        // margin-top: 8px;
        max-height: 30px;
        max-width: 90px;
        margin: auto;
      }
      .img-txt {
        width: 107px;
        height: 15px;
        border-top: 1px solid #ccc;
        text-align: center;
        line-height: 5px;
        padding-top: 8px;
        color: #666;
      }
    }
    // .img-box-style:hover {
    //   border:1px solid #3E84D6; 
    // }
  }
   
  .brand-msg {
    height: 40px;
    position: relative;
    background-color: #97c3f1;
    // margin: 10px 0;
    box-sizing: border-box;
    // line-height: 40px;
    display: flex;
    color: #fff;

    .close-btn {
      cursor: pointer;
      position: absolute;
      top: 7px;
      right: 34px;
      width: 42px;
      height: 26px;
      line-height: 26px;
      text-align: center;
      background: #ffffff;
      border-radius: 6px;
      font-size: 14px;
      font-weight: bold;
      color: #50a3f8;
    }
    .brandName{
      position: relative;
      .brand{
        font-size: 16px;
        line-height: 40px;
        font-weight: bold;
        margin-left: 20px;
      }
      .brand-cls {
      position: absolute;
      top: 40px;
      left: -8px;
      z-index: 99;
      // height: 214px;
      width: 570px;
      // overflow-y: auto;
      border: 1px solid #ccc;
      padding: 20px;
      background-color: #fff;
      // display: flex;
      // flex-wrap: wrap;
      // align-items: center;
      .jiao {
        position: absolute;
        top: -8px;
        left: 116px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 4px 8px 4px;
        border-color: transparent transparent #fff transparent;
      }
      .brand-search {
        display: flex;
        .el-input {
          .el-input__inner {
            height: 32px;
            line-height: 32px;
            border: 1px solid #498fff;
            border-radius: 2px 0 0 2px;
          }
        }
        .brand-btn {
          width: 74px;
          height: 38px;
          font-size: 14px;
          color: #fff;
          text-align: center;
          line-height: 38px;
          background: #498fff;
          border: 1px solid #498fff;
          border-radius: 0 2px 2px 0;
        }
      }
      .brand-info {
        margin-top: 5px;
        display: flex;
        flex-wrap: wrap;
        .cls-item {
          margin: 0 10px;
          padding: 5px 0;
          cursor: pointer;
          color: black;
          // width: 98px;
        }
        .active {
          color: #3E84D6;
        }
      }
  }
    }

    .brand-name {
      position: relative;
      width: 143px;
      text-align: center;
      display: flex;
      .brand-cls {
      position: absolute;
      top: 40px;
      left: -8px;
      z-index: 99;
      // height: 214px;
      width: 570px;
      // overflow-y: auto;
      border: 1px solid #ccc;
      padding: 20px;
      background-color: #fff;
      // display: flex;
      // flex-wrap: wrap;
      // align-items: center;
      .jiao {
        position: absolute;
        top: -8px;
        left: 116px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 4px 8px 4px;
        border-color: transparent transparent #fff transparent;
      }
      .brand-search {
        display: flex;
        .el-input {
          .el-input__inner {
            height: 32px;
            line-height: 32px;
            border: 1px solid #498fff;
            border-radius: 2px 0 0 2px;
          }
        }
        .brand-btn {
          width: 74px;
          height: 38px;
          font-size: 14px;
          color: #fff;
          text-align: center;
          line-height: 38px;
          background: #498fff;
          border: 1px solid #498fff;
          border-radius: 0 2px 2px 0;
        }
      }
      .brand-info {
        margin-top: 5px;
        display: flex;
        flex-wrap: wrap;
        .cls-item {
          margin: 0 10px;
          padding: 5px 0;
          cursor: pointer;
          color: black;
          // width: 98px;
        }
        .active {
          color: #3E84D6;
        }
      }
  }
      .brand-select {
        padding: 10px 0 10px;
        cursor: pointer;
      }

      .brand-checked {
        width: 80px;
        height: 22px;
        background-color: #fff;
        color: #50a3f8;
        text-align: center;
        line-height: 22px;
        margin-left: 28px;
        border-radius: 2px;
      }
    }
    .line {
      margin-top: 20px;
      width: 2px;
      height: 27px;
      background: #ffffff;
      opacity: 0.2;
      padding: 0;
      margin-right: 20px;
    }
    .brand-names {
      width: 95px;
      text-align: center;
      .brand-parts {
        padding: 10px 0 8px;
        font-size: 14px;
      }
      .parts-text {
        font-size: 18px;
        font-family: DFPShouJinW3-GB;
        font-style: oblique;
      }
    }
    > div {
      padding: 0 10px;
      cursor: pointer;
    }
    > div:first-child {
      cursor: default;
    }
    .close-btn {
      cursor: pointer;
      position: absolute;
      top: 7px;
      right: 34px;
      width: 42px;
      height: 26px;
      line-height: 26px;
      text-align: center;
      background: #ffffff;
      border-radius: 6px;
      font-size: 14px;
      font-weight: bold;
      color: #50a3f8;
    }
  }
  

  // 该品牌下的分类
  .goods-list {
    box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.13);
    .list-head {
      display: flex;
      height: 40px;
      align-items: center;
      background: #d3d7d9;
      margin: 10px 0 0 0;
      .head-item {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        .filterZero {
          position: absolute;
          top: 15px;
        }
        .sort {
          // display: flex;
          // flex-direction: column;
          // justify-content: center;
          // margin-left: 2px;
          width: 12px;
          height: 12px;
          img {
            width: 12px;
            height: 12px;
          }
        }
        i {
          cursor: pointer;
          font-size: 12px;
        }
        i:hover {
          transform: scale(1.5);
          color: #3E84D6;
        }
        .el-icon-caret-top {
          position: relative;
          top: 2px;
        }
        .el-icon-caret-bottom {
          position: relative;
          top: -2px;
        }
        .active {
          color: #3E84D6;
        }
      }

      .oecode {
        // min-width: 180px;
        min-width: 238px;
      }
      .goods_name {
        min-width: 240px !important;
        max-width: 160px;
      }
      .sccode{
        // width: 120px !important;
      }
      .buy-nums {
        max-width: 120px;
        min-width: 120px;
      }
      .handle {
        min-width: 100px;
        max-width: 100px;
        line-height: 22px;
      }
    }
    .list-body {
      // border-top: 1px solid #9E9E9E;
      .list-goods {
        // padding: 15px 0;

        position: relative;
        // border-bottom: 1px solid #CCCCCC;
        // background: #f6f6f7;
        // &:nth-child(odd){
        //   background: #f6f6f7;
        // }
        .row {
          display: flex;
          height: 72px;
          // padding-bottom: 10px;
          .list-rol {
            flex: 1;
            text-align: center;
            line-height: 72px;
            height: 72px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            border-right: 1px solid #e3e4ea;
            position: relative;
            .blue {
              color: #3E84D6;
            }
            .vehicle {
              width: 230px;
              height: 38px;
              position: absolute;
              bottom: 0;
              left: 10px;
              overflow: hidden;
              text-align: left;
              color: #999999;
              font-style: 12px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
          .oecode {
            // min-width: 180px;
            line-height: 50px;
            min-width: 247px;
            display: flex;
            box-sizing: border-box;
            // padding-left: 15px;
            align-items: center;
            cursor: pointer;
            .brand {
              min-width: 48px;
              max-width: 48px;
              height: 20px;
              margin: 0 10px;
              background: #3E84D6;
              text-align: center;
              line-height: 20px;
              color: #fff;
            }
            .tag-icon {
              display: flex;
              align-items: center;
              transform: scale(0.7);
              justify-content: center;
              width: 20px;
              height: 20px;
              background: $vin-blue;
              color: #fff;
              // border: 1px solid $vin-blue;
              border-radius: 50%;
              font-size: 12px;
              position: relative;
              top: -5px;
              left: 2px;
            }
          }
          .goods_name {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            min-width: 225px;
            max-width: 160px;
          }
          .buy-nums {
            min-width: 120px;
            max-width: 120px;
            position: relative;
            .el-input-number {
              width: 110px;
            }
            .goods-Fcl-tit {
              position: absolute;
              left: 50%;
              transform: translateX(-50%);
              bottom: 2px;
              font-size: 12px;
              line-height: 12px;
              width: 120px;
              text-align: center;
              color: #999;
            }
          }
          .handle {
            line-height: 16px;
            min-width: 100px;
            max-width: 100px;
            justify-content: center;
            flex-wrap: wrap;
            align-items: center;
            padding-top: 5px;
            padding-left: 10px;
            box-sizing: border-box;
            > div {
              margin-top: 2px;
            }
            .btn {
              width: 80px;
              height: 22px;
              display: flex;
              justify-content: center;
              border: 1px solid #3E84D6;
              color: #3E84D6;
              align-items: center;
              border-radius: 5;
              cursor: pointer;
              margin-top:19px;
            }
            .cart-num {
              width: 80px;
              height: 16px;

              font-size: 12px;
              color: #999999;
            }
            .replace-btn {
              width: 80px;
              height: 16px;
              color: #59a8f8;
              text-align: center;
              cursor: pointer;
            }
          }
        }
        .row-two {
          border-top: 1px solid #e3e4ea;
          height: 31px;
          padding-bottom: 0px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          > div {
            color: #999999;
          }

          .buyHis {
            // font-style: italic;
            cursor: pointer;
            text-align: right;
            flex: 1;
            padding: 0 5px;

            color: red;
            // color: #999;

            > span {
              margin-right: 20px;
              align-items: center;
            }
            .readMore {
              cursor: pointer;
            }
          }
          .han-box {
            display: flex;

            text-align: center;
            .col-num {
              min-width: 120px;
            }
            .handle {
              flex: 1;
              flex-direction: column;
              min-width: 100px;
              max-width: 100px;
              line-height: 22px;
              position: relative;
              > div {
                left: 10px;
              }
              .cart-num {
                position: absolute;
                top: -10px;
              }
              .replace-btn {
                color: #3E84D6;
                cursor: pointer;
                position: absolute;
                bottom: -10px;
              }
            }
          }
        }
      }
      .list-goods:nth-child(2n+1) {
        background: white;
      }
    }
  }
  .el-dialog__body {
    // padding: 10px 20px;
    padding: 0 !important;
    .his-list {
      .list-head {
        display: flex;
        height: 40px;
        align-items: center;
        background: #eeeeee;
        margin: 10px 0;
        padding: 0 8px;
        > div {
          flex: 1;
          text-align: center;
        }
        .col-100 {
          max-width: 100px;
        }
      }
      .list-body {
        .row {
          display: flex;
          padding: 0 8px;
          > div {
            flex: 1;
            padding: 2px 0;
            border-bottom: 1px solid #eee;
            flex: 1;
            text-align: center;
            line-height: 28px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .col-100 {
            max-width: 100px;
          }
        }
      }
    }
  }
  .hezi {
    .el-dialog__wrapper {
      .el-dialog {
        width: 760px;
        .el-dialog__header {
          position: relative;
          padding: 10px 20px 20px;
          .el-icon-close:before {
            position: absolute;
            top: -10px;
            right: 0px;
          }
        }
        .el-dialog__body {
          overflow-y: auto;
          height: 500px;
          padding: 0 10px;
          box-sizing: border-box;
        }
      }
    }
  }
  // 互换件
  .store-vis {
    .el-dialog {
      .tit {
        padding: 10px;
        background: #3E84D6;
        color: #fff;
        display: flex;
        justify-content: space-between;
        .el-icon-close {
          cursor: pointer;
          color: #fff;
        }
      }
      .list-body {
        padding: 10px;
        .list-head {
          display: flex;
          background: #dfdfdf;
          height: 28px;
          line-height: 28px;
          > div {
            text-align: center;
            flex: 1;
          }
          .name {
            min-width: 120px;
          }
          .oe {
            min-width: 140px;
          }
          > div:first-child {
            text-align: left;
            padding-left: 10px;
          }
        }
        .list-row {
          display: flex;
          align-items: center;
          border-bottom: 1px solid#ccc;
          > div {
            text-align: center;
            flex: 1;
            padding: 8px 0;
            display: flex;
            justify-content: center;
            align-items: center;
            .btn {
              cursor: pointer;
              color: #3E84D6;
            }
          }
          > div:first-child {
            text-align: left;
            padding-left: 10px;
            justify-content: flex-start;
          }
          .tag-icon {
            display: flex;
            align-items: center;
            transform: scale(0.9);
            justify-content: center;
            width: 17px;
            height: 17px;
            border: 1px solid #ff781c;
            border-radius: 50%;
            color: #ff781c;
            margin-right: 5px;
            font-size: 12px;
          }
          .buy-nums {
            .el-input-number {
              width: 100%;
            }
          }
          .name {
            min-width: 120px;
          }
          .oe {
            min-width: 140px;
          }
        }
      }
    }
  }
  .paging {
    margin-top: 45px;
    display: flex;
    justify-content: center;
    margin: 15px 0;
    .database {
      line-height: 30px;
    }
  }
}
</style>