<template>
<div class="union-home w">
    <img :src="banner" alt="">
    <div class="home-modu" v-if="clearGoodsList.length>0">
        <div class="modu-title">
           <img src="../../assets/union/clear.png" alt="">
        </div>
        <div class="modu-content clear-modu" >
            <div class="goods-list">
                <div class="goods-item" @click="showGoodsInfo(goods)" v-for="(goods,index) of clearGoodsList" :key="index" :class="{'disbld':!goods.goods_id}">
                    <div class="img-box" v-if="goods.goods_id">
                        <img :src="goods.goods_image" alt="">
                    </div>
                    <div class="goods-info" v-if="goods.goods_id">
                        <div class="goods-name">{{goods.goods_name}}</div>
                        <div class="price">
                            
                            <span >
                                <span class="symbol">￥</span>{{goods.taxStoPrice2}}
                                <span class="old-price">
                                    ￥{{goods.goods_price}}
                                </span>
                            </span>
                            <!-- <span v-else>
                                会员可见
                            </span> -->
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="home-modu home-hot" v-if="hotGoodsList.length>0">
        <div class="modu-title">
            <img src="../../assets/union/hotit.png" alt="">
        </div>
        <div class="modu-content hot-modu" >
            
            <div class="hot-content">
               
                
                <div class="hot-goods">
                    <div class="hot-goods-left">
                        <div class="goods-item" @click="showGoodsInfo(hotGoodsList[0])" >
                            <div class="img-box">
                                <img :src="hotGoodsList[0].goods_image" alt="">
                            </div>
                            <div class="goods-info" v-if="hotGoodsList[0]">
                                <div class="goods-name">{{hotGoodsList[0].goods_name}}</div>
                                <div class="goods-bottom">
                                    <div class="bottom-left">
                                        <div class="price-row">
                                            <span >
                                                <span class="now-price">
                                                    <span class="symbol">￥</span><span class="price">{{hotGoodsList[0].goods_price}}</span>
                                                </span>
                                                
                                                <span class="old-price">
                                                    <span class="symbol">￥</span><span class="price">{{$hnwTools.times(hotGoodsList[0].goods_price,1.2)}}</span>
                                                </span>
                                            </span>
                                            <!-- <span v-else>
                                                会员可见
                                            </span> -->
                                            
                                        </div>
                                        <div class="desc">
                                            店铺热销商品第一名
                                        </div>
                                    </div>
                                    <div class="read-btn">
                                        <img src="../../assets/union/right.png" alt="">
                                    </div>
                                </div>
                                <div class="item-bottom">
                                
                                    
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="hot-goods-right">
                        <div class="propag-img">
                            <img src="../../assets/union/hotGoods.png" alt="">
                        </div>
                        
                        <div class="hot-goods-list">
                            <div class="goods-item" @click="showGoodsInfo(goods)" v-for="(goods,index) of hotGoodsList" :class="reClass(index)" :key="index">
                                <div class="img-box">
                                    <img :src="goods.goods_image" alt="">
                                </div>
                                <div class="goods-info" v-if="goods.goods_id">
                                    <!-- {{goods.goods_name}} -->
                                    <div class="goods-name" :title="goods.all_goods_name?goods.all_goods_name:goods.goods_name">
                                        <div v-for="s in goods.goods_name" :key="s">
                                            {{s}}
                                        </div>
                                        <div class="ess" v-if="goods.all_goods_name">
                                            <div>.</div>
                                            <div>.</div>
                                            <div>.</div>
                                        </div>
                                    </div>
                                    <div class="goods-bottom">
                                        <div class="bottom-left">
                                            <div class="price-row">
                                                <div >
                                                    <div class="old-price">
                                                        <span class="symbol">￥</span><span class="price">{{$hnwTools.times(goods.goods_price,1.2)}}</span>
                                                    </div>
                                                    <div class="now-price">
                                                        <span class="symbol">￥</span><span class="price">{{goods.goods_price}}</span>
                                                    </div>
                                                    
                                                    
                                                </div>
                                                
                                                
                                            </div>
                                            
                                        </div>
                                        <div class="read-btn">
                                            <img src="../../assets/union/right.png" alt="">
                                        </div>
                                    </div>
                                    <div class="item-bottom">
                                    
                                        
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
    <div class="home-modu w recommend-home">
        <div class="modu-title">
            <img src="../../assets/union/brand.png" alt="">
        </div>
        <div class="modu-content recommend-modu" v-if="recommendGoodsList.length>0">
            
            
            <div class="goods-list">
                <div class="goods-item" @click="showGoodsInfo(goods)" v-for="(goods,index) of recommendGoodsList"  :key="index">
                    <div class="img-box">
                        <img :src="goods.goods_image" alt="">
                    </div>
                    <div class="goods-info" v-if="goods.goods_id">

                        <div class="price-row">
                            <span >
                                <span class="now-price">
                                    <span class="symbol">￥</span><span class="price">{{goods.goods_price}}</span>
                                </span>
                                
                                <span class="old-price">
                                    <span class="symbol">￥</span><span class="price">{{$hnwTools.times(goods.goods_price,1.2)}}</span>
                                </span>
                            </span>
                            <!-- <span v-else>
                                会员可见
                            </span> -->
                            
                        </div>
                        <div class="item-bottom">
                            <div class="goods-name">{{goods.goods_name}}</div>
                            <div class="read-btn">
                                点击查看
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
</div>
</template>

<script>
export default {
    data(){
        return{
            clearGoodsList:[],
            hotGoodsList:[],
            recommendGoodsList:[],
            shopId:"",
            banner:""
        }

    },
    methods:{
        async showGoodsInfo(goods){
          if(!goods.goods_id){
            return 
          }
        //   if(this.$store.state.shop!=this.shopId&&this.$store.state.store_id!=this.shopId){
        //     return this.$warMsg("暂无权限")
        //   }
          goods.preRou = "联盟调货"
          localStorage.setItem("hnw-web-item-data",JSON.stringify(goods))
          this.$router.push("/union/goodsinfo")
      },
    //   热销类名
        reClass(index){
            if(index==0){
                return "first"
            }
        }
    },
    async created(){
        let bannerRes = await this.$api.getHomeBanner({
            source: 5
        })
        if(bannerRes&&bannerRes.length>0){
            this.banner = bannerRes[0].adv_pic
        }
        this.shopId = this.$route.params.id
        
        // 获取清库存 4
        this.clearGoodsList = await this.$api.getlistRecom({
            goods_commend:3,
            is_online:1,
            is_chain:2
        })
        // 获取热销 5
        this.hotGoodsList = await this.$api.getlistRecom({
            goods_commend:4,
            is_online:1,
            is_chain:2
        })
        // 推荐 8
        let res = await this.$api.getlistRecom({
            goods_commend:1,
            is_online:1,
            is_chain:2
        })
        // let res= await this.$api.getShopRecommend({

        //   curpage:this.currentPage,
        //   page:999,
        //   is_online:1,
        //   type:"recommend",
        //   is_chain:2
        // })
        if(res){
            this.recommendGoodsList = res
        }
        
        if(this.clearGoodsList.length>4){
            this.clearGoodsList = this.clearGoodsList.slice(0,4)    
        }else if(this.clearGoodsList.length>0&&this.clearGoodsList.length<4){
            for (let index = 0; index < 4-this.clearGoodsList.length; index++) {
                this.clearGoodsList.push({
                    name:""
                })
            }
        }
        if(this.hotGoodsList.length>3){

            this.hotGoodsList = this.hotGoodsList.slice(0,3)    
        }else if(this.hotGoodsList.length>0&&this.hotGoodsList.length<3){
            for (let index = 0; index < 3-this.hotGoodsList.length; index++) {
                this.hotGoodsList.push({
                    name:""
                })
            }
        }
        if(this.hotGoodsList){
            this.hotGoodsList.forEach(goods=>{
                if(goods.goods_name.length>8){
                    goods.all_goods_name = goods.goods_name
                    goods.goods_name = goods.goods_name.slice(0,7)
                }
            })
        }
        // 平台价格处理
        let handNames = ["recommendGoodsList","hotGoodsList","clearGoodsList"]
        // handNames.forEach(arr=>{
        //     if(this[arr]&&this[arr].length>0){
        //         this[arr].forEach(goods=>{
        //             goods.goods_price = goods.taxUntPrice || goods.goods_price
        //         })
        //     }
        // })
    },
    props:{
        
    }
}
</script>

<style lang="scss">

$xyblue:#1B458B;
$xyow:#FF9E20;
.union-home{
    padding-top: 10px;
    // .band{
    //     margin: 0 auto;
    //     width: 1200px;
    //     margin-top: 10px;

    //     img{
    //     width: 1200px;
    //     }
    // }
    .home-modu{
        // background: #fff;
        .modu-title{
            text-align: center;
            padding-top: 10px;
            padding-bottom: 5px;
        }
        .modu-content{
            padding: 10px 0;
            >div{

            }
        }
        .goods-item{
            cursor: pointer;
        }
        // 清库存
        .clear-modu{
            .goods-list{
                display: flex;
                justify-content: space-between;
                .goods-item{
                    background: #fff;
                    width: 286px;
                    border: 1px solid #739FE9;
                    border-radius:24px;
                    .img-box{
                        width: 100%;
                        height: 286px;
                        overflow: hidden;
                        img{
                            width: 286px;
                            border-radius: 24px 24px 0px 24px;
                        }
                    }
                    .goods-info{
                        padding: 10px 15px;
                        border-radius: 24px;
                        background:#e7ecf0;
                        .price{
                            color: $xyow;
                            // font-size: 16px;
                            font-size: 24px;
                            span{
                            }
                            .symbol{
                                font-size: 12px;
                            }
                        }
                        .old-price{
                            text-decoration: line-through;
                            color: #C1C1C1;
                            font-size: 12px;
                        }
                        .goods-name{
                            color: #43659a;
                            font-size: 24px;
                            padding: 5px 0;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }
                    }
                }
                .disbld{
                    background: #f7f7f7;
                    border: none;
                }
            }

        }
        // 热销商品
        .hot-modu{
            
            // height: 462px;
            box-sizing: border-box;
           
            .hot-content{
                height: 100%;
                .hot-goods{
                    display: flex;
                    .goods-item{
                        background: #fff;
                    }
                    .hot-goods-left{
                        width: 330px;
                        .goods-item{
                            height:462px;
                            width: 330px;
                            border-radius: 15px;
                            margin-top: 10px;
                           
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                            .img-box{
                                text-align: center;
                                // height: 336px;
                                overflow: hidden;
                                width: 336px;
                                height: 360px;
                                img{
                                    width: 284px;
                                
                                }
                            }
                            .goods-info{
                                // background: $xyblue;
                                background: #fff;
                                padding: 16px 8px;
                                display: flex;
                                flex-direction: column;
                                justify-content: space-between;
                                flex: 1;
                                box-sizing: border-box;
                                
                                .goods-name{
                                    font-size: 22px;
                                    color: #1B458B;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    white-space: nowrap;
                                }
                                .goods-bottom{
                                    display: flex;
                                    flex: 1;
                                    align-items: flex-end;
                                    justify-content: space-between;
                                    .bottom-left{
                                        
                                        .price-row{
                                            color: #fff;
                                            padding: 5px 0;
                                            span{
        
                                            }
                                            .now-price{
                                                color: $xyow;
                                                font-size: 18px;
                                                .symbol{
                                                    font-size: 14px;
                                                }
                                            }
                                            .old-price{
                                                text-decoration: line-through;
                                                color: #C1C1C1;
                                                font-size: 12px;
                                            }
                                        }
                                        .read-btn{

                                        }
                                        .desc{
                                            line-height: 16px;
                                            font-size: 16px;
                                        }
                                    }
                                }
                                .item-bottom{
                                    display: flex;
                                    align-items: center;
                                    justify-content: space-between;
                                    
                                    
                                    .read-btn{
                                        background: $xyow;
                                        color: #fff;
                                        padding: 5px ;
                                        cursor: pointer;
                                    }
                                }
                            }
                        }
                    }
                    .hot-goods-right{
                        flex: 1;
                        padding-left: 15px;
                       
                        .propag-img{
                            padding-bottom: 10px;
                        }
                        .hot-goods-list{
                            display: flex;
                            justify-content: space-between;
                            .goods-item{
                                width: 420px;
                                height: 289px;
                                border-radius: 15px;
                                margin-top: 10px;
                            
                                display: flex;
                                
                                justify-content: space-between;
                                .img-box{
                                    text-align: center;
                                    height: 289px;
                                    width: 289px;
                                    text-align: center;
                                    overflow: hidden;
                                    img{
                                        width: 284px;
                                    
                                    }
                                }
                                .goods-info{
                                    // background: $xyblue;
                                    background: #fff;
                                    padding: 16px 8px;
                                    
                                    flex: 1;

                                    .goods-name{
                                        font-size: 20px;
                                        line-height: 20px;
                                        color: #1B458B;
                                        height: 160px;
                                        max-height: 160px;
                                        text-align: center;
                                        overflow: hidden;
                                        position: relative;
                                        .ess{
                                            position: absolute;
                                            font-size: 12px;
                                            line-height: 5px;
                                            bottom: 3px;
                                            left: 50%;
                                            transform: translateX(-50%);
                                            font-weight: 700;
                                        }
                                    }
                                    .goods-bottom{
                                        text-align: center;
                                        .bottom-left{
                                            
                                            .price-row{
                                                padding-top: 10px;
                                                color: #fff;
                                                
                                                span{
            
                                                }
                                                .now-price{
                                                    color: $xyow;
                                                    font-size: 18px;
                                                    padding: 5px 0 20px 0;
                                                    .symbol{
                                                        font-size: 14px;
                                                    }
                                                }
                                                .old-price{
                                                    text-decoration: line-through;
                                                    color: #C1C1C1;
                                                    font-size: 12px;
                                                }
                                            }
                                            .read-btn{

                                            }
                                            .desc{
                                                line-height: 16px;
                                                font-size: 16px;
                                            }
                                        }
                                    }
                                    .item-bottom{
                                        display: flex;
                                        align-items: center;
                                        justify-content: space-between;
                                        
                                        
                                        .read-btn{
                                            background: $xyow;
                                            color: #fff;
                                            padding: 5px ;
                                            cursor: pointer;
                                        }
                                    }
                                }
                            }
                            .first{
                                display: none;
                            }
                        }
                    }
                }
            }
            
        }
    }
    .recommend-home{
        position: relative;
       
        padding-top: 10px;
        .modu-title{
            color: #fff;
            
           
            position: relative;
            z-index: 5;
        }
        
        .goods-list{
            display: flex;
            flex-wrap: wrap;
            
            justify-content: space-between;
            .goods-item{
                width: 284px;
                border-radius: 15px;
                margin-top: 10px;
                .img-box{
                    img{
                        width: 284px;
                       
                    }
                }
                .goods-info{
                    // background: $xyblue;
                    background: #fff;
                    padding: 16px 8px;
                    .price-row{
                        color: #fff;
                        span{

                        }
                        .now-price{
                            color: $xyow;
                            .symbol{
                                font-size: 12px;
                            }
                        }
                        .old-price{
                            text-decoration: line-through;
                            color: #C1C1C1;
                            font-size: 12px;
                        }
                    }
                    .item-bottom{
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        
                        .goods-name{
                            font-size: 16px;
                            
                        }
                        .read-btn{
                            background: $xyow;
                            width: 66px;
                            color: #fff;
                            padding: 5px ;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
    .home-hot{
        .modu-title{
            margin: 0 auto;
            box-sizing: border-box;
            
        }
    }
}
</style>