import axios from "axios";

const fetch = async (url,data)=>{
    let hj =  axios.defaults.baseURL
    let res = ""
  
     
        // res = await axios({
        //     method:"get",
        //     url: hj + url,  
        //     headers:{'Content-Type': 'application/x-www-form-urlencoded'}, 
        //     params:data
        // })

        var xhr = new XMLHttpRequest();
        xhr.open('GET',hj+url, true);  // 设置请求方法、URL、是否异步
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');  // 设置请求头
        // xhr.onreadystatechange = function() {  // 设置响应处理函数
        // if (xhr.readyState === 4 && xhr.status === 200) {
        //     // var response = JSON.parse(xhr.responseText);
        //     console.log(xhr.responseText);
        //     res = xhr.responseText
        // }
        // };
        xhr.send();  // 发送请求

        res = await new Promise((rep,rej)=>{
            xhr.onreadystatechange = function () {
                if (xhr.readyState == 4 && xhr.status == 200) {
                    let json = xhr.responseText;//获取到json字符串，还需解析
                    try{
                        json = JSON.parse(json)
                        rep(json)
                    }catch(e){
                        console.error(e)
                    //    $erMsg("解析失败")
                        rej(e)
                    }
                }
            };
        })

        return res
        }

const getGoodsInfoReplace = async (data)=>{
    return await fetch(`index.php?act=web_index&op=inter&oecode=${data.oecode}`)
}

const apiGET = {
    getGoodsInfoReplace
}

export default apiGET