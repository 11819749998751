<template>
    <div class="inte-raffle">
      <LuckyWheel
        class="zp-box"
        ref="myLucky"
        width="478px"
        height="443px"
        :prizes="prizeList"
        :blocks="blocks"
        :buttons="buttons"
        @start="startCallback"
        @end="endCallback"
      />
      <div class="tips" >
        {{ goldNum }}积分/次

      </div>
    </div>
  </template>
  
  <script>
  
  export default {
    data () {
      return {
        blocks: [
          { 
            padding: '10px',
            imgs:[
              {
                
                src:require("../../../assets/integral/zhuanpan.png")
              }
            ]
          }
        ],
        prizeList: [
        ],
        buttons: [
          { 
            pointer: true,
            radius:"35%",
            
            imgs: [
              {
                src:require("../../../assets/integral/anniu.png"),
                top:"-60px",
                width:80,
                height:101
              }
            ],
          },
        ],
        goldNum:10
      }
    },
    methods: {
      // 点击抽奖按钮会触发star回调
      startCallback () {
        if (this.$store.state.integral_num >= this.goldNum) {
          // 调用抽奖组件的play方法开始游戏
          this.$refs.myLucky.play()
          // 模拟调用接口异步抽奖
          this.remoteGetPrizeIndex()
        } else {
          this.$warMsg("积分不足")
          
        }
        
        // setTimeout(() => {
        //   // 假设后端返回的中奖索引是0
        //   const index = 0
        //   // 调用stop停止旋转并传递中奖索引
          
        // }, 3000)
        
      },
      // 抽奖结束会触发end回调
      endCallback (prize) {
        console.log("prize",prize)
        let prizeName = prize.title
        let tipContent = ''
        console.log("prize",prize);
        if (prize.type==1 ) {
          tipContent = '很遗憾，没有中奖！'
          this.$warMsg(tipContent)
        } else {
          tipContent = `恭喜您，获得 ${prizeName} !`
          this.$suMsg(tipContent)
        }
        
        this.$store.dispatch("getInteNum")
      },
      async getPrizeList () {
       	
        // 等待接口返回的数据进一步处理
        let res = await this.$api.getRamInteList({
            buyer_id:this.$store.state.store_id,
            member_id:this.$store.state.member_id
        })
        this.prizeList = []
        if(res&&res.db&&res.db.length>0){
            res.db.forEach(item=>{
                this.prizeList.push({
                    fonts:[
                        {
                            text: item.title,
                            fontColor:"#DC2224",
                            top:40,
                        }
                    ],
                    imgs:[
                        {
                            src:item.image,
                            top:70,
                            // width:75,
                            // height:75
                        }
                    ],

                    title:item.title,
                    type:item.type,
                    prizeId:item.id	
                })
            })
            
        }else{
            this.$warMsg("获取失败")
        }
      },
      async remoteGetPrizeIndex () {
        let res = await  this.$api.createRaintOrder({
          buyer_id:this.$store.state.store_id,
          buyer_name:this.$store.state.store_name,
          member_id:this.$store.state.member_id,
          member_name:this.$store.state.member_name,
        })
        
        // 拿到后端返回的 prizeId 后，开始循环比对得出那个中奖的数据
        for (let i = 0; i < this.prizeList.length; i++) {
                      
          let item = this.prizeList[i]
          if (item.prizeId == res) {
            // 中奖下标

            // this.prizeIndex = i  
                this.$refs.myLucky.stop(i)
          }
        }
	      	
      },
      test(){
        
        //  张傲杰的测试   
        for (let index = 0; index < 1000; index++) {
          setTimeout(() => {
            let member_name = "" 
            let member_id = this.$store.state.store_id 
            if(index%2==0){
              member_name = "张傲杰的测试"
              member_id += "-397"
            }else{
              
              member_id += "-1115"
              member_name = "外网链接"
            }
            this.$api.createRaintOrder({
              buyer_id:this.$store.state.store_id,
              buyer_name:this.$store.state.store_name,
              member_id,
              member_name
          })
          console.log("开始",index,"次");
          
          }, index*500);
          
        }
      }
    },
    async created(){
        this.getPrizeList()
        let res = await this.$api.getRamInteConf()
	      this.goldNum = res
        
        
    }
  }
  </script>
<style lang="scss">
.inte-raffle{
  height: 476px;
  background: url("../../../assets/integral/cjback1.png");
  position: relative;
  .zp-box{
    
    position: absolute;
    bottom: 0;
    right: 72px;
  }
  .tips{
    position: absolute;
    bottom: 180px;
    left: 120px;
    color: #fff;
    width: 166px;
    height: 52px;
    line-height: 52px;
    text-align: center;
    font-size: 22px;
  }
}
</style>