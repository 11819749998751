<template>
  <div class="store-intr-info"  v-if="!loading">
    <div class="hnw-goods-detail" >
        <div class="top">
          <div class="hnw-area-center" >
            <div class="title">
              <span style="padding-left: 10px;" class="" @click="reBack" v-if="itemData.preRou=='店铺'">
                  {{itemData.preRou}}
                <i class="el-icon-arrow-right"></i>
              </span>
              <span class="" @click="reBack" v-else>
                  {{itemData.preRou}}
                <i class="el-icon-arrow-right"></i>
              </span>
              <!-- <span class="" @click="goBus"  v-if="itemData.preRou">
                  {{goods.store_sup.shop_name}}
                <i class="el-icon-arrow-right" ></i>
              </span> -->
              
              <span class="" @click="reBack" v-if="itemData.preRou=='企业B2b'">
                  {{goods.shop_name}}
                <!-- <i class="el-icon-arrow-right"></i> -->
              </span>
              
              <span v-if="itemData.setlist_id ">
                {{itemData.setlist_name}}详情页
                

              </span>
              <span v-else-if="itemData.groupbuy_id">
                {{itemData.groupbuy_name}}
              </span>
                
                
              <span v-else>
                {{goods.goods_name}}详情页
              </span>
            </div>

            
          </div>
        </div>
        <div class="hnw-area-center">
          <div class="detail-container">
              <div class="detail-pic">
                <Photo   :imagesList="goods.goods_images" />
              </div> 
              <div class="detail-handler">

                <div class="detail-name">
                  {{goods.name}}
                </div>
                <!-- <div class="detail-desc">适用车型{{goods.MjVe||goods.vehicletype}}</div> -->

                <!-- 采购 -->
                <div class="detail-price-v" >
                    <div class="weizi">
                      价格: 
                    </div> 
                    <div class="red">
                      <span class="fsz38">
                        {{goods.price}}
                      </span> 
                        积分
                    </div>
                    <div class="old-price">
                      ￥ {{goods.old_price||"暂无原价"}}
                    </div> 
                </div>
                <div class="datail-footer">
                <div class="detail-msg" >
                    <div v-if="goods.info">
                      <span>OE号：{{goods.info.oecode }}</span>
                    
                        <span>生产编码：{{goods.info.sccode }}</span>

                        
                        <span >
                          库存：
                          <span >{{goods.info.goods_storage}}</span>
                        </span>
                    
                        <span>配件品牌：{{goods.info.oenaturebrand  }}</span>
                    
                        <span>配件产地：{{goods.info.oenatureplace }}</span>
                    </div>
                  </div>
                  <!--配送  -->
                  <div class="item-row">
                    <div class="label">配送</div>
                    <el-cascader
                      class="change"
                      size="mini"
                      v-model="dispacthSite"
                      :options="bournList"
                      :props="{ expandTrigger: 'hover',value:'name',label:'name',children:'sub'}"
                    ></el-cascader>
                  </div>
                  <!-- 运费 -->
                  <div class="item-row">
                    <span class="label">运费</span>
                    <span class="cost">免费</span>
                  </div>
                  
                  <!-- ,秒杀数量 -->
                  <div class="item-row" >
                    <span class="label">数量</span>
                    <!-- 不限购 -->
                    <el-input-number
                      size="mini"
                      v-model="goods_num"
                      :min="1"
                      label="数量"
                    ></el-input-number>
                  </div>

                  <!-- 数量 -->
                  

                  <!-- 承诺 -->

                  <div class="item-row" v-if="itemData.groupbuy_id">
                    <span class="label">承诺</span>
                    <span class="i-threes">
                      <i class="el-icon-circle-check"></i>正品
                    </span>
                    <span class="i-threes">
                      <i class="el-icon-circle-check"></i>保价
                    </span>
                    <span class="i-threes">
                      <i class="el-icon-circle-check"></i>无忧
                    </span>

                  </div>

                  <div class="item-row" v-else>
                    <span class="label">承诺</span>
                    <span class="i-three">
                      <i class="el-icon-circle-check"></i>正品
                    </span>
                    <span class="i-three">
                      <i class="el-icon-circle-check"></i>保价
                    </span>
                    <span class="i-three">
                      <i class="el-icon-circle-check"></i>无忧
                    </span>

                  </div>
                  
                
                  <!-- 采购按钮样式 -->
                  <div class="handler">
                        <div class="join"  @click="buyNow">
                          <div class="join-in"  >立即兑换</div>
                        </div>
                                          
                  </div>
                </div>
              </div>
          </div>
          

          <div class="sgj-box">
            <div class="share_atte">
              <div class="han-item" @click="share">
                <img src="../../../assets/detail/fenxiang-1.png" alt /> 分享
              </div>
          
            </div>

            
          </div>
        </div>
      
    </div>
    <div  v-if="goods.info" class="goods-info-box hnw-area-center">
      <div class="attr-table">
        <div class="table-head" v-if="itemData.groupbuy_id">
          <div class="head-item"  v-for="(item,index) in goodsAttr" :key="index" :class="{'activeRed':index==goodsAttrIndex}" @click="goodsAttrIndex=index">{{item}}</div>  
        </div>
        <div class="table-head" v-else>
          <div class="head-item" v-for="(item,index) in goodsAttr" :key="index" :class="{'active':index==goodsAttrIndex}" @click="goodsAttrIndex=index">{{item}}</div>  
        </div>
        <div class="table-body">
          <!-- 每个属性不同的展现形式 -->
          <!-- 查看商品详情属性 -->
          <template v-if="goodsAttrIndex==0">
            <div class="goods-msg-body">
              <div class="goods-msg-col" v-for="(attr,index) in showGoodsMsgList" :key="index">
                <div class="label">{{attr.title}}：</div>
                <div class="value">{{goods.info[attr.key]||'暂无数据'}}</div>
              </div>
            </div>
          </template>
          <template v-else-if="goodsAttrIndex==1">
           
            <div class="empty" >
              暂无数据
            </div>
          </template>
          <template v-else-if="goodsAttrIndex==2">
            <div class="comm-table-box" v-if="vehList.length>0">
                <div class="comm-table-head">
                    <div>品牌</div>
                    <div>车型</div>
                    <div>年份</div>
                    <div>市场</div>
                    <div class="handle" >操作</div>
                </div>
                <div class="comm-table-body">
                    <!-- substituteLink -->
                    <div class="body-row" v-for="(veh,index) in vehList" :key="index">
                        <div>{{veh.brandname}}</div>
                        <div>{{veh.cars_model}}</div>
                        <div>{{veh.year}}</div>
                        <div>{{veh.market}}</div>
                    
                        <div class="handle">
                            <!--  -->
                            <span class="btn" @click="readVehInfo(veh)">查看</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="empty" v-else>
              暂无数据
            </div>
          </template>
          
          <template v-else-if="goodsAttrIndex==3">
            <div class="comm-table-box" v-if="replaceList.length>0">
                <div class="comm-table-head">
                    <div>品牌</div>
                    <div>零件号</div>
                    <div>生产编码</div>

                    <div>零件名称</div>
                    <div>件数</div>
                    <div>备注</div>
                    <div>销售价</div>
                    <div class="handle" v-if="$store.getters.notLvD">操作</div>
                </div>
                <div class="comm-table-body">
                    <!-- substituteLink -->
                    <div class="body-row" v-for="(replace,index) in replaceList" :key="index">
                      <div>{{replace.pid[0].brandcn}}</div>
                      <div>{{replace.pid[0].pid}}</div>
                      <div>{{goods.sccode}}</div>
                      <div >{{replace.pid[0].lable}}</div>
                      <div>{{replace.pid[0].num}}</div>
                      <div >{{replace.pid[0].remark}}</div>
                      <div>{{replace.pid[0].prices}}</div>
                      <div class="handle" v-if="$store.getters.notLvD">
                        <!-- -->
                          <i class="el-icon-shopping-cart-2 btn"  @click="toBuy(replace)" v-if="replace.gysNum"></i>
                      </div>
                    </div>
                </div>
            </div>

            <div class="empty" v-else>
              暂无数据
            </div>
          </template>
          <template v-else-if="goodsAttrIndex==4">
          
            <div class="comm-table-box" v-if="priceList.length>0">
                <div class="comm-table-head">
                    <div>零件类型</div>
                  <div>厂商</div>
                  <div>说明</div>
                  <div>进价(未含税)</div>
                  <div>进价(含税)</div>
                  <div>销售价(未含税)</div>
                  <div>销售价(含税)</div>
                  <div>服务商</div>
                </div>
                <div class="comm-table-body">
                    
                    <div class="body-row" v-for="(priceItem,index) in priceList" :key="index">
                        <div>{{priceItem.parttype}}</div>
                        <div>{{priceItem.mill}}</div>
                        <div>{{priceItem.remark}}</div>
                        <div>{{priceItem.eot_price}}</div>
                        <div>{{priceItem.cost_price}}</div>
                        <div>{{priceItem.sale_price1}}</div>
                        <div>{{priceItem.sale_price}}</div>
                        <div>{{priceItem.supplier}}</div>
                    </div>
                </div>
            </div>
            <div class="empty" v-else>
              暂无数据
            </div>
          </template>
          <template v-else-if="goodsAttrIndex==5">
              
            <div class="empty">
              暂无数据
            </div>
          </template>
          
        </div>
      </div>
     
    </div>

    <div class="rec-body">
       <div class="rec-title">
         ~ 同类推荐 ~
       </div>
      <div class="hnw-area-center">

        <div class="recGoodslist">
            <el-row  :gutter="8">
                <el-col :span="6" v-for="(item,index) in recGoodsList" :key="index" class="goods-col">
                    
                    <div class="goods-card" @click="toGoodsInfo(item)">
                        <div class="img-box" >
                            <img :src="item.images[0]" alt="">
                            
                        </div>
                        <div class="card-fot">
                            <!-- 价格和仓位 -->
                            <div class="pri_warehouse">
                                <div class="red">
                                    <div class="num">
                                        {{item.price}}
                                    </div>
                                    <div class="jf">
                                        积分
                                    </div>
                                </div>
                                <div class="ware">
                                    限量 {{item.stock}} 件
                                </div>
                            </div>
                            <div class="goods-name">
                                {{item.name}}
                            </div>
                            <div class="handle">
                                <el-button size="mini" type="primary" >去兑换</el-button>
                            </div>
                        </div>
                    </div>

                </el-col>
            </el-row>
        </div>
      </div>

    </div>
  </div>
</template>

<script>


import Photo from "../../../components/Photo" 
import area from "../../../tools/adress"
export default {
    components:{
        Photo,
    },
  data(){

    return{
      goodsAttrIndex:0,
      shopGoodsHot:true,
      imgSrc:"", //默认显示大图
      goodsAttr: [
        "商品详情",
        "套内商品",
        // "适配车型",
        // "互换件",
        // "参考价格",
        // "配件商信息"
      ],
      // 配送到的地址
      dispacthSite:"",
      goods_num:1,
      itemData:{},
      bournList:[],
      recGoodsList:[],
      goods:{
       
      },
      showGoodsMsgList:[],
      vehList:[],
      priceList:[],
      replaceList:[],
      loading:true
    }
  },

  async created(){
      

      let showAttrs = ["标准名称","配件分类","原厂名称","计量单位","英文名称","规格","OE号","重量","生产编码","长度","配件品牌","宽度","产地","体积","件号","指导售价","部位码"]
      showAttrs.forEach(attr=>{
        let hasAttr = this.$hnwTools.goodsAttrs.find(attr1=>{
          return attr1.title == attr
        })
        if(hasAttr){
          
          this.showGoodsMsgList.push({
            title:attr,
            key:hasAttr.key
          })
        }else{
          
          this.showGoodsMsgList.push({
            
            title:attr,
            key:'empty'
          })
  
        }

      })
      
      await this.init()

      // 对配送至地址处理
     
      let temp = area.arrAll.slice(1,area.arrAll.length)
      // city   district
      temp = temp.filter(prov=>{
        if(prov.sub){
            prov.sub = prov.sub.filter(city=>{
              if(city.name!="请选择"){
                  if(city.sub){
                    city.sub = city.sub.filter(district=>{
                      if(district.name!="请选择"){
                        return district
                      }
                    })
                  }
                  return city
              }
            })
        }
        return prov
      })
      this.bournList = temp

  },
  methods:{
   
    // 初始化的方法
    async init(){
      this.loading = true
      this.itemData = JSON.parse(localStorage.getItem('hnw-web-item-data'))
      
      let res = await this.$api.getIntegralInfo({
        id:this.itemData.id,
        shop:this.$route.params.shop||"1780"
      })
      
      this.goods = res
      this.goods.goods_images = []
      
      let mode = ""
      if(this.goods.info){

        mode = "goods"
        if(this.goods.info.image){
          this.goods.info.image.forEach(img=> {
            this.goods.goods_images.push({
              img_url:img
            })
          })
        }
        // 如果没有套包
        this.goods.goods_bag = this.goods.goods_bag || []
        // 设置配件商相关
        this.goods.store_sup = {}
        
        
        let brandObj = {}
        if(this.goods.info.oecarbrand){
            let branRes = await this.$api007.getAllBrand()
            
            brandObj = branRes.data.find(item=>{
                return item.name == this.goods.info.oecarbrand
            })
            brandObj = brandObj || {}
        }else{
            let res = await this.$api007.getPartByOe({
                pid:this.goods.info.oecode
            })
            if(res.data&&res.data.length>0){
                brandObj.brandcode = res.data[0].brand_list[0].brandCode
            }
            
        }
        if(brandObj.brandcode){
            // 获取价格
            await this.getPrice(brandObj.brandcode)
            // 获取替换件
            await this.getPartReplace(brandObj.brandcode)
        }else{
          console.log("未找到对应品牌",this.goods.info.oecarbrand);
        }
        // 获取适配车型
        await this.getPartVeh('')

      }else{
        if(res.images){

          let imgs = JSON.parse(res.images)
          imgs.forEach(img=> {
            this.goods.goods_images.push({
              img_url:img
            })
          })
          console.log("imgs",this.goods.goods_images);
        }
      }
      this.loading = false
      let recRes =  await this.$api.getIntegralRec({
        page:1,
        limit:8,
        shop:this.$route.params.shop||"1780",
        mode
      })

      if(recRes.data){
          recRes.data.forEach(goods => {
              if(goods.images){
                  goods.images = JSON.parse(goods.images)
              }
          })
          this.recGoodsList = recRes.data
      } 
    },
    toGoodsInfo(goods){
      scrollTo(0,0)
      goods.preRou = "积分礼品"
      localStorage.setItem("hnw-web-item-data",JSON.stringify(goods))
      this.init()

    },
    async getPrice(brandCode){
        let res = await this.$api007.getPartPrice({
            pid:this.goods.info.oecode,
            brandCode
        })
        
        this.priceList = res.data
        if(Array.isArray(this.priceList)){
            this.priceList.forEach(item=>{

                item.sale_price1 = this.$hnwTools.div(item.sale_price,1.13).toFixed(2)
            })
        }
    },
    async getPartReplace(brandCode){
          let res = await this.$api007.getPartReplace({
            pid:this.goods.info.oecode,
            brandCode
        })
        
        this.replaceList = res.data
        
        let pid_list = []
        // 获取对应商品能否购买
        
        if(Array.isArray(this.replaceList)){
            pid_list = this.replaceList.map(item=>item.pid[0].pid)
        }
        // console.log("pid_listPart",pid_list)
        if(pid_list.length>0){
            let gysList = await this.$api.getGysNum({
                oeList:pid_list
            })
            
            gysList.forEach(item=>{
                if(item.data.length>0){
                    this.replaceList.forEach(part=>{
                        if(part.pid[0].pid==item.oecode){
                            part.gysNum = item.data.length
                            part.storeList = item.data

                        }
                    }) 
                    // let part = this.replaceList.find(part1=>part1.pid[0].pid==item.oecode)
                }
                
            })

        }

    },
    async getPartVeh(filter_brand){
        // brandCode:this.brandCode,
        let res = await this.$api007.getPartVeh({
            part:this.goods.info.oecode,
            filter_brand
        })
        
        if(!filter_brand){
            this.brandList = res.all_brands
        }
        this.filBrand = filter_brand
        this.vehList = res.data
    },
    readVehInfo(veh){
        // let link = location.href.split("#")[0]
        
        // window.open(link + `#/catalog/vehinfo/${veh.uri_param.p}/${veh.uri_param.brandCode}/${veh.uri_param.mcid}`)


        // this.$emit("changeData",{
        //     brandCode:veh.uri_param.brandCode,

        //     mcid:veh.uri_param.mcid  
        // })
        
        veh.uri_param.mcid = veh.uri_param.mcid.replace(/\//g,"xiegang")
        this.$router.push(`/catalog/vehinfo/${veh.uri_param.p}/${veh.uri_param.brandCode}/${veh.uri_param.mcid}`)
        this.$store.commit("navPush",{
            label:`${veh.brandname}${veh.cars_model}${veh.year}${veh.market}`,
            route:`/catalog/vehinfo/${veh.uri_param.p}/${veh.uri_param.brandCode}/${veh.uri_param.mcid}`
        })
    },
    async toBuy(epcPart){
      if(epcPart.storeList[0]){
          let goods = epcPart.storeList[0]
          this.joinCart(goods)
      }  
    },
    async joinCart(){
      let cart = []
      let cartGoods = {}
      cartGoods.goods_num = 1
      cartGoods.oecode = this.goods.oecode
      cartGoods.sccode = this.goods.sccode
      
      let keys1 = ["goods_id","goods_name","goods_price","goods_storage","store_id","store_name","setlist_id"]
      keys1.forEach(key=>{
        cartGoods[key] = this.goods.info[key]
      })
      //  console.log("this.itemData.groupbuy_price2",this.itemData.groupbuy_price2)
      // 对电商价格处理 
      
      cartGoods.cart_type = 1
      
      cart.push(cartGoods)
      // 跳转到加入成功页
      
      await this.$api.joinCart(cart)
      
      
    },
    share(){
      const input = document.createElement('input');

      document.body.appendChild(input);

      

      if(this.$axios.defaults.baseURL=="https://jc.hnwmall.com/web/"){
          input.setAttribute('value', 'http://b.huanuowei.cn/');
          // window.open("http://jc.hnwmall.com:6600/#/login/"+mixText)
        }else{
          input.setAttribute('value', 'http://b2b.huanuowei.cn/');
          // window.open("http://www.huanuowei.cn/#/login/"+mixText)
        }
      input.select();
      if (document.execCommand('copy')) {

          document.execCommand('copy');
          // this.$message({
          //   message: '网址复制成功!',

          //   type: 'success'
          // })
        this.$suMsg("网址复制成功")
      }else{
          this.$erMsg("复制失败")
      }
      document.body.removeChild(input);
    },
    buyNow(){
      localStorage.setItem("hnw-web-item-data",JSON.stringify({
        ...this.itemData,
        goods_num:this.goods_num
      }))
      setTimeout(() => {
        this.$emit("tosub")
      }, 0);
      // this.$router.push("/store/suborder/"+this.itemData.id +"/"+this.goods_num+"/"+this.$route.params.shop)
    },
    reBack(){
      this.$emit("changeNum",5)
    },
   
  }
};
</script>

<style   lang="scss" scoped>
.store-intr-info{
  // 商品详情页面
   background: #fff;
  .hnw-goods-detail{
    .top{
      // background: #f7f7f7;
      height: 40px;
      line-height: 40px;
      border-top: 1px solid #f7f7f7;
      >div{
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        padding-bottom: 10px;
        font-size: 12px;
        .title{
          >span{
            cursor: pointer;
          }
        }
        .hnw-logo{
          display: flex;
          align-items: flex-end;
          padding-top: 5px;
          .font{
  
          }
          .title-pic{
            display: flex;
            align-items: flex-end;
            margin-right: 20px;
            img{
              width: 55px;
              height: 24px;
              position: relative;
              margin-right: 8px;
            }
            .hnw{
              cursor: pointer;
            }
          }
          .collect{
            
            img{
              // width: 18px;
              // height: 18px;
              width: 14px;
              height: 14px;
              position: relative;
              margin-right: 4px;
              cursor: pointer;
              top: 1px;
            }
          }
  
        }
      }

    }
    
    .detail-container{
      display: flex;
    
      .detail-pic{

      }
      .detail-handler{
        // padding-top: 10px;
        box-sizing: border-box;
        height: 344px;
        margin-left: 30px;
        // display: flex;
        flex-direction: column;
        flex: 1;
        font-size: 14px;
        .detail-name{
          // margin-top: 10px;
          font-size: 20px;
          font-weight: bold;
          margin-bottom: 15px;
          .deta-sename{
            padding-left: 20px;
          }
          .detail-desc{
            font-size: 14px;
            color: #999;
            padding: 10px 0;
          }
        }
        // 集单样式

        .total-order{
          // display: flex;
          height: 105px;
          // align-items: center;
          background: #3E84D6;
          font-size: 14px;
          color: #fff;
          padding-left: 15px;
          // padding: 0 16px;justify-content: space-between;
          box-sizing: border-box;
          border-radius: 2px;
          box-shadow: 0px 0px 6px 0px rgba(52, 72, 92, 0.35);
          .details-top{
            height: 60px;
            display: flex;
            justify-content: space-between;
            // align-items:flex-end;
            // line-height: 60px;
            .details-price{
              display: flex;
              align-items:flex-end;
              .current-price{
                display: flex;
                align-items:flex-end;
                .big{
                  font-size: 38px;
                  margin: 0 10px -4px 15px;
                }
              }
            }
            .current-time{
              width: 193px;
              height: 59px;
              background: #E8F3FF;
              border-radius: 2px;
              .introduce{
                height: 28px;
                line-height: 28px;
                text-align: center;
                color: #3E84D6;
              }
              .specific-time{
                // padding-left: 10px;
                display: flex;
                 justify-content: center;
                .i-time-box{
                  min-width: 22px;
                  height: 22px;
                  // padding: 0 4px;
                  line-height: 24px;
                  text-align: center;
                  background: #3E84D6;
                  border-radius: 2px;
                }
                .day{
                  color:#3E84D6 ;
                  line-height: 24px;
                  font-size: 14px;
                  padding: 0 2px;
                }
              }
            }
          }
         .details-bottom{
            display: flex;
            justify-content: space-between;
            height: 50px;
            line-height: 50px;
            .plan{
              display: flex;
              color: #dfdfdf;
              .progress{
                display: flex;
                .percentage{
                  margin: 0 10px 0 16px;
                }
                .progress-body{
                  display: flex;
                  width: 180px;
                  height: 7px;
                  // background-color: #FEE8EA;
                  border: 1px solid #fff;
                  border-radius:  4px;
                  overflow: hidden;
                  margin-top: 21px;
                  margin-right: 10px;
                  .progress1{
                    text-align: center;
                    line-height: 14px;
                    background-color: #fff;
                    color: #fff;
                    border-radius:  4px;
                  }
                  

                }	
              }
            }
            .total-people{
              display: flex;
              align-items: center;
              padding-right: 20px;
              img{
                width: 20px;
                height: 20px;
                margin-top: -5px;
                padding-right: 10px;
              }
            }
          }


        //  以下是不用的
          .total-order-price{
            i{

            }
          }
          .total-center{
            display: flex;
            // align-items: center;
            >span{
              margin-right: 16px;
               img{
                width: 20px;
                height: 20px;
                margin-right: 10px;
              }
            }
            .total-people{
              display: flex;
              // align-items: center;
              img{
                width: 20px;
                height: 20px;
              }
            }
            .total-now-price{
              display: flex;
              // align-items: center;
              
            }
            .total-progress{
              span{

              }
            }
          }
          .total-odd{
            display: flex;
            // align-items: center;
            .day{
              margin: 0 4px;
            }
            .i-time-box{
              width: 16px;
              height: 16px;
              text-align: center;
              line-height: 16px;
              background: #fff;
              color: #3E84D6;
             
            }
          }
        }

        // 秒杀样式
        .total-orders{
          // display: flex;
          height: 105px;
          // align-items: center;
          background: #f00;
          font-size: 14px;
          color: #fff;
          padding-left: 15px;
          // padding: 0 16px;justify-content: space-between;
          box-sizing: border-box;
          box-shadow: 0px 0px 6px 0px rgba(52, 72, 92, 0.35);
          border-radius: 2px;
          background: linear-gradient(11deg, #FF0000, #FB1C31);
          .details-top{
            height: 60px;
            display: flex;
            justify-content: space-between;
            // align-items:flex-end;
            // line-height: 60px;
            .details-price{
              display: flex;
              align-items:flex-end;
              .current-price{
                display: flex;
                align-items:flex-end;
                .big{
                  font-size: 38px;
                  margin: 0 10px -4px 15px;
                }
              }
            }
            .current-time{
              width: 193px;
              height: 59px;
              background: #FFE1E1;
              border-radius: 2px;
              .introduce{
                height: 28px;
                line-height: 28px;
                text-align: center;
                color: #f00;
              }
              .specific-time{
                // padding-left: 20px;
                display: flex;
                justify-content: center;
                .i-time-box{
                  min-width: 22px;
                  height: 22px;
                  // padding: 0 4px;
                  line-height: 24px;
                  text-align: center;
                  background: #f00;
                  border-radius: 2px;
                }
                .day{
                  color:#f00 ;
                  line-height: 24px;
                  font-size: 12px;
                  padding: 0 2px;
                }
              }
            }
          }
          .details-bottom{
            display: flex;
            justify-content: space-between;
            height: 50px;
            line-height: 50px;
            .plan{
              display: flex;
              color: #dfdfdf;
              .progress{
                display: flex;
                .percentage{
                  margin: 0 10px 0 16px;
                }
                .progress-body{
                  display: flex;
                  width: 180px;
                  height: 7px;
                  // background-color: #FEE8EA;
                  border: 1px solid #fff;
                  border-radius:  4px;
                  overflow: hidden;
                  margin-top: 21px;
                  margin-right: 10px;
                  .progress1{
                    text-align: center;
                    line-height: 14px;
                    background-color: #fff;
                    color: #fff;
                    border-radius:  4px;
                  }
                  

                }	
              }
            }
            .total-people{
              display: flex;
              align-items: center;
              padding-right: 20px;
              img{
                width: 20px;
                height: 20px;
                margin-top: -5px;
                padding-right: 10px;
              }
            }
          }


        //  以下是不用的
          .total-order-price{
            i{

            }
          }
          .total-center{
            display: flex;
            // align-items: center;
            >span{
              margin-right: 16px;
               img{
                width: 20px;
                height: 20px;
                margin-right: 10px;
              }
            }
            .total-people{
              display: flex;
              // align-items: center;
              img{
                width: 20px;
                height: 20px;
              }
            }
            .total-now-price{
              display: flex;
              // align-items: center;
              
            }
            .total-progress{
              span{

              }
            }
          }
          .total-odd{
            display: flex;
            // align-items: center;
            .day{
              margin: 0 4px;
            }
            .i-time-box{
              width: 16px;
              height: 16px;
              text-align: center;
              line-height: 16px;
              background: #fff;
              color: #3E84D6;
             
            }
          }
        }

        
        .detail-price-v{
          padding: 20px;
          height: 85px;
          // line-height: 85px;
          background: #F5F5F5;
          box-sizing: border-box;
          display: flex;
          justify-items: center;
          .weizi{
            line-height: 55px;
            font-size: 14px;
            color: #666666;
            padding-right: 5px;
          }
          .fsz38{
            font-size: 38px;
            
          }
          .old-price{
            text-decoration: line-through;
            color: #999999;
            padding-top: 8px;
            padding-left: 7px;
            display: flex;
            align-items: center;
          }
        }
        .datail-footer{
          
          padding: 0px 0 0 15px;
          
          box-sizing: border-box;
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          color: #666;
          .detail-msg{
            height: 40px;
            line-height: 40px;
            color: #999;
            span{
              margin-right: 18px;
            }
          }
          .item-row{
            height: 48px;
            line-height: 48px;
            display: flex;
            align-items: center;
            .label{
              width: 40px;
              color: #999;
            }
            .i-three{
              margin-right: 45px;
              i{
                color: #3E84D6;
                margin-right: 3px;
              }
            }
            .i-threes{
              margin-right: 45px;
              i{
                color: #f00;
                margin-right: 3px;
              }
            }
            .clear-tip{
              margin-left: 10px;
              font-size: 12px;
              line-height: 12px;
            }
          }
          .handler{
            display: flex;
            .join{
              width: 189px;
              height: 46px;
              background: #3E84D6;
              border-radius: 2px;
              display: flex;
              cursor: pointer;
              justify-content: center;
              align-items: center;
              color: #fff;
              margin-right: 8px;
              margin-top: 5px;
            }
            .joins{
              width: 189px;
              height: 46px;
              background: #f00;
              border-radius: 2px;
              display: flex;
              cursor: pointer;
              justify-content: center;
              align-items: center;
              color: #fff;
              margin-right: 8px;
              margin-top: 5px;
            }
            .handler-text{
              line-height: 60px;
              display: flex;
              justify-content: center;
              align-items: center;
              span{
                margin: 0 5px;
              }
              .colorRed{
                color: #f00;
                 cursor: pointer;
              }
              .handler-click{
                color: #3E84D6;
                cursor: pointer;
              }
            }
          }
        }
        .block{
          .demonstration{
            .give{

            }
          }
        }
      }
    }
    
    // 分享举报关注等
    .sgj-box{
      display: flex;
      align-items: center;
      width: 420px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 0;
      .share_atte{
        display: flex;
        .han-item{
          margin-right: 20px;
          display: flex;
          align-items: center;
          cursor: pointer;
          img{
            margin-right: 5px;
          }
        }
      }
    }
  }
  

}
.goods-info-box{
  // margin-left: 10px;
  // width: 980px;
  padding-bottom: 10px;
  flex: 1;
  box-sizing: border-box;
  .attr-table{
    
    .table-head{
      display: flex;
      height: 38px;
      border: 1px solid #ccc;
      box-sizing: border-box;
      .head-item{
        width: 100px;
        line-height: 38px;
        text-align: center;
        cursor: pointer;
      }
      .active{

      }
    }
    .table-body{
      .goods-msg-body{
        display: flex;
        flex-wrap: wrap;
          box-sizing: border-box;
        border: 1px solid #ccc;
        border-top: none;
        .goods-msg-col{
          width: 50%;
          display: flex;
          border-bottom: 1px solid #ccc;
          height: 28px;
          line-height: 28px;
          padding-left: 20px;
          box-sizing: border-box;
          .label{
            text-align: center;
            width: 90px;
            margin-right: 5px;
          }
          .value{

          }
        }
        .goods-msg-col:last-child{
          border: none;
        }
      }
      .empty{
        text-align: center;
        height: 100px;
        line-height: 100px;
        text-align: center;
        color: #999;
        border: 1px solid #ccc;
        border-top: none;
      }
      // 采购平台复制
        .label{
            // background: rgb(225, 239, 255);
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding: 5px 0;
            min-height: 15px;
            
        }
        
        .xiangqing-box{
            display: flex;
            border: 1px solid #E8E8E8;
            border-top: none;
            .col{
                flex: 1;
                
                .item{
                    display: flex;
                    border-top: 1px solid #E8E8E8;
                    .label{
                        width: 80px;
                        // background: #59A8F8;
                        color: #666666;
                    }
                    .value{
                        padding: 5px 0;
                        color: #666;
                    }
                }
            }
        }
        .chexing-box{
            border:1px solid#ccc;
            border-top: none;
            .item{
                display: flex;
                border-top: 1px solid #E8E8E8;
                .label{
                    width: 80px;
                    justify-content: center;
                    border-right: 1px solid #ccc;
                }
                .value{
                    padding: 5px;
                }
            }
            .item:first-child{
              border-top: none;
            }
        }
        .comm-table-box{
            border: 1px solid #ccc;
            border-top: none;
            .comm-table-head{
                display: flex;
                border-right: none;
                font-weight: 600;
                >div{
                    border-right:1px solid #ccc;
                    flex: 1;
                    justify-content: center;
                    padding: 5px 0;
                    text-align: center;
                }
            }
            .comm-table-body{
                .body-row{
                    display: flex;
                    border-top: 1px solid #ccc;
                    >div{
                        flex: 1;
                        padding: 5px;
                        // text-align: center;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-right:1px solid #ccc;
                        .btn{
                          cursor: pointer;
                        }
                    }
                    
                }
            }
        }
        .manu-box{
            border: 1px solid #ccc;
            border-top: none;
            .item{
                display: flex;
                border-top: 1px solid #ccc;
                .label{
                    width: 100px;
                }
                .value{
                    padding: 5px;
                    
                }
            }
            .item:first-child{
              border-top: none;
            }
        }
        .sort{
            width: 40px;
        }
        .oe{
            width: 120px;
        }
        .scode{
            width: 120px;
        }
        .name{
            width: 120px;
        }
        .brand{
            width: 80px;
        }
        .num{
            width: 100px;
        }
        .head{
            display: flex;
            
            background: $gray;
            height: 28px;
            line-height: 28px;
            text-align: center;
            justify-content: flex-start;
            padding:0  $leftPad;
        }
        .partPrice{
            padding: 5px $leftPad;
            border-bottom: 1px solid #ccc;
            display: flex;
            >div{
                text-align: center;
            }
            .curpon{
                cursor: pointer;
                
            }
        }
        // 复制完毕
    }
  }
  .poster{
    margin-top: 20px;
    overflow: hidden;
    p{
      width: 100%;
      img{
        width: 100%;
      }
    }
  }
  

}
.rec-body{
  background: #f7f7f7;
  .rec-title{
    text-align: center;
    height: 60px;
    font-size: 20px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #333333;
    line-height: 60px;
  }
  .recGoodslist{
    .goods-col{
              
          .goods-card{
              background: #fff;
              
              height: 310px;
              display: flex;
              margin-bottom: 10px;
              flex-direction: column;
              cursor: pointer;
              // justify-content: space-between;		
              box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
              border-radius: 6px ;
              .img-box{
                  // cursor: pointer;
                  height: 210px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  img{
                      // width: 229px;
                      // height: 130px;
                      width: 210px;
                      height: 210px;
                  }
              }
              .card-fot{
                  box-sizing: border-box;
                  padding: 10px;
                  .pri_warehouse{
                      display: flex;
                      justify-content: space-between;
                      align-items: flex-end;
                      .red{
                          display: flex;
                          .num{
                              font-size: 22px;
                          }
                          .jf{
                              position: relative;
                              padding-left: 3px;
                              top: 5px;
                          }
                      }
                      .ware{
                          color: #999999;
                      }
                  }
                  .goods-name{
                      font-weight: 600;
                      line-height: 22px;
                      min-height: 22px;
                      font-size: 16px;
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                  }
                  .handle{
                      text-align: right;
                  }
              }
          }
      }
  }
}
.active{
  background: #3E84D6;
  color: #fff;
}
.activeRed{
  background: red;
  color: #fff;
}

</style>