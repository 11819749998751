<template>
    <div class="init">
        <div class="my-coupons">
            <h3>我的优惠</h3>
        </div>
        <div class="coupons">
            <span @click="num=1" :class="{active:num==1}">未使用 &#40;9&#41;</span>
            <span @click="num=2" :class="{active:num==2}">已使用 (9)</span>
            <span @click="num=3" :class="{active:num==3}">已失效 (9)</span>
        </div>
        <div class="coupons-content" v-if="num==1">
            <div class="coupons-list">
                <div class="coupons-name">减免卷</div>
                <div class="coupons-price">39</div>
                <div class="coupons-require">订单满199可使用</div>
                <div class="coupons-line"></div>
                <div class="coupons-date">2018.10.25-2020.02.12</div>
            </div>
            <div class="coupons-list">
                <div class="coupons-name">现金卷</div>
                <div class="coupons-price">10</div>
                <div class="coupons-require">无限制条件</div>
                <div class="coupons-line"></div>
                <div class="coupons-date">2018.10.25-2020.02.12</div>
            </div>
            
        </div>
        <div class="coupons-content" v-if="num==2">
           <div class="employ-list">
                <div class="employ-name">减免卷</div>
                <div class="employ-price">39</div>
                <div class="employ-require">订单满199可使用</div>
                <div class="employ-line"></div>
                <div class="employ-date">使用时间：2021.07.26</div>
            </div>
            <div class="employ-list">
                <div class="employ-name">现金卷</div>
                <div class="employ-price">10</div>
                <div class="employ-require">无限制条件</div>
                <div class="employ-line"></div>
                <div class="employ-date">使用时间：2021.07.26</div>
            </div>
        </div>
        <div class="coupons-content" v-if="num==3">
            <div class="employ-list">
                <div class="employ-name">减免卷</div>
                <div class="employ-price">39</div>
                <div class="employ-require">订单满199可使用</div>
                <div class="employ-line"></div>
                <div class="employ-date">已过期</div>
            </div>
            <div class="employ-list">
                <div class="employ-name">现金卷</div>
                <div class="employ-price">10</div>
                <div class="employ-require">无限制条件</div>
                <div class="employ-line"></div>
                <div class="employ-date">已过期</div>
            </div>

        </div>
    </div>
</template>


<script>
export default {
    data(){
        return{
            num:"1",
        }
    },
    methods:{

    },
    created(){

    },
    props:{
        
    }
}
</script>

<style lang="scss">
.init{
    .my-coupons{
        width: 1097px;
        height: 40px;
        background: #fff;
        margin-top: 20px;
        line-height: 40px;
        padding-left: 10px;
        box-sizing: border-box;
        h3{
            font-weight: 400;
        }
    }
    .coupons{
        cursor:pointer;
        width: 1097px;
        height: 40px;
        background-color: #D3E7FB;
        margin-top: 20px;
        line-height: 40px;
        .active{
                color: #3E84D6 !important;
        }
        span{
            display: inline-block;
            height: 40px;
            line-height: 40px;
            margin: 0 10px 0 10px;
        }
    }
    .coupons-content{
        padding-top: 20px;
        padding-left: 10px;
        width: 1097px;
        background-color: #fff;
        display: flex;
         flex-wrap: wrap;
        //  margin: 23px 0 0 14px;
         .coupons-list{
             position: relative;
             width: 260px;
             height: 140px;
             background-color: #D3E7FB;
             overflow: hidden;
             margin-right: 10px;
             margin-bottom: 10px;
             .coupons-name{
                 position: absolute;
                 top: 11px;
                 left: -30px;
                 width: 100px;
                 height: 20px;
                 background-color: #C3E1FF;
                 color: #50A3F8;
                 text-align: center;
                 line-height: 20px;
                 transform: rotate(314deg);
             }
             .coupons-price{
                 height: 66px;
                 line-height: 66px;
                 text-align: center;
                font-size: 48px;
                font-weight: bold;
                color: #50A3F8;
             }
             .coupons-require{
                height: 18px;
                line-height: 18px;
                text-align: center;
                font-size: 18px;
                color: #50A3F8;
             }
             .coupons-line{
                 width: 234px;
                 border: 1px dashed #50A3F8;
                 margin: 10px 0 14px 15px;
             }
             .coupons-date{
                font-size: 18px;
                color: #50A3F8;
                text-align: center;
             }
         }
        //  已使用
        .employ-list{
             position: relative;
             width: 260px;
             height: 140px;
             background-color: #D9D9D9;
             overflow: hidden;
             margin-right: 10px;
             margin-bottom: 10px;
             .employ-name{
                 position: absolute;
                 top: 11px;
                 left: -30px;
                 width: 100px;
                 height: 20px;
                 background-color: #999999;
                 color: #AAAAAA;
                 text-align: center;
                 line-height: 20px;
                 transform: rotate(314deg);
             }
             .employ-price{
                 height: 66px;
                 line-height: 66px;
                 text-align: center;
                font-size: 48px;
                font-weight: bold;
                color: #999999;
             }
             .employ-require{
                height: 18px;
                line-height: 18px;
                text-align: center;
                font-size: 18px;
                color: #999999;
             }
             .employ-line{
                 width: 234px;
                 border: 1px dashed #999999;
                 margin: 10px 0 14px 15px;
             }
             .employ-date{
                font-size: 18px;
                color: #999999;
                text-align: center;
             }
         }
    }
}
</style>