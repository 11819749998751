
const UpDateState = (state,data)=>{
    for(let k in data){
        // 防止部分为空的
        if(data[k]||data[k]==0||k=='for_branch'){
          state[k] = data[k]
        }
    }
    // console.log("state",state);
    localStorage.setItem("hnw-web-state",JSON.stringify(state))
} 
const resetState = (state)=>{
    for(let k in state){
        state[k] = ""
        
    }
    state.invDia = false
}
const navPush = (state,data)=>{
    if(data.pop){
        state.epcNavList.pop()
    }
   
    state.epcNavList.push(data)
}
const toggleLoding = (state,data)=>{
    state.showLoding = data
}
export default {
    UpDateState,
    resetState,
    toggleLoding,
    navPush
}