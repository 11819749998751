<template>
  <div class="service">
      <div class="nav">
          <div class="nav-center">
          <img src="../../assets/index/logo123.png" alt="">  
          <img class="logo-text" src="../../assets/index/汽配供应链专家sevice.png" alt="">
          </div>
          <div class="nav-right" @click="toHome">
              <img src="../../assets/login/home.png" alt="">
            <span v-if="$store.getters.notLvD">华诺威首页</span>
            <span v-else>返回店铺</span>
              
          </div>
      </div>
      <div class="w">
          <div class="centre">
              <div class="center-left">
                  <div class="service-centre">
                      <img src="../../assets/index/service.png" alt="">
                      服务中心
                  </div>
                  <ul class="guide">
                    <!-- 购物指南 -->
                      <li class="color">购物指南</li>
                      <li @click="num=0"  :class="{active:num==0}">新手入门</li>
                      <li @click="num=1"  :class="{active:num==1}">注册认证</li>
                      <li @click="num=2"  :class="{active:num==2}">集单业务</li>
                  </ul>
                    <!-- 支付&售后 -->
                  <ul class="guide1">
                      <!-- 支付& -->
                      <li class="color">售后</li>
                      <!-- <li @click="num=3"  :class="{active:num==3}">支付证明</li> -->
                      <li @click="num=4"  :class="{active:num==4}">关于退换货</li>
                  </ul>
                    <!-- 关于我们 -->
                  <ul class="guide2">
                      <li class="color">关于我们</li>
                      <!-- <li @click="num=5"  :class="{active:num==5}">华诺威</li> -->
                      <li @click="num=6"  :class="{active:num==6}">关于我们</li>
                      <li @click="num=7"  :class="{active:num==7}">联系方式</li>
                      <li @click="num=8"  :class="{active:num==8}">法律声明</li>
                  </ul>
              </div>
              <div class="center-right">
                  <!-- 新手入门 -->
                  <div class="center-content" v-show="num==0">
                    <h2>新手入门</h2>
                    <div class="content">
                        <h2 style="text-align: center;">汽配供应链专家</h2>
                        <h2 style="text-align: center;">新手入门手册</h2>
                        <div class="br-25">
                            
                        </div>
                        <div style="text-align: center;" class="content-wz">
                           上海华诺威汽车零部件有限公司
                        </div>
                         <div style="text-align: center;" class="content-wz">
                           2020年11月
                        </div>
                        <div class="br-6">

                        </div>
                        <div style="text-align: center;" class="content-wz">
                           目录
                        </div>
                         <div class="content-wz">
                           汽配供应链专家.................................................................................................................................................................................................................  1
                           <br>
                            1前言.................................................................................................................................................................................................................................  3
                            <br>
                             <span style=" padding-left: 30px;">
                                 1.1 目的.....................................................................................................................................................................................................................	3
                             </span>
                            <br>
                            <span style=" padding-left: 30px;">
                                1.2 使用平台..............................................................................................................................................................................................................	3
                            </span>
                            <br>
                            <span style=" padding-left: 30px;">
                                1.3 使用人员..............................................................................................................................................................................................................	4
                            </span>
                            <br>
                            2 平台模块........................................................................................................................................................................................................................	4
                            <br>
                            3 页面操作........................................................................................................................................................................................................................	4
                            <br>
                            <span style=" padding-left: 30px;">
                                3.1 账号登陆..............................................................................................................................................................................................................	4
                            </span>
                            <br>
                            <span style=" padding-left: 40px;">
                                3.1.1已有账号登陆..................................................................................................................................................................................................	4
                            </span>
                            <br>
                           <span style=" padding-left: 30px;">
                                3.2 快捷菜单建立.......................................................................................................................................................................................................	5
                           </span>
                            <br>
                            <span style=" padding-left: 30px;">
                                3.3 采购......................................................................................................................................................................................................................	6
                           </span>
                            <br>
                             <span style=" padding-left: 30px;">
                                3.4 购物车去下单.......................................................................................................................................................................................................	6
                           </span>
                            <br>
                            <span style=" padding-left: 30px;">
                                3.5 下单..................................................................................................................................................................................................................... 	6
                           </span>
                            <br>
                            填写地址，如果地址不对，可以点击右上角“新增地址”按钮，进行地
                            址的新增，新增完成后到点击“更多地址”，选择下单地址......................................	 7
                            <br>
                             <span style=" padding-left: 30px;">
                                3.6 查看订单..............................................................................................................................................................................................................	7
                           </span>
                            <br>
                            在快捷菜单栏或者左侧采购导航栏【我的订单】中可修改、取消订单，查看发货状态物流信息等；................................................................................	7

                        </div>
                        <h2>1、前言</h2>
                        <h2 style=" padding-left: 30px;">1.1 目的</h2>
                        <div class="content-wz" style=" padding-left: 50px;">
                            本文档为了统一用户操作规范，方便用户对每个页面进行正确的采购操作。包括相关人员能够了解采购步骤，故书写此操作手册。
                        </div>
                         <h2 style=" padding-left: 30px;">1.2 使用平台</h2>
                        <div class="content-wz" style=" padding-left: 50px;">
                            汽配供应链专家。
                        </div>
                         <h2 style=" padding-left: 30px;">1.3 使用人员</h2>
                        <div class="content-wz" style=" padding-left: 50px;">
                            企业联盟，代理商以及其他相关人员。
                        </div>
                         <h2>2、平台模块</h2>
                        <div class="content-wz">
                                01  总部采购
                                <br>
                                02  促销活动
                                <br>
                                03  我的订单
                                <br>
                                04  购物车
                        </div>
                        <h2>3、页面操作</h2>
                        <h2 style=" padding-left: 30px;">3.1 账号登陆</h2>
                        <h2 style=" padding-left: 50px;">3.1.1已有账号登陆</h2>
                        <div class="content-wz">
                            <span style="padding-left: 200px;">
                                用户可根据已有的账号，直接进行登录页面（ http://www.huanuowei.cn/）；如图首页
                            </span>
                            <img src="../../assets/index/01.png" alt="">
                        </div>
                        <h2 style=" padding-left: 30px;">3.2 快捷菜单建立</h2>
                        <div class="content-wz">
                            <img src="../../assets/index/0.2.png" alt="">
                            点击导航栏右侧，红色画框内，可根据需要建立快捷菜单
                        </div>
                        <h2 style=" padding-left: 30px;">3.3 采购</h2>
                        <div class="content-wz">
                            <img src="../../assets/index/0.3.png" alt="">
                            进入“总部采购”页面，默认页面显示华诺威所有商品，可直接选择商品加入购物车，也可以在输入框中输入商品名称，OE号，生产编码等搜索条件，点击“搜索按钮”进行商品查看；将所需商品，加入购物车
                        </div>
                         <h2 style=" padding-left: 30px;">3.4 购物车去下单</h2>
                        <div class="content-wz">
                            <img src="../../assets/index/0.4.png" alt="">
                            <span style=" padding-left: 300px;">
                                点击导航栏【购物车】，选择所需商品和商品数量去下单，也可删除商品
                            </span>
                        </div>
                        <h2 style=" padding-left: 30px;">3.5 下单</h2>
                        <div class="content-wz">
                            <img src="../../assets/index/0.5.png" alt="">
                            <span style=" padding-left: 100px;">
                                填写地址，如果地址不对，可以点击右上角“新增地址”按钮，进行地址的新增，新增完成后到点击“更多地址”，选择下单地址
                            </span>
                        </div>
                        <h2 style=" padding-left: 30px;">3.6 查看订单</h2>
                        <div class="content-wz">
                            <img src="../../assets/index/0.6.png" alt="">
                            <span style=" padding-left: 200px;">
                                在快捷菜单栏或者左侧采购导航栏【我的订单】中可修改、取消订单，查看发货状态物流信息等；
                            </span>
                        </div>
                    </div>
                </div>
                  <!-- 注册认证 -->
                  <div class="center-content" v-show="num==1">
                    <h2>注册认证</h2>
                    <div class="content">
                        <h2>注册认证</h2>
                        <div class="content-wz content-em">
                            您好！欢迎进入华诺威汽配供应链平台，在您提交完注册认证信息审核通过后，您将成为我们尊贵的会员。在这里您将享有比普通用户更优惠的价格和服务。
                        </div>
                    </div>
                </div>
                  <!-- 集单业务 -->
                  <div class="center-content" v-show="num==2">
                    <h2>集单业务</h2>
                    <div class="content">
                        <h2>集单业务</h2>
                        <div class="content-wz content-em">
                            集单即是通过互联网平台，由华诺威平台联合汽配品牌厂商、经销商，共同发起的汽车零部件采购行动，按月为单位，每个标的不低于50万，以营销传播的方式降低采购成本价（或议价能力），用户（不仅限于经销商）在线参加集单，满标即集单成立（反之，集单时间周期内，未达成则集单失败）
                        </div>
                        <h2>详情</h2>
                        <div class="content-wz ">
                            集单周期内，可向平台申请退标，经总部核实后方可推标。
                            <br>
                            集单结束后，不可申请退标。
                        </div>
                    </div>
                </div>
                  <!-- 支付证明 -->
                  <div class="center-content" v-show="num==3">
                    <!-- <h2>支付证明</h2>
                    <div class="content">
                        <h2>支付证明</h2>
                        <div class="content-wz">
                            暂无数据
                        </div>
                    </div> -->
                </div>
                  <!-- 关于退换货 -->
                  <div class="center-content" v-show="num==4">
                    <h2>关于退换货</h2>
                    <div class="content">
                        <h2>退换货标准</h2>
                        <div class="content-wz">
                            1、用户因商品确实存在质量问题而提出的退换货要求的，商家应一律承担退换货的售后服务。
                            <br>
                            2、商家发货后，由于物流配送中导致商品毁损等问题的，商品未经使用且包装附件完整不影响二次销售的，商家应承担退换货的售后服务。
                            <br>
                            3、如用户收货后发现错发、漏发情形的，商家按照规定流程退换。
                            <br>
                            4、所有定制商品一律不予退换。
                        </div>
                        <h2>发货说明</h2>
                        <div class="content-wz content-em">
                            16:00前下单，当天可发货（国定节假日，发货可能延后），如遇特殊情况不能按照约定时间发货的，平台人员会提前与您取得联系，与您进行协调。平台默认为圆通快递，圆通不能送达的帮您转为其它快递。如有特殊要求请联系平台客服。
                        </div>
                        <h2>退换货说明</h2>
                        <div class="content-wz content-em">
                            因质量问题、物流配送、错发、漏发等原因退货，商家应承担退换货的售后服务。
                        </div>
                        <h2>验货说明</h2>
                        <div class="content-wz">
                            请您签收时务必验货！
                            <br>
                            本平台保证所寄送的货品均为正品，且包装完好。
                            <br>
                            如物流公司对货品的处理有欠妥当，导致货品外包装有破损的，请当面拆货，确保产品无异议，如有外包装破损、货品变形、断裂等现象请拒收！若由他人代收，出现问题的请及时与平台客服联系！感谢您的理解支持与配合。
                        </div>
                    </div>
                </div>
                  <!-- 关于我们 -->
                  <div class="center-content" v-show="num==6">
                    <h2>关于我们</h2>
                    <div class="content">
                        <h2 class="referral">公司介绍</h2>
                        <div class="content-wz content-em">
                        华诺威汽车零部件（上海）股份有限公司（简称：华诺威）成立于2014年3月，公司以南北大众汽车零部件为核心业务，依托三十多家国内知名汽配股东公司十几年的积淀资源及后续开发的实力，现已成为众多国际、国内强势品牌的独家服务商。合作品牌有:博世、卢卡斯、法雷奥、德尔福、采埃孚、卢卡斯、舍弗勒、伦福德、博格华纳、凯吉、帝伯格茨、马瑞利、马牌等。凭借综合信息系统、物流、金融等功能支撑，公司多方位为战略合作伙伴和普通大众服务。成立以来，华诺威凭借企业文化、专业水平、创新能力和服务水平，已在行业内形成良好的口碑和行业领先的地位。
                        </div>
                        <h2 class="referral">发展历程</h2>
                        <div class="course">
                            <div class="course-content">
                                <div class="course-title">2010年</div>
                                <p>联盟雏形初具2010年，联盟雏形初具</p>
                                <div class="course-text">为了寻求企业更好的发展，上海辽达、昕豫、挺海、凯鹏圣帝4家上海地区的汽配企业互相抱团，集单采购进行分销，形成了我们联盟的雏形。</div>
                            </div>
                            <div class="course-content">
                                <div class="course-title">2014年</div>
                                <p>公司成立</p>
                                <div class="course-text">经过几年的艰辛探索，华诺威正式成立，主要以南北大众汽车零部件分销为核心业务。</div>
                            </div>
                             <div class="course-content">
                                <div class="course-title">2015年</div>
                                <p>新址建立</p>
                                <div class="course-text">华诺威在各战略合作伙伴，各股东单位的支持下，新址建立。</div>
                            </div>
                            <div class="course-content">
                                <div class="course-title">2016年</div>
                                <p>联盟不断扩大</p>
                                <div class="course-text">华诺威完成了1.8亿的目标销售额，同时联盟单位达到三十多家。</div>
                            </div>
                            <div class="course-content">
                                <div class="course-title">2017年</div>
                                <p>持续发力</p>
                                <div class="course-text">华诺威完成了2.5亿的目标销售额，同时地级市股东不断增多。</div>
                            </div>
                            <div class="course-content">
                                <div class="course-title">2018年</div>
                                <p>业界影响力</p>
                                <div class="course-text">华诺威在2018年完成了2.6亿的目标销售额，在业界获得一致好评。</div>
                            </div>
                            <div class="course-content">
                                <div class="course-title">2019年</div>
                                <p>城市合伙人全国招募</p>
                                <div class="course-text">华诺威城市合伙人计划全面推进</div>
                            </div>
                            <div class="course-content">
                                <div class="course-title">2020年</div>
                                <p>乘风破浪，逆势增长</p>
                                <div class="course-text">
                                华诺威互联网建设，逐步完善，业界影响力进一步加强 <br />
                                蓄势待发，开启2021新征程
                            </div>
                            </div>
                        </div>
                        <h2 class="referral">公司荣誉</h2>
                        <div class="trophy">
                            <div class="trophy-top">
                                <div class="trophy-top-content">
                                    <img src="../../assets/my/service/trophy1.png" alt="">
                                    <div class="introduce">
                                        <p class="presentation-size">2020采埃孚</p>
                                        <p class="presentation-b">售后经销商大会王者之师奖</p>
                                    </div>
                                </div>
                                <div class="trophy-top-content">
                                    <img src="../../assets/my/service/trophy2.png" alt="">
                                    <div class="introduce">
                                        <p class="presentation">2020年度</p>
                                        <p class="presentation-b">中国汽配供应链金奖</p>
                                        <p class="presentation-b">卓越车型供应链平台</p>
                                    </div>
                                </div>
                                <div class="trophy-top-content">
                                    <img src="../../assets/my/service/trophy3.png" alt="">
                                    <div class="introduce">
                                        <p class="presentation">2020年度</p>
                                        <p class="presentation-b">中国汽车后市场最受欢迎品牌大奖</p>
                                        <p class="presentation-b">最具有影响力奖</p>
                                    </div>
                                </div>
                                <div class="trophy-top-content">
                                    <img src="../../assets/my/service/trophy4.png" alt="">
                                    <div class="introduce">
                                        <p class="presentation-size">2020年度</p>
                                        <p class="presentation-b">长三角车型件领军型卡斯夫奖</p>
                                    </div>
                                </div>
                                <div class="trophy-top-content">
                                    <img src="../../assets/my/service/trophy5.png" alt="">
                                    <div class="introduce">
                                        <p class="presentation-size">2020年度</p>
                                        <p class="presentation-b">最具有潜力全车件企业奖</p>
                                    </div>
                                </div>
                            </div>
                            <div class="trophy-bottom">
                                <div class="trophy-bottom-content">
                                    <img src="../../assets/my/service/trophy6.jpg" alt="">
                                    <div class="introduce">
                                        <p class="presentation-size">2019年度</p>
                                        <p class="presentation-b">上海汽车配件用品行业协会副会长单位</p>
                                    </div>
                                </div>
                                <div class="trophy-bottom-content">
                                    <img src="../../assets/my/service/trophy7.jpg" alt="">
                                    <div class="introduce">
                                        <p class="presentation-size">2019年度</p>
                                        <p class="presentation-b">GA国际汽配联盟——突出贡献奖</p>
                                    </div>
                                </div>
                                <div class="trophy-bottom-content">
                                    <img src="../../assets/my/service/trophy8.png" alt="">
                                    <div class="introduce">
                                        <p class="presentation-size">2019年度</p>
                                        <p class="presentation-b">中国汽车售后零部件行业服务质量</p>
                                        <p class="presentation-b">采购方优选企业</p>
                                    </div>
                                </div>
                                <div class="trophy-bottom-content">
                                    <img src="../../assets/my/service/trophy9.png" alt="">
                                    <div class="introduce">
                                        <p class="presentation-size">2019年度</p>
                                        <p class="presentation-b">中国汽配供应链奖，优秀供应链平台奖</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h2 class="referral">自有品牌</h2>
                        <div class="brand">
                            <div class="brand-content">
                                <!-- <div class="brand-img"> -->
                                    <img src="../../assets/my/service/ATIlogo.png" alt="">
                                <!-- </div> -->
                                <div class="masking">
                                    ATI系列
                                </div>
                            </div>
                            <div class="brand-content">
                                <div class="brand-img">
                                <img src="../../assets/my/service/ATI1.png" alt="">
                                </div>
                                <div class="masking1">
                                    减震器
                                </div>
                            </div>
                            <div class="brand-content">
                                <div class="brand-img">
                                <img src="../../assets/my/service/engine.png" alt="">
                                </div>
                                <div class="masking1">
                                    发动机
                                </div>
                            </div>
                            <div class="brand-content">
                                <div class="brand-img">
                                <!-- <img src="" alt=""> -->
                                </div>
                                <div class="masking1">
                                    
                                </div>
                            </div>
                        </div>
                        <div class="brand">
                            <div class="brand-content">
                                <!-- <div class="brand-img"> -->
                                    <img src="../../assets/my/service/hnwLogo.png" alt="">
                                <!-- </div> -->
                                <div class="masking">
                                    华诺威系列
                                </div>
                            </div>
                            <div class="brand-content">
                                <div class="brand-img">
                                <img src="../../assets/my/service/fan1.png" alt="">
                                </div>
                                <div class="masking1">
                                    鼓风机
                                </div>
                            </div>
                            <div class="brand-content">
                                <div class="brand-img">
                                <img src="../../assets/my/service/refrigerating1.png" alt="">
                                </div>
                                <div class="masking1">
                                    冷冻液
                                </div>
                            </div>
                            <div class="brand-content">
                                <div class="brand-img">
                                <!-- <img src="" alt=""> -->
                                </div>
                                <div class="masking1">
                                    
                                </div>
                            </div>
                        </div>
                        
                        <h2 class="referral">合作伙伴</h2>
                        <div class="cooperation">
                            <img src="../../assets/my/service/trademark.png" alt="">
                        </div>
                    </div>
                </div>
                  <!-- 联系方式 -->
                  <div class="center-content" v-show="num==7">
                    <h2>联系方式</h2>
                    <div class="content">
                        <h2>联系方式</h2>
                        <div class="content-wz">
                           公司总机：021-69585015
                        </div>
                        <h2>配件销售：</h2>
                        <div class="content-wz">
                           简红玉/南区: 19901765660 <br>
                        </div>
                        <div class="content-wz">
                           梁浩/北区  : 19901765156 <br>
                        </div>
                        <div class="content-wz">
                           马宝/中西区: 19916551183 <br>
                        </div>
                        <h2>技术服务：</h2>
                        <div class="content-wz">
                           石亚伟：15262842500
                        </div>
                        <div class="content-wz">
                           版权所有©2020华诺威汽车零部件（上海）股份有限公司&nbsp;&nbsp;Copyright©2020chinawayutoparts
                        </div>

                    </div>
                </div>
                  <!-- 法律声明 -->
                    <div class="center-content" v-show="num==8">
                    <h2>法律声明</h2>
                    <div class="content roll" >
                        <h2 style="text-align: center;">《华诺威汽配供应链专家用户服务协议》</h2>
                        <div class="content-wz">
                           感谢您成为华诺威汽配供应链专家的注册用户。
                           <br>
                           特别提示：
                           <br>
                           当您按照注册页面提示填写信息、阅读并同意本协议且完成全部注册程序后，或您按照激活页面提示填写信息、阅读并同意本协议且完成全部激活程序后，或您以其他华诺威汽配供应链专家允许的方式实际使用本服务时，即表示您已充分阅读、理解并接受本协议的全部内容，并与华诺威汽配供应链专家达成协议。
                           <br>
                           您承诺接受并遵守本协议的约定，届时您不应以未阅读本协议的内容或者未获得华诺威汽配供应链专家对您问询的解答等理由，主张本协议无效，或要求撤销本协议。
                        </div>
                        <h2>一、协议相关声明</h2>
                        <div class="content-wz">
                          1. 协议内容包括协议正文及所有华诺威汽配供应链专家已经发布的或将来可能发布的各类规则。所有规则为本协议不可分割的组成部分，与协议正文具有同等法律效力。
                          <br>
                          2. 您在同意所有协议条款并完成注册所有程序后，才能成为本站的正式用户，此时您可以享受本站完整的  服务。只要您使用华诺威汽配供应链专家，本协议即对您产生约束，届时您不应以未阅读本协议的内容或者  未获得华诺威汽配供应链专家对您问询的解答等理由，主张本协议无效，或要求撤销本协议。
                          <br>
                          3. 您同意：本协议条款是处理双方权利义务的契约，始终有效，法律另有强制性规定或双方另有特别约定  的，依其规定。
                          <br>
                          4. 您承诺接受并遵守本协议的约定。如果您不同意本协议的约定，您应立即停止注册程序或停止使用华诺  威汽配供应链专家平台服务。
                          <br>
                          5. 华诺威汽配供应链专家有权根据需要不定期地制订、修改本协议及/或各类规则，并在华诺威汽配供应链  专家公示，不再另行单独通知用户。变更后的协议和规则经网站公示期满后，立即生效。如您不同意相关变  更，应当立即停止使用华诺威汽配供应链专家平台服务。您继续使用华诺威汽配供应链专家平台服务，即表  明您接受修订后的协议和规则。
                          <br>
                          6. 用户有义务不时关注并阅读最新版的协议及网站公告。
                          <br>
                          7. 如果本协议中任何一条被视为废止、无效或因任何理由不可执行，该条应视为可分的且并不影响任何其  余条款的有效性和可执行性。
                        </div>
                        <h2>二、账户管理</h2>
                        <div class="content-wz">
                            1、主体资格
                            <br>
                            您确认，在您完成注册程序或以其他华诺威汽配供应链专家允许的方式实际使用本服务时，您应当是具备完全民事行为能力和完全民事力的自然人、法人或其他组织。
                            <br>
                            2、注册和账户
                            <br>
                            ①　当您按照注册页面提示填写信息、阅读并同意本协议且完成全部注册程序后，或在您按照激活页面提示填写信息、阅读并同意本协议且完成全部激活程序后，或您以其他华诺威汽配供应链专家允许的方式实际使用本服务时，您即受本协议约束。您可以使用您提供或确认手机号码或者华诺威汽配供应链专家允许的其它方式作为登录手段进入华诺威汽配供应链专家。
                            <br>
                            ②　您注册成功后，华诺威汽配供应链专家会为您开通一个账户，作为您使用本服务的唯一身份标识。该账户的所有权归属于华诺威，您仅拥有使用权。登录账户时，您可以使用您提供或确认的用户名、邮箱、手机号码或华诺威汽配供应链专家允许的其他方式作为注册用户名进行登录，但在登录时您必须输入您设定并保管的账户密码。为保护您的权益，您在设定账户的密码时，请勿使用重复性或者连续数字的简单密码。请您对密码加以妥善保管，切勿将密码告知他人，因密码保管不善而造成的所有损失由您自行承担。
                            <br>
                            ③ 您了解并同意，如您在华诺威汽配供应链专家注册成功，您在此明确授权，您的账户注册信息在您通过华诺威汽配供应链专家注册成功时，已授权华诺威汽配供应链专家使用，以使您更便捷地使用本服务。
                            <br>
                            ④　您可以对账户设置华诺威汽配供应链专家昵称。您设置的昵称不得侵犯或涉嫌侵犯他人合法权益。如您设置的昵称涉嫌侵犯他人合法权益，华诺威有权终止向您提供本服务，注销您的昵称。若您不再是华诺威汽配供应链专家的注册用户，对于您的原注册账户的昵称，华诺威可以将其开放给任意注册用户。
                            <br>
                            3、用户信息
                            <br>
                            ①　在注册或激活流程时，您应当依照法律法规要求，按相应页面的提示准确提供您的资料，并于资料信息变更时及时更新，以保证您所提交资料的真实、及时、完整和准确。如有合理理由怀疑您提供的资料错误、不实、过时或不完整的，华诺威有权向您发出询问及/或要求改正的通知，并有权直接做出删除相应资料的处理，直至中止、终止对您提供部分或全部服务。华诺威对此不承担任何责任，您将承担因此产生的任何直接或间接损失及不利后果。
                            <br>
                            ②　您应当准确填写并及时更新您提供的联系电话、车辆信息等联系方式，以便华诺威汽配供应链专家或其他商家与您进行有效联系，因通过这些联系方式无法与您取得联系，导致您在使用华诺威汽配供应链专家服务过程中产生任何损失或增加费用的，应由您完全独自承担。您了解并同意，您有义务保持你提供的联系方式的真实性和有效性，如有变更或需要更新的，您应按华诺威汽配供应链专家的要求进行操作。
                            <br>
                            4、账户安全
                            <br>
                            ①　华诺威汽配供应链专家只允许每位用户使用一个华诺威汽配供应链专家平台账户。如有证据证明或华诺威汽配供应链专家有理由相信您存在注册或使用多个华诺威汽配供应链专家平台账户的情形，华诺威有权采取冻结或关闭账户、取消订单、拒绝提供服务等措施，如给华诺威汽配供应链专家平台及相关方造成损失的，您还应承担赔偿责任。
                            <br>
                            ②　您对通过您的账户所进行的活动、行为和事件依法享有权利和承担责任，且不得以任何形式转让账户、授权他人使用您的账户以及与他人交易账户。
                            <br>
                            ③　鉴于网络服务的特殊性， 华诺威汽配供应链专家无义务审核是否是您本人使用该组用户名及密码，仅审核用户名及密码是否与数据库中保存的一致，任何人只要输入的用户名及密码与数据库中保存的一致，即可凭借该组用户名及密码登陆并获得华诺威汽配供应链专家所提供的各类服务，所以即使您认为您的账户登陆行为并非您本人所为，华诺威将不承担因此而产生的任何责任。
                            <br>
                            ④　您可以通过在华诺威汽配供应链专家平台参与商品评价、晒单、网站活动等方式获得积分、返利和抵用券等。积分和抵用券都具有特定的使用规则和有效期，逾期将被清零；请您不时关注您账户中的积分和抵用券的有效期，在有效期届满前，华诺威将不再另行作特别通知；华诺威不对逾期清零的积分和抵用券负责。对于恶意购买和不正当手段获得的积分和抵用券，华诺威有权作出独立判断并采取包含但不限于冻结您的用户账户或清空积分、抵用券等措施。
                            <br>
                            5、华诺威汽配供应链专家登录名注销
                            <br>
                            a）若您在华诺威汽配供应链专家成功注册账户后，连续24个月时间内未使用该账户（包括使用此账户订购商家商品，发表使用体验，参与商品讨论，参加华诺威汽配供应链专家平台的有关活动以及使用其他服务），则华诺威有权对您的账户进行清理，届时您的华诺威汽配供应链专家账号可能被注销，不能再登录华诺威汽配供应链专家，所有华诺威汽配供应链专家服务同时终止。
                            <br>
                            b）您向华诺威汽配供应链专家提出注销账户申请，经华诺威汽配供应链专家平台审核同意后，由华诺威汽配供应链专家注销该用户账户，本协议即告终止。但在注销该用户账户后，华诺威仍有权：
                            <br>
                            ① 保留该用户的注册信息及过往的全部交易行为记录；
                            <br>
                            ② 如用户在账户注销前在华诺威汽配供应链专家上存在违法行为或违反本协议的行为，华诺威仍可行使本协议所规定的权利。
                            <br>
                            c）在下列情况下，华诺威汽配供应链专家可以通过注销用户账户的方式单方解除本协议：
                            <br>
                            ① 在用户违反本协议相关规定时，华诺威有权暂停或终止向该用户提供服务。如该用户在华诺威汽配供应链专家暂停或终止提供服务后，再一次直接或间接或以他人名义注册为华诺威汽配供应链专家平台用户的，则华诺威有权再次暂停或终止向该用户提供服务；
                            <br>
                            ② 一经发现用户注册信息中的内容是虚假的，华诺威即有权终止向该用户提供服务；
                            <br>
                            ③ 本协议修改或更新时，如用户表示不愿接受新的服务协议的，华诺威有权终止向该用户提供服务；
                            <br>
                            ④ 华诺威认为需终止提供服务的其他情况。
                            <br>
                            d）用户理解并同意，即便在本协议终止及用户的服务被终止后，华诺威仍有权：
                            <br>
                            ① 继续保存并使用您的用户信息；
                            <br>
                            ② 继续向用户主张在其使用华诺威汽配供应链专家交易平台服务期间因违反法律法规、本协议及平台规则而应承担的责任。
                        </div>
                         <h2>三、用户规范</h2>
                        <div class="content-wz">
                            通过华诺威汽配供应链专家，您发布信息的内容须严格遵守以下禁止事项：
                            <br>
                            1. 禁止发布发表损害国家、民族、社会公共利益和涉及国家安全的信息资料或言论；
                            <br>
                            2. 禁止利用本站从事洗钱、窃取商业秘密、窃取用户信息等违法犯罪活动；
                            <br>
                            3. 禁止干扰本站的正常运转，不得侵入本站信息系统；
                            <br>
                            4. 禁止发表任何违法犯罪、淫秽、不文明等信息资料；
                            <br>
                            5. 禁止传输或发表损害国家社会公共利益和涉及国家安全的信息资料或言论；
                            <br>
                            6. 禁止利用在本站注册的账户进行非法牟利性经营活动；
                            <br>
                            7. 禁止发布任何侵犯他人著作权、商标权等知识产权或合法权利的内容；
                            <br>
                            本站保有删除站内各类不符合法律政策或不真实信息的内容而无须通知您的权利。
                            若您未遵守以上规定，本站有权做出独立判断并采取暂停或关闭您的帐号等措施。您须对自己在网上的言论和行为承担法律责任。
                        </div>
                        <h2>四、华诺威汽配供应链专家服务</h2>
                        <div class="content-wz">
                           1、通过华诺威汽配供应链专家提供服务，商家可在华诺威汽配供应链专家上发布信息、查询商品和服务信息、达成交易意向并进行交易、对其他商家进行评价、参加华诺威组织的活动以及使用其它信息服务及技术服务，具体以所开通的华诺威汽配供应链专家提供的服务内容为准。
                           <br>
                            2、您在华诺威汽配供应链专家上交易过程中与其他商家发生交易纠纷时，一旦您或其他商家任一方或双方共同提交华诺威要求调处，则华诺威作为独立第三方，有权根据单方判断做出调处决定，您了解并同意接受华诺威的判断和调处决定。
                            <br>
                            3、您了解并同意，华诺威有权应政府部门（包括司法及行政部门）的要求，向其提供您向华诺威汽配供应链专家提供的用户信息和交易记录等必要信息。如您涉嫌侵犯他人知识产权等合法权益，则华诺威汽配供应链专家亦有权在初步判断涉嫌侵权行为存在的情况下，向权利人提供您必要的身份信息。
                            <br>
                            4、您在使用华诺威汽配供应链专家服务过程中，所产生的应纳税赋，以及一切硬件、软件、服务及其他方面的费用，均由您独自承担。
                        </div>
                        <h2>华诺威汽配供应链专家服务使用规范</h2>
                        <div class="content-wz">
                             1、在华诺威汽配供应链专家上使用本服务过程中，您承诺遵守以下约定：
                             <br>
                            ①　实施的所有行为均遵守国家法律、法规等规范性文件及华诺威汽配供应链专家各项规则的规定和要求，不违背社会公共利益或公共道德，不损害他人的合法权益，不偷逃应缴税费，不违反本协议及相关规则。
                            <br>
                            ②　不以虚构或歪曲事实的方式不当评价其他商家，不采取不正当方式制造或提高自身的信用度，不采取不正当方式制造或提高（降低）其他商家的信用度。
                            <br>
                            ③　不对华诺威汽配供应链专家上的任何数据作商业性利用，包括但不限于在未经华诺威事先书面同意的情况下，以复制、传播等任何方式使用华诺威汽配供应链专家站上展示的资料。
                            <br>
                            ④　不使用任何装置、软件或例行程序干预或试图干预华诺威汽配供应链专家的正常运作或正在华诺威汽配供应链专家上进行的任何交易、活动。您不得采取任何将导致不合理的庞大数据负载加诸华诺威汽配供应链专家网络设备的行动。
                        </div>
                         <h2>五、 订单生效规则</h2>
                        <div class="content-wz">
                            1、当您下订单时，请您仔细确认所购商品的名称、价格、数量、型号、规格、尺寸、联系地址、电话、收货人等信息。收货人与您本人不一致的，收货人的行为视为您的行为，您对收货人的行为承担连带责任。
                            <br>
                            您提交订单即表示对订单中所确认的订购商品、收货地址等信息的准确性负责。如果因为您填写的收货人联系电话、地址等信息错误，导致未能如约定获取华诺威汽配供应链专家提供的产品和服务的，由此造成的损失需由您自行承担。
                                <br>
                            2、您理解并认可华诺威汽配供应链专家平台上的订单生效规则：
                            <br>
                            ①华诺威汽配供应链专家平台上商家展示的商品信息（如商品名称、价格、商品描述等）仅构成要约邀请。当您通过华诺威汽配供应链专家平台订购商家商品，确定购买并成功提交订单时（订单内容应包含购买的商品数量、价格及支付方式、收货人、联系方式、收货地址等信息），即视为您通过华诺威汽配供应链专家向平台的商家发出了购买订单商品的要约。
                            <br>
                            ②华诺威汽配供应链专家平台上展示的商家商品和价格等信息仅构成要约邀请，您下单时须填写您希望购买的商品数量、价款及支付方式、收货人、联系方式、收货地址（合同履行地点）、合同履行方式等内容，当用户提交订单时即视为用户通过华诺威汽配供应链专家向华诺威汽配供应链专家平台上的商家发出了购买订单商品的要约；
                            <br>
                            ③系统生成的订单信息是计算机信息系统根据您填写的内容自动生成的数据，仅是您向华诺威汽配供应链专家平台商家发出的合同要约； 华诺威汽配供应链专家平台商家收到您的订单信息后，只有商家将您在订单中订购的商品从仓库实际直接向您发出时（以商品出库为标志），方视为您与华诺威汽配供应链专家平台商家之间就实际直接向您发出的商品建立了合同关系；
                            <br>
                            ④如果您在一份订单里订购了多种商品并且华诺威汽配供应链专家平台商家只给您发出了部分商品时，您与华诺威汽配供应链专家平台商家之间仅就实际直接向您发出的商品建立了合同关系；只有在华诺威汽配供应链专家平台商家实际直接向您发出了订单中订购的其他商品时，您和华诺威汽配供应链专家平台商家之间就订单中该其他已实际直接向您发出的商品才成立合同关系。
                            <br>
                            3、华诺威汽配供应链专家平台商家仅向消费者提供商品销售服务，若您并非因生活消费购买商品，而是中间商、零售商或批发商，则华诺威汽配供应链专家平台商家有权单方取消订单。
                            <br>
                            4、若您拟购买的商品超过网站内公示的购买数量限制，请您与华诺威汽配供应链专家客服联系。对于您已经从非团购渠道或非第三方商家指定渠道提交的订单，华诺威汽配供应链专家或华诺威汽配供应链专家平台商家有权单方予以取消。
                            <br>
                            5、 您有权在下列情况下，取消订单：
                            <br>
                            ①经与华诺威平台商家协商达成一致的；
                            <br>
                            ②华诺威平台商家对用户订单做出承诺之前；
                            <br>
                            ③华诺威平台商家网站上公布的商品价格发生变化或错误，用户在华诺威平台商家发货之前通知华诺威平台商家的。                                                                               <br>                                   
                            6、华诺威汽配供应链专家平台商家在下列情况下，可以取消用户订单：
                                <br>
                            ① 经与用户协商达成一致的；
                            <br>
                            ② 华诺威平台商家网站上显示的商品信息明显错误或缺货的；
                            <br>
                            ③ 用户订单信息明显错误或用户订购数量超出华诺威平台商家备货量的。
                            <br>
                            7、 华诺威平台商家在下列情况下，亦可取消用户订单：
                            <br>
                            ① 因不可抗力、华诺威平台系统发生故障或遭受第三方攻击，及其他华诺威平台无法控制的情形，根据华诺威平台判断需要取消用户订单的；
                            <br>
                            ② 经华诺威平台判断，用户订购行为不符合公平原则或诚实信用原则的情形；
                            <br>
                            ③ 按华诺威平台已经发布的或将来可能发布或更新的各类规则，可取消用户订单的其他情形。
                        </div>
                        <h2>六、 协议终止</h2>
                        <div class="content-wz">
                           协议终止情况：<br>
                            1. 注册信息中的主要内容不真实或不准确或不及时或不完整时。<br>
                            2. 本协议（含规则）变更时，您明示并通知华诺威不愿接受新的服务协议的；<br>
                            3. 您主动向华诺威汽配供应链专家要求注销您的账户，经华诺威汽配供应链专家审核同意， 华诺威汽配供应链专家注销（永久冻结）您的账户。<br>

                            协议终止后相关事宜：<br>
                            1.您的账户被注销（永久冻结）后，华诺威没有义务为您保留或向您披露您账户中的任何信息，也没有义务向您或第三方转发任何您未曾阅读或发送过的信息。<br>
                            2.华诺威有权继续保存您的注册信息及您使用华诺威汽配供应链专家平台服务期间的所有交易信息。<br>
                            3.您在使用华诺威汽配供应链专家平台服务期间存在违法行为或违反本协议和/或规则的行为，华诺威仍可依据本协议向您主张权利。

                        </div>
                        <h2>七、 知识产权</h2>
                        <div class="content-wz">
                          1. 用户一旦接受本协议，即表明该用户主动将其在任何时间段在本平台发表的任何形式的信息内容（包括但不限于客户评价、客户咨询、各类话题文章等信息内容）的财产性权利等任何可转让的权利，如著作权财产权（包括并不限于：复制权、发行权、出租权、展览权、表演权、放映权、广播权、信息网络传播权、摄制权、改编权、翻译权、汇编权以及应当由著作权人享有的其他可转让权利），全部独家且不可撤销地转让给平台所有，您同意平台有权就任何主体侵权而单独提起诉讼。
                          <br>
                            2. 本协议已经构成《中华人民共和国著作权法》第二十五条及相关法律规定的著作财产权等权利转让书面协议，其效力及于用户在平台上发布的任何受著作权法保护的作品内容，无论该等内容形成于本协议订立前还是本协议订立后。<br>
                            3. 除法律另有强制性规定外，未经平台明确的特别书面许可，任何单位或个人不得以任何方式非法地全部或部分复制、转载、引用、链接、抓取或以其他方式使用本平台的信息内容，否则，华诺威有权追究其法律责任。<br>
                            4. 华诺威汽配供应链专家所刊登的资料信息（诸如文字、图表、标识、按钮图标、图像、声音文件片段、数字下载、数据编辑和软件），均是华诺威的财产，受中国和国际版权法的保护。华诺威汽配供应链专家上所有内容的汇编是华诺威的排他财产，受中国和国际版权法的保护。华诺威汽配供应链专家上所有软件都是华诺威或其关联公司或其软件供货商的财产，受中国和国际版权法的保护。<br>
                            5. 华诺威是本站的制作者，拥有此网站内容及资源的著作权等合法权利，受国家法律保护，有权不时地对本协议及本站的内容进行修改，并在本站公布，无须另行通知您。在法律允许的最大限度范围内，华诺威对本协议及本站内容拥有解释权。<br>

                        </div>
                        <h2>八、 免责声明</h2>
                        <div class="content-wz">
                             华诺威有义务确保华诺威汽配供应链专家平台的正常运行，尽力避免服务中断或将中断时间限制在最短时间内，保证您在平台交易活动的顺利进行。但以下情况，华诺威无需承担任何责任：<br>
                            1. 您将账号密码告知他人或与他人共享注册账户，由此导致任何资料的泄露。<br>
                            2. 黑客攻击、计算机病毒侵入、政府管理等不可抗力造成本平台崩溃或无法正常使用，从而导致交易无法完成或信息、记录丢失。<br>
                            3. 您向本平台提供错误、不完整、不实信息等造成不能正常使用本平台服务或遭受任何其他损失。
                        </div>
                        <h2>九、 法律适用、管辖与其他</h2>
                        <div class="content-wz">
                            1. 本协议之效力、解释、变更、执行与争议解决均适用中华人民共和国大陆地区法律，如无相关法律规定的，则应参照通用国际商业惯例和（或）行业惯例。
                            <br>
                            2. 本协议包含了您使用平台需遵守的一般性规范，您在使用平台时还需遵守适用于该平台的特殊性规范。一般性规范如与特殊性规范不一致或有冲突，则特殊性规范具有优先效力。
                            <br>
                            3. 如您对本协议或其执行内容有任何异议，双方应尽力友好协商解决，协商不成时，您同意以平台注册地（上海嘉定区）所在地人民法院为第一审管辖法院。
                        </div>
                        <h2>其他</h2>
                        <div class="content-wz">
                          华诺威汽配供应链专家尊重用户和消费者的合法权利，本协议及本网站上发布的各类规则、声明等其他内容，均是为了更好的、更加便利的为您提供服务。本站欢迎您提出意见和建议，华诺威将虚心接受并适时修改本协议及本站上的各类规则。
                        </div>
                    </div>
                </div> 
              </div>
          </div>
      </div>
  </div>
</template>

<script>
export default {
    data(){
        return {
           num:"0",
           
        }

    },
    methods:{
        toHome(){
           
            if(this.$store.state.shop&&this.$store.state.shop!=1780){ 
                this.$router.push(`/store/businesses/${this.$store.state.shop}`)
            }else{ 
                if(this.pageMode==2){
                    this.$router.push('/find/index')
                }else{
                    this.$router.push('/')
                }
            }
        }
    },
    created(){
        this.num=this.$route.params.num 
    }
}

</script>

<style lang="scss" scoped >
@font-face {
  font-family: STFWXQ;
  src: url(../../styles/书体坊王学勤钢笔行书.ttf);
}
.service{
    padding-bottom: 50px;
    background-color: #f7f7f7;
    .nav{
        position: relative;
        height: 72px;
        background-color: #50A3F8FF;
        
        .nav-center{
            width: 1200px;
            margin: 0 auto;
            img {
                margin-top: 17px;
                vertical-align: bottom;
                width: auto;
                height: 41px;
                &:nth-child(2){
                    margin-bottom: 5px !important;
                    margin-left: 10px;
                }
            }
            .logo-text{ 
                width: 187px;
                height: auto;
            }
        }
        .nav-right{
            position: absolute;
            top: 0;
            right: 0;
            // float: right;
            height: 72px;
            width: 150px;
            line-height: 72px;
            color: #fff;
            cursor:pointer;
            img{
                width: 18px;
                height: 18px;
                vertical-align:middle;
            display:inline-block;
            }
        }
    }
    .w{
        width: 1200px;
        margin: 0 auto;
        .centre{
            display: flex;
            padding: 0 20px;
            .color{
                font-size: 16px;
                color: #333;
                font-weight: 700;
            }
            .center-left{
               
                width: 160px;
               
                background-color: #fff;
                margin-top: 20px;
               .active{
                   color: #3E84D6 !important;
               }
                .service-centre{
                    height: 56px;
                    color: #333;
                    line-height: 56px;
                    font-weight: 700;
                    border-bottom: 1px solid #DFDFDF;
                    font-size: 16px;
                    img{
                        width: 15px;
                        height: 15px;
                        margin: 0 8px 0 10px;
                    }
                }
                .guide{
                    height: 153px;
                    border-bottom: 1px solid #dfdfdf;
                    // text-align: center;
                    padding-left: 33px;
                    li {
                        cursor:pointer;
                        color: #666;
                        padding-top: 20px;
                    }
                }
                 .guide1{
                    height: 120px;
                    border-bottom: 1px solid #dfdfdf;
                    // text-align: center;
                    padding-left: 33px;
                    li {
                        cursor:pointer;
                        color: #666;
                        padding-top: 20px;
                    }
                }
                 .guide2{
                    height: 160px;
                    border-bottom: 1px solid #dfdfdf;
                    // text-align: center;
                    padding-left: 33px;
                    li {
                        cursor:pointer;
                        color: #666;
                        padding-top: 20px;
                    }
                }
            }
            .center-right{
                margin-top: 20px;
                
                width: 980px;
                min-height: 700px;
                background-color:#fff;
                padding: 0 10px;
                margin-left: 20px;
                overflow: hidden;
                

                h2{
                    height: 56px;
                    line-height: 56px;
                    font-size: 16px;
                }
                .content{
                    // font-size: 14px;
                    // padding-top: 20px;
                    border-top: 1px solid #dfdfdf;
                    .br-25{
                        height: 30px;
                    }
                    .br-6{
                        height: 24px;
                    }
                    .content-wz{
                        font-size: 14px;
                        // font-weight: 700;
                        line-height: 28px;
                        img {
                            width: 980px;
                            height: 300px;
                        }
                    }
                    .content-em{
                        text-indent:2em;
                    }
                    .referral{
                        text-align: center;
                        // margin-top: 30px;
                    }
                    .course{
                        display: flex;
                        flex-wrap: wrap;
                        justify-content:space-between;
                        .course-content{
                            width: 234px;
                            height: 230px;
                            border: 1px solid #eee;
                            margin-bottom: 10px;
                            .course-title{
                                font-size: 18px;
                                text-align: center;
                                margin: 30px 0 20px;
                            }
                            >p{
                                text-align: center;
                                margin-bottom: 38px;
                            }
                            .course-text{
                                // text-align: center;
                                padding: 0 8px;
                                line-height: 20px;
                            }
                        }
                    }
                    .trophy{
                        .trophy-top{
                            display: flex;
                            flex-wrap: wrap;
                            justify-content:space-between;
                            .trophy-top-content{
                                width: 188px;
                                height: 255px;
                                margin-bottom: 20px;
                                position: relative;
                                font-size: 12px;
                                border: 1px solid #eee;
                                img{
                                    width: 100%;
                                    height: 100%;
                                }
                                .introduce{
                                    position: absolute;
                                    bottom: 0;
                                    left: 0;
                                    height: 68px;
                                    width: 100%;
                                    background-color: rgba(0,0,0,.5);
                                    // background-color: #000;
                                    // opacity: 0.5;
                                    color: #fff;
                                    .presentation{
                                        text-align: center;
                                        margin: 8px 0 6px;
                                    }
                                    .presentation-size{
                                         text-align: center;
                                        margin: 17px 0 8px;
                                    }
                                    .presentation-b{
                                        text-align: center;
                                        margin-bottom: 6px;
                                    }
                                }
                            }
                        }
                        .trophy-bottom{
                            display: flex;
                            flex-wrap: wrap;
                            justify-content:space-between;
                            .trophy-bottom-content{
                                width: 235px;
                                height: 231px;
                                margin-bottom: 20px;
                                position: relative;
                                font-size: 12px;
                                border: 1px solid #eee;
                                img{
                                    width: 100%;
                                    height: 100%;
                                }
                                .introduce{
                                    position: absolute;
                                    bottom: 0;
                                    left: 0;
                                    height: 68px;
                                    width: 100%;
                                    background-color: rgba(0,0,0,.5);
                                    // background-color: #000;
                                    // opacity: 0.5;
                                    color: #fff;
                                    .presentation{
                                        text-align: center;
                                        margin: 8px 0 6px;
                                    }
                                    .presentation-size{
                                         text-align: center;
                                        margin: 17px 0 8px;
                                    }
                                    .presentation-b{
                                        text-align: center;
                                        margin-bottom: 6px;
                                    }
                                }
                            }
                        }
                    }
                    .brand{
                        display: flex;
                        flex-wrap: wrap;
                        justify-content:space-between;
                        margin-bottom: 10px;
                        .brand-content{
                            width: 238px;
                            height: 174px;
                            border: 1px solid #eee;
                            position: relative;
                            .brand-img{
                                
                                width: 238px;
                                height: 134px;
                                padding: 17px 0 0 56px;
                                box-sizing: border-box;
                                img{
                                    // padding: 39px 42px;
                                    // width: 100%;
                                    // height: 100%;
                                }
                            }
                            
                            .masking{
                                position: absolute;
                                bottom: 0;
                                left: 0;
                                width: 100%;
                                height: 40px;
                                background:rgba(0,0,0,.5);
                                // opacity: 0.1;
                                z-index: 1;
                                text-align: center;
                                color: #fff;
                                line-height: 40px;
                            }
                            .masking1{
                                position: absolute;
                                bottom: 0;
                                left: 0;
                                width: 100%;
                                height: 40px;
                                background-color: #f7f7f7;
                                // opacity: 0.1;
                                z-index: 1;
                                text-align: center;
                                color: #fff;
                                line-height: 40px;
                                color: #444138;
                            }
                        }
                    }
                    .cooperation{
                        width: 960px;
                        height: 370px;
                        border: 1px solid #eee;
                        img{
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
            }
        }
    }
}

</style>