<template>
<div class="address-form-box">
    <el-dialog
        title="收货人地址"
        :visible.sync="AddressVis"
        width="650px"
        @closed="$emit('closed')"
    >
        <div class="address-form">
            <div class="msg-item">
                <div class="msg-label">
                   收货人：
                </div>
                <div class="msg-value">
                    <input type="text" v-model="addressData.true_name" placeholder="收货人姓名">
                </div>
            </div>
           
            <div class="msg-item">
                <div class="msg-label">
                    所在地区：
                </div>
                <div class="msg-value">
                    <AddressSelect @upAdress="upAdress" :site="site" v-if="showSele"></AddressSelect>
                </div>
            </div>
            <div class="msg-item">
                <div class="msg-label">
                    详细地址：
                </div>
                <div class="msg-value">
                    
                    <input type="text" v-model="addressData.address">
                </div>
            </div>
            <div class="msg-item">
                <div class="msg-label">
                    手机号码：
                </div>
                <div class="msg-value">
                    <input type="text" v-model="addressData.mob_phone">
                </div>
            </div>
            <div class="msg-item">
                <div class="msg-label">
                    固定电话：
                    
                </div>
                <div class="msg-value">
                    <input type="text" v-model="addressData.tel_phone">
                </div>
            </div>
            
            <div class="msg-item">
                <div class="msg-label">
                    
                </div>
                <div class="msg-value">
                    <el-checkbox v-model="addressData.choose">设置为默认地址</el-checkbox>
                </div>
            </div>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="subSite" size="mini">确 定</el-button>
            <el-button @click="AddressVis=false" size="mini">取 消</el-button>
        </span>
    </el-dialog>
</div>
</template>
<script>

import AddressSelect from "./AdressSelect.vue";
export default {
    data(){
        return{
            addressData:{
               
                true_name:"",
                area_info:"",
                address:"",
                mob_phone:"",
                // 固定电话
                tel_phone:"",
                // 1 默认  0不是默认
                is_default:0,
                choose:true
            },
            site:[],
            AddressVis:false,
            showSele:true
        }
    },
    props:{
        editData:{
            type:Object,
            default(){
                return{}
            }
        }
    },
    created(){
       
        // if(this.editData.true_name){
        //     console.log("editData",this.editData)
        //     this.addressData = JSON.parse(JSON.stringify(this.editData))
        // }
    },
    methods:{
        
        upAdress(info){
            this.addressData.area_info = info
        },
        // 提交地址
        async subSite(){

            this.addressData.is_default =  this.addressData.choose?1:0
            let res = ''
            
            if(!this.addressData.mob_phone||this.addressData.mob_phone.length<11){
                return this.$erMsg("请填写正确的号码")
            }
            if(this.editData.true_name){ 
                res = await this.$api.editAddr(this.addressData)
            }else{ 

                res = await this.$api.addAddr(this.addressData)
            }
            if(res){
                this.$suMsg("成功")
                this.$emit("getList")
            }else{
                this.$erMsg("失败")
            }
            this.AddressVis = false

        }
    },
    watch:{
        "editData.true_name":{
            deep:true,
            handler(){
                this.showSele = false
                this.addressData = JSON.parse(JSON.stringify(this.editData)) 
                if(this.addressData.area_info){
                    this.site = this.addressData.area_info.split('-')
                }else{
                    this.site = []
                }
                this.$nextTick(()=>{
                    this.showSele = true
                })
            }
        }
    },
    components:{
        AddressSelect
    }
}
</script>

<style lang="scss" scoped>
.address-form-box{
    .el-dialog__header{
        background: #EFEFEF;
        padding: 10px;
        .el-dialog__title{
            line-height: 14px;
            font-size: 14px;
        }
    }
    
    .el-dialog__headerbtn{
        top: 0;
    }
    .el-dialog__body{
        padding: 20px;
        padding-bottom: 0;
        .address-form{
            .msg-item{
                display: flex;
                .msg-label{
                    width: 70px;
                }
                .msg-value{
                    margin-bottom: 8px;
                    >input{
                        width: 260px;
                        outline: none;
                        padding: 2px 4px;
                        border: 1px solid #CCCCCC;
                        &:focus{
                            
                        }
                        &::placeholder{
                            color: #999;
                            font-size: 12px;
                        }
                        // #999999
                    }
                }
            } 

        }
    }
}
</style>