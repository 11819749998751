// index.php?act=setlist&op=getlist
import { post } from "axios"
import store from '../../../store'
const getSetList = async (data)=>{
    return post("index.php?act=store_setlist&op=getlist",{
        ...data
    })
}
// 获取购买记录
const getBuyHisList = async ()=>{
    return post("index.php?act=orders&op=buy_record",{
        member_id:store.state.member_id,
        page:3
    })
}
export {
    getSetList,
    getBuyHisList
}