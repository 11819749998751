<template>
<div class="w">
  <div class="clearInv">
      <div class="goods-list" v-if="storeGoodsList.length>0">
        <el-row  :gutter="8">
        <el-col :span="6" v-for="(item,index) in storeGoodsList" :key="index" class="goods-col">
            <!-- <div class="goods-card" @click="explVis=true"> -->
            <div class="goods-card" @click="goRecommend(item)">
                <div class="img-box" >
                    <img :src="item.homeImg||item.goods_image" alt="">
                    
                </div>
                <div class="card-fot">
                    <div class="fot-bot-price" >
						<div class="showPrice blue" v-if="!$store.state.member_id" >
							会员可见
						</div>
                        <div class="showPrice" v-else>
							
                            <div style="font-size:14px; color:#FF2020; padding-top: 8px;">￥</div>
                            <div class="Price">{{item.goods_price}}</div>
                            <div class="salePrice">￥{{item.oldPrice}}</div> 
                        </div>
                    </div>
                    <div class="fot-top">
                        <span class="goods-name">{{item.goods_name}}</span>
                        <div class="joinCart" @click.stop="joinCart(item)">
                            <img src="../../assets/index/shop.png" alt="">
                        </div>
                    </div>
                    <div class="fot-bot">
                            <div class="fot-right">
                                <div class="store-name" >
                                    {{item.oenaturebrand}}
                                </div>
                                <div class="store-oe">
                                    {{item.oecode}}
                                </div>
                            </div> 
                                <div class="store-inventory">
                                    <span v-if="$store.state.look_inv==1">还剩{{item.goods_storage}}</span>
                                    <span v-else>{{item.goods_storage>0?"有货":"无货"}}</span>
                                    
                                </div>
                    </div>
                </div>
            </div>
        </el-col>
        
        </el-row>
    </div>
	<div class="empty" v-else>
		暂无数据
	</div>
  </div>
</div>
</template>

<script>
export default {
    data() {
        return{
            storeGoodsList:[],
      }
    },
    methods: {
        goRecommend(acti){
            if(this.$isCompany()){
                acti.preRou = "买一送一"
                let rou = '/give/goodsinfo'
                localStorage.setItem("hnw-web-item-data",JSON.stringify(acti))
                
                this.$router.push(rou)
            }
        },
        async joinCart(carItem){
				if(this.$isCompany()){

					let res = ''
					
					let carMessage = {
						goods_id:carItem.goods_id,
						sccode:carItem.sccode,
						oecode:carItem.oecode,
						goods_name:carItem.goods_name,
						goods_price:carItem.goods_price,
						goods_storage:carItem.goods_storage,
						goods_num:2,
						store_id:carItem.store_id,
						store_name:carItem.store_name,
						cart_type:'1',
				
					}
					res = await this.$api.joinCart([carMessage])
					
					// 跳转到加入成功页
					
				}
			},
    },
    async created(){
        this.storeGoodsList = await this.$api.getlistRecom({
            goods_commend:2,
			store_id:1780
        })
		if(this.$store.state.shop==1780){
			// Cang
			let indexRes = []
			let houseInfo = []
			// this.storeGoodsList = this.storeGoodsList.filter(item => item.house_info!=null)
			this.storeGoodsList.forEach((goods) => {
				this.$storageCangInfo(goods)
			}); 
		}
		
		if (this.storeGoodsList.length > 0) {
					let ids = this.storeGoodsList.map(item => item.goods_id)
					let goods_id = ids.join(",")
					// 获取图片
					let listimgs = await this.$api.getGoodsListHomeImg({
						goods_id,
						goods_type: "买一送一",
						shop_id: this.$store.state.shop,
					}) 
					listimgs.forEach(item => {
						this.storeGoodsList.forEach(goods => {
							if (goods.goods_id == item.goods_id) { 
								this.$set(goods, "homeImg", item.url)
							}
						})
					})
				}
			// 处理价格1 2
			// this.storeGoodsList.forEach(item=>{
			// 	if(this.$store.state.sc_id==14){

			// 		item.goods_price = item.goodprice2
			// 	}else{
			// 		item.goods_price = item.goodprice1>0 ?item.goodprice1 : item.goods_price
			// 	}
			// 	item.oldPrice =  this.$hnwTools.times(item.goods_price,1.2)
			// 	// item.goods_price = this.$handlePrice(item.goods_price)
				
			// })
			this.storeGoodsList.forEach(goods=>{
                   
                // store.goods_list.forEach(goods=>{
                    goods.goods_price = this.$handlePrice(goods)
                // })
            }) 
    },
}
</script>

<style lang="scss">
    .w{
        width: 1200px;
        margin: 0 auto;
        .clearInv{
            margin-top: 10px;
            .goods-list{
				background: #fff;
				.goods-col{
					// padding-top: 14px;
					.goods-card{
						background: #fff;
						// width: 290px;
						height: 336px;
						display: flex;
						margin-bottom: 10px;
						flex-direction: column;
						// justify-content: space-between;		
						box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
						border-radius: 6px ;
						.img-box{
						    // cursor: pointer;
							height: 210px;
							display: flex;
							justify-content: center;
							align-items: center;
							img{
								// width: 229px;
								// height: 130px;
								width: 210px;
								height: 210px;
							}
						}
						.card-fot{
							// padding: 14px 10px;
							padding: 0px 14px 10px;
							box-sizing: border-box;
							.fot-bot-price{
									font-size: 18px;
									padding-right: 10px;
									.dot{
										font-size: 14px;
										position: relative;
										left: -3px;
									}
									.blue{
										font-size: 14px;
										color: #3E84D6;
									}
									.showPrice{
										display: flex;
										margin: 20px 0 10px 0;
										.Price{
											font-size: 24px;
											color: #FF2020;
											
										}
										.salePrice{
											font-size: 14px;
											text-decoration: line-through;
											color: #999999;
											padding-top: 8px;
											padding-left: 7px;
										}
									}
								}
							.fot-top{
								display: flex;
								height: 22px;
								position: relative;
								.store-name{
									font-size: 12px;
									border:1px solid #3E84D6;
									padding: 3px 1px;
									margin-right: 5px;
									
									border-radius: 2px;
								}
								.goods-name{
									font-size: 16px;
									font-weight: 500;
									color: #333333;
									
								}
								.joinCart{
									width: 25px;
									height: 23px;
									position: absolute;
									top: 0;
									right: 0;
									cursor:pointer;
									img{
										width: 25px;
										height: 23px;
									}
									}
							}
							.fot-bot{
								display: flex;
								justify-content: space-between;
								padding-top: 6px;
								height: 28px;
								line-height: 28px;
								.fot-right{
									display: flex;
									.store-name{
										min-width: 47px;
										height: 28px;
										padding: 0 4px;
										line-height: 28px;
										text-align: center;
										background: #3E84D6;
										font-size: 14px;
										color: #fff;
										margin-right: 14px;
										// cursor: pointer;
									}
									.store-oe{
										max-width: 130px;
										font-size: 14px;
										color: #3E84D6;
										overflow:hidden;
										white-space:nowrap;
										text-overflow:ellipsis;
									}
								}
								.store-inventory{
									font-size: 14px;
									color: #999999;
								}
							}
						}
					}
				}
				
			}
			.empty{
				text-align: center;
				font-weight: 700;
				font-size: 16px;
				color: #ccc;
				padding: 20px;
			}
        }
    }
</style>