<template>
<div class="inte-groups" :class="{'noBor':noBor}">
    <div class="group-list">
        <div class="group-item" v-for="option in groups" :key="option.value" @click="cutGroup(option)" :class="{'active':group_type==option.value}">
            {{option.title}}
        </div>
        
    </div>
</div>
</template>

<script>
export default {
    data(){
        return{
            group_type:""
        }
    },
    methods:{
        cutGroup(option){
            this.$emit("modeChange",option.value)
            this.group_type = option.value
        },
    },
    created(){
        this.group_type = this.groups[0].value
    },
    props:{
        groups:{
            require:true,
            type:Array
        },
        noBor:{
            default:false
        }
    }
}
</script>

<style lang="scss">
.inte-groups{
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid #3E84D6;
    position: relative;
    // margin-bottom: 10px;
    .group-list{
        display: flex;
        
        .group-item{
            cursor: pointer;
            line-height: 38px;
            
            height: 38px;
            padding: 0 30px;
        }
        .active{
            background: #3E84D6;
            // border: 1px solid #3E84D6;
            color: #fff;
            font-weight: bold;
        }
    }
}
.noBor{
    border: none;
}
</style>