<template>
<div class="store-integral-index">
    <div class="integral-title">
        <div class="w">
            <div class="inte-types">
                <div class="inte-type" :class="{'acti-inte':active==modu}" @click="toModu(modu)" v-for="modu in inteModus" :key="modu">
                    {{modu}}
                </div>
            </div>
            <div class="inteNum">
                <div class="label">
                    我的积分:
                </div>
                <div class="red num">
                    {{$store.state.integral_num}}
                </div>
            </div>
        </div>
    </div>
    <div class="inte-content">
        <div class="w">
            
           <inteGoodsList v-if="active=='积分商城'" @showInteInfo="showInteInfo"></inteGoodsList>
           <inteDetail v-else-if="active=='积分明细'"></inteDetail>
           <inteRecord v-else-if="active=='兑换记录'"></inteRecord>
        </div>
    </div>
    <div class="dia-box">
        <el-dialog
            :show-close="false"
            :visible.sync="dialogVisible"
            width="602px"
        >
            <div class="inte-dia-content">
                <div class="sing-img-box">
                     <div class="sing-btn" @click="singAdd">

                     </div>
                    <div class="handle-box" :class="{'han-10':isSign}" @click="dialogVisible=false">
                        <!-- <i class="el-icon-circle-close"></i> -->
                    </div>
                    <img class="sing-img" src="../../../assets/integral/signover.png" alt="" v-if="isSign">   
                    <img class="sing-img" src="../../../assets/integral/sing.png" alt="" v-else>   
                </div>
            </div>
        </el-dialog>
    </div>
</div>
</template>

<script>
import inteGoodsList from "../../integral/inteCompent/inteGoodsList.vue"
import inteDetail from "../../integral/inteCompent/inteDetail.vue"
import inteRecord from "../../integral/inteCompent/inteRecord.vue"
export default {
    data(){
        return{
           inteModus:["积分商城","积分明细","兑换记录","积分签到"],
           active:"积分商城",
        //    今日是否已经签到
           isSign:false,
           dialogVisible:false
        }
    },
    methods:{ 
        async toModu(modu){
            if(modu=="积分签到"){
                
                let nowTime = new Date()
                let mouth = nowTime.getMonth()+1
                let nowTimeStr  = (mouth<10?"0"+mouth:mouth) + "-" + (nowTime.getDate()<10?"0"+nowTime.getDate():nowTime.getDate())
                let params ={
                }
                if(this.$route.params.id){
                    params.shop = this.$route.params.id ||"1780"
                }
                let res = await this.$api.getInteSigninMsg(params)
                console.log("res",res);
                if(res.data&&res.data.length>0){
                    let Index = res.data.findIndex(date=>date==nowTimeStr)
                    if(Index!=-1){
                        console.log("今日已签到");
                        this.isSign = true
                    }else{
                        console.log("今日未签到");
                    }
                }
                this.dialogVisible = true
            }else{
                this.active = modu
            }
        },
        showInteInfo(){
            console.log("??");
            this.$emit("showInteInfo")
        },
        async singAdd(){
            if(this.isSign){
                // this.$suMsg("今天已签到")
                return 
            }
            let params ={
            }
            if(this.$route.params.id){
                params.shop = this.$route.params.id || "1780"
            }
            let res = await this.$api.inteSignAdd(params)
            if(res){
                this.$suMsg("签到成功")
                this.$store.dispatch("getInteNum")
                this.isSign = true
            }else{
                this.$erMsg("签到失败")
            }
        }
    },
    async created(){
        this.$store.dispatch("getInteNum")
        
        // let res = await this.$api.inteSignAdd()
    },
    components:{
        inteGoodsList,
        inteDetail,
        inteRecord
    },
    props:{
        chain:""
    }
}
</script>

<style lang="scss">
.store-integral-index{
    width: 1200px;
    margin: 0 auto;
    .integral-title{
        box-shadow:0px 5px 6px 0px rgba(41, 49, 56, 0.19);
        .w{
            display: flex;
            justify-content: space-between;
            
            .inte-types{
                display: flex;
                >div{
                    padding: 15px;
                    cursor: pointer;
                }
                .acti-inte{
                    color: #3E84D6;
                }
            }
            .inteNum{
                display: flex;
                align-items: center;
                font-weight: 700;
                text-align: center;
                margin-right:10px;
                .label{
                    color: #999;
                    padding-right: 5px;
                }
                .num{
                    border-radius: 3px;
                    background: red;
                    color: #fff;
                    min-width: 36px;
                    box-sizing: border-box;
                    padding: 5px 10px;
                }
            }
        }
    }
    .inte-content{
        // background: #f5f5f5;
        padding-top: 10px;
        .w{
            
        }
    }
    .dia-box{
        .el-dialog{
            background:transparent;
        }
        .el-dialog__header{
            padding: 0;
            border: none;
        }
        .el-dialog__body{
            padding: 0;
            background: transparent;
        }
        .inte-dia-content{
            background: transparent;
            position: relative;
            
            .sing-img-box{
                position: relative;
                background: transparent;
                // bottom: -460px;
                .sing-btn{
                    position: absolute;
                    cursor: pointer;
                    width: 320px;
                    height: 68px;
                   
                    bottom: 30px;
                    left: 50%;
                    transform: translateX(-50%);
                }
                .sing-img{
                    background: transparent;
                }
                .handle-box{
                    position: absolute;
                    right: 24px;
                    top: 68px;
                    z-index: 999;
                    width: 40px;
                    height: 40px;
                    cursor: pointer;
                    
                    i{
                        // color: #fff;
                        font-size: 40px;
                        cursor: pointer;
                    }
                }
                .han-10{
                    top: 10px;
                }
            }
        }
    }
}
</style>