<template>
<div class="Invoice">
    <!-- Invo -->
    <!-- 发票管理 -->
    <div class="btn-box">
        <div class="table-title" v-if="tempNum==1|| tempNum==3">
            <div :class="{title2:tempNum==1}" @click="tempNum=1">发票管理</div>
            <div :class="{title2:tempNum==3}" @click="tempNum=3">地址管理</div>
        </div>
        <el-button v-if="tempNum==1" class="btn" type="primary" size="mini" @click="tempNum=2">新增发票</el-button>
        <el-button v-if="tempNum==3" class="btn" type="primary" size="mini" @click="tempNum=4">新增地址</el-button>
    </div>
    <template v-if="tempNum==1">
        

            <div class="invo-table">
                <div class="table-head">
                    <div>发票类型</div>
                    <div>发票抬头</div>
                    <div>纳税人识别号</div>
                    <div>注册地址</div>
                    <div>注册电话</div>
                    <div>开户银行</div>
                    <div>银行账户</div>
                    <div class="handle">操作</div>
                </div>
                
                <div class="table-body">
                    <div class="table-row" v-for="(item,index) in invoList" :key="index">
                        <div>{{item.inv_type}}</div>
                        <div>{{item.inv_title}}</div>
                        <div>{{item.inv_code}}</div>
                        <div>{{item.inv_reg_addr}}</div>
                        <div>{{item.inv_reg_phone}}</div>
                        <div>{{item.inv_reg_bname}}</div>
                        <div>{{item.inv_reg_baccount}}</div>
                        <div class="handle">
                            <div @click="editInvoStart(item)" class="btn">编辑</div> 

                            <div @click="delInvo(item.inv_id)" class="btn">删除</div>           
                        </div>
                    </div>
                </div>
            </div>
        
        
    </template>
    <!-- 收票地址管理 -->
    <template v-if="tempNum==3">
            <div class="invo-table">
                <div class="table-head">
                    <div>收票人姓名</div>
                    <div>收票人电话</div>
                    <div>收票人地址</div>
                    <div>详细地址</div>
                    <div class="handle">操作</div>
                </div>
                
                <div class="table-body">
                    <div class="table-row" v-for="(item,index) in ressList" :key="index">
                        <div>{{item.name}}</div>
                        <div>{{item.mob_phone}}</div>
                        <div>{{item.provinces}}</div>
                        <div>{{item.address}}</div>
                        <div class="handle">
                            <div @click="editRessStart(item)" class="btn">编辑</div> 

                            <div @click="delRess(item.id)" class="btn">删除</div>           
                        </div>
                    </div>
                </div>
            </div>
        
        
    </template>

    <template v-if="tempNum==2">
        <div class="invo-box">
          <el-form :model="invoData" status-icon :rules="invoRules" ref="demandFrom" label-width="120px" >
                <el-form-item label="发票类型">
                    <el-radio v-model="invoData.inv_type" label="个人">个人</el-radio>
                    <el-radio v-model="invoData.inv_type" label="企业">企业</el-radio>
                </el-form-item>
                
                <el-form-item label="发票抬头" prop="inv_title">
                    <el-input type="text" v-model="invoData.inv_title"></el-input>
                </el-form-item>
                

                <div v-if="invoData.inv_type=='企业'">
                    <el-form-item label="纳税人识别号" prop="inv_code">
                        <el-input type="text" v-model="invoData.inv_code"></el-input>
                    </el-form-item>
                    <el-form-item label="注册地址" >
                        <el-input type="text" v-model="invoData.inv_reg_addr"></el-input>
                    </el-form-item>
                
                    <el-form-item label="注册电话" >
                        <el-input type="text" v-model="invoData.inv_reg_phone"></el-input>
                    </el-form-item>
                    
                    <el-form-item label="开户银行">
                        <el-input type="text" v-model="invoData.inv_reg_bname"></el-input>
                    </el-form-item>
                    
                    <el-form-item label="银行账户" >
                        <el-input type="text" v-model="invoData.inv_reg_baccount"></el-input>
                    </el-form-item>
                    
                </div>
                <el-form-item>
                    <el-button @click="tempNum=1">放弃</el-button>
                    <el-button type="primary" @click="subMethod">{{btnText}}</el-button>
                </el-form-item>
          </el-form>
        </div>
    </template>

    <template v-if="tempNum==4">
        <div class="invo-box">
          <el-form :model="invoData" status-icon :rules="invoRules" ref="demandFrom" label-width="120px" >
                <!-- <el-form-item label="发票类型">
                    <el-radio v-model="invoData.inv_type" label="个人">个人</el-radio>
                    <el-radio v-model="invoData.inv_type" label="企业">企业</el-radio>
                </el-form-item>
                
                <el-form-item label="发票抬头" prop="inv_title">
                    <el-input type="text" v-model="invoData.inv_title"></el-input>
                </el-form-item> -->
                

                
                    <el-form-item label="收票人姓名" prop="inv_code">
                        <el-input type="text" v-model="ressData.name"></el-input>
                    </el-form-item>
                    <el-form-item label="收票人电话" >
                        <el-input type="text" v-model="ressData.mob_phone"></el-input>
                    </el-form-item>
                
                    <el-form-item label="收票人地址" >
                        <!-- <el-input type="text" v-model="invoData.inv_reg_phone"></el-input> -->
                        <AddressSelect @upAdress="upAdress"></AddressSelect>
                        <!-- @upAdress="upAdress" :site="site" -->
                    </el-form-item>
                    
                    <el-form-item label="详细地址">
                        <el-input type="text" v-model="ressData.address"></el-input>
                    </el-form-item>
                    
                  
                
                <el-form-item>
                    <el-button @click="tempNum=3">放弃</el-button>
                    <el-button type="primary" @click="saveData">{{btnText}}</el-button>
                </el-form-item>
          </el-form>
        </div>
    </template>
    
</div>
</template>

<script>
import AddressSelect from "../../components/AdressSelect.vue";
export default {
    data(){
        return{
            invoList:[],
            ressList:[],
            tempNum:1,
            invType:"",
            invoRules:{
               
            },
            btnText:"新增",
            invoData:{
                // 个人还是企业
                inv_type:"个人",
                inv_title:"",
                inv_code:"",
                inv_reg_addr:"",
                inv_reg_phone:"",
                inv_reg_bname:"",
                inv_reg_baccount:"",

            },
            ressData:{
                name:'',
                mob_phone:'',
                provinces:'',
                address:''
            }
        }
    },
    methods:{
        upAdress(info,city){
            console.log("info",info)
            this.ressData.provinces = info
        },
        async getInvoList(){
           this.invoList = await this.$api.getInvoList()
        },
        async getRessList(){
            this.ressList = await this.$api.getRessList()
            console.log("this.ressList",this.ressList)
        },
        subMethod(){
            if(this.invoData.inv_id){
                this.editInvo()
            }else{    
                this.addInvo()
            }
        },
        saveData(){
            if(this.ressData.inv_id){
                this.editRess()
            }else{    
                this.addRess()
            }
        },
        async addInvo(){
            let res = await this.$api.addInvo(this.invoData)
            if(res){
                this.$suMsg("提交成功")
                this.tempNum = 1
                this.getInvoList()
            }else{
                this.$erMsg("提交失败")
            }
        },
         async addRess(){
            let res = await this.$api.addRess(this.ressData)
            if(res){
                this.$suMsg("提交成功")
                this.tempNum = 3
                this.getRessList()
            }else{
                this.$erMsg("提交失败")
            }
        },
        
        editInvoStart(invo){
            this.invoData =  JSON.parse(JSON.stringify(invo))
            
            this.btnText  = "编辑"
            this.tempNum = 2
        },
        editRessStart(invo){
             this.ressData =  JSON.parse(JSON.stringify(invo))
            
            this.btnText  = "编辑"
            this.tempNum = 4
        },
        async editInvo(){
           
            let res = await this.$api.editInvo(this.invoData)
            if(res){
                this.$suMsg("编辑成功")
                this.tempNum = 1
                this.getInvoList()
            }else{
                this.$erMsg("编辑失败")
            }
        },
        async editRess(){
           
            let res = await this.$api.editRess(this.ressData)
            if(res){
                this.$suMsg("编辑成功")
                this.tempNum = 3
                this.getRessList()
            }else{
                this.$erMsg("编辑失败")
            }
        },
        delInvo(inv_id){
            this.$affMsg("确定删除这条发票信息?",async ()=>{
                let res = await this.$api.delInvo(inv_id)
                if(res){
                    this.$suMsg("删除成功")
                    this.getInvoList()
                }else{
                    this.$erMsg("删除失败")
                }
            })
            
        },
        delRess(id){
            this.$affMsg("确定删除这条发票信息?",async ()=>{
                let res = await this.$api.delRess(id)
                if(res){
                    this.$suMsg("删除成功")
                    this.getRessList()
                }else{
                    this.$erMsg("删除失败")
                }
            })
        },
    },
    created(){
        this.getInvoList()
        this.getRessList()
        // this.invoList = [{
        //     inv_type:"发票类型",
        //     inv_title:"11",
        //     inv_code:"12",
        //     inv_reg_addr:"13",
        //     inv_reg_phone:"14",
        //     inv_reg_bname:"15",
        //     inv_reg_baccount:"16",
        // }]
    },
    watch:{
        tempNum(n){
            if(n==1){
                for(let key in this.invoData){
                    if(key!='inv_type'){
                        this.invoData[key] = ''
                    }
                }
                this.btnText  = "新增"
            }else if(n==3){
                for(let key in this.ressData){
                    if(key!='inv_type'){
                        this.ressData[key] = ''
                    }
                }
                this.btnText  = "新增"
            }
        },
        "invoData.inv_type":{
            deep:true,
            handler(n){
                if(n=='个人'){
                    this.invoRules = {
                        inv_title:[
                            { required: true, message: '请输入发票抬头', trigger: 'blur' }
                        ]
                    }
                }else{
                    this.invoRules = {
                        inv_title:[
                            { required: true, message: '请输入发票抬头', trigger: 'blur' }
                        ],
                        inv_code:[
                            { required: true, message: '请输入纳税人识别号', trigger: 'blur' }
                        ],
                    }
                }
            }
        }
    },
    props:{
        
    },
    components:{
        AddressSelect
    }
}
</script>

<style lang="scss">
.Invoice{
    .btn-box{
        display: flex;
        justify-content: space-between;
        // text-align: right;
        // padding: 10px;
        height: 40px;
        line-height: 40px;
        .table-title{
            display: flex;
            >div{
                width: 100px;
                text-align: center;
                cursor:pointer;
            }
            .title2{
                border-bottom: 3px solid skyblue;
            }
        }
        .btn{
            margin-top: 5px;
            height: 30px;
        }
    }
    background: #fff;
    .invo-table{
        text-align: center;
        .table-head{
            display: flex;
            padding: 10px 0;
            margin-bottom: 10px;
            background: #e5e5e5;
            >div{
                flex: 1;
            }
            .handle{
                max-width: 120px;
                min-width: 120px;
            }
        }
        .table-body{
            .table-row{
                display: flex;
                padding: 10px 0;
                border-top: 1px solid #ccc;
                >div{
                    flex: 1;
                }
                .handle{
                    display: flex;
                    justify-content: center;
                    max-width: 120px;
                    min-width: 120px;
                    >.btn{
                        cursor: pointer;
                        margin-right: 5px;
                    }
                }
            }
        }
    }
}
</style>