<template>
<div class="re-part">
    <template v-if="tempNum==1">
        <div class="order-state">
            <div class="order-state-item" :class="{'active':state===item.value}" v-for="(item,index) in  tabLabels" :key="index" @click="state=item.value">
                {{item.label}}
            </div>
            <el-button size="mini" class="apply-btn cont" type="primary" v-if="hasCont" @click="continueAff">继续填写</el-button>
            <el-button size="mini" class="apply-btn" type="primary" @click="tempNum=2">申请售后</el-button>
        </div>


        <ParaSearch 
            api="getAfitsList" 
            ref="ParaSearch"
            :prammsList="prammsList"
            :state="state"
            :searchData="searchData" 
            @searchEnd="searchEnd"
            :type="searchData.type"
        ></ParaSearch>

        <div class="order-list">
            <div class="batch-handle">
                <el-button size="mini" plain type="primary" @click="exportOrder">订单导出</el-button>
            </div>
            <div class="order-head table-head">

                <div class="order-goods">
                    <div class="chec-box">
                        <el-checkbox v-model="allChoose" @change="allChooseChange"></el-checkbox>
                    </div>
                    <div class="w-80">OE号</div>
                    <div class="w-80">生产编码</div>
                    <div class="w-80">商品名称</div>
                    <div class="w-80">品牌</div>
                    <div class="w-60">单价</div>
                    
                    <div class="w-60">申请数量</div>
                    <div class="w-60">审核数量</div>
                    <div class="w-60">赔付数量</div>
                    <!-- 
                    <div class="w-60">待赔数量</div> -->
                    
                </div>
                <div class="order-right">
                    <!-- <div class="status">申请状态</div> -->
                    <div class="status">赔付状态</div>
                    <div class="deal-handle">操作</div>
                </div>
            </div>
            <div class="order-body">
                <div v-for="(order,index) in orderList" :key="index">
                    <div class="order-row" v-for="(goods,index1) in order.goods" :key="index1">

                        <div class="order-msg">
                            <div class="chec-box">
                                <el-checkbox v-model="order.choose"></el-checkbox>
                            </div>
                            <div class="time">{{ $hnwTools.formatDateTime(order.time)}}</div>
                            <div>退货单号：{{order.returnId}}</div>
                            <!-- <div>原订单号：{{order.orderId||'无'}}</div> -->
                        
                            <div class="jdr">申请人：{{order.member_name}}</div>
                        </div>
                        <div class="list-container">
                            
                            <div class="order-goods">
                                <div class="row" >
                                    <div class="chec-box"></div>
                                    

                                    <div class="w-80">{{goods.oecode}}</div>
                                    <div class="w-80">{{goods.sccode}}</div>
                                    <div class="w-80">{{goods.goods_name}}</div>
                                    <div class="w-80">{{goods.oenaturebrand}}</div>
                                    <div class="w-60">{{goods.goods_price}}</div>
                                    <div class="w-60">{{goods.number}}</div>
                                    <div class="w-60" >
                                        <span>
                                            {{goods.tonumber||"--"}}
                                        </span>
                                    </div>
                                    <div class="w-60" >
                                        <span>
                                            {{goods.refuse_number||"--"}}
                                        </span>
                                    </div>
                                    <!-- 

                                    <div class="w-60">
                                        <span v-if="order.state>2">
                                        {{Number(goods.tonumber)- Number(goods.refuse_number) }}
                                        </span>
                                        <span v-else>
                                        --
                                        </span>
                                    </div> -->
                                    
                                    
                                
                                </div>
                                <!-- <MoreUp @toggle="toggle" :order="order" v-if="order.goods.length>1" :defaFlag="true" backColor="#f5f5f5"></MoreUp> -->
                            </div>
                            <div class="order-right">
                                <!-- <div class="status">{{order.state==1?"待审核":"已审核"}}</div> -->
                                <div class="status">
                                    <span>
                                        {{goods.state_text}}
                                    </span>
                                    
                                </div>
                                <div class="handle-btn">
                                    <!-- <div  v-if="!order.ress_order" @click="logisId=order.id;">添加物流</div> -->
                                    <div class="logic" v-if="goods.return_state==3||goods.return_state==4" @click="compenInfo(goods)">赔付详情</div>
                                    <div class="re-order-info" @click="reOrderInfo(order.id,goods)">订单详情</div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <div class="pagin-box">
            <el-pagination
            @size-change="sizeChange"
            @current-change="currentChange"
            @reset="reset"
            :current-page.sync="searchData.curpage"
            :page-size.sync="searchData.page"

            :page-sizes="[5, 10, 20, 50]"
            background
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            :hide-on-single-page="true"
            >
            </el-pagination>
        </div>
        <el-dialog
            :visible.sync="dialogVisible"
            width="980px"
            title="赔付详情"
            top="8vh"
            >
             <el-table
                :data="reGoods"
                stripe
                style="width: 100%">
                <el-table-column
                prop="sele_sn"
                label="销售单号"
                >
                </el-table-column>
                <el-table-column
                prop="retu_type"
                label="赔付类型"
                >
            
                </el-table-column>
                <el-table-column
                prop="number"
                label="退货备注">
                </el-table-column>
            </el-table>
        </el-dialog>
    </template>
    <template v-if="tempNum==2">
        <div class="re-nav">
            <div class="nav-all" @click="tempNum=1">
                所有订单
                <i class="el-icon-arrow-right"></i>
            </div>
            <div>
                申请售后
            </div>
        </div>
        <div class="re-head">
            <NovaSearch 
                api="afitSearchsale" 
                :searchData="searchData1"
                @searchEnd="applySearchEnd"
            ></NovaSearch>
            <el-button size="mini" type="primary" @click="saveData">保存</el-button>
        </div>
        <div class="re-apply-body">
            <div class="apply-item">
                <div class="apply-title">
                    配件列表
                </div>
                <div class="re-part-table">

                    <div class="table-head">
                        <div>OE号</div>
                        <div>生产编码</div>
                        <div>商品名称</div>
                        <div>品牌</div>
                        <div>单价</div>
                        <div >退货数量</div>
                        <div>操作</div>
                    </div>
                    <div class="table-body">
                        <div class="part-row" v-for="(goods,index) in rePartList" :key="index">
                            <div>{{goods.oecode}}</div>
                            <div>{{goods.sccode}}</div>
                            <div>{{goods.goods_name}}</div>
                            <div>{{goods.oenaturebrand}}</div>
                            <div>{{goods.goods_price}}</div>
                            <div>
                                <el-input-number v-model="goods.number" :min="1" label="退货数量" size="mini"></el-input-number>
                            </div>
                            
                            <div>
                                <div class="btn" @click="goodsChange(goods)">
                                    删除
                                </div>
                            </div>
                        </div>

                        <div class="repart-total">
                            共计 <span class="red">{{reTotal.num}}</span> 件商品，商品总金额<span class="red">{{reTotal.price}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="apply-item">
                <div class="apply-title">
                    填写物流<span class="red">(必填!)</span>
                </div>
                <div class="apply-from">
                    <el-form ref="ressFrom" :model="applyData" :rules="rules" label-width="140px" size="mini">
                        <div class="flex-item">
                            <el-form-item label="填写物流单号:" prop="ress_order">
                                <el-input v-model="applyData.ress_order"></el-input>
                            </el-form-item>
                            <el-form-item label="填写物流公司:" prop="ress_code">
                                <el-input v-model="applyData.ress_code"></el-input>
                            </el-form-item>
                        </div>
                        <el-form-item label="填写备注">
                            <el-input type="textarea" v-model="applyData.ress_txt"></el-input>
                        </el-form-item>
                     </el-form>
                </div>
            </div>
            <div class="apply-item">
                <div class="apply-title">
                    填写订单编号<span class="red">(可不填)</span>
                </div>
                <div class="apply-from">
                    <el-form ref="form" :model="applyData" label-width="140px" size="mini">
                        <el-form-item label="填写订单编号:">
                            <el-input v-model="applyData.orderId"></el-input>
                        </el-form-item>
                        
                        <el-form-item label="上传收货单:">
                            <el-upload
                                :action="$axios.defaults.baseURL+'index.php?act=upload&op=image'"
                                list-type="picture-card"
                                :data="upImgData"
                                :headers="{'token':$store.state.token}"
                                multiple
                                name="image"
                                :on-success="upImgSuccessTake"
                                size="mini"
                                >
                                <i class="el-icon-plus"></i>
                            </el-upload>
                        </el-form-item>
                        <el-form-item label="上传实物图:">
                            <el-upload
                                :action="$axios.defaults.baseURL+'index.php?act=upload&op=image'"
                                list-type="picture-card"
                                :data="upImgData"
                                multiple
                                :headers="{'token':$store.state.token}"
                                name="image"
                                :on-success="upImgSuccessPart"
                                >
                                <i class="el-icon-plus"></i>
                            </el-upload>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
            
            <div class="apply-item center">
                <el-button size="mini" type="primary" @click="commitApply">提交申请</el-button>
            </div>
        </div>
        <el-dialog
            :visible.sync="dialogVisible"
            width="980px"
            top="8vh"
            title="搜索结果"
            >
                <div class="re-part-table">
                    <div class="table-head">
                        <div>OE号</div>
                        <div>生产编码</div>
                        <div>商品名称</div>
                        <div>品牌</div>
                        <div>产地</div>
                        <div>价格</div>
                        <div class="goods-rnumber">数量</div>
                        <div>操作</div>
                    </div>
                    <div class="table-body">
                        <div class="part-row" v-for="(goods,index) in searList" :key="index">
                            <div>{{goods.oecode}}</div>
                            <div>{{goods.sccode}}</div>
                            <div>{{goods.goods_name}}</div>
                            <div>{{goods.oenaturebrand}}</div>
                            <div>{{goods.oenatureplace}}</div>
                            <div>{{goods.goods_price}}</div>
                            <div class="goods-rnumber">
                                <el-input-number v-model="goods.number" :min="1" label="退货数量" size="mini"></el-input-number>
                            </div>
                            <div>
                                <el-checkbox v-model="goods.checked" @change="goodsChange(goods)"></el-checkbox>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="re-sear-pagi">
                    <el-pagination
                        :current-page.sync="searchData1.page"
                        :page-size.sync="searchData1.limit"
                        background
                        layout="total, prev, pager, next, jumper"
                        :total="searchData1.total"
                        :hide-on-single-page="true"
                    >
                    </el-pagination>
                </div>
        </el-dialog>
    </template>
    <template v-else-if="tempNum==3">
        <div class="re-nav">
            <div class="nav-all" @click="tempNum=1">
                所有订单
                <i class="el-icon-arrow-right"></i>
            </div>
            <div>
                订单详情
            </div>
        </div>
        <div class="re-apply-body">
            <div class="apply-item">
                <div class="order-info-msg">
                    <div class="msg-left">
                        <div>
                            售后单号：{{orderData.returnId}}
                        </div>
                        <div class="re-order-state">
                            <!-- {{ $hnwTools.getOrderStateText(orderData.state)}} -->
                            {{orderData.state_text}}
                        </div>
                        
                    </div>
                    <div class="msg-right">
                        
                        <div class="order-step-list">
                            <el-steps :active="Number(orderData.state)-1" align-center finish-status="success" process-status="finish">
                                <el-step title="待审核" ></el-step>
                                <el-step title="订单已审核"></el-step>
                                <el-step title="订单已赔付"></el-step>
                                <el-step title="订单已完成"></el-step>
                            </el-steps>
                        </div>
                    </div>
                    
                </div>
            </div>
            <div class="apply-item">
                <div class="order-user-msg">
                    <div class="user-msg-item">
                        <div class="title">
                            退货方信息
                        </div>
                        <div class="msg-body">
                            <div class="msg-row">
                                退货人员：
                                <span>
                                    {{orderData.member_name}}
                                </span>
                            </div>
                            <div class="msg-row">
                                地址：
                                <span>
                                    {{orderData.return_ress}}
                                   
                                </span>
                            </div>
                            <div class="msg-row">
                                手机号：
                                <span>
                                    {{orderData.return_iphone}}
                                </span>
                            </div>
                            <div class="msg-row">
                                退货备注 ：
                                <span>
                                    {{orderData.ress_txt}}
                                </span>
                            </div>
                        
                        </div>
                    </div>

                    <!-- <div class="user-msg-item">
                        <div class="title">
                            收货方信息
                        </div>
                        <div class="msg-body">
                            <div class="msg-row">
                                收货方：
                                <span>
                                
                                    {{orderData.true_name}}
                                </span>
                            </div>
                            <div class="msg-row">
                                地址：
                                <span>
                                    {{orderData.area_info}}
                                    {{orderData.address}}
                                </span>
                            </div>
                            <div class="msg-row">
                                手机号：
                                <span>
                                    {{orderData.mob_phone}}
                                </span>
                            </div>
                            
                        </div>
                    </div> -->
                    <div class="user-msg-item">
                        <div class="title">
                            配送信息
                        </div>
                        <div class="msg-body">
                            <div class="msg-row">
                                退货物流：
                                <span>
                                    {{orderData.ress_code}}
                                </span>
                            </div>
                            <div class="msg-row">
                                退货单号：
                                <span>
                                    {{orderData.ress_order}}
                                </span>
                            </div>
                            <div class="msg-row">
                                返货物流：
                                <span>
                                    {{orderData.return_code}}
                                </span>
                            </div>
                            <div class="msg-row">
                                返货单号：
                                <span>
                                    {{orderData.return_order}}
                                </span>
                            </div>
                        
                        </div>
                    </div>
                    
                </div>
            </div> 
            <div class="apply-item">
                <div class="re-part-table">
                    
                    <div class="table-head">
                        <div>OE号</div>
                        <div>生产编码</div>
                        <div>商品名称</div>
                        <div>品牌</div>
                      
                        <div>价格</div>
                       
                        <div class="w-60">退货数量</div>
                        <div class="w-60">审核数量</div>
                        <div class="w-60">赔付数量</div>
                      
                       
                    </div>
                    <div class="table-body">
                        <div class="table-type">
                            申请列表 
                            <div class="total"> 
                                共计￥ {{total_price}}
                            </div>
                        </div>
                        <div class="part-row" v-for="(goods,index) in orderData.goods" :key="index">
                            <div>{{goods.oecode}}</div>
                            <div>{{goods.sccode}}</div>
                            <div>{{goods.goods_name}}</div>
                            <div>{{goods.oenaturebrand}}</div>
                            <div>{{goods.goods_price}}</div>
                            <div class="w-60">{{goods.number}}</div>
                            <div class="w-60" >
                                <span>
                                    {{goods.tonumber||"--"}}
                                </span>
                            </div>

                            <div class="w-60" >
                                <span>
                                    {{goods.refuse_number||"--"}}
                                </span>
                            </div>
                        </div>
                        <div class="table-type" v-if="orderData.compenGoods&&orderData.compenGoods.length>0">
                           退货列表
                            <div class="total"> 
                                共计￥ {{com_total_price}}
                            </div>
                        </div>
                        <div class="part-row" v-for="(goods,index) in orderData.compenGoods" :key="index">
                            <div>{{goods.oecode}}</div>
                            <div>{{goods.sccode}}</div>
                            <div>{{goods.goods_name}}</div>
                            <div>{{goods.oenaturebrand}}</div>
                            <div>{{goods.goods_price}}</div>
                            <div class="w-60">{{goods.number}}</div>
                            <!-- <div class="w-60" >
                                <span>
                                    {{goods.tonumber||"--"}}
                                </span>
                            </div>

                            <div class="w-60" >
                                <span>
                                    {{goods.refuse_number||"--"}}
                                </span>
                            </div>
                            <div class="w-60">
                                <span v-if="orderData.state>2">
                                {{Number(goods.tonumber)- Number(goods.refuse_number) }}
                                </span>
                                <span v-else>
                                --
                                </span>
                            </div> -->

                        </div>
                        <div class="order-xs-msg">
                            
                            <div class="order-sn-num">
                                <div class="xs">
                                    原订单号: {{orderData.orderId}}
                                </div>
                                <div class="xsd">
                                    对应销售单号: {{orderData.saleId}}
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
            <div class="apply-item">
                <div class="apply-title">
                    发货单
                </div>
                <div class="appley-body">
                    <img v-for="(img,index) in  orderData.receipt_images" :key="index" :src="img" alt="" @click="redBigImg(img)">
                </div>
            </div>
            <div class="apply-item">
                <div class="apply-title">
                    实物图
                </div>
                <div class="appley-body">
                    <img v-for="(img,index) in  orderData.rale_images" :key="index" :src="img" @click="redBigImg(img)" alt="">
                </div>
            </div>
            <el-dialog :visible.sync="dialogVisible">
                <img width="100%" :src="dialogImageUrl" alt="">
            </el-dialog>
        </div>
    </template>
</div>
</template>

<script>
import MoreUp from "./MoreUp.vue"
import NovaSearch from "../../components/NovaSearch.vue"
         
export default {
    data(){
        return{
            
            tabLabels:[
                {
                    label:"所有订单",
                    value:"",
                    num:0,
                    sign:"all"
                },
                
                {
                    label:"待审核",
                    value:"1",
                    num:0,
                    sign:"submit"
                },
                {
                    label:"待赔付",
                    value:"2",
                    num:0,
                    sign:"send_no"
                },
                {
                    label:"赔付中",
                    value:"3",
                    num:0,
                    sign:"send_no"
                },
                {
                    label:"已赔付",
                    value:"4",
                    num:0,
                    sign:"send_no"
                },
                {
                    label:"已拒赔",
                    value:"5",
                    num:0,
                    sign:"send_no"
                },
            ],
            rules:
            {
                ress_order:[
                    { required: true, message: '请填写物流单号', trigger: 'blur' }
                ],
                ress_code:[
                    { required: true, message: '请填写物流公司', trigger: 'blur' }
                ],
            },
            orderList:[],
            allChoose:false,
            total:0,
            goods_id:"",
            dialogVisible:false,
            state:"",
            rePartList:[],
            searchData:{
                curpage:1,
                page:10,
                
                oecode:"",
                sccode:"",
                goods_name:"",
                oenaturebrand:"",
                oecarbrand:"",
                // 退货订单号
                returnId:""
            },
            prammsList:[
        
                {
                    key: "oecode",
                    title: "OE号",
                },
                
                {
                    key: "sccode",
                    title: "生产编码",
                },
                {
                    key: "goods_name",
                    title: "配件名称",
                },
                
                {
                    key: "oecarbrand",
                    title: "配件品牌",
                },
                
                {
                    key: "returnId",
                    title: "退货单号",
                }
            ],
            searchData1:{
                oecode:"",
                sccode:"",
                goods_name:"",
                oenaturebrand:"",
                oecarbrand:"",
                page:1,
                limit:10
            },
            upImgData:{
                store_id:this.$store.state.store_id
            },
            
            logisData:{

            },
            // 订单详情
            orderData:{},
            searList:[],
            applyData:{
                receipt_images:[],
                rale_images:[],
                ress_order:"",
                ress_code:"",
                ress_txt:"",
            },
            logisId:"",
            tempNum:1,
            dialogImageUrl:"",
            total_price:"0",
            com_total_price:"0",
            reGoods:[],
            sendGoods:[],
            // 是否存在保存的数据
            hasCont:false
        }
    },
    methods:{
        sizeChange(){
            
        },
        currentChange(){
            
        },
        upImgSuccessTake(res){
            this.applyData.receipt_images.push(res.data.url)  
        },
        upImgSuccessPart(res){
            this.applyData.rale_images.push(res.data.url) 
        },
        reset(){
            this.searchData.curpage = 1
            this.searchData.page = 10
        },
        redBigImg(url){
            this.dialogImageUrl = url
            
            this.dialogVisible  = true
        },
        // 继续填写
        continueAff(){
            let rePartData = JSON.parse(localStorage.getItem("hnw-repart-save-data"))
            this.applyData = rePartData.applyData 
            this.rePartList = rePartData.saveGoods
            this.tempNum = 2
        },
        searchEnd(res){
            console.log("res",res);
            this.orderList = res.data || [] 
            this.orderList.forEach(order=>{
                order.goods.forEach(goods=>{
                    let obj = this.tabLabels.find(state=>state.value==goods.return_state)
                    if(obj){
                        goods.state_text = obj.label
                    }
                    this.$set(goods,"choose",false)
                })
            })
            this.total = Number(res.count)
            this.allChoose = false
        },
        goodsChange(goods){
            let index =  this.rePartList.findIndex(item=>item.goods_id==goods.goods_id)
            if(index==-1){
                
                this.rePartList.push(goods)
            }else{
                this.rePartList.splice(index,1)
            }
            
        },
        applySearchEnd(res){
            console.log("res",res);
            
            if(res.count>0){

                this.dialogVisible = true
                this.searList = res.data
                this.searchData1.total = Number(res.count)
                this.searList.forEach(goods=>{
                    let index =  this.rePartList.findIndex(item=>item.goods_id==goods.goods_id)
                    this.$set(goods,"number",1)
                    this.$set(goods,"checked",index!=-1)
                })
            }else{
                this.$warMsg("未找到商品")
            }
        },
        // 保存信息
        saveData(){
            let mastKey = ["goods_id","goods_name","store_id","store_name","goods_price","number","oecode","sccode","oenaturebrand","oenatureplace","vehicletype"]
            
            let saveGoods = []

            this.rePartList.forEach(goods=>{
                let temp = {}
                mastKey.forEach(key=>{
                   temp[key] = goods[key]
                })
                saveGoods.push(temp)
            })
            let data = {
                applyData:{
                    ...this.applyData,
                },
                saveGoods
            }
            try{
                localStorage.setItem("hnw-repart-save-data",JSON.stringify(data))
                this.$suMsg("保存成功")
            }catch(e){
                this.$erMsg("保存出错")
            }
        },
        // 提交申请
        async commitApply(){
            console.log("data",this.applyData);
            
            if(this.rePartList.length==0){
                return this.$erMsg("未选择商品")
            }
            
            if(this.applyData.rale_images){
                this.applyData.rale_images = JSON.stringify(this.applyData.rale_images)
            }

            if(this.applyData.receipt_images){
                this.applyData.receipt_images = JSON.stringify(this.applyData.receipt_images)
            }
            
            this.sendGoods = [] // 方便保存 
            // 必传字段
            let mastKey = ["goods_id","goods_name","store_id","store_name","goods_price","number","oecode","sccode","oenaturebrand","oenatureplace","vehicletype"]
            this.rePartList.forEach(goods=>{
                let temp = {}
                mastKey.forEach(key=>{
                   temp[key] = goods[key]
                })
                this.sendGoods.push(temp)
            })

            this.$refs.ressFrom.validate(async flag=>{
                    if(flag){
                        // 获取一下地址
                        // let ressList = await this.$api.getAddrList()
                        // ressList = ressList.data
                        // let resInfo = ressList.find(ress=>ress.is_default==1)
                        // if(!resInfo){
                            // return_iphone:resInfo.mob_phone,
                            // return_ress:resInfo.area_info+resInfo.address,
                        // }

                        let res =  await this.$api.addAfitsale({
                            ...this.applyData,
                            member_id:this.$store.state.member_id,
                            member_name:this.$store.state.member_name,
                            store_id:this.$store.state.store_id,
                            store_name:this.$store.state.store_name,
                            goods:this.sendGoods
                        })

                        if(res){
                            this.$suMsg("提交成功")
                            localStorage.setItem("hnw-repart-save-data")
                            this.tempNum = 1
                        }
                    }else{
                        this.$erMsg("物流信息未填写")
                    }
            })
            
        },
        toggle(obj){
            // console.log(obj)
            if(obj.flag){
                if(this.state==4){
                    this.$set(obj.order,"showList",obj.order.compenGoods)
                }else{
                    this.$set(obj.order,"showList",obj.order.goods)
                }
            }else{
                this.$set(obj.order,"showList",obj.order.oneList)
            }
        },
        exportOrder(){
            // .substr(0,this.$axios.defaults.baseURL.length-4)
            let str1 = this.$axios.defaults.baseURL
            let baseUrl =  str1 + "index.php?act=afitsale&op=list" + "&"  
            baseUrl += `store_id=${this.$store.state.store_id}&curpage=${this.searchData.curpage}&page=${this.searchData.page}&`
            
            let arr = this.orderList.filter(item=>{
                return item.choose
            })
            arr = arr.map(item=>item.returnId)
            if(arr.length==0){
                return this.$warMsg("请选择导出的订单")
            }
            let str = arr.join(",")
            
            console.log("baseUrl",baseUrl +  "mode=" + str)
            location.href = baseUrl +  "mode=" + str 
        },

        // 订单详情
        async reOrderInfo(id,goods){
            const res =  await this.$api.getAfitsInfo({
                id
            })
            this.orderData = res
            this.total_price = this.orderData.goods.reduce((total,item)=>{
                return total + this.$hnwTools.times(Number(item.number),Number(item.goods_price))
            },0)
            this.com_total_price = this.orderData.compenGoods.reduce((total,item)=>{
                return total + this.$hnwTools.times(Number(item.number),Number(item.goods_price))
            },0)

            if(this.orderData.state==1){
                this.orderData.state_text = "待审核"
            }else if(this.orderData.state==2){
                this.orderData.state_text = "待赔付"
            }else if(this.orderData.state==3){
                this.orderData.state_text = "赔付中"
            }

            this.orderData.goods = [goods]
            this.tempNum = 3
        },
        
        async compenInfo(order){
            let record = await this.$api.getRecordByReGoodsId({
                id:order.id
            })
            console.log("record",record);
            this.reGoods = record || []
            if(this.reGoods.length==0){
                this.$warMsg("商品赔付详情")
            }
            if(!this.reGoods||this.reGoods.length==0){
                this.$warMsg("订单未赔付")
            }else{
                this.dialogVisible = true
            }
        },
        resetLogis(){
            this.logisData = {}
            this.dialogVisible = false
        },
        // 添加物流
        async addAfLogi(){
            let res = await this.$api.addAfitsLogi({
                id:this.logisId,
                ...this.logisData
            })
            
            console.log("res",res);
            if(res){
                this.$suMsg("添加成功")
                this.$refs.ParaSearch.getList()
            }
            this.logisData = {}
            this.dialogVisible = false
        },
        // 删除订单
        orderDel(order_sn){
            this.$affMsg("确定删除该订单？",async ()=>{
                let res=  await this.$api.delOrder(order_sn)
                if(res){
                    this.$suMsg("删除成功")
                    this.$refs.ParaSearch.getList()
                }
            })
        },
        allChooseChange(flag){
            this.orderList.forEach(order=>{
                this.$set(order,"choose",flag)
                order.goods.forEach(goods=>[
                    // this.$set(goods,"choose",flag)
                ])
            })
        }
    },
    async created(){
       
        let save = localStorage.getItem("hnw-repart-save-data")
        if(save){
            this.hasCont = true
        }
        
    },
    watch:{
        tempNum(n){
            if(n==1){
                this.rePartList = []
                this.applyData = {
                    receipt_images:[],
                    rale_images:[]
                }
                this.$nextTick(()=>{
                    this.$refs.ParaSearch.getList()
                })
            }
        },
        state(n){
            this.$nextTick(()=>{
                this.$refs.ParaSearch.getList()
            })
        }
    },

    computed:{
        reTotal(){
			let num = 0
			let price = 0
			this.rePartList.forEach(goods=>{
				num = this.$hnwTools.plus(num,goods.number)
				
				let temp = 0
				
				
				temp = this.$hnwTools.times(goods.goods_price,goods.number)
				price = this.$hnwTools.plus(price,temp)
				// price +=  *  
			})

			price = price.toFixed(2)
			return {
				num,
				price
			}

		}
    },
    mounted(){
        if(this.tempNum==1){

            this.$refs.ParaSearch.getList()
        }
    },
    components:{
        MoreUp,
        NovaSearch
    },
    props:{
        
    }
}
</script>

<style lang="scss">
.re-part{
    
    background: #fff;
    .table-head{
        background: $vin-blue;
        color: #fff;
        height: 38px;
        line-height: 38px;
        text-align: center;
        display: flex;
        padding: 0 5px;
        >div{
            flex: 1;
        }
    }
    .table-body{
        .part-row{
            display: flex;
            >div{
                flex: 1;
                height: 38px;
                line-height: 38px;
                text-align: center;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .btn{
                cursor: pointer;
            }
        }
        .repart-total{
            text-align: right;
            padding: 5px 10px;
        }
        .part-row:nth-child(2n){
            background: #eee;
        }
        .order-xs-msg{
            padding:  5px;
            border-top: 5px solid #eee;
            .order-sn-num{
                padding: 5px 0;
                display: flex;
                align-items: center;
                >div{
                    margin-right: 10px;
                }
                // justify-content: space-between;
            }
            
            .xsd{

            }
            .total{
                
                padding-bottom: 0;
                text-align: right;
            }
        }
        .table-type{
            padding: 5px 8px;
            font-weight: 700;
            display: flex;
            .total{
                color: red;
                padding-left: 5px;
            }
        }
    }
    .order-state{
        
        border-bottom: 2px solid #3E84D6;
        position: relative;
        display: flex;
        .order-state-item{
            color: #666;
            cursor: pointer;
           
            padding: 15px;
            text-align: center;
        }
       
        .active{
            background: #3E84D6;
            color: #fff;
        }
        .apply-btn{
            position: absolute;
            right: 5px;
            top: 50%;
            transform: translateY(-50%);
        }
        .cont{
            right: 100px;
        }
    }
    // 筛选跳过
    .sear_sreen{
    }

    .order-list{
        position: relative;
        .batch-handle{
            position: absolute;
            right: 10px;
            top: -38px;
        }
        .order-head{
            .w-60{
                max-width: 60px;
            }
            .order-goods{
                display: flex;
                flex: 1;
                .chec-box{
                    max-width: 15px;
                    min-width: 15px;
                }
                >div{
                    flex: 1;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                
                .w-60{
                    max-width: 60px;
                }
                .w-120{
                    min-width: 120px;
                }
            }
            .order-right{
                display: flex;
                justify-content: center;
                max-width: 260px;
                min-width: 260px;
                justify-content: space-evenly;
                align-items: center;
                >div{
                    flex: 1;
                }
            }
        }

        .order-body{
            background: $backGray;
            border: 5px solid #fff;
            .order-row{
                border-top: 5px solid #fff;
                .order-msg{
                    
                    display: flex;
                    align-items: center;
                    padding: 10px 5px;
                    font-size: 12px;
                    position: relative;
                    >div{
                        margin-right: 10px;
                    }
                    .time{
                        background: $vin-blue;
                        color: #fff;
                        text-align: center;
                        padding: 3px;
                    }
                    img{
                        margin-left: 10px;
                    }
                    .del-btn{
                        cursor: pointer;
                        position: absolute;
                        right: 10px;
                        top: 10px;
                    }
                    .jdr{
                        padding-left: 10px;
                        box-sizing: border-box;
                        max-width: 330px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }

                .list-container{
                    display: flex;
                    text-align: center;
                    border: 1px solid#ddd;
                    border-left: none;
                    border-right: none;
                    padding: 5px 0;
                    .order-goods{
                        flex: 1;
                        .row{
                            display: flex;
                            padding: 10px 0;
                            // border-bottom: 1px solid #ccc;
                            .chec-box{
                                max-width: 15px;
                            }
                            >div{
                                flex: 1;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }
                            
                            .w-60{
                                max-width: 60px;
                            }
                            .w-120{
                                min-width: 120px;
                            }
                        }
                        .row:last-child{
                            border:none;
                        }
                    }
                    .order-right{
                        display: flex;
                        width: 260px;
                       
                        justify-content: space-evenly;
                        align-items: center;
                        >div{
                            flex: 1;
                        }
                        .handle-btn{
                            >div{
                                cursor: pointer;
                            }
                        }
                        .logic{
                            color: #FF7519;
                            margin-bottom: 5px;
                        }
                        .re-order-info{
                            color: #3E84D6;
                        }
                    }
                }
            }
        }
    }
    .el-dialog{
        
        .re-part-table{
            
            .goods-rnumber{
                min-width: 110px;
                .el-input-number{
                    width: 100px;
                }
            }
        }
        .re-sear-pagi{
            text-align: right;
            padding: 10px 0;
        }
    }
    // 退款申请列表
    .re-nav{
        display: flex;
        height: 20px;
        line-height: 20px;
        padding: 8px 10px;
        border-bottom: 2px solid #3E84D6;
        .nav-all{
            color: #3E84D6;
            cursor: pointer;
            i{
                color: #3E84D6;
            }
        }
    }
    .re-head{
        display: flex;
        padding-right: 10px;
        justify-content: space-between;
        align-items:center;

    }
    .re-apply-body{
        padding-top: 10px;
        .apply-item{
            padding-bottom: 10px;
            padding-top: 5px;
            border-bottom: 5px solid #eee;
            .apply-title{
                border-left: 2px solid #3E84D6;
                padding:5px;
                margin-bottom: 5px;
                color: #3E84D6;
                .red{
                    color: red;
                }
            }
            .apply-from{
                padding: 0 15px;
                .flex-item{
                    display: flex;
                }
            }
            .appley-body{
                img{
                    width: 186px;
                    margin: 0 10px;
                    cursor: pointer;
                }
            }
            
        }
        .center{
            text-align: center;
            padding-top: 10px;
        }
        .apply-item:last-child{
            border: none;
        }
        .order-user-msg{
            height: 150px;
            display: flex;
            .user-msg-item{
                flex: 1;
                padding: 20px;
                padding-bottom: 0;
                border-right: 1px solid #ccc;
                .title{
                    padding-bottom: 10px;
                    color: $vin-blue;
                }
                .msg-body{
                    .msg-row{
                        margin-bottom: 7px;
                        >span{

                        }
                    }
                }
            }
            .user-msg-item:last-child{
                border: none;
            }
        }
        .order-info-msg{
            display: flex;
            height: 100px;
            box-sizing: border-box;
            
            >div{
                padding: 20px;
                box-sizing: border-box;
            }
            .msg-left{
                text-align: center;
                min-width: 255px;
                border-right: 1px solid #ccc;
                >div{

                }
                .re-order-state{
                    color: red;
                    font-size: 24px;
                    margin: 24px 0;
                }
                .btn{
                    width: 88px;
                    height: 24px;
                    line-height: 24px;
                    cursor: pointer;
                    border: 1px solid #CCCCCC;
                    margin: 0 auto;
                }
            }
            .msg-right{
                flex: 1;
            }
        }
    }
    .pagin-box{
        padding: 10px 0;
        text-align: right;
    }
    .el-dialog__footer{
        background: #fff;
    }
}
</style>