<template>
<div class="my-account">
    <!-- 1）姓名 2）公司  3）部门 4）职位  5）账号：6）密码；7）绑定手机 -->
    <div class="account-msg">
        <div class="row">
            <div class="label">
                姓名：
            </div>
            <div class="value">
                {{account.nickname}}
            </div>
        </div>
        <div class="row">
            <div class="label">
                公司：
            </div>
            <div class="value">
                {{account.store_name}}
            </div>
        </div>
        <div class="row">
            <div class="label">
                部门：
            </div>
            <div class="value">
                {{account.role_name_far}}
            </div>
        </div>
        <div class="row">
            <div class="label">
                职位：
            </div>
            <div class="value">
                {{account.position}}
            </div>
        </div>
        <div class="row">
            <div class="label">
                账号：
            </div>
            <div class="value">
                {{account.account_number}}
            </div>
            <!--  -->
            <el-button size="mini" type="primary" @click="edit(1)" v-if="role_name!='all'">修改账号</el-button>
        </div>
        <div class="row">
            <div class="label">
                密码：
            </div>
            <div class="value">
                {{account.password}}
            </div>
            <el-button size="mini" type="primary" @click="edit(2)">修改密码</el-button>
        </div>
        <div class="row">
            <div class="label">
                绑定手机号：
            </div>
            <div class="value">
                {{account.phone}}
            </div>
            <el-button size="mini" type="primary" @click="edit(3)">{{account.phone?'修改手机号':'绑定手机号'}}</el-button>
        </div>
        <div class="row">
            <div class="label">
                头像：
            </div>
            <div class="img">
                <img :src="account.member_avatar||require('../../assets/index/home/face.svg')" alt="">
            </div>
            <el-button size="mini" type="primary" @click="edit(4)">修改头像</el-button>
        </div>
        
    </div>
    <!-- 模态框 -->
    <div class="resePass-box">
          <el-dialog :title="title" :visible.sync="accVis" :modal="true" show-close center>
            <el-form :model="account" label-width="100px" ref="restRef" :rules="passwordRule">
              <el-form-item label="账号:"  v-if="title=='修改账号'">
                <el-input v-model="account.account_number" autocomplete="off"></el-input>
              </el-form-item>
              
              <el-form-item label="原密码:" v-if="title=='修改密码'" >
                <el-input v-model="account.password" autocomplete="off"></el-input>
              </el-form-item>
              
              <el-form-item label="新密码:" v-if="title=='修改密码'" prop="new_password">
                <el-input v-model="account.new_password"   placeholder="密码8~16位；必须包含字母和数字"></el-input>
              </el-form-item>
              
              <el-form-item label="确认新密码:" v-if="title=='修改密码'" prop="confirm_password">
                <el-input v-model="account.confirm_password"  placeholder="密码8~16位；必须包含字母和数字"></el-input>
              </el-form-item>
              <!-- 修改手机号 -->
              <template v-if="title=='修改手机号'">
                <template v-if="checkStep==1">
                     <el-form-item label="原手机号:">
                        <el-input v-model="account.phone" autocomplete="off" disabled></el-input>
                    </el-form-item>
                    
                    <el-form-item label="验证码:" >
                        <el-input v-model="captcha"  ></el-input>
                        <div  @click="getCaptcha" class="dxYzm-btn">
                            <div>
                                {{vcodeBtnName}}
                            </div>
                        </div>
                    </el-form-item>
                </template>
                <template v-if="checkStep==2">
                    <el-form-item label="新手机号:">
                        <el-input v-model="account.new_phone" autocomplete="off"></el-input>
                    </el-form-item>
                    
                    <el-form-item label="验证码:">
                        <el-input v-model="captcha"  ></el-input>
                        <div  @click="getCaptcha" class="dxYzm-btn">
                            <div>
                                {{vcodeBtnName}}
                            </div>
                        </div>
                    </el-form-item>
                </template>
                
               

              </template>
              <el-form-item label="上传头像" v-if="title=='修改头像'">
                <el-upload
                    :action="$axios.defaults.baseURL+'index.php?act=upload&op=image'"
                    list-type="picture-card"
                    :on-preview="handlePictureCardPreview"
                    :data="upImgData"
                    :headers="{'token':$store.state.token}"
                    name="image"
                    :on-success="upImgSuccessLogo"
                    :limit="1"
                    :on-remove="aaaa"
                    :file-list="avatarList"
                    >
                    <i class="el-icon-plus"></i>
                </el-upload>
            </el-form-item>

            </el-form>
            <div slot="footer" class="dialog-footer">
                <template v-if="title=='修改手机号'">
                     <el-button type="primary" @click="affEdit">{{checkStep==1?'下一步':'确认绑定'}}</el-button>
                </template>
                <template v-else>
                    <el-button @click="accVis=false">取 消</el-button>
                    <el-button type="primary" @click="affEdit">确 定</el-button>
                </template>
            </div>
            
          </el-dialog>
         
    </div>
</div>
</template>

<script>
import { mapState} from "vuex"
export default {
    data(){
        const validatePass = (rule, value, callback) => {
            let reg = new RegExp(/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,16}$/)
            if(!reg.test(value)){
                callback(new Error("密码需要包含字母和数字;长度在8~16位之间"));
            }else{
                callback();
            }
        }
        return{
            dialogVisible:false,
            account:{},
            title:"修改手机号",
            accVis:false,
            vcodeBtnName:"获取验证码",
            // 验证的步骤
            checkStep:1,
            countNum:"",
            countDownTimer:"",
            captcha:"",
            upImgData:{
                store_id:this.$store.state.store_id
            },
            member_avatar:'',
            avatarList:[],
            passwordRule: {
                new_password: [
                    { required: true, message: "请填写密码", trigger: "blur" },
                    { validator: validatePass, trigger: "blur" }
                ],
                confirm_password: [
                    { required: true, message: "请填写密码", trigger: "blur" },
                    { validator: validatePass, trigger: "blur" }
                ],
            },
        }
    },
    methods:{
        handlePictureCardPreview(file){
            console.log("file",file)
        },
        aaaa(file){
        console.log("file111",file)
            this.member_avatar =""
        },
         // 头像上传
        upImgSuccessLogo(res){
            this.member_avatar = res.data.url 
            console.log("this.member_avatar1111",this.member_avatar)
            // this.companyInfo.store_logo= res.data.file_name
            // this.companyInfo.store_logo_url =  res.data.file_url
        },
        edit(type){
            if(type==1){
                this.title = '修改账号'
            }else if(type==2){
                 this.title = '修改密码'
                //  this.account.new_password = ""
                //  this.account.confirm_password = ""
            }else if(type==3){
                 this.title = '修改手机号'
                 if(!this.account.phone){
                     this.checkStep = 2
                 }
            }else if(type==4){
                this.title = '修改头像'
                // this.accVis =false
                // this.dialogVisible =true
            }else{
                
            }
            this.accVis = true
        },
        
        async affEdit(){
            if(this.title=='修改账号'){
                
                this.account.new_password = this.account.password
                this.account.confirm_password = this.account.password
                let res = await this.$api.editStaffPass({
                    member_id:this.member_id,
                    ...this.account
                })
                
                if(res.code==2020){

                    return this.$warMsg(res.msg)
                }else{
                    this.$suMsg("修改完成")
                }
            }else if(this.title=='修改密码'){
                console.log("???");
                this.$refs.restRef.validate(async (flag) => {
                    if (flag) {
                        let api = this.role_name=='all'?"editPass":"editStaffPass"
                        if(this.account.new_password!=this.account.confirm_password){
                            return this.$erMsg("两次输入的密码不一致")
                        }
                        const res =  await this.$api[api]({
                            member_id:this.member_id,
                            ...this.account
                        })
                        if(res.code==2020){
                            return this.$warMsg(res.msg)
                        }else{

                            this.$suMsg("修改完成")
                            this.$store.commit("UpDateState",{
                                password:this.account.new_password
                            })

                            this.account.password = this.account.new_password
                            this.account.new_password = ""
                            this.account.confirm_password = ""
                            this.accVis = false
                        }
                    }
                });
                
            }else if(this.title=='修改头像'){
                console.log("this.member_avatar",this.member_avatar)
                let res = await this.$api.editImage({
                    member_id:this.member_id,
                    member_avatar:this.member_avatar
                })
                // 列表接口 没有封装
                if(this.$store.state.token){
                    this.account = await this.$api.getAccMsg({
                        member_id:this.member_id,
                        type:this.role_name=='all'?1:2
                    })
                    this.$store.commit("UpDateState",{
                        member_avatar:this.member_avatar
                    })
                } 
                localStorage.setItem("hnw-web-state",JSON.stringify(this.$store.state))
                this.accVis = false
            }else if(this.title=='修改手机号'){

               if(this.checkStep==1){
                   if(!this.captcha){
                       return this.$erMsg("请填写验证码")
                   }
                   let res = await this.$api.sendSms({
                       phone:this.account.phone,
                       captcha:this.captcha
                   })

                   if(res){
                    //    重置计时等
                       this.captcha = ""
                       this.vcodeBtnName = "获取验证码"
                       clearInterval(this.countDownTimer);
                       this.countNum = 120
                       this.checkStep= 2
                       this.accVis = false
                   }else{
                       this.$erMsg("验证码错误!")
                   }
                   return 
               }else{
                   const res = await this.$api.bindPhone({
                       member_id:this.$store.state.member_id,
                       mobile:this.account.new_phone,
                       captcha:this.captcha
                   })
                   if(res){
                        this.$suMsg("绑定成功")
                        this.vcodeBtnName = "获取验证码"
                        this.accVis = false
                        clearInterval(this.countDownTimer);
                        this.countNum = 120
                        this.checkStep= 1
                        this.account.phone = this.account.new_phone
                   }else{
                     return this.$erMsg("绑定失败，手机号已被绑定或者验证码错误")
                   }
               }
            }else{

            }
            
        }, 
        async getCaptcha(){
            let phone = 0
            let type = 5
            if(this.checkStep==1){
                if(this.account.phone.length<11){
                    return this.$erMsg("请输入正确的手机号")
                }else{
                    phone = this.account.phone
                }

            }else{

                if(this.account.new_phone.length<11){
                    return this.$erMsg("请输入正确的手机号")
                }else{
                    phone = this.account.new_phone
                }
                type = 4
            }
            
            if (this.vcodeBtnName != '获取验证码' && this.vcodeBtnName != '重新发送'){return ;}
        
            this.vcodeBtnName =  "发送中...";
            const res = await this.$api.getCaptcha({
                type,
                phone
            })
            if(!res){
                this.$erMsg("发送失败!手机号已被注册")
            }else{
                this.$suMsg("短信已发送，请注意查收")
            }
            // 倒计时
            this.countNum = 120;
            this.countDownTimer = setInterval(function(){this.countDown();}.bind(this), 1000);
        },

        countDown(){
            if (this.countNum < 1){
                clearInterval(this.countDownTimer);
                this.vcodeBtnName = "重新发送";
                return ;
            }
            this.countNum--;
            this.vcodeBtnName =  this.countNum + '秒重发';
        },
        
    },
    computed:{
        ...mapState(['role_name',"member_id"])
    },
    async created(){
        console.log("this.$store.state",this.$store.state)
        if(this.$store.state.token){
            this.account = await this.$api.getAccMsg({
                member_id:this.member_id,
                type:this.role_name=='all'?1:2
            }) 
            this.$set(this.account,"new_password",'')
            this.$set(this.account,"confirm_password",'')
            this.$set(this.account,"new_phone",'')
            if(this.account.member_avatar){
                this.avatarList.push({
                    url:this.account.member_avatar
                })
            }
            }
        
        // {url:account.member_avatar}
    }
}
</script>

<style lang="scss">
.my-account{
    background: #fff;
    .acc-title{
        padding-left: $leftPad;
        font-size: 16px;
        padding-bottom: 5px;
    }
    .account-msg{
        // padding-top: 10px;
        // padding-left: $leftPad;
        padding: $leftPad;
        box-sizing: border-box;
        .row{
            display: flex;
            padding: 20px 0 0 0;
            align-items: center;
            .label{
                width: 120px;
                text-align: right;
                margin-right: 4px;
                color: #9d9d9d;
            }
            .value{
                border: 1px solid #ccc;
                min-width: 300px;
                padding: 5px;
                min-height: 28px;
                box-sizing: border-box;
                color: #666;
            }
            .img{
                border: 1px solid #ccc;
                width: 150px;
                height: 150px;
                border-radius: 10px;
                overflow: hidden;
                >img{
                    width: 100%;
                    height: 100%;
                }
            }
            .el-button{
                margin-left: 10px;
            }
        }
    }
}
</style>