<template>
    <div class="union-goods-list" @click="showFilter=false">
        <div class="w" v-loading="showLoding">
             <!-- 品牌配件信息 -->
            <div class="brand-msg" >
                
                <div class="brand-name">
                    
                    <div class="brand-select" @click.stop="toggleFiler('oenaturebrand')">
                        选择品牌
                        <i class="el-icon-arrow-down" v-if="!showFilter"></i>
                        <i class="el-icon-arrow-up" v-if="showFilter"></i>
                        </div>
                    <div class="brand-checked" @click.stop="toggleFiler('oenaturebrand')">{{oenaturebrand || "全部"}}</div>
                </div>
                <div class="brand-name">
                    <div class="brand-select" @click.stop="toggleFiler('oesort')">
                        产品分类 
                        <i class="el-icon-arrow-down" v-if="!showFilter"></i>
                        <i class="el-icon-arrow-up" v-if="showFilter"></i>
                        </div>
                    <div class="brand-checked" @click.stop="toggleFiler('oesort')">{{oesort || "全部"}}</div>
                </div>
                <div class="line"></div>
                <div @click="query(3,'')" class="brand-names">
                    <div class="brand-parts">配件数量</div>
                    <div class="parts-text">{{goodsMsgTotal.count}}</div>
                </div>
                <div  @click="query(3,'ok')" class="brand-names">
                    <div class="brand-parts">有货</div>
                    <div class="parts-text">{{goodsMsgTotal.count_ok}}</div>
                    
                </div>
                <div  @click="query(3,'no')" class="brand-names">
                    <div class="brand-parts">无货</div>
                    <div class="parts-text">{{goodsMsgTotal.count_no}}</div>
                    
                </div>
                <div class="close-btn" @click="clearFilter" v-if="oenaturebrand || oesort">
                    <!-- <i class="el-icon-circle-close"></i> -->
                    清除
                </div>
            </div>

            <div class="brand-cls" v-if="showFilter">
                <div class="jiao"></div>
                <div class="brand-search" @click.stop> 

                    <el-input  v-model="filterText" clearable placeholder="请输入内容"></el-input>
                    <div class="brand-btn" @click.stop="filterSearch">搜索</div>
                </div>
                <div class="brand-info">    
                    <!-- :class="{'active':this[filterType]==cls[filterType]}" -->
                    <!-- <div class="cls-item" v-for="(cls,index) in screenData[filterType]" :key="index" @click="query(2,cls)" :class="{'active':[filterType]==cls}">
                        {{index+1}} {{cls}}
                    </div> -->

                    <div class="cls-item" v-for="(cls,index) in comScreenData" :key="index" @click="query(2,cls)" :class="{'active':[filterType]==cls}">
                        {{index+1}} {{cls}}
                    </div>
                    
                </div>
            </div>
          
           
            
            <!-- 商品目录 -->
            <div class="product" v-if="group_type!='no'">
                <ul>
                    <li v-for="(item,index) of listAttr" :key="index" >
                        {{item.label}}
                      
                        <div class="ico">
                           
                            <img src="../../assets/brand/up.png" v-if="sortActive==item.key+1" alt="" @click="sortChange(item.key,2,index)" >

                            <img src="../../assets/brand/down.png" v-else alt="" @click="sortChange(item.key,1,index)" >
                        </div>
                    </li> 
                  
                    <li>
                        标签
                    </li>
                    <li>
                        订货数量
                    </li>
                    <!-- <li>区域</li> -->
                    <li>
                        操作
                    </li>  
                </ul>
            </div>

            <!-- <div class="history" v-if="!$store.state.searchText">
                <span>历史搜索记录</span>
            </div> -->
            <!-- 商品详情 -->
            <div class="available" v-if="group_type=='no'">
                <div v-for="(item,index) in noInquireList" :key="index">
                    没查到 <span class="red">{{item}}</span> 相关配件，请试试 <span class="blue" @click="postDemand">发布需求</span> 。
                </div>
            </div>
            <div class="cargo" v-else v-for="(item,index) of storeGoodsList" :key='"list"+index'>
                    <ul >
                        <li :class="{'case-list':true,'isAll':item.isShowAll}" v-for="(goods,childIndex) of item.goods_list"
                                :key="'child'+childIndex">
                            <div class="details">
                                <div class="isOe">
                                    <div class="isOe-top">
                                        <!-- <el-checkbox v-model="goods.checked" >   -->
                                            <div class="name">{{goods.oenaturebrand}}</div>
                                        <!-- </el-checkbox> -->
                                     
                                        <div class="number"  @click.stop="showGoodsInfo(goods)">
                                            {{group_type?goods.store_name:goods.oecode}}
                                        </div>
                                        <!-- <div class="tag-icon" v-if="goods.exchange.length>0">
                                            互
                                        </div> -->
                                        <div class="tag-icon" v-if="goods.isExchange">
                                            互
                                        </div>
                                    </div>

                                    <div class="vehicleModel" @click.stop="showGoodsInfo(goods)" :title="goods.vehicletype">车型:{{goods.vehicletype}}</div>
                                </div>

                                <div class="productName" :title="goods.goods_name">{{goods.goods_name}}</div>
                                
                                <div class="encoding" :title="goods.sccode">{{goods.sccode}}</div>
                                <div class="brand" :title="goods.oenaturebrand" >
                                    {{goods.oenaturebrand}}
                                    
                                </div>
                                
                                <div class="inventory" :class="{'disabled-color':goods.goods_storage==0}">
                                    <span v-if="$store.state.look_inv==1">{{goods.goods_storage}}</span>
                                    <span v-else>{{goods.goods_storage>0?"有货":"无货"}}</span>
                                </div>
                                
                                <div class="TheOrderPrice"> ¥ {{goods.goods_price}}
                                    <span v-if="goods.brand_ratio_price">({{goods.brand_ratio_price}})</span>
                                </div>
                                <div class="specification">  {{goods.goods_commend_text}}</div>
                                
                                <div class="numAd">
                                     
                                    <el-input-number   v-if="$store.state.zero_buy==1" v-model="goods.goods_num" :min="1"  size="mini"></el-input-number>
                                    <el-input-number   v-else  v-model="goods.goods_num"  @change="impueNumChange(goods)" :min="1"  size="mini"></el-input-number>
                                    <span class="goods-Fcl-tit" v-if="goods.brand_ratio_price">
                                        整箱更优惠
                                    </span>
                                </div>
                                <!-- <div class="area">{{goods.house||'总部仓'}}</div> -->
                                <div class="handle-box">
                                 
                                    <el-button class="add-btn" :class="{'ycg-btn':goods.goods_storage==0}" @click="joinCart(goods,index,childIndex,item.store_info)">
                                        {{goods.goods_storage>0?'加入购物车':'加入预购单'}}
                                        <!-- 加入购物车 -->
                                    </el-button> 
                                    <div class="addition">
                                        <span v-if="goods.cartNum">
                                            已添加 {{goods.cartNum}} 件
                                        </span>
                                    </div>
                                    <!-- v-if="goods.exchange.length>0" -->
                                    <!-- <div class="replace-btn" @click="toBuy(goods)" v-if="!goods.isExchange&&goods.exchange.length>0">查看互换件</div>  -->
                                </div>
                                    
                                <div class="stock" v-if="goods.self_storage">自有库存{{goods.self_storage || 0}}</div>
                                <!-- <div class="addition"  v-if="goods.cartNum">已添加 {{goods.cartNum}} 件</div> -->
                            </div>
                            
                            <div class="goods-ex-msg" v-if="goods.orderInfo">
                                <div class="buyHis"   @click="readHisList(goods.goods_id)" >
                                    您最近一次购买：
                                    <span>
                                        {{$hnwTools.formatDateTime(goods.orderInfo.add_time,'ymd')}}
                                    </span>
                                    <!-- 
                                    <span>
                                        {{goods.orderInfo.member_name}}
                                    </span> -->
                                    <span>
                                        {{goods.orderInfo.goods_num}} 件
                                    </span>
                                    <!-- <span>
                                        {{$hnwTools.getOrderStateText(goods.orderInfo.order_state)}}
                                    </span> -->
                                    <span class="readMore" >
                                        更多
                                    </span>
                                </div>
                                <!-- 测试预购单顺便 -->
                                <!-- <div class="store-name" v-if="goods.store_name">
                                    {{goods.store_name}}
                                </div> -->
                            </div>
                        </li>
                            <div class="pagings" v-if="group_type==''&& $store.state.batchs.length==0" >
                          
                                <el-pagination
                                    background
                                    @size-change="query(1,true)"
                                    @current-change="query(1,true)"
                                    :current-page.sync="currentPage"
                                    :page-sizes="[ 15,20, 30,40]"
                                    :page-size.sync="pageSize"
                                    layout="total, sizes, prev, pager, next, jumper"
                                    :total="Math.ceil(item.total)">
                                </el-pagination>
                            </div>
                            <div class="shoppingAll" v-else>
                                <div @click="joinCartAll">
                                     全部加入购物车
                                </div>
                                
                            </div>
                    </ul>
                    
                </div>
                <el-dialog title="历史购买记录" :visible.sync="hisVis" center  width="800px">
                    <div class="his-list">
                        <div class="list-head">
                            <div>购买时间</div>
                            <div>购买人</div>
                            <div class="col-100">价格</div>
                            <div class="col-100">购买个数</div>
                            <div class="col-100">订单状态</div>
                        </div>
                        <div class="list-body">
                            <div class="row" v-for="(his,index) in gooodsHisList" :key="index">
                                <div>{{$hnwTools.formatDateTime(his.add_time,'ymd')}}</div>
                                <div>{{his.member_name}}</div>
                                <div  class="col-100">{{his.goods_price}}</div>
                                <div  class="col-100">{{his.goods_num}}</div>
                                <div class="col-100">{{$hnwTools.getOrderStateText(his.order_state)}}</div>
                            </div>
                        </div>
                    </div>
                </el-dialog>
                <div class="store-vis">
                    <el-dialog
                        :visible.sync="storeVis"
                        width="900px"
                        :show-close="false"
                    >
                    
                        <div class="tit">
                            供应商列表
                            <i class="el-icon-close" @click="storeVis=false"></i>
                        </div>
                        <div class="list-body">
                            <div class="list-head">
                                <div>供应商名称</div>
                                <div class="name">名称</div>
                                <div class="oe">OE号</div>
                                <div>品牌</div>
                                <div>价格</div>
                                <div>库存</div>
                                <div class="buy-nums list-rol">
                                    购买数量
                                </div>
                                
                                <div>操作</div>
                            </div>
                            <div class="list-row" v-for="(goods,index) in buyStoreList" :key="index">
                                <!-- <div>{{goods.store_name}}</div> -->
                                <div>华诺威</div>
                                <div class="name">{{goods.goods_name}}</div>
                                <div class="oe">
                                    {{goods.oecode}}
                                </div>
                                <div>{{goods.oenaturebrand}}</div>
                                <div>￥{{goods.goods_price}}</div>
                                <div :class="{'disabled-color':goods.goods_storage==0}">
                                    <span v-if="$store.state.look_inv==1">{{goods.goods_storage}}</span>
                                    <span v-else>{{goods.goods_storage>0?"有货":"无货"}}</span>
                                </div>
                                <div class="buy-nums list-rol">
                                   
                                    <el-input-number  v-if="$store.state.zero_buy==1" v-model="goods.goods_num" :min="1" size="mini"></el-input-number>
                                    <el-input-number  v-else @change="impueNumChange(goods)" v-model="goods.goods_num" :min="1" size="mini"></el-input-number>
                                </div>
                                <div>

                                    <span class="btn"  :class="{'ycg-btn':goods.goods_storage==0}"  @click="replaceJoinCart(goods)" >
                                        {{goods.goods_storage>0?'加入购物车':'加入预购单'}} 
                                        <!-- 加入购物车 -->
                                    </span>
                                </div>
                            </div>
                        </div>
                    </el-dialog>
                </div>
                 <!-- 弹出框  -->
                <div class="hezi" >
                    <el-dialog
                        :visible.sync="dialogVisible"
                        width="980px"
                        top="8vh"
                        >
                        
                        <DiaGoodsInfo :id="goods_id"></DiaGoodsInfo>
                    </el-dialog>
                </div>
        </div>
    </div>
</template>

<script>

export default {
    data() {
        return{
            gooodsHisList:[],
            hisVis:false,
            showLoding:false,
            storeGoodsList:[],            //搜索框数据
           
            message:"展开筛选",    
            showCond:false,         //筛选显示
            checked: false,         //多选框
            showSearch:false,       //数据展示
            // showAll:false,          //弹出框数据展示
            showbelow:false,        //筛选展示
            showScreen:false,
            showZero:false,
            num: 1,
            sort_type:'',
            sort_way:'',
            sort_self:true,
            showList:[],
            start_list:[],
            sortActive:'',
            group_type:'',
            
            listAttr: [             
                { key:"oecode",label:"OE号",ckbox:false},
                { key:"goods_name",label:"商品名称",ckbox:false},
                { key:"sccode",label:"生产编码",ckbox:false},
                { key:"oenaturebrand",label:"配件品牌",ckbox:false},
                { key:"goods_storage",label:"库存",ckbox:false},
                { key:"goods_price",label:"订货价",ckbox:false}
            ],
            // { key:"oenatureplace",label:"产地",},
            //     { key:"oenature",label:"配件性质",},
            attrList:[],
            goods_id:"",
            dialogVisible:false,
            currentPage:1,
            pageSize:15,
            totalParts:0,     //搜索配件数
            total:0,             //搜索总条数
            screenData:{},
            oesort:"",
            oenaturebrand:"",
            couditionH:"20px",
            //    互换件相关
            storeVis:false,
            buyStoreList:[],
            noInquireList:[],
            disStoreList:[],
            showFilter:false,
            filterType:"oenaturebrand",
            filterText:"",
            storage_type:"",
            goodsMsgTotal:{

            }
      }
    },
    async created(){
        console.log("searchText",this.searchText);
        this.query(1,"screen")
    },
    computed:{
        comScreenData(){

            if(this.filterText){
                return this.screenData[this.filterType].filter(item=>item.includes(this.filterText))
            }else{
                return this.screenData[this.filterType]
            }
        }
    },
    methods: {
        
        impueNumChange(goods) {

            if(isNaN(goods.goods_num)){
                
                this.$nextTick(()=>{
                    this.$set(goods,"goods_num",1)
                })
            }
            if(goods.goods_storage>0&&goods.goods_num>goods.goods_storage){
                this.$nextTick(()=>{
                    goods.goods_num = goods.goods_storage
                })
                return this.$store.commit('UpDateState',{invDia:true})
            }
        },
        toggleFiler(type){
            this.showFilter = !this.showFilter
            this.filterType = type
        },
        clearFilter(){
            this.oesort = ""
            this.oenaturebrand = ""
            this.showFilter = false
            this.query()
        },
        filterSearch(){
            this.screenData[this.filterType]
        },
         // 弹出框数据
        async showGoodsInfo(goods){

            this.goods_id = goods.goods_id
            this.dialogVisible = true
        },
        // // 弹出框数据
        // async showGoodsInfo(goods){
            
        //     if(this.$isCompany()){
        //         goods.preRou = "找件"
        //         localStorage.setItem("hnw-web-item-data",JSON.stringify(goods))
        //         this.$router.push("/union/goodsinfo")
        //     }
        // },

        // 升序降序
        sortChange(type,way,index){
            this.sort_type = type
            this.sort_way = way
            this.sortActive = type + way
            
            this.listAttr[index].ckbox=!this.listAttr[index].ckbox
            
            this.query(1)
        },
        cutGroup(type){
            this.group_type = type
            if(type=''){
                this.listAttr[0].label = 'OE号'
            }else{
                this.listAttr[0].label = '商家'
            }
            if(this.$store.state.batchs){
                this.searches()
            }else{
                this.query(1)
            }
        },
        // 去下一页
        shortCurpageChange(type){
            
            if(type==1){
                if(this.storeGoodsList[0].curpage>1){
                    this.storeGoodsList[0].curpage --
                    this.handleStoreCurrentChange(this.storeGoodsList[0])
                }else{
                    // this.$warMsg("已经第一个了")
                }
            }else{
                if(this.storeGoodsList[0].curpage<this.storeGoodsList[0].total/this.storeGoodsList[0].page){
                    this.storeGoodsList[0].curpage ++
                    this.handleStoreCurrentChange(this.storeGoodsList[0])
                }else{
                    // this.$warMsg("已经最后一页了")
                }
                
            }
           
        },
        //展开收起筛选
        toggleScreen(){
            
            this.showCond = !this.showCond
            this.showScreen = !this.showScreen
            if(this.showCond){
                this.showCond=true;
                this.message="收起筛选"
            }else{
                this.showCond=false;
                this.message="展开筛选"
            }
       
         },
         toggleCH(){
             this.couditionH = this.couditionH == 'auto'?'20px':'auto'
         },
        
       
        // 店铺商品分页
        async handleStoreSizeChange(store){
           
            let res =  await this.storeSearch(store.curpage,store.page,store.store_info.store_id)
           
            store.goods_list=res
            store.goods_list.forEach(goods=>{
                this.$set(goods,"goods_num",1)
                this.$set(goods,"choose",false)
                // goods.goods_price = this.$handlePrice(goods)
                let store = this.disStoreList.find(item=>item.store_id==goods.store_id)
                
                if(store){
                    goods.store_name = store.store_name
                }else{
                    console.log("未知store_id",goods.store_id);
                }
                if(!Array.isArray(goods.exchange)){
                    if(!goods.exchange){
                        goods.exchange = []
                    }else{
                        goods.exchange = [goods.exchange]
                    }
                }else{
                     goods.exchange.forEach(goods=>{
                      
                        // goods.goods_price = this.$handlePrice(goods)
                    })
                }
            })
        },
        async handleStoreCurrentChange(store){
            let res = await this.storeSearch(store.curpage,store.page,store.store_info.store_id)
            
            store.goods_list=res
            store.goods_list.forEach(goods=>{
                this.$set(goods,"goods_num",1)
                this.$set(goods,"choose",false)
                // goods.goods_price = this.$handlePrice(goods)
                let store = this.disStoreList.find(item=>item.store_id==goods.store_id)
                
                if(store){
                    goods.store_name = store.store_name
                }else{
                    console.log("未知store_id",goods.store_id);
                }
                if(!Array.isArray(goods.exchange)){
                    if(!goods.exchange){
                        goods.exchange = []
                    }else{
                        goods.exchange = [goods.exchange]
                    }
                    
                }else{
                     goods.exchange.forEach(goods=>{
                            // goods.goods_price = this.$handlePrice(goods.goods_price)
                            // goods.goods_price = this.$handlePrice(goods)
                        })
                }

            })


        },
        

        //加入购物车
        async toBuy(epcPart){
            
            this.storeVis = true
            this.buyStoreList = epcPart.exchange || []

            this.buyStoreList.forEach(item=>{
                this.$set(item,'goods_num',1)
            })
            
           
        },
        joinCart(carItem,index,childIndex,){
            const tidySubPart = async ()=>{
                let carMessage = {
                    goods_id:carItem.goods_id,
                    sccode:carItem.sccode,
                    oecode:carItem.oecode,
                    goods_name:carItem.goods_name,
                    goods_price:carItem.goods_price,
                    goods_num:carItem.goods_num,
                    goods_storage:carItem.goods_storage,
                    buyer_id:'',
                    order_name:'',
                    member_id:'',
                    buyer_name:'',
                    // store_id:this.group_type?carItem.store_id:store.store_id,
                    // store_name:this.group_type?carItem.store_name:store.store_name,
                    store_id:carItem.store_id,
                    store_name:carItem.store_name,
                    cart_type:'1',
                    setlist_id:'',
                    groupbuy_id:'',
                    promotion_id:''
                }
                
                await this.$api.joinCart([carMessage])
                //更新记录中 已添加X件 字段
                this.storeGoodsList[index].goods_list[childIndex].cartNum = Number(carItem.cartNum) + Number(carItem.goods_num)
            }

            if(this.$isCompany()){

                if(this.$store.state.zero_buy!=1){
                    if(carItem.goods_storage<1){
                        // return this.$store.commit('UpDateState',{invDia:true})
                        
                    }else if(carItem.goods_num+Number(carItem.cartNum)>carItem.goods_storage){
                        return this.$store.commit('UpDateState',{invDia:true})
                    }
                }
                // 预购单已添加提示
                if(carItem.goods_storage<1&&Number(carItem.cartNum)>0){
                    this.$affMsg("当前预购单已有该商品，是否继续添加?",tidySubPart)
                }else{
                    tidySubPart()
                }
                
            }
            
        },
       async joinCartAll(){
           let cart = []

           if(!this.storeGoodsList[0].goods_list){
                return this.$warMsg("没有任何商品")
            }
           this.storeGoodsList.forEach(item=>{
               item.goods_list.forEach(goods=>{
                   cart.push({
                    goods_id:goods.goods_id,
                    sccode:goods.sccode,
                    oecode:goods.oecode,
                    goods_name:goods.goods_name,
                    goods_price:goods.goods_price,
                    goods_num:goods.goods_num,
                    goods_storage:goods.goods_storage,
                    buyer_id:'',
                    order_name:'',
                    member_id:'',
                    buyer_name:'',
                    store_id:goods.store_id,
                    store_name:goods.store_name,
                    cart_type:'1',
                    setlist_id:'',
                    groupbuy_id:'',
                    promotion_id:''
                   })
               })
            }) 
            
            let res  = await this.$api.joinCart(cart)
            if(res){
                // this.storeGoodsList.forEach(item=>{
                //     item.goods_list.forEach(goods=>{
                //         goods.cartNum = goods.goods_num
                //     })
                // })
            }
        },
        async replaceJoinCart(carItem){
            this.storeVis = false
            // if(this.$store.state.zero_buy!=1){
            //     if(carItem.goods_storage<1){
            //         return this.$store.commit('UpDateState',{invDia:true})
            //     }else if(carItem.goods_num+Number(carItem.cartNum)>carItem.goods_storage){
            //         return this.$store.commit('UpDateState',{invDia:true})
            //     }
            // }
            let carMessage = {
                goods_id:carItem.goods_id,
                sccode:carItem.sccode,
                oecode:carItem.oecode,
                goods_name:carItem.goods_name,
                goods_price:carItem.goods_price,
                goods_num:carItem.goods_num,
                goods_storage:carItem.goods_storage,
                store_id:carItem.store_id,
                store_name:carItem.store_name,
                cart_type:'1'
            }
            
            await this.$api.joinCart([carMessage])
            
        },
        
      /*
      调用接口返回搜索结果
      * */
        async query(type,value){
            let screen = ""
            if(type==1){
              
                this.oenaturebrand = ""
                this.oesort = ""
                if(value){
                    screen = value
                }
            }else if(type==2){
                console.log(this.filterType);
                // if(this.filterType==''){

                // }else{
                    
                // }
                this[this.filterType] = value
                // this.oesort = value
                
                this.showFilter= false
            }else if(type==3){
                this.storage_type = value
            }
            
            let search = ""
            search = this.searchText
            search = search.replace(this.$hnwTools.spec,"")
            search = search.trim()
            this.$store.commit("toggleLoding",true)
            let res = await this.$api.getFindList({
                store_id:this.$store.state.store_id,
                member_id:this.$store.state.member_id,
                store_curpage:this.currentPage,
                store_page:this.pageSize,
                group_type:this.group_type,
                sort_type:this.sort_type,
                sort_way:this.sort_way,
                search,
                type:screen,
                storage_type:this.storage_type,
                oenaturebrand:this.oenaturebrand,
                oesort:this.oesort,
                is_chain:2,
                shop_type:this.$store.state.shop_type,
                code:''
            })
            
            console.log("res",res);
            this.showLoding = false
            if(!res||!res.data){
                if(search){
                    this.$warMsg("未搜索到任何商品")
                }
            return this.storeGoodsList = []
            }
            if(res.data.length>0){
                res.data.forEach(store=>{
                    
                    store.goods_list.forEach((goods,index)=>{
                        
                        if(!goods.cartNum){
                            goods.cartNum = 0
                        }

                        
                        if(!goods.self_storage){
                            goods.self_storage = 0
                        }

                        this.$set(goods,"goods_num",1)
                        this.$set(goods,"checked",false)

                        if(goods.goods_commend=="1"){
                            goods.goods_commend_text = "推荐"
                        }else if(goods.goods_commend=="3"){
                            goods.goods_commend_text = "库存"
                        }else if(goods.goods_commend=="4"){
                            goods.goods_commend_text = "热销"
                        }else{
                            goods.goods_commend_text = "正常"
                        }

                    })
                })
                
            }
            // 
            this.storeGoodsList = [...res.data]
            // this.storeGoodsList.forEach(goods=>{
            //     goods.goods_price = goods.taxUntPrice || goods.goods_price
            // })
            if(screen&&res.screen){
                // count
                for(let key in res.screen){
                    if(key!='count'){
                        this.screenData[key] = []
                        res.screen[key].forEach(item=>{
                            if(item[key]){
                                this.screenData[key].push(item[key])
                            }
                        })
                    }else{
                        this.goodsMsgTotal = res.screen[key]
                    }

                }
               
            }
        },
        async searches(){
            
            let oecodes =''
            this.$store.state.batchs.forEach(item=>{
                oecodes += item.search+','
            })
            
            oecodes = oecodes.substr(0,oecodes.length-1)
            let res = await this.$api.getSearches({
                searchs:oecodes,
                // page:this.currentPage,
                // limit:this.pageSize,
                store_id:this.$store.state.store_id
            })
            
            console.log("res",res);
            let arr = res.data[0].goods_list || []
            
            arr.forEach(goods=>{
                let obj = this.$store.state.batchs.find(searOe=>{
                    let noRpOecode = goods.oecode.replace(/\s/g,"")
                    let noRpSccode = goods.sccode.replace(/\s/g,"")
                    let noRpEva = goods.evaluation_good_star.replace(/\s/g,"")
                    let noRpCount = goods.evaluation_count.replace(/\s/g,"")
                    if(searOe.search==goods.oecode||goods.sccode==searOe.search||searOe.search==goods.evaluation_good_star||searOe.search==goods.evaluation_count){
                        return goods
                    }else if(searOe.search==noRpOecode||noRpSccode==searOe.search||searOe.search==noRpEva||searOe.search==noRpCount){
                        return goods
                    }else{
                        return ""
                    }
                    
                })
                console.log("obj",obj);
                if(obj){
                    this.$set(goods,"goods_num",obj.goods_storage)
                }
                // goods.goods_price = this.$handlePrice(goods)

                let store = this.disStoreList.find(item=>item.store_id==goods.store_id)
                
                if(store){
                    goods.store_name = store.store_name
                }else{
                    // console.log("未知store_id",goods.store_id);
                }
                if(!Array.isArray(goods.exchange)){
                    if(!goods.exchange){
                        goods.exchange = []
                    }else{
                        goods.exchange = [goods.exchange]
                        
                    }
                }else{
                    goods.exchange.forEach(exGoods=>{
                        
                        exGoods.goods_price = this.$handlePrice(exGoods)
                    })
                }

                if(this.$store.state.zero_buy!=1){
                    if(goods.cartNum*1>goods.goods_storage*1){
                        goods.cartNum = goods.goods_storage
                    }
                }


            })
            
            this.storeGoodsList=res.data
            if(this.storeGoodsList&&this.storeGoodsList.length>0){
                if(this.storeGoodsList[0].goods_list&&this.storeGoodsList[0].goods_list.length>0){
                    this.storeGoodsList[0].goods_list.forEach(goods=>{
                        this.$set(goods,"cartNum",0)
                    })
                }
            }
            // cartNum
            this.noInquireList = res.data[0].return
        },

        async readHisList(goods_id){
            let res = await this.$api.getGoodsRecord(goods_id)
            this.gooodsHisList = res
            this.hisVis = true
        }
    },
    props:{
        searchText:""
    },
    watch:{
        searchText(n){
            this.query(1,"screen")
        }
    }
}
</script>

<style lang="scss" scoped>
    .index{
        border-top: 1px solid #f7f7f7;
        padding-bottom: 10px;
    }
    .hezi{
        ::v-deep .el-dialog__header{
            border: none;
        }
    }
    .union-goods-list{
        .brand-msg{
            height: 82px;
            margin-bottom: 10px;
            position: relative;
            background-color: #409EFF;
            // margin: 10px 0;
            box-sizing: border-box;
            // line-height: 40px;
            display: flex;
            color: #fff;
            
            .brand-name{
                width: 143px;
                text-align: center;
                .brand-select{
                    padding: 19px 0 10px;
                    cursor: pointer;
                }
            
                .brand-checked{
                    width: 80px;
                    height: 22px;
                    background-color: #fff;
                    color: #50A3F8;
                    text-align: center;
                    line-height: 22px;
                    margin-left: 28px;
                    border-radius: 2px;
                }
            
            }
            .line{
                margin-top: 28px;
                width: 2px;
                height: 27px;
                background: #FFFFFF;
                opacity: 0.2;
                padding: 0;
                margin-right: 20px;
            }
            .brand-names{
                width: 95px;
                text-align: center;
                .brand-parts{
                padding: 20px 0 8px;
                font-size: 14px;
            }
            .parts-text{
                font-size: 18px;
                    font-family: DFPShouJinW3-GB;
                    font-style: oblique;
            }
            }
            >div{
                padding: 0 10px;
                cursor: pointer;
            }
            >div:first-child{
                cursor: default;
            }
            .close-btn{
                cursor: pointer;
                position: absolute;
                top: 27px;
                right: 34px;
                width: 54px;
                height: 30px;
                line-height: 29px;
                text-align: center;
                background: #FFFFFF;
                border-radius: 6px;
                font-size: 14px;
                font-weight: bold;
                color: #50A3F8;
            }
        }
        .brand-cls{
            position: absolute;
            top: 50px;
            left: 120px;
            z-index: 99;
            // height: 214px;
            width: 570px;
            // overflow-y: auto;
            border: 1px solid #ccc;
            padding: 20px;
            background-color: #fff;
            // display: flex;
            // flex-wrap: wrap;
            // align-items: center;
            .jiao{
                position: absolute;
                top: -8px;
                left: 116px;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 0 4px 8px 4px;
                border-color: transparent transparent #fff transparent;
            }
            .brand-search{
                display: flex;
                .el-input{
                    .el-input__inner{
                        height: 32px;
                        line-height: 32px;
                        border: 1px solid #498FFF;
                        border-radius: 2px 0 0 2px;
                    }
                }
                .brand-btn{
                    width: 74px;
                    height: 38px;
                    line-height: 38px;
                    font-size: 14px;
                    color: #fff;
                    text-align: center;
                    background: #498FFF;
                    cursor: pointer;
                    border: 1px solid #498FFF;
                    border-radius: 0 2px 2px 0;
                }
            }
            .brand-info{
                margin-top: 5px;
                display: flex;
                flex-wrap: wrap;
                .cls-item{
                    margin: 0 10px;
                    padding: 5px 0;
                    cursor: pointer;
                    // width: 98px;
                }
                .active{
                    color: #3E84D6;
                }
            }
        }
        
    }
    .w{
        width: 1200px;
        margin: 0 auto;
        .sort{
            position: relative;
            height: 38px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .sort-left{
                width: 86px;
                font-size: 16px;
                font-weight:bold;
                color: #333;
                height: 20px;
                border-bottom: 2px solid #3E84D6;
                padding-top: 10px;
            }
            // .sort-right {
            //     height: 20px;
            //     text-align: center;
               
            //     font-size: 14px;
            //     font-weight: 400;
            //     color: #333333;
            //     line-height: 20px;
            //     cursor:pointer;
            // }
            .screen{
                display: flex;
                position: absolute;
                top: 20px;
                right: 0px;
                text-align: right;
               
            }
        }

        .condition-tabel{
            border: 1px solid #ccc;
            border-bottom: none;
            margin-top: 8px;
            .coudition-group{
                display: flex;
                .label{
                    min-width: 80px;
                    border-right: 1px solid #ccc;
                    border-bottom: 1px solid #ccc;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                .coudition{
                    border-bottom: 1px solid #ccc;
                    flex: 1;
                    display: flex;
                    justify-content: space-between;
                    min-height: 28px;
                    .active{
                        color:#3E84D6;
                    }
                    .coudition-left{
                        display: flex;
                        flex-wrap: wrap;
                        overflow: auto;
                        overflow: hidden;
                        padding: 2px 0;
                        position: relative;
                        flex: 1;
                        
                        .coudition-daxiao{
                            margin: 5px;
                            min-width: 10%;
                            border-right: 1px solid #ccc;
                            text-align: center;

                        }
                        .toggle{
                            position: absolute;
                            bottom: -2px;
                            left: 50%;
                            i{
                                cursor: pointer;
                            }
                        }
                    }
                    .pabo10{
                        padding-bottom: 10px;
                    }
                    .item{
                        text-align: center;
                        line-height: 12px;
                        padding: 0 2px;
                        min-width: 5%;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        border-right: 1px solid #ccc;
                        cursor: pointer;
                        margin: 5px;
                    }
                    .item:last-child{
                        border-right: none;
                    }
                    .item:hover{
                        color:#3E84D6;
                    }
                }

                .coudition-right{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    min-width: 100px;
                    span{
                        cursor: pointer;
                    }
                }

            }
        }
        .top-handle{
            display: flex;
            // justify-content: space-between;
            border-bottom: 2px solid #3E84D6;
            position: relative;
            .group-list{
                display: flex;
                padding: 5px 0 0 0;
                .group-item{
                    cursor: pointer;
                    line-height: 38px;
                    // border: 1px solid #ccc;
                    height: 38px;
                    padding: 0 30px;
                }
                .active{
                    background: #3E84D6;
                    // border: 1px solid #3E84D6;
                    color: #fff;
                    font-weight: bold;
                }
            }
            .short-pag{
                position: absolute;
                top: 5px;
                right: 65px;
                display: flex;
                align-items: center;
                padding-right: 20px;
                .all{
                    padding: 0 10px;
                    
                }
                .filterZero{

                }
                >.up-down{
                   display: flex;
                   flex-direction: column;
                   padding-left: 5px;
                   div{
                       cursor: pointer;
                   }
                   .notCli{
                       cursor: not-allowed;
                   }
                }
            }
            .sort-right {
                position: absolute;
                right: 0;
                top: 10px;
                height: 20px;
                text-align: center;
                font-size: 14px;
                font-weight: 400;
                color: #333333;
                line-height: 20px;
                cursor:pointer;
            }
        }
        .screen-list{
            display: flex;
            justify-content: flex-end;
            align-items: center;
            .screen-item{
                position: relative;
                padding: 0 10px;
                &::before{
                    content: "";
                    position: absolute;
                    width: 1px;
                    height: 60%;
                    left: 0;
                    top: 30%;
                    background: #888;
                }
                
            }
            .screen-item:first-child{
                 &::before{
                    width: 0;
                 }
            }
            
            i{
                cursor: pointer;
            }
            i:hover{
                transform: scale(1.2);
                color: #3E84D6;
            }
        }
        // 商品目录
        .product{
            height: 40px;
            background: #C4C9CC;
            // margin-top: 8px;
            
            ul{
                display: flex;
                // padding-left: 102px;
                box-sizing: border-box;
            li{
                display: flex;
                height: 40px;
                justify-content:center;
                align-items: center;
                position: relative;
                color: #666;
                .ico{
                    // display: flex;
                    // flex-direction: column; 
                    // justify-content: center; 
                    // align-items: center;
                    // >i{
                    //     cursor: pointer;
                    // }
                    // i:hover{
                    // transform: scale(1.5);
                    // color: #3E84D6;
                    // }
                    // .el-icon-caret-top{
                    //     position: relative;
                    //     top: 3px;
                    // }
                    // .el-icon-caret-bottom{
                    //     position: relative;
                    //     top: -3px;
                    // }

                    width: 12px;
                    height: 12px;
                    img{
                      width: 12px;
                    height: 12px;  
                    }
                }
                .active{
                    color: #3E84D6;
                }

                &:nth-child(1){
                    // margin: 0 0 0 102px;
                    min-width:180px;
                    // max-width: 82px;
                    max-width: 180px;
                }
                 &:nth-child(2){
                    // min-width: 150px;
                    // max-width: 150px;
                    min-width: 180px;
                    max-width: 180px;
                }
                 &:nth-child(3){
                    min-width: 120px;
                    max-width: 130px;
                }
                 &:nth-child(4){
                    min-width: 120px;
                    max-width: 130px;
              
                }

                
                 &:nth-child(5){
                    min-width: 120px;
                    max-width: 130px;
                    // flex: 1;
                }
                 &:nth-child(6){
                    min-width: 120px;
                    max-width: 130px;
                    // flex: 1;
                }
                 &:nth-child(7){
                    min-width: 120px;
                    max-width: 120px;
                }
                 &:nth-child(8){
                    min-width: 120px;
                    max-width: 130px;
                }
                 &:nth-child(9){
                    min-width: 120px;
                    max-width: 130px;
                }
                 &:nth-child(10){
                    min-width: 120px;
                    max-width: 120px;
                }
                .filterZero{
                    position: absolute;
                    top: 24px;
                   
                }
            }
        }
     }
        .history{   
            margin-top: 10px;
            height: 14px;
            text-align: center;
            color: #999;
        }
        // 商品信息
        .available{
            
            >div{
                 height: 48px;
                line-height: 48px;
                padding-left: 20px;
                border-bottom: 1px solid #dadada;
                background: #FFFFFF;
                box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.13);
                font-size: 16px;
               .red{
                   color: #f00;
               } 
               .blue{
                   color: #50A3F8;
               }
            }
        }
        .cargo{
            display: flex;
            box-sizing: border-box;
            position: relative;
            box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.13);
            margin-bottom: 20px;
            background: #fff;
            >ul{
                width: 100%;
            }
            .case-list{
                &.isAll{
                 height: auto;
                }
            }
            .case-list:nth-child(2n)
            {
            background:#F7F7F7;
            }
            .case{
                padding-bottom: 10px;
                height: 30px;
                line-height: 44px;
                border-bottom:  1px solid #9e9e9e;
                position: relative;
                .company {
                    display:inline-block;
                    padding:0 20px 0 17px;
                    font-size: 14px;
                    color: #333;
                    font-weight: 700;
                }
                .phone{
                    font-size: 14px;
                    color: #666;
                    padding-right: 20px;
                    img{
                        width: 14px;
                        height: 14px;
                    }
                }
                .filterZero{
                    margin-left: 10px;
                }
            }
                

            .details{
                display: flex;
                height: 67px;
                // line-height: 55px;
                // border-bottom: 1px solid #ccc;
                position: relative;
                .el-checkbox {
                    width: 84px;
                    padding-left: 18px;

                }
                .isOe{
                    // min-width: 194px;
                    // max-width: 82px;
                    width: 180px;
                    border-right: 1px solid #E3E4EA;
                    
                    .isOe-top{
                        display: flex;
                        margin-top: 14px;
                        margin-bottom: 10px;
                        // margin: 0 0 0 28px;
                        margin-left: 10px;
                        .name{
                            width: 48px;
                            height: 20px;
                            background-color:#3E84D6;
                            font-size: 12px;
                            text-align: center;
                            line-height: 20px;
                            color: #fff;
                            
                        }
                        .number{
                            cursor:pointer ;
                            // min-width: 180px;
                            max-width: 100px;
                            // max-width: 180px;
                            margin-left: 5px;
                            margin-top: 2px;
                            overflow: hidden; //超出的文本隐藏
                            text-overflow: ellipsis; //溢出用省略号显示
                            white-space: nowrap; //溢出不换行
                            padding-top: 0;
                            color: #333;
                            // display: flex;
                            
                        }
                            .tag-icon{
                                display: flex;
                                align-items: center;
                                transform: scale(.7);
                                justify-content: center;
                                width: 20px;
                                height: 20px;
                                background: $vin-blue;
                                color: #fff;
                                border-radius: 50%;
                                font-size: 12px;
                                position: relative;
                                top: -8px;
                                left: 2px;
                            }
                    }
                    .vehicleModel{
                    margin: 0 0 0 10px;
                    max-width: 400px;
                    box-sizing: border-box;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    cursor:pointer;;
                    font-size: 14px;
                    color: #999;
                }
                }
                
                .productName{
                    // min-width: 150px;
                    // max-width: 150px;
                    width: 180px;
                    line-height: 69px;
                    border-right: 1px solid #E3E4EA;
                    overflow: hidden;
                    text-align: center;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
                .encoding{
                    overflow: hidden;
                    width: 120px;
                    line-height: 69px;
                    border-right: 1px solid #E3E4EA;
                    text-align: center;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
                .brand{
                    overflow: hidden;
                    width: 120px;
                    line-height: 69px;
                    border-right: 1px solid #E3E4EA;
                    text-align: center;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    position: relative;
                    .hnw-self-brand{
                        position: absolute;
                        bottom: -25px;
                        left: 50%;
                        transform: translateX(-50%);
                        color: #ff7519;
                    }
                }
                // 自选品牌
                .selfBrand{
                    background: #d9ecff;
                    border-bottom: 1px solid #ccc;
                }
                .place{
                    overflow: hidden;
                    width: 120px;
                    line-height: 69px;
                    border-right: 1px solid #E3E4EA;
                    text-align: center;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
                .nature{
                    overflow: hidden;
                    width: 120px;
                    border-right: 1px solid #E3E4EA;
                    line-height: 69px;
                    text-align: center;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
                .inventory{
                    overflow: hidden;
                    width: 120px;
                    line-height: 69px;
                    border-right: 1px solid #E3E4EA;
                    text-align: center;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
                .TheOrderPrice{
                    overflow: hidden;
                    width: 120px;
                    border-right: 1px solid #E3E4EA;
                    line-height: 69px;
                    text-align: center;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    span{
                        position: relative;
                        top: 1px;
                    }
                    
                }
                .specification{
                     overflow: hidden;
                    width: 120px;
                    border-right: 1px solid #E3E4EA;
                    line-height: 69px;
                    text-align: center;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
                .area{
                    overflow: hidden;
                    width: 120px;
                    border-right: 1px solid #E3E4EA;
                    line-height: 69px;
                    text-align: center;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
                .numAd{
                    width: 120px;
                   border-right: 1px solid #E3E4EA;
                   line-height: 69px;
                   position: relative;
                   .el-input-number{
                       margin: 0 0px 0 10px;
                   }
                   .goods-Fcl-tit{
                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%);
                    bottom: 2px;
                    font-size: 12px;
                    line-height: 12px;
                    width: 120px;
                    text-align: center;
                    color: #999;
                   }
                }
                .handle-box{
                    position: relative;
                    box-sizing: border-box;
                    padding: 5px 0;
                    width: 130px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    // justify-content: space-between;
                    >div{
                        height: 20px;
                        line-height: 20px;
                    }
                    .addition{
                        font-size: 14px;
                        color: #999;
                    }
                    .replace-btn{
                        color: #3E84D6;
                        cursor: pointer;
                    }
                }
                .el-input-number{
                    width: 100px;
                    margin: 0 64px 0 20px;
                }
            .el-button--default {
                width: 80px;
                height: 22px;
                border: 1px solid #3E84D6;
                padding: 0;
                color: #59A8F8;
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                border-radius: 0px;
            }
                
                
                .stock{
                    position: absolute;
                    right: 160px;
                    top: 26px;
                    font-size: 14px;
                    color: #999;
                }
                // .addition{
                //     position: absolute;
                //     right: 5px;
                //     top: 44px;
                //     height: 15px;
                //     line-height: 15px;
                //     font-size: 14px;
                //     color: #999;
                // }
            }
            .goods-ex-msg{
                display: flex;
                border-top: 1px solid #E3E4EA;
                justify-content: flex-end;
                align-items: center;
                line-height: 30px;
                height: 30px;
                .buyHis{
                    cursor: pointer;
                    text-align: right;
                   
                    padding: 0 5px;
                    color: red;
                    font-size: 12px;
                    >span{
                        margin-right: 10px;
                        align-items: center;
                    }
                    .readMore{
                        cursor: pointer;
                    }
                }
                .store-name{
                    padding: 0 5px;
                    color: #3E84D6;
                }

            }
            .pagings{
                display: flex;
                justify-content:flex-end;
                align-items: center;
                margin: 20px 0;
            } 
            .shoppingAll{
                display: flex;
                justify-content:flex-end;
                align-items: center;
                margin: 20px 0;
                padding-right: 20px;
                >div{
                    // width: 100px;
                    // height: 40px;
                    background-color: #3E84D6;
                    color: #fff;
                    padding: 0 5px;
                    line-height: 40px;
                    text-align: center;
                    // border: 1px solid #000;
                    border-radius: 5px;
                    cursor: pointer;
                }
            }
                


        }
        .paging{
            display: flex;
            justify-content:center;
            margin: 20px 0;
        } 
        ::v-deep .el-dialog__body{
            padding: 0 !important;
            
            .his-list{
                .list-head{
                    display: flex;
                    height: 40px;
                    align-items: center;
                    background: #EEEEEE;
                    margin: 10px 0;
                    padding: 0 8px;
                    >div{
                        flex: 1;
                        text-align: center;
                    }
                    .col-100{
                        max-width: 100px;
                    }
                }
                .list-body{
                    .row{
                        display: flex;
                        padding: 0 8px;
                        >div{
                            flex: 1;
                            padding: 2px 0;
                            border-bottom: 1px solid #eee;
                            flex: 1;
                            text-align: center;
                            line-height: 28px;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                        .col-100{
                            max-width: 100px;
                        }
                    }
                }
            }
        }
        // 互换件
        .store-vis{
            .el-dialog{
                
                .tit{
                    padding: 10px ;
                    background: #3E84D6;
                    color: #fff;
                    display: flex;
                    justify-content: space-between;
                    .el-icon-close{
                        cursor: pointer;
                        color: #fff;
                    }
                }
                .list-body{
                    padding: 10px;
                    .list-head{
                        display: flex;
                        background: #DFDFDF;
                        height: 28px;
                        line-height: 28px;
                        >div{
                            text-align: center;
                            flex: 1;
                        }
                        .name{
                            min-width: 120px;
                        }
                        .oe{
                            min-width: 140px;
                        }
                        >div:first-child{
                            text-align: left;
                            padding-left: 10px;
                        }
                        
                    }
                    .list-row{
                        display: flex;
                        align-items: center;
                        border-bottom: 1px solid#ccc;
                        >div{
                            text-align: center;
                            flex: 1;
                            padding: 8px 0 ;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            .btn{
                                cursor: pointer;
                                color: #3E84D6;
                            }
                        }
                        >div:first-child{
                            text-align: left;
                            padding-left: 10px;
                            justify-content: flex-start;
                        }
                        .tag-icon{
                            display: flex;
                            align-items: center;
                            transform: scale(.9);
                            justify-content: center;
                            width: 17px;
                            height: 17px;
                            border: 1px solid #ff781c;
                            border-radius: 50%;
                            color: #ff781c;
                            margin-right: 5px;
                            font-size: 12px;
                        }
                        .buy-nums{
                            .el-input-number{
                                width: 100%;
                            }
                        }
                        .name{
                            min-width: 120px;
                        }
                        .oe{
                            min-width: 140px;
                        }
                    }
                }
            
            }
        }
}
</style>
