<template>
<div class="subOrder">
    <div class="order-msg-handle w">
		<div class="msg-title">
			填写并核对订单信息
		</div>
		<div class="order-msg-handle-item">
			<div class="order-msg-title">
				收货人信息
				<div class="addAdress">

					<span @click="$refs.siteform.AddressVis=true" >新增收货地址</span>
				</div>
			</div>
			<div class="order-msg-body">
				<div class="site-list-body">
					<div class="row" v-if="!showMoreSite">
						<!-- subSite -->
						<div class="name opt active">
							{{subSite.true_name}}
							<span  class="mark"></span>
							<i class="el-icon-check"></i>
						</div>

						<div class="adress">
							<span>{{subSite.true_name}}</span>
							<span>{{subSite.area_info}}</span>
							<span>{{subSite.address}}</span>
							<span> {{subSite.mob_phone}}</span>
							<span class="default" v-if="subSite.is_default==1">默认地址</span>
						</div>
						
					</div>
					<div class="row site-row" v-for="(site,index) in siteList" :key="index" v-else>
						<div class="name opt" :class="{'active':site.choose}" @click="seleSite(site)">
							{{site.true_name}}
							<span  class="mark"></span>
							<i class="el-icon-check"></i>
						</div>

						<div class="adress">
							<span>{{site.true_name}}</span>
							<span>{{site.area_info}}</span>
							<span>{{site.address}}</span>
							<span> {{site.mob_phone}}</span>
							<span class="default" v-if="site.is_default==1">默认地址</span>
						</div>
						<div class="setting">
							<span v-if="site.is_default==0" @click="defa(site)">设为默认地址</span>
							<span  @click="edit(site)">编辑</span>
							<span @click="del(site.address_id)">删除</span>
						</div>
					</div>
				</div>

				<div class="moreadress" @click="showMoreSite=!showMoreSite">
					
					{{showMoreSite?'收起地址':'更多地址'}} 
					<i class="el-icon-d-arrow-right" :class="{'pack':showMoreSite}"></i>
				</div>
				
			</div>
		</div>

	
		

		<div class="order-msg-handle-item">
			<!-- <div class="order-msg-title">
				送货清单
			</div> -->
			<div class="addremarks"> <span style="color:#409EFF; width: 4px;">丨</span>送货清单</div>
			<div class="order-msg-body">
				<div class="goods-table">
					<div class="table-head">
						<div class="goods-msg">
							商品图片
						</div>
						<div class="item">
							礼品名称
						</div>
						<div class="item">
							库存
						</div>
						<div class="num">
							数量
						</div>
						<div class="item">
							积分小计
						</div>
					</div>

					<div class="table-body">
						<div class="table-row">
							<div class="goods-msg">
								<div class="img-box" v-if="goods.goods_images">
									<img :src="goods.goods_images[0]" alt="">
								</div>
							</div>
							<div class="item">
								{{goods.name}}
							</div>
							<div class="item">
								{{goods.stock}}
							</div>
							<div class="num">
								<el-input-number
								size="mini"
								v-model="goods_num"
								:min="1"
								:max="Number(goods.stock)"
								label="数量"
								></el-input-number>
							</div>
							<div class="item red">
								{{total}}
							</div>
						</div>
					</div>

				</div>
			
			</div>
		</div>

		<div class="addremarks"> <span style="color:#409EFF; width: 4px;">丨</span>添加订单备注</div>
		<div class="order-sum">
			<div class="remark">
			
				<div class="remark-body">
					<el-input 
					type="textarea"
					v-model="remarkVal" 
					:rows="5"
					maxlength="45"
					show-word-limit
					></el-input>
					
				</div>
				
			</div>
			<div>
				

				<div class="sum-item">
					<div class="sum-label">
						商品积分：
					</div>
					<div class="sum-value">
						<span class="red">
							{{total}}
						</span>
					</div>
				</div>
				<div class="sum-item">
					<div class="sum-label">
						运费：
					</div>
					<div class="sum-value">
						<span >0.00</span>
					</div>
				</div>
				<div class="sum-item">
					<div class="sum-label">
						剩余积分： 
					</div>
					<div class="sum-value">
						<span>
							{{ $hnwTools.minus($store.state.integral_num,total)}}
						</span>
					</div>
				</div>
				
			</div>
		
		</div>
		
		<div class="order-fot-msg">
			<div class="price">
				<div>应付积分：</div>
				<div class="price-num red">
					{{total}}
				</div>
			</div>
			<div class="msg-adrress">
				<span>寄送至：</span>
				{{subSite.area_info}}
				{{subSite.address}}
				<span class="userName">收货人：{{subSite.true_name}} </span>
				<span class="phone">{{subSite.mob_phone}}</span>
			</div>
		</div>
		<div class="sub-order">
			<div class="sub-handle" @click="subOrder">
				提交订单
			</div>
		</div>
	</div>
	<SiteForm ref="siteform" @getList="getSiteList" :editData="editSiteData" @closed="closed"></SiteForm>
</div>
</template>

<script>
import SiteForm from "../../components/SiteForm"
export default {
    data(){
        return{
            goods:{
				goods_images:[],
				price:1,
				
			},
			goods_num:1,
			showMoreSite:false,
			subSite:{},
			remarkVal:"",
			editSiteData:{},
        }
    },
    methods:{
		async subOrder(){
			if(!this.goods.stock||this.goods_num>this.goods.stock){
				return this.$warMsg("商品库存不足")
			}
			let res =  await this.$api.createIntegralOrder({
				integral_id:this.goods.id,
				member_id:this.$store.state.member_id,
				buyer_id:this.$store.state.store_id,
				member_name:this.$store.state.member_name,
				expname:this.subSite.true_name,
				member_iphone:this.subSite.mob_phone,
				member_ress:this.subSite.area_info+this.subSite.address,
				member_text:this.remarkVal,
				// store_id:this.goods.store_id,
				store_id:this.$store.state.store_id,
				store_name:this.$store.state.store_name,
				number:this.goods_num,
				shop:this.$route.params.id || "1780"
			}) 
			if(res){
				this.$suMsg("提交成功")
				this.$store.dispatch("getInteNum")
				this.$router.push("/integral")
			}
		},
		seleSite(site){
			this.siteList.forEach(site=>{
				this.$set(site,'choose',false)
			})
			this.$set(site,'choose',true)
			this.showMoreSite = false
			this.subSite = site
		},
		edit(site){
            this.editSiteData = site
           
            this.$refs.siteform.AddressVis=true
		},
		async del(address_id){
            this.$affMsg("确定删除该地址?",async ()=>{
				let res = await this.$api.delAddr(address_id)
				if(res){
					this.getSiteList()
					this.$suMsg("删除成功")
				}else{
					this.$erMsg("删除失败")
				}
			},()=>{
				this.$infoMsg("已取消")
            })
            
        },
		async defa({address_id}){
            let res = await this.$api.defaAddr(address_id)
            if(res){
                this.$suMsg("设置成功")
                this.getSiteList()
            }else{
                this.$erMsg("设置失败")
            }
		},
		closed(){
			this.editSiteData = {}
		},
		async getSiteList(){
			
			let temp = await this.$api.getAddrList()
			this.siteList = temp.data
			
			
			if(this.subSite.true_name){
				
				this.siteList.forEach(site=>{
					this.$set(site,'choose',false)
					if(site.address_id==this.subSite.address_id){
						this.$set(site,'choose',true)
					}
				})
				
			}else{
				
				this.siteList.forEach(site=>{
					this.$set(site,'choose',false)
					if(site.is_default==1){
						this.$set(site,'choose',true)
						this.subSite  =  site
					}
				})
			}
		},
    },
    async created(){
		
		this.goods_num = this.$route.params.num
		await this.getSiteList()
		let res = await this.$api.getIntegralInfo({
			id:this.$route.params.id || "1780"
		})
		
		this.goods = res
		this.goods.goods_images = []
		
		if(res.images){
			this.goods.goods_images = JSON.parse(res.images)
		} 
    },
    props:{
        
    },
	computed:{
		total(){
			if(this.goods.price){
				return this.$hnwTools.times(Number(this.goods.price),Number(this.goods_num))
			}else{
				return 1
			}
		}
	},
	components:{
		SiteForm
	}
}
</script>

<style lang="scss">
.subOrder{
    // 提交订单页
	.order-msg-handle{
		.msg-title{
			color: #666;
			font-size: 16px;
			border-bottom: 1px solid #F1F1F1;
			padding: 10px 0;
			font-weight: bold;
		}
		
		// 每个模块
		.order-msg-handle-item{
			border-bottom: 1px solid #F1F1F1;
			.order-msg-title{
				padding: 10px 20px;
				box-sizing: border-box;
				font-size: 16px;
				display: flex;
				justify-content: space-between;
				font-weight: bold;
				.addAdress{
					color: #3E84D6;
					span{
						cursor: pointer;
						font-size: 14px;
					}
				}
				.font18{
					font-size: 18px;

				}
			}
			.invoice-list{
				display: flex;
				font-size: 14px;
				color: #666;
				height: 56px;
				line-height: 56px;
				.inquiry{
					width: 140px;
					padding-left: 18px;
					// padding-right: 56px;
				}
				.input{
					.el-input {

						width: 260px;
					.el-input__inner{
					}
					}
				}
				.el-radio{
					width: 100px;
					margin-right:0;
				}
			}
			.appreciation{
				padding-bottom: 30px;
				border-bottom: 1px dashed #F0F0F0;
				.appreciation-msg{
					height: 36px;
					line-height: 36px;
					background: #FCEBEB;
					padding-left: 20px;
					color: #FF2020;
					margin-bottom: 10px;
				}
				.dedicated-body{
					.dedicated{
						height: 287px;
						.dedicated-msg{
							height: 48px;
							line-height: 48px;
							background-color: #f2f2f2;
							display: flex;
							justify-content:space-between;
							padding: 0 10px;
						}
						.dedicated-from{
							height: 241px;
							display: flex;
							padding: 40px 0 0 100px;
							box-sizing: border-box;
							.from-l{
								width: 50%;
								height: 100%;
								.el-input{
									width: 320px;
								}
								div{
									margin-top: 10px;
									span{
										width: 120px;
										display: inline-block;
										text-align: right;
										padding-right: 20px;
									}
								}
							}
							.from-r{
								width: 50%;
								height: 100%;
							}
						}
					}
				}
				.location-body{
					.location{
						height: 210px;
						.location-msg{
							height: 48px;
							line-height: 48px;
							background-color: #f2f2f2;
							display: flex;
							justify-content:space-between;
							padding: 0 10px;
						}
						.location-from{
							height: 168px;
							display: flex;
							padding: 40px 0 0 100px;
							box-sizing: border-box;
							.from-l{
								width: 50%;
								height: 100%;
								.el-input{
									width: 320px;
								}
								div{
									margin-top: 10px;
									span{
										width: 120px;
										display: inline-block;
										text-align: right;
										padding-right: 20px;
									}
								}
								.choice{
									display: flex;
									 ::v-deep .AdressSelect{
										.el-input__inner{
											height: 36px;
											line-height: 36px;
											border-radius: 2px;
										}
									}
								}
							}
						}
					}
				}
			}
			.order-msg-body{
				padding-bottom: 10px;
				.row{
					display: flex;
					align-items: center;
				}
				.site-row{
					margin: 10px 0;
					position: relative;
					.setting{
						position: absolute;
						display: none;
						right: 0;
						>span{
							margin-right: 10px;
							cursor: pointer;
						}
					}
				}
				.site-row:hover{
					background: #B5D7FF;
					.opt{
						
						background: #fff;
					}
					.setting{
						display: block;
					}
				}
				.opt{
					width: 130px;
					height: 30px;
					border:1px solid #D8D8D8;
					text-align: center;
					line-height: 30px;
					margin-right: 10px;
					cursor: pointer;
					.mark{
						opacity: 0;
					}
					i{
						opacity: 0;
					}
				}
				.name{

				}
				.disab{
					background: #D8D8D8;
					color: #666;	
				}
				.active{
					border: 1px solid #3E84D6;
					position: relative;
					overflow: hidden;
					background: #fff;
					color: #333;
					.mark{
						position: absolute;
						bottom: 0;
						width: 20px;
						height: 30px;
						right: -16px;
						background: #3E84D6;
						transform: skewX(-45deg);
						opacity: 1;
					}
					i{
						position: absolute;
						bottom: -2px;
						right: -1px;
						color: #fff;
						opacity: 1;
					}
				}
				.adress{
					.default{
						background: #999;
						color: #fff;
						margin-left: 5px;
						padding: 5px;
				
					}
				}
				.moreadress{
					padding-top: 10px;
					color: #999;
					cursor: pointer;
					i{
						transform: rotate(90deg);
						transition: all .3;
					}
					.pack{
						transform: rotate(-90deg);
					}
				}
				// 商品列表
				.goods-table{
					.table-head{
						background: #f2f2f2;
						height: 38px;
						line-height: 38px;
						text-align: center;
						display: flex;
						>div{
							flex: 1;
						}
						.item{
							flex: 1;
						}
						.num{
							width: 130px;
						}
					}
					.table-body{
						.table-row{
							background: #f2f2f2;
							margin-top: 3px;
							display: flex;
							align-items: center;
							padding: 10px 0;
							.goods-msg{
								display: flex;
								justify-content: center;
								.img-box{
									width: 80px;
									height: 80px;
									margin: 0 20px;
									
									img{
										width: 100%;
										height: 100%;
									}
								}
								
							}
							>div{
								flex: 1;
							}
							.item{
								flex: 1;
								text-align: center;
							}
							.num{
								width: 130px;
								text-align: center;
							}
						}
					}
				}

				// .remark{
				// 	.remark-title{
				// 		font-size: 12px;
				// 		color: #666;
				// 	}
				// 	.remark-body{
				// 		display: flex;
				// 		align-items: center;
				// 		.el-input{
				// 			width: 600px;
				// 			height: 30px;
				// 			::v-deep .el-input__inner{
				// 				height: 30px;
				// 			}
				// 		}
				// 		.hint{
				// 			margin-left: 30px;
				// 		}
				// 	}
				// }
				.invoice-list{
					display: flex;
					font-size: 12px;
					color: #666;
					.invoice-opt{
						margin: 0 8px;
					}
					.edit{
						color: #3E84D6;
						cursor: pointer;
					}
				}
				
			}
			.site-list-body{
				max-height: 128px;
				// overflow-y: auto;
			}
			.select-box{
				display: flex;
				height: 79px;
				line-height: 79px;
				.please{
					width: 140px;
					text-align: right;
					padding-right: 10px;
					box-sizing: border-box;
					color: #666666;
				}
				.elect-box{
					width: 420px;
					.el-select{
						width: 100%;
					}
				}
				.regulation{
					font-size: 14px;
					font-weight: 500;
					color: #409EFF;
					padding-left: 10px;
				}
			}
			.coupons-content{
        width: 1097px;
        background-color: #fff;
        display: flex;
         flex-wrap: wrap;
         margin: 23px 0 20px 14px;
         .coupons-list{
             position: relative;
             width: 260px;
             height: 140px;
             background-color: #D3E7FB;
             overflow: hidden;
             margin-right: 10px;
             margin-bottom: 10px;
             .coupons-name{
                 position: absolute;
                 top: 11px;
                 left: -30px;
                 width: 100px;
                 height: 20px;
                 background-color: #C3E1FF;
                 color: #50A3F8;
                 text-align: center;
                 line-height: 20px;
                 transform: rotate(314deg);
             }
             .coupons-price{
                 height: 66px;
                 line-height: 66px;
                 text-align: center;
                font-size: 48px;
                font-weight: bold;
                color: #50A3F8;
             }
             .coupons-require{
                height: 18px;
                line-height: 18px;
                text-align: center;
                font-size: 18px;
                color: #50A3F8;
             }
             .coupons-line{
                 width: 234px;
                 border: 1px dashed #50A3F8;
                 margin: 10px 0 14px 15px;
             }
             .coupons-date{
                font-size: 18px;
                color: #50A3F8;
                text-align: center;
             }
			 
         }
		 .coupons-lists{
             position: relative;
             width: 260px;
             height: 140px;
             background-color: #D3D3D3;
             overflow: hidden;
             margin-right: 10px;
             margin-bottom: 10px;
             .coupons-names{
                 position: absolute;
                 top: 11px;
                 left: -30px;
                 width: 100px;
                 height: 20px;
                 background-color: #D5D5D5;
                 color: #999999;
                 text-align: center;
                 line-height: 20px;
                 transform: rotate(314deg);
             }
             .coupons-prices{
                 height: 66px;
                 line-height: 66px;
                 text-align: center;
                font-size: 48px;
                font-weight: bold;
                color: #999999;
             }
             .coupons-requires{
                height: 18px;
                line-height: 18px;
                text-align: center;
                font-size: 18px;
                color: #999999;
             }
             .coupons-lines{
                 width: 234px;
                 border: 1px dashed #999999;
                 margin: 10px 0 14px 15px;
             }
             .coupons-dates{
                font-size: 18px;
                color: #999999;
                text-align: center;
             }
         }
    }
		}
		.addremarks{
			margin-top: 10px;
			height: 50px;
			line-height: 50px;
			font-size: 16px;
			color: #333333;
			font-weight: bold;
		}
		.order-sum{
			display: flex;
			justify-content: space-between;
			padding: 0 5px 20px;
			font-size: 12px;
			.remark{
				width: 730px;
			}
			.sum-item{
				display: flex;
				line-height: 18px;
				padding: 3px 0;
				.sum-label{
					text-align:right;
					margin-right: 12px;
					width: 140px;
				}
				.sum-value{
					text-align: left;
				}
			}
			
		}
		.order-fot-msg{
			background: #f2f2f2;
			padding: 20px 10px;
			text-align: right;
			font-size: 12px;
			.price{
				display: flex;
				justify-content: flex-end;
				align-items: center;
				margin-bottom: 10px;
				.price-num{
					font-size: 18px;
				}
				
			}
			.msg-adrress{
				
				span{

				}
				.phone{

				}
				.userName{
					margin-left: 14px;
				}
			}
		}
		.sub-order{
			display: flex;
			justify-content: flex-end;
			padding: 20px 0;
			.sub-handle{
				width: 140px;
				height: 36px;
				line-height: 36px;
				text-align: center;
				color: #fff;
				cursor: pointer;
				background: #3E84D6;
			}
		}
	}
}
</style>