<template>
<!-- 爆炸图加商品列表页 -->
<div class="epc-img-list">
    <div class="part-img-box">        
        <PartImg 
            :partImgSty="partImgSty"
            :imgSrc="imgSrc" 
            :hotspots="hotspots"
            @amplify="amplify"  
            @acDotChange="acDotChange" 
            v-if="showImg"
        >
        </PartImg>
    </div>

    <div class="part-list">
        <div class="part-handle-head">
            <div class="handle-han-left">
                <div class="btn" @click="reback">
                    <img src="../../assets/vin/fh.png" alt="">
                    返回
                </div>
                <template v-if="imgList.length>0">
                    <div class="tab" @click="switchGroup(1)">
                        <i class="el-icon-caret-left"></i> 
                        上一个
                    </div>
                    <div class="tab" @click="switchGroup(2)">
                        下一个
                        <i class="el-icon-caret-right"></i>
                    </div>
                </template>
                
            </div>
            <div class="tip-tit" v-if="!listFilter">
                *注意:红色字体零件不适配该车型
            </div>
            <div class="search">

                <div class="switch-handle">
                    <div class="filter" @click="showConfig" v-if="$route.params.vin!='veh'">
                        配置
                    </div>
                    <div class="filter"  :class="{'active':listFilter}" @click="listFilter=!listFilter">
                        过滤
                    </div>
                    
                </div>

                <el-autocomplete
                    class="inline-input"
                    size="mini"
                    v-model="partsName"
                    @keyup.enter.native="oeSearch"
                    :fetch-suggestions="querySearch"
                    placeholder="请输入配件名称或者编号"
                    :hide-loading="true"
                ></el-autocomplete>
                <div class="btn" @click="oeSearch">
                    搜索
                </div>
            </div>

        </div>
        <div class="goods-list-box">
            
            <VinGoodsList 
                :goodsList="epcPartList"  
                :filterText="partsName" 
                :listFilter="listFilter" 
                :brandCode="brandCode" 
                :activePnum="activePnum" 
                @rowClick="rowClick" 
                v-if="showEpcPart"
                @showRefmid="showRefmid"
            ></VinGoodsList>
        </div>
        <VinForNameList
            ref="vinforname"
            :brandCode="brandCode"
            :mcid="mcid"
            :vin="vin"
            :hideHandle="hideHandle"
        >
        </VinForNameList>
    </div>
    <el-drawer
        title="车架号配置"
        :visible.sync="drawer"
        :with-header="false"
        direction="ltr"
    >
        <div class="config-content">
            <div class="config-search">
                <el-input placeholder="请输入内容" v-model="configKeys" size="mini">
                    <el-button slot="append" icon="el-icon-search"></el-button>
                </el-input>
            </div>
            <div class="config-list">
                <div class="config-item" v-for="(v,k) in filteredConfig" :key="v">
                    <div class="config-label">
                        {{k}}
                    </div>
                    <div class="config-value">
                        
                        <el-tooltip class="item" effect="dark" :content="v" placement="top">
                            <span>
                                {{v}}
                            </span>
                        </el-tooltip>
                    </div>
                </div>
            </div>
        </div>
    </el-drawer>
</div>
</template>

<script>
import VinGoodsList from "./VinGoodsList"
import PartImg from "./PartImg"
import VinForNameList from "./VinForNameList"

export default {
    data(){
        return{
       
            listFilter:true,
            showImg:false,
            showEpcPart:false,
            epcIndex:0,
            partsName:"",
            partImgSty:{
                width:"20rem",
                height:"100%"
            },
            imgSrc:"",
            hotspots:[],
            partList:[],
            // 上分组下分组用到的
            imgList:[],
            drawer:false,
            drawerNum:1,
            partInfo:{},
            epcPartList:[],
            searchType:"1",
            
            goodsList:[],
    
            activePnum:"",
            dot:{},
            vin:"",
            brandCode:"",
            mcid:"",
            ispop:false,
            configKeys:"",
            allConfig:{},
            hideHandle:false
        }
    },
    methods:{
        amplify(isAmp){
            if(isAmp){
                this.partImgSty.width = "20rem"
            }else{
                this.partImgSty.width = "28rem"
            }
        },
        rowClick(pnum){
            this.hotspots.forEach(dot=>{

                if(dot.hsKey==pnum){
                    dot.active = true
                }else{
                    dot.active = false
                }

            })
        },
        acDotChange(pnum){
            
            this.activePnum = pnum
            this.hotspots.forEach(dot=>{

                if(dot.hsKey==pnum){
                    dot.active = true
                }else{
                    dot.active = false
                }

            })
            
        },
        async querySearch(str,cb){
            cb([])
        },
        switchGroup(type){
            if(type==1){
               
                if(this.epcIndex==0){
                    return this.$warMsg("已是第一个分组")
                }else{
                    this.epcIndex = this.epcIndex - 1
                    this.epcSearch(this.imgList[this.epcIndex],'switch')
                }
            }else{
               
                if(this.epcIndex==this.imgList.length-1){
                    return this.$warMsg("已到最后分组")
                }else{
                    this.epcIndex  = this.epcIndex + 1
                    this.epcSearch(this.imgList[this.epcIndex],'switch')
                }
            }

        },
        async epcSearch(part){
            
            let params = {}

            this.brandCode = part.brandCode
            this.mcid = part.mcid.replace(/xiegang/g,"/")

            // this.mcid = part.mcid
            this.vin = part.vin
            
            params = {
                brandCode:part.brandCode,
                mcid:part.mcid.replace(/xiegang/g,"/"),
                num:part.num,
                mid:part.mid,
                partName:part.name.replace(/xiegang/g,"/"),
                subgroup:part.subgroup
            }
            if(this.$route.params.vin&&this.$route.params.vin!='veh'){
               
                params.vin = this.$route.params.vin
            }
            let res = await this.$api007.vinPartSearch(params)
            this.showImg = false
            this.showEpcPart = false
         
            let nearList = []
            if(res.data.part_detail){

                res.data.part_detail.forEach(parts=>{
                    if(Array.isArray(parts)){
                        parts.forEach(part=>{
                        
                            nearList.push(part)
                        })
                    }else{
                        nearList.push(parts)
                    }
                })
                
                
                nearList.forEach(part=>{
                    part.remark = part.remark.replace(/<br\/>/g,"")
                    part.label = part.label.replace(/<br\/>/g,"")
                    

                })
            
            }else{
                
                console.error("未查询到相邻配件")
            }
            
            this.epcPartList = nearList

            this.imgSrc = res.img.imagePath

            
            if(res.img.imgPoints){
                this.hotspots = res.img.imgPoints.hotspots
                
            }
            if(Array.isArray(this.hotspots)){
                this.hotspots.forEach(dot=>{
                    dot.left = dot.hsX + "px"
                    dot.top = dot.hsY + "px"
                    dot.width = dot.maxX -  dot.hsX + "px"
                    dot.height = dot.maxY -  dot.hsY + "px"
                    // 该零件是否使用该车型
                    let temp =  nearList.filter(item=>item.pnum==dot.hsKey)
                    let isNot = temp.every(part=>part.colorvalue!=1)
                    if(isNot){
                        dot.title = "零件不适用该车型"
                    }
                    this.$set(dot,"active",false)
                })
            }
            // 如果刚进来
            let hasNav = false
            if(!this.ispop){
                let reg = /\/catalog\/epcimglist/
                hasNav = this.$store.state.epcNavList.some(nav=>reg.test(nav.route))
            }
            if(!hasNav){
                this.$store.commit("navPush",{
                    label:`${part.name.replace(/xiegang/g,"/")}${part.mid}`,
                    pop:this.ispop,
                    route:`/catalog/epcimglist/${part.brandCode}/${part.mcid}/${this.$route.params.vin}/${part.num}/${part.mid}/${part.subgroup}/${part.name}`
                })
            }
            this.ispop = true
            this.$nextTick(()=>{
                this.showImg = true
                this.showEpcPart = true
            })
        },
        reback(){
            let list = this.$store.state.epcNavList.slice(0,this.$store.state.epcNavList.length-1)
            this.$store.commit("UpDateState",{
                epcNavList:list
            })
            if(this.$store.state.epvPreNav){
                this.$router.push(this.$store.state.epvPreNav)
            }else{
                this.$router.go(-1)
            }
        },
        async oeSearch(){
            this.$refs.vinforname.partsName = this.partsName
            this.hideHandle = false
            this.$refs.vinforname.oeSearch()
            
        },
        
        // 获取配置
        async showConfig(){
            this.drawer = true
            let res = await this.$api007.vinSearch(this.$route.params.vin)
            if(res.data.maindata){
                this.allConfig = {
                    ...res.data.maindata
                }
            }
            if(res.data.subdata){
                this.allConfig = {
                    ...this.allConfig,
                    ...res.data.subdata
                }
            }
        },
        // 展示跳转配件
        showRefmid(part){
            console.log("part",part);
            this.hideHandle = true
            this.$refs.vinforname.partEpcSearch(part)
        }
    },
    async created(){

        // 获取分组数据 只有vin查询有

        // if(this.$route.params.vin!='veh'){
            
        //     }
        this.imgList = JSON.parse(localStorage.getItem("hnw-vin-img-list")) ||[]
        this.epcIndex =   this.imgList.findIndex(item=>item.mid==this.$route.params.mid)
        console.log("imgList",this.imgList);
        await this.epcSearch(this.$route.params)
        // 查询列表
    },
    computed:{
        filteredConfig(){
            let obj ={}
            if(this.configKeys){
                for(let key in this.allConfig){
                    // console.log("key",key);
                    let reg = new RegExp(this.configKeys,"i")
                    if(reg.test(key)||reg.test(this.allConfig[key])){
                        obj[key] = this.allConfig[key]
                    }
                }
                
            }
            return obj
        }
    },
    components:{
        VinGoodsList,
        PartImg,
        VinForNameList
    }
}
</script>

<style lang="scss">
.epc-img-list{
    display: flex;
    height: 100%;
    background: #F4F6F7;
    padding: 10px;
    box-sizing: border-box;
    .part-list{
        margin-left: 10px;
        flex: 1;
        overflow: auto;
        position: relative;
        background: #fff;
        display: flex;
        flex-direction: column;
        .part-handle-head{

            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-left: 15px;
            height: 40px;
            .handle-han-left{
                display: flex;
                align-items: center;
                .btn{
                    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.12);
                    border-radius: 6px;
                    img{
                        margin-right: 6px;
                        width: 13px;
                        height: 13px;
                    }
                }
                .tab{
                    @include fl-c;
                    cursor: pointer;
                    width: 66px;
                    height: 30px;
                    background: #FFFFFF;
                    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.12);
                    border-radius: 6px;
                    margin-left: 10px;
                    i{
                        font-size: 20px;
                    }
                }

            }
            .tip-tit{
                color: #f00;
            }
            .btn{
                color: #fff;
                cursor: pointer;
                background: $vin-blue;
                width: 66px;
                height: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
                i{
                    margin-right: 5px;
                }
            }
            .search{
                display: flex;
                align-items: center;
                
                .switch-handle{

                    display: flex;
                    align-items: center;
                   
                    .filter{
                        @include fl-c;
                        cursor: pointer;
                        width: 66px;
                        height: 30px;
                        background: #FFFFFF;
                        color: #254385;
                        box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.12);
                        border-radius: 6px;
                        margin-right: 10px;
                    }
                    .active{
                        background: #254385;
                        color: #fff;
                    }
                }
                .el-input__inner{
                    border-radius: 0;
                   
                    width: 8rem;
                }
                .btn{
                    width: 84px;
                    height: 28px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    color: #fff;
                    background: #198AFF;
                }

            }
        }
        .goods-list-box{
            position: relative;
            flex: 1;
        }
    }
    .config-content{
        
        .config-search{
            padding: 10px;
            box-shadow: 0 2px 4px 0 #ccc;
        }
        .config-list{
            height: calc(100% - 92px);
            padding-top: 5px;
            overflow: auto;
            .config-item{
                display: flex;
                border-bottom: 1px solid rgba(0,0,0,.09);
                padding: 5px 0;
                margin: 0 10px;
                line-height: 22px;
                .config-label{
                    position: relative;
                    width: 40%;
                    min-width: 100px;
                    white-space: nowrap;
                    overflow: hidden; //超出的文本隐藏
                    text-overflow: ellipsis; //溢出用省略号显示
                    &:after {
                        content: "";
                        position: absolute;
                        top: 0;
                        right: 0;
                        width: 1px;
                        height: 22px;
                        background-color: #ddd;
                    }
                }
                .config-value{
                    width: 60%;
                    padding-left: 10px;
                    white-space: nowrap;
                    overflow: hidden; //超出的文本隐藏
                    text-overflow: ellipsis; //溢出用省略号显示
                }
            }
        }
    }
}
</style>