
import fc from 'fraction-calculator';
import Vue from 'vue';

// 查询货位的算法
const getAllotion = (allotion) => {
    
    console.log("allotion",allotion)
    let allotionObj = {
        dy:{
            title:"丹阳",
            list:[]
        },
        sh:{
            title:"上海",
            list:[]
        }
    }

    let arr = [] 
	if(allotion){
		try{
			let key = Object.keys(allotion)
			arr = allotion[key].shelfStockInfos
			
			arr.forEach(item=>{
				item.code=allotion[key].originPartsCode
                if(item.warehouseName.includes("丹阳")){
                    allotionObj.dy.list.push(item)
                }else{
                    allotionObj.sh.list.push(item)
                }
			})
		}catch(e){
			console.log(e);
			console.log("转换发生错误");
		}
		
	}
	
    return allotionObj
    // } 
}

/**
 * 分仓订单数据清洗算法
 * @param {*} stock 
 * @param {*} house 
 * @param {*} data 
 * @returns 
 */
const getStockInfo = (stock, house, data) => {
    if (stock <= 0 || house == "" || data.length <= 0) {
        return false
    } 
    var warehouse = [] 
    try{
        if(data!='丹阳仓'){
            var info = JSON.parse(data)
            switch (house) {
                case "0":     // 进丹阳仓
                    warehouse[warehouse.length] = {
                        "warehouseCode": "丹阳仓",
                        "qty": stock
                    }
                    break;
                case "1":     // 进上海仓
                    var x = 0
                    info.forEach(item => {
                        if (x < stock && item['warehouseCode']!='丹阳仓' && item['qty']!="0") {
                            warehouse[warehouse.length] = { "warehouseCode": item['warehouseCode'], "qty": Number(item['qty']) }
                            x += Number(item['qty'])
                        }
                    })
                    if(x>stock){
                        warehouse[warehouse.length-1]['qty'] -= (x - stock)
                    }
                    break;
                default:
                    return false
            }
        }
        
    }catch(e){
        console.log(e)
    }
    return warehouse ? warehouse : false
}

// 标准化时间
const formatDateTime = (value, str) => {
    if (!value) {
        return ""
    }
    if (value == 0) {
        return ""
    }
    var date = new Date();
    date.setTime(value * 1000);
    var y = date.getFullYear();
    var m = date.getMonth() + 1;
    m = m < 10 ? ('0' + m) : m;
    var d = date.getDate();
    d = d < 10 ? ('0' + d) : d;
    var h = date.getHours();
    h = h < 10 ? ('0' + h) : h;
    var minute = date.getMinutes();
    var second = date.getSeconds();
    minute = minute < 10 ? ('0' + minute) : minute;
    second = second < 10 ? ('0' + second) : second;
    if (str == "ymd") {
        return y + '-' + m + '-' + d
    } else if (str == "hms") {
        return h + ':' + minute + ':' + second
    } else if (str == "mdhm") {

        return m + '-' + d + ' ' + h + ':' + minute
    } else {
        return y + '-' + m + '-' + d + ' ' + h + ':' + minute + ':' + second
    }
}
// 剩余时间
const reLeftTime = (timestamp, mode) => {
    var date1 = new Date();  //开始时间

    var date3 = timestamp - date1.getTime()  //时间差的毫秒数
    //计算出相差天数
    var day = Math.floor(date3 / (24 * 3600 * 1000))
    //计算出小时数

    var leave1 = date3 % (24 * 3600 * 1000)    //计算天数后剩余的毫秒数
    var hour = Math.floor(leave1 / (3600 * 1000))
    //计算相差分钟数
    var leave2 = leave1 % (3600 * 1000)        //计算小时数后剩余的毫秒数
    var minute = Math.floor(leave2 / (60 * 1000))


    //计算相差秒数
    var leave3 = leave2 % (60 * 1000)      //计算分钟数后剩余的毫秒数
    var second = Math.round(leave3 / 1000)

    // 还有多久开始
    return { day, hour, minute, second }
}
// 获取订单的状态
// 处理倒计时
const startCountDown = (_self) => {

    const countDown = (_self) => {
        let leftTime = _self.leftTime - new Date();
        let hours = 0, minutes = 0, seconds = 0;
        if (leftTime > 0) {

            hours = parseInt(leftTime / 1000 / 60 / 60, 10);
            minutes = parseInt(leftTime / 1000 / 60 % 60, 10);
            seconds = parseInt(leftTime / 1000 % 60, 10);
        } else {
            clearInterval(_self.timerInter)
            console.log("倒计时结束!")
        }
        if (hours < 10) { hours = '0' + hours; }

        if (minutes < 10) { minutes = '0' + minutes; }
        if (seconds < 10) { seconds = '0' + seconds; }
        _self.h = hours; _self.i = minutes; _self.s = seconds;
    }
    countDown(_self);
    _self.timerInter = setInterval(() => {
        countDown(_self);
    }, 1000);
}
const getOrderStateText = (order_state) => {
    let order_stateText = ""
    switch (order_state) {
        case "-2":
            order_stateText = "订单已取消"
            break;
        case "-1":
            order_stateText = "审核未通过"
            break;


        case "1":
            order_stateText = "待发货"

            break;
        case "2":
            // 已发货
            order_stateText = "待收货"

            break;
        case "3":
            order_stateText = "已收货"

            break;
        case "4":
            order_stateText = "订单完成"
            break;

        default:
            order_stateText = "已提交"
            break;
    }
    return order_stateText
}
/**
 * @param choose 是否选中 
 * @param show 是否展示
 * @param title 变量意思
 * @param key 对应的字段
 */
// 将非华诺威商品转换成华诺威字段
const formatGoodsKey = (goods) => {

    if (!goods.store_id) {
        // console.error("商品没有商家id",goods.name,goods.sccode);
    } else if (goods.store_id != 1780 && goods.is_chain.includes("3")) {
        // console.log("转换华诺威属性？");
        if (goods.is_chain.includes("3")) {
            goods.goods_name = goods.factoryname
            goods.oecode = goods.evaluation_good_star
            goods.sccode = goods.evaluation_count
            goods.oenaturebrand = goods.virtual_indate
            goods.oenatureplace = goods.virtual_limit
        }
    } else {
        // console.log("该商品是华诺威商品!");
    }
}
const goodsAttrs = [
    {
        choose: true,
        show: true,
        title: "OE号",
        key: "oecode",
        isSort: true
    },
    {
        choose: true,
        show: true,
        title: "商品名称",
        key: "goods_name",
        isSort: true
    },
    {
        choose: true,
        show: true,
        title: "产品名称",
        key: "goods_name",
        isSort: true
    },
    {
        choose: true,
        show: true,
        title: "生产编码",
        key: "sccode",
        isSort: true
    },

    {
        choose: true,
        show: true,
        title: "商品状态",
        key: "goods_state"
    },
    {
        choose: true,
        show: true,
        title: "库存",
        key: "goods_storage",
        isSort: true
    },
    {
        choose: true,
        show: true,
        title: "库存预警",
        key: "goods_storage_alarm",
        isSort: true
    },
    {
        choose: true,
        show: true,
        title: "商品价格",
        key: "goods_price",
        isSort: true
    },
    {
        choose: true,
        show: true,
        title: "订货价",
        key: "goods_price",
        isSort: true
    },
    {
        choose: false,
        show: false,
        title: "清库存价",
        key: "taxStoPrice2"
    },
    {
        choose: true,
        show: true,
        title: "联盟价格",
        key: "taxUntPrice",
        isSort: true
    },
    {
        choose: true,
        show: true,
        title: "自营价格",
        key: "taxWebPrice",
        isSort: true
    },
    {
        choose: true,
        show: true,
        title: "直营价格",
        key: "sellPrice1",
        isSort: true
    },
    {
        choose: true,
        show: true,
        title: "平台价格",
        key: "taxSellPrice2",
        isSort: true
    },

    {
        choose: true,
        show: true,
        title: "产地",
        key: "oenatureplace",
        isSort: true
    },
    {
        choose: true,
        show: true,
        title: "适配车型",
        key: "vehicletype"
    },
    {
        choose: true,
        show: true,
        title: "新增日期",
        key: "goods_addtime",
        isSort: true
    },
    {
        choose: true,
        show: true,
        title: "上架日期",
        key: "to_time",
        isSort: true
    },
    {
        choose: true,
        show: true,
        title: "审核日期",
        key: "to_time",
        isSort: true
    },
    {
        choose: true,
        show: true,
        title: "配件分类",
        key: "oesort"
    },
    {
        choose: true,
        show: true,
        title: "配件品牌",
        alias: "品牌",
        key: "oenaturebrand",
        isSort: true
    },
    {
        choose: true,
        show: true,
        title: "车型品牌",
        key: "oecarbrand"
    },
    {
        choose: true,
        show: true,
        title: "仓库",
        key: "storage"
    },
    {
        choose: true,
        show: true,
        title: "件号",
        key: "oepart"
    },
    {
        choose: true,
        show: true,
        title: "部位码",
        key: "oeposition"
    },
    {
        choose: true,
        show: true,
        title: "计量单位",
        key: "oecompany"
    },
    {
        choose: true,
        show: true,
        title: "规格",
        key: "spec"
    },

    {
        choose: true,
        show: true,
        title: "整箱价",
        key: "goods_jingle"
    },


    // 非HNW商户字段 22927  加
    // 非HNW商户字段 22927  加
    {
        choose: true,
        show: true,
        title: "HNW-标识码",
        key: "HNWID"
    },
    {
        choose: true,
        show: true,
        title: "HNW-名称",
        key: "factoryname"
    },
    {
        choose: true,
        show: true,
        title: "HNW-OE",
        key: "evaluation_good_star"
    },
    {
        choose: true,
        show: true,
        title: "HNW-生产码",
        key: "evaluation_count"
    },
    {
        choose: true,
        show: true,
        title: "HNW-品牌",
        key: "virtual_indate"
    },
    {
        choose: true,
        show: true,
        title: "HNW-产地",
        key: "virtual_limit"
    },

    // {
    //     choose:true,
    //     show:true,
    //     title:"总部有货",
    //     key:"have_gift"
    // },
    // {
    //     choose:true,
    //     show:false,
    //     title:"原厂品牌件号",
    //     key:"oepart"
    // },

    // {
    //     choose:true,
    //     show:true,
    //     title:"是否清洗",
    //     key:"mateMode"
    // },



    // {
    //     choose:true,
    //     show:true,
    //     title:"原厂名称",
    //     key:"factoryname"
    // },
    // {
    //     choose:true,
    //     show:true,
    //     title:"标准名称",
    //     key:"goods_name"
    // },
    // {
    //     choose:true,
    //     show:true,
    //     title:"配件厂牌",
    //     key:"stdBrand"
    // },
    // {
    //     choose:true,
    //     show:true,
    //     title:"清洗品牌",
    //     key:"cleanBrand"

    // },

    // {
    //     choose:true,
    //     show:true,
    //     title:"性质",
    //     key:"oenature"
    // },

    // {
    //     choose:true,
    //     show:true,
    //     title:"仓位",
    //     key:"storage_po"
    // },



    // {
    //     choose:false,
    //     show:false,
    //     title:"不含税进货价格1",
    //     key:"stoPrice1"
    // },
    // {
    //     choose:false,
    //     show:false,
    //     title:"不含税进货价格2",
    //     key:"stoPrice2"
    // },
    // {
    //     choose:false,
    //     show:false,
    //     title:"含税进货价格1",
    //     key:"taxStoPrice1"
    // },


    // {
    //     choose:false,
    //     show:false,
    //     title:"不含税销售价格1",
    //     key:"sellPrice1"
    // },
    // {
    //     choose:false,
    //     show:false,
    //     title:"不含税销售价格2",
    //     key:"sellPrice2"
    // },
    // {
    //     choose:false,
    //     show:false,
    //     title:"含税销售价格1",
    //     key:"taxSellPrice1"
    // },
    // {
    //     choose:false,
    //     show:false,
    //     title:"含税销售价格2",
    //     key:"taxSellPrice2"
    // },

    // {
    //     choose:false,
    //     show:false,
    //     title:"联盟采购价(含税)",
    //     key:"purchase_tax"
    // },
    // {
    //     choose:false,
    //     show:false,
    //     title:" 电商销售价(含税)",
    //     key:"taxWebPrice"
    // },
    // {
    //     choose:false,
    //     show:false,
    //     title:"联盟调货价(含税)",
    //     key:"taxUntPrice"
    // },

    // {
    //     choose:false,
    //     show:false,
    //     title:"退货数量",
    //     key:"resale_num"
    // }

]
const getGoodsKey = (title) => {
    let attr = goodsAttrs.find(item => item.title == title)
    if (attr) {
        return attr.key
    } else {
        console.log("有标题未找到对应的字段", title)
        return ""
    }
}

const storeUrls = [

    {
        id: "1974",
        // url:"https://xy.huanuowei.cn",
        url: "xy.huanuowei.cn",
        shop_name: "昕豫汽配"
    },
    {
        id: "1905",
        // url:"https://cd.huanuowei.cn",
        url: "cd.huanuowei.cn",
        shop_name: "成都华诺威汽配"
    },
    {
        id: "2097",
        // url:"https://xcf.huanuowei.cn",
        url: "xcf.huanuowei.cn",
        shop_name: "鑫崇芳汽配"
    },
    {
        id: "",
        // url:"https://hz.huanuowei.cn",
        url: "hz.huanuowei.cn",
        shop_name: "合众瑞吉"
    },
    // {
    //     id: "1876",
    //     // url:"https://km.huanuowei.cn",
    //     url: "hq.huanuowei.cn",
    //     shop_name: "昆明航黔"
    // },
    {
        id: "1975",
        // url:"https://zrf.huanuowei.cn",
        url: "zrf.huanuowei.cn",
        shop_name: "中润丰汽配"
    },
    {
        id: "",
        // url:"https://xcd.huanuowei.cn",
        url: "xcd.huanuowei.cn",
        shop_name: "鑫畅达汽配"
    },
    {
        id: "1881",
        // url:"https://cy.huanuowei.cn",
        url: "cy.huanuowei.cn",
        shop_name: "楚佑汽配"
    },
    {
        id: "2136",
        // url:"https://open.huanuowei.cn",
        url: "open.huanuowei.cn",
        shop_name: "开放平台"
    },
    {
        id: "1871",
        // url:"https://th.huanuowei.cn",
        url: "th.huanuowei.cn",
        shop_name: "挺海汽配"
    },
    {
        id: '2018',
        // url:"https://ybj.huanuowei.cn",
        url: "ybj.huanuowei.cn",
        shop_name: "优百佳汽配"
    },
    {
        id: '1948',
        // url:"https://ybj.huanuowei.cn",
        url: "tc.huanuowei.cn",
        shop_name: "长沙中驰"
    },
]
// web端右上菜单
const myMenuList = [
    {
        title: "我的订单",
        path: "/my/order",
        img: "order",
        img1: "order1"
    },
    {
        title: "退货/换货",
        path: "/my/RePart",
        img: "exchange",
        img1: "exchange1"
    },
    {
        title: "我的收藏",
        path: "/my/collect",
        img: "collect",
        img1: "collect1"
    },
    {
        title: "我的地址",
        path: "/my/address",
        img: "site",
        img1: "site1"
    },
    {
        title: "我的消息",
        path: "/my/message",
        img: "news",
        img1: "news1"
    },
    {
        title: "我的优惠",
        path: "/my/discounts",
        img: "discounts",
        img1: "discounts1"
    },

    {
        title: "需求发布",
        path: "/my/demand",
        img: "demand",
        img1: "demand1"
    },
    {
        title: "企业信息",
        path: "/my/company",
        img: "enterprise",
        img1: "enterprise1"
    },
    {
        title: "账号信息",
        path: "/my/account",
        img: "id",
        img1: "id1"
    },
    {
        title: "我的发票",
        path: "/my/invoice",
        img: "invoice",
        img1: "invoice1"
    },
    // {
    //     title:"员工管理",
    //     path:"/my/sysstaff",
    //     img:"invoice",
    //     img1:"invoice1"
    // },
]
// 店铺点头像的菜单
const storeMenuList = [
    {
        title: "我的订单",
        path: "/my/order",
        img: "order",
        img1: "order1"
    },
    {
        title: "我的消息",
        path: "/my/message",
        img: "news",
        img1: "news1"
    },
    {
        title: "企业信息",
        path: "/my/company",
        img: "enterprise",
        img1: "enterprise1"
    },
    {
        title: "账号信息",
        path: "/my/account",
        img: "id",
        img1: "id1"
    },
]
// 简单混淆与处理
const mixStr = (str, type) => {
    let str1 = ''
    if (type == 1) {
        str.split("").forEach(char => {
            str1 += char.charCodeAt() * 3 + ","
        })
        str1 = str1.substr(0, str1.length - 1)
    } else {
        str.split(",").forEach(char => {

            str1 += String.fromCodePoint(char / 3)
        })
    }
    return str1
}

// 对需求处理状态处理
const reDemandStateText = (num) => {
    let stateText = ""
    if (num == 0) {
        stateText = "解决中"
    } else if (num == 1) {
        stateText = "已解决 "
    } else if (num == 2) {
        stateText = "未解决"
    } else {
        stateText = "未知状态"
    }
    return stateText
}

const sevenData = []
const commonParts = [

]
// 特殊字符匹配
// %
const spec = /[`!@#$^&*\+=<>?:"{},.;'[]·~！@#￥……&*{}《》？：“”【】、；‘'，。、]/g
// 加减乘除处理
// let jia = this.$hnwTools.plus(0.1,0.2)
// let jian = this.$hnwTools.minus(0.2,0.1)
// let chen = this.$hnwTools.times(0.1,0.2)
// let chu = this.$hnwTools.div(0.1,0.2)
// const fc
const plus = (n1, n2) => {
    return fc(n1).plus(n2).toNumber()
}
const minus = (n1, n2) => {
    return fc(n1).minus(n2).toNumber()
}
const times = (n1, n2) => {
    return fc(n1).times(n2).toNumber()
}
const div = (n1, n2) => {
    return fc(n1).div(n2).toNumber()
}
let priceSys = [
    { key: "contract_1", label: "C-股东" },
    { key: "contract_2", label: "C-城市合伙人" },
    { key: "contract_3", label: "C-地级市" },
    { key: "contract_4", label: "C-普通企业" },
    { key: "contract_5", label: "D-经销商" },
    { key: "contract_6", label: "地级市代理" },
    { key: "contract_7", label: "A-供货商" },
    { key: "contract_8", label: "特殊类型" },
    { key: "contract_9", label: "省级代理" },
]
export default {
    // 格式化时间
    formatDateTime,
    formatGoodsKey,
    goodsAttrs,
    // 根据字段名称获取字段key
    getGoodsKey,
    // 根据订单状态获取文字
    getOrderStateText,
    getStockInfo,
    myMenuList,
    storeUrls,
    storeMenuList,
    mixStr,
    reLeftTime,
    startCountDown,
    reDemandStateText,
    sevenData,
    commonParts,
    spec,
    plus,
    minus,
    times,
    div,
    priceSys,
    getAllotion,
}