<template>
    <div class="body-img">
        <img :src="bodyImg" alt="">
    </div>
</template>

<script>
export default {
    data(){
        return{
            bodyImg:""
        }
    },
    created(){
        this.bodyImg = localStorage.getItem('web-banner-body-img')
    }
}
</script>

<style lang="scss">
.body-img{
    width: 1200px;
    margin: 0 auto;
    img{
        width: 1200px;
    }
}
</style>