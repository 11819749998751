<template>
	<div class="hnw-oss-img">
		<!-- <img lazy-load :lazy-load-margin="0" :src="picture"
			:style="{width:width+'rpx',height:width+'rpx'}" @error="imageError()"></img> -->
            <el-image
                :style="{width:width+'px',height:width+'px'}"
                :src="picture"
                @error="imageError()"
            ></el-image>
	</div>
</template>

<script>
	export default {

		data() {
			return {
				picture:""
			}
		},
		methods: {
			imageError(){

				console.log("图片加载失败")
				this.picture = "https://test.huanuowei.cn/images/static/index/zanwu.png"
			}
		},
		created(){
			
			
			if(!this.src){
				this.picture = "https://test.huanuowei.cn/images/static/index/zanwu.png"

			}else{
				if(!this.src.includes('image.oos.huanuowei.cn')){
					this.picture = "https://image.oos.huanuowei.cn/upload/shop/store/goods/1780/" + this.src
				}else{
					this.picture = this.src
				}
				
			}

			// if(!this.picture.includes('x-oss-process=image')){
			// 	this.picture = this.picture +"?x-oss-process=image/resize,p_25" 
			// }
			
			
		},
		props:{

			src:"",
			width:{
				default:"210"
			}
		}
	}
</script>
<style lang="scss">
	.hnw-oss-img{
		image{
			
		}
	}
</style>
