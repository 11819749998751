import { post,get } from "axios"
import store from '../../../store'
// 获取商品详情
const getGoodsInfo = async (data)=>{
    
    const res =  await post("index.php?act=store_info&op=goodsInfo",{
        ...data
    })
    return res
}

// 获取公司类型
const getAllCompanyType = async ()=>{
 const res =await post("index.php?act=store_manage&op=storeClassType")
 return res
}

// 获取支付类型
const getPayType = async ()=>{
 const res = await post("index.php?act=store_info&op=store_pay")
 return res 
}

// 开放接口储存
const setOpen = async (key,data)=>{
    const res=  await post("index.php?act=store_info&op=open_list_set",{
       key,
       set:data
    })
    if(!res){
       console.log("设置失败") 
    }else{
        return true
    }
}

// 开放接口获取
const getOpen = async (key)=>{
    const res=  await post("index.php?act=store_info&op=open_list_get",{
       key
    })
    if(res){
        return res
    }else{
        console.error("开放接口获取失败")
    }
}
// 获取物品购买记录 
const getGoodsRecord = async (goods_id,chain,store_id='')=>{
    const res = await post("index.php?act=store_info&op=goods_record",{
        goods_id,
        chain,
        store_id:store_id||store.state.store_id
    })
    
    return res
}
// 标记商品已有明觉数据
const setGoodsMjSign = async (goods_id)=>{
    return await post("index.php?act=store_goods&op=goodsMjEdit",{
        goods_id
    })
}
// 搜索记录
const getSearchRecord = async (data)=>{
    if(store.state.store_id){
        return await post("index.php?act=store_info&op=record_list",{
            store_id:store.state.store_id,
            member_id:store.state.member_id,
           ...data
        })
    }else{
        return []
    }
}
// 添加搜索记录  包括vin那边的  
const addSearchRecord = async (data)=>{
    if(store.state.store_id){
        return await post("index.php?act=web_index&op=record_add",{
            store_id:store.state.store_id,
            member_id:store.state.member_id,
           ...data
        })
    }else{
        return []
    }
}
// 是否有互换件
const  hasReplace = async (data)=>{
    const res =  await post("index.php?act=replace&op=isReplace",{
        ...data
    })
    return res
}
// 互换件列表
const  getReplaceList = async (data)=>{
    const res =  await post("index.php?act=replace&op=ReplaceGoods",{
        ...data
    })
    return res
}
// 获取当前已集单数量
const getCollectBuyerNum = async (setlist_id)=>{
    const res =  await post("index.php?act=store_cart&op=stock_quantity",{
        setlist_id
    })
    return res
}
// 获取零库存是否可以购买
const getCommonSeting = async (setlist_id)=>{
    const res =  await post("index.php?act=store_manage&op=setting",{
        setlist_id
    })
    return res
}
// 获取商品总部库存
// 获取零库存是否可以购买
const getHnwStorage = async (data)=>{
    const res =  await post("index.php?act=store_cart&op=stock",{
        ...data
    })
    return res
}
// 获取或者存取版本
// &model=2&data=1.0.0.1
const handleVer = async (data)=>{
    const res =  await post("index.php?act=data&op=version",{
        ...data
    })
    return res
}
// 获取本地mac接口
const deviceMacCdde = async (data)=>{
    const res =  await get("http://localhost:5919/local-api/v1/get/mac",{
        params:{
            ...data
        }
    })
    return res
}

// 获取用户mac接口
const getMyMacCdde = async (data)=>{    
    const res =  await post("index.php?act=setting&op=getDevice",{
        ...data
    })
    return res
}

// 收集分析日志
const getAnalysisLog = (key) => {
	
	return post("index.php?act=data&op=statistics",{
		version:localStorage.getItem("hnw-web-vers"),
		system:'windows',
		source:"web",
		value:200,
        shop_id:store.state.shop,
        store_id:store.state.store_id,
        member_id:store.state.member_id,
        key,
        member_name:store.state.member_name,
        store_name:store.state.store_name 
	})
}
// 批量查找互换件
const getBatchSearch = async (data)=>{    
    const res =  await post("index.php?act=web_index&op=batch_search",{
        ...data
    })
    return res
}
// 请求mac是否可用
const isokUserCode = async (data)=>{
    const res =  await post("",{
        ...data
    })
    return res
}

const test = async ()=>{
    console.log("一个测试方法")
}
export {
    getAllCompanyType,
    getPayType,
    getGoodsInfo,
    getGoodsRecord,
    setOpen,
    getOpen,
    setGoodsMjSign,
    getSearchRecord,
    addSearchRecord,
    hasReplace,
    getReplaceList,
    getCollectBuyerNum,
    getCommonSeting,
    getHnwStorage,
    handleVer,
    test, 
    deviceMacCdde,
    getMyMacCdde,
    getAnalysisLog,
    getBatchSearch,
    isokUserCode
}